import FormMasterAssetCategory from "../form";

export default {
  name: "CreateMasterAssetCategory",
  components: {
    FormMasterAssetCategory,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      message: {},
    };
  },
  methods: {
    async addMasterAssetCategory(payload) {
      const data = {
        assetName: payload.assetName,
        chartOfAccountId: payload.chartOfAccountId,
        description: payload.description,
        chartOfAccountCreditId: payload.chartOfAccountCreditId,
        fixedAsset: payload.fixedAsset,
        chartOfAccountDebitId: payload.chartOfAccountDebitId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Tambah Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.$refs.formMasterAssetCategoryRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "parameter",
          reqUrl: "asset",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formMasterAssetCategoryRef.backToList();
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.message = error.response?.data?.errorFields;
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formMasterAssetCategoryRef.property.animation.submitLoading = false;
      }
    },
  },
};
