var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-5 px-5 w-full"},[_c('div',{staticClass:"flex justify-center items-center flex-row w-full"},[_c('div',{staticClass:"flex justify-between items-center flex-row w-full"},[_c('div',{staticClass:"flex w-full items-center justify-between",staticStyle:{"max-width":"250px"},attrs:{"inline":""}},[_c('label',{staticClass:"text-xs"},[_vm._v("Deskripsi")]),_c('b-input',{attrs:{"size":"is-small","lazy":true},on:{"input":_vm.searchByDescriptionChartOfAccount},model:{value:(_vm.property.filterDto.description),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "description", $$v)},expression:"property.filterDto.description"}})],1),_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",attrs:{"label":"Chart Of Account","icon-left":"plus","disabled":!_vm.$store.getters['getActionsInsert'],"size":"is-small"},on:{"click":_vm.routeToPageAddChartOfAccount}})],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":true,"data":_vm.table.data.chartOfAccount,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.chartOfAccount.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.chartOfAccount.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.chartOfAccount.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"chartOfAccountCode","label":"COA"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.chartOfAccountCode))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"description","label":"Deskripsi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.description))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"chartOfAccountParentCode","label":"Coa Induk"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.chartOfAccountParentCode))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"coaTypeName","label":"Tipe Coa"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.coaTypeName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"chartOfAccountParentCode","label":"Status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.status ? props.row.status.statusName : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsUpdate'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.routeToPageEditChartOfAccount(props)}}},[_vm._v(" Rincian ")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDelete'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.deleteChartOfAccount(props)}}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsAuthorize'] ||
                props.row.status.statusId === '3' ||
                props.row.status.statusId === '2',"aria-role":"menuitem"},on:{"click":function($event){return _vm.authorizationMChartOfAccount(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.chartOfAccount.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.chartOfAccount.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationChartOfAccount},model:{value:(_vm.property.listElement.chartOfAccount.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.chartOfAccount, "currentPage", $$v)},expression:"property.listElement.chartOfAccount.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }