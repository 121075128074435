export default {
  name: "FormMMenuAccess",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          menuAccess: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        menuAccessName: "",
        menuAccessParentId: "",
        menuAccessLink: "",
        menuAccessPosition: "",
        menuAccessSort: "",
        menuAccessLevel: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        menuInduk: [],
      },
    };
  },
  methods: {
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.menuAccessId = payload.menuAccessId;
        this.dataForm.menuAccessName = payload.menuAccessName;
        this.dataForm.menuAccessParentId = payload.menuAccessParentId;
        this.dataForm.menuAccessLink = payload.menuAccessLink;
        this.dataForm.menuAccessPosition = payload.menuAccessPosition;
        this.dataForm.menuAccessSort = payload.menuAccessSort;
        this.dataForm.menuAccessLevel = payload.menuAccessLevel;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitMenuAccess() {
      this.$emit("submitMenuAccess", this.dataForm);
    },
    rollback() {
      this.helperGetMenuAccessDataForReference();
    },
    helperGetMenuAccessDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "menu-access",
          params: {
            menuAccessName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const value = index.menuAccessId;
            const text = `${index.menuAccessSort} - ${index.menuAccessName}`;
            this.options.menuInduk.push({ value, text });
          });
        });
    },
    routeToPageList() {
      this.$router.push("/admin/menu-access");
    },
    appendSelectOptions() {
      this.options.menuInduk.push({
        value: "",
        text: " -- Pilih --",
      });
    },
  },
  mounted() {
    this.checkingMainId();
    this.helperGetMenuAccessDataForReference();
    this.appendSelectOptions();
  },
};
