export default {
  name: "FormMChartOfAccount",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          addChartOfAccount: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        coaFlag: "",
        coaTypeId: "",
        chartOfAccountId: "",
        currency: "",
        description: "",
        normalBalance: "",
        chartOfAccountCode: "",
        chartOfAccountParentId: "",
        chartOfAccountParentCode: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        mataUang: [],
        jenisLaporan: [],
        indukLedger: [],
        tipeCoa: [],
        saldoNormal: [
          {
            text: "Debit",
            value: "D",
          },
          {
            text: "Kredit",
            value: "C",
          },
        ],
        coaFlag: [
          {
            text: "Pembiayaan",
            value: "1",
          },
          {
            text: "DPK",
            value: "2",
          },
          {
            text: "Pendapatan",
            value: "6",
          },
        ],
      },
    };
  },
  methods: {
    routeToPageListChartOfAccount() {
      this.$router.push("/parameter/chart-of-account");
    },

    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.currencyName;
            const value = i.currencyId;
            this.options.mataUang.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceIndukLedger() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              description: "",
              page: 0,
            },
            url: "chart-of-account",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.chartOfAccountCode + " - " + i.description;
            const value = i.chartOfAccountId;
            this.options.indukLedger.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceTypeCoa() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "reference/r-chart-of-account-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.coaTypeName;
            const value = i.coaTypeId;
            this.options.tipeCoa.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.description = payload.description;
        this.dataForm.currency = payload.currency;
        this.dataForm.normalBalance = payload.normalBalance;
        this.dataForm.chartOfAccountCode = payload.chartOfAccountCode;
        this.dataForm.chartOfAccountParentId = payload.chartOfAccountParentId;
        this.dataForm.chartOfAccountParentCode =
          payload.chartOfAccountParentCode;
        this.dataForm.coaTypeId = payload.coaTypeId;
        this.dataForm.coaFlag = payload.coaFlag;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitChartOfAccount() {
      this.$emit("submitChartOfAccount", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceCurrency();
    this.getReferenceIndukLedger();
    this.getReferenceTypeCoa();
  },
};
