import MixinsFuntions from "../../../../service/MixinsFuntions";

export default {
  name: "ListTDepositAccount",
  mixins: [MixinsFuntions],
  components: {},
  data() {
    return {
      dataForm: {
        checkingCif: {
          cifNumber: "",
          cifIdName: "",
          officeCode: "",
        },
      },
      session: {
        cifChecking: {
          cifNumber: "",
          cifIdName: "",
          officeCode: "",
        },
      },
      property: {
        animation: {
          checkingCif: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        modal: {
          showModalCheckingCif: false,
          showModalResultListCif: false,
        },
        listElement: {
          depositAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          cif: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          cifIdName: "",
          accountNumber: "",
          dateTo: "",
          dateFrom: "",
          cityName: "",
          orderByBalanceEnd: false,
          statusAccountId: "",
          officeCode: "",
        },
      },
      options: {
        office: [],
        statusAccount: [],
        filterOffice: [],
      },
      table: {
        totalRekening: 0,
        totalNominatif: 0,
        header: {
          depositAccount: [],
          cif: [],
        },
        data: {
          depositAccount: [],
          cif: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        accountNumber: this.property.filterDto.accountNumber,
        idCardNumber: "",
        cifIdName: this.property.filterDto.cifIdName,
        cityName: this.property.filterDto.cityName,
        orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
        officeCode: this.property.filterDto.officeCode,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        statusAccountId: this.property.filterDto.statusAccountId,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list/download",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Pembukaan_Deposito.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async findDepositAccount() {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            idCardNumber: "",
            cifIdName: this.property.filterDto.cifIdName,
            cityName: this.property.filterDto.cityName,
            orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusAccountId: this.property.filterDto.statusAccountId,
            officeCode: this.property.filterDto.officeCode,
            page: 0,
            size: this.property.listElement.depositAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositAccount =
              resp.data.data.pageResponse.content;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominatif;
            this.property.listElement.depositAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.property.listElement.depositAccount.currentPage =
              resp.data.data.pageResponse.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetDataDepositAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout + 500);
      }
    },
    handleNeutralListElementCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.rows = 0;
    },
    async handleCheckingAvailabilityCif() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.cifChecking.cifNumber = this.dataForm.checkingCif.cifNumber;
        this.session.cifChecking.cifIdName = this.dataForm.checkingCif.cifIdName;
        this.session.cifChecking.officeCode = this.dataForm.checkingCif.officeCode;
        this.dataForm.checkingCif.cifNumber = "";
        this.dataForm.checkingCif.officeCode = "";
        this.dataForm.checkingCif.cifIdName = "";
        const timeout = 500;
        try {
          this.property.animation.checkingCif.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "cif/simple-list",
            payload: {
              officeCode: this.session.cifChecking.officeCode,
              cifNumber: this.session.cifChecking.cifNumber,
              categoryId: "",
              cifIdName: this.session.cifChecking.cifIdName,
              cifMotherName: "",
              page: 0,
              size: this.property.listElement.cif.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.cif = resp.data.data.content;
              this.property.listElement.cif.rows = resp.data.data.totalElements;
              this.property.modal.showModalCheckingCif = false;
              this.property.modal.showModalResultListCif = true;
            } else {
              // this.dataForm.checkingCif.cifNumber = this.session.cifChecking.cifNumber;
              // this.dataForm.checkingCif.cifIdName = this.session.cifChecking.cifIdName;
              // this.dataForm.checkingCif.officeCode = this.session.cifChecking.officeCode;
              // this.session.cifChecking.officeCode = "";
              // this.session.cifChecking.cifNumber = "";
              // this.session.cifChecking.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          this.dataForm.checkingCif.cifNumber = this.session.cifChecking.cifNumber;
          this.dataForm.checkingCif.cifIdName = this.session.cifChecking.cifIdName;
          this.dataForm.checkingCif.officeCode = this.session.cifChecking.officeCode;
          this.session.cifChecking.officeCode = "";
          this.session.cifChecking.cifNumber = "";
          this.session.cifChecking.cifIdName = "";
          console.log(error.response);
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingCif.isLoading = false;
          }, timeout);
        }
      }
    },
    handleCatchErrorCif(error) {
      console.log(error.response);
      this.table.data.cif = [];
      this.property.listElement.cif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangePaginationCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.session.cifChecking.cifNumber,
            cifMotherName: "",
            officeCode: this.session.cifChecking.officeCode,
            categoryId: "",
            cifIdName: this.session.cifChecking.cifIdName,
            page: event - 1,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataCif(resp) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.message = resp.data.message;
    },
    async handleDeleteDataDepositAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "deposit-account/" + props.row.depositAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositAccount.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.filterDto.cityName = "";
                  this.property.filterDto.statusAccountId = "";
                  this.property.filterDto.officeCode = "";
                  this.property.filterDto.orderByBalanceEnd = false;
                  this.property.listElement.depositAccount.rows = 0;
                  this.helperGetDataTableDepositAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleRouteToPageAddDepositAccount(props) {
      if (props.row.statusId !== "3") {
        this.property.modal.showModalResultListCif = false;
        this.$buefy.toast.open({
          message: `Cif Belum Aktif !`,
          type: "is-danger",
          duration: 3000,
          position: "is-top",
          queue: false,
        });
        return;
      }
      this.$router.push(
        `/deposit/deposit-account/new?refId=${this.encryptBASE64(
          JSON.stringify({ cifId: props.row.cifId })
        )}`
      );
    },
    handleRouteToPageEditDepositAccount(props) {
      this.$router.push(
        `/deposit/deposit-account/${this.encryptBASE64(
          `${props.row.depositAccountId}`
        )}`
      );
    },
    async handleChangePaginationDepositAccount(event) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            idCardNumber: "",
            cifIdName: this.property.filterDto.cifIdName,
            cityName: this.property.filterDto.cityName,
            orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
            statusAccountId: this.property.filterDto.statusAccountId,
            officeCode: this.property.filterDto.officeCode,
            page: event - 1,
            size: this.property.listElement.depositAccount.perPage,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositAccount =
              resp.data.data.pageResponse.content;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominatif;
            this.property.listElement.depositAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.handleFailedGetDataDepositAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout);
      }
    },
    async handleAuthorizationDepositAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account/authorized/" + props.row.depositAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositAccount.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.filterDto.cityName = "";
                  this.property.filterDto.statusAccountId = "";
                  this.property.filterDto.officeCode = "";
                  this.property.filterDto.orderByBalanceEnd = false;
                  this.property.listElement.depositAccount.rows = 0;
                  this.helperGetDataTableDepositAccount();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleCatchErrorDepositAccount(error) {
      console.log(error.response);
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.currentPage = 1;
      this.property.listElement.depositAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async helperGetDataTableDepositAccount() {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: "",
            idCardNumber: "",
            cifIdName: "",
            cityName: "",
            officeCode: "",
            orderByBalanceEnd: false,
            statusAccountId: "",
            page: 0,
            size: this.property.listElement.depositAccount.perPage,
            dateTo: "",
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositAccount =
              resp.data.data.pageResponse.content;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominatif;
            this.property.listElement.depositAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.handleFailedGetDataDepositAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataDepositAccount(resp) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.message = resp.data.message;
    },
    handleCloseModalCheckingCif() {
      this.property.modal.showModalCheckingCif = false;
      this.handleClearModalCheckingCif();
    },
    handleClearModalResultCheckingCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.perPage = 5;
    },
    handleClearModalCheckingCif() {
      this.dataForm.checkingCif.cifNumber = "";
      this.dataForm.checkingCif.cifIdName = "";
      this.dataForm.checkingCif.officeCode = "";
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Cabang Saat Ini --" }];
          this.options.filterOffice = [
            { value: "", text: "-- Semua Cabang --" },
          ];
          resp.data.data.content.map((data) => {
            var value = data.officeCode;
            var text = `${data.officeCode} - ${data.officeName}`;
            this.options.office.push({ text, value });
            this.options.filterOffice.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getReferenceOffice();
    this.helperGetDataTableDepositAccount();
    this.getReferenceStatusAccount();
  },
};
