var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-center items-center flex-row w-full"},[_c('div',{staticClass:"flex justify-between items-center flex-wrap flex-row w-full"},[_c('div',{staticClass:"flex w-full flex-wrap items-center justify-between",staticStyle:{"max-width":"280px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nama UMB")]),_c('b-input',{staticStyle:{"max-width":"200px","width":"100%"},attrs:{"size":"is-small","lazy":true}})],1),_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",attrs:{"label":"UMB","icon-left":"plus","size":"is-small","disabled":!_vm.$store.getters['getActionsInsert']},on:{"click":_vm.routeTopageAdd}})],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.umb,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.umb.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.umb.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.umb.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Kode UMB"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Nama UMB"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Bagian"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Keterangan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Tanggal Realisasi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Tanggal Pengembalian"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Nominal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('span',{staticClass:"text-xs"})]},proxy:true}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"actions","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}])},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsUpdate'],"aria-role":"menuitem"},on:{"click":_vm.showModalEditUMB}},[_vm._v(" Edit ")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDelete'],"aria-role":"menuitem"}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDetail'],"aria-role":"menuitem"}},[_vm._v("Detail")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsAuthorize'],"aria-role":"menuitem"}},[_vm._v("Authorisasi")])],1)]},proxy:true}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.umb.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.umb.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},model:{value:(_vm.property.listElement.umb.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.umb, "currentPage", $$v)},expression:"property.listElement.umb.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }