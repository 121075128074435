import { jsPDF } from "jspdf";

export default {
  name: "ListTLoanPaid",
  data() {
    return {
      dataForm: {
        checkingLoanAccount: {
          accountNumber: "",
        },
      },
      property: {
        animation: {
          checkingLoanAccount: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingLoanAccount: false,
          showModalResultListLoanAccount: false,
        },
        filterDto: {
          loanAccountNumber: "",
          cifIdName: "",
          dateFrom: "",
          dateTo: "",
        },
        listElement: {
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanPaid: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      session: {
        checkingLoanAccount: {
          accountNumber: "",
        },
      },

      table: {
        header: {},
        data: {
          loanPaid: [],
          loanAccount: [],
        },
      },
    };
  },
  methods: {
    async findLoanPaid() {
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-paid-off/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            cifIdName: this.property.filterDto.cifIdName,
            page: 0,
            size: this.property.listElement.loanPaid.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaid = resp.data.data.content;
            this.property.listElement.loanPaid.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaid(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanPaid(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaid.downloading = false;
        }, timeout + 500);
      }
    },

    async handleCheckingLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingLoanAccount.accountNumber = this.dataForm.checkingLoanAccount.accountNumber;
        this.dataForm.checkingLoanAccount.accountNumber = "";
        const timeout = 500;
        try {
          this.property.animation.checkingLoanAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "transaction-loan/findByLoanAccountNumber/" +
              this.session.checkingLoanAccount.accountNumber,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              console.log(resp);
              this.table.data.loanAccount.push(resp.data.data);
              this.property.listElement.loanAccount.rows =
                resp.data.data.totalElements;
              this.property.modal.showModalCheckingLoanAccount = false;
              this.property.modal.showModalResultListLoanAccount = true;
            } else {
              this.dataForm.checkingLoanAccount.accountNumber = this.session.checkingLoanAccount.accountNumber;
              this.session.checkingLoanAccount.accountNumber = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.dataForm.checkingLoanAccount.accountNumber = this.session.checkingLoanAccount.accountNumber;
            this.session.checkingLoanAccount.accountNumber = "";
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : `Terjadi Kesalahan !`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingLoanAccount.isLoading = false;
          }, timeout);
        }
      }
    },
    async authorizedLoanPaid(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl: "loan-paid-off/authorized/" + props.row.loanPaidOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.loanAccountNumber = "";
                  this.property.listElement.loanPaid.rows = 0;
                  this.getLoanPaid();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteDataLoanPaid(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-paid-off/" + props.row.loanPaidOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.loanPaid.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.loanAccountNumber = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.dateFrom = "";
                  this.property.listElement.loanPaid.rows = 0;
                  this.getLoanPaid();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleRouteToPageAddLoanAccountPaid(props) {
      this.$router.push(
        `/loan/loan-paid/new?refId=${this.encryptBASE64(
          JSON.stringify({ loanAccountId: props.row.loanAccountId })
        )}`
      );
    },

    async changePaginationLoanPaid(event) {
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-paid-off/simple-list",
          payload: {
            transactionCodeId: "",
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.loanPaid.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaid = resp.data.data.content;
            this.property.listElement.loanPaid.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaid(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanPaid(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaid.downloading = false;
        }, timeout);
      }
    },
    async getLoanPaid() {
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-paid-off/simple-list",
          payload: {
            loanAccountNumber: "",
            cifIdName: "",
            transactionCodeId: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaid = resp.data.data.content;
            this.property.listElement.loanPaid.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaid(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanPaid(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaid.downloading = false;
        }, timeout);
      }
    },
    failedGetDataLoanPaid(resp) {
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.rows = 0;
      this.property.listElement.loanPaid.message = resp.data.message;
    },
    handleErrorGetLoanPaid(error) {
      console.log(error.response);
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.rows = 0;
      this.property.listElement.loanPaid.currentPage = 1;
      this.property.listElement.loanPaid.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleCloseModalCheckingLoanAccount() {
      this.property.modal.showModalCheckingLoanAccount = false;
      this.handleClearModalCheckingLoanAccount();
    },
    handleClearModalCheckingLoanAccount() {
      this.dataForm.checkingLoanAccount.accountNumber = "";
    },
    handleClearModalResultCheckingLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.perPage = 5;
    },
    routeToPageEditLoanPaid(props) {
      this.$router.push(
        `/loan/loan-paid/${this.encryptBASE64(`${props.row.loanPaidOffId}`)}`
      );
    },
    printOutLoanPaid(props) {
      console.log(props);
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      doc
        .setFontSize(9)
        .text(`Nomor Referensi : ${props.row.referenceNumber}`, 0.55, 0.8);
      doc
        .setFontSize(9)
        .text(`Nomor Kontrak : ${props.row.loanAccountNumber}`, 0.55, 1.1);
      doc
        .setFontSize(9)
        .text(`Nama Nasabah : ${props.row.cifIdName}`, 0.55, 1.4);
      doc.setFontSize(9).text(`Cabang : ${props.row.officeName}`, 0.55, 1.7);

      doc
        .setFontSize(9)
        .text(
          `Kode Transaksi : ${props.row.transactionCodeName} - ${props.row.transactionCode}`,
          0.55,
          2
        );
      doc
        .setFontSize(9)
        .text(`Alasan Pelunasan : ${props.row.reason}`, 0.55, 2.3);
      doc
        .setFontSize(9)
        .text(`No. Bukti : ${props.row.documentProofNumber}`, 0.55, 2.6);
      doc
        .setFontSize(9)
        .text(
          `Tgl. Transaksi : ${this.dateToYearMonthDay(
            props.row.transactionDate
          )}`,
          0.55,
          2.9
        );
      doc
        .setFontSize(9)
        .text(`Rekening Tabungan : ${props.row.accountNUmber}`, 0.55, 3.2);
      doc
        .setFontSize(9)
        .text(
          `Saldo Rekening Tabungan : ${this.convertCurrency(
            props.row.savingAccountInstallmentBalanceUsable
          )}`,
          0.55,
          3.5
        );
      doc
        .setFontSize(9)
        .text(
          `Sisa Pokok  : ${this.convertCurrency(
            props.row.outstandingPrincipal
          )}`,
          0.55,
          3.8
        );
      doc
        .setFontSize(9)
        .text(
          `Sisa Margin  : ${this.convertCurrency(props.row.marginPrincipal)}`,
          0.55,
          4.2
        );
      doc
        .setFontSize(9)
        .text(
          `Diskon (Margin)   : ${this.convertCurrency(
            props.row.discountMargin
          )}`,
          0.55,
          4.5
        );

      doc
        .setFontSize(9)
        .text(
          `Sisa Margin Dibayar  : ${this.convertCurrency(
            props.row.marginRecognized
          )}`,
          0.55,
          4.8
        );

      doc
        .setFontSize(9)
        .text(
          `Margin Diakui  : ${this.convertCurrency(
            props.row.marginRecognized
          )}`,
          0.55,
          5.2
        );

      doc
        .setFontSize(9)
        .text(
          `Nama  : ${props.row.savingAccountInstallmentCifIdName}`,
          0.55,
          5.5
        );

      doc
        .setFontSize(9)
        .text(
          `Cabang  : ${props.row.savingAccountInstallmentBranchName}`,
          0.55,
          5.8
        );
      doc
        .setFontSize(9)
        .text(
          `Total Pelunasan  : ${this.convertCurrency(props.row.totalPaidOff)}`,
          0.55,
          6.4
        );
      doc
        .setFontSize(9)
        .text(`Keterangan  : ${props.row.description}`, 0.55, 6.1);

      doc.setFontSize(9).text("( Maker )", 0.55, pageHeight - 3);
      doc.setFontSize(9).text("( Taker )", 1.2, pageHeight - 3);
      doc.setFontSize(9).text("( Signer )", 1.82, pageHeight - 3);
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
  },
  mounted() {
    this.getLoanPaid();
  },
};
