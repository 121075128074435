//   this.exportExcel({
//     body:[]
//     header: []
//     worksheetName: "",
//     fileName: ``,
//     format: "",
//     headerStyle: {
//       align: "center",
//       fontColor: "FFFFFF",
//       fontBold: true,
//       bgColor: "031E68",
//       borderColor: "000000",
//     },
//     bodyStyle: {
//       align: "center",
//       fontColor: "000000",
//       fontBold: false,
//       bgColor: "FFFFFF",
//       borderColor: "000000",
//     },
//   });

import ExcelJS from "exceljs";

export const exportExcel = async (params) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(params.worksheetName);
  worksheet.columns = params.header;
  let index = 0;
  while (index !== params.body.length) {
    worksheet.addRow(params.body[index]);
    let rowItem = worksheet.getRow(index + 2);
    rowItem.eachCell((cell) => {
      cell.border = {
        top: { style: "thin", color: { argb: params.bodyStyle.borderColor } },
        left: { style: "thin", color: { argb: params.bodyStyle.borderColor } },
        bottom: {
          style: "thin",
          color: { argb: params.bodyStyle.borderColor },
        },
        right: { style: "thin", color: { argb: params.bodyStyle.borderColor } },
      };
      cell.alignment =
        params.bodyStyle.align === "center"
          ? { vertical: "middle", horizontal: "center" }
          : {};
      cell.font = {
        color: { argb: params.bodyStyle.fontColor },
        bold: params.bodyStyle.fontBold,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: params.bodyStyle.bgColor },
      };
    });
    index++;
  }

  const rowHeader = worksheet.getRow(1);
  rowHeader.eachCell((cell) => {
    cell.border = {
      top: { style: "thin", color: { argb: params.headerStyle.borderColor } },
      left: { style: "thin", color: { argb: params.headerStyle.borderColor } },
      bottom: {
        style: "thin",
        color: { argb: params.headerStyle.borderColor },
      },
      right: { style: "thin", color: { argb: params.headerStyle.borderColor } },
    };
    cell.alignment =
      params.headerStyle.align === "center"
        ? { vertical: "middle", horizontal: "center" }
        : {};
    cell.font = {
      color: { argb: params.headerStyle.fontColor },
      bold: params.headerStyle.fontBold,
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: params.headerStyle.bgColor },
    };
  });
  const buffer = await createBuffer(params.format, workbook);

  if (buffer) {
    const blob = new Blob([buffer]);
    const url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `${params.fileName}.${params.format}`;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

const createBuffer = async (format, workbook) => {
  if (format === "xlsx") {
    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  }
  if (format === "csv") {
    const buffer = await workbook.csv.writeBuffer();
    return buffer;
  }
  if (!format) {
    return false;
  }
};
