export default {
  name: "DetailConfiguration",
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        configCode: "",
      },
      dataForm: {
        configDescription: "",
        configValue: "",
      },
    };
  },
  methods: {
    routeToPageListConfiguration() {
      this.$router.push("/admin/configuration");
    },
    getIdentityFromSession() {
      const params = this.$route.params.id;
      if (!params) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListConfiguration();
        return;
      }
      const configCode = this.decryptBASE64(params);
      this.identity.configCode = configCode;
    },
    async getConfigurationByCode() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListConfiguration();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "configuration/" + this.identity.configCode,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.configValue = resp.data.data.configCode
              ? resp.data.data.configCode
              : "";
            this.dataForm.configDescription = resp.data.data.configDescription
              ? resp.data.data.configDescription
              : "";
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListConfiguration(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListConfiguration(),
          });
        }
      }
    },
    async editDataConfiguration() {
      const data = {
        configDescription: this.dataForm.configDescription,
        configValue: this.dataForm.configValue,
      };
      console.log(JSON.stringify(data));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl: "configuration/" + this.identity.configCode,
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.NotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.getConfigurationByCode();
  },
};
