import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

export default {
  name: "FormTSavingAccountDetailRak",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        transactionCodeId: "",
        transactionCodeName: "",
        transactionDate: "",
        nominal: 0,
        description: "",
        savingAccountId: "",
        valutaDate: "",
        savingId: "",
        cifIdName: "",
        officeName: "",
        documentProofNumber: "",
        accountNumber: "",
        cetakData: "",
        balanceUsable: 0,
        holdNominal: 0,
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        journalRecord: {
          transactionCode: "",
          productCode: "",
          journalRecordDetails: [],
        },
      },
      options: {
        kodeTransaksi: [],
      },
      property: {
        animation: {
          rak: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
        modal: {
          showModalPrint: false,
        },
      },
    };
  },
  methods: {
    async getSavingChartOfAccountBySavingId() {
      this.form.journalRecord.journalRecordDetails = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: `saving-chart-of-account/saving-transaction/${this.form.savingId}/${this.form.transactionCodeId}`,
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i, index) => {
            const productLedgerId = i.savingChartOfAccountId;
            const chartOfAccountId = i.mchartOfAccount
              ? i.mchartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.mchartOfAccount
              ? i.mchartOfAccount.chartOfAccountCode
              : "";
            const label = i.mproductLedger
              ? i.mproductLedger.productLedgerName
              : "";
            const pairType = i.pairType;
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.mchartOfAccount
              ? i.mchartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairMChartOfAccount
              ? i.pairMChartOfAccount.chartOfAccountId
              : "";
            const pairChartOfAccountCode = i.pairMChartOfAccount
              ? i.pairMChartOfAccount.pairChartOfAccountCode
              : "";
            const amount = 0;
            this.form.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              chartOfAccountCode,
              pairChartOfAccountId,
              pairChartOfAccountCode,
              label,
              positionMutation,
              labelInputComponent,
              pairType,
              pairPositionMutation,
              amount,
              index: index,
            });
          });
        }
      } catch (error) {}
    },
    changeKodeTransaksi() {
      this.form.transactionCodeName = "";
      this.options.kodeTransaksi.map((index) => {
        if (this.form.transactionCodeId === index.value) {
          this.form.transactionCodeName = index.description;
          this.form.journalRecord.transactionCode = index.transactionCode;
          this.getSavingChartOfAccountBySavingId();
        }
      });
    },
    routeToPageListSavingAccountDetailRak() {
      this.$router.push("/saving/saving-account-detail-rak");
    },
    printBuktiTransaksi() {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      doc
        .setFontSize(9)
        .text(
          ` ${this.form.printSavingAccountDetail.officeCode} - ${this.form.printSavingAccountDetail.officeName}`,
          0.55,
          2.1
        );
      doc.setFontSize(9).text(
        `Tanggal Transaksi   :    ${
          this.form.printSavingAccountDetail.transactionDate
            ? new Date(
                this.form.printSavingAccountDetail.transactionDate
              ).getDate()
            : ""
        } ${
          this.form.printSavingAccountDetail.transactionDate
            ? this.property.accessories.day[
                new Date(
                  this.form.printSavingAccountDetail.transactionDate
                ).getMonth()
              ]
            : ""
        } ${
          this.form.printSavingAccountDetail.transactionDate
            ? new Date(
                this.form.printSavingAccountDetail.transactionDate
              ).getFullYear()
            : ""
        }  ${
          this.form.printSavingAccountDetail.transactionDate
            ? new Date(
                this.form.printSavingAccountDetail.transactionDate
              ).getHours()
            : ""
        } : ${
          this.form.printSavingAccountDetail.transactionDate
            ? new Date(
                this.form.printSavingAccountDetail.transactionDate
              ).getMinutes()
            : ""
        } : ${
          this.form.printSavingAccountDetail.transactionDate
            ? new Date(
                this.form.printSavingAccountDetail.transactionDate
              ).getSeconds()
            : ""
        }
          `,
        0.55,
        2.4
      );
      doc
        .setFontSize(9)
        .text(
          `No Rekening            :   ${this.form.printSavingAccountDetail.accountNumber} - ${this.form.printSavingAccountDetail.cifIdName}`,
          0.55,
          2.7
        );
      doc
        .setFontSize(9)
        .text(
          `Kode Transaksi        :   ${this.form.printSavingAccountDetail.transactionCode} - ${this.form.printSavingAccountDetail.transactionName}`,
          0.55,
          3.0
        );
      doc
        .setFontSize(9)
        .text(
          `Nominal                    :    ${
            this.form.printSavingAccountDetail.nominal
              ? this.form.printSavingAccountDetail.nominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          0.55,
          3.3
        );

      doc
        .setFontSize(9)
        .text(
          `Keterangan               :   ${this.form.printSavingAccountDetail.description}`,
          0.55,
          3.6
        );

      doc
        .setFontSize(9)
        .text(
          `Print By  :  ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userName
          } ( ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userCode
          } )`,
          0.55,
          pageHeight - 0.5
        );

      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "transaction-code",
            params: {
              name: "",
              page: 0,
              menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_MENU_ACCESS_SAVING_ACCOUNT_DETAIL,
            },
          }
        );
        resp.data.data.content.map((i) => {
          const text = `${i.transactionCode} - ${i.transactionName}`;
          const value = i.transactionCodeId;
          const description = i.transactionName;
          const transactionCode = i.transactionCode;
          this.options.kodeTransaksi.push({
            text,
            value,
            description,
            transactionCode,
          });
        });
      } catch (error) {}
    },
    appendEmptytOptionSelect() {
      this.options.kodeTransaksi = [{ value: "", text: "-- Pilih --" }];
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    async cetakSlipTrx() {
      this.property.animation.cetakSlip.isLoading = true;

      let pdf = new jsPDF("landscape", "mm", [297, 210]);

      setTimeout(() => {
        html2canvas(document.querySelector("#table-slip-rak"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-slip-rak").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 21, 9);
          pdf.autoPrint();

          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-slip-rak").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          this.property.modal.showModalPrint = false;
        });
      }, 200);
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListSavingAccountDetail();
        return;
      }
      console.log(params);
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).savingAccountId;
      this.$emit("getMainId", this.identity.mainId);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.transactionCodeId = payload.mtransactionCode
          ? payload.mtransactionCode.transactionCodeId
          : "";
        this.form.transactionCodeName = payload.mtransactionCode
          ? payload.mtransactionCode.transactionName
          : "";
        this.form.descriptionTransactionCode = payload.mtransactionCode
          ? payload.mtransactionCode.transactionName
          : "";
        this.form.cifClassificationName = payload.tsavingAccount
          ? payload.tsavingAccount.mcif
            ? payload.tsavingAccount.mcif.classificationId
              ? payload.tsavingAccount.mcif.classificationId
                  .cifClassificationName
              : ""
            : ""
          : "";
        this.form.cifIdName = payload.tsavingAccount
          ? payload.tsavingAccount.mcif
            ? payload.tsavingAccount.qqName
              ? `${payload.tsavingAccount.mcif.cifIdName} ${payload.tsavingAccount.qqCode} ${payload.tsavingAccount.qqName}`
              : payload.tsavingAccount.mcif.cifIdName
            : ""
          : "";
        this.form.officeName = payload.tsavingAccount
          ? payload.tsavingAccount.mcif
            ? payload.tsavingAccount.mcif.officeId
              ? payload.tsavingAccount.mcif.officeId.officeName
              : ""
            : ""
          : "";
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.accountNumber = payload.accountNumber;
        this.form.transactionDate = payload.transactionDate;
        this.form.valutaDate = payload.valutaDate;
        this.form.mutationPosition = payload.mutationPosition;
        this.form.nominal = payload.nominal;
        this.form.description = payload.description;
        this.form.referenceNumber = payload.referenceNumber;
        this.form.transactionNumber = payload.transactionNumber;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.form.accountNumber = payload.accountNumber;
      this.form.cifIdName = payload.mcif
        ? payload.qqName
          ? `${payload.mcif.cifIdName} ${payload.qqCode} ${payload.qqName}`
          : payload.mcif.cifIdName
        : "";
      this.form.officeName = payload.mcif
        ? payload.mcif.officeId
          ? payload.mcif.officeId.officeName
          : ""
        : "";
      this.form.savingAccountId = payload.savingAccountId;
      this.form.savingId = payload.msaving.savingId;
      this.form.balanceUsable = payload.balanceUsable;
      this.form.holdNominal = payload.holdNominal;
      this.form.journalRecord.productCode = payload.msaving
        ? payload.msaving.savingCode
        : "";
    },
    submitSavingAccountDetailRak() {
      this.$emit("submitSavingAccountDetailRak", this.form);
    },
    callBackSubmitSavingAccountDetailRak(resp) {
      this.form.cetakData = resp.data.data;
      this.property.modal.showModalPrint = true;
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendEmptytOptionSelect();
    this.getReferenceTransactionCode();
  },
};
