export default {
  name: "FormTLoanInstallmentCorrection",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          addLoanInstallmentPaymentReturn: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
        modal: {
          showModalPrint: false,
        },
        money: {
          precision: 2,
          decimal: ",",
          thousands: ".",
          prefix: "",
          suffix: "",
          masked: false,
        },
      },
      identifier: {
        cetakData: "",
      },
      dataForm: {
        reasonReturn: "",
        docProofNumber: "",
        description: "",
      },
    };
  },
  methods: {
    submitLoanPaymentReturn() {
      this.$emit("submitLoanPaymentReturn", this.dataForm);
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    routeToPageInstallmentCorrection() {
      this.$router.push("/loan/installment-correction");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageInstallmentCorrection();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).loanAccountNumber;
      this.$emit("getMainId", this.identity.mainId);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting) {
        this.dataForm = payload;
        return;
      }
      this.dataForm = { ...payload };
      this.dataForm.description = this.dataForm
        ? `${this.dataForm.transactionName} - ${this.dataForm.loanAccountNo} an ${this.dataForm.loanAccountName}`
        : "";
    },
  },
  mounted() {
    this.checkingMainId();
  },
};
