<template>
  <FormCashAdvance
    ref="formCashAdvanceRef"
    @submit="addCashAdvance($event)"
    :isExisting="false"
  />
</template>

<script>
import FormCashAdvance from "../form";
export default {
  name: "CreateCashAdvance",
  components: {
    FormCashAdvance,
  },
  methods: {
    async addCashAdvance(payload) {
      const data = {
        cashAdvanceName: payload.cashAdvanceName,
        cashAdvanceCode: payload.cashAdvanceCode,
        realizationDate: payload.realizationDate,
        returnDate: payload.returnDate,
        nominal: payload.nominal,
        nominalAdmin: payload.nominalAdmin,
        description: payload.description,
        departmentId: payload.departmentId,
        cashAdvanceTypeId: payload.cashAdvanceTypeId,
        accountNumber: payload.accountNumber,
        accountType: payload.accountType,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.$refs.formCashAdvanceRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: data,
        });
        await this.simpleWait(1000);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.toast.open(
            this.$NotificationUtils.success
          );
          toastBuefy.$on("close", () => {
            this.$refs.formCashAdvanceRef.backToList();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1000);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formCashAdvanceRef.property.animation.submitLoading = false;
      }
    },
  },
};
</script>
