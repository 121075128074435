var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',[_vm._m(0),_c('div',{staticClass:"flex justify-center w-full"},[_c('span',{staticClass:"text-md font-bold"},[_vm._v("Tanggal : "+_vm._s(_vm.dataForm.date ? _vm.Moment(_vm.dataForm.date).format("DD-MM-YYYY") : _vm.dataForm.date))])])]),_c('br'),_c('hr',{staticClass:"w-full my-1",staticStyle:{"background-color":"grey","height":"1px"}}),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2.5 mt-5",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-md"},[_vm._v("Laba Rugi Posisi sd Kemarin")]),_c('money',_vm._b({staticClass:"text-start p-2 text-xs rounded w-full md:w-1/2",staticStyle:{"font-size":"15px","outline":"1px solid #e7eaed","height":"35px","width":"100%","max-width":"250px"},attrs:{"value":_vm.dataForm.history
          ? ("" + (_vm.dataForm.history
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')))
          : 0,"disabled":""}},'money',_vm.VMoneyPrecision2,false))],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2.5",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-md"},[_vm._v("Total Pendapatan Hari Ini")]),_c('money',_vm._b({staticClass:"text-start p-2 text-xs rounded w-full md:w-1/2",staticStyle:{"font-size":"15px","outline":"1px solid #e7eaed","height":"30px","width":"100%","max-width":"250px"},attrs:{"value":_vm.dataForm.revenue
          ? ("" + (_vm.dataForm.revenue
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')))
          : 0,"disabled":""}},'money',_vm.VMoneyPrecision2,false))],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2.5",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-md"},[_vm._v("Total Biaya Hari Ini")]),_c('money',_vm._b({staticClass:"text-start p-2 text-xs rounded w-full md:w-1/2",staticStyle:{"font-size":"15px","outline":"1px solid #e7eaed","height":"30px","width":"100%","max-width":"250px"},attrs:{"value":_vm.dataForm.expense
          ? ("" + (_vm.dataForm.expense
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')))
          : 0,"disabled":""}},'money',_vm.VMoneyPrecision2,false))],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2.5",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-md"},[_vm._v("Estimasi Biaya Baghas")]),_c('money',_vm._b({staticClass:"text-start p-2 text-xs rounded w-full md:w-1/2",staticStyle:{"font-size":"15px","outline":"1px solid #e7eaed","height":"30px","width":"100%","max-width":"250px"},on:{"input":_vm.changeProfitLossEstimate},model:{value:(_vm.dataForm.totalProfitShare),callback:function ($$v) {_vm.$set(_vm.dataForm, "totalProfitShare", $$v)},expression:"dataForm.totalProfitShare"}},'money',_vm.VMoneyPrecision2,false))],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2.5 mb-5",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-md"},[_vm._v("Estimasi Laba Rugi Berjalan")]),_c('money',_vm._b({staticClass:"text-start p-2 text-xs rounded w-full md:w-1/2",staticStyle:{"font-size":"15px","outline":"1px solid #e7eaed","height":"30px","width":"100%","max-width":"250px"},attrs:{"value":_vm.dataForm.totalProfitLoss
          ? ("" + (_vm.dataForm.totalProfitLoss
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')))
          : 0,"disabled":""}},'money',_vm.VMoneyPrecision2,false))],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center w-full"},[_c('span',{staticClass:"text-md font-bold"},[_vm._v("ESTIMASI LABA RUGI")])])}]

export { render, staticRenderFns }