var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-end"},[_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",staticStyle:{"width":"100%","max-width":"125px"},attrs:{"disabled":!_vm.$store.getters['getActionsInsert'],"label":"Single Entry","icon-left":"plus","size":"is-small"},on:{"click":_vm.routeToPageAddSingleEntry}})],1),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.singleEntry,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.singleEntry.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.singleEntry.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.singleEntry.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"transactionDate","label":"Tanggal Transaksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.transactionDate ? props.row.transactionDate : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"officeCode","label":"Kode Cabang"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.officeCode ? props.row.officeCode : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"chartOfAccountName","label":"Coa"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.chartOfAccountName ? props.row.chartOfAccountName : "")+" - "+_vm._s(props.row.chartOfAccountCode ? props.row.chartOfAccountCode : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"mutationPosition","label":"Posisi Mutasi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.mutationPosition ? props.row.mutationPosition : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"description","label":"Deskripsi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.description ? props.row.description : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"nominal","label":"Nominal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.nominal ? props.row.nominal .toFixed(2) .replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0)+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"referenceNumber","label":"Nomor Referensi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.referenceNumber ? props.row.referenceNumber : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"batch","label":"Batch"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.batch ? props.row.batch : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"statusName","label":"status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.statusName ? props.row.statusName : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem","disabled":!_vm.$store.getters['getActionsDetail']},on:{"click":function($event){return _vm.routeToPageRincianSingleEntry(props)}}},[_vm._v("Rincian")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem","disabled":!_vm.$store.getters['getActionsDelete']},on:{"click":function($event){return _vm.deleteSingleEntry(props)}}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem","disabled":!_vm.$store.getters['getActionsAuthorize']},on:{"click":function($event){return _vm.authorizationSingleEntry(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.singleEntry.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.singleEntry.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationSingleEntry},model:{value:(_vm.property.listElement.singleEntry.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.singleEntry, "currentPage", $$v)},expression:"property.listElement.singleEntry.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }