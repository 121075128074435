import FormTTaskInterbankLiability from "../form";

export default {
  name: "CreateTTaskInterbankLiability",
  components: {
    FormTTaskInterbankLiability,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    async addDataTaskInterbankLiability(payload) {
      const data = {
        sourceAccountId: payload.sourceAccountId,
        targetAccountId: payload.targetAccountId,
        documentProofNumber: payload.documentProofNumber,
        description: payload.description,
        transactionCodeId: payload.transactionCodeId,
        nominal: payload.nominal,
      };
      console.log(JSON.stringify(data));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formTTaskInterbankLiability.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-liability",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formTTaskInterbankLiability.routeToPageListTaskInterbankLiability();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formTTaskInterbankLiability.property.animation.submitLoading = false;
      }
    },
  },
};
