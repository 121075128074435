export default {
  name: "DetailMMortgageSlthe",
  data() {
    return {
      property: {
        animation: {
          addMortgageSlthe: {
            isLoading: false,
          },
        },
      },
      identity: {
        mortgageSltheId: "",
      },
      dataForm: {
        setupDate: "",
        marketPrice: "",
        slthePrice: "",
        description: "",
        mortgageCaratId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        karat: [],
      },
    };
  },
  methods: {
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("MORTGAGE_SLTHE_ID");
      this.identity.mortgageSltheId = getTokenFromSession;
    },
    clearIdentifierMortgageSlTheIdOnSessionStorage() {
      sessionStorage.removeItem("MORTGAGE_SLTHE_ID");
    },
    tokenAvailabilityChecker() {
      const token = sessionStorage.getItem("MORTGAGE_SLTHE_ID");
      if (!token) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListMortgageSlthe();
      }
    },
    appendDefaultInput() {
      const defaultOption = { value: "", text: "-- Pilih --" };
      this.options.karat.push(defaultOption);
    },
    routeToPageListMortgageSlthe() {
      this.$router.push("list");
    },
    appendDefaultInputNewDate() {
      this.dataForm.setupDate = new Date().toISOString();
    },
    async getDataMortgageSlthe() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListMortgageSlthe();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "mortgage-metal-price/" + this.identity.mortgageSltheId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.setupDate = resp.data.data.setupDate;
            this.dataForm.marketPrice = resp.data.data.marketPrice;
            this.dataForm.slthePrice = resp.data.data.slthePrice;
            this.dataForm.description = resp.data.data.description;
            // this.dataForm.mortgageCaratId =
            //   resp.data.data.mortgageCarat.mortgageCaratId;
            this.dataForm.createdByUserCode = resp.data.data.createdByUserCode;
            this.dataForm.createdByUserName = resp.data.data.createdByUserName;
            this.dataForm.createdDate = resp.data.data.createdDate;
            this.dataForm.updatedDate = resp.data.data.updatedDate;
            this.dataForm.updatedByUserCode = resp.data.data.updatedByUserCode;
            this.dataForm.updatedByUserName = resp.data.data.updatedByUserName;
            this.dataForm.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.authorizedByUserName =
              resp.data.data.authorizedByUserName;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListMortgageSlthe(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListMortgageSlthe(),
          });
        }
      }
    },

    async getReferencemortgageCarat() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/mortgage-carat",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.mortgageCaratName;
            const value = i.mortgageCaratId;
            this.options.karat.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  beforeMount() {
    this.tokenAvailabilityChecker();
  },
  mounted() {
    this.getIdentityFromSession();
    this.appendDefaultInput();
    this.getDataMortgageSlthe();
    this.getReferencemortgageCarat();
    this.appendDefaultInputNewDate();
  },
  destroyed() {
    this.clearIdentifierMortgageSlTheIdOnSessionStorage();
  },
};
