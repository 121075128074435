const MInterbankAssets = {
  actions: {
    GET_M_INTERBANK_ASSETS_BY_ACCOUNT_NUMBER({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/interbankAssets/find-by-account-number/${payload.accountNumber}`,
            method: "GET",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};

export default MInterbankAssets;
