import FormMTransactionCode from "../form";

export default {
  name: "CreateMTransactionCode",
  components: {
    FormMTransactionCode,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    async addTransactionCode(payload) {
      const data = {
        transactionCode: payload.transactionCode,
        isModul: payload.isModul,
        transactionName: payload.transactionName,
        subledgerDebt: payload.subledgerDebt,
        positionDebt: payload.positionDebt,
        subledgerCredit: payload.subledgerCredit,
        positionCredit: payload.positionCredit,
        isAuthorization: payload.isAuthorization,
        usedOnId: payload.usedOnId,
        isTransactionRecap: payload.isTransactionRecap,
        isValidationPrint: payload.isValidationPrint,
        transactionBookCode: payload.transactionBookCode,
        transactionBookPrint: payload.transactionBookPrint,
        transactionBookDescription: payload.transactionBookDescription,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formMTransactionCodeRef.property.animation.addTransactionCode.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "parameter",
                reqUrl: "transaction-code",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$refs.formMTransactionCodeRef.routeToPageListTransactionCode();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            } finally {
              setTimeout(() => {
                this.$refs.formMTransactionCodeRef.property.animation.addTransactionCode.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
