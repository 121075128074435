export default {
  name: "FormDttot",
  props: {
    isExisting: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        name: "",
        description: "",
        densusCode: "",
        placeOfBirth: "",
        dateOfBirth: "",
        citizen: "",
        address: "",
        suspect: "",
        description: "",
        //user-activity
        createdDate: "",
        createdByUserCode: "",
        createdByUserName: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
      },
      options: {
        suspect: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
    };
  },
  methods: {
    async helperGetSuspectDataForReference() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "/reference/suspect",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.suspect = [{ text: "-- Pilih --", value: "" }]
          resp.data.data.map((i) => {
            const value = i.suspectId;
            // const code = i.suspectCode;
            const text = i.suspectName;
            this.options.suspect.push({ text, value });
          });
        }
      } catch (e) { }
    },
    routeToPageListDttot() {
      this.$router.push("/report/dttot");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        return;
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.name = payload.name,
          this.form.densusCode = payload.densusCode,
          this.form.placeOfBirth = payload.placeOfBirth,
          this.form.dateOfBirth = payload.dateOfBirth,
          this.form.citizen = payload.citizen,
          this.form.address = payload.address,
          this.form.suspect = payload.suspectId,
          this.form.description = payload.description,
          this.form.createdByUserCode = payload.createdByUserCode,
          this.form.createdByUserName = payload.createdByUserName,
          this.form.createdDate = payload.createdDate,
          this.form.updatedDate = payload.updatedDate,
          this.form.updatedByUserCode = payload.updatedByUserCode,
          this.form.updatedByUserName = payload.updatedByUserName
        return;
      }
    },
    submitDttot() {
      this.$emit("submitDttot", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.helperGetSuspectDataForReference();
  },
}