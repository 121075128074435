export default {
  name: "FormGeneratePinAtm",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      dataForm: {
        savingAccountId: "",
        cardNumber: "",
        cardPin: "",
        cardCvv: "",
        cardPassword: "",
        cardTypeId: "",
        description: "",
        cifIdName: "",
        accountNumber: "",
        phoneNumber: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        animation: {
          generatePinAtm: {
            isLoading: false,
          },
        },
      },
      options: {
        cardType: [],
      },
    };
  },
  methods: {
    submitSavingCard() {
      this.$emit("submitSavingCard", this.dataForm);
    },
    routeToPageListLoanGeneratePinAtm() {
      this.$router.push("/saving-card");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListSavingAccountDetail();
        return;
      }
      console.log(params);
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).savingAccountId;
      this.$emit("getMainId", this.identity.mainId);
    },
    getDefaultFormValue(payload) {
      console.log(payload);
      if (this.isExisting) {
        this.dataForm.savingAccountId = payload.savingAccount?.savingAccountId;
        this.dataForm.cardNumber = payload.cardNumber;
        this.dataForm.cardPin = payload.cardPin;
        this.dataForm.cardCvv = payload.cardCvv;
        this.dataForm.cardPassword = payload.cardPassword;
        this.dataForm.cardTypeId = payload.cardType?.cardTypeId;
        this.dataForm.description = payload.description;
        this.dataForm.cifIdName = payload.savingAccount?.mcif?.cifIdName;
        this.dataForm.accountNumber = payload.savingAccount?.accountNumber;
        this.dataForm.phoneNumber = payload.savingAccount?.mcif
          ?.cifMobilePhoneNumber
          ? payload.savingAccount?.mcif?.cifMobilePhoneNumber
          : payload.savingAccount?.mcif?.cifPhoneNumber;
          this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      console.log(payload);
      this.dataForm.savingAccountId = payload?.savingAccountId;
      this.dataForm.cifIdName = payload?.mcif?.cifIdName;
      this.dataForm.accountNumber = payload?.accountNumber;
      this.dataForm.phoneNumber = payload?.mcif?.cifMobilePhoneNumber
        ? payload?.mcif?.cifMobilePhoneNumber
        : payload?.mcif?.cifPhoneNumber;
      console.log(this.dataForm);
    },
    async getReferenceCardType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "card-type",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.cardType = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = index.cardTypeName;
            const value = index.cardTypeId;
            this.options.cardType.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceCardType();
  },
};
