import ModalSearchSavingAccount from "../../../../components/modal-search-saving-account";

export default {
  components: {
    ModalSearchSavingAccount,
  },
  name: "FormOverbookChartOfAccount",
  props: {
    isExisting: false,
  },
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
        },
        modal: {
          findSavingAccountSource: "",
          findSavingAccountTarget: "",
        },
      },
      identity: {
        mainId: "",
      },
      form: {
        sourceAccountId: "",
        sourceAccountName: "",
        sourceOfficeCode: "",
        sourceAccountNumber: "",
        targetAccountId: "",
        targetAccountName: "",
        targetOfficeCode: "",
        transactionDate: "",
        targetAccountNumber: "",
        documentProofNumber: "",
        description: "",
        transactionCode: "",
        nominal: 0,
        referenceNumber: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        office: [],
        chartOfAccount: [],
        transactionCode: [],
      },
    };
  },
  watch: {},
  methods: {
    onChangeTransactionCode() {
      this.form.sourceAccountId = "";
      this.form.sourceAccountName = "";
      this.form.sourceOfficeCode = "";
      this.form.sourceAccountNumber = "";
      this.form.targetAccountId = "";
      this.form.targetAccountName = "";
      this.form.targetOfficeCode = "";
      this.form.targetAccountNumber = "";
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.transactionCode = payload.transactionCode;
        this.form.transactionDate = payload.createdDate;
        this.form.sourceAccountId = payload.sourceAccountId;
        this.form.sourceAccountName = payload.sourceAccountName;
        this.form.sourceOfficeCode = payload.sourceOfficeCode;
        this.form.sourceAccountNumber = payload.sourceAccountNumber;
        this.form.targetAccountId = payload.targetAccountId;
        this.form.targetAccountName = payload.targetAccountName;
        this.form.targetOfficeCode = payload.targetOfficeCode;
        this.form.targetAccountNumber = payload.targetAccountNumber;
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.description = payload.description;
        this.form.nominal = payload.nominal;
        this.form.referenceNumber = payload.referenceNumber;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    chartOfAccountDescFinder(value) {
      return this.options.chartOfAccount.find((i) => i.value === value)
        .description;
    },
    backToList() {
      this.$router.push("/overbook-chart-of-account");
    },
    submit() {
      this.$emit("submit", this.form);
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "office/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.officeName} - ${index.officeCode}`;
            const value = index.officeCode;
            const officeName = index.officeName;
            this.options.office.push({
              text,
              value,
              officeName,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              page: 0,
              name: "",
              transactionCodes: "2002,2011,2019",
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.transactionCode = [{ value: "", text: "-- Pilih --" }];
          let index = 0;
          while (
            resp.data.data.content.length !==
            this.options.transactionCode.length - 1
          ) {
            const text = `${resp.data.data.content[index].transactionName} - ${resp.data.data.content[index].transactionCode}`;
            const value = resp.data.data.content[index].transactionCode;
            this.options.transactionCode.push({
              text,
              value,
            });
            index++;
          }
          this.checkingMainId();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.chartOfAccount = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountId;
            const description = index.description;
            this.options.chartOfAccount.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      this.identity.mainId =
        this.isExisting === true ? this.decryptBASE64(params) : "";
      this.$emit("getMainId", this.identity.mainId);
      this.form.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.getReferenceOffice();
    this.getReferenceTransactionCode();
    this.getReferenceChartOfAccount();
    this.checkingMainId();
  },
};
