import Notification from "../../../../components/notifications";

export default {
  name: "AddTLoanAccountDroppingOverbook",
  data() {
    return {
      identity: {
        loanAccountId: "",
      },
      dataForm: {
        sourceAccount: {
          loanAccountNumber: "",
          cifIdName: "",
          officeName: "",
          nominalLoan: "",
        },
      },
    };
  },
  methods: {
    handlePayloadTerminal() {
      const getTokenFromSession = sessionStorage.getItem("loan-account-id");

      this.identity.loanAccountId = getTokenFromSession;
    },
    async helperGetLoanAccountDataById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.handleRouteToPageListLoanAccountDroppingOverbook();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "transaction-loan/findById" + this.identity.loanAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.sourceAccount.loanAccountNumber =
              resp.data.data.loanAccountNumber;
            this.dataForm.sourceAccount.cifIdName = resp.data.data.mcif
              ? resp.data.data.mcif.cifIdName
              : "";
            this.dataForm.sourceAccount.officeName = resp.data.data.moffice
              ? resp.data.data.moffice.officeName
              : "";
            this.dataForm.sourceAccount.nominalLoan =
              resp.data.data.nominalLoan;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.handleRouteToPageListLoanAccountDroppingOverbook(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.handleRouteToPageListLoanAccountDroppingOverbook(),
          });
        }
      }
    },
    handleRouteToPageListLoanAccountDroppingOverbook() {
      this.$router.push("list");
    },
    helperTokenAvailabilityChecker() {
      const token = sessionStorage.getItem("loan-account-id");
      if (!token) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.handleRouteToPageListLoanAccountDroppingOverbook();
      }
    },
    helperClearIdentifierLoanAccountIdOnSessionStorage() {
      sessionStorage.removeItem("loan-account-id");
    },
  },
  beforeMount() {
    this.helperTokenAvailabilityChecker();
  },
  mounted() {
    this.handlePayloadTerminal();
    this.helperGetLoanAccountDataById();
  },
  destroyed() {
    this.helperClearIdentifierLoanAccountIdOnSessionStorage();
  },
};
