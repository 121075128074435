import FormTDepositAccountDetailFundAllocated from "../form";

export default {
  name: "CreateTDepositAccountDetailFundAllocated",
  components: {
    FormTDepositAccountDetailFundAllocated,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.helperGetDepositFundAllocatedById();
    },
    async helperGetDepositFundAllocatedById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTDepositAccountFundAllocatedRef.handleRouteToPageListDepositFundAllocated();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "deposit-account/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTDepositAccountFundAllocatedRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTDepositAccountFundAllocatedRef.handleRouteToPageListDepositFundAllocated(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan !`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTDepositAccountFundAllocatedRef.handleRouteToPageListDepositFundAllocated(),
          });
        }
      }
    },
    async handleAddDepositAccountDetail(payload) {
      const data = {
        nominal: payload.depositFundAllocated.nominal,
        documentProofNumber: payload.depositFundAllocated.documentProofNumber,
        description: payload.depositFundAllocated.description,
        depositAccountId: this.identity.mainId,
        transactionCodeId: payload.depositFundAllocated.transactionCodeId,
        type: "CASH",
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Proses Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formTDepositAccountFundAllocatedRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/fund-allocated",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formTDepositAccountFundAllocatedRef.handleRouteToPageListDepositFundAllocated();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formTDepositAccountFundAllocatedRef.property.animation.submitLoading = false;
      }
    },
  },
};
