var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-center items-center flex-row w-full"},[_c('div',{staticClass:"flex justify-between items-center flex-wrap flex-row w-full"},[_c('div',{staticClass:"flex w-full flex-wrap items-center justify-between",staticStyle:{"max-width":"280px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nama Jurnal")]),_c('b-input',{staticStyle:{"max-width":"190px"},attrs:{"size":"is-small","lazy":true},on:{"input":_vm.searchByJournalNameJournalTemplate},model:{value:(_vm.property.filterDto.journalName),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "journalName", $$v)},expression:"property.filterDto.journalName"}})],1)]),_c('div',{staticClass:"flex justify-end flex-col"},[_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",attrs:{"disabled":!_vm.$store.getters['getActionsInsert'],"label":"Template","icon-left":"plus","size":"is-small"},on:{"click":_vm.routeToPageAddJurnalTemplate}})],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.journalTemplate,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.journalTemplate.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.journalTemplate.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.journalTemplate.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"journalTemplateId","label":"Template Id"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.journalTemplateId))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"journalTemplateName","label":"Nama Jurnal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.journalTemplateName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"journalTemplateItem","label":"Item Jurnal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.journalTemplateItem))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"mchartOfAccountDto.chartOfAccountCode","label":"Akun COA"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.mchartOfAccountDto ? props.row.mchartOfAccountDto.chartOfAccountCode : ""))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"description","label":"Keterangan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.description))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"mtransactionCodeResponseDto","label":"Kode Transaksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.mtransactionCodeResponseDto ? props.row.mtransactionCodeResponseDto.transactionCode : "")+" - "+_vm._s(props.row.mtransactionCodeResponseDto ? props.row.mtransactionCodeResponseDto.transactionName : ""))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"position","label":"Mutasi ( Dr / Cr )"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.position === "C" ? "Credit" : "Debit"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsUpdate'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.routeToPageEditJurnalTemplate(props)}}},[_vm._v(" Rincian ")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDelete'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.deleteDataJurnalTemplate(props)}}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsAuthorize'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.authorizationJurnalTemplate(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.journalTemplate.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.journalTemplate.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationJurnalTemplate},model:{value:(_vm.property.listElement.journalTemplate.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.journalTemplate, "currentPage", $$v)},expression:"property.listElement.journalTemplate.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }