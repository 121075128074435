import { data } from "autoprefixer";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import moment from "moment";
import DepositDisburseListPdf from "@/components/page/deposit-disburse-list-pdf";

export default {
  name: "ListTDepositDisbursement",
  components: {
    DepositDisburseListPdf,
  },
  data() {
    return {
      dataForm: {
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
        previewData: "",
        createdDate: "",
        officeName: "",
      },
      session: {
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        modal: {
          showModalCheckingDepositAccount: false,
          showModalResultListDepositAccount: false,
          showModalPrint: false,
        },
        listElement: {
          depositDisburse: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          depositAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          referenceNumber: "",
          depositAccountNumber: "",
          dateFrom: "",
          dateTo: "",
        },
        animation: {
          isDownloadingFile: false,
          checkingDepositAccount: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
      },
      options: {
        eksport: [],
      },
      table: {
        header: {
          depositDisburse: [],
          depositAccount: [],
        },
        data: {
          depositDisburse: [],
          depositAccount: [],
        },
      },
    };
  },
  methods: {
    Moment: moment,
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.depositDisburseListPdfRef.onOpenPreview({
        tableData: this.table.data.depositDisburse,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          officeName: this.dataForm.officeName,
          filteredDate: this.dataForm.createdDate
            ? this.dataForm.createdDate
            : `${moment().format("DD-MM-YYYY")} `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        depositAccountNumber: this.property.filterDto.depositAccountNumber,
        referenceNumber: this.property.filterDto.referenceNumber,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        statusId: "",
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/disburse_download/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Pencairan_Deposito.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async searchDepositDisburse() {
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/disburse",
          payload: {
            referenceNumber: this.property.filterDto.referenceNumber,
            depositAccountNumber: this.property.filterDto.depositAccountNumber,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositDisburse = resp.data.data.content;
            this.property.listElement.depositDisburse.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDepositDisburse(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositDisburse(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositDisburse.downloading = false;
        }, timeout);
      }
    },
    helperGetUSerDataFromSession() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.dataForm.officeName = getUserPayloadFromSession.officeName;
    },
    async cetakSlipTrx() {
      this.property.animation.cetakSlip.isLoading = true;
      let pdf = new jsPDF("potrait", "mm", "a4");
      setTimeout(() => {
        html2canvas(document.querySelector("#table-trx"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-trx").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 10, 9);
          pdf.autoPrint();
          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-trx").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          this.property.modal.showModalPrint = false;
        });
      }, 200);
    },
    async deleteDepositDisburse(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account-detail/" + props.row.depositAccountDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositDisburse.currentPage = 1;
                  this.property.filterDto.referenceNumber = "";
                  this.property.filterDto.depositAccountNumber = "";
                  this.property.listElement.depositDisburse.rows = 0;
                  this.helperGetDataTableDepositDisburse();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async authorizationDepositDisburse(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account-detail/disburse/authorized/" +
                  props.row.depositAccountDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositDisburse.currentPage = 1;
                  this.property.filterDto.referenceNumber = "";
                  this.property.filterDto.depositAccountNumber = "";
                  this.property.listElement.depositDisburse.rows = 0;
                  this.helperGetDataTableDepositDisburse();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageAddDepositDisburse(props) {
      this.$router.push(
        `/deposit/deposit-account-disburse/new?refId=${this.encryptBASE64(
          JSON.stringify({
            depositAccountNumber: props.row.depositAccountNumber,
          })
        )}`
      );
    },
    routeToPageDetailDepositDisburse(props) {
      this.$router.push(
        `/deposit/deposit-account-disburse/${this.encryptBASE64(
          `${props.row.depositAccountDetailId}`
        )}`
      );
    },
    handleCatchErrorDepositDisburse(error) {
      console.log(error.response);
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.rows = 0;
      this.property.listElement.depositDisburse.currentPage = 1;
      this.property.listElement.depositDisburse.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },

    async changePaginationDepositDisburse(event) {
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/disburse",
          payload: {
            depositAccountNumber: this.property.filterDto.depositAccountNumber,
            referenceNumber: this.property.filterDto.referenceNumber,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.depositDisburse.perPage,
            sortBy: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositDisburse = resp.data.data.content;
            this.property.listElement.depositDisburse.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDepositDisburse(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositDisburse(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositDisburse.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableDepositDisburse() {
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/disburse",
          payload: {
            referenceNumber: "",
            depositAccountNumber: "",
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositDisburse = resp.data.data.content;
            this.property.listElement.depositDisburse.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDepositDisburse(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositDisburse(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositDisburse.downloading = false;
        }, timeout);
      }
    },
    failedGetDataDepositDisburse(resp) {
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.rows = 0;
      this.property.listElement.depositDisburse.message = resp.data.message;
    },
    async checkingAvailabilityDepositAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.depositAccount.accountNumber = this.dataForm.depositAccount.accountNumber;
        this.session.depositAccount.cifIdName = this.dataForm.depositAccount.cifIdName;
        this.dataForm.depositAccount.accountNumber = "";
        this.dataForm.depositAccount.cifIdName = "";
        const timeout = 500;
        try {
          this.property.animation.checkingDepositAccount.isLoading = true;
          this.property.listElement.depositAccount.downloading = false;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "deposit-account/disburse/simple-list",
            payload: {
              depositAccountNumber: this.session.depositAccount.accountNumber,
              cifIdName: this.session.depositAccount.cifIdName,
              depositBilyetNumber: "",
              page: 0,
              size: this.property.listElement.depositAccount.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingDepositAccount = false;
              this.property.modal.showModalCheckingResultDepositAccount = true;
              this.table.data.depositAccount = resp.data.data.content;
              this.property.listElement.depositAccount.rows =
                resp.data.data.totalElements;
            } else if (resp.data.code === "DATA_NOT_FOUND") {
              this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
              this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
              this.session.depositAccount.accountNumber = "";
              this.session.depositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `Nomor Rekening Tidak Tersedia!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
              this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
              this.session.depositAccount.accountNumber = "";
              this.session.depositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
          this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
          this.session.depositAccount.accountNumber = "";
          this.session.depositAccount.cifIdName = "";
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingDepositAccount.isLoading = false;
          }, timeout);
        }
      }
    },
    handleErrorDepositAccount(error) {
      this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
      this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
      this.session.depositAccount.accountNumber = "";
      this.session.depositAccount.cifIdName = "";
      console.log(error.response);
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.currentPage = 1;
      this.property.listElement.depositAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationDepositAccount(event) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/disburse/simple-list",
          payload: {
            depositAccountNumber: this.session.depositAccount.accountNumber,
            cifIdName: this.session.depositAccount.cifIdName,
            page: event - 1,
            depositBilyetNumber: "",
            size: this.property.listElement.depositAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.depositAccount.rows =
              resp.data.data.totalElements;
            this.table.data.depositAccount = resp.data.data.content;
          } else {
            this.failedGetDepositAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout);
      }
    },
    showModalPrintDepositAccountDisburse(props) {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.property.modal.showModalPrint = true;
      this.dataForm.previewData = props.row;
      Object.assign(this.dataForm.previewData, {
        printInformation: `${
          getUserPayloadFromSession.userName
        } ${moment().format("DD-MM-YYYY")} ${moment().format("HH:mm:ss")}`,
      });
    },
    failedGetDepositAccount(resp) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.message = resp.data.message;
    },
    clearModalCheckingDepositAccount() {
      this.dataForm.depositAccount.accountNumber = "";
      this.dataForm.depositAccount.cifIdName = "";
    },
    clearModalResultCheckingDepositAccount() {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.perPage = 5;
      this.property.listElement.depositAccount.currentPage = 1;
    },
    closeModalCheckingDepositAccount() {
      this.property.modal.showModalCheckingDepositAccount = false;
      this.clearModalCheckingDepositAccount();
    },
  },
  mounted() {
    moment.locale("ID");
    this.helperGetUSerDataFromSession();
    this.helperGetDataTableDepositDisburse();
  },
};
