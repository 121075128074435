class fieldPropertyScheme {
  constructor() {
    this.type = "";
    this.label = "";
    this.message = "";
    this.models = "";
    this.disabled = false;
    this.show = true;
    this.event = new eventDto();
  }
}

class eventDto {
  constructor() {
    this.type = "input";
    this.action = () => {};
  }
}

class InputModels extends fieldPropertyScheme {
  constructor() {
    super();
    this.mask = "";
    this.placeholder = "";
    this.formater = null;
    this.value = null;
    this.isInformationComponent = false;
    this.clicked = () => {};
    this.appendText = "";
  }
}

class TextAreaModels extends fieldPropertyScheme {
  constructor() {
    super();
    this.placeholder = "";
  }
}

class MoneyModels extends fieldPropertyScheme {
  constructor() {
    super();
    this.placeholder = "";
    this.appendText = "";
  }
}

class SelectModels extends fieldPropertyScheme {
  constructor() {
    super();
    this.appendText = "";
    this.selectProperty = new SelectPropertyDto();
  }
}

class SelectPropertyDto {
  constructor() {
    this.options = [];
    this.bindValue = "";
    this.bindText = () => {};
  }
}

class RadioModels extends fieldPropertyScheme {
  constructor() {
    super();
    this.radioProperty = new RadioPropertyDto();
  }
}

class RadioPropertyDto {
  constructor() {
    this.options = [];
  }
}

class CheckboxModels extends fieldPropertyScheme {
  constructor() {
    super();
    this.checkboxProperty = new CheckboxPropertyDto();
  }
}

class CheckboxPropertyDto {
  constructor() {
    this.label = "";
    this.falseValue = false;
    this.trueValue = true;
  }
}

class DateInputModels extends fieldPropertyScheme {
  constructor() {
    super();
    this.placeholder = "";
    this.formater = null;
    this.dateParser = (date) => {
      return new Date(date).toISOString();
    };
  }
}

export function ModelMapper(type) {
  if (type === "text" || type === "number" || type === "password")
    return new InputModels();
  if (type === "money") return new MoneyModels();
  if (type === "textarea") return new TextAreaModels();
  if (type === "select" || type === "model-list-select")
    return new SelectModels();
  if (type === "date") return new DateInputModels();
  if (type === "radio") return new RadioModels();
  if (type === "checkbox") return new CheckboxModels();
}
