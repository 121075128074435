import Moment from "moment";
export default {
  name: "ManualTriggerInstallment",
  data() {
    return {
      form: {
        dateFrom: "",
      },
      loading: false,
    };
  },
  methods: {
    setDefaultDate() {
      Moment.locale("ID");
      this.form.dateFrom = Moment().format("YYYY-MM-DD");
    },
    async onClicked() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Peringatan ! Proses Debet Manual Hanya Boleh Dilakukan Pada Saat Akhir Hari , Jika Sudah Di Lakukan Harap Cek Pada Jurnal Untuk Memastikan Hasil Manual Triger Terjumlahkan ",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.loading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "loan",
          reqUrl: `loan-installment/manual-triger-autodebit-installment?dateFrom=${this.form.dateFrom}`,
        });
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.toast.open(
            this.$NotificationUtils.success
          );
          return;
        }
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.setDefaultDate();
  },
};
