import FormTDepositAccountDetailFundAllocated from "../form";

export default {
  name: "ExistingTDepositAccountDetailFundAllocated",
  components: {
    FormTDepositAccountDetailFundAllocated,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.helperGetDepositAccountDetailDataById();
    },
    async helperGetDepositAccountDetailDataById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "deposit-account-detail/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTDepositAccountFundAllocatedRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTDepositAccountFundAllocatedRef.handleRouteToPageListDepositFundAllocated(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTDepositAccountFundAllocatedRef.handleRouteToPageListDepositFundAllocated(),
          });
        }
      }
    },
  },
};
