import FormProductLedger from "../form";

export default {
  name: "ExistingProductLedger",
  components: {
    FormProductLedger,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getProductLedgerById();
    },
    async getProductLedgerById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formMProductLedgerRef.routeToPageList();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "product-ledger/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$refs.formMProductLedgerRef.getDefaultFormValue(resp.data.data);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.$refs.formMProductLedgerRef.routeToPageList(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.$refs.formMProductLedgerRef.routeToPageList(),
        });
      }
    },
    async editInterbankAssets(payload) {
      var data = {
        productLedgerId: payload.productLedgerId,
        productLedgerCode: payload.productLedgerCode,
        productLedgerName: payload.productLedgerName,
        description: payload.description,
        productCategoryId: payload.productCategoryId,
        productCategoryCode: payload.productCategoryCode,
        productCategoryName: payload.productCategoryName,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formMProductLedgerRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl: "product-ledger/" + this.identity.mainId,
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formMProductLedgerRef.property.animation.submitLoading = false;
      }
    },
  },
};
