export default {
  name: "ListMInterbankAssets",
  data() {
    return {
      property: {
        listElement: {
          interbankAssets: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          interbankAssetsTypeId: "",
          accountNumber: "",
          accountName: "",
        },
      },
      options: {
        aba: [{ value: "", text: "Semua Kategori" }],
      },
      table: {
        data: {
          interbankAssets: [],
        },
      },
    };
  },
  methods: {
    routeToPageAddInterbankAssets() {
      this.$router.push("/interbank-assets/new");
    },
    async authorizationInterbankAssets(props) {
      console.log(props)
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "interbankAssets/authorized/" + props.row.interbankAssetsId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.interbankAssets.currentPage = 1;
                  this.property.filterDto.interbankAssetsTypeId = "";
                  this.property.filterDto.accountName = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.listElement.interbankAssets.rows = 0;
                  this.getInterbankAssets();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageDetailInterbankAssets(props) {
      this.$router.push(
        `/interbank-assets/${this.encryptBASE64(
          `${props.row.interbankAssetsId}`
        )}`
      );
    },
    routeToPageEditInterbankAssets(props) {
      this.$router.push(
        `/interbank-assets/${this.encryptBASE64(
          `${props.row.interbankAssetsId}`
        )}`
      );
    },

    async findInterbankAssets() {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankAssets",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            accountName: this.property.filterDto.accountName,
            interbankAssetsTypeId: this.property.filterDto.interbankAssetsTypeId,
            page: 0,
            size: this.property.listElement.interbankAssets.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.interbankAssets = resp.data.data.content;
            this.property.listElement.interbankAssets.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.interbankAssets.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetInterbankAssets(resp);
          }
        }, timeout);
      } catch (error) {
        this.handleErrorInterbankAssets(error);
      } finally {
        setTimeout(() => {
          this.property.listElement.interbankAssets.downloading = false;
        }, timeout + 500);
      }
    },
    async deleteInterbankAssets(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "interbankAssets/" + props.row.interbankAssetsId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.interbankAssets.currentPage = 1;
                  this.property.filterDto.interbankAssetsTypeId = "";
                  this.property.filterDto.accountName = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.listElement.interbankAssets.rows = 0;
                  this.getInterbankAssets();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationInterbankAssets(event) {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankAssets",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            accountName: this.property.filterDto.accountName,
            interbankAssetsTypeId: this.property.filterDto.interbankAssetsTypeId,
            page: event - 1,

            size: this.property.listElement.interbankAssets.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.interbankAssets = resp.data.data.content;
            this.property.listElement.interbankAssets.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetInterbankAssets(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorInterbankAssets(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.interbankAssets.downloading = false;
        }, timeout);
      }
    },

    handleErrorInterbankAssets(error) {
      console.log(error.response);
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.rows = 0;
      this.property.listElement.interbankAssets.currentPage = 1;
      this.property.listElement.interbankAssets.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },

    getReferenceInterbankAssetsType() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "interbank-assets-type",
        })
        .then((response) => {
          response.data.data.map((index) => {
            const value = index.interbankAssetsTypeId;
            const text = index.interbankAssetsTypeName;
            this.options.aba.push({
              value,
              text,
            });
          });
        });
    },

    async getInterbankAssets() {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankAssets",
          payload: {
            accountNumber: "",
            accountName: "",
            interbankAssetsTypeId: "",
            page: 0,
            size: this.property.listElement.interbankAssets.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.interbankAssets = resp.data.data.content;
            this.property.listElement.interbankAssets.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetInterbankAssets(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorInterbankAssets(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.interbankAssets.downloading = false;
        }, timeout);
      }
    },
    failedGetInterbankAssets(resp) {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.rows = 0;
      this.property.listElement.interbankAssets.message = resp.data.message;
    },
  },
  mounted() {
    this.getInterbankAssets();
    this.getReferenceInterbankAssetsType();
  },
};
