import ModalSearchSavingAccount from "../../../../components/modal-search-saving-account";

export default {
  name: "AddTCashCollection",
  components: {
    ModalSearchSavingAccount,
  },
  data() {
    return {
      identity: {
        savingAccountId: "",
        mainId: "",
      },
      identifier: {
        targetAccountSelectedForEdit: "",
      },
      property: {
        animation: {
          uploadMultiAccount: {
            isLoading: false,
          },
          addMultiAccount: {
            isLoading: false,
          },
          checkingSavingAccount: {
            sourceAccountNumber: {
              isLoading: false,
            },
            destinationAccountNumberAdd: {
              isLoading: false,
            },
            destinationAccountNumberEdit: {
              isLoading: false,
            },
          },
        },
        modal: {
          showModalAddTargetAccount: false,
          showModalEditTargetAccount: false,
          showModalFindSavingAccount: false,
          showModalFindSavingAccountMultiAdd: false,
          showModalFindSavingAccountMultiEdit: false,
        },
      },
      dataForm: {
        file: null,
        nominal: 0,
        transactionCode: "",
        transactionDate: "",
        description: "",
        sourceAccountNumber: "",
        cifIdName: "",
        accountType: "",
        accountTypeMulti: "",
        cifOfficeName: "",
        documentProofNumber: "",
        detail: [],
        collectorId: "",
        addTargetAccount: {
          accountType: "",
          nominal: 0,
          description: "",
          cifIdName: "",
          destinationAccountNumber: "",
        },
        editTargetAccount: {
          accountType: "",
          nominal: 0,
          description: "",
          cifIdName: "",
          identifierId: "",
          destinationAccountNumber: "",
        },
      },
      options: {
        transactionCode: [],
        interbankAssets: [],
        collector: [],
        accountType: [
          {
            text: "-- Pilih --",
            value: "",
            disabled: false,
          },
          {
            text: "Rekening Tabungan",
            value: "TAB",
            disabled: false,
          },
          {
            text: "ABA",
            value: "ABA",
            disabled: false,
          },
          {
            text: "SBB ",
            value: "SBB",
            disabled: true,
          },
        ],
      },
      table: {
        data: {},
      },
    };
  },
  methods: {
    showModalFindSavingAccount(type) {
      switch (type) {
        case "source":
          this.property.modal.showModalFindSavingAccount = true;
          break;
        case "multi-add":
          this.property.modal.showModalFindSavingAccountMultiAdd = true;
          this.property.modal.showModalAddTargetAccount = false;
          break;
        case "multi-edit":
          this.property.modal.showModalFindSavingAccountMultiEdit = true;
          this.property.modal.showModalEditTargetAccount = false;
          break;
        default:
          break;
      }
    },
    selectSavingAccountSource(props) {
      this.dataForm.cifIdName = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
      this.dataForm.cifOfficeName = props.row.officeName;
      this.dataForm.sourceAccountNumber = props.row.accountNumber;
    },
    selectSavingAccountMultiAdd(props) {
      this.property.modal.showModalAddTargetAccount = true;
      this.dataForm.addTargetAccount.destinationAccountNumber =
        props.row.accountNumber;
      this.dataForm.addTargetAccount.cifIdName = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
    },
    selectSavingAccountMultiEdit(props) {
      this.property.modal.showModalEditTargetAccount = true;
      this.dataForm.editTargetAccount.destinationAccountNumber =
        props.row.accountNumber;
      this.dataForm.editTargetAccount.cifIdName = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
    },
    closeModalSavingAccountMultiAdd(property) {
      this.property.modal.showModalFindSavingAccountMultiAdd = property;
      this.property.modal.showModalAddTargetAccount = true;
    },
    closeModalSavingAccountMultiEdit(property) {
      this.property.modal.showModalFindSavingAccountMultiEdit = property;
      this.property.modal.showModalEditTargetAccount = true;
    },
    changeAccountTypeSource() {
      this.dataForm.sourceAccountNumber = "";
      this.dataForm.cifIdName = "";
      this.dataForm.cifOfficeName = "";
    },
    resetDetail() {
      this.dataForm.detail = [];
      this.calculateTotalNominal();
    },
    async uploadFile() {
      let formData = new FormData();
      formData.append("file", this.dataForm.file);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.uploadMultiAccount.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA_FILE",
          reqUrl: "t-multi-account/upload-cash-collection",
          endPoint: "transaction",
          payload: formData,
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((data) => {
            const dto = {
              nominal: data.nominal ? data.nominal : 0,
              description: data.description,
              destinationAccountNumber: data.accountNumber,
              cifIdName: data.name,
              accountType: data.accountType,
              identifierId: `${data.accountNumber}`,
            };
            this.dataForm.detail.push(dto);
            this.calculateTotalNominal();
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.uploadMultiAccount.isLoading = false;
      }
    },
    closeModalEditTargetAccount() {
      this.property.modal.showModalEditTargetAccount = false;
      this.dataForm.editTargetAccount.nominal = 0;
      this.dataForm.editTargetAccount.description = "";
      this.dataForm.editTargetAccount.destinationAccountNumber = "";
      this.dataForm.editTargetAccount.cifIdName = "";
      this.dataForm.editTargetAccount.accountType = "";
    },
    calculateTotalNominal() {
      this.dataForm.nominal = 0;
      this.dataForm.detail.map((index) => {
        this.dataForm.nominal = this.dataForm.nominal + index.nominal;
      });
    },
    getTargetAccountByIdentifier(props) {
      this.dataForm.detail.map((index) => {
        if (index.identifierId === props.row.identifierId) {
          this.identifier.targetAccountSelectedForEdit = props.row.identifierId;
          this.dataForm.editTargetAccount.accountType = index.accountType;
          this.dataForm.editTargetAccount.nominal = index.nominal;
          this.dataForm.editTargetAccount.description = index.description;
          this.dataForm.editTargetAccount.cifIdName = index.cifIdName;
          this.dataForm.editTargetAccount.identifierId = index.identifierId;
          this.dataForm.editTargetAccount.destinationAccountNumber =
            index.destinationAccountNumber;
          this.property.modal.showModalEditTargetAccount = true;
        }
      });
    },
    showModalAddTargetAccount() {
      if (this.dataForm.accountTypeMulti === "") {
        this.$buefy.toast.open({
          message: `Harap Isi Jenis Rekening Multi Terlebih Dahulu`,
          type: "is-danger",
          duration: 2000,
          position: "is-top",
          queue: false,
        });
        return;
      }
      this.property.modal.showModalAddTargetAccount = true;
      this.dataForm.addTargetAccount.accountType = this.dataForm.accountTypeMulti;
    },
    closeModalAddTargetAccount() {
      this.property.modal.showModalAddTargetAccount = false;
      this.dataForm.addTargetAccount.nominal = 0;
      this.dataForm.addTargetAccount.description = "";
      this.dataForm.addTargetAccount.destinationAccountNumber = "";
      this.dataForm.addTargetAccount.cifIdName = "";
      this.dataForm.addTargetAccount.accountType = "";
    },
    addTargetAccount() {
      const dto = {
        nominal: this.dataForm.addTargetAccount.nominal,
        description: this.dataForm.addTargetAccount.description,
        destinationAccountNumber: this.dataForm.addTargetAccount
          .destinationAccountNumber,
        accountType: this.dataForm.addTargetAccount.accountType,
        cifIdName: this.dataForm.addTargetAccount.cifIdName,
        identifierId: `${this.dataForm.addTargetAccount.destinationAccountNumber}`,
      };
      this.dataForm.detail.push(dto);
      this.closeModalAddTargetAccount();
      this.calculateTotalNominal();
    },
    editTargetAccount() {
      this.dataForm.detail.map((index) => {
        if (
          index.identifierId === this.identifier.targetAccountSelectedForEdit
        ) {
          index.nominal = this.dataForm.editTargetAccount.nominal;
          index.description = this.dataForm.editTargetAccount.description;
          index.destinationAccountNumber = this.dataForm.editTargetAccount.destinationAccountNumber;
          index.cifIdName = this.dataForm.editTargetAccount.cifIdName;
          index.accountType = this.dataForm.editTargetAccount.accountType;
          index.identifierId = `${this.dataForm.editTargetAccount.destinationAccountNumber}`;
        }
      });
      this.closeModalEditTargetAccount();
      this.calculateTotalNominal();
    },
    deleteTargetAccount(props) {
      this.$buefy.dialog.confirm({
        message: "Hapus Rekening Tujuan ?",
        type: "is-success",
        onConfirm: async () => {
          var filterUnlessSelected = this.dataForm.detail.filter(
            (index) => index.identifierId !== props.row.identifierId
          );
          this.dataForm.detail = filterUnlessSelected;
          this.calculateTotalNominal();
        },
      });
    },
    async addMultiAccount() {
      const payload = {
        transactionCode: this.dataForm.transactionCode,
        description: this.dataForm.description,
        sourceAccountNumber: this.dataForm.sourceAccountNumber,
        documentProofNumber: this.dataForm.documentProofNumber,
        detail: this.dataForm.detail,
        accountType: this.dataForm.accountType,
        nominal: this.dataForm.nominal,
        collectorId: this.dataForm.collectorId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addMultiAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "t-multi-account/cash-collection",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.routeToPageListMultiAccount();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            } finally {
              setTimeout(() => {
                this.property.animation.addMultiAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    routeToPageListMultiAccount() {
      this.$router.push("list");
    },
    changeAccountNumberAddMultiAba() {
      this.dataForm.addTargetAccount.cifIdName = "";
      const findAvailibilityAccount = this.dataForm.detail.filter(
        (index) =>
          index.identifierId ===
          this.dataForm.addTargetAccount.destinationAccountNumber
      );
      if (findAvailibilityAccount.length > 0) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: "No. Rekening Sudah Ada Di Daftar",
          confirmText: "Ok",
          type: "is-danger",
        });
        this.dataForm.addTargetAccount.destinationAccountNumber = "";
        return;
      }
      this.options.interbankAssets.map((index) => {
        this.dataForm.addTargetAccount.cifIdName = index.accountName;
      });
    },
    changeAccountNumberEditMultiAba() {
      this.dataForm.editTargetAccount.cifIdName = "";
      const findAvailibilityAccount = this.dataForm.detail.filter(
        (index) =>
          index.identifierId ===
            this.dataForm.editTargetAccount.destinationAccountNumber &&
          index.identifierId !== this.dataForm.editTargetAccount.identifierId
      );
      if (findAvailibilityAccount.length > 0) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: "No. Rekening Sudah Ada Di Daftar",
          confirmText: "Ok",
          type: "is-danger",
        });
        this.dataForm.editTargetAccount.destinationAccountNumber = "";
        return;
      }
      this.options.interbankAssets.map((index) => {
        this.dataForm.editTargetAccount.cifIdName = index.accountName;
      });
    },
    async getReferenceInterbankAsset() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if ((resp.data.code = "SUCCESS")) {
          this.options.interbankAssets = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.interbankAssetsId;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = index.accountName;
            const accountBranch = `${index.bankName} - Cabang ${index.accountBranch}`;
            this.options.interbankAssets.push({
              text,
              value,
              accountName,
              accountBranch,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              name: "",
              page: 0,
              menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_MENU_ACCESS_MULTI_ACCOUNT,
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCode;
            const description = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.transactionCode.push({
              text,
              value,
              description,
              transactionCode,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceCollector() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.collector.push({ text, value });
          });
        }
      } catch (error) {}
    },
    defaultDateNow() {
      this.dataForm.transactionDate = new Date().toISOString();
    },
  },
  mounted() {
    this.getReferenceTransactionCode();
    this.getReferenceInterbankAsset();
    this.getReferenceCollector();
    this.defaultDateNow();
  },
};
