export default {
  name: "ListTBalanceCash",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
  },
  data() {
    return {
      property: {
        listElement: {
          balanceCash: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "DATA NOT FOUND",
            downloading: false,
          },
        },
        filterDto: {
          dateFrom: "",
          dateTo: "",
          page: 0,
          size: 10,
          userName: "",
          statusId: "",
          officeCode: "",
          tellerUserCode: "",
        },
      },
      table: {
        data: {
          balanceCash: [],
        },
      },
      options: {
        statusAccount: [],
        office: [],
        user: [],
      },
    };
  },
  methods: {
    async findBalanceCash() {
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "balance-cash",
          payload: {
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusId,
            officeCode: this.property.filterDto.officeCode,
            tellerUserCode: this.property.filterDto.tellerUserCode,
            page: 0,
            size: this.property.listElement.balanceCash.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.balanceCash = resp.data.data.content;
            this.property.listElement.balanceCash.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataBalanceCash(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorBalanceCash(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.balanceCash.downloading = false;
        }, timeout);
      }
    },

    routeToPageBalanceCash() {
      this.$router.push("balance-cash/new");
    },
    // getUserDataFromSession() {
    //   const data = JSON.parse(
    //     this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
    //   );
    //   this.property.filterDto.userName = data.userName;
    // },
    routeToPageDetailBalanceCash(props) {
      sessionStorage.setItem("BALANCE_CASH_ID", props.row.balanceCashId);
      this.$router.push("detail");
      this.$router.push(
        `/balance-cash/${this.encryptBASE64(`${props.row.balanceCashId}`)}`
      );
    },
    async getBalanceCash() {
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "balance-cash",
          payload: {
            dateFrom: "",
            dateTo: "",
            statusId: "",
            officeCode: "",
            tellerUserCode: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.balanceCash = resp.data.data.content;
            this.property.listElement.balanceCash.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataBalanceCash(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorBalanceCash(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.balanceCash.downloading = false;
        }, timeout);
      }
    },
    async deleteBalanceCash(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "balance-cash/" + props.row.balanceCashId,
              });
              if (resp.data.code === "SUCCESS") {
                this.property.filterDto.dateFrom = "";
                this.property.filterDto.dateTo = "";
                this.getBalanceCash();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async authorizationBalanceCash(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "balance-cash/authorized/" + props.row.balanceCashId,
              });
              if (resp.data.code === "SUCCESS") {
                this.property.filterDto.dateFrom = "";
                this.property.filterDto.dateTo = "";
                this.getBalanceCash();
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationBalanceCash(event) {
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "balance-cash",
          payload: {
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusId,
            officeCode: this.property.filterDto.officeCode,
            tellerUserCode: this.property.filterDto.tellerUserCode,
            page: event - 1,
            size: this.property.listElement.balanceCash.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.balanceCash = resp.data.data.content;
            this.property.listElement.balanceCash.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataBalanceCash(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorBalanceCash(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.balanceCash.downloading = false;
        }, timeout);
      }
    },
    failedGetDataBalanceCash(resp) {
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.rows = 0;
      this.property.listElement.balanceCash.message = resp.data.message;
    },
    handleErrorBalanceCash(error) {
      console.log(error.response);
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.rows = 0;
      this.property.listElement.balanceCash.currentPage = 1;
      this.property.listElement.balanceCash.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async helperGetCabangList() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    setDefaultOfficeCodeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      console.log(getUserDataFromSession);
      this.property.filterDto.officeCode = getUserDataFromSession.officeCode;
    },
    // async helperGetUserDataForReference() {
    //   try {
    //     const resp = await this.$store.dispatch(
    //       "GET_REFERENCE_FROM_USER_MANAGEMENT",
    //       {
    //         url: "v2/user/active-user",
    //         params: {
    //           page: 0,
    //           officeCode: "",
    //         },
    //       }
    //     );
    //     if (resp.data.code === "SUCCESS") {
    //       this.options.user = [{ value: "", text: "-- Pilih --" }];
    //       resp.data.data.content.map((index) => {
    //         const value = index.userCode;
    //         const text = `${index.userCode} - ${index.profileSurename}`;
    //         this.options.user.push({ text, value });
    //       });
    //     }
    //   } catch (error) {}
    // },
  },
  mounted() {
    // this.getUserDataFromSession();
    this.helperGetCabangList();
    this.setDefaultOfficeCodeValue();
    // this.helperGetUserDataForReference();
    this.getBalanceCash();
    this.getReferenceStatusAccount();

  },
};
