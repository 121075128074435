import FormMJournalLedger from "../form";

export default {
  name: "ExistingMJournalLedger",
  components: {
    FormMJournalLedger,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getJournalLedgerById();
    },
    async getJournalLedgerById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formMJournalLedgerRef.routeToPageListJournalLedger();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl: "journal-ledger/manual/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formMJournalLedgerRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formMJournalLedgerRef.routeToPageListJournalLedger(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formMJournalLedgerRef.routeToPageListJournalLedger(),
          });
        }
      }
    },
    async editJournalLedger(payload) {
      const data = {
        header: {
          journalCode: payload.header.journalCode,
          linkModule: payload.header.linkModule,
          nominal:
            this.$refs.formMJournalLedgerRef.calculateDebt ===
            this.$refs.formMJournalLedgerRef.calculateCredit
              ? this.$refs.formMJournalLedgerRef.calculateDebt
              : 0,
          description: payload.header.description,
        },
        detail: [],
      };
      data.detail = [];
      this.$refs.formMJournalLedgerRef.table.data.journalLedgerManual.map(
        (index) => {
          const debtNominal = !index.debtNominal ? 0 : index.debtNominal;
          const creditNominal = !index.creditNominal ? 0 : index.creditNominal;
          const description = index.description;
          const chartOfAccountId = index.chartOfAccountId;
          data.detail.push({
            debtNominal,
            creditNominal,
            description,
            chartOfAccountId,
          });
        }
      );
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formMJournalLedgerRef.property.animation.addJournalLedgerManual.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "parameter",
                reqUrl: "journal-ledger/manual/" + this.identity.mainId,
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formMJournalLedgerRef.property.animation.addJournalLedgerManual.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
