var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex flex-col",staticStyle:{"max-width":"600px"}},[_c('form-builder',{attrs:{"scheme":{
        metalTypeId: {
          label: 'Jenis Emas',
          models: 'metalTypeId',
          type: 'select',
          selectProperty: {
            options: _vm.options.metalType,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
          event: {
            type: 'input',
            action: function (e) {
              _vm.getMortgageMetalPrice(e.target.value);
            },
          },
        },
        mortgageCode: {
          models: 'mortgageCode',
          label: 'Kode Gadai Karat',
          type: 'text',
        },
        mortgageName: {
          models: 'mortgageName',
          label: 'Nama Gadai Karat',
          type: 'text',
        },
        mortgageCaratId: {
          models: 'mortgageCaratId',
          label: 'Karat',
          type: 'select',
          selectProperty: {
            options: _vm.options.mortgageCarat,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
          event: {
            type: 'input',
            action: function (e) {
              _vm.changeSelectMortgageCarat(e.target.value);
            },
          },
        },
        timePeriodId: {
          models: 'timePeriodId',
          label: 'Jangka Waktu',
          type: 'select',
          selectProperty: {
            options: _vm.options.timePeriode,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
        },
        rentPricePercent: {
          models: 'rentPricePercent',
          label: 'Harga Sewa / gr',
          type: 'number',
          appendText: '%',
        },
        slthePrice: {
          isInformationComponent: true,
          value: _vm.form.slthePrice,
          label: 'Harga SLTE',
          type: 'text',
          formater: function (e) {
            return _vm.convertDecimalCurrency(e);
          },
          appendText: '/ gr',
        },
        maxLoanPercent: {
          type: 'number',
          models: 'maxLoanPercent',
          label: 'Maksimum Pinjaman',
          appendText: '/ gr',
        },
        chartOfAccountId: {
          models: 'chartOfAccountId',
          label: 'Kode COA',
          type: 'model-list-select',
          selectProperty: {
            options: _vm.options.chartOfAccount,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
        },
        description: {
          models: 'description',
          type: 'textarea',
          label: 'Keterangan',
        },
      },"message-mapper":_vm.formMessage},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('div',{staticClass:"flex mt-5 justify-center w-full"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.routeToPageListMortgage}},[_vm._v("Kembali")]),_c('b-button',{staticClass:"button-theme-confirm",attrs:{"loading":_vm.property.animation.submitLoading,"type":"is-success","disabled":!_vm.$store.getters['getActionsInsert'] ||
            _vm.property.animation.submitLoading === true},on:{"click":_vm.submitMortgage}},[_vm._v(" Simpan ")])],1)]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.form.createdByUserCode,"createdByUserName":_vm.form.createdByUserName,"createdDate":_vm.form.createdDate,"updatedDate":_vm.form.updatedDate,"updatedByUserCode":_vm.form.updatedByUserCode,"updatedByUserName":_vm.form.updatedByUserName,"authorizedDate":_vm.form.authorizedDate,"authorizedByUserCode":_vm.form.authorizedByUserCode,"authorizedByUserName":_vm.form.authorizedByUserName}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }