export default {
  name: "ListMProductLedger",
  data() {
    return {
      property: {
        listElement: {
          productLedger: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          name: "",
          productCategory: "",
        },
      },
      options: {
        tipeLayanan: [],
      },
      table: {
        header: {
          productLedger: [],
        },
        data: {
          productLedger: [],
        },
      },
    };
  },
  methods: {
    async findProductLedger() {
      this.table.data.productLedger = [];
      this.property.listElement.productLedger.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "product-ledger",
          payload: {
            name: this.property.filterDto.name,
            productCategoryId: this.property.filterDto.productCategory,
            page: 0,
            size: this.property.listElement.productLedger.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.productLedger = resp.data.data.content;
          this.property.listElement.productLedger.currentPage =
            resp.data.data.pageable.pageNumber + 1;
          this.property.listElement.productLedger.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataProductLedger(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorProductLedger(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.productLedger.downloading = false;
      }
    },

    async handleDeleteDataProductLedger(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl: "product-ledger/" + props.row.productLedgerId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.productLedger.currentPage = 1;
          this.property.filterDto.productCategory = "";
          this.property.filterDto.name = "";
          this.property.listElement.productLedger.rows = 0;
          this.helperGetDataTableProductLedger();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handleAuthorizationMProductLedger() {
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.$buefy.toast.open(this.$NotificationUtils.apiNotFound);
        },
      });
    },

    handleCatchErrorProductLedger(error) {
      console.log(error.response);
      this.table.data.productLedger = [];
      this.property.listElement.productLedger.rows = 0;
      this.property.listElement.productLedger.currentPage = 1;
      this.property.listElement.productLedger.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangePaginationProductLedger(event) {
      this.table.data.productLedger = [];
      this.property.listElement.productLedger.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "product-ledger",
          payload: {
            name: this.property.filterDto.name,
            productCategoryId: this.property.filterDto.productCategory,
            page: event - 1,
            size: this.property.listElement.productLedger.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.productLedger = resp.data.data.content;
          this.property.listElement.productLedger.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataProductLedger(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorProductLedger(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.productLedger.downloading = false;
      }
    },
    handleFailedGetDataProductLedger(resp) {
      this.table.data.productLedger = [];
      this.property.listElement.productLedger.rows = 0;
      this.property.listElement.productLedger.message = resp.data.message;
    },
    routePageCreate() {
      this.$router.push("/product-ledger/new");
    },
    routeToPageEditProductLedger(props) {
      this.$router.push(
        `/product-ledger/${this.encryptBASE64(`${props.row.productLedgerId}`)}`
      );
    },
    async helperGetDataTableProductLedger() {
      this.table.data.productLedger = [];
      this.property.listElement.productLedger.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "product-ledger",
          payload: {
            name: "",
            productCategoryId: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.productLedger = resp.data.data.content;
          this.property.listElement.productLedger.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataProductLedger(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorProductLedger(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.productLedger.downloading = false;
      }
    },

    helperGetProductCategoryDataForReference() {
      this.$store.dispatch("GET_REFERENCE_PRODUCT_CATEGORY").then((resp) => {
        resp.data.data.map((i) => {
          const value = i.productCategoryId;
          const text = i.productCategoryName;
          this.options.tipeLayanan.push({ text, value });
        });
      });
    },

    handleChargingOptionsComponent() {
      this.options.tipeLayanan.push({
        value: "",
        text: "Semua Kategori",
      });
    },
  },
  mounted() {
    this.helperGetDataTableProductLedger();
    this.handleChargingOptionsComponent();
    this.helperGetProductCategoryDataForReference();
  },
};
