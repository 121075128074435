export default {
  name: "FormTTaskInterbankLiability",
  components: {},
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        transactionCodeId: "",
        documentProofNumber: "",
        nominal: 0,
        description: "",
        sourceSavingAccountNumber: "",
        targetSavingAccountNumber: "",
        sourceCoaNumber: "",
        targetCoaNumber: "",
        sourceAccountId: "",
        targetAccountId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        sourceAccountResult: {
          accountName: "",
          branch: "",
          description: "",
        },
        targetAccountResult: {
          accountName: "",
          branch: "",
          description: "",
        },
      },
      options: {
        kodeTransaksi: [],
        sourceAccount: [],
        targetAccount: [],
        chartOfAccount: [],
        rekeningAbp: [],
        sourceAccountType: [],
        targetAccountType: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
    };
  },
  methods: {
    async searchTargetSavingAccount() {
      this.$buefy.dialog.prompt({
        title: "Cari Nomor Rekening",
        message: `Masukkan Nomor Rekening`,
        type: "is-success",
        inputAttrs: {
          placeholder: "Nomor Rekening",
        },
        trapFocus: true,
        cancelText: `Batal`,
        confirmText: "Cari",
        closeOnConfirm: false,
        onConfirm: async (accountNumber, { close }) => {
          if (accountNumber) {
            try {
              const resp = await this.$store.dispatch(
                "GET_T_SAVING_ACCOUNT_BY_ACCOUNT_NUMBER",
                { accountNumber: accountNumber }
              );
              if (resp.data.code === "SUCCESS") {
                close();
                this.$buefy.dialog.confirm({
                  title: "Konfirmasi Nomor Rekening",
                  message: `
                  <span><b>Nomor Rekening</b> : ${
                    resp.data.data.accountNumber
                  }</span>
                  <br />
                  <span><b>Nama</b> : ${
                    resp.data.data.mcif ? resp.data.data.mcif.cifIdName : ""
                  }</span>
                  <br />
                  <span><b>Cabang</b> : ${
                    resp.data.data.mcif
                      ? resp.data.data.mcif.officeId
                        ? resp.data.data.mcif.officeId.officeName
                        : ""
                      : ""
                  }</span>
                  `,
                  type: "is-success",
                  canCancel: ["button"],
                  cancelText: "Batal",
                  confirmText: "Konfirmasi",
                  onConfirm: () => {
                    this.form.targetAccountResult.accountName = resp.data.data
                      .mcif
                      ? resp.data.data.mcif.cifIdName
                      : "";
                    this.form.targetAccountResult.branch = resp.data.data.mcif
                      ? resp.data.data.mcif.officeId
                        ? resp.data.data.mcif.officeId.officeName
                        : ""
                      : "";
                    this.form.targetSavingAccountNumber =
                      resp.data.data.accountNumber;
                    this.form.targetAccountId = resp.data.data.savingAccountId;
                  },
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
                this.form.targetAccountId = "";
                this.form.targetAccountResult.accountName = "";
                this.form.targetAccountResult.branch = "";
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }
        },
      });
    },
    changeSelectGetCoaDetailTarget() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.targetCoaNumber) {
          this.form.targetAccountResult.description = index.description;
          this.form.targetAccountId = index.value;
        }
      });
    },

    changeSelectGetCoaDetailSource() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.sourceCoaNumber) {
          this.form.sourceAccountResult.description = index.description;
          this.form.sourceAccountId = index.value;
        }
      });
    },

    async searchSourceSavingAccount() {
      this.$buefy.dialog.prompt({
        title: "Cari Nomor Rekening",
        message: `Masukkan Nomor Rekening`,
        type: "is-success",
        inputAttrs: {
          placeholder: "Nomor Rekening",
        },
        trapFocus: true,
        cancelText: `Batal`,
        confirmText: "Cari",
        closeOnConfirm: false,
        onConfirm: async (accountNumber, { close }) => {
          if (accountNumber) {
            try {
              const resp = await this.$store.dispatch(
                "GET_T_SAVING_ACCOUNT_BY_ACCOUNT_NUMBER",
                { accountNumber: accountNumber }
              );
              if (resp.data.code === "SUCCESS") {
                close();
                this.$buefy.dialog.confirm({
                  title: "Konfirmasi Nomor Rekening",
                  message: `
                  <span><b>Nomor Rekening</b> : ${
                    resp.data.data.accountNumber
                  }</span>
                  <br />
                  <span><b>Nama</b> : ${
                    resp.data.data.mcif ? resp.data.data.mcif.cifIdName : ""
                  }</span>
                  <br />
                  <span><b>Cabang</b> : ${
                    resp.data.data.mcif
                      ? resp.data.data.mcif.officeId
                        ? resp.data.data.mcif.officeId.officeName
                        : ""
                      : ""
                  }</span>
                  `,
                  type: "is-success",
                  canCancel: ["button"],
                  cancelText: "Batal",
                  confirmText: "Konfirmasi",
                  onConfirm: () => {
                    this.form.sourceSavingAccountNumber =
                      resp.data.data.accountNumber;
                    this.form.sourceAccountId = resp.data.data.savingAccountId;
                    this.form.sourceAccountResult.accountName = resp.data.data
                      .mcif
                      ? resp.data.data.mcif.cifIdName
                      : "";
                    this.form.sourceAccountResult.branch = resp.data.data.mcif
                      ? resp.data.data.mcif.officeId
                        ? resp.data.data.mcif.officeId.officeName
                        : ""
                      : "";
                  },
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
                this.form.targetAccountId = "";
                this.form.targetAccountResult.accountName = "";
                this.form.targetAccountResult.branch = "";
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }
        },
      });
    },
    changeSelectOptionsTargetCoa() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.targetAccountId) {
          this.form.targetAccountResult.description = index.description;
        }
      });
    },
    changeSelectOptionsSourceCoa() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.sourceAccountId) {
          this.form.sourceAccountResult.description = index.description;
        }
      });
    },
    changeSelectSourceRekeningAbp() {
      this.options.rekeningAbp.map((index) => {
        if (index.value === this.form.sourceAccountId) {
          this.form.sourceAccountResult.accountName = index.accountName;
          this.form.sourceAccountResult.branch = index.accountBranch;
        }
      });
    },
    changeSelectTargetRekeningAba() {
      this.options.rekeningAbp.map((index) => {
        if (index.value === this.form.targetAccountId) {
          this.form.targetAccountResult.accountName = index.accountName;
          this.form.targetAccountResult.branch = index.accountBranch;
        }
      });
    },
    changeSelectKodeTransaksi() {
      this.form.sourceAccountId = "";
      this.form.sourceAccountResult.accountName = "";
      this.form.sourceAccountResult.branch = "";
      this.form.sourceAccountResult.description = "";
      this.form.sourceCoaNumber = "";
      this.form.sourceSavingAccountNumber = "";
      this.form.targetSavingAccountNumber = "";
      this.form.targetCoaNumber = "";
      this.form.targetAccountId = "";
      this.form.targetAccountResult.accountName = "";
      this.form.targetAccountResult.branch = "";
      this.form.targetAccountResult.description = "";
    },
    helperGetTransactionCodeDataForReference() {
      const payload = {
        url: "transaction-code",
        params: {
          name: "",
          page: 0,
          menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_TRX_MENU_ACCESS_TRX_ABP,
        },
      };
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", payload)
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const value = index.transactionCodeId;
            const text = `${index.transactionCode} - ${index.transactionName}`;
            this.options.kodeTransaksi.push({ text, value });
          });
        });
    },
    helperGetChartOfAccountDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "chart-of-account",
          params: {
            description: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const value = index.chartOfAccountId;
            const removeSpaceInSubsidaryLedgerNumber = `${index.chartOfAccountCode}`.trim();
            const description = `${index.description}`.trim();
            const text = `${removeSpaceInSubsidaryLedgerNumber} - ${description}`;
            this.options.chartOfAccount.push({ text, value, description });
          });
        });
    },
    routeToPageListTaskInterbankLiability() {
      this.$router.push("/task-interbank-liability");
    },
    appendOptionseSelect() {
      this.options.kodeTransaksi.push({ value: "", text: "-- Pilih --" });
      this.options.sourceAccountType.push({ value: "", text: "-- Pilih --" });
      this.options.targetAccountType.push({ value: "", text: "-- Pilih --" });
    },
    helperGetInterbankLiabilityDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_TRANSACTION", {
          url: "interbankliability",
          params: {
            interbankLiabilityTypeId: "",
            accountNumber: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const value = index.interbankLiabilityId;
            const text = `${index.rinterbankLiabilityType.interbankLiabilityTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = index.accountName;
            const accountBranch = `${index.bankName} - Cabang ${index.accountBranch}`;
            this.options.rekeningAbp.push({
              text,
              value,
              accountName,
              accountBranch,
            });
          });
        });
    },
    async helperGetInterbankLiabilityByAccountNumber(type) {
      const payload = {
        accountNumber:
          type === "SOURCE"
            ? this.form.sourceInterbankLiabilityAccountNumber
            : this.form.targetInterbankLiabilityAccountNumber,
      };
      try {
        const resp = await this.$store.dispatch(
          "GET_M_INTERBANK_LIABILITY_BY_ACCOUNT_NUMBER",
          payload
        );
        if (resp.data.code === "SUCCESS") {
          switch (type) {
            case "SOURCE":
              this.form.sourceAccountResult.accountName =
                resp.data.data.accountName;
              this.form.sourceAccountResult.branch =
                resp.data.data.accountBranch;
              break;

            case "TARGET":
              this.form.targetAccountResult.accountName =
                resp.data.data.accountName;
              this.form.targetAccountResult.branch =
                resp.data.data.accountBranch;
              break;

            default:
              return;
              break;
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async helperGetSavingAccountByAccountNumber(type) {
      const payload = {
        accountNumber:
          type === "SOURCE"
            ? this.form.sourceSavingAccountNumber
            : this.form.targetSavingAccountNumber,
      };
      try {
        const resp = await this.$store.dispatch(
          "GET_T_SAVING_ACCOUNT_BY_ACCOUNT_NUMBER",
          payload
        );
        if (resp.data.code === "SUCCESS") {
          switch (type) {
            case "SOURCE":
              this.form.sourceAccountResult.accountName = resp.data.data.mcif
                ? resp.data.data.mcif.cifIdName
                : "";
              this.form.sourceAccountResult.branch = resp.data.data.mcif
                ? resp.data.data.mcif.officeId
                  ? resp.data.data.mcif.officeId.officeName
                  : ""
                : "";

              break;

            case "TARGET":
              this.form.targetAccountResult.accountName = resp.data.data.mcif
                ? resp.data.data.mcif.cifIdName
                : "";
              this.form.targetAccountResult.branch = resp.data.data.mcif
                ? resp.data.data.mcif.officeId
                  ? resp.data.data.mcif.officeId.officeName
                  : ""
                : "";
              break;

            default:
              return;
              break;
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handlingSelectorAccountSourceAndTarget(payload) {
      switch (payload.mtransactionCode.transactionCodeId) {
        case "58":
          this.form.sourceCoaNumber = payload.sourceAccountNumber;
          this.form.sourceAccountResult.description = payload.sourceAccountName;
          this.form.targetInterbankLiabilityAccountNumber =
            payload.targetAccountNumber;
          this.helperGetInterbankLiabilityByAccountNumber("TARGET");
          break;

        case "59":
          this.form.sourceInterbankLiabilityAccountNumber =
            payload.sourceAccountNumber;
          this.form.targetCoaNumber = payload.sourceAccountNumber;
          this.form.targetAccountResult.description = payload.sourceAccountName;
          this.helperGetInterbankLiabilityByAccountNumber("SOURCE");
          break;

        case "60":
          this.form.sourceInterbankLiabilityAccountNumber =
            payload.sourceAccountNumber;
          this.form.targetSavingAccountNumber = payload.targetAccountNumber;
          this.helperGetInterbankLiabilityByAccountNumber("SOURCE");
          this.helperGetSavingAccountByAccountNumber("TARGET");
          break;

        case "61":
          this.form.sourceSavingAccountNumber = payload.sourceAccountNumber;
          this.form.targetInterbankLiabilityAccountNumber =
            payload.targetAccountNumber;
          this.helperGetInterbankLiabilityByAccountNumber("TARGET");
          this.helperGetSavingAccountByAccountNumber("SOURCE");
          break;

        default:
          return;
          break;
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.transactionCodeId = payload.mtransactionCode
          ? payload.mtransactionCode.transactionCodeId
          : "";
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.nominal = payload.nominal;
        this.form.description = payload.description;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        this.form.referenceNumberSourceAccount =
          payload.referenceNumberSourceAccount;
        this.form.referenceNumberTargetAccount =
          payload.referenceNumberTargetAccount;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        this.handlingSelectorAccountSourceAndTarget(payload);
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitTaskInterbankLiability() {
      this.$emit("submitTaskInterbankLiability", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendOptionseSelect();
    this.helperGetTransactionCodeDataForReference();
    this.helperGetChartOfAccountDataForReference();
    this.helperGetInterbankLiabilityDataForReference();
    this.form.transactionDate = this.fetchAppSession(
      "@vue-session/application"
    ).system_date;
  },
};
