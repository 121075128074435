var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex flex-col",staticStyle:{"max-width":"600px"}},[_c('form-builder',{attrs:{"message-mapper":_vm.formMessage,"scheme":{
        collectabilityIncomeTypeId: {
          models: 'collectabilityIncomeTypeId',
          label: 'Jenis Pendapatan',
          type: 'select',
          selectProperty: {
            options: _vm.options.jenisPendapatan,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
        },
        loanCollectabilityStatusId: {
          models: 'loanCollectabilityStatusId',
          label: 'Status Kolektibilitas',
          type: 'select',
          selectProperty: {
            options: _vm.options.kodeKolektibilitas,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
        },
        collectabilityCodeId: {
          models: 'collectabilityCodeId',
          label: 'Kode Kolektibilitas',
          type: 'select',
          selectProperty: {
            options: _vm.options.collectabilityCodeId,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
        },
        daysInArrearsBeforeMin: {
          models: 'daysInArrearsBeforeMin',
          label: 'Minimal Tertunggak Sebelum',
          type: 'text',
          appendText: 'Hari',
        },
        daysInArrearsAfterMin: {
          models: 'daysInArrearsAfterMin',
          label: 'Minimal Tertunggak Setelah',
          type: 'text',
          appendText: 'Hari',
        },
        daysInArrearsAfterMax: {
          models: 'daysInArrearsAfterMax',
          label: 'Maximal Tertunggak Setelah',
          type: 'text',
          appendText: 'Hari',
        },
        daysInArrearsBeforeMax: {
          label: 'Maximal Tertunggak Sebelum',
          models: 'daysInArrearsBeforeMax',
          type: 'text',
          appendText: 'Hari',
        },
        profitShareEstimate: {
          models: 'profitShareEstimate',
          label: 'RBH',
          type: 'number',
          appendText: '%',
        },
        weight: {
          models: 'weight',
          label: 'Bobot CAD',
          type: 'number',
          appendText: '%',
        },
      }},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('div',{staticClass:"flex mt-5 justify-center w-full"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.routeToPageListLoanCollectability}},[_vm._v("Kembali")]),_c('b-button',{staticClass:"button-theme-confirm",attrs:{"loading":_vm.property.animation.submitLoading,"disabled":!_vm.$store.getters['getActionsInsert'] ||
            _vm.property.animation.submitLoading === true,"type":"is-success"},on:{"click":_vm.submitLoanCollectability}},[_vm._v(" Simpan")])],1)]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.form.createdByUserCode,"createdByUserName":_vm.form.createdByUserName,"createdDate":_vm.form.createdDate,"updatedDate":_vm.form.updatedDate,"updatedByUserCode":_vm.form.updatedByUserCode,"updatedByUserName":_vm.form.updatedByUserName,"authorizedDate":_vm.form.authorizedDate,"authorizedByUserCode":_vm.form.authorizedByUserCode,"authorizedByUserName":_vm.form.authorizedByUserName}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }