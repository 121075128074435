export default {
  name: "ListCashOutDanamon",
  data() {
    return {
      validationDomain: "",
      property: {
        animation: {
          submitLoading: false,
        },
        filterDto: {
          sourceAccount: "",
          referenceNumber: "",
          beneficiaryAccount: "",
          dateFrom: "",
          dateTo: "",
        },
        listElement: {
          cashOutDanamon: {
            rows: 0,
            perPage: 10,
            message: "",
            currentPage: 1,
            downloading: false,
          },
        },
      },
      table: {
        data: {
          cashOutDanamon: [],
        },
      },
    };
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getHistoryCashOutDanamon();
  },
  methods: {
    routeToPageInquiry() {
      this.$router.push("cash-out-danamon/inquiry");
    },
    async authorizationCashOutDanamon(props) {
      console.log(props);
      if (this.validationDomain === "olibs.bprshikp.co.id") {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        } else {
          this.$buefy.dialog.confirm({
            message: "Otorisasi Data Ini ?",
            type: "is-success",
            onConfirm: async () => {
              try {
                const resp = await this.$store.dispatch({
                  type: "POST_DATA",
                  endPoint: "outgoing-service",
                  reqUrl: "danamon/authorized/" + props.row.id,
                });
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.getHistoryCashOutDanamon();
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              } catch (error) {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            },
          });
        }
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        } else {
          this.$buefy.dialog.confirm({
            message: "Otorisasi Data Ini ?",
            type: "is-success",
            onConfirm: async () => {
              try {
                const resp = await this.$store.dispatch({
                  type: "POST_DATA",
                  endPoint: "outgoing-service-appdev",
                  reqUrl: "danamon/authorized/" + props.row.id,
                });
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.getHistoryCashOutDanamon();
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              } catch (error) {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            },
          });
        }
      }
    },
    async getHistoryCashOutDanamon() {
      this.table.data.cashOutDanamon = [];
      this.property.listElement.cashOutDanamon.downloading = true;
      const timeout = 500;
      try {
        if (this.validationDomain === "olibs.bprshikp.co.id") {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service",
            reqUrl: "danamon/history",
            payload: {
              isourceAccount: "",
              referenceNumber: "",
              beneficiaryAccount: "",
              dateFrom: "",
              dateTo: "",
              page: 0,
              size: 10,
            },
          });
          console.log(resp);
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.cashOutDanamon = resp.data.data.content;
              this.property.listElement.cashOutDanamon.rows =
                resp.data.data.totalElements;
            } else {
              this.failedGetCashOutDanamin(resp);
            }
          }, timeout);
        } else {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/history",
            payload: {
              isourceAccount: "",
              referenceNumber: "",
              beneficiaryAccount: "",
              dateFrom: "",
              dateTo: "",
              page: 0,
              size: 10,
            },
          });
          console.log(resp);
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.cashOutDanamon = resp.data.data.content;
              this.property.listElement.cashOutDanamon.rows =
                resp.data.data.totalElements;
            } else {
              this.failedGetCashOutDanamin(resp);
            }
          }, timeout);
        }
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetaCashOutDanamon(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashOutDanamon.downloading = false;
        }, timeout);
      }
    },
    async searchHistoryCashOutDanamon() {
      this.table.data.cashOutDanamon = [];
      this.property.listElement.cashOutDanamon.downloading = true;
      const timeout = 500;
      try {
        if (this.validationDomain === "olibs.bprshikp.co.id") {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service",
            reqUrl: "danamon/history",
            payload: {
              isourceAccount: this.property.filterDto.isourceAccount,
              referenceNumber: this.property.filterDto.referenceNumber,
              beneficiaryAccount: this.property.filterDto.beneficiaryAccount,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              page: 0,
              size: this.property.listElement.cashOutDanamon.perPage,
            },
          });
          console.log(resp);
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.cashOutDanamon = resp.data.data.content;
              this.property.listElement.cashOutDanamon.rows =
                resp.data.data.totalElements;
              this.property.listElement.cashOutDanamon.currentPage =
                resp.data.data.pageable.pageNumber + 1;
            } else {
              this.failedGetCashOutDanamin(resp);
            }
          }, timeout);
        } else {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/history",
            payload: {
              isourceAccount: this.property.filterDto.isourceAccount,
              referenceNumber: this.property.filterDto.referenceNumber,
              beneficiaryAccount: this.property.filterDto.beneficiaryAccount,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              page: 0,
              size: this.property.listElement.cashOutDanamon.perPage,
            },
          });
          console.log(resp);
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.cashOutDanamon = resp.data.data.content;
              this.property.listElement.cashOutDanamon.rows =
                resp.data.data.totalElements;
              this.property.listElement.cashOutDanamon.currentPage =
                resp.data.data.pageable.pageNumber + 1;
            } else {
              this.failedGetCashOutDanamin(resp);
            }
          }, timeout);
        }
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetaCashOutDanamon(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashOutDanamon.downloading = false;
        }, timeout);
      }
    },
    failedGetCashOutDanamin(resp) {
      this.property.listElement.cashOutDanamon.rows = 0;
      this.table.data.cashOutDanamon = [];
      this.property.listElement.cashOutDanamon.message = resp.data.message;
    },
    handleErrorGetaCashOutDanamon(error) {
      console.log(error.response);
      this.table.data.cashOutDanamon = [];
      this.property.listElement.cashOutDanamon.rows = 0;
      this.property.listElement.cashOutDanamon.currentPage = 1;
      this.property.listElement.cashOutDanamon.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async addStatusCashOutDanamon(props) {
      const data = {
        transferReferenceNumber: props.row.transferReferenceNumber,
        clientReferenceNumber: props.row.clientReferenceNumber,
      };
      this.property.animation.submitLoading = true;
      try {
        if (this.validationDomain === "olibs.bprshikp.co.id") {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "outgoing-service",
            reqUrl: "danamon/status",
            payload: data,
          });
          console.log("data inquery = > ", resp);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.getHistoryCashOutDanamon();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } else {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/status",
            payload: data,
          });
          console.log("data inquery = > ", resp);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.getHistoryCashOutDanamon();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.submitLoading = false;
      }
    },
    async changePaginationHistoryCashOutDanamon(event) {
      this.table.data.cashOutDanamon = [];
      this.property.listElement.cashOutDanamon.downloading = true;
      const timeout = 500;
      try {
        if (this.validationDomain === "olibs.bprshikp.co.id") {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service",
            reqUrl: "danamon/history",
            payload: {
              isourceAccount: this.property.filterDto.isourceAccount,
              referenceNumber: this.property.filterDto.referenceNumber,
              beneficiaryAccount: this.property.filterDto.beneficiaryAccount,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              page: event - 1,
              size: this.property.listElement.cashOutDanamon.perPage,
            },
          });
          console.log(resp);
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.cashOutDanamon = resp.data.data.content;
              this.property.listElement.cashOutDanamon.rows =
                resp.data.data.totalElements;
              this.property.listElement.cashOutDanamon.currentPage =
                resp.data.data.pageable.pageNumber + 1;
            } else {
              this.failedGetCashOutDanamin(resp);
            }
          }, timeout);
        } else {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/history",
            payload: {
              isourceAccount: this.property.filterDto.isourceAccount,
              referenceNumber: this.property.filterDto.referenceNumber,
              beneficiaryAccount: this.property.filterDto.beneficiaryAccount,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              page: event - 1,
              size: this.property.listElement.cashOutDanamon.perPage,
            },
          });
          console.log(resp);
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.cashOutDanamon = resp.data.data.content;
              this.property.listElement.cashOutDanamon.rows =
                resp.data.data.totalElements;
              this.property.listElement.cashOutDanamon.currentPage =
                resp.data.data.pageable.pageNumber + 1;
            } else {
              this.failedGetCashOutDanamin(resp);
            }
          }, timeout);
        }
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetaCashOutDanamon(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashOutDanamon.downloading = false;
        }, timeout);
      }
    },
  },
};
