import Moment from "moment";

export default {
  name: "DistributionProfitShare",
  data() {
    return {
      dataForm: {
        allGolongan: "1",
        reRenderComponent: true,
      },
      property: {
        animation: {
          isDownloadingFile: false,
        },
        filterDto: {
          period: "",
        },
        listElement: {
          distributionProfitShare: {
            downloading: false,
            message: "",
            rows: 0,
            perPage: 10,
            currentPage: 1,
          },
        },
      },
      table: {
        data: {
          distributionProfitShare: {
            gol1: [],
            gol2: [],
            gol3: [],
          },
        },
      },
      options: {
        golongan: [
          {
            text: "Golongan 1",
            value: "1",
          },
          {
            text: "Golongan 2",
            value: "2",
          },
          {
            text: "Golongan 3",
            value: "3",
          },
        ],
      },
    };
  },
  methods: {
    async onDownloadFile() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        period: this.periodConvertMonth(this.property.filterDto.period),
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/distribute-profit-share/excel",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Distribusi_Bagi_Hasil.${"xlsx"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    periodConvertMonth(val) {
      Moment.locale("ID");
      return `${Moment(val).format("YYYY")}-${Moment(val).format("MM")}`;
    },
    async helperGetTableDistributionProfitShare() {
      this.table.data.distributionProfitShare.gol1 = [];
      this.table.data.distributionProfitShare.gol2 = [];
      this.table.data.distributionProfitShare.gol3 = [];
      this.property.listElement.distributionProfitShare.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/distribute-profit-share",
          payload: {
            period: this.periodConvertMonth(this.property.filterDto.period),
          },
        });
        console.log("");
        if (resp.data.code === "SUCCESS") {
          this.table.data.distributionProfitShare.gol1 = resp.data.data.gol1;
          this.table.data.distributionProfitShare.gol2 = resp.data.data.gol2;
          this.table.data.distributionProfitShare.gol3 = resp.data.data.gol3;
        } else {
          this.failedGetDistributionProfitShare(resp);
        }
      } catch (error) {
        this.handleErrorGetDistributionProfitShare(error);
      } finally {
        this.property.listElement.distributionProfitShare.downloading = false;
      }
    },
    async searchDistributionProfitShare() {
      this.table.data.distributionProfitShare.gol1 = [];
      this.table.data.distributionProfitShare.gol2 = [];
      this.table.data.distributionProfitShare.gol3 = [];
      this.property.listElement.distributionProfitShare.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/distribute-profit-share",
          payload: {
            period: this.periodConvertMonth(this.property.filterDto.period),
          },
        });
        console.log("");
        if (resp.data.code === "SUCCESS") {
          this.table.data.distributionProfitShare.gol1 = resp.data.data.gol1;
          this.table.data.distributionProfitShare.gol2 = resp.data.data.gol2;
          this.table.data.distributionProfitShare.gol3 = resp.data.data.gol3;
        } else {
          this.failedGetDistributionProfitShare(resp);
        }
      } catch (error) {
        this.handleErrorGetDistributionProfitShare(error);
      } finally {
        this.property.listElement.distributionProfitShare.downloading = false;
      }
    },
    failedGetDistributionProfitShare(resp) {
      this.property.listElement.distributionProfitShare.rows = 0;
      this.table.data.distributionProfitShare.gol1 = [];
      this.table.data.distributionProfitShare.gol2 = [];
      this.table.data.distributionProfitShare.gol3 = [];
      this.property.listElement.distributionProfitShare.message =
        resp.data.message;
    },
    handleErrorGetDistributionProfitShare(error) {
      console.log(error.response);
      this.table.data.distributionProfitShare.gol1 = [];
      this.table.data.distributionProfitShare.gol2 = [];
      this.table.data.distributionProfitShare.gol3 = [];
      this.property.listElement.distributionProfitShare.rows = 0;
      this.property.listElement.distributionProfitShare.currentPage = 1;
      this.property.listElement.distributionProfitShare.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changeAllGolongan(event) {
      this.dataForm.allGolongan = event;
      this.dataForm.reRenderComponent = false;
      console.log("reRender = ", this.dataForm.reRenderComponent);
      await this.$nextTick();
      this.dataForm.reRenderComponent = true;
    },
    getSystemDate() {
      this.property.filterDto.period = new Date();
      console.log("date => ", this.property.filterDto.period);
    },
  },
  mounted() {
    this.getSystemDate();
    this.helperGetTableDistributionProfitShare();
  },
};
