import FormSipendar from "../form";

export default {
    name: "CreateSipendar",
    components: {
        FormSipendar,
    },
    methods: {
        async addSipendar(payload) {
            const data = {
              idCardNumber: payload.idCardNumber,
              otherCardNumber: payload.otherCardNumber,
              npwpNumber: payload.npwpNumber,
              periode: payload.periode,
              rtypePersonId: payload.rtypePersonId,
              sipendarName: payload.sipendarName,
              watchlistCode: payload.watchlistCode,
              birthPlace: payload.birthPlace,
              birthDate: payload.birthDate,
            };
            if (window.navigator.onLine === false) {
              this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
              return;
            }
            const confirmation = await this.simpleConfirmation(
              "Simpan Data Ini ?",
              "is-success"
            );
            if (!confirmation) {
              return;
            }
            this.$refs.formSipendarRef.property.animation.sumbitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "sipendar",
                payload: data,
              });
              await this.simpleWait(1000);
              if (resp.data.code === "SUCCESS") {
                const toastBuefy = this.$buefy.toast.open(
                  this.$NotificationUtils.success
                );
                toastBuefy.$on("close", () => {
                  this.$refs.formSipendarRef.routeToPageListSipendar();
                });
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              await this.simpleWait(1000);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            } finally {
              await this.simpleWait(1000);
              this.$refs.formSipendarRef.property.animation.sumbitLoading = false;
            }
        },
    },
}