import Create from "./create";
import Existing from "./existing";
import Installment from "./installment";
import List from "./list";

export default {
  Create,
  Existing,
  Installment,
  List,
};
