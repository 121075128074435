export default{
    name: "ListDttot",
    components: {},
    data() {
        return {
          property: {
            listElement: {
              dttot: {
                rows: 0,
                perPage: 10,
                currentPage: 1,
                downloading: false,
                message: "",
              },
            },
            filterDto: {
              name: "",
              address: "",
            },
          },
          table: {
            data: {
              dttot: [],
            },
          },
        };
      },

      methods:{
        routeToPageAddDttot() {
          this.$router.push("/report/dttot/new");
        },
        async findDttot() {
          this.table.data.dttot = [];
          this.property.listElement.dttot.downloading = true;
          const timeout = 500;
          try {
            const resp = await this.$store.dispatch({
              type: "GET_LIST_DATA",
              endPoint: "transaction",
              reqUrl: "dttot",
              payload: {
                name: this.property.filterDto.name,
                address: this.property.filterDto.address,
                page: 0,
                size: 10,
              },
            });
    
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.table.data.dttot = resp.data.data.content;
                this.property.listElement.dttot.rows =
                  resp.data.data.totalElements;
                this.property.listElement.dttot.currentPage =
                  resp.data.data.pageable.pageNumber + 1;
              } else {
                this.failedGetDataDttot(resp);
              }
            }, timeout);
          } catch (error) {
            setTimeout(() => {
              this.handleCatchErrorDttot(error);
            }, timeout);
          } finally {
            setTimeout(() => {
              this.property.listElement.dttot.downloading = true;
            }, timeout);
          }
        },
        handleCatchErrorDttot(error) {
          console.log(error.response);
          this.table.data.dttot = [];
          this.property.listElement.dttot.rows = 0;
          this.property.listElement.dttot.currentPage = 1;
          this.property.listElement.dttot.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        },
        async changePaginationDttot(event) {
            this.table.data.dttot = [];
            this.property.listElement.dttot.downloading = true;
            const timeout = 500;
      
            try {
              const resp = await this.$store.dispatch({
                type: "GET_LIST_DATA",
                endPoint: "transaction",
                reqUrl: "dttot",
                payload: {
                  name: this.property.filterDto.name,
                  address: this.property.filterDto.address,
                  page: event - 1,
                  size: this.property.listElement.dttot.perPage,
                },
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.table.data.dttot = resp.data.data.content;
                  this.property.listElement.dttot.rows =
                    resp.data.data.totalElements;
                } else {
                  this.failedGetDataDttot(resp);
                }
              }, timeout);
            } catch (error) {
              setTimeout(() => {
                this.handleCatchErrorDttot(error);
              }, timeout);
            } finally {
              setTimeout(() => {
                this.property.listElement.dttot.downloading = false;
              }, timeout);
            }
        },
        async helperGetDataTableDttot() {
            this.table.data.dttot = [];
            this.property.listElement.dttot.downloading = true;
            const timeout = 500;
      
            try {
              const resp = await this.$store.dispatch({
                type: "GET_LIST_DATA",
                endPoint: "transaction",
                reqUrl: "dttot",
                payload: {
                  name: "",
                  address: "",
                  page: 0,
                  size: 10,
                },
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.table.data.dttot = resp.data.data.content;
                  this.property.listElement.dttot.rows =
                    resp.data.data.totalElements;
                } else {
                  this.failedGetDataDttot(resp);
                }
              }, timeout);
            } catch (error) {
              setTimeout(() => {
                this.handleCatchErrorDttot(error);
              }, timeout);
            } finally {
              setTimeout(() => {
                this.property.listElement.dttot.downloading = false;
              }, timeout);
            }
        },
        failedGetDataDttot(resp) {
            this.table.data.dttot = [];
            this.property.listElement.dttot.rows = 0;
            this.property.listElement.dttot.message = resp.data.message;
        },  
        routeToPageEditDttot(props) {
          this.$router.push(
            `/report/dttot/${this.encryptBASE64(
              `${props.row.id}`
            )}`
          );
        },
        async deleteDttot(props) {
          if (window.navigator.onLine === false) {
            this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
            return;
          } else {
            const confirmation = await this.simpleConfirmation(
              "Hapus Data Ini ?",
              "is-success"
            );
            if (!confirmation) {
              return;
            }
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "dttot/" + props.row.id,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                await this.simpleWait(1000);
                this.property.listElement.dttot.currentPage = 1;
                this.property.filterDto.name = "";
                this.property.filterDto.address = "";
                this.property.listElement.dttot.rows = 0;
                this.helperGetDataTableDttot();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }
        },
      },
      mounted(){
        this.helperGetDataTableDttot();
      }
}

