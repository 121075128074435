export default {
  name: "ListMasterAssetCategory",
  data() {
    return {
      property: {
        listElement: {
          masterAssetCategory: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          name: "",
        },
      },
      table: {
        header: {},
        data: {
          masterAssetCategory: [],
        },
      },
    };
  },
  methods: {
    failedGetDatamasterAssetCategory(resp) {
      this.table.data.masterAssetCategory = [];
      this.property.listElement.masterAssetCategory.rows = 0;
      this.property.listElement.masterAssetCategory.message = resp.data.message;
    },
    async getMasterAssetCategory() {
      this.table.data.masterAssetCategory = [];
      this.property.listElement.masterAssetCategory.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "asset",
          payload: {
            name: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.masterAssetCategory = resp.data.data.content;
          this.property.listElement.masterAssetCategory.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDatamasterAssetCategory(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetmasterAssetCategory(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.masterAssetCategory.downloading = false;
      }
    },
    handleErrorGetmasterAssetCategory(error) {
      console.log(error.response);
      this.table.data.masterAssetCategory = [];
      this.property.listElement.masterAssetCategory.rows = 0;
      this.property.listElement.masterAssetCategory.currentPage = 1;
      this.property.listElement.masterAssetCategory.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationMasterAssetCategory(event) {
      this.table.data.masterAssetCategory = [];
      this.property.listElement.masterAssetCategory.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "asset",
          payload: {
            name: this.property.filterDto.name,
            page: event,
            size: this.property.listElement.masterAssetCategory.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.masterAssetCategory = resp.data.data.content;
          this.property.listElement.masterAssetCategory.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDatamasterAssetCategory(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetmasterAssetCategory(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.masterAssetCategory.downloading = false;
      }
    },
    async findMasterAssetCategory() {
      this.table.data.masterAssetCategory = [];
      this.property.listElement.masterAssetCategory.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "asset",
          payload: {
            name: this.property.filterDto.name,
            page: 0,
            size: this.property.listElement.masterAssetCategory.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.masterAssetCategory = resp.data.data.content;
          this.property.listElement.masterAssetCategory.rows =
            resp.data.data.totalElements;
          this.property.listElement.masterAssetCategory.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDatamasterAssetCategory(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetmasterAssetCategory(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.masterAssetCategory.downloading = false;
      }
    },
    routeToPageAddMasterAssetCategory() {
      this.$router.push("/parameter/master-asset-category/new");
    },
    routeToPageEditMasterAssetCategory(props) {
      this.$router.push(
        `/parameter/master-asset-category/${this.encryptBASE64(
          `${props.row.assetId}`
        )}`
      );
    },
    async deleteMasterAssetCategory(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        const confirmation = await this.simpleConfirmation(
          "Hapus Data Ini ?",
          "is-success"
        );
        if (!confirmation) {
          return;
        }
        try {
          const resp = await this.$store.dispatch({
            type: "DELETE_DATA",
            endPoint: "parameter",
            reqUrl: "asset/" + props.row.assetId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            await this.simpleWait(1000);
            this.property.listElement.masterAssetCategory.currentPage = 1;
            this.property.filterDto.name = "";
            this.property.listElement.masterAssetCategory.rows = 0;
            this.getOffice();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async authorizationMasterAssetCategory(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        const confirmation = await this.simpleConfirmation(
          "Otorisasi Data Ini ?",
          "is-success"
        );
        if (!confirmation) {
          return;
        }
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA_AUTHORIZED",
            endPoint: "parameter",
            reqUrl: "asset/authorized/" + props.row.assetId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            await this.simpleWait(1000);
            this.property.listElement.masterAssetCategory.currentPage = 1;
            this.property.listElement.masterAssetCategory.rows = 0;
            this.property.filterDto.name = "";
            this.getMasterAssetCategory();
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
  },
  mounted() {
    this.getMasterAssetCategory();
  },
};
