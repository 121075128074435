export default {
  name: "ListMTransactionCode",
  data() {
    return {
      property: {
        listElement: {
          transactionCode: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          name: "",
          transactionCode: "",
        },
      },
      options: {
        journalAllocation: [],
      },
      table: {
        header: {},
        data: {
          transactionCode: [],
        },
      },
    };
  },
  methods: {
    async findTransactionCode() {
      this.table.data.transactionCode = [];
      const timeout = 500;
      this.property.listElement.transactionCode.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "transaction-code",
          payload: {
            name: this.property.filterDto.name,
            transactionCode: this.property.filterDto.transactionCode,
            page: 0,
            size: this.property.listElement.transactionCode.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.transactionCode = resp.data.data.content;
            this.property.listElement.transactionCode.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.transactionCode.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataTransactionCode(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorTransactionCode(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.transactionCode.downloading = false;
        }, timeout + 500);
      }
    },
    async deleteTransactionCode(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "parameter",
                reqUrl: "transaction-code/" + props.row.transactionCodeId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.transactionCode.currentPage = 1;
                  this.property.filterDto.name = "";
                  this.property.listElement.transactionCode.rows = 0;
                  this.getTransactionCode();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageAddTransactionCode() {
      this.$router.push("/parameter/transaction-code/new");
    },
    routeToPageEditTransactionCode(props) {
      this.$router.push(
        `/parameter/transaction-code/${this.encryptBASE64(
          `${props.row.transactionCodeId}`
        )}`
      );
    },

    journalAlocationFinder(id) {
      const journalAllocationFind = this.options.journalAllocation.find(
        (index) => index.value === id
      );
      return journalAllocationFind ? journalAllocationFind.text : "";
    },
    authorizationTransactionCode() {
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.$buefy.toast.open(this.$NotificationUtils.apiNotFound);
        },
      });
    },
    handleErrorTransactionCode(error) {
      console.log(error.response);
      this.table.data.transactionCode = [];
      this.property.listElement.transactionCode.rows = 0;
      this.property.listElement.transactionCode.currentPage = 1;
      this.property.listElement.transactionCode.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationTransactionCode(event) {
      this.table.data.transactionCode = [];
      this.property.listElement.transactionCode.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "transaction-code",
          payload: {
            name: this.property.filterDto.name,
            page: event - 1,
            size: this.property.listElement.transactionCode.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.transactionCode = resp.data.data.content;
            this.property.listElement.transactionCode.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataTransactionCode(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorTransactionCode(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.transactionCode.downloading = false;
        }, timeout);
      }
    },
    failedGetDataTransactionCode(resp) {
      this.table.data.transactionCode = [];
      this.property.listElement.transactionCode.rows = 0;
      this.property.listElement.transactionCode.message = resp.data.message;
    },
    async getTransactionCode() {
      this.table.data.transactionCode = [];
      this.property.listElement.transactionCode.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "transaction-code",
          payload: {
            name: "",
            page: 0,
            size: this.property.listElement.transactionCode.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.transactionCode = resp.data.data.content;
            this.property.listElement.transactionCode.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataTransactionCode(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorTransactionCode(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.transactionCode.downloading = false;
        }, timeout);
      }
    },
    async getReferenceJournalAllocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "journal-allocation",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.journalAllocationName;
            const value = i.journalAllocationId;
            this.options.journalAllocation.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getTransactionCode();
    this.getReferenceJournalAllocation();
  },
};
