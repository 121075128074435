export default {
  state: {
    identifier: {
      depositId: "",
    },
    options: {
      depositNisbahChartOfAccount: {
        depositChartOfAccount: [],
      },
    },
  },
  actions: {
    CLEAR_OPTIONS_DEPOSIT({ commit }, payload) {
      commit("CLEAR_OPTIONS_DEPOSIT");
    },
    SET_IDENTIFIER_DEPOSIT_ID({ commit }, payload) {
      commit("SET_IDENTIFIER_DEPOSIT_ID", payload);
    },
    SET_OPTIONS_DEPOSIT_CHART_OF_ACCOUNT_DEPOSIT_NISBAH_CHART_OF_ACCOUNT(
      { commit },
      payload
    ) {
      commit(
        "SET_OPTIONS_DEPOSIT_CHART_OF_ACCOUNT_DEPOSIT_NISBAH_CHART_OF_ACCOUNT",
        payload
      );
    },
  },
  mutations: {
    SET_IDENTIFIER_DEPOSIT_ID(state, payload) {
      state.identifier.depositId = payload;
    },
    SET_OPTIONS_DEPOSIT_CHART_OF_ACCOUNT_DEPOSIT_NISBAH_CHART_OF_ACCOUNT(
      state,
      payload
    ) {
      state.options.depositNisbahChartOfAccount.depositChartOfAccount = payload;
    },
    CLEAR_OPTIONS_DEPOSIT(state, payload) {
      state.identifier.depositId = "";
      state.options.depositNisbahChartOfAccount.depositChartOfAccount = [];
    },
  },
  getters: {
    get_identifier_deposit_id: (state) => state.identifier.depositId,
    get_options_deposit_chart_of_account_deposit_nisbah_chart_of_account: (
      state
    ) => state.options.depositNisbahChartOfAccount.depositChartOfAccount,
  },
};
