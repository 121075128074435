import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "MAccountOfficer",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      property: {
        animation: {
          addAccountOfficer: {
            isLoading: false,
          },
          editAccountOfficer: {
            isLoading: false,
          },
        },
        listElement: {
          accountOfficer: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },

        filterDto: {
          accountOfficerName: "",
          accountOfficerCode: "",
          officeId: "",
          jobTitleInitial: "",
        },
        modal: {
          showModalAddAccountOfficer: false,
          showModalEditAccountOfficer: false,
          showModalDetailAccountOfficer: false,
          label: "",
        },
      },
      options: {
        cabang: [],
        jobTitle: [
          {
            text: "--- Pilih ---",
            value: "",
          },
          {
            text: "REMEDIAL",
            value: "R",
          },
          {
            text: "MARKETING",
            value: "M",
          },
          {
            text: "FUNDING",
            value: "F",
          },
        ],
      },
      dataForm: {
        add: {
          officeId: "",
          accountOfficerCode: "",
          accountOfficerName: "",
          accountOfficerTelephoneNumber: "",
          jobTitleInitial: "",
          nik: "",
        },
        edit: {
          officeId: "",
          accountOfficerCode: "",
          accountOfficerName: "",
          accountOfficerTelephoneNumber: "",
          accountOfficerId: "",
          jobTitleInitial: "",
          nik: "",
        },
        detail: {
          officeId: "",
          accountOfficerCode: "",
          accountOfficerName: "",
          accountOfficerTelephoneNumber: "",
          accountOfficerId: "",
          createdBy: "",
          createdDate: "",
          updatedBy: "",
          updatedDate: "",
          jobTitleInitial: "",
          nik: "",
        },
      },
      table: {
        data: {
          accountOfficer: [],
        },
      },
    };
  },
  methods: {
    async updateStatusAccountOfficer(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      if (props.row.statusId === "2") {
        const confirm = await this.simpleConfirmation(
          "Aktifkan Data Ini ?",
          "is-success"
        );
        if (!confirm) return;
      } else if (props.row.statusId === "3") {
        const confirm = await this.simpleConfirmation(
          "Nonaktifkan Data Ini ?",
          "is-success"
        );
        if (!confirm) return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/update-status/" + props.row.accountOfficerId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.accountOfficer.currentPage = 1;
          this.property.filterDto.accountOfficerName = "";
          this.property.filterDto.accountOfficerCode = "";
          this.property.filterDto.officeId = "";
          this.property.filterDto.jobTitleInitial = "";
          this.property.listElement.accountOfficer.rows = 0;
          this.getAccountOfficer();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async searchByNameAccountOfficer() {
      this.table.data.accountOfficer = [];
      this.property.listElement.accountOfficer.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer",
          payload: {
            accountOfficerName: this.property.filterDto.accountOfficerName,
            accountOfficerCode: this.property.filterDto.accountOfficerCode,
            officeId: this.property.filterDto.officeId,
            jobTitleInitial: this.property.filterDto.jobTitleInitial,
            page: 0,
            size: this.property.listElement.accountOfficer.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.accountOfficer = resp.data.data.content;
            this.property.listElement.accountOfficer.rows =
              resp.data.data.totalElements;
            this.property.listElement.accountOfficer.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataAccountOfficer(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAccountOfficer();
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.accountOfficer.downloading = false;
        }, timeout + 500);
      }
    },
    async deleteAccountOfficer(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/" + props.row.accountOfficerId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.toast.open(this.$NotificationUtils.success);
          setTimeout(() => {
            this.property.listElement.accountOfficer.currentPage = 1;
            this.property.filterDto.name = "";
            this.property.listElement.accountOfficer.rows = 0;
            this.getAccountOfficer();
          }, 1000);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              officeName: "",
              page: 0,
            },
            url: "office",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.cabang.push({ value: "", text: "-- Semua Cabang --" });
          resp.data.data.content.map((index) => {
            const value = index.officeId;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.cabang.push({
              value,
              text,
            });
          });
        }
      } catch (error) {}
    },
    // authorizationAccountOfficer(props) {
    //   if (window.confirm("Otorisasi Data Ini ?")) {
    //     this.$buefy.toast.open(this.$NotificationUtils.apiNotFound);
    //   }
    // },
    async changePaginationAccountOfficer(event) {
      this.table.data.accountOfficer = [];
      this.property.listElement.accountOfficer.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer",
          payload: {
            accountOfficerName: this.property.filterDto.accountOfficerName,
            accountOfficerCode: this.property.filterDto.accountOfficerCode,
            officeId: this.property.filterDto.officeId,
            jobTitleInitial: this.property.filterDto.jobTitleInitial,
            page: event - 1,
            size: this.property.listElement.accountOfficer.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.accountOfficer = resp.data.data.content;
            this.property.listElement.accountOfficer.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataAccountOfficer(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAccountOfficer(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.accountOfficer.downloading = false;
        }, timeout);
      }
    },
    async addAccountOfficer() {
      const payload = {
        officeId: this.dataForm.add.officeId,
        accountOfficerCode: this.dataForm.add.accountOfficerCode,
        accountOfficerName: this.dataForm.add.accountOfficerName,
        accountOfficerTelephoneNumber: this.dataForm.add
          .accountOfficerTelephoneNumber,
        jobTitleInitial: this.dataForm.add.jobTitleInitial,
        nik: this.dataForm.add.nik,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.property.animation.addAccountOfficer.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer",
          payload: payload,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            this.closeModalAddAccountOfficer();
            this.getAccountOfficer();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 1500);
      } catch (error) {
        console.log(error.response);
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.addAccountOfficer.isLoading = false;
        }, 1000);
      }
    },
    async editAccountOfficer() {
      const payload = {
        officeId: this.dataForm.edit.officeId,
        accountOfficerCode: this.dataForm.edit.accountOfficerCode,
        accountOfficerName: this.dataForm.edit.accountOfficerName,
        accountOfficerTelephoneNumber: this.dataForm.edit
          .accountOfficerTelephoneNumber,
        jobTitleInitial: this.dataForm.edit.jobTitleInitial,
        nik: this.dataForm.edit.nik,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.property.animation.editAccountOfficer.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/" + this.dataForm.edit.accountOfficerId,
          payload: payload,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            this.property.listElement.accountOfficer.currentPage = 1;
            this.property.filterDto.name = "";
            this.property.listElement.accountOfficer.rows = 0;
            this.getAccountOfficer();

            this.closeModalEditAccountOfficer();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.editAccountOfficer.isLoading = false;
        }, 1000);
      }
    },
    closeModalAddAccountOfficer() {
      this.property.modal.showModalAddAccountOfficer = false;
      this.property.animation.addAccountOfficer.isLoading = false;
      this.dataForm.add.officeId = "";
      this.dataForm.add.accountOfficerCode = "";
      this.dataForm.add.accountOfficerName = "";
      this.dataForm.add.accountOfficerTelephoneNumber = "";
      this.dataForm.add.jobTitleInitial = "";
      this.dataForm.add.nik = "";
    },
    async showModalEditAccountOfficer(props) {
      this.property.modal.showModalEditAccountOfficer = true;
      this.property.modal.label = "FORM EDIT ACCOUNT OFFICER";
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl: "account-officer/" + props.row.accountOfficerId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.accountOfficerId =
              resp.data.data.accountOfficerId;
            this.dataForm.edit.officeId = resp.data.data.officeId;
            this.dataForm.edit.accountOfficerCode =
              resp.data.data.accountOfficerCode;
            this.dataForm.edit.accountOfficerName =
              resp.data.data.accountOfficerName;

            this.dataForm.edit.accountOfficerTelephoneNumber =
              resp.data.data.accountOfficerTelephoneNumber;
            this.dataForm.edit.jobTitleInitial = resp.data.data.jobTitleInitial;
            this.dataForm.edit.nik = resp.data.data.nik;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async showModalDetailAccountOfficer(props) {
      this.property.modal.showModalDetailAccountOfficer = true;
      this.property.modal.label = "FORM DETAIL ACCOUNT OFFICER";
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl: "account-officer/" + props.row.accountOfficerId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.detail.accountOfficerId =
              resp.data.data.accountOfficerId;
            this.dataForm.detail.officeId = resp.data.data.officeId;
            this.dataForm.detail.accountOfficerCode =
              resp.data.data.accountOfficerCode;
            this.dataForm.detail.accountOfficerName =
              resp.data.data.accountOfficerName;
            this.dataForm.detail.accountOfficerTelephoneNumber =
              resp.data.data.accountOfficerTelephoneNumber;
            this.dataForm.detail.createdBy = resp.data.data.createdBy;
            this.dataForm.detail.createdDate = resp.data.data.createdDate;
            this.dataForm.detail.updatedBy = resp.data.data.updatedBy;
            this.dataForm.detail.updatedDate = resp.data.data.updatedDate;
            this.dataForm.detail.jobTitleInitial =
              resp.data.data.jobTitleInitial;
            this.dataForm.detail.nik = resp.data.data.nik;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    closeModalDetailAccountOfficer() {
      this.property.modal.showModalDetailAccountOfficer = false;
      this.dataForm.detail.accountOfficerId = "";
      this.dataForm.detail.officeId = "";
      this.dataForm.detail.accountOfficerCode = "";
      this.dataForm.detail.accountOfficerName = "";
      this.dataForm.detail.accountOfficerTelephoneNumber = "";
      this.dataForm.detail.jobTitleInitial = "";
      this.dataForm.detail.nik = "";
    },
    closeModalEditAccountOfficer() {
      this.property.modal.showModalEditAccountOfficer = false;
      this.dataForm.edit.accountOfficerId = "";
      this.dataForm.edit.officeId = "";
      this.dataForm.edit.accountOfficerCode = "";
      this.dataForm.edit.accountOfficerName = "";
      this.dataForm.edit.accountOfficerTelephoneNumber = "";
      this.dataForm.edit.jobTitleInitial = "";
      this.dataForm.edit.nik = "";
    },
    showModalAddAccountOfficer() {
      this.property.modal.showModalAddAccountOfficer = true;
      this.property.modal.label = "FORM ADD ACCOUNT OFFICER";
    },
    failedGetDataAccountOfficer(resp) {
      this.table.data.accountOfficer = [];
      this.property.listElement.accountOfficer.rows = 0;
      this.property.listElement.accountOfficer.message = resp.data.message
        ? "DATA NOT FOUND"
        : "";
    },
    handleErrorGetAccountOfficer(error) {
      this.property.listElement;
      this.table.data.accountOfficer = [];
      this.property.listElement.accountOfficer.rows = 0;
      this.property.listElement.accountOfficer.currentPage = 1;
      this.property.listElement.accountOfficer.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getAccountOfficer() {
      this.table.data.accountOfficer = [];
      this.property.listElement.accountOfficer.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer",
          payload: {
            accountOfficerName: "",
            accountOfficerCode: "",
            officeId: "",
            jobTitleInitial: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.accountOfficer = resp.data.data.content;
            this.property.listElement.accountOfficer.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataAccountOfficer(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAccountOfficer(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.accountOfficer.downloading = false;
        }, timeout);
      }
    },
  },
  mounted() {
    this.getAccountOfficer();
    this.getReferenceOffice();
  },
};
