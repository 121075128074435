export default {
  name: "CollectorFunding",
  data() {
    return {
      isShow: false,
      property: {
        listElement: {
          collectorFunding: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          userName: "",
          officeId: "",
          chartOfAccountCode: "",
        },
        animation: {
          isDownloadingFile: false,
        },
      },
      table: {
        header: {},
        data: {
          collectorFunding: [],
        },
      },
      options: {
        coa: [],
        cabang: [],
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      console.log("filetype", fileType);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        userName: this.property.filterDto.userName,
        chartOfAccountCode: this.property.filterDto.chartOfAccountCode,
        officeId: this.property.filterDto.officeId,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "collector-funding/files",
          payload: payload,
        });
        console.log("download", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Collector_Funding_.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    failedGetCollectorFunding(resp) {
      this.property.listElement.collectorFunding.rows = 0;
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.message = resp.data.message;
    },
    handleErrorGetCollectorFunding(error) {
      console.log(error.response);
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.rows = 0;
      this.property.listElement.collectorFunding.currentPage = 1;
      this.property.listElement.collectorFunding.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getCollectorFunding() {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding/simple-list",
          payload: {
            userName: "",
            chartOfAccountCode: "",
            officeId: "",
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    async searchCollectorFunding() {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding/simple-list",
          payload: {
            userName: this.property.filterDto.userName,
            chartOfAccountCode: this.property.filterDto.chartOfAccountCode,
            officeId: this.property.filterDto.officeId,
            page: 0,
            size: this.property.listElement.collectorFunding.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
            this.property.listElement.collectorFunding.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    async changePaginationCollectorFunding(event) {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding/simple-list",
          payload: {
            userName: this.property.filterDto.userName,
            chartOfAccountCode: this.property.filterDto.chartOfAccountCode,
            officeId: this.property.filterDto.officeId,
            page: event - 1,
            size: this.property.listElement.collectorFunding.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
            this.property.listElement.collectorFunding.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    async authorizationCollectorFunding(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "collector-funding/authorized/" +
                  props.row.collectorFundingId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.collectorFunding.currentPage = 1;
                  this.property.filterDto.userName = "";
                  this.property.filterDto.chartOfAccountCode = "";
                  this.property.filterDto.officeId = "";
                  this.property.listElement.collectorFunding.rows = 0;
                  this.getCollectorFunding();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routerToPageAddCollectorFundingSetor() {
      this.$router.push("/collector-funding-detail/add/setor");
    },
    routeToPageRincianCollectorFunding(props) {
      this.$router.push(
        `/collector-funding/${this.encryptBASE64(
          `${props.row.collectorFundingId}`
        )}`
      );
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.cabang = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((data) => {
            var value = data.officeId;
            var text = data.officeName;
            this.options.cabang.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.coa = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountCode;
            const description = index.description;
            this.options.coa.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {}
    },
    async deleteCollectorFunding(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "collector-funding/" + props.row.collectorFundingId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                this.property.listElement.collectorFunding.currentPage = 1;
                this.property.filterDto.userName = "";
                this.property.filterDto.chartOfAccountCode = "";
                this.property.filterDto.officeId = "";
                this.property.listElement.collectorFunding.rows = 0;
                this.getCollectorFunding();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getCollectorFunding();
    this.getReferenceOffice();
    this.getReferenceChartOfAccount();
  },
};
