export default {
  name: "ListTLoanSp3Reschedule",
  data() {
    return {
      options: {
        restructure: [],
      },
      identifier: {
        loanSp3Id: "",
      },
      property: {
        modal: {
          showModalCheckingLoanSp3: false,
          showModalResultListLoanSp3: false,
          showModalResultListSubContract: false,
        },
        animation: {
          checkingSp3: {
            isLoading: false,
          },
        },
        listElement: {
          loanSp3Reschedule: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanSp3: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          cifNumber: "",
          cifIdName: "",
          sp3DocNumber: "",
          isRestruct2: false,
        },
      },
      dataForm: {
        loanSp3Checking: {
          cifIdName: "",
          sp3DocNumber: "",
        },
      },
      session: {
        loanSp3Checking: {
          cifIdName: "",
          sp3DocNumber: "",
        },
      },
      table: {
        data: {
          loanSp3Reschedule: [],
          loanSp3: [],
          subLoanAccount: [],
        },
      },
    };
  },
  methods: {
    async routeToPageAddLoanAccountReschedule(props) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "transaction-loan/sp3id/" + props.row.loanSp3Id,
          endPoint: "loan",
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: "Sp3 Sudah Di Gunakan Kontrak Lain",
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.identifier.loanSp3Id = props.row.loanSp3Id;
          this.findLoanAccountOld(props);
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async findLoanAccountOld(props) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "transaction-loan/sp3id/" + props.row.loanSp3ParentId,
          endPoint: "loan",
        });
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data.isLineFacility === true) {
            this.findSubLoanAccountOldByLoanAccountParentId(resp);
            return;
          }
          this.$router.push(
            `/loan/loan-account-reschedule/new?refId=${this.encryptBASE64(
              JSON.stringify({
                loanAccountId: resp.data.data.loanAccountId,
                loanSp3Id: props.row.loanSp3Id,
              })
            )}`
          );
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async findSubLoanAccountOldByLoanAccountParentId(data) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl:
            "transaction-loan/subcontract/" + data.data.data.loanAccountId,
          endPoint: "loan",
        });
        if (resp.data.code === "SUCCESS") {
          this.table.data.subLoanAccount = resp.data.data;
          this.property.modal.showModalResultListSubContract = true;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handleCloseModalCheckingLoanSp3() {
      this.property.modal.showModalCheckingLoanSp3 = false;
      this.dataForm.loanSp3Checking.sp3DocNumber = "";
      this.dataForm.loanSp3Checking.cifIdName = "";
    },
    handleCloseModalResultCheckingLoanSp3() {
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.rows = 0;
      this.property.listElement.loanSp3.currentPage = 1;
      this.property.listElement.loanSp3.perPage = 5;
    },
    handleCloseModalResultSubLoanAccount() {
      this.table.data.subLoanAccount = [];
      this.identifier.loanSp3Id = "";
      this.property.modal.showModalResultListSubContract = false;
    },
    async findLoanSp3Reschedule() {
      this.table.data.loanSp3Reschedule = [];
      this.property.listElement.loanSp3Reschedule.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-sp3/simple-list",
          payload: {
            cifNumber: "",
            cifIdName: this.property.filterDto.cifIdName,
            sp3Subject: "",
            sp3DocumentFile1: "",
            sp3DocNumber: this.property.filterDto.sp3DocNumber,
            isHaveParent: true,
            isRestruct2: false,
            isAvailSp3: false,
            page: 0,
            size: this.property.listElement.loanSp3Reschedule.perPage,
          },
        });

        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanSp3Reschedule = resp.data.data.content;
            this.property.listElement.loanSp3Reschedule.rows =
              resp.data.data.totalElements;
            this.property.listElement.loanSp3Reschedule.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetDataLoanSp3Reschedule(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanSp3Reschedule(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanSp3Reschedule.downloading = false;
        }, timeout + 500);
      }
    },

    async handleChangePaginationLoanSp3Reschedule(event) {
      this.table.data.loanSp3Reschedule = [];
      this.property.listElement.loanSp3Reschedule.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-sp3/simple-list",
          payload: {
            cifNumber: "",
            sp3Subject: "",
            sp3DocumentFile1: "",
            isRestruct2: false,
            isHaveParent: true,
            isAvailSp3: false,
            sp3DocNumber: this.property.filterDto.sp3DocNumber,
            cifIdName: this.property.filterDto.cifIdName,
            page: event - 1,
            size: this.property.listElement.loanSp3Reschedule.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanSp3Reschedule = resp.data.data.content;
            this.property.listElement.loanSp3Reschedule.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataLoanSp3Reschedule(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanSp3Reschedule(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanSp3Reschedule.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataLoanSp3Reschedule(resp) {
      this.table.data.loanSp3Reschedule = [];
      this.property.listElement.loanSp3Reschedule.rows = 0;
      this.property.listElement.loanSp3Reschedule.message = resp.data.message;
    },
    selectSubLoanAccountForReschedule(props) {
      const payload = {
        loanSP3Id: this.identifier.loanSp3Id,
        akadNumberOld: props.row.akadNumber,
        loanAccountIdOld: props.row.loanAccountId,
      };
      sessionStorage.setItem(
        "PAYLOAD_LOAN_ACCOUNT_RESCHEDULE",
        JSON.stringify(payload)
      );
      this.$router.push("/loan/loan-account-reschedule/add");
    },
    async handleCheckingLoanSp3() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.table.data.loanSp3 = [];
        this.property.animation.checkingSp3.isLoading = true;
        this.property.listElement.loanSp3.rows = 0;
        this.session.loanSp3Checking.sp3DocNumber = this.dataForm.loanSp3Checking.sp3DocNumber;
        this.session.loanSp3Checking.cifIdName = this.dataForm.loanSp3Checking.cifIdName;
        this.dataForm.loanSp3Checking.sp3DocNumber = "";
        this.dataForm.loanSp3Checking.cifIdName = "";
        const timeout = 500;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "loan",
            reqUrl: "loan-sp3/simple-list",
            payload: {
              cifNumber: "",
              page: 0,
              size: this.property.listElement.loanSp3.perPage,
              isHaveParent: false,
              isRestruct2: false,
              isAvailSp3: false,
              cifIdName: this.session.loanSp3Checking.cifIdName,
              sp3DocNumber: this.session.loanSp3Checking.sp3DocNumber,
              sp3Subject: "",
              sp3DocumentFile1: "",
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingLoanSp3 = false;
              this.property.modal.showModalResultListLoanSp3 = true;
              this.table.data.loanSp3 = resp.data.data.content;
              this.property.listElement.loanSp3.rows =
                resp.data.data.totalElements;
            } else {
              this.dataForm.loanSp3Checking.sp3DocNumber = this.session.loanSp3Checking.sp3DocNumber;
              this.dataForm.loanSp3Checking.cifIdName = this.session.loanSp3Checking.cifIdName;
              this.session.loanSp3Checking.sp3DocNumber = "";
              this.session.loanSp3Checking.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          console.log(error.response);
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingSp3.isLoading = false;
          }, timeout);
        }
      }
    },
    async handleDeleteDataLoanSp3Reschedule(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-sp3/" + props.row.loanSp3Id,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.loanSp3Reschedule.currentPage = 1;
                  this.property.listElement.loanSp3Reschedule.rows = 0;
                  this.property.filterDto.sp3DocNumber = "";
                  this.property.filterDto.cifIdName = "";
                  this.helperGetDataTableLoanSp3Reschedule();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleCatchErrorLoanSp3(error) {
      console.log(error.response);
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.rows = 0;
      this.property.listElement.loanSp3.currentPage = 1;
      this.property.listElement.loanSp3.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleCatchErrorLoanSp3Reschedule(error) {
      console.log(error.response);
      this.table.data.Reschedule = [];
      this.property.listElement.loanSp3Reschedule.rows = 0;
      this.property.listElement.loanSp3Reschedule.currentPage = 1;
      this.property.listElement.loanSp3Reschedule.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async helperGetDataTableLoanSp3Reschedule() {
      this.table.data.loanSp3Reschedule = [];
      this.property.listElement.loanSp3Reschedule.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-sp3/simple-list",
          payload: {
            cifNumber: "",
            sp3Subject: "",
            sp3DocumentFile1: "",
            cifIdName: "",
            isRestruct2: false,
            isHaveParent: true,
            sp3DocNumber: "",
            isAvailSp3: false,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanSp3Reschedule = resp.data.data.content;
            this.property.listElement.loanSp3Reschedule.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataLoanSp3Reschedule(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanSp3Reschedule(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanSp3Reschedule.downloading = false;
        }, timeout);
      }
    },
    handleRouteToPageEditLoanSp3Reschedule(props) {
      this.$router.push(
        `/loan/loan-sp3-reschedule/${this.encryptBASE64(
          `${props.row.loanSp3Id}`
        )}`
      );
    },
    handleCatchLoanSp3(error) {
      console.log(error.response);
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.rows = 0;
      this.property.listElement.loanSp3.currentPage = 1;
      this.property.listElement.loanSp3.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangePaginationCheckingLoanSp3(event) {
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-sp3/simple-list",
          payload: {
            cifNumber: "",
            cifIdName: this.session.loanSp3Checking.cifIdName,
            page: event - 1,
            sp3Subject: "",
            sp3DocNumber: this.session.loanSp3Checking.sp3DocNumber,
            isRestruct2: false,
            isHaveParent: false,
            isAvailSp3: false,
            sp3DocumentFile1: "",
            size: this.property.listElement.loanSp3.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanSp3 = resp.data.data.content;
            this.property.listElement.loanSp3.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataLoanSp3(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchLoanSp3(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanSp3.downloading = false;
        }, timeout);
      }
    },
    handleChargingOptionsComponent() {
      this.options.restructure.push(
        {
          value: true,
          text: "Ya",
        },
        {
          value: false,
          text: "Tidak",
        }
      );
    },
    handleFailedGetDataLoanSp3(resp) {
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.rows = 0;
      this.property.listElement.loanSp3.message = resp.data.message;
    },
    handleRouteToPageAddLoanSp3Reschedule(props) {
      this.$router.push(
        `/loan/loan-sp3-reschedule/new?refId=${this.encryptBASE64(
          JSON.stringify({ loanSp3Id: props.row.loanSp3Id })
        )}`
      );
    },
  },
  mounted() {
    this.helperGetDataTableLoanSp3Reschedule();
    this.handleChargingOptionsComponent();
  },
};
