export default {
  name: "ListTSavingAccountDetailCloseSavingAccount",
  data() {
    return {
      form: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        modal: {
          showModalCheckingSavingAccount: false,
          showModalResultListSavingAccount: false,
        },
        animation: {
          CheckingSavingAccount: {
            isLoading: false,
          },
        },
        listElement: {
          savingAccountClose: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          dateFrom: "",
          dateTo: "",
        },
      },
      table: {
        data: {
          savingAccountClose: [],
          savingAccount: [],
        },
      },
    };
  },
  methods: {
    async searchByAccountNumber(event) {
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: {
            accountNumber: event,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.savingAccountClose.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccountClose = resp.data.data.content;
          this.property.listElement.savingAccountClose.rows =
            resp.data.data.totalElements;
          this.property.listElement.savingAccountClose.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataSavingAccountClose(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorSavingAccountClose(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountClose.downloading = false;
      }
    },
    async searchByDateFrom(event) {
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            dateFrom: event,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.savingAccountClose.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccountClose = resp.data.data.content;
          this.property.listElement.savingAccountClose.rows =
            resp.data.data.totalElements;
          this.property.listElement.savingAccountClose.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataSavingAccountClose(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorSavingAccountClose(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountClose.downloading = false;
      }
    },
    async searchByDateTo(event) {
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: event,
            page: 0,
            size: this.property.listElement.savingAccountClose.perPage,
          },
        });

        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccountClose = resp.data.data.content;
          this.property.listElement.savingAccountClose.rows =
            resp.data.data.totalElements;
          this.property.listElement.savingAccountClose.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataSavingAccountClose(resp);
        }
      } catch (error) {
        this.handleErrorSavingAccountClose(error);
      } finally {
        this.property.listElement.savingAccountClose.downloading = false;
      }
    },
    routeToPageAddSavingAccountClose(props) {
      this.$router.push(
        `/saving/close-saving-account/new?refId=${this.encryptBASE64(
          JSON.stringify({
            savingAccountId: props.row.savingAccountId,
          })
        )}`
      );
    },
    routeToPageDetailSavingAccountClose(props) {
      this.$router.push(
        `/saving/close-saving-account/${this.encryptBASE64(
          `${props.row.savingAccountDetailId}`
        )}`
      );
    },
    async authorizationSavingAccountClose(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/close-saving-account/authorized/" +
            props.row.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.savingAccountClose.currentPage = 1;
          this.property.filterDto.accountNumber = "";
          this.property.filterDto.dateFrom = "";
          this.property.filterDto.dateTo = "";
          this.property.listElement.savingAccountClose.rows = 0;
          this.getSavingAccountClose();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handleErrorSavingAccountClose(error) {
      console.log(error.response);
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.rows = 0;
      this.property.listElement.savingAccountClose.currentPage = 1;
      this.property.listElement.savingAccountClose.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationSavingAccountClose(event) {
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.savingAccountClose.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccountClose = resp.data.data.content;
          this.property.listElement.savingAccountClose.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountClose(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorSavingAccountClose(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountClose.downloading = false;
      }
    },
    async getSavingAccountClose() {
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: {
            accountNumber: "",
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccountClose = resp.data.data.content;
          this.property.listElement.savingAccountClose.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountClose(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorSavingAccountClose(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountClose.downloading = false;
      }
    },
    failedGetDataSavingAccountClose(resp) {
      this.property.listElement.savingAccountClose.message = resp.data.message;
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.rows = 0;
    },
    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
        this.form.checkingSavingAccount.accountNumber = "";
        this.form.checkingSavingAccount.cifIdName = "";

        try {
          this.property.animation.CheckingSavingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.content;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.property.modal.showModalResultListSavingAccount = true;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
          this.form.checkingSavingAccount.accountNumber = "";
          this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
          this.form.checkingSavingAccount.cifIdName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.CheckingSavingAccount.isLoading = false;
        }
      }
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.sav;
      ingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    closeModalCheckingSavingAccount() {
      this.property.modal.showModalCheckingSavingAccount = false;
      this.clearModalCheckingSavingAccount();
    },
    clearModalCheckingSavingAccount() {
      this.form.checkingSavingAccount.accountNumber = "";
    },
  },
  mounted() {
    this.getSavingAccountClose();
  },
};
