import Notification from "../../../../components/notifications";
import Moment from "moment";

export default {
  name: "ListTLoanCollectability",
  data() {
    return {
      property: {
        animation: {
          addLoanCollectability: {
            isLoading: false,
          },
          checkingAccount: {
            isLoading: false,
          },
        },
        modal: {
          showModalNoKontrak: false,
          showModalResultAccount: false,
        },
        filterDto: {
          loanCollectabilityId: "",
          accountNumber: "",
          cifIdName: "",
        },
      },
      dataForm: {
        searchLoanData: {
          loanAccountNumber: "",
          cifIdName: "",
        },
        loanAccountId: 0,
        loanCollectabilityId: "",
        accountName: "",
        accountCabang: "",
        loanName: "",
        nominal: 0,
        endDate: "",
        collectabilityNow: "",
        description: "",
      },
      session: {
        loanData: {
          loanAccountNumber: "",
          cifIdName: "",
        },
      },
      table: {
        data: {
          loanCollectability: [],
          listAccount: [],
        },
        pagination: {
          loanCollectability: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
    };
  },
  methods: {
    async helperGetLoanCollectabilityDataTable() {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability-draft",
          payload: {
            loanCollectabilityId: "",
            loanAccountNumber: "",
            page: 0,
            size: this.table.pagination.loanCollectability.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollectability = resp.data.data.content;
            this.table.pagination.loanCollectability.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetLoanCollectability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanCollectability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.table.pagination.loanCollectability.downloading = false;
        }, timeout);
      }
    },
    async changePaginationLoanCollectabilityDraft(event) {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability-draft",
          payload: {
            loanCollectabilityId: "",
            loanAccountNumber: "",
            //   rloanStatusId: 3,
            page: event - 1,
            size: this.table.pagination.loanCollectability.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollectability = resp.data.data.content;
            this.table.pagination.loanCollectability.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetLoanCollectability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleFailedGetLoanCollectability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.table.pagination.loanCollectability.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetLoanCollectability(resp) {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.rows = 0;
      this.table.pagination.loanCollectability.message = resp.data.message;
    },
    handleCatchErrorLoanCollectability(error) {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.rows = 0;
      this.table.pagination.loanCollectability.currentPage = 1;

      this.table.pagination.loanCollectability.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleCheckingLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.loanData.loanAccountNumber = this.dataForm.searchLoanData.loanAccountNumber;
        this.session.loanData.cifIdName = this.dataForm.searchLoanData.cifIdName;
        this.dataForm.searchLoanData.cifIdName = "";
        this.dataForm.searchLoanData.loanAccountNumber = "";
        try {
          this.property.animation.checkingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "loan",
            reqUrl: "t-collectability",
            payload: {
              accountNumber: this.session.loanData.loanAccountNumber,
              // cifIdName: this.session.loanData.cifIdName,
              // isSubContract: false,
              page: 0,
              size: this.table.pagination.loanAccount.perPage,
            },
          });
          if (resp.data.code === "SUCCESS") {
            this.table.data.listAccount = resp.data.data.content;
            this.property.modal.showModalNoKontrak = false;
            this.property.modal.showModalResultAccount = true;
          } else if (resp.data.code === "LOAN_ACCOUNT_NOT_FOUND") {
            this.dataForm.searchLoanData.loanAccountNumber = this.session.loanData.loanAccountNumber;
            this.dataForm.searchLoanData.cifIdName = this.session.loanData.cifIdName;
            this.session.loanData.loanAccountNumber = "";
            this.session.loanData.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Kontrak Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.dataForm.searchLoanData.loanAccountNumber = this.session.loanData.loanAccountNumber;
            this.session.loanData.loanAccountNumber = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message} !`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          this.dataForm.searchLoanData.loanAccountNumber = this.session.loanData.loanAccountNumber;
          this.session.loanData.loanAccountNumber = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          this.property.animation.checkingAccount.isLoading = false;
        }
      }
    },
    async handleChangePaginationLoanAccount(event) {
      this.table.data.listAccount = [];
      this.table.pagination.loanAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan",
          payload: {
            loanAccountNumber: this.session.loanData.loanAccountNumber,
            cifIdName: this.session.loanData.cifIdName,
            isSubContract: false,
            page: event - 1,
            size: this.table.pagination.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.listAccount = resp.data.data.content;
            this.table.pagination.loanAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.table.data.listAccount = [];
            this.table.pagination.loanAccount.rows = 0;
            this.table.pagination.loanAccount.message = resp.data.message;
          }
        }, timeout);
      } catch (error) {
        this.table.data.listAccount = [];
        this.table.pagination.loanAccount.rows = 0;
        this.table.pagination.loanAccount.currentPage = 1;
        this.table.pagination.loanAccount.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        setTimeout(() => {
          this.table.pagination.loanAccount.downloading = false;
        }, timeout);
      }
    },
    handleChooseLoanAccountReschedule(props) {
      this.dataForm.loanAccountId =
        props.row.tloanAccountResponseDto.loanAccountId;
      this.dataForm.accountName =
        props.row.tloanAccountResponseDto.tloanSp3.mcif.cifIdName;
      this.dataForm.accountCabang =
        props.row.tloanAccountResponseDto.moffice.officeName;
      this.dataForm.loanName = props.row.tloanAccountResponseDto.mloan.loanName;
      this.dataForm.collectabilityNow =
        props.row.tloanAccountResponseDto.mloan.rloanCollectabilityCode !== null
          ? props.row.tloanAccountResponseDto.mloan.rloanCollectabilityCode
            ? props.row.tloanAccountResponseDto.mloan.rloanCollectabilityCode
                .collectabilityCodeName
            : "-"
          : "-";
      this.dataForm.nominal = props.row.tloanAccountResponseDto.nominalLoan;
      this.dataForm.endDate = props.row.tloanAccountResponseDto.endDate;
      this.property.modal.showModalResultAccount = false;
      this.routeToPageAddLoanCollectability();
    },
    handleCloseModalResultLoanAccount() {
      this.property.modal.showModalResultAccount = false;
      this.handleClearInputModalCheckingLoanAccount();
    },
    handleClearInputModalCheckingLoanAccount() {
      this.dataForm.searchLoanData.loanAccountNumber = "";
      this.dataForm.searchLoanData.cifIdName = "";
    },
    routeToPageAddLoanCollectability() {
      const payload = {
        dataIdentifier: this.dataForm,
      };
      sessionStorage.setItem(
        "SESSION_LOAN_ACCOUNT_DATA",
        JSON.stringify(payload)
      );
      this.$router.push("add");
    },
    removeSessionLoanCollectability() {
      sessionStorage.removeItem("SESSION_LOAN_ACCOUNT_DATA");
      sessionStorage.removeItem("LOAN_COLLECTABILITY_ID");
    },
    routeToPageEditLoanCollectability(props) {
      sessionStorage.setItem(
        "LOAN_COLLECTABILITY_ID",
        props.row.loanCollectabilityId
      );
      this.$router.push("edit");
    },

    async handleSearchByLoanName(event) {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability-draft",
          payload: {
            loanCollectabilityId: "",
            loanAccountNumber: event,
            page: 0,
            size: this.table.pagination.loanCollectability.perPage,
          },
        });
        setTimeout(() => {
          // console.log(resp.data)
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollectability = resp.data.data.content;
            this.table.pagination.loanAccount.rows =
              resp.data.data.totalElements;
            this.table.pagination.loanAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetLoanCollectability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanCollectability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.table.pagination.loanCollectability.downloading = false;
        }, timeout + 500);
      }
    },

    // DETAIL
    handleRouteToPageDetailLoanCollectability(props) {
      sessionStorage.setItem(
        "LOAN_COLLECTABILITY_ID",
        props.row.loanCollectabilityId
      );
      this.$router.push("detail");
    },

    // DELETE
    async handleDeleteDataLoanCollectability(props) {
      const payload = {
        id: props.row.loanCollectabilityId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collectability-draft/" + props.row.loanCollectabilityId,
              });
              this.table.pagination.loanCollectability.downloading = true;
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.helperGetLoanCollectabilityDataTable();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // AUTHORIZE
    async handleAuthorizationLoanCollectability(props) {
      const payload = {
        id: props.row.loanCollectabilityId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collectability-draft/authorized/" +
                  props.row.loanCollectabilityId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.loanCollectabilityId = "";
                  this.property.filterDto.accountNumber = "";
                  this.table.pagination.loanCollectability.rows = 0;
                  this.table.pagination.loanCollectability.currentPage = 1;
                  this.helperGetLoanCollectabilityDataTable();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    changeDateCollectability(val) {
      Moment.locale("ID");
      if (val !== "") {
        var date = Moment(val).format("LL");
        return date;
      }
    },
  },
  mounted() {
    this.helperGetLoanCollectabilityDataTable();
    this.removeSessionLoanCollectability();
  },
};
