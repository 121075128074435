export default {
  name: "AtmChannelList",
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
        },
        filterDto: {
          atmChannelId: "",
          officeCode: "",
          atmChannelCode: "",
          atmChannelName: "",
        },
        listElement: {
          atmChannel: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },

      options: {
        office: [],
      },
      table: {
        data: {
          atmChannel: [],
        },
      },
    };
  },
  methods: {
    routeToPageHistory(props) {
      // console.log(props)
      sessionStorage.setItem("ATM_CHANNEL_ID", props.row.atmChannelId);
      sessionStorage.setItem("ATM_CHANNEL_CODE", props.row.atmChannelCode);
      sessionStorage.setItem("ATM_CHANNEL_NAME", props.row.atmChannelName);
      this.$router.push("atm-channel/history");
    },
    handleErrorGetAtmChannel(error) {
      console.log(error.response);
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.rows = 0;
      this.property.listElement.atmChannel.currentPage = 1;
      this.property.listElement.atmChannel.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetAtmChannel(resp) {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.rows = 0;
      this.property.listElement.atmChannel.message = resp.data.message;
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeCode;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async searchAtmChannel() {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel",
          payload: {
            atmChannelId: this.property.filterDto.atmChannelId,
            officeCode: this.property.filterDto.officeCode,
            atmChannelCode: this.property.filterDto.atmChannelCode,
            atmChannelName: this.property.filterDto.atmChannelName,
            page: 0,
            size: this.property.listElement.atmChannel.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.atmChannel = resp.data.data.content;
            this.property.listElement.atmChannel.rows =
              resp.data.data.totalElements;
            this.property.listElement.atmChannel.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetAtmChannel(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannel(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmChannel.downloading = false;
        }, timeout);
      }
    },
    async getTableAtmChannel() {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel",
          payload: {
            atmChannelId: "",
            officeCode: "",
            atmChannelCode: "",
            atmChannelName: "",
            page: 0,
            size: this.property.listElement.atmChannel.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.atmChannel = resp.data.data.content;
            this.property.listElement.atmChannel.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetAtmChannel(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannel(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmChannel.downloading = false;
        }, timeout);
      }
    },
    async changePaginationatmChannel(event) {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel",
          payload: {
            atmChannelId: this.property.filterDto.atmChannelId,
            officeCode: this.property.filterDto.officeCode,
            atmChannelCode: this.property.filterDto.atmChannelCode,
            atmChannelName: this.property.filterDto.atmChannelName,
            page: event - 1,
            size: this.property.listElement.atmChannel.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.atmChannel = resp.data.data.content;
            this.property.listElement.atmChannel.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetAtmChannel(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannel(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmChannel.downloading = false;
        }, timeout);
      }
    },
    routeToPageDetailatmChannel(props) {
      this.$router.push(
        `/atm-channel/${this.encryptBASE64(`${props.row.atmChannelId}`)}`
      );
    },
    routeToPageAddatmChannel() {
      this.$router.push("/atm-channel/new");
    },
    async deleteatmChannel(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "atm-channel/" + props.row.atmChannelId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.atmChannel.currentPage = 1;
                  this.property.filterDto.atmChannelId = "";
                  this.property.filterDto.officeCode = "";
                  this.property.filterDto.atmChannelCode = "";
                  this.property.filterDto.atmChannelName = "";
                  this.property.listElement.atmChannel.rows = 0;
                  this.getDepositBilyet();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getTableAtmChannel();
    this.getReferenceOffice();
  },
};
