import Notification from "../../../../components/notifications";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Moment from "moment";

export default {
  name: "AddTLoanCollectability",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      property: {
        animation: {
          addLoanCollectability: {
            isLoading: false,
          },
          checkingAccount: {
            isLoading: false,
          },
        },
        listElement: {
          loanAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          loanSp3: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        identifier: {
          loanCollectabilityId: "",
        },
        loanAccountId: 0,
        loanCollectabilityId: "",
        accountName: "",
        accountCabang: "",
        loanName: "",
        nominal: 0,
        endDate: "",
        collectabilityNow: "",
        description: "",
      },
      session: {
        loanData: {
          loanAccountNumber: "",
          cifIdName: "",
        },
      },
      options: {
        kodeKolektibilitas: [],
      },
      table: {
        header: {},
        data: {
          depositOverbook: [],
          listAccount: [],
        },
      },
      selected: new Date(),
    };
  },
  methods: {
    async handleEditLoanCollectability() {
      const payload = {
        loanAccountId: this.dataForm.loanAccountId,
        loanCollectabilityId: this.dataForm.loanCollectabilityId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addLoanCollectability.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collectability-draft/" +
                  this.dataForm.identifier.loanCollectabilityId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.handleRouteToPageListLoanCollectability();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addLoanCollectability.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    handleRouteToPageListLoanCollectability() {
      this.$router.push("list");
    },
    helperGetLoanCollectabilityStatusDataForReference() {
      this.$store
        .dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-collectability-status",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityStatusId;
            const text = i.collectabilityStatusName;
            this.options.kodeKolektibilitas.push({ text, value });
          });
        });
    },
    handleChargingOptionsComponent() {
      this.options.kodeKolektibilitas.push({ value: "", text: "-- Pilih --" });
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem(
        "LOAN_COLLECTABILITY_ID"
      );
      this.dataForm.identifier.loanCollectabilityId = getTokenFromSession;
    },
    async getLoanCollectabilityDraftByID() {
      const payload = {
        id: this.dataForm.identifier.loanCollectabilityId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.addLoanCollectability.isLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "loan-collectability-draft/" +
              this.dataForm.identifier.loanCollectabilityId,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.dataForm.loanAccountId =
                resp.data.data.tloanAccount.loanAccountId;
              this.dataForm.loanCollectabilityId =
                resp.data.data.mloanCollectability.loanCollectabilityId;
              this.dataForm.accountName =
                resp.data.data.tloanAccount.tloanSp3.mcif.cifIdName;
              this.dataForm.accountCabang =
                resp.data.data.tloanAccount.moffice.officeName;
              this.dataForm.loanName =
                resp.data.data.mloanCollectability.rloanAkadType.loanAkadTypeName;
              this.dataForm.nominal = resp.data.data.tloanAccount.nominalLoan;
              this.dataForm.endDate = resp.data.data.tloanAccount.endDate;
              this.dataForm.collectabilityNow =
                resp.data.data.mloanCollectability.rloanCollectabilityStatus.collectabilityStatusName;
            } else {
              this.handleRouteToPageListLoanCollectability();
            }
          }, 1000);
        } catch (error) {
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.property.animation.addLoanCollectability.isLoading = false;
          }, 1000);
        }
      }
    },
    changeDateCollectability(val) {
      Moment.locale("ID");
      if (val !== "") {
        var date = Moment(val).format("LL");
        return date;
      }
    },
  },
  mounted() {
    this.helperGetLoanCollectabilityStatusDataForReference();
    this.handleChargingOptionsComponent();
    this.getIdentityFromSession();
    this.getLoanCollectabilityDraftByID();
  },
};
