export default {
  name: "FormMJournalTemplate",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          addJournalTemplate: {
            isLoading: false,
          },
        },
      },
      options: {
        kodeCoa: [],
        mutasi: [],
        position: [],
        kodeTransaksi: [],
        tipeJurnal: [],
        jurnalTemplateItem: [],
      },
      dataForm: {
        journalTemplateName: "",
        journalTemplateItem: "",
        description: "",
        journalTemplateParentId: "",
        position: "",
        chartOfAccountId: "",
        transactionCodeId: "",
        journalTemplateTypeId: "",
        descriptionCoa: "",
        jurnalTemplateId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
    };
  },
  methods: {
    routeToPageListJournalTemplate() {
      this.$router.push("/parameter/journal-template");
    },
    changeChartOfAccountId() {
      this.dataForm.descriptionCoa = "";
      this.options.kodeCoa.map((i) => {
        if (i.value === this.dataForm.chartOfAccountId) {
          this.dataForm.descriptionCoa = i.description;
        }
      });
    },
    helperGetChartOfAccountDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "chart-of-account",
          params: {
            description: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            const description = i.description;
            this.options.kodeCoa.push({ text, value, description });
          });
        });
    },
    helperGetJournalLedgerTypeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_PARAMETER", {
          url: "r-journal-ledger-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.journalTemplateTypeId;
            const text = i.journalTemplateTypeName;
            this.options.tipeJurnal.push({ text, value });
          });
        });
    },
    helperGetTransactionCodeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "transaction-code",
          params: {
            name: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.transactionCodeId;
            const text = `${i.transactionCode} - ${i.transactionName}`;
            this.options.kodeTransaksi.push({ text, value });
          });
        });
    },
    helperGetJurnalTemplateItemForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "m-journal-template",
          params: {
            journalName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.journalTemplateId;
            const text = i.journalTemplateName;
            this.options.jurnalTemplateItem.push({ text, value });
          });
        });
    },
    helperChargingOptionsComponent() {
      this.options.kodeCoa.push({ value: "", text: "-- Pilih --" });
      this.options.mutasi.push({ value: "", text: "-- Pilih --" });
      this.options.position.push(
        { value: "", text: "-- Pilih --" },
        { value: "C", text: "CREDIT" },
        { value: "D", text: "DEBET" }
      );
      this.options.kodeTransaksi.push({ value: "", text: "-- Pilih --" });
      this.options.tipeJurnal.push({ value: "", text: "-- Pilih --" });
      this.options.jurnalTemplateItem.push({ value: "", text: "-- Pilih --" });
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.journalTemplateName = payload.journalTemplateName;
        this.dataForm.journalTemplateItem = payload.journalTemplateItem;
        this.dataForm.description = payload.description;
        this.dataForm.position = payload.position;
        this.dataForm.chartOfAccountId = payload.mchartOfAccountDto
          ? payload.mchartOfAccountDto.chartOfAccountId
          : "";
        this.dataForm.transactionCodeId = payload.mtransactionCodeResponseDto
          ? payload.mtransactionCodeResponseDto.transactionCodeId
          : "";
        this.dataForm.journalTemplateTypeId = payload.rjournalTemplateTypeResponseDto
          ? payload.rjournalTemplateTypeResponseDto.journalTemplateTypeId
          : "";
        this.dataForm.descriptionCoa = payload.mchartOfAccountDto
          ? payload.mchartOfAccountDto.description
          : "";
        this.dataForm.journalTemplateParentId = payload.mjournalTemplateParentResponseDto
          ? payload.mjournalTemplateParentResponseDto.journalTemplateId
          : "";
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitJournalTemplate() {
      this.$emit("submitJournalTemplate", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.helperChargingOptionsComponent();
    this.helperGetChartOfAccountDataForReference();
    this.helperGetJournalLedgerTypeDataForReference();
    this.helperGetTransactionCodeDataForReference();
    this.helperGetJurnalTemplateItemForReference();
  },
};
