var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"w-full py-5 px-5"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Kode Transaksi")]),_c('b-field',{staticStyle:{"width":"270px"},attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0] ? 'Kolom Ini Wajib Di Isi' : ''}},[_c('model-select',{staticStyle:{"width":"100%","font-size":"12px"},attrs:{"options":_vm.options.kodeTransaksi,"size":"is-small"},on:{"input":_vm.changeKodeTransaksi},model:{value:(_vm.form.transactionCodeId),callback:function ($$v) {_vm.$set(_vm.form, "transactionCodeId", $$v)},expression:"form.transactionCodeId"}})],1)],1)]}}],null,true)}),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("No Bukti Dokumen")]),_c('b-field',{staticStyle:{"width":"270px"}},[_c('b-input',{staticStyle:{"width":"100%"},attrs:{"size":"is-small"},model:{value:(_vm.form.documentProofNumber),callback:function ($$v) {_vm.$set(_vm.form, "documentProofNumber", $$v)},expression:"form.documentProofNumber"}})],1)],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Transaksi Referensi ( Akun. Sumber )")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":"","placeholder":!_vm.isExisting
            ? 'GENERATED BY SYSTEM'
            : _vm.form.referenceNumberSourceAccount}})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Transaksi Referensi ( Akun. Tujuan )")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":"","placeholder":!_vm.isExisting
            ? 'GENERATED BY SYSTEM'
            : _vm.form.referenceNumberTargetAccount}})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Tanggal Transaksi")]),_c('datetime',{attrs:{"type":"datetime","input-class":"text-start p-3","disabled":"","input-style":"width: 270px; font-size: 14px; outline: 1px solid #e7eaed; height: 30px;"},model:{value:(_vm.form.transactionDate),callback:function ($$v) {_vm.$set(_vm.form, "transactionDate", $$v)},expression:"form.transactionDate"}})],1),_c('span',{staticClass:"text-sm mt-5"},[_c('b',[_vm._v("REKENING DEBET")])]),(_vm.property.modal.showModalFindSavingAccountSource)?_c('ModalSearchSavingAccount',{attrs:{"isEdcSearch":false,"showModal":_vm.property.modal.showModalFindSavingAccountSource},on:{"closeModalFindSavingAccount":function($event){_vm.property.modal.showModalFindSavingAccountSource = $event},"selectFindSavingAccount":function($event){return _vm.selectSavingAccountSource($event)}}}):_vm._e(),(
        _vm.form.transactionCodeId ===
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_TAB_CR_ABA
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rekening Tabungan")]),_c('div',{staticClass:"flex flex-col items-end w-full",staticStyle:{"max-width":"300px"}},[_c('b-input',{staticStyle:{"max-width":"270px","width":"100%","font-size":"15px","background-color":"white"},attrs:{"size":"is-small","readonly":""},nativeOn:{"click":function($event){return _vm.showModalFindSavingAccount('source')}},model:{value:(_vm.form.sourceSavingAccountNumber),callback:function ($$v) {_vm.$set(_vm.form, "sourceSavingAccountNumber", $$v)},expression:"form.sourceSavingAccountNumber"}}),_c('span',{staticStyle:{"font-size":"0.7rem","margin-top":"3px","font-weight":"520","color":"gray"}},[_vm._v("Klik Untuk Cari Rekening")])],1)]):_vm._e(),(
        _vm.isExisting === false &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_TAB ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABA_CR_SBB ||
            _vm.form.transactionCodeId === '53' ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABA_CR_ABA)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rekening ABA")]),_c('model-select',{staticStyle:{"max-width":"270px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.interbankAssets},on:{"input":_vm.changeSourceRekeningAba},model:{value:(_vm.form.sourceAccountId),callback:function ($$v) {_vm.$set(_vm.form, "sourceAccountId", $$v)},expression:"form.sourceAccountId"}})],1):_vm._e(),(
        _vm.isExisting === true &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_TAB ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABA_CR_SBB ||
            _vm.form.transactionCodeId === '53' ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABA_CR_ABA)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rekening ABA")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.sourceinterbankAssetsAccountNumber),callback:function ($$v) {_vm.$set(_vm.form, "sourceinterbankAssetsAccountNumber", $$v)},expression:"form.sourceinterbankAssetsAccountNumber"}})],1):_vm._e(),(
        _vm.isExisting === false &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABA ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_SBB_CR_ABP)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("SBB ( COA )")]),_c('model-select',{staticStyle:{"max-width":"270px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.chartOfAccount,"placeholder":"Cari Nama Atau Nomor COA"},on:{"input":_vm.changeGetCoaDetailSource},model:{value:(_vm.form.sourceCoaNumber),callback:function ($$v) {_vm.$set(_vm.form, "sourceCoaNumber", $$v)},expression:"form.sourceCoaNumber"}})],1):_vm._e(),(
        _vm.isExisting === true &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABA ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_SBB_CR_ABP)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("SBB ( COA )")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%","font-size":"15px","background-color":"white"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.sourceCoaNumber),callback:function ($$v) {_vm.$set(_vm.form, "sourceCoaNumber", $$v)},expression:"form.sourceCoaNumber"}})],1):_vm._e(),(
        _vm.form.transactionCodeId !==
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_SBB_CR_ABA &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABP
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nama")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.sourceAccountResult.accountName),callback:function ($$v) {_vm.$set(_vm.form.sourceAccountResult, "accountName", $$v)},expression:"form.sourceAccountResult.accountName"}})],1):_vm._e(),(
        _vm.form.transactionCodeId !==
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_SBB_CR_ABA &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABP
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Cabang")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.sourceAccountResult.branch),callback:function ($$v) {_vm.$set(_vm.form.sourceAccountResult, "branch", $$v)},expression:"form.sourceAccountResult.branch"}})],1):_vm._e(),(
        _vm.form.transactionCodeId !==
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_ABA_CR_TAB &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_ABA &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_SBB &&
          _vm.form.transactionCodeId !== '53' &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_TAB_CR_ABA
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Deskripsi")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.sourceAccountResult.description),callback:function ($$v) {_vm.$set(_vm.form.sourceAccountResult, "description", $$v)},expression:"form.sourceAccountResult.description"}})],1):_vm._e(),(
        _vm.form.transactionCodeId ===
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_SBB_CR_ABA ||
          _vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABP
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Cabang")]),_c('model-select',{staticStyle:{"max-width":"270px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.office},model:{value:(_vm.form.officeId),callback:function ($$v) {_vm.$set(_vm.form, "officeId", $$v)},expression:"form.officeId"}})],1):_vm._e(),(_vm.property.modal.showModalFindSavingAccountDestination)?_c('ModalSearchSavingAccount',{attrs:{"isEdcSearch":false,"showModal":_vm.property.modal.showModalFindSavingAccountDestination},on:{"closeModalFindSavingAccount":function($event){_vm.property.modal.showModalFindSavingAccountDestination = $event},"selectFindSavingAccount":function($event){return _vm.selectSavingAccountDestination($event)}}}):_vm._e(),_c('span',{staticClass:"text-sm mt-5"},[_c('b',[_vm._v("REKENING KREDIT")])]),(
        _vm.form.transactionCodeId ===
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_ABA_CR_TAB
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rekening Tabungan")]),_c('div',{staticClass:"flex flex-col items-end w-full",staticStyle:{"max-width":"300px"}},[_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","readonly":""},nativeOn:{"click":function($event){return _vm.showModalFindSavingAccount('destination')}},model:{value:(_vm.form.targetSavingAccountNumber),callback:function ($$v) {_vm.$set(_vm.form, "targetSavingAccountNumber", $$v)},expression:"form.targetSavingAccountNumber"}}),_c('span',{staticStyle:{"font-size":"0.7rem","margin-top":"3px","font-weight":"520","color":"gray"}},[_vm._v("Klik Untuk Cari Rekening")])],1)]):_vm._e(),(
        _vm.isExisting === false &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABA ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_TAB_CR_ABA ||
            _vm.form.transactionCodeId === '57' ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABA_CR_ABA)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Rekening ABA")]),_c('model-select',{staticStyle:{"max-width":"270px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.interbankAssets},on:{"input":_vm.changeTargetRekeningAba},model:{value:(_vm.form.targetAccountId),callback:function ($$v) {_vm.$set(_vm.form, "targetAccountId", $$v)},expression:"form.targetAccountId"}})],1):_vm._e(),(
        _vm.isExisting === true &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABA ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_TAB_CR_ABA ||
            _vm.form.transactionCodeId === '57' ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABA_CR_ABA)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Rekening ABA")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%","font-size":"15px","background-color":"white"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.targetinterbankAssetsAccountNumber),callback:function ($$v) {_vm.$set(_vm.form, "targetinterbankAssetsAccountNumber", $$v)},expression:"form.targetinterbankAssetsAccountNumber"}})],1):_vm._e(),(
        _vm.isExisting === false &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_SBB ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABP_CR_SBB)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("SBB ( COA )")]),_c('model-select',{staticStyle:{"max-width":"270px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.chartOfAccount,"placeholder":"Cari Nama Atau Nomor COA"},on:{"input":_vm.changeSelectOptionsGetCoaDetailTarget},model:{value:(_vm.form.targetCoaNumber),callback:function ($$v) {_vm.$set(_vm.form, "targetCoaNumber", $$v)},expression:"form.targetCoaNumber"}})],1):_vm._e(),(
        _vm.isExisting === true &&
          (_vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_SBB ||
            _vm.form.transactionCodeId ===
              _vm.fetchAppSession('@vue-session/config-bundle')
                .VUE_APP_TRX_CODE_DB_ABP_CR_SBB)
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("SBB ( COA )")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%","font-size":"15px","background-color":"white"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.targetCoaNumber),callback:function ($$v) {_vm.$set(_vm.form, "targetCoaNumber", $$v)},expression:"form.targetCoaNumber"}})],1):_vm._e(),(
        _vm.form.transactionCodeId !== '53' &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_SBB &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABP_CR_SBB
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nama")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.targetAccountResult.accountName),callback:function ($$v) {_vm.$set(_vm.form.targetAccountResult, "accountName", $$v)},expression:"form.targetAccountResult.accountName"}})],1):_vm._e(),(
        _vm.form.transactionCodeId !== '53' &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_SBB &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABP_CR_SBB
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Cabang")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.targetAccountResult.branch),callback:function ($$v) {_vm.$set(_vm.form.targetAccountResult, "branch", $$v)},expression:"form.targetAccountResult.branch"}})],1):_vm._e(),(
        _vm.form.transactionCodeId !==
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_ABA_CR_TAB &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_TAB_CR_ABA &&
          _vm.form.transactionCodeId !== '57' &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABA_CR_ABA &&
          _vm.form.transactionCodeId !==
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_SBB_CR_ABA
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Deskripsi")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","type":"textarea","disabled":""},model:{value:(_vm.form.targetAccountResult.description),callback:function ($$v) {_vm.$set(_vm.form.targetAccountResult, "description", $$v)},expression:"form.targetAccountResult.description"}})],1):_vm._e(),(
        _vm.form.transactionCodeId ===
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_DB_ABA_CR_SBB ||
          _vm.form.transactionCodeId ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_DB_ABP_CR_SBB
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Cabang")]),_c('model-select',{staticStyle:{"max-width":"270px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.office},model:{value:(_vm.form.officeId),callback:function ($$v) {_vm.$set(_vm.form, "officeId", $$v)},expression:"form.officeId"}})],1):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center mt-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nominal")]),_c('money',_vm._b({staticClass:"text-start p-3",staticStyle:{"max-width":"270px","width":"100%","font-size":"12px","outline":"1px solid #e7eaed","height":"35px"},model:{value:(_vm.form.nominal),callback:function ($$v) {_vm.$set(_vm.form, "nominal", $$v)},expression:"form.nominal"}},'money',_vm.VMoneyPrecision2,false))],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Keterangan")]),_c('b-input',{staticStyle:{"max-width":"270px","width":"100%"},attrs:{"size":"is-small","type":"textarea"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('div',{staticClass:"flex mt-5 justify-center w-full"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.routeToPageListInterbankAssets}},[_vm._v("Kembali")]),(_vm.isExisting === false)?_c('b-button',{staticClass:"button-theme-confirm",attrs:{"loading":_vm.property.animation.submitLoading,"disabled":!_vm.$store.getters['getActionsInsert'] ||
              _vm.property.animation.submitLoading === true,"type":"is-success"},on:{"click":function($event){return handleSubmit(_vm.submitTaskInterbankAssets)}}},[_vm._v(" Simpan ")]):_vm._e()],1)]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.form.createdByUserCode,"createdByUserName":_vm.form.createdByUserName,"createdDate":_vm.form.createdDate,"updatedDate":_vm.form.updatedDate,"updatedByUserCode":_vm.form.updatedByUserCode,"updatedByUserName":_vm.form.updatedByUserName,"authorizedDate":_vm.form.authorizedDate,"authorizedByUserCode":_vm.form.authorizedByUserCode,"authorizedByUserName":_vm.form.authorizedByUserName}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }