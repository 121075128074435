export default {
  name: "FormTSavingAccountDetailReversal",
  props: {
    isExisting: false,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identity: {
        mainId: "",
      },
      form: {
        referenceNumber: "",
        documentProofNumber: "",
        transactionDate: "",
        valutaDate: "",
        accountNumber: "",
        cifIdName: "",
        nominalSavingAccountDetail: 0,
        transactionCodeId: "",
        nominal: 0,
        description: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      options: {
        kodeTransaksi: [],
      },
    };
  },
  methods: {
    helperGetDataTransactionCodeForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            name: "",
            page: 0,
            menuAccessid: "",
          },
          url: "transaction-code",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            const description = i.transactionName;
            this.options.kodeTransaksi.push({ text, value, description });
          });
        });
    },
    handleRouteToPageListReversalSavingAccountDetail() {
      this.$router.push("/saving/reversal");
    },

    handleChargingOptionsComponent() {
      this.options.kodeTransaksi.push({ value: "", text: "-- Pilih --" });
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.handleRouteToPageListReversalSavingAccountDetail();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).savingAccountDetailId;
      this.$emit("getMainId", this.identity.mainId);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.referenceNumber = payload.referenceNumber;
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.transactionDate = payload.transactionDate;
        this.form.valutaDate = payload.valutaDate;
        this.form.accountNumber = payload.accountNumber;
        this.form.cifIdName = payload.tsavingAccount
          ? payload.tsavingAccount.mcif
            ? payload.tsavingAccount.mcif.cifIdName
            : ""
          : "";
        this.form.nominal = payload.nominal;
        this.form.nominalSavingAccountDetail = payload.nominal;
        this.form.description = payload.description;
        this.form.transactionCodeId =
          payload.mtransactionCode.transactionCodeId;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.form.referenceNumber = payload.referenceNumber;
      this.form.documentProofNumber = payload.documentProofNumber;
      this.form.transactionDate = payload.transactionDate;
      this.form.valutaDate = payload.valutaDate;
      this.form.accountNumber = payload.accountNumber;
      this.form.cifIdName = payload.tsavingAccount
        ? payload.tsavingAccount.mcif
          ? payload.tsavingAccount.mcif.cifIdName
          : ""
        : "";
      this.form.nominalSavingAccountDetail = payload.nominal;
    },
    submitSavingAccountDetailReversal() {
      this.$emit("submitSavingAccountDetailReversal", this.form);
    },
  },
  beforeMount() {},
  mounted() {
    this.validationDomain = window.location.hostname;
    this.checkingMainId();
    this.handleChargingOptionsComponent();
    this.helperGetDataTransactionCodeForReference();
  },
  destroyed() {},
};
