export default {
  name: "MappingOfficeCoa",
  data() {
    return {
      listOffice: [],
      isLoadOffice: false,
      isLoading: false,
      chartOfAccountSelected: "",
      property: {
        listElement: {
          office: {
            downloading: false,
            rows: 0,
            massage: "",
          },
        },
      },
      options: {
        parameterCoaList: [],
      },
    };
  },
  methods: {
    async onChangeChartOfAccount() {
      this.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "office-coa-mapping/" + this.chartOfAccountSelected,
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          const modifyResponse = resp.data.data.officeIds.reduce(
            (a, v) => ({ ...a, [v]: v }),
            {}
          );
          this.mapCoaWithOffice(modifyResponse);
        }
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    mapCoaWithOffice(payload) {
      this.listOffice = this.listOffice.map((v) => ({
        ...v,
        isActive: payload[v.value] ? true : false,
      }));
    },
    async helperGetParameterCoa() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountId;
            this.options.parameterCoaList.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceOffice() {
      this.isLoadOffice = true;
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeId;
            const officeCode = index.officeCode;
            const isActive = false;
            this.listOffice.push({ text, value, officeCode, isActive });
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadOffice = false;
      }
    },
    async onClickSave() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Simpan Data ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      try {
        let data = this.listOffice.reduce(function (arrReturned, item) {
          if (item.isActive === true) {
            arrReturned.push(item.value);
          }
          return arrReturned;
        }, []);
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "parameter",
          reqUrl: "office-coa-mapping/" + this.chartOfAccountSelected,
          payload: {
            officeIds: data,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.message = error.response?.data?.errorFields;
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
  },
  mounted() {
    this.helperGetParameterCoa();
    this.getReferenceOffice();
  },
};
