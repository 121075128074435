import Moment from "moment";
export default{
    name: "FormSipendar",
    props: {
        isExisting: {
            default: false,
            type: Boolean,
            required: true,
        },
    },
    data(){
        return{
            identity: {
                mainId: "",
            },
            form: {
                idCardNumber: "",
                otherCardNumber: "",
                npwpNumber: "",
                periode: "",
                rtypePersonId: "",
                sipendarName: "",
                watchlistCode: "",
                birthPlace: "",
                birthDate: "",
                //user-activity
                createdDate: "",
                createdByUserCode: "",
                createdByUserName: "",
                updatedDate: "",
                updatedByUserCode: "",
                updatedByUserName: "",
            },
            options: {
                kategori: [],
              },
            property: {
                animation: {
                  submitLoading: false,
                },
              },
        };
    },
    methods: {
        Moment: Moment,
          getSystemDate(){
            payload.birthDate = this.fetchAppSession(
              "@vue-session/application"
            ).system_date;
        },
        async helperGetKategoriDataForReference() {
            try {
              const resp = await this.$store.dispatch({
                type: "GET_LIST_DATA",
                endPoint: "transaction",
                reqUrl: "type-person",
              });
              if (resp.data.code === "SUCCESS") {
                this.options.kategori = [{ text: "-- Pilih --", value: "" }]
                resp.data.data.map((i) => {
                  const value = i.typePersonId;
                  const text = i.typePersonName;
                  this.options.kategori.push({ text, value });
                });
              }
            } catch (e) { }
        },
        routeToPageListSipendar() {
            this.$router.push("/report/sipendar");
        },
        checkingMainId() {
            var params = this.$route.params.id;
            if (this.isExisting === true && params) {
              this.identity.mainId = this.decryptBASE64(params);
              this.$emit("getMainId", this.identity.mainId);
              return;
            }
        },
        getDefaultFormValue(payload) {
            if (this.isExisting === true) {
                this.form.idCardNumber = payload.idCardNumber,
                this.form.otherCardNumber = payload.otherIdCardNumber,
                this.form.npwpNumber = payload.npwpNumber,
                this.form.periode = payload.periode ? Moment(payload.periode).format("YYYY-MM-DD") : payload.periode,
                this.form.rtypePersonId = payload.rtypePersonId,
                this.form.sipendarName = payload.sipendarName,
                this.form.watchlistCode = payload.watchlistCode,
                this.form.birthPlace = payload.birthPlace,
                this.form.birthDate = payload.birthDate ? Moment(payload.birthDate).format("YYYY-MM-DD") : payload.birthDate
                this.form.createdByUserCode = payload.createdByUserCode,
                this.form.createdByUserName = payload.createdByUserName,
                this.form.createdDate = payload.createdDate,
                this.form.updatedDate = payload.updatedDate,
                this.form.updatedByUserCode = payload.updatedByUserCode,
                this.form.updatedByUserName = payload.updatedByUserName
              return;
            }
        },
        submitSipendar() {
            this.$emit("submitSipendar", this.form);
        },
    },
    mounted() {
        this.checkingMainId();
        this.helperGetKategoriDataForReference();
    }
}