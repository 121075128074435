export default {
  name: "UpdateFixRate",
  data() {
    return {
      property: {
        animation: {
          isDownloadingFile: false,
          upload: false,
        },
      },
      form: {
        file: null,
      },
    };
  },
  methods: {
    async onDownloadTemplateUs() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/download-template-fix-rate/us",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Template_Upload_Fix_Rate_.${"csv"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadTemplateIdn() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: false,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/download-template-fix-rate/idn",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Template_Upload_Fix_Rate_Idn.${"csv"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async uploadFile() {
      let formData = new FormData();
      formData.append("file", this.form.file);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.upload = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA_FILE",
          reqUrl: "deposit-account/upload-bulk-fix-rate",
          endPoint: "transaction",
          payload: formData,
        });
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.toast.open(
            this.$NotificationUtils.success
          );
          toastBuefy.$on("close", () => {
            this.form.file = null;
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.upload = false;
      }
    },
  },
};
