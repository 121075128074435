var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-between flex-wrap flex-row"},[_c('div',{staticClass:"w-full flex justify-center items-start flex-col",staticStyle:{"max-width":"400px"}},[_c('div',{staticClass:"flex justify-between mt-1 flex-wrap items-center flex-row w-full",staticStyle:{"max-width":"320px"},attrs:{"inline":""}},[_c('span',{staticClass:"text-xs"},[_vm._v("Periode")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('b-input',{staticStyle:{"max-width":"120px","width":"100%"},attrs:{"size":"is-small","type":"date"},on:{"input":_vm.searchakumulasiPenyusutan},model:{value:(_vm.property.filterDto.dateFrom),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "dateFrom", $$v)},expression:"property.filterDto.dateFrom"}}),_c('b-icon',{attrs:{"icon":"chevron-right","size":"is-small"}}),_c('b-input',{staticStyle:{"max-width":"120px","width":"100%"},attrs:{"size":"is-small","type":"date"},on:{"input":_vm.searchakumulasiPenyusutan},model:{value:(_vm.property.filterDto.dateTo),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "dateTo", $$v)},expression:"property.filterDto.dateTo"}})],1)])])]),_c('div',{staticClass:"p-1 overflow-auto"},[_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.akumulasiPenyusutan,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.akumulasiPenyusutan.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
              _vm.property.listElement.akumulasiPenyusutan.downloading === false
            )?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.akumulasiPenyusutan.message ? _vm.property.listElement.akumulasiPenyusutan.message : "DATA NOT FOUND")+" ")]):_vm._e()],1)]},proxy:true},{key:"footer",fn:function(){return [_c('th',{staticStyle:{"border-right":"0px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Total")]),_c('br'),_c('span',{staticClass:"text-sm"},[_vm._v("Balance")])]),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px"}}),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px"}}),_c('th',{staticStyle:{"border-left":"0px","border-right":"0px","text-align":"center"}},[_c('span',{staticClass:"text-green-500 text-sm"},[_vm._v("Rp "+_vm._s(_vm.convertDecimalCurrency(_vm.table.footer.totalDebet)))])]),_c('th',{staticStyle:{"border-left":"0px","text-align":"center"}},[_c('span',{staticClass:"text-green-500 text-sm"},[_vm._v("Rp "+_vm._s(_vm.convertDecimalCurrency(_vm.table.footer.totalCredit)))]),_c('br'),_c('span',{staticClass:"text-green-500 text-sm"},[_vm._v("Rp "+_vm._s(_vm.convertDecimalCurrency(_vm.table.footer.balance)))])])]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Nomor Voucher"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.coaCode ? props.row.coaCode : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Tanggal Transaksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.transactionDate ? props.row.transactionDate : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Keterangan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.description ? props.row.description : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Debet"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.debit ? _vm.convertDecimalCurrency(props.row.debit) : 0))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Credit"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.credit ? _vm.convertDecimalCurrency(props.row.credit) : 0))])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }