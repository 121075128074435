export default {
  name: "authModal",
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      property: {
        modal: {
          showModalReauthentication: false,
          showModalInputOtp: false,
        },
        animation: {
          reauthentication: {
            isLoading: false,
          },
          login: {
            isLoadingOtp: false,
          },
        },
      },
      dataForm: {
        userCode: "",
        password: "",
      },
      validationDomain: "",
      verificationOtp: "",
    };
  },
  mounted() {
    this.getAsset();
    this.handleShowModalAuth();
    this.handlePrepareReAuthentication();
    this.validationDomain = window.location.hostname;
  },
  methods: {
    submitLogin() {
      if (
        this.validationDomain === "appdev.digiard.id" ||
        this.validationDomain === "localhost"
      ) {
        this.handleReauthentication();
      } else {
        this.handleReauthenticationOtp();
      }
    },
    /* start otp */
    handleOnComplete(value) {
      this.verificationOtp = value;
      // console.log("OTP completed: ", value);
    },
    handleOnChange(value) {
      console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    closeModalInputOtp() {
      this.property.modal.showModalInputOtp = false;
    },
    async handleReauthenticationOtp() {
      if (this.validationDomain === "pojok.bprshikp.co.id") {
        const payloadHeader = {
          userCode: this.dataForm.userCode,
          password: this.dataForm.password,
          channelId: "web",
          isUserTellerPojok: true,
        };
        if (this.dataForm.username === "" || this.dataForm.password === "") {
          return;
        } else {
          this.property.animation.login.isLoading = true;
          this.property.animation.login.isDisable = true;
          try {
            const resp = await this.$store.dispatch(
              "POST_LOGIN",
              payloadHeader
            );
            await this.simpleWait(500);
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalInputOtp = true;
              this.xTokenId = resp.data.data.tokenId;
            } else {
              this.$buefy.notification.open({
                message: `<b>Gagal !</b> <br/> ${resp.data.message}`,
                position: "is-top-right",
                type: "is-danger",
                hasIcon: true,
                queue: false,
              });
            }
          } catch (error) {
            setTimeout(() => {
              console.log(error.response);
              this.$buefy.notification.open({
                message: error.response
                  ? `${error.response.statusText}`
                  : `<b>Gagal !</b> <br/> Terjadi Kesalahan !`,
                position: "is-bottom-right",
                type: "is-danger",
                hasIcon: true,
                queue: false,
              });
            }, 1000);
          } finally {
            await this.simpleWait(500);
            this.property.animation.login.isLoading = false;
            this.property.animation.login.isDisable = false;
          }
        }
      } else {
        const payloadHeader = {
          userCode: this.dataForm.userCode,
          password: this.dataForm.password,
          channelId: "web",
          isUserTellerPojok: false,
        };
        if (this.dataForm.username === "" || this.dataForm.password === "") {
          return;
        } else {
          this.property.animation.login.isLoading = true;
          this.property.animation.login.isDisable = true;
          try {
            const resp = await this.$store.dispatch(
              "POST_LOGIN",
              payloadHeader
            );
            await this.simpleWait(500);
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalInputOtp = true;
              this.xTokenId = resp.data.data.tokenId;
            } else {
              this.$buefy.notification.open({
                message: `<b>Gagal !</b> <br/> ${resp.data.message}`,
                position: "is-top-right",
                type: "is-danger",
                hasIcon: true,
                queue: false,
              });
            }
          } catch (error) {
            setTimeout(() => {
              console.log(error.response);
              this.$buefy.notification.open({
                message: error.response
                  ? `${error.response.statusText}`
                  : `<b>Gagal !</b> <br/> Terjadi Kesalahan !`,
                position: "is-bottom-right",
                type: "is-danger",
                hasIcon: true,
                queue: false,
              });
            }, 1000);
          } finally {
            await this.simpleWait(500);
            this.property.animation.login.isLoading = false;
            this.property.animation.login.isDisable = false;
          }
        }
      }
    },
    async submitOtpLogin() {
      if (this.validationDomain === "pojok.bprshikp.co.id") {
        const data = {
          userCode: this.dataForm.userCode,
          password: this.dataForm.password,
          channelId: "web",
          isUserTellerPojok: true,
          otp: this.verificationOtp,
          tokenId: this.xTokenId,
        };
        this.property.animation.login.isLoadingOtp = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "user-management",
            reqUrl: "login/otp",
            payload: data,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.fetchMenuAccess(resp);
              this.property.modal.showModalInputOtp = false;
            } else {
              this.$buefy.notification.open({
                message: `<b>Gagal !</b> <br/> ${resp.data.message}`,
                position: "is-top-right",
                type: "is-danger",
                hasIcon: true,
                queue: false,
              });
            }
          }, 1000);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.notification.open({
              message: error.response
                ? `${error.response.statusText}`
                : `<b>Gagal !</b> <br/> Terjadi Kesalahan !`,
              position: "is-bottom-right",
              type: "is-danger",
              hasIcon: true,
              queue: false,
            });
          }, 1000);
        } finally {
          setTimeout(() => {
            this.property.animation.login.isLoadingOtp = false;
          }, 1000);
        }
      } else {
        const data = {
          userCode: this.dataForm.userCode,
          password: this.dataForm.password,
          channelId: "web",
          isUserTellerPojok: false,
          otp: this.verificationOtp,
          tokenId: this.xTokenId,
        };
        this.property.animation.login.isLoadingOtp = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "user-management",
            reqUrl: "login/otp",
            payload: data,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.fetchMenuAccess(resp);
              this.property.modal.showModalInputOtp = false;
            } else {
              this.$buefy.notification.open({
                message: `<b>Gagal !</b> <br/> ${resp.data.message}`,
                position: "is-top-right",
                type: "is-danger",
                hasIcon: true,
                queue: false,
              });
            }
          }, 1000);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.notification.open({
              message: error.response
                ? `${error.response.statusText}`
                : `<b>Gagal !</b> <br/> Terjadi Kesalahan !`,
              position: "is-bottom-right",
              type: "is-danger",
              hasIcon: true,
              queue: false,
            });
          }, 1000);
        } finally {
          setTimeout(() => {
            this.property.animation.login.isLoadingOtp = false;
          }, 1000);
        }
      }
    },
    /* end otp */
    handleShowModalAuth() {
      this.property.modal.showModalReauthentication = true;
    },
    async fetchMenuAccess(response) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "user-role/menu-link/" + response.data.data.roleId,
          header: {
            "x-token-id": response.data.data.tokenId,
          },
          endPoint: "user-management",
        });
        if (resp.data.code === "SUCCESS") {
          const map = new Map(Object.entries(resp.data.data));
          sessionStorage.setItem(
            "@vue-session/auth-bundle",
            this.encryptBASE64(JSON.stringify(response.data.data))
          );
          sessionStorage.setItem(
            "@vue-session/access-bundle",
            this.encryptBASE64(JSON.stringify(Array.from(map)))
          );
          // eslint-disable-next-line no-undef
          axios.defaults.headers.common["x-token-id"] =
            response.data.data.tokenId;
          this.property.modal.showModalReauthentication = false;
          this.$store.dispatch("SET_IS_TOKEN_EXPIRED", false);
          this.$buefy.dialog.confirm({
            title: "Berhasil Re Authentication",
            message:
              "Expired Sudah Di Perpanjang <b>Refresh Halaman Untuk Mendapatkan Update</b>!",
            confirmText: "Refresh",
            canClose: ["outside", "button"],
            hasIcon: true,
            type: "is-warning",
            cancelText: "Abaikan",
            closeOnConfirm: false,
            onConfirm: (value, { close }) => {
              close();
              setTimeout(() => {
                window.location.reload();
              }, 500);
            },
          });
        } else {
          this.$buefy.notification.open({
            duration: 3000,
            message: `<b>Failed to retrieve the Data Menu !</b> <br/> ${resp.data.message}`,
            position: "is-top-right",
            type: "is-danger",
            hasIcon: true,
          });
        }
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.notification.open({
            duration: 3000,
            message: error.response
              ? `Failed to retrieve the Data Menu ! : ${error.response.statusText}`
              : `<b>Failed to retrieve the Data Menu !</b> <br/> Terjadi Kesalahan !`,
            position: "is-bottom-right",
            type: "is-danger",
            hasIcon: true,
          });
        }, 1000);
      }
    },
    async handleReauthentication() {
      const payloadHeader = {
        userCode: this.dataForm.userCode,
        password: this.dataForm.password,
        channelId: "web",
        isUserTellerPojok: false,
      };
      try {
        this.property.animation.reauthentication.isLoading = true;
        const resp = await this.$store.dispatch(
          "POST_LOGIN_DEV",
          payloadHeader
        );
        if (resp.data.code === "SUCCESS") {
          this.fetchMenuAccess(resp);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan`,
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.reauthentication.isLoading = false;
      }
    },
    handlePrepareReAuthentication() {
      const getTokenFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );

      this.dataForm.userCode = getTokenFromSession.userCode;
    },
    handleLogout() {
      sessionStorage.clear();
      window.location.reload();
    },
    async getAsset() {
      let loading = false;
      let session = null;
      while (!session && !loading) {
        loading = true;
        console.log("fetch asset");
        session = sessionStorage.getItem("@vue-session/asset-bundle");
        await this.simpleWait(1000);
        loading = false;
      }
      this.brand_image = JSON.parse(window.atob(session)).logoImg;
    },
  },
};
