export default {
  name: "UpdateAccountOfficer",
  data() {
    return {
      property: {
        modal: {
          showModalCheckingLoanAccount: false,
          showModalResultLoanAccount: false,
        },
        animation: {
          checkingLoanAccount: {
            isLoading: false,
          },
          submitLoading: false,
        },
        listElement: {
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        loanAccountChecking: {
          accountNumber: "",
        },
        loanAccountNumber: "",
        cifIdName: "",
        officeName: "",
        loanAccountId: "",
        originAccountOfficerIdHandleId: "",
        originAccoundOfficerPromoId: "",
        accountOfficerIdHandleId: "",
        accoundOfficerPromoId: "",
      },
      table: {
        data: {
          loanAccount: [],
        },
      },
      session: {
        loanAccountChecking: {
          accountNumber: "",
        },
      },
      options: {
        accountOfficer: [],
      },
    };
  },
  methods: {
    async getReferenceAccountOfficer() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        console.log("AO => ", resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.accountOfficer.push({ text, value });
          });
        }
      } catch (error) {}
    },
    closePopUpCheckingLoanAccount() {
      this.property.modal.showModalCheckingLoanAccount = false;
      this.dataForm.loanAccountChecking.accountNumber = "";
    },
    closePopUpResultCheckingLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.perPage = 5;
    },
    async checkingLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.checkingLoanAccount.isLoading = true;
      this.session.loanAccountChecking.accountNumber = this.dataForm.loanAccountChecking.accountNumber;
      this.dataForm.loanAccountChecking.accountNumber = "";
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.session.loanAccountChecking.accountNumber,
            page: 0,
            size: this.property.listElement.loanAccount.perPage,
            cifIdName: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.property.modal.showModalCheckingLoanAccount = false;
            this.property.modal.showModalResultLoanAccount = true;
          } else {
            this.dataForm.loanAccountChecking.accountNumber = this.session.loanAccountChecking.accountNumber;
            this.session.loanAccountChecking.accountNumber = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 500);
      } catch (error) {
        this.dataForm.loanAccountChecking.accountNumber = this.session.loanAccountChecking.accountNumber;
        this.session.loanAccountChecking.accountNumber = "";
        console.log(error.response);
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.animation.checkingLoanAccount.isLoading = false;
        }, 500);
      }
    },
    failedGetLoanAccount(resp) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.message = resp.data.message;
    },
    handleErrorLoanAccount(error) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.session.loanAccountChecking.accountNumber,
            cifIdName: "",
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 500);
      }
    },
    getDataLoanAccount(props) {
      console.log(props);
      this.property.modal.showModalResultLoanAccount = false;
      this.dataForm.loanAccountNumber = props.row.loanAccountNumber;
      this.dataForm.cifIdName = props.row.cifIdName;
      this.dataForm.loanAccountId = props.row.loanAccountId;
      this.dataForm.officeName = props.row.officeName;
    },
    async onUpdateAo() {
      const payload = {
        accountOfficerIdHandleId: this.dataForm.accountOfficerIdHandleId,
        accoundOfficerPromoId: this.dataForm.accoundOfficerPromoId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Update AO ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "transaction-loan/account-officer/" +
                  this.dataForm.loanAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  this.dataForm.loanAccountNumber = "";
                  this.dataForm.cifIdName = "";
                  this.dataForm.loanAccountId = "";
                  this.dataForm.accountOfficerIdHandleId = "";
                  this.dataForm.accoundOfficerPromoId = "";
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getReferenceAccountOfficer();
  },
};
