export default {
  name: "Inquery",
  data() {
    return {
      validationDomain: window.location.hostname,
      property: {
        modal: {
          formTransfer: false,
          formConfirmStatus: false,
        },
        animation: {
          inquiry: {
            submitLoading: false,
          },
          submitLoading: false,
        },
      },
      form: {
        inquery: {
          accountNo: "",
          bicBank: "",
        },
        transfer: {
          amount: 0,
          sourceOfFund: "",
          desc: "",
          inquiryId: "",
          /*desk tujuan transfer*/
          accountName: "",
          bankName: "",
          accountNo: "",
        },
        status: {
          accountName: "",
          feeAmount: "",
          transferReferenceNumber: "",
          clientReferenceNumber: "",
          nominalTransfer: "",
          totalTransfer: "",
        },
      },
      options: {
        bank: [],
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cash-out-danamon");
    },
    closeModal() {
      this.form.inquery.accountNo = "";
      this.form.inquery.bicBank = "";
      this.form.transfer.amount = 0;
      this.form.transfer.sourceOfFund = "";
      this.form.transfer.desc = "";
      this.form.transfer.inquiryId = "";
      this.form.transfer.accountName = "";
      this.form.transfer.bankName = "";
      this.form.transfer.accountNo = "";
      this.form.status.transferReferenceNumber = "";
      this.form.status.clientReferenceNumber = "";
      this.property.modal.formTransfer = false;
    },
    closeModalConfirmStatus() {
      this.form.inquery.accountNo = "";
      this.form.inquery.bicBank = "";
      this.form.transfer.amount = 0;
      this.form.transfer.sourceOfFund = "";
      this.form.transfer.desc = "";
      this.form.transfer.inquiryId = "";
      this.form.transfer.accountName = "";
      this.form.transfer.bankName = "";
      this.form.transfer.accountNo = "";
      this.form.status.transferReferenceNumber = "";
      this.form.status.clientReferenceNumber = "";
      this.property.modal.formTransfer = false;
      this.property.modal.formConfirmStatus = false;
    },
    async getReferenceBank() {
      if (this.validationDomain === "olibs.bprshikp.co.id") {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service",
            reqUrl: "danamon/bic",
            payload: {
              query: "",
              size: 1000,
            },
          });
          console.log("this is bank => ", resp);
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const text = i.bankName;
              const value = i.id;
              this.options.bank.push({
                text,
                value,
              });
            });
          }
        } catch (e) {}
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/bic",
            payload: {
              query: "",
              size: 1000,
            },
          });
          console.log("this is bank => ", resp);
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const text = i.bankName;
              const value = i.id;
              this.options.bank.push({
                text,
                value,
              });
            });
          }
        } catch (e) {}
      }
    },
    async addIquiryCashOutDanamon() {
      const data = {
        accountNo: this.form.inquery.accountNo,
        bicBank: this.form.inquery.bicBank,
        // refNumber: this.form.inquery.refNumber,
      };
      this.property.animation.inquiry.submitLoading = true;
      try {
        if (this.validationDomain === "olibs.bprshikp.co.id") {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "outgoing-service",
            reqUrl: "danamon/inquiry",
            payload: data,
          });
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.form.transfer.accountName = resp.data.data.accountName;
            this.form.transfer.bankName = resp.data.data.bankName;
            this.form.transfer.accountNo = resp.data.data.accountNo;
            this.form.transfer.inquiryId = resp.data.data.inquiryId;
            this.form.transfer.refNumber = resp.data.data.refNumber;
            this.property.modal.formTransfer = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } else {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/inquiry",
            payload: data,
          });
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.form.transfer.accountName = resp.data.data.accountName;
            this.form.transfer.bankName = resp.data.data.bankName;
            this.form.transfer.accountNo = resp.data.data.accountNo;
            this.form.transfer.inquiryId = resp.data.data.inquiryId;
            this.form.transfer.refNumber = resp.data.data.refNumber;
            this.property.modal.formTransfer = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
        this.closeModal();
      } finally {
        this.property.animation.inquiry.submitLoading = false;
      }
    },
    async addTranferCashOutDanamon() {
      const data = {
        amount: this.form.transfer.amount,
        sourceOfFund: this.form.transfer.sourceOfFund,
        desc: this.form.transfer.desc,
        inquiryId: this.form.transfer.inquiryId,
      };
      this.property.animation.submitLoading = true;
      try {
        if (this.validationDomain === "olibs.bprshikp.co.id") {
          const resp = await this.$store.dispatch({
            type: "POST_DATA_CASH_OUT",
            endPoint: "outgoing-service",
            reqUrl: "danamon/transfer",
            payload: data,
          });
          console.log("data inquery = > ", resp);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.form.status.transferReferenceNumber =
              resp.data.data.transferReferenceNumber;
            this.form.status.clientReferenceNumber =
              resp.data.data.clientReferenceNumber;
            this.form.status.accountName = resp.data.data.accountName;
            const feeAmount = resp.data.data.feeAmount
              .replace(",", "")
              .split(".")[0];
            const feeConvert = parseInt(feeAmount.replace(/\D/g, ""), 10);
            this.form.status.feeAmount = feeConvert;
            this.form.status.nominalTransfer = this.form.transfer.amount;
            this.form.status.totalTransfer =
              this.form.status.feeAmount + this.form.status.nominalTransfer;
            this.property.modal.formConfirmStatus = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } else {
          const resp = await this.$store.dispatch({
            type: "POST_DATA_CASH_OUT",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/transfer",
            payload: data,
          });
          console.log("data inquery = > ", resp);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.form.status.transferReferenceNumber =
              resp.data.data.transferReferenceNumber;
            this.form.status.clientReferenceNumber =
              resp.data.data.clientReferenceNumber;
            this.form.status.accountName = resp.data.data.accountName;
            const feeAmount = resp.data.data.feeAmount
              .replace(",", "")
              .split(".")[0];
            const feeConvert = parseInt(feeAmount.replace(/\D/g, ""), 10);
            this.form.status.feeAmount = feeConvert;
            this.form.status.nominalTransfer = this.form.transfer.amount;
            this.form.status.totalTransfer =
              this.form.status.feeAmount + this.form.status.nominalTransfer;
            this.property.modal.formConfirmStatus = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.submitLoading = false;
      }
    },
    async addStatusCashOutDanamon() {
      const data = {
        transferReferenceNumber: this.form.status.transferReferenceNumber,
        clientReferenceNumber: this.form.status.clientReferenceNumber,
      };
      this.property.animation.submitLoading = true;
      try {
        if (this.validationDomain === "olibs.bprshikp.co.id") {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "outgoing-service",
            reqUrl: "danamon/status",
            payload: data,
          });
          console.log("data inquery = > ", resp);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.backToList();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } else {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "outgoing-service-appdev",
            reqUrl: "danamon/status",
            payload: data,
          });
          console.log("data inquery = > ", resp);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-success",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.backToList();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.submitLoading = false;
      }
    },
  },
  mounted() {
    this.getReferenceBank();
  },
};
