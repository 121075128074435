export default {
  name: "FormMasterAssetCategory",
  props: {
    isExisting: false,
    message: {
      type: Object,
      required: false,
      default: {},
    },
  },
  computed: {
    validationCoaId() {
      return this.formSchemeModel.chartOfAccountId;
    },
  },
  data() {
    return {
      formSchemeModel: {
        assetName: "",
        chartOfAccountCreditId: "",
        chartOfAccountId: "",
        chartOfAccountDebitId: "",
        description: "",
        assetParentId: "",
        fixedAsset: false,
      },

      options: {
        accountNumber: [],
        officeId: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/parameter/master-asset-category");
    },

    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.officeId = resp.data.data.content;
          this.options.officeId.unshift({
            officeId: "",
            officeName: "-- Pilih --",
          });
        }
      } catch (error) {}
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.accountNumber = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountId;
            const description = index.description;
            this.options.accountNumber.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        return;
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.formSchemeModel = {
          ...this.formSchemeModel,
          assetName: payload.assetName,
          officeId: payload.moffice?.officeId,
          chartOfAccountId: payload.mchartOfAccount?.chartOfAccountId,
          description: payload.description,
          chartOfAccountCreditId:
            payload.mchartOfAccountCredit?.chartOfAccountId,
          fixedAsset: payload.fixedAsset,
          chartOfAccountDebitId: payload.mchartOfAccountDebit?.chartOfAccountId,
          createdByUserCode: payload.createdByUserCode,
          createdByUserName: payload.createdByUserName,
          createdDate: payload.createdDate,
          updatedDate: payload.updatedDate,
          updatedByUserCode: payload.updatedByUserCode,
          updatedByUserName: payload.updatedByUserName,
          authorizedDate: payload.authorizedDate,
          authorizedByUserCode: payload.authorizedByUserCode,
          authorizedByUserName: payload.authorizedByUserName,
        };
        return;
      }
    },
    submit() {
      this.$emit("submit", this.formSchemeModel);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceOffice();
    this.getReferenceChartOfAccount();
  },
};
