var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-end flex-col"},[_c('div',{staticClass:"flex justify-between flex-wrap flex-row"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 gap-4 md:lg-grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"},[_c('div',{staticClass:"flex flex-col items-center"},[_c('form-builder',{attrs:{"message-mapper":_vm.formMessage,"scheme":{
                officeName: {
                  type: 'text',
                  label: 'Cabang',
                  isInformationComponent: true,
                  value: _vm.identity.session.officeName,
                },
                mortgageRegisterNumber: {
                  type: 'number',
                  show: !_vm.isExisting,
                  label: 'Nomor SBR Awal',
                  models: 'mortgageRegisterNumber',
                  event: {
                    type: 'change',
                    action: function (e) {
                      _vm.changeMortgageNumberEnd(e);
                    },
                  },
                },
                dataMortgageRegisterNumber: {
                  type: 'number',
                  show: _vm.isExisting,
                  label: 'Nomor SBR Awal',
                  isInformationComponent: true,
                  value: _vm.dataForm.data.mortgageRegisterNumber,
                },
                sequence: {
                  type: 'number',
                  label: 'Jumlah SBR',
                  show: !_vm.isExisting,
                  models: 'sequence',
                  event: {
                    type: 'change',
                    action: function (e) {
                      _vm.changeMortgageNumberEnd(e);
                    },
                  },
                },
                mortgageRegisterNumberLast: {
                  type: 'number',
                  show: !_vm.isExisting,
                  label: 'Nomor SBR Akhir',
                  isInformationComponent: true,
                  value: _vm.identity.mortgageRegisterNumberAkhir,
                },
              }},model:{value:(_vm.dataForm),callback:function ($$v) {_vm.dataForm=$$v},expression:"dataForm"}})],1)])]),_c('div',{staticClass:"flex my-3 justify-center w-full mt-5"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"220px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":function($event){$event.preventDefault();return _vm.handleRouteToPageSBR.apply(null, arguments)}}},[_vm._v("Kembali Ke List")]),(_vm.isExisting === false)?_c('b-button',{staticClass:"button-theme-confirm",attrs:{"loading":_vm.property.animation.sbr.isLoading,"disabled":_vm.property.animation.sbr.isLoading === true,"type":"is-success"},on:{"click":function($event){$event.preventDefault();return _vm.submitSbr.apply(null, arguments)}}},[_vm._v(" Simpan")]):_vm._e()],1)])])]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.dataForm.createdByUserCode,"createdByUserName":_vm.dataForm.createdByUserName,"createdDate":_vm.dataForm.createdDate,"updatedDate":_vm.dataForm.updatedDate,"updatedByUserCode":_vm.dataForm.updatedByUserCode,"updatedByUserName":_vm.dataForm.updatedByUserName,"authorizedDate":_vm.dataForm.authorizedDate,"authorizedByUserCode":_vm.dataForm.authorizedByUserCode,"authorizedByUserName":_vm.dataForm.authorizedByUserName}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }