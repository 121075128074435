export default {
  name: "FormMCollectorFunding",
  props: {
    isExisting: false,
    message: {
      type: Object,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      form: {
        chartOfAccountId: "",
        userId: "",

        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },

      options: {
        userId: [],
        chartOfAccountId: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        return;
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.chartOfAccountId = payload.chartOfAccountId;
        this.form.userId = payload.userId;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/collector-funding");
    },
    submit() {
      this.$emit("submit", this.form);
    },
    getReferenceUserId() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "v2/user/active-user",
          params: {
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            this.options.userId = [{ value: "", text: "-- Pilih --" }];
            resp.data.data.content.map((index) => {
              const text = `${index.profileSurename} - ${index.userNik}`;
              const value = index.userId;
              this.options.userId.push({ text, value });
            });
          }
        });
    },

    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.chartOfAccountId = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((data) => {
            const text = `${data.chartOfAccountCode} - ${data.description}`;
            const value = data.chartOfAccountId;
            const description = data.description;
            this.options.chartOfAccountId.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {}
    },
  },

  mounted() {
    this.checkingMainId();
    this.getReferenceUserId();
    this.getReferenceChartOfAccount();
  },
};
