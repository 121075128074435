import FormTDepositAccount from "../form";

export default {
  name: "CreateTDepositAccount",
  components: {
    FormTDepositAccount,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.helperGetDataCifById();
    },
    async helperGetDataCifById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTDepositAccountRef.handleRouteToPageListDepositAccount();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "cif/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTDepositAccountRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : `Terjadi Kesalahan`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTDepositAccountRef.handleRouteToPageListDepositAccount(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTDepositAccountRef.handleRouteToPageListDepositAccount(),
          });
        }
      }
    },
    async handleAddDepositAccount(payload) {
      var data = {
        accountNumber: payload.accountNumber,
        profitShareAddPercent: payload.profitShareAddPercent,
        profitShareTotalPercent: payload.profitShareTotalPercent,
        isRollover: payload.isRollover,
        openDate: payload.openDate,
        timePeriode: payload.timePeriode,
        dueDate: payload.dueDate,
        isTaxable: payload.isTaxable,
        taxRestitutionNumber: payload.taxRestitutionNumber,
        isZakat: payload.isZakat,
        infaqNominal: payload.infaqNominal,
        nominal: payload.nominal,
        qqName: payload.qqName,
        qqCode: payload.qqCode,
        accountOfficerHandleId: payload.accountOfficerHandleId,
        accountOfficerPromoId: payload.accountOfficerPromoId,
        profitShareBankCode: payload.profitShareBankCode,
        profitShareBankName: payload.profitShareBankName,
        profitShareBankAccount: payload.profitShareBankAccount,
        profitShareBankAccountName: payload.profitShareBankAccountName,
        profitShareBankCost: payload.profitShareBankCost,
        profitShareBankNews: payload.profitShareBankNews,
        depositTransferBankCode: payload.depositTransferBankCode,
        depositTransferBankName: payload.depositTransferBankName,
        depositTransferBankAccount: payload.depositTransferBankAccount,
        depositTransferBankAccountName: payload.depositTransferBankAccountName,
        depositTransferBankCost: payload.depositTransferBankCost,
        depositTransferBankNews: payload.depositTransferBankNews,
        isBlocked: payload.isBlocked,
        transactionDate: payload.transactionDate,
        blockedDate: payload.blockedDate,
        accountClassificationId: payload.accountClassificationId,
        cifId: this.identity.mainId,
        depositId: payload.depositId,
        smsNotification: payload.smsNotification,
        rsourceFundId: payload.rsourceFundId,
        rpurposeOpenAccountId: payload.rpurposeOpenAccountId,
        rdepositOptionDisburseFundId: payload.rdepositOptionDisburseFundId,
        rdepositOptionDisburseProfitShareId:
          payload.rdepositOptionDisburseProfitShareId,
        rdepositOptionDisburseDepositId:
          payload.rdepositOptionDisburseDepositId,
        rblockedReasonId: payload.rblockedReasonId,
        mdepositNisbahChartOfAccountId: payload.mdepositNisbahChartOfAccountId,
        disburseDepositAccount: payload.disburseDepositAccount,
        disburseFundAccount: payload.disburseFundAccount,
        disburseProfitShareAccount: payload.disburseProfitShareAccount,
        referalOfficeId: payload.referalOfficeId,
        commitRate: payload.commitRate,
        isBpjs: payload.isBpjs,
      };
      console.log(JSON.stringify(payload));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formTDepositAccountRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formTDepositAccountRef.handleRouteToPageListDepositAccount();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1500);
        this.$refs.formTDepositAccountRef.property.animation.submitLoading = false;
      }
    },
  },
};
