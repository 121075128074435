import Moment from "moment";

export default {
  name: "FormMInterbankAssets",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        biCode: "",
        bankName: "",
        chartOfAccountId: "",
        accountBranch: "",
        accountNumber: "",
        accountName: "",
        balance: "",
        interbankAssetsTypeId: "",
        timePeriode: "",
        dueDate: "",
        closeDate: "",
        interestRate: 0,
        tenorYear: 0,
        tenorMonth: 0,
        tenorDay: 0,
        tenor: 1,
        descriptionCoa: "",
        openDate: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        showMargin: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
        maxValue: {
          JatuhTempo: "",
        },
      },
      options: {
        kodeBank: [],
        jenisAsetAba: [],
        kodeAkunCoa: [],
        tenor: [
          { value: 0, text: "-- Pilih --" },
          { value: 1, text: "Harian" },
          { value: 2, text: "Bulanan" },
          { value: 3, text: "Tahun" },
        ],
      },
    };
  },
  methods: {
    marginShow() {
      this.form.createdDate === null
        ? this.form.showMargin == false
        : this.form.showMargin == true;
    },
    routeToPageListInterbankAssets() {
      this.$router.push("/interbank-assets");
    },
    changeJatuhTempo() {
      if (this.form.openDate !== "") {
        var endDate = Moment(this.form.closeDate);
        var startDate = Moment(this.form.openDate);
        console.log(endDate, startDate);
        switch (this.form.tenor) {
          case 1:
            this.form.tenorDay = endDate.diff(startDate, "days") + 1;
            break;
          case 2:
            this.form.tenorMonth = endDate.diff(startDate, "months") + 1;
            break;
          case 3:
            this.form.tenorYear = endDate.diff(startDate, "years") + 1;
            break;

          default:
            return;
            break;
        }
      }
    },
    changeChartOfAccount() {
      this.form.descriptionCoa = "";
      this.options.kodeAkunCoa.map((i) => {
        if (i.value === this.form.chartOfAccountId) {
          this.form.descriptionCoa = i.description;
          console.log(i.value);
        }
      });
    },
    changeTenor() {
      this.form.tenorYear = 0;
      this.form.tenorMonth = 0;
      this.form.tenorDay = 0;
      this.form.closeDate = "";
    },
    calculateJangkaWaktuDay() {
      if (this.form.openDate !== "") {
        var day = this.form.tenorDay;
        console.log(new Date(this.form.openDate));
        var calculate = Moment(new Date(this.form.openDate))
          .add(day, "days")
          .format();
        this.form.closeDate = calculate;
      } else {
        return;
      }
    },
    calculateJangkaWaktuMonth() {
      if (this.form.openDate !== "") {
        var month = this.form.tenorMonth;
        var calculate = Moment(new Date(this.form.openDate))
          .add(month, "month")
          .format();
        this.form.closeDate = calculate;
      }
    },
    calculateJangkaWaktuYear() {
      if (this.form.openDate !== "") {
        var year = this.form.tenorYear;
        var calculate = Moment(new Date(this.form.openDate))
          .add(year, "year")
          .format();
        this.form.closeDate = calculate;
      }
    },
    getReferenceChartOfAccount() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "chart-of-account",
          params: {
            description: "",
            page: 0,
          },
        })
        .then((resp) => {
          this.options.kodeAkunCoa = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            const description = i.description;
            this.options.kodeAkunCoa.push({
              text,
              value,
              description,
            });
          });
        });
    },
    getReferenceInterbankAssetsType() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "interbank-assets-type",
        })
        .then((resp) => {
          this.options.jenisAsetAba = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.interbankAssetsTypeId;
            const text = i.interbankAssetsTypeName;
            this.options.jenisAsetAba.push({ text, value });
          });
        });
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.biCode = payload.biCode;
        this.form.bankName = payload.bankName;
        this.form.chartOfAccountId = payload.mchartOfAccount
          ? payload.mchartOfAccount.chartOfAccountId
          : "";
        this.form.accountBranch = payload.accountBranch;
        this.form.accountNumber = payload.accountNumber;
        this.form.accountName = payload.accountName;
        this.form.balance = payload.balance;
        this.form.tenor =
          payload.tenorDay !== 0
            ? 1
            : payload.tenorMonth !== 0
            ? 2
            : payload.tenorYear !== 0
            ? 3
            : 0;
        this.form.interbankAssetsTypeId = payload.rinterbankAssetsType
          ? payload.rinterbankAssetsType.interbankAssetsTypeId
          : "";
        this.form.closeDate = payload.closeDate;
        this.form.interestRate = payload.interestRate;
        this.form.tenorYear = payload.tenorYear;
        this.form.tenorMonth = payload.tenorMonth;
        this.form.tenorDay = payload.tenorDay;
        this.form.descriptionCoa = payload.mchartOfAccount
          ? payload.mchartOfAccount.description
          : "";
        this.form.openDate = payload.createdDate;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    sumbitInterbankAssets() {
      this.$emit("sumbitInterbankAssets", this.form);
    },
    appendDefaultInputValue() {
      this.form.openDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendDefaultInputValue();
    this.getReferenceInterbankAssetsType();
    this.getReferenceChartOfAccount();
  },
};
