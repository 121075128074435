var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"500px"}},[_c('form-builder',{attrs:{"message-mapper":_vm.message,"scheme":{
        assetName: {
          type: 'text',
          label: 'Nama Aset',
          models: 'assetName',
        },
        chartOfAccountId: {
          type: 'model-list-select',
          label: 'COA Kategori',
          models: 'chartOfAccountId',
          selectProperty: {
            options: _vm.options.accountNumber,
            bindValue: 'value',
            bindText: function (item) {
              return ("" + (item.text));
            },
          },
        },
        fixedAsset: {
          type: 'checkbox',
          label: 'Fixed Asset',
          models: 'fixedAsset',
          checkboxProperty: {
            label: 'Ya',
            falseValue: false,
            trueValue: true,
          },
        },
        chartOfAccountCreditId: {
          type: 'model-list-select',
          label: 'COA Credit',
          models: 'chartOfAccountCreditId',
          selectProperty: {
            options: _vm.options.accountNumber,
            bindValue: 'value',
            bindText: function (item) {
              return ("" + (item.text));
            },
          },
          // show:
          //   validationCoaId !==
          //   fetchAppSession('@vue-session/config-bundle')
          //     .VUE_APP_COA_ID_AKTIVA_TETAP_TANAH,
        },
        chartOfAccountDebitId: {
          type: 'model-list-select',
          label: 'COA Debit',
          models: 'chartOfAccountDebitId',
          selectProperty: {
            options: _vm.options.accountNumber,
            bindValue: 'value',
            bindText: function (item) {
              return ("" + (item.text));
            },
          },
          // show:
          //   validationCoaId !==
          //   fetchAppSession('@vue-session/config-bundle')
          //     .VUE_APP_COA_ID_AKTIVA_TETAP_TANAH,
        },
        description: {
          type: 'textarea',
          label: 'Keterangan',
          models: 'description',
        },
      }},model:{value:(_vm.formSchemeModel),callback:function ($$v) {_vm.formSchemeModel=$$v},expression:"formSchemeModel"}})],1),_c('div',{staticClass:"flex mt-5 justify-center w-full"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.backToList}},[_vm._v("Kembali")]),_c('b-button',{staticClass:"button-theme-confirm",attrs:{"disabled":!_vm.$store.getters['getActionsInsert'] ||
            _vm.property.animation.submitLoading,"loading":_vm.property.animation.submitLoading,"type":"is-success"},on:{"click":_vm.submit}},[_vm._v(" Simpan ")])],1)]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.formSchemeModel.createdByUserCode,"createdByUserName":_vm.formSchemeModel.createdByUserName,"createdDate":_vm.formSchemeModel.createdDate,"updatedDate":_vm.formSchemeModel.updatedDate,"updatedByUserCode":_vm.formSchemeModel.updatedByUserCode,"updatedByUserName":_vm.formSchemeModel.updatedByUserName,"authorizedDate":_vm.formSchemeModel.authorizedDate,"authorizedByUserCode":_vm.formSchemeModel.authorizedByUserCode,"authorizedByUserName":_vm.formSchemeModel.authorizedByUserName}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }