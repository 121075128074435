<template>
  <div>
    <b-modal
      v-model="showModal"
      :width="500"
      :canClose="['outside']"
      scroll="clip"
      :on-cancel="onCloseModal"
    >
      <header style="border-radius:0px" class="modal-card-head">
        <span class="tetx-base">Form Update AO</span>
      </header>
      <div class="card" style="width: auto;border-radius:0px">
        <div
          class="bg-white pb-5 pt-3 flex justify-start items-center flex-col"
          style="width:auto;"
        >
          <div
            v-if="form"
            style="max-width: 430px"
            class="flex flex-row flex-wrap justify-between w-full items-center mt-3"
          >
            <span class="text-sm  w-full md:w-1/2">AO Handle</span>
            <b-field class="w-full md:w-1/2">
              <model-select
                :options="options.accountOfficer"
                v-model="form.accountOfficerIdHandle"
                style="width: 100%; font-size: 12px"
              ></model-select>
            </b-field>
          </div>
          <div
            v-if="form"
            style="max-width: 430px"
            class="flex flex-row flex-wrap justify-between w-full items-center mt-3"
          >
            <span class="text-sm  w-full md:w-1/2">AO Collector</span>
            <b-field class="w-full md:w-1/2">
              <model-select
                :options="options.accountOfficer"
                v-model="form.collectorId"
                style="width: 100%; font-size: 12px"
              ></model-select>
            </b-field>
          </div>
          <div
            style="max-width: 430px"
            class="flex flex-row justify-between w-full items-center mt-20"
          >
            <b-button @click="onCloseModal" class="is-light" style="width:49%"
              >Batal</b-button
            >
            <b-button @click="onConfirm" type="is-link" style="width:49%"
              >Simpan</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "UpdateAoLoanAccountDialog",
  data() {
    return {
      form: null,
      showModal: false,
      isLoading: false,
      options: {
        accountOfficer: [],
      },
    };
  },
  methods: {
    onOpenModal(payload) {
      this.showModal = true;
      this.form = payload;
    },
    onCloseModal() {
      this.showModal = false;
      this.form = null;
    },
    async onConfirm() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Konfirmasi ?",
        "is-success"
      );
      if (confirmation) {
        this.isLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "loan",
            reqUrl: "transaction-loan/update-ao/" + this.form.loanAccountId,
            payload: this.form,
          });
          if (resp.data.code === "SUCCESS") {
            this.onCloseModal();
            await this.simpleWait(700);
            this.$buefy.toast.open(this.$NotificationUtils.success);
            this.$emit("onfinish");
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getReferenceAo() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "v2/user/active-user",
          params: {
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.accountOfficer.push({ text, value });
          });
        });
    },
  },
  created() {
    this.getReferenceAo();
  },
};
</script>

<style></style>
