import ModalSearchSavingAccount from "../../../../components/modal-search-saving-account";

export default {
  name: "FormOverbook",
  components: {
    ModalSearchSavingAccount,
  },
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        transactionName: "",
        referenceNumber: "",
        transactionCodeId: "",
        nominal: 0,
        description: "",
        sourceAccountNumber: "",
        destinationAccountNumber: "",
        documentProofNumber: "",
        sourceNameAccountNumber: "",
        sourceOfficeAccountNumber: "",
        destinationNameAccountNumber: "",
        destinationOfficeAccountNumber: "",
        accountNumberSource: "",
        transactionCode: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        jenisTransaksiPindahBuku: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
        modal: {
          showModalFindSavingAccountSource: false,
          showModalFindSavingAccountDestination: false,
        },
      },
    };
  },
  methods: {
    showModalFindSavingAccount(type) {
      switch (type) {
        case "source":
          this.property.modal.showModalFindSavingAccountSource = true;
          break;
        case "destination":
          this.property.modal.showModalFindSavingAccountDestination = true;
          break;
        default:
          break;
      }
    },
    selectSavingAccountSource(props) {
      this.form.sourceAccountNumber = props.row.accountNumber;
      this.form.sourceNameAccountNumber = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
      this.form.sourceOfficeAccountNumber = props.row.officeName;
    },
    selectSavingAccountDestination(props) {
      this.form.destinationAccountNumber = props.row.accountNumber;
      this.form.destinationNameAccountNumber = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
      this.form.destinationOfficeAccountNumber = props.row.officeName;
    },
    routeToPageListOverbook() {
      this.$router.push("/saving/overbook");
    },
    changeTransactionCode() {
      this.options.jenisTransaksiPindahBuku.map((i) => {
        if (i.value === this.form.transactionCodeId) {
          this.form.transactionCode = i.transactionCode;
        }
      });
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              name: "",
              transactionCodes: this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SAVING_OVERBOOK,
              page: 0,
            },
            url: "transaction-code",
          }
        );
        Promise.all(
          resp.data.data.content.map((i) => {
            const value = this.isExisting
              ? i.transactionCode
              : i.transactionCodeId;
            const text = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.jenisTransaksiPindahBuku.push({
              text,
              value,
              transactionCode,
            });
          })
        ).then(() => {
          if (!this.isExisting) {
            this.form.transactionCodeId = "16";
            this.changeTransactionCode();
          }
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.referenceNumber = payload.referenceNumber;
        this.form.transactionName = payload.transactionName;
        this.form.accountNumberSource = payload.accountNumberSource;
        this.form.nameSource = payload.qqNameSource
          ? `${payload.nameSource} ${payload.qqCodeSource} ${payload.qqNameSource}`
          : payload.nameSource;
        this.form.sourceAccountNumber = payload.accountNumberSource;
        this.form.sourceNameAccountNumber = payload.nameSource;
        this.form.sourceOfficeAccountNumber = payload.officeNameSource;
        this.form.destinationNameAccountNumber = payload.nameDestination;
        this.form.destinationAccountNumber = payload.accountNumberDestination;
        this.form.destinationOfficeAccountNumber =
          payload.officeNameDestination;
        this.form.nominal = payload.nominal;
        this.form.transactionCode = payload.transactionCode;
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.description = payload.description;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    sumbitOverbook() {
      this.$emit("sumbitOverbook", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceTransactionCode();
  },
};
