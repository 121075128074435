var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("User")]),_c('b-input',{staticStyle:{"max-width":"250px","width":"100%"},attrs:{"size":"is-small","disabled":""},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Tanggal")]),_c('datetime',{attrs:{"type":"datetime","input-class":"text-start p-3","input-style":"width: 250px; font-size: 12px; outline: 1px solid #e7eaed; height: 30px;","disabled":""},model:{value:(_vm.form.tanggal),callback:function ($$v) {_vm.$set(_vm.form, "tanggal", $$v)},expression:"form.tanggal"}})],1),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center  my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Kode Transaksi")]),_c('model-select',{staticStyle:{"max-width":"250px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.kodeTransaksi},on:{"input":_vm.onChangeTransactionCode},model:{value:(_vm.form.transactionCode),callback:function ($$v) {_vm.$set(_vm.form, "transactionCode", $$v)},expression:"form.transactionCode"}})],1),(
        _vm.form.transactionCode ===
          _vm.fetchAppSession('@vue-session/config-bundle')
            .VUE_APP_TRX_CODE_PENGISIAN_KAS_ANTAR_TELLER ||
          _vm.form.transactionCode ===
            _vm.fetchAppSession('@vue-session/config-bundle')
              .VUE_APP_TRX_CODE_PENGEMBALIAN_KAS_ANTAR_TELLER
      )?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center  my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Teller Tujuan")]),_c('model-select',{staticStyle:{"max-width":"250px","width":"100%","font-size":"12px"},attrs:{"options":_vm.options.user},model:{value:(_vm.form.userId),callback:function ($$v) {_vm.$set(_vm.form, "userId", $$v)},expression:"form.userId"}})],1):_vm._e(),(_vm.isExisting === false)?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Saldo Kas")]),_c('b-input',{staticStyle:{"max-width":"250px","width":"100%"},attrs:{"size":"is-small","disabled":"","value":_vm.form.currentBalanceNominal
            ? _vm.convertDecimalCurrency(_vm.form.currentBalanceNominal)
            : _vm.form.currentBalanceNominal}})],1):_vm._e(),(_vm.form.transactionCode)?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.form.transactionCode === _vm.fetchAppSession("@vue-session/config-bundle") .VUE_APP_TRX_CODE_PENGEMBALIAN_KAS_TELLER ? "Jumlah Pengembalian" : _vm.form.transactionCode === _vm.fetchAppSession("@vue-session/config-bundle") .VUE_APP_TRX_CODE_PENGISIAN_KAS_TELLER || _vm.form.transactionCode === _vm.fetchAppSession("@vue-session/config-bundle") .VUE_APP_TRX_CODE_PENGISIAN_KAS_ANTAR_TELLER || _vm.form.transactionCode === _vm.fetchAppSession("@vue-session/config-bundle") .VUE_APP_TRX_CODE_PENGEMBALIAN_KAS_ANTAR_TELLER ? "Jumlah Isi Kas" : ""))]),_c('b-field',{staticStyle:{"max-width":"250px","width":"100%"}},[_c('money',{staticClass:"text-start p-3 text-xs rounded",staticStyle:{"max-width":"250px","width":"100%","outline":"1px solid #e7eaed","height":"30px"},attrs:{"disabled":_vm.form.transactionCode == '' ? '' : false},nativeOn:{"keyup":function($event){return _vm.onChangeBalanceMutation.apply(null, arguments)}},model:{value:(_vm.form.balanceMutation),callback:function ($$v) {_vm.$set(_vm.form, "balanceMutation", $$v)},expression:"form.balanceMutation"}})],1)],1):_vm._e(),(_vm.isExisting === false)?_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Total Kas")]),_c('b-input',{staticStyle:{"max-width":"250px","width":"100%"},attrs:{"size":"is-small","disabled":"","value":_vm.form.totalCash
            ? _vm.convertDecimalCurrency(_vm.form.totalCash)
            : _vm.form.totalCash}})],1):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm"},[_vm._v("Keterangan")]),_c('b-input',{staticStyle:{"max-width":"250px","width":"100%"},attrs:{"type":"textarea","size":"is-small"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('div',{staticClass:"flex mt-5 justify-center w-full"},[_c('div',{class:_vm.isExisting === false
            ? 'w-full flex justify-between items-center flex-row'
            : 'w-full flex justify-center items-center flex-row',staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.routeToPageListBalanceCash}},[_vm._v("Kembali")]),(_vm.isExisting === false)?_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-success","disabled":!_vm.$store.getters['getActionsInsert'],"loading":_vm.property.animation.submitLoading},on:{"click":function($event){return handleSubmit(_vm.sumbitBalanceCash)}}},[_vm._v(" Proses")]):_vm._e()],1)]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.form.createdByUserCode,"createdByUserName":_vm.form.createdByUserName,"createdDate":_vm.form.createdDate,"updatedDate":_vm.form.updatedDate,"updatedByUserCode":_vm.form.updatedByUserCode,"updatedByUserName":_vm.form.updatedByUserName,"authorizedDate":_vm.form.authorizedDate,"authorizedByUserCode":_vm.form.authorizedByUserCode,"authorizedByUserName":_vm.form.authorizedByUserName}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }