import FormTSingleEntry from "../form";

export default {
  name: "ExistingSingleEntry",
  components: {
    FormTSingleEntry,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    async getSingleEntryById(payload) {
      console.log('test', payload)
      this.identity.mainId = payload;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTSingleEntryRef.routeToPageListSingleEntry();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "single-entry/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$refs.formTSingleEntryRef.getDefaultFormValue(resp.data.data);
        }
      } catch (error) {}
    },
    async editSingleEntry(payload) {
      const data = {
        chartOfAccountId: payload.chartOfAccountId,
        officeId: payload.officeId,
        nominal: payload.nominal,
        mutationPosition: payload.mutationPosition,
        description: payload.description,
      };
      console.log(data);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.$refs.formTSingleEntryRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl: "single-entry/" + this.identity.mainId,
          payload: data,
        });
        await this.simpleWait(1000);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.toast.open(
            this.$NotificationUtils.success
          );
          toastBuefy.$on("close", () => {
            this.$refs.formTSingleEntryRef.routeToPageListSingleEntry();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1000);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formTSingleEntryRef.property.animation.submitLoading = false;
      }
    },
  },
};
