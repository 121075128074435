export default {
  name: "ListTSingleEntry",
  data() {
    return {
      property: {
        listElement: {
          singleEntry: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      table: {
        data: {
          singleEntry: [],
        },
      },
    };
  },
  methods: {
    routeToPageRincianSingleEntry(props) {
      this.$router.push(
        `/single-entry/${this.encryptBASE64(
          `${props.row.journalLedgerDetailId}`
        )}`
      );
    },
    routeToPageAddSingleEntry() {
      this.$router.push("/single-entry/new");
    },
    async authorizationSingleEntry(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl: "single-entry/authorized/" + props.row.journalLedgerDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.singleEntry.rows = 0;
          this.property.listElement.singleEntry.currentPage = 1;
          this.helperGetTableSingleEntry();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    deleteSingleEntry(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "single-entry/" + props.row.journalLedgerDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.singleEntry.currentPage = 1;
                  this.property.listElement.singleEntry.rows = 0;
                  this.helperGetTableSingleEntry();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async helperGetTableSingleEntry() {
      this.table.data.singleEntry = [];
      this.property.listElement.singleEntry.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "single-entry",
          payload: {
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.singleEntry = resp.data.data.content;
          this.property.listElement.singleEntry.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSingleEntry(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSingleEntry(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.singleEntry.downloading = false;
      }
    },
    failedGetSingleEntry(resp) {
      this.property.listElement.singleEntry.rows = 0;
      this.table.data.singleEntry = [];
      this.property.listElement.singleEntry.message = resp.data.message;
    },
    handleErrorGetSingleEntry(error) {
      console.log(error.response);
      this.table.data.singleEntry = [];
      this.property.listElement.singleEntry.rows = 0;
      this.property.listElement.singleEntry.currentPage = 1;
      this.property.listElement.singleEntry.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationSingleEntry(event) {
      this.table.data.singleEntry = [];
      this.property.listElement.singleEntry.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "single-entry",
          payload: {
            page: event - 1,
            size: this.property.listElement.singleEntry.perPage,
          },
        });
        console.log(resp);
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.singleEntry = resp.data.data.content;
          this.property.listElement.singleEntry.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSingleEntry(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSingleEntry(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.singleEntry.downloading = false;
      }
    },
  },
  mounted() {
    this.helperGetTableSingleEntry();
  },
};
