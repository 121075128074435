import "core-js/stable";
import Vue from "vue";
import App from "./App";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store/index";
import router from "./router";
// import "primeicons/primeicons.css"; //icons

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Buefy from "buefy";
import pdf from "vue-pdf";
import VUppercase from "v-uppercase";
import VueMask from "v-mask";
import vueDebounce from "vue-debounce";
import detect from "detect-file-type";
import VueSignature from "vue-signature-pad";

import "./vee-validate";

import {
  HandleImplementRole,
  HanldeSetupHeaders,
  HandleResponseGuard,
  HandleSetupConsole,
  NotificationErrorFieldToText,
  dateToYearMonthDay,
  encryptBASE64,
  decryptBASE64,
  endOfMonthDate,
  printOutWindow,
} from "./service/serviceHelpers";

import MoreInformationDetail from "./components/more-information-detail";
import UpdateAoLoanAccountDialog from "./components/page/update-ao-loan-account-dialog";
import NotificationUtilsData from "./components/notifications/";
import DocumentChecklistModalUpload from "./components/document-checklist-modal-upload/";
import CommonSelectSort from "./components/common/select-sort";
import FormBuilder from "@/components/form-builder";
import money from "v-money";
import "regenerator-runtime/runtime.js";

import { ModelSelect, ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import "tailwindcss/dist/tailwind.min.css";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TableProgres from "vue-spinner/src/PulseLoader.vue";
import VueHead from "vue-head";

import { exportExcel } from "./service/ExcelJSExport";
import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);
Vue.component("model-select", ModelSelect);
Vue.component("model-list-select", ModelListSelect);
Vue.component("vue-pdf", pdf);
Vue.use(vueDebounce);
Vue.use(VUppercase);
Vue.use(VueSignature);
Vue.use(VueMask);
Vue.use(Buefy);
Vue.use(money, {
  precision: 0,
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  masked: false,
});
Vue.mixin({
  data() {
    return {
      MaskDate: "##-##-####",
      // MaskNpwp: "##.###.###.#-###.###",
      MaskNpwp: "################",
      MaskKtp: "################",
      MaskPostalCode: "#####",
      VMoneyPrecision2: {
        precision: 2,
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        masked: false,
      },
      VMoneyPrecisionWeightFormat: {
        precision: 2,
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: "",
        masked: false,
      },
      ipfsDmsUrlImageViewer: "https://dms-dev-io.digiard.id/ipfs/",
    };
  },
  methods: {
    printOutWindow(blob) {
      printOutWindow(blob);
    },
    fetchAppSession(name) {
      return JSON.parse(this.decryptBASE64(sessionStorage.getItem(name)));
    },
    endOfMonthDate(val) {
      return endOfMonthDate(val);
    },
    exportExcel(payload) {
      exportExcel(payload);
    },
    async convertBase64ToBlob(url) {
      const resp = await fetch(url);
      let blobResp = await resp.blob();
      return blobResp;
    },
    simpleConfirmation(message, type) {
      return new Promise(async (resolve, reject) => {
        this.$buefy.dialog.confirm({
          message: message,
          type: type,
          onConfirm: () => {
            resolve(true);
          },
          onCancel: () => {
            reject(false);
          },
        });
      });
    },
    connectionNavigator() {
      if (!window.navigator.onLine) {
        this.$buefy.snackbar.open(
          this.$NotificationUtils.snackbarConnectionError
        );
        return false;
      }
      return true;
    },
    detectFileTypeFromBuffer(bytebuffer) {
      return new Promise(async (resolve, reject) => {
        detect.fromBuffer(bytebuffer, function(err, result) {
          if (err) {
            reject(err);
          }
          resolve(result);
        });
      });
    },
    simpleWait(duration) {
      return new Promise(async (resolve, reject) => {
        setTimeout(() => {
          resolve("");
        }, duration);
      });
    },
    postalCodeFinder(data, value) {
      return data.find((i) => i.value === value).postalcode;
    },
    encryptBASE64(val) {
      return encryptBASE64(val);
    },
    decryptBASE64(hash) {
      return decryptBASE64(hash);
    },

    globalNotificationErrorFieldToText: function(value) {
      return NotificationErrorFieldToText(value);
    },
    dateToYearMonthDay: function(date) {
      return dateToYearMonthDay(date);
    },
    dateToMonthText(val) {
      const date = new Date(val);
      const monthName = date.toLocaleString("id-ID", { month: "long" });
      return `${date.getDate()} ${monthName} ${date.getFullYear()}`;
    },
    dateToMonth(val) {
      const date = new Date(val);
      const monthName = date.toLocaleString("id-ID", { month: "long" });
      return `${monthName} ${date.getFullYear()}`;
    },
    dateToYear(val) {
      const date = new Date(val);
      return `${date.getFullYear()}`;
    },
    formatMinusAccountingVersion(value) {
      var convertToPositive = value * -1;
      return `( ${convertToPositive
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")} )`;
    },
    emailFormatChecking(val) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(val);
    },
    validateStringContainsNumber(val) {
      if (!val) {
        return true;
      }
      var re = /^[A-Za-z\s]+$/;
      return re.test(val);
    },
    /* Validasi Special Character Password */
    validateSpecialCharactersPassword(val) {
      var specialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/;
      var uppercase = /[A-Z]/;
      var number = /[0-9]/;
      var lowercase = /[a-z]/;

      return (
        specialCharacter.test(val) &&
        uppercase.test(val) &&
        number.test(val) &&
        lowercase.test(val)
      );
    },
    modelListSelectLoanBiCodeCombination(item) {
      return `${item.sandiBiCode} - ${item.sandiBiName}`;
    },
    stringContainNumberValidation(text) {
      return /\d/.test(text);
    },
    debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    convertDecimalCurrency(val) {
      if (!val) {
        return 0;
      }
      let formatter = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY",
        currencyDisplay: "code",
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      })
        .format(val)
        .replace("JPY", "")
        .trim();
      return formatter;
    },
    convertCurrency(val) {
      var harga = val
          .toString()
          .split("")
          .reverse()
          .join(""),
        hargaRibuan = harga.match(/\d{1,3}/g);
      hargaRibuan = hargaRibuan
        .join(",")
        .split("")
        .reverse()
        .join("");
      return hargaRibuan;
    },
  },
});
Vue.prototype.$NotificationUtils = NotificationUtilsData;
Vue.config.productionTip = false;
Vue.component("MoreInformationDetail", MoreInformationDetail);
Vue.component("update-ao-loan-account-dialog", UpdateAoLoanAccountDialog);
Vue.component("document-checklist-modal-upload", DocumentChecklistModalUpload);
Vue.component("cbs-table-progres", TableProgres);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("common-select-sort", CommonSelectSort);
Vue.component("form-builder", FormBuilder);

window.axios = require("axios");
Vue.config.performance = true;
// Vue.config.devtools = process.env.NODE_ENV === "development" ? true : false;
Vue.config.devtools = true;
Vue.use(CoreuiVue);

Vue.use(Datetime);
Vue.component("datetime", Datetime);
Vue.use(VueHead);

Vue.prototype.$log = console.log.bind(console);

const getUserPayloadData = sessionStorage.getItem("@vue-session/auth-bundle");

HandleResponseGuard();
HandleSetupConsole();

if (getUserPayloadData) {
  HanldeSetupHeaders();
  HandleImplementRole();
}

console.log(process.env.NODE_ENV);

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
