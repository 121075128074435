export default {
  name: "ListTCashCollection",
  data() {
    return {
      property: {
        listElement: {
          cashCollection: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          edcCode: "",
          dateFrom: "",
          dateTo: "",
          collectorUserName: "",
          statusAccountId: "",
        },
      },
      table: {
        data: {
          cashCollection: [],
        },
      },
      options: {
        statusAccount: [],
      },
    };
  },
  methods: {
    async findCashCollector() {
      this.table.data.cashCollection = [];
      this.property.listElement.cashCollection.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account/cash-collection",
          payload: {
            edcCode: this.property.filterDto.edcCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            collectorUserName: this.property.filterDto.collectorUserName,
            page: 0,
            size: this.property.listElement.cashCollection.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashCollection = resp.data.data.content;
            this.property.listElement.cashCollection.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.cashCollection.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetCashCollection(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashCollection(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashCollection.downloading = false;
        }, timeout + 500);
      }
    },

    routeToPageDetailCashCollection(props) {
      sessionStorage.setItem("MULTI_ACCOUNT_ID", props.row.multiAccountId);
      this.$router.push("detail");
    },
    routerToPageAddCashCollectiont() {
      this.$router.push("add");
    },
    async getMCashCollection() {
      this.table.data.cashCollection = [];
      this.property.listElement.cashCollection.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account/cash-collection",
          payload: {
            edcCode: "",
            dateFrom: "",
            dateTo: "",
            collectorUserName: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashCollection = resp.data.data.content;
            this.property.listElement.cashCollection.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetCashCollection(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashCollection(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashCollection.downloading = false;
        }, timeout);
      }
    },
    failedGetCashCollection(resp) {
      this.property.listElement.cashCollection.rows = 0;
      this.table.data.cashCollection = [];
      this.property.listElement.cashCollection.message = resp.data.message;
    },
    handleErrorGetCashCollection(error) {
      console.log(error.response);
      this.table.data.cashCollection = [];
      this.property.listElement.cashCollection.rows = 0;
      this.property.listElement.cashCollection.currentPage = 1;
      this.property.listElement.cashCollection.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationCashCollection(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "t-multi-account/cash-collection/authorized/" +
                  props.row.multiAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.cashCollection.currentPage = 1;
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.collectorUserName = "";
                  this.property.filterDto.edcCode = "";
                  this.property.listElement.cashCollection.rows = 0;
                  this.getMCashCollection();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationCashCollection(event) {
      this.table.data.cashCollection = [];
      this.property.listElement.cashCollection.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account/cash-collection",
          payload: {
            edcCode: this.property.filterDto.edcCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            collectorUserName: this.property.filterDto.collectorUserName,
            page: event - 1,
            size: this.property.listElement.cashCollection.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashCollection = resp.data.data.content;
            this.property.listElement.cashCollection.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetCashCollection(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashCollection(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashCollection.downloading = false;
        }, timeout);
      }
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getMCashCollection();
    this.getReferenceStatusAccount();
  },
};
