import FormAtmChannel from "../form";
export default {
  name: "AtmChannelCreate",
  components: {
    FormAtmChannel,
  },
  data() {
    return {
      mainId: "",
    };
  },
  methods: {
    async addAtmChannel(payload) {
      const data = {
        sourceChannelCode: payload.sourceChannelCode,
        destinationChannelCode: payload.destinationChannelCode,
        amount: payload.amount,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Tambah Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.$refs.formAtmChannelRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel-transaction",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formAtmChannelRef.backToList();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formAtmChannelRef.property.animation.submitLoading = false;
      }
    },
  },
};
