export default {
  name: "FormTLoanSp3",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      dataForm: {
        cifId: "",
        officeName: "",
        cifNumber: "",
        cifIdName: "",
        sp3DocNumber: "",
        nominalLoan: "",
        sp3Date: "",
        sp3Subject: "",
        sp3DocumentFile1: "",
        deliveryDate: "",
        receiptDate: "",
        approvalDate: "",
        sp3DocumentFile2: "",
        returnDate: "",
        loanSp3SendreceiveTypeByDeliverySendreceiveTypeId: "",
        loanSp3DebiturResponseByDebiturResponseId: "",
        loanSp3SendreceiveTypeByReturnSendreceiveTypeId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        pengirimanSp3: [],
        tanggapanNasabah: [],
        pengembalianSp3: [],
      },
      property: {
        animation: {
          loanSp3: {
            isLoading: false,
          },
        },
      },
    };
  },
  methods: {
    routeToPageListLoanSp3() {
      this.$router.push("/loan/loan-sp3");
    },
    getReferenceLoanSp3SendReceiveType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-sp3-send-receive-type",
        })
        .then((resp) => {
          this.options.pengirimanSp3 = [{ value: "", text: "-- Pilih --" }];
          this.options.pengembalianSp3 = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((i) => {
            const value = i.sendreceiveTypeId;
            const text = i.sendreceiveTypeName;
            this.options.pengirimanSp3.push({ text, value });
            this.options.pengembalianSp3.push({ text, value });
          });
        });
    },
    getReferenceLoanSp3DebiturResponse() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-sp3-debitur-response",
        })
        .then((resp) => {
          this.options.tanggapanNasabah = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((i) => {
            const value = i.debiturResponseId;
            const text = i.debiturResponseName;
            this.options.tanggapanNasabah.push({ text, value });
          });
        });
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListLoanSp3();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? (this.identity.mainId = this.decryptBASE64(params))
          : (this.identity.mainId = JSON.parse(
              this.decryptBASE64(query)
            ).cifId);
      this.$emit("getMainId", this.identity.mainId);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.officeName = payload.mcif
          ? payload.mcif.moffice
            ? payload.mcif.moffice.officeName
            : ""
          : "";
        this.dataForm.cifId = payload.mcif ? payload.mcif.cifId : "";
        this.dataForm.cifIdName = payload.mcif ? payload.mcif.cifIdName : "";
        this.dataForm.cifNumber = payload.mcif ? payload.mcif.cifNumber : "";
        this.dataForm.sp3DocNumber = payload.sp3DocNumber;
        this.dataForm.sp3Date = payload.sp3Date;
        this.dataForm.sp3Subject = payload.sp3Subject;
        this.dataForm.sp3DocumentFile1 = payload.sp3DocumentFile1;
        this.dataForm.deliveryDate = payload.deliveryDate;
        this.dataForm.loanSp3SendreceiveTypeByDeliverySendreceiveTypeId = payload.rloanSp3SendreceiveTypeByDeliverySendreceiveTypeId
          ? payload.rloanSp3SendreceiveTypeByDeliverySendreceiveTypeId
              .sendreceiveTypeId
          : "";
        this.dataForm.receiptDate = payload.receiptDate;
        this.dataForm.loanSp3DebiturResponseByDebiturResponseId = payload.rloanSp3DebiturResponseByDebiturResponseId
          ? payload.rloanSp3DebiturResponseByDebiturResponseId.debiturResponseId
          : "";
        this.dataForm.approvalDate = payload.approvalDate;
        this.dataForm.loanSp3SendreceiveTypeByReturnSendreceiveTypeId = payload.rloanSp3SendreceiveTypeByReturnSendreceiveTypeId
          ? payload.rloanSp3SendreceiveTypeByReturnSendreceiveTypeId
              .sendreceiveTypeId
          : "";
        this.dataForm.sp3DocumentFile2 = payload.sp3DocumentFile2;
        this.dataForm.returnDate = payload.returnDate;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.dataForm.officeName = payload.officeId
        ? payload.officeId.officeName
        : "";
      this.dataForm.cifNumber = payload.cifNumber;
      this.dataForm.cifIdName = payload.cifIdName;
    },
    sumbitLoanSp3() {
      this.$emit("submitLoanSp3", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceLoanSp3SendReceiveType();
    this.getReferenceLoanSp3DebiturResponse();
  },
  destroyed() {},
};
