export default {
  state: {
    identifier: {
      savingId: "",
    },
    options: {
      savingNisbahChartOfAccount: {
        savingChartOfAccount: [],
      },
    },
  },
  actions: {
    CLEAR_OPTIONS_SAVING({ commit }, payload) {
      commit("CLEAR_OPTIONS_SAVING");
    },
    SET_IDENTIFIER_SAVING_ID({ commit }, payload) {
      commit("SET_IDENTIFIER_SAVING_ID", payload);
    },
  },
  mutations: {
    SET_IDENTIFIER_SAVING_ID(state, payload) {
      state.identifier.savingId = payload;
    },
    CLEAR_OPTIONS_SAVING(state, payload) {
      state.identifier.savingId = "";
      state.options.savingNisbahChartOfAccount.savingChartOfAccount = [];
    },
  },
  getters: {
    get_identifier_saving_id: (state) => state.identifier.savingId,
  },
};
