export default {
  name: "FormTSavingAccountDetailCloseSavingAccount",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      options: {
        kodeTransaksi: [],
      },
      form: {
        savingId: "",
        accountNumber: "",
        cifIdName: "",
        officeName: "",
        balanceEnd: 0,
        costAccountClose: 0,
        documentProofNumber: "",
        transactionDate: "",
        accountNumberPair: "",
        nominal: 0,
        holdNominal: 0,
        description: "",
        kodeTransaksi: "",
        transactionCodeId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        journalRecord: {
          transactionCode: "",
          productCode: "",
          journalRecordDetails: [],
        },
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
    };
  },
  methods: {
    async getSavingChartOfAccountBySavingId() {
      this.form.journalRecord.journalRecordDetails = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: `saving-chart-of-account/saving-transaction/${this.form.savingId}/${this.form.transactionCodeId}`,
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.savingChartOfAccountId;
            const chartOfAccountId = i.mchartOfAccount
              ? i.mchartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.mchartOfAccount
              ? i.mchartOfAccount.chartOfAccountCode
              : "";
            const label = i.mproductLedger
              ? i.mproductLedger.productLedgerName
              : "";
            const pairType = i.pairType;
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.mchartOfAccount
              ? i.mchartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairMChartOfAccount
              ? i.pairMChartOfAccount.chartOfAccountId
              : "";
            const pairChartOfAccountCode = i.pairMChartOfAccount
              ? i.pairMChartOfAccount.chartOfAccountCode
              : "";
            const amount = 0;
            this.form.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              chartOfAccountCode,
              pairChartOfAccountId,
              pairChartOfAccountCode,
              label,
              positionMutation,
              labelInputComponent,
              pairType,
              pairPositionMutation,
              amount,
              index: index,
            });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async getSavingAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListSavingAccountClose();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.form.accountNumber = resp.data.data.accountNumber;
            this.form.cifIdName = resp.data.data.mcif
              ? resp.data.data.qqName
                ? `${resp.data.data.mcif.cifIdName} ${resp.data.data.qqCode} ${resp.data.data.qqName}`
                : resp.data.data.mcif.cifIdName
              : "";
            this.form.officeName = resp.data.data.mcif
              ? resp.data.data.mcif.officeId
                ? resp.data.data.mcif.officeId.officeName
                : ""
              : "";
            this.form.balanceEnd = resp.data.data.balanceEnd;
            this.form.costAccountClose = resp.data.data.msaving
              ? resp.data.data.msaving.balanceMinimum
              : "";
            this.form.savingId = resp.data.data.msaving
              ? resp.data.data.msaving.savingId
              : "";
            this.form.journalRecord.productCode = resp.data.data.msaving
              ? resp.data.data.msaving.savingCode
              : "";
            this.form.holdNominal = resp.data.data.holdNominal;
            this.calculateNominalDitarik();
            this.getSavingChartOfAccountBySavingId();
            this.defaultInputSavingAccountDetailClose();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListSavingAccountClose(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListSavingAccountClose(),
          });
        }
      }
    },
    calculateNominalDitarik() {
      this.form.nominal =
        this.form.balanceEnd -
        this.form.costAccountClose -
        this.form.holdNominal;
    },
    routeToPageListSavingAccountClose() {
      this.$router.push("/saving/close-saving-account");
    },
    defaultInputSavingAccountDetailClose() {
      this.form.description = `${this.form.kodeTransaksi} An ${this.form.cifIdName}`;
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListSavingAccountClose();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).savingAccountId;
      this.$emit("getMainId", this.identity.mainId);
      this.form.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.form.valutaDate =
        this.isExisting === true
          ? this.form.valutaDate
          : new Date().toISOString();
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.referenceNumber = payload.referenceNumber;
        this.form.accountNumber = payload.accountNumber;
        this.form.cifIdName = payload.tsavingAccount
          ? payload.tsavingAccount.mcif
            ? payload.tsavingAccount.qqName
              ? `${payload.tsavingAccount.mcif.cifIdName} ${payload.tsavingAccount.qqCode} ${payload.tsavingAccount.qqName}`
              : payload.tsavingAccount.mcif.cifIdName
            : ""
          : "";
        this.form.officeName = payload.moffice
          ? payload.moffice.officeName
          : "";
        this.form.transactionName = payload.mtransactionCode
          ? payload.mtransactionCode.transactionName
          : "";
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.transactionDate = payload.transactionDate;
        this.form.valutaDate = payload.valutaDate;
        this.form.balanceEnd = payload.balanceEnd;
        this.form.nominal = payload.nominal;
        this.form.costAccountClose = payload.tsavingAccount
          ? payload.tsavingAccount.msaving
            ? payload.tsavingAccount.msaving.balanceMinimum
            : ""
          : "";
        this.form.description = payload.description;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.form.kodeTransaksi = payload.transactionName;
      this.form.transactionCodeId = payload.transactionCodeId;
      this.form.journalRecord.transactionCode = payload.transactionCode;
      this.getSavingAccountById();
    },
    submitSavingAccountDetailClose() {
      this.$emit("submitSavingAccountDetailClose", this.form);
    },
    convertDecimalCurrency(val) {
      if (!val) {
        return 0;
      }
      let formatter = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY",
        currencyDisplay: "code",
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      })
        .format(val)
        .replace("JPY", "")
        .trim();
      return formatter;
    },
    onChangeSaldoDapatDitarik(event) {
      console.log("this is infaq", event.target.value);
      // this.dataForm.step6.reset();
      // if (!event) {
      //   return;
      // }
      // var l_id = this.mixin_data_options_loan_document_checklist_loan_account.find(
      //     (data) => data.value === event
      // );
      // this.dataForm.filter.loanAccountId = l_id.loanAccountId;
      // this.getLoanDocumentChecklistByLoanAccountId();
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendDefaultInputValue();
  },
};
