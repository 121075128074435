export default {
  name: "FormDayOff",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          dayOff: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        dayOffDate: "",
        dayOffDescription: "",
        dayOffId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
    };
  },
  methods: {
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.dayOffDate = payload.dayOffDate;
        this.dataForm.dayOffDescription = payload.dayOffDescription;
        this.dataForm.dayOffId = payload.dayOffId;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/parameter/day-off/");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitDayOff() {
      this.$emit("submitDayOff", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
  },
};
