import FormMUser from "../form";

export default {
  name: "CreateMUser",
  components: {
    FormMUser,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    async addUser(payload) {
      const data = {
        tellerAuthorizationLimit: payload.tellerAuthorizationLimit,
        userName: payload.userName,
        userPassword: payload.userPassword,
        otherAuthorizationLimit: payload.otherAuthorizationLimit,
        userLimit: payload.userLimit,
        expiredDate: payload.expiredDate,
        dayPasswordValid: payload.dayPasswordValid,
        loanAuthorizationLimit: payload.loanAuthorizationLimit,
        profileSurename: payload.profileSurename,
        userNik: payload.userNik,
        muserRoleId: payload.muserRoleId,
        ruserJobtitleId: payload.ruserJobtitleId,
        isAccessAccount: payload.isAccessAccount,
        isActivityLog: payload.isActivityLog,
        mdepartmentId: payload.mdepartmentId,
        ruserStatusId: payload.ruserStatusId,
        mofficeCode: payload.mofficeCode,
        depositAuthorizationLimit: payload.depositAuthorizationLimit,
        profileMobilePhone: payload.profileMobilePhone,
      };
      console.log(JSON.stringify(data));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formMUserRef.property.animation.addUser.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "user-management",
                reqUrl: "v2/user",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$refs.formMUserRef.routeToPageListUser();
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formMUserRef.property.animation.addUser.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
