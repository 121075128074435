import FormOverbook from "../form";

export default {
  name: "CreateOverbook",
  components: {
    FormOverbook,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    async addOverbook(payload) {
      const data = {
        transactionCodeId: payload.transactionCodeId,
        nominal: payload.nominal,
        description: payload.description,
        sourceAccountNumber: payload.sourceAccountNumber,
        destinationAccountNumber: payload.destinationAccountNumber,
        documentProofNumber: payload.documentProofNumber,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formOverbookRef.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "overbook",
                payload: data,
              });
              await this.simpleWait(1500);
              if (resp.data.code === "SUCCESS") {
                const toastBuefy = this.$buefy.snackbar.open(
                  this.$NotificationUtils.snackbarSuccess
                );
                toastBuefy.$on("close", () => {
                  this.$refs.formOverbookRef.routeToPageListOverbook();
                });
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              await this.simpleWait(1500);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            } finally {
              await this.simpleWait(1000);
              this.$refs.formOverbookRef.property.animation.submitLoading = false;
            }
          },
        });
      }
    },
  },
};
