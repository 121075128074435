export default {
  name: "MutasiCoa",
  data() {
    return {
      property: {
        listElement: {
          mutasiCoa: {
            massage: "",
            downloading: false,
          },
        },
        animation: {
          isDownloadingFile: false,
        },
        modal: {
          showModalDownloadCsv: false,
        },
      },
      options: {
        chartOfAccount: [],
        office: [],
        users: [],
      },
      form: {
        dateFrom: "",
        dateTo: "",
        chartOfAccount: "",
        office: "",
        userCode: "",
        footerTotal: "",
      },
      table: {
        data: {
          mutasiCoa: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        coaId: this.form.chartOfAccount,
        officeCode: this.form.office,
        userCode: this.form.userCode,
        isComaDelimiter: true,
        fileType: fileType,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "coa-mutation-history/download-csv",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Mutasi_Sbb.${fileType === "xls" ? "csv" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
        this.property.modal.showModalDownloadCsv = false;
      }
    },
    async onDownloadFileUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        coaId: this.form.chartOfAccount,
        officeCode: this.form.office,
        userCode: this.form.userCode,
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "coa-mutation-history/download-csv",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Mutasi_Sbb.${fileType === "csv" ? "csv" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
        this.property.modal.showModalDownloadCsv = false;
      }
    },
    async onDownloadFileIdn(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        coaId: this.form.chartOfAccount,
        officeCode: this.form.office,
        userCode: this.form.userCode,
        isComaDelimiter: false,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "coa-mutation-history/download-csv",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Mutasi_Sbb.${fileType === "csv" ? "csv" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.modal.showModalDownloadCsv = false;
        this.property.animation.isDownloadingFile = false;
      }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.chartOfAccount.push({ value: "", text: "-- Pilih --" });
          resp.data.data.content.map((data) => {
            const text = `${data.chartOfAccountCode} - ${data.description}`;
            const value = data.chartOfAccountId;

            this.options.chartOfAccount.push({
              text,
              value,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeCode;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async helperGetUserDataForReference() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
              officeCode: "",
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.users = [{ value: "", text: "-- Semua User --" }];
          resp.data.data.content.map((index) => {
            const value = index.userCode;
            const text = `${index.userCode} - ${index.profileSurename}`;
            this.options.users.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getCoMutationsHistory() {
      this.property.listElement.mutasiCoa.downloading = true;
      this.table.data.mutasiCoa = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "coa-mutation-history",
          payload: {
            dateFrom: this.form.dateFrom,
            dateTo: this.form.dateTo,
            coaId: this.form.chartOfAccount,
            officeCode: this.form.office,
            userCode: this.form.userCode,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.table.data.mutasiCoa = resp.data.data.list;
          this.form.footerTotal = resp.data.data;
        } else {
          this.property.listElement.mutasiCoa.message = resp.data.message;
        }
      } catch (error) {
        this.property.listElement.mutasiCoa.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      }
      this.property.listElement.mutasiCoa.downloading = false;
    },
    closeModalDownloadCsv() {
      this.property.modal.showModalDownloadCsv = false;
    },
  },

  mounted() {
    this.getReferenceChartOfAccount();
    this.getReferenceOffice();
    this.helperGetUserDataForReference();
  },
};
