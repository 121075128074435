import FormTSavingAccountDetailCloseSavingAccount from "../form";

export default {
  name: "CreateTSavingAccountDetailCloseSavingAccount",
  components: {
    FormTSavingAccountDetailCloseSavingAccount,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getTransactionCode();
    },
    async getTransactionCode() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTSavingAccountDetailCloseSavingAccountRef.routeToPageListSavingAccountClose();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl:
              "transaction-code/transaction-code/" +
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CLOSE_SAVING_ACCOUNT,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTSavingAccountDetailCloseSavingAccountRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTSavingAccountDetailCloseSavingAccountRef.routeToPageListSavingAccountClose(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTSavingAccountDetailCloseSavingAccountRef.routeToPageListSavingAccountClose(),
          });
        }
      }
    },
    async addSavingAccountClose(payload) {
      const data = {
        documentProofNumber: payload.documentProofNumber,
        savingAccountId: this.identity.mainId,
        transactionDate: payload.transactionDate,
        valutaDate: payload.valutaDate,
        nominal: payload.nominal,
        description: payload.description,
        journalRecord: payload.journalRecord,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formTSavingAccountDetailCloseSavingAccountRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formTSavingAccountDetailCloseSavingAccountRef.routeToPageListSavingAccountClose();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formTSavingAccountDetailCloseSavingAccountRef.property.animation.submitLoading = false;
      }
    },
  },
};
