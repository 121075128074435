export default {
  state: {
    identifier: {
      loanId: "",
    },
  },
  actions: {
    CLEAR_OPTIONS_LOAN({ commit }, payload) {
      commit("CLEAR_OPTIONS_LOAN");
    },
    SET_IDENTIFIER_LOAN_ID({ commit }, payload) {
      commit("SET_IDENTIFIER_LOAN_ID", payload);
    },
  },
  mutations: {
    SET_IDENTIFIER_LOAN_ID(state, payload) {
      state.identifier.loanId = payload;
    },
    CLEAR_OPTIONS_LOAN(state, payload) {
      state.identifier.loanId = "";
    },
  },
  getters: {
    get_identifier_loan_id: (state) => state.identifier.loanId,
  },
};
