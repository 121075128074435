export default {
  name: "AtmChannelForm",
  props: {
    isExisting: false,
  },
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        mainId: "",
      },
      form: {
        atmChannelCode: "",
        officeId: "",
        chartOfAccountId: "",
        userId: "",
        currentBalance: 0,
      },
      options: {
        office: [],
        chartOfAccount: [],
        user: [],
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/atm-channel");
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "office/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.map((index) => {
            const text = `${index.officeCode} - ${index.officeName}`;
            const value = index.officeId;
            const officeName = index.officeName;
            this.options.office.push({
              text,
              value,
              officeName,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.chartOfAccount = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountId;
            const description = index.description;
            this.options.chartOfAccount.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getRefenceUser() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.user = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const value = index.userId;
            const text = `${index.profileSurename} - ${index.userNik}`;
            this.options.user.push({ text, value });
          });
        }
      } catch (error) {}
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        return;
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form = {
          ...this.form,
          atmChannelCode: payload.atmChannelCode,
          officeId: payload.officeId,
          currentBalance: payload.currentBalance,
          chartOfAccountId: payload.chartOfAccountId,
          userId: payload.userId,
          createdByUserCode: payload.createdByUserCode,
          createdByUserName: payload.createdByUserName,
          createdDate: payload.createdDate,
          updatedDate: payload.updatedDate,
          updatedByUserCode: payload.updatedByUserCode,
          updatedByUserName: payload.updatedByUserName,
          authorizedDate: payload.authorizedDate,
          authorizedByUserCode: payload.authorizedByUserCode,
          authorizedByUserName: payload.authorizedByUserName,
        };
        return;
      }
    },
    submit() {
      this.$emit("submit", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceOffice();
    this.getReferenceChartOfAccount();
    this.getRefenceUser();
  },
};
