import FormDttot from "../form";

export default {
    name: "ExistingDtto",
    components: {
        FormDttot,
    },
    data() {
      return {
        identity: {
          mainId: "",
        },
        message: {},
      };
    },
    methods: {
      getMainId(props) {
        this.identity.mainId = props;
        this.getDttoById();
      },
      async getDttoById() {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          this.$refs.formDttotRef.backToList();
          return;
        }
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "dttot/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formDttotRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.$refs.formDttotRef.backToList(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan !`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.$refs.formDttotRef.backToList(),
          });
        }
      },
      async editDtto(payload) {
        const data = {
          name: payload.name,
          densusCode: payload.densusCode,
          placeOfBirth: payload.placeOfBirth,
          dateOfBirth: payload.dateOfBirth,
          citizen: payload.citizen,
          address: payload.address,
          suspectId: payload.suspect,
          description: payload.description,
        };
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirmation = await this.simpleConfirmation(
          "Edit Data Ini ?",
          "is-success"
        );
        if (!confirmation) {
          return;
        }
        this.$refs.formDttotRef.property.animation.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "transaction",
            reqUrl: "dttot/" + this.identity.mainId,
            payload: data,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
            this.$refs.formDttotRef.routeToPageListDttot()
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1500);
          this.message = error.response?.data?.errorFields;
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.$refs.formDttotRef.property.animation.submitLoading = false;
        }
      },
    },
  };