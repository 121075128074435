import FormChartOfAccountOverbook from "../form";

export default {
  name: "ExistingOverbookChartOfAccount",
  components: {
    FormChartOfAccountOverbook,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(payload) {
      this.identity.mainId = payload;
      this.getOverbookChartOfAccountById();
    },
    async getOverbookChartOfAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formChartOfAccountOverbookRef.backToList();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "transaction-coa/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$refs.formChartOfAccountOverbookRef.getDefaultFormValue(
            resp.data.data
          );
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formChartOfAccountOverbookRef.backToList(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () =>
            this.$refs.formChartOfAccountOverbookRef.backToList(),
        });
      }
    },
    async editOverbookChartOfAccount(payload) {
      const data = {
        sourceAccountId: payload.sourceAccountId,
        sourceOfficeCode: payload.sourceOfficeCode,
        targetAccountId: payload.targetAccountId,
        targetOfficeCode: payload.targetOfficeCode,
        documentProofNumber: payload.documentProofNumber,
        description: payload.description,
        transactionCode: payload.transactionCode,
        nominal: payload.nominal,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formChartOfAccountOverbookRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl: "transaction-coa/" + payload.referenceNumber,
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formChartOfAccountOverbookRef.property.animation.submitLoading = false;
      }
    },
  },
};
