<template>
  <FormCashAdvance
    ref="formCashAdvanceRef"
    @getMainId="getMainId($event)"
    @submit="editCashAdvance($event)"
    :isExisting="true"
  />
</template>

<script>
import FormCashAdvance from "../form";
export default {
  name: "ExistingCashAdvance",
  components: {
    FormCashAdvance,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(payload) {
      this.identity.mainId = payload;
      this.getCashAdvanaceById();
    },
    async getCashAdvanaceById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formCashAdvanceRef.backToList();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "cash-advance/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          this.$refs.formCashAdvanceRef.getDefaultFormValue(resp.data.data);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.$refs.formCashAdvanceRef.backToList(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.$refs.formCashAdvanceRef.backToList(),
        });
      }
    },
    async editCashAdvance(payload) {
      const data = {
        cashAdvanceName: payload.cashAdvanceName,
        cashAdvanceCode: payload.cashAdvanceCode,
        realizationDate: payload.realizationDate,
        returnDate: payload.returnDate,
        nominal: payload.nominal,
        nominalAdmin: payload.nominalAdmin,
        description: payload.description,
        departmentId: payload.departmentId,
        cashAdvanceTypeId: payload.cashAdvanceTypeId,
        accountNumber: payload.accountNumber,
        accountType: payload.accountType,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.$refs.formCashAdvanceRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance/" + this.identity.mainId,
          payload: data,
        });
        await this.simpleWait(1000);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.toast.open(this.$NotificationUtils.success);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1000);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formCashAdvanceRef.property.animation.submitLoading = false;
      }
    },
  },
};
</script>
