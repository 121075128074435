import Moment from "moment";
export default {
  name: "MoreInformationDetail",
  props: {
    createdByUserCode: "",
    createdByUserName: "",
    createdDate: "",
    updatedDate: "",
    updatedByUserCode: "",
    updatedByUserName: "",
    authorizedDate: "",
    authorizedByUserCode: "",
    authorizedByUserName: "",
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    Moment: Moment,
    setupDate() {
      Moment.locale("ID");
    },
  },
  mounted() {
    this.setupDate();
  },
};
