export default {
  name: "FormLoanSp3Restructure",
  props: {
    isExisting: false,
  },
  data() {
    return {
      property: {
        animation: {
          loanSp3Restructure: {
            isLoading: false,
          },
        },
      },
      options: {
        TypeSP3Baru: [],
        pengirimanSp3: [],
        tanggapanNasabah: [],
        pengembalianSp3: [],
        statusPembiayaan: [],
      },
      dataForm: {
        officeName: "",
        sp3DateOld: "",
        sp3DocNumberOld: "",
        cifIdName: "",
        sp3Date: "",
        sp3Subject: "",
        cifId: "",
        sp3DocumentFile1: "",
        sp3DocNumber: "",
        deliveryDate: "",
        receiptDate: "",
        approvalDate: "",
        sp3DocumentFile2: "",
        returnDate: "",
        sp3DocNumber: "",
        loanSp3SendreceiveTypeByDeliverySendreceiveTypeId: "",
        loanSp3DebiturResponseByDebiturResponseId: "",
        loanSp3SendreceiveTypeByReturnSendreceiveTypeId: "",
        loanStatusId: "",
        loanSp3ParentId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    routeToPageListLoanSp3Restructure() {
      this.$router.push("/loan/loan-sp3-restructure");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListLoanSp3Restructure();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? (this.identity.mainId = this.decryptBASE64(params))
          : (this.identity.mainId = JSON.parse(
              this.decryptBASE64(query)
            ).loanSp3Id);
      this.$emit("getMainId", this.identity.mainId);
    },
    sumbitLoanSp3Restructure() {
      this.$emit("sumbitLoanSp3Restructure", this.dataForm);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.officeName = payload.mcif
          ? payload.mcif.moffice
            ? payload.mcif.moffice.officeName
            : ""
          : "";
        this.dataForm.sp3Date = payload.sp3Date;
        this.dataForm.cifNumber = payload.mcif ? payload.mcif.cifNumber : "";
        this.dataForm.cifIdName = payload.mcif ? payload.mcif.cifIdName : "";
        this.dataForm.sp3Subject = payload.sp3Subject;
        this.dataForm.cifId = payload.mcif ? payload.mcif.cifId : "";
        this.dataForm.sp3DocumentFile1 = payload.sp3DocumentFile1;
        this.dataForm.sp3DocNumber = payload.sp3DocNumber;
        this.dataForm.deliveryDate = payload.deliveryDate;
        this.dataForm.receiptDate = payload.receiptDate;
        this.dataForm.approvalDate = payload.approvalDate;
        this.dataForm.sp3DocumentFile2 = payload.sp3DocumentFile2;
        this.dataForm.returnDate = payload.returnDate;
        this.dataForm.loanSp3SendreceiveTypeByDeliverySendreceiveTypeId = payload.rloanSp3SendreceiveTypeByDeliverySendreceiveTypeId
          ? payload.rloanSp3SendreceiveTypeByDeliverySendreceiveTypeId
              .sendreceiveTypeId
          : "";
        this.dataForm.loanSp3DebiturResponseByDebiturResponseId = payload.rloanSp3DebiturResponseByDebiturResponseId
          ? payload.rloanSp3DebiturResponseByDebiturResponseId.debiturResponseId
          : "";
        this.dataForm.loanSp3SendreceiveTypeByReturnSendreceiveTypeId = payload.rloanSp3SendreceiveTypeByReturnSendreceiveTypeId
          ? payload.rloanSp3SendreceiveTypeByReturnSendreceiveTypeId
              .sendreceiveTypeId
          : "";
        this.dataForm.loanStatusId = payload.rloanStatus
          ? payload.rloanStatus.loanStatusId
          : "";
        this.dataForm.loanSp3ParentId = payload.tloanSp3Parent
          ? payload.tloanSp3Parent.loanSp3Id
          : "";
        this.dataForm.sp3DocNumberOld = payload.tloanSp3Parent
          ? payload.tloanSp3Parent.sp3DocNumber
          : "";
        this.dataForm.sp3DateOld = payload.tloanSp3Parent
          ? payload.tloanSp3Parent.sp3Date
          : "";
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.dataForm.officeName = payload.mcif.moffice.officeName;
      this.dataForm.cifIdName = payload.mcif.cifIdName;
      this.dataForm.sp3DateOld = payload.sp3Date;
      this.dataForm.loanSp3ParentId = payload.loanSp3Id;
      this.dataForm.cifId = payload.mcif.cifId;
      this.dataForm.sp3DocNumberOld = payload.sp3DocNumber;
    },
    getReferenceLoanStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-status",
        })
        .then((resp) => {
          this.options.statusPembiayaan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.loanStatusId;
            const text = i.loanStatusName;
            this.options.statusPembiayaan.push({ text, value });
          });
        });
      this.dataForm.loanStatusId = "4";
    },
    getReferenceLoanSp3SendReceiverType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-sp3-send-receive-type",
        })
        .then((resp) => {
          this.options.pengembalianSp3 = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.sendreceiveTypeId;
            const text = i.sendreceiveTypeName;
            this.options.pengirimanSp3.push({ text, value });
            this.options.pengembalianSp3.push({ text, value });
          });
        });
    },
    getReferenceLoanSp3DebiturResponse() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-sp3-debitur-response",
        })
        .then((resp) => {
          this.options.tanggapanNasabah = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.debiturResponseId;
            const text = i.debiturResponseName;
            this.options.tanggapanNasabah.push({ text, value });
          });
        });
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceLoanStatus();
    this.getReferenceLoanSp3SendReceiverType();
    this.getReferenceLoanSp3DebiturResponse();
  },
};
