export default {
  name: "ListOverbookChartOfAccount",
  data() {
    return {
      form: {
        transactionCodeId: "",
      },
      property: {
        filterDto: {
          dateFrom: "",
          dateTo: "",
          transactionCode: "",
          statusAccountId: "",
        },
        listElement: {
          overbookChartOfAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      options: {
        kodeTransaksi: [],
        statusAccount: [],
      },
      table: {
        data: {
          overbookChartOfAccount: [],
        },
      },
    };
  },
  methods: {
    async changePaginationOverbookChartOfAccount(event) {
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "transaction-coa",
          payload: {
            transactionCode: this.property.filterDto.transactionCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusAccountId,
            page: event - 1,
            size: this.property.listElement.overbookChartOfAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.overbookChartOfAccount = resp.data.data.content;
          this.property.listElement.overbookChartOfAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataOverbookChartOfAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorOverbookChartOfAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.overbookChartOfAccount.downloading = false;
      }
    },
    routeToPageRincianOverbookCoa(props) {
      this.$router.push(
        `/overbook-chart-of-account/${this.encryptBASE64(
          `${props.row.referenceNumber}`
        )}`
      );
    },
    async findOverbookChartOfAccount() {
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "transaction-coa",
          payload: {
            transactionCode: this.property.filterDto.transactionCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusAccountId,
            page: 0,
            size: this.property.listElement.overbookChartOfAccount.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbookChartOfAccount = resp.data.data.content;
            this.property.listElement.overbookChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOverbookChartOfAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorOverbookChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbookChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    routeToPageAddOverbookCoa() {
      this.$router.push("overbook-chart-of-account/new");
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              page: 0,
              name: "",
              transactionCodes: "2002,2011,2019",
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.kodeTransaksi = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCode;
            this.options.kodeTransaksi.push({ text, value });
          });
        }
      } catch (error) {}
    },
    handleErrorOverbookChartOfAccount(error) {
      console.log(error.response);
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.rows = 0;
      this.property.listElement.overbookChartOfAccount.currentPage = 1;
      this.property.listElement.overbookChartOfAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationOverbookChartOfAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "transaction-coa/authorized/" + props.row.referenceNumber,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.overbookChartOfAccount.currentPage = 1;
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.transactionCode = "";
                  this.property.filterDto.statusAccountId = "";
                  this.property.listElement.overbookChartOfAccount.rows = 0;
                  this.getOverbookCoa();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteOverbookChartOfAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "transaction-coa/" + props.row.referenceNumber,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.overbookChartOfAccount.currentPage = 1;
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.transactionCode = "";
                  this.property.filterDto.statusAccountId = "";
                  this.property.listElement.overbookChartOfAccount.rows = 0;
                  this.getOverbookCoa();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getOverbookCoa() {
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "transaction-coa",
          payload: {
            transactionCode: "",
            dateFrom: "",
            dateTo: "",
            statusId: "",
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbookChartOfAccount = resp.data.data.content;
            this.property.listElement.overbookChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOverbookChartOfAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorOverbookChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbookChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetDataOverbookChartOfAccount(resp) {
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.rows = 0;
      this.property.listElement.overbookChartOfAccount.message =
        resp.data.message;
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getOverbookCoa();
    this.getReferenceTransactionCode();
    this.getReferenceStatusAccount();
  },
};
