export default {
  name: "ModalSearchSavingAccount",
  props: {
    showModal: false,
    isEdcSearch: false,
  },
  data() {
    return {
      property: {
        findSavingAccount: {
          accountNumberOrCifIdName: "",
        },
        listElement: {
          findSavingAccount: {
            downloading: false,
            message: "",
          },
        },
        modal: {
          showModalAddTargetAccount: false,
          showModalEditTargetAccount: false,
          showModalFindSavingAccount: false,
        },
      },
      table: {
        data: {
          findSavingAccount: {},
        },
      },
      companyName: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModalFindSavingAccount", false);
    },
    selectSavingAccount(props) {
      this.$emit("selectFindSavingAccount", props);
      this.closeModal();
    },
    showModalFindSavingAccount() {
      this.property.modal.showModalFindSavingAccount = true;
    },
    async findSavingAccountByAccount() {
      this.table.data.findSavingAccount = [];
      this.property.listElement.findSavingAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl:
            this.isEdcSearch === true
              ? "saving-account/edc"
              : "saving-account/dual-search",
          payload: {
            accountNumberOrCifIdName: this.property.findSavingAccount
              .accountNumberOrCifIdName,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.findSavingAccount = resp.data.data;
            this.property.listElement.findSavingAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.findSavingAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.property.listElement.findSavingAccount.rows = 0;
            this.property.listElement.findSavingAccount.message =
              resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.table.data.findSavingAccount = [];
          this.property.listElement.findSavingAccount.rows = 0;
          this.property.listElement.findSavingAccount.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.findSavingAccount.downloading = false;
        }, timeout + 500);
      }
    },
  },
  mounted() {
    this.property.findSavingAccount.cifIdName = "";
    this.property.findSavingAccount.accountNumber = "";
    this.findSavingAccountByAccount();
    this.companyName = this.fetchAppSession(
      "@vue-session/asset-bundle"
    ).companyName;
  },
};
