import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SavingAccountStatementListPdf from "@/components/page/saving-account-statement-list-pdf";
import moment from "moment";
export default {
  name: "TSavingHistory",
  components: {
    SavingAccountStatementListPdf,
  },
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
    /* Code Old */
    // minDateRange() {
    //   return new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1)
    //     .toISOString()
    //     .substr(0, 10);
    // },

    minDateRange() {
      const currentYear = 2023;
      const startMonth = 1; // Februari (indeks bulan ke-1)
      const startDay = 2; // Tanggal 2

      // Set tanggal awal ke bulan Februari 2023.
      const startDate = new Date(currentYear, startMonth, startDay);
      return startDate.toISOString().substr(0, 10);
    },
  },

  data() {
    return {
      property: {
        animation: {
          CheckingSavingAccount: {
            isDownloadingFile: false,
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingSavingAccount: false,
          showModalResultListSavingAccount: false,
        },
        listElement: {
          accountStatement: {
            message: "",
            downloading: false,
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          dateFrom: "",
          dateTo: "",
          sorting: "",
          moreThan6Months: false,
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      form: {
        moreThan6Months: false,
        dateFrom: "",
        dateTo: "",
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
          moreThan6Months: false,
        },
        savingAccount: {
          header: {
            accountNumber: 0,
            name: "",
            balanceEnd: 0,
            balanceMinimum: 0,
            holdNominal: 0,
            activeBalance: 0,
            branchCode: 0,
            branchName: "",
            cifAddress: "",
            startDate: "",
            endDate: "",
          },
        },
        officeName: "",
        transactionDate: "",
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
          moreThan6Months: false,
        },
      },
      options: {
        urutkanBerdasarkan: [
          { value: "ASC", text: "Terlama - Terbaru" },
          { value: "DESC", text: "Terbaru - Terlama" },
        ],
      },
      table: {
        header: {
          transaction: [
            {
              key: "transactionDate",
              label: "Tanggal",
            },
            {
              key: "documentNo",
              label: "No Bukti Dokumen",
            },
            {
              key: "transactionNo",
              label: "No Transaksi",
            },
            {
              key: "description",
              label: "Keterangan",
            },
            {
              key: "debit",
              label: "Mutasi Debet",
            },
            {
              key: "credit",
              label: "Mutasi Kredit",
            },
            {
              key: "balanceEnd",
              label: "Saldo Akhir",
            },
          ],
        },
        data: {
          transaction: [],
          savingAccount: [],
        },
      },
    };
  },
  methods: {
    Moment: moment,
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        accountNumber: this.property.filterDto.accountNumber,
        dateTo: this.form.dateTo,
        dateFrom: this.form.dateFrom,
        sorting: this.property.filterDto.sorting,
        moreThan6Months: this.form.moreThan6Months,
      };
      this.property.animation.CheckingSavingAccount.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "account-statement/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Account_Statement.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
        console.log(error);
      } finally {
        this.property.animation.CheckingSavingAccount.isDownloadingFile = false;
      }
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.moreThan6Months = this.property.filterDto.moreThan6Months;
        this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
        this.form.checkingSavingAccount.accountNumber = "";
        this.form.checkingSavingAccount.cifIdName = "";
        // this.property.filterDto.moreThan6Months = false;

        try {
          this.property.animation.CheckingSavingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.form.savingAccount.header.startDate = "";
            this.form.savingAccount.header.endDate = "";
            this.table.data.savingAccount = resp.data.data.content;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.property.modal.showModalResultListSavingAccount = true;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
          this.form.checkingSavingAccount.accountNumber = "";
          this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
          this.form.checkingSavingAccount.cifIdName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.CheckingSavingAccount.isLoading = false;
        }
      }
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.sav;
      ingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    closeModalCheckingSavingAccount() {
      this.property.modal.showModalCheckingSavingAccount = false;
      this.clearModalCheckingSavingAccount();
    },
    clearModalCheckingSavingAccount() {
      this.form.checkingSavingAccount.accountNumber = "";
      this.form.checkingSavingAccount.cifIdName = "";
      this.property.filterDto.dateFrom = "";
      this.property.filterDto.dateTo = "";
      this.property.filterDto.moreThan6Months = false;
    },
    async getSavingAccountDetailAccountStatement(props) {
      this.property.animation.CheckingSavingAccount.isLoading = true;
      this.property.filterDto.accountNumber = props.row.accountNumber;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "account-statement",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
            sorting: this.property.filterDto.sorting,
            moreThan6Months: this.property.filterDto.moreThan6Months,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.clearModalResultCheckingSavingAccount();
          this.table.data.transaction = [];
          this.property.listElement.accountStatement.downloading = true;
          this.property.modal.showModalResultListSavingAccount = false;
          this.form.dateFrom = this.property.filterDto.dateFrom;
          this.form.dateTo = this.property.filterDto.dateTo;
          this.form.moreThan6Months = this.property.filterDto.moreThan6Months;

          this.clearModalCheckingSavingAccount();
          await this.simpleWait(500);
          this.form.savingAccount.header.accountNumber =
            resp.data.data.header.accountNumber;
          this.form.savingAccount.header.name = resp.data.data.header.qqName
            ? `${resp.data.data.header.name} ${resp.data.data.header.qqCode} ${resp.data.data.header.qqName}`
            : resp.data.data.header.name;
          this.form.savingAccount.header.balanceEnd =
            resp.data.data.header.balanceEnd;
          this.form.savingAccount.header.balanceMinimum =
            resp.data.data.header.balanceMinimum;
          this.form.savingAccount.header.holdNominal =
            resp.data.data.header.holdNominal;
          this.form.savingAccount.header.activeBalance =
            resp.data.data.header.activeBalance;
          this.form.savingAccount.header.branchCode =
            resp.data.data.header.branchCode;
          this.form.savingAccount.header.branchName =
            resp.data.data.header.branchName;
          this.form.savingAccount.header.cifAddress =
            resp.data.data.header.cifAddress;
          if (resp.data.data.detail) {
            this.table.data.transaction = resp.data.data.detail;
            this.form.transactionDate = resp.data.data.detail.transactionDate;
            this.preparePeriode();
          } else {
            this.table.data.transaction = [];
            this.property.listElement.accountStatement.message =
              "Belum Ada Riwayat Transaksi";
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            timer: 2000,
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          timer: 2000,
        });
      } finally {
        await this.simpleWait(1000);
        this.property.listElement.accountStatement.downloading = false;
        this.property.animation.CheckingSavingAccount.isLoading = false;
      }
    },
    async defaultPeriodeDate() {
      this.form.savingAccount.header.startDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.form.savingAccount.header.endDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    preparePeriode() {
      if (this.form.dateFrom !== "" && this.form.dateTo !== "") {
        this.form.savingAccount.header.startDate = `${new Date(
          this.form.dateFrom
        ).getDate()} ${
          this.property.accessories.day[new Date(this.form.dateFrom).getMonth()]
        } ${new Date(this.form.dateFrom).getFullYear()}`;

        this.form.savingAccount.header.endDate = `${new Date(
          this.form.dateTo
        ).getDate()} ${
          this.property.accessories.day[new Date(this.form.dateTo).getMonth()]
        } ${new Date(this.form.dateTo).getFullYear()}`;
      } else {
        this.defaultPeriodeDate();
      }
    },
    async sortingSavingAccountStatement(event) {
      this.table.data.transaction = [];
      this.property.listElement.accountStatement.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "account-statement",
          payload: {
            accountNumber: this.form.savingAccount.header.accountNumber,
            dateFrom: this.form.dateFrom,
            dateTo: this.form.dateTo,
            sorting: event,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data.detail) {
            this.table.data.transaction = resp.data.data.detail;
            this.preparePeriode();
          } else {
            this.table.data.transaction = [];
            this.property.listElement.accountStatement.message =
              "Belum Ada Transaksi";
          }
        } else {
          this.table.data.transaction = [];
          this.property.listElement.accountStatement.message =
            resp.data.message;
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorAccountStatement(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.accountStatement.downloading = false;
      }
    },
    handleErrorAccountStatement(error) {
      console.log(error.response);
      this.property.listElement.accountStatement.message = error.response
        ? `${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    prepareSavingAccountStatement() {
      this.property.filterDto.sorting = "ASC";
      const getAccountNumberFromSession = sessionStorage.getItem(
        "ACCOUNT_NUMBER_FROM_BALANCE_INQUIRY"
      );
      if (getAccountNumberFromSession) {
        this.form.checkingSavingAccount.accountNumber = getAccountNumberFromSession;
        this.property.modal.showModalCheckingSavingAccount = true;
      }
    },
    clearIdentifierAccountNumberFromBalanceInquirySessionStorage() {
      sessionStorage.removeItem("ACCOUNT_NUMBER_FROM_BALANCE_INQUIRY");
    },
  },

  mounted() {
    moment.locale("ID");
    this.prepareSavingAccountStatement();
  },
  beforeDestroy() {
    this.clearIdentifierAccountNumberFromBalanceInquirySessionStorage();
  },
};
