export default {
  name: "ListMMenuAccess",
  data() {
    return {
      property: {
        listElement: {
          menu: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          menuAccessName: "",
          menuAccessSort: "",
        },
      },
      table: {
        data: {
          menu: [],
        },
      },
    };
  },
  methods: {
    async findMenuAccess() {
      this.table.data.menu = [];
      this.property.listElement.menu.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          reqUrl: "menu-access",
          payload: {
            menuAccessName: this.property.filterDto.menuAccessName,
            menuAccessSort: this.property.filterDto.menuAccessSort,
            page: 0,
            size: this.property.listElement.menu.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.menu = resp.data.data.content;
            this.property.listElement.menu.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.menu.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataMenuAccess(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorMenu(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.menu.downloading = false;
        }, timeout + 500);
      }
    },
    async deleteDataMenuAccess(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "user-management",
                reqUrl: "menu-access/" + props.row.menuAccessId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.menu.currentPage = 1;
                  this.property.filterDto.menuAccessName = "";
                  this.property.filterDto.menuAccessSort = "";
                  this.property.listElement.menu.rows = 0;
                  this.helperGetDataTableMenu();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageAddMenu() {
      this.$router.push("/admin/menu-access/new");
    },
    routeToPageEditMenu(props) {
      this.$router.push(
        `/admin/menu-access/${this.encryptBASE64(`${props.row.menuAccessId}`)}`
      );
    },
    authorizationMMenu(props) {
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.$buefy.toast.open(this.$NotificationUtilsn.apiNotFound);
        },
      });
    },
    handleCatchErrorMenu(error) {
      console.log(error.response);
      this.table.data.menu = [];
      this.property.listElement.menu.rows = 0;
      this.property.listElement.menu.currentPage = 1;
      this.property.listElement.menu.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationMenu(event) {
      this.table.data.menu = [];
      this.property.listElement.menu.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          reqUrl: "menu-access",
          payload: {
            menuAccessName: this.property.filterDto.menuAccessName,
            menuAccessSort: this.property.filterDto.menuAccessSort,
            page: event - 1,
            size: this.property.listElement.menu.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.menu = resp.data.data.content;
            this.property.listElement.menu.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataMenuAccess(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorMenu(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.menu.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableMenu() {
      this.table.data.menu = [];
      this.property.listElement.menu.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          reqUrl: "menu-access",
          payload: {
            menuAccessName: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.menu = resp.data.data.content;
            this.property.listElement.menu.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataMenuAccess(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorMenu(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.menu.downloading = false;
        }, timeout);
      }
    },

    failedGetDataMenuAccess(resp) {
      this.table.data.menu = [];
      this.property.listElement.menu.rows = 0;
      this.property.listElement.menu.message = resp.data.message;
    },
  },
  mounted() {
    this.helperGetDataTableMenu();
  },
};
