export default {
  name: "FormTInventoryAmortisasi",
  props: {
    isExisting: false,
    message: {
      type: Object,
      required: false,
      default: {},
    },
  },
  computed: {
    validationPairCoa() {
      return this.formSchemeModel.pairChartOfAccountId;
    },
  },
  data() {
    return {
      formSchemeModel: {
        inventoryAmortitationName: "",
        assetId: "",
        inventoryNumber: "",
        inventoryPrice: 0,
        dateBuy: "",
        timePeriod: 0,
        description: "",
        startDate: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },

      options: {
        kelompokBarang: [],
        perkiraanLawan: [],
        user: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        return;
      }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.perkiraanLawan = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountId;
            const description = index.description;
            this.options.perkiraanLawan.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceUser() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "user/tellers",
          endPoint: "parameter",
          payload: {
            isIncluded: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.user = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const text = `${i.userCode} - ${i.userName}`;
            const value = i.userId;
            this.options.user.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceInventoryCategory() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "asset/list",
          payload: {
            fixedAsset: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.kelompokBarang = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((i) => {
            const text = i.assetName;
            const value = i.assetId;
            this.options.kelompokBarang.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.formSchemeModel = payload;
        this.formSchemeModel.assetId = payload.masset?.assetId;
        this.formSchemeModel.startDate = payload.createdDate;
        this.formSchemeModel.createdByUserCode = payload.createdByUserCode;
        this.formSchemeModel.createdByUserName = payload.createdByUserName;
        this.formSchemeModel.createdDate = payload.createdDate;
        this.formSchemeModel.updatedDate = payload.updatedDate;
        this.formSchemeModel.updatedByUserCode = payload.updatedByUserCode;
        this.formSchemeModel.updatedByUserName = payload.updatedByUserName;
        this.formSchemeModel.authorizedDate = payload.authorizedDate;
        this.formSchemeModel.authorizedByUserCode =
          payload.authorizedByUserCode;
        this.formSchemeModel.authorizedByUserName =
          payload.authorizedByUserName;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/inventory-amortisasi");
    },
    submit() {
      this.$emit("submit", this.formSchemeModel);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceInventoryCategory();
    this.getReferenceChartOfAccount();
    this.getReferenceUser();
  },
};
