export default {
  name: "FormMMortgage",
  props: {
    isExisting: false,
    formMessage: {},
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      identifier: {
        slthePrice: 0,
      },
      options: {
        timePeriode: [],
        chartOfAccount: [],
        metalType: [],
        mortgageCarat: [],
      },
      form: {
        mortgageName: "",
        mortgageCode: "",
        rentPricePercent: "",
        timePeriodId: "",
        mortgageCaratId: "",
        estimatePrice: "",
        description: "",
        chartOfAccountId: "",
        slthePrice: "",
        maxLoanPercent: "",
        metalTypeId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
    };
  },
  methods: {
    async getReferenceMortgageCarat() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "mortgage-carat",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.mortgageCarat = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((data) => {
            const text = data.mortgageCaratName;
            const value = data.mortgageCaratId;
            const mortgageCaratCode = parseFloat(data.mortgageCaratCode);
            this.options.mortgageCarat.push({ text, value, mortgageCaratCode });
          });
        }
      } catch (error) {}
    },
    async getMortgageParent() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_LOAN",
          {
            url: "mortgage/simple-list",
            params: {
              mortgageCode: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.mortgageParent = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((i) => {
            const text = `${i.mortgageCode} - ${i.mortgageName}`;
            const value = i.mortgageId;
            this.options.mortgageParent.push({ text, value });
          });
        }
      } catch (error) {}
    },
    changeSelectMortgageCarat(e) {
      this.options.mortgageCarat.map((data) => {
        if (data.value === e ? e : this.form.mortgageCaratId) {
          this.form.slthePrice =
            (this.identifier.slthePrice / 24) * data.mortgageCaratCode;
        }
      });
    },
    async getReferenceMetalType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "mortgage-metal-type",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.metalType = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((data) => {
            const text = `${data.metalTypeName}`;
            const value = data.metalTypeId;
            this.options.metalType.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceTimePeriode() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "time-period",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = `${i.timePeriodName} -${i.timeType}`;
            const value = i.timePeriodId;
            this.options.timePeriode.push({ text, value });
          });
          this.form.timePeriodId = "4";
        }
      } catch (error) {}
    },

    routeToPageListMortgage() {
      this.$router.push("/loan/mortgage");
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            this.options.chartOfAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
    appendDefaultInput() {
      const defaultOption = { value: "", text: "-- Pilih --" };
    },
    async getMortgageMetalPrice(e) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "mortgage-metal-price/last-market-price/" + e,
        });
        if (resp.data.code === "SUCCESS") {
          this.identifier.slthePrice = resp.data.data;
        } else {
          this.identifier.slthePrice = 0;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.changeSelectMortgageCarat();
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.mortgageName = payload.mortgageName;
        this.form.mortgageCode = payload.mortgageCode;
        this.form.rentPricePercent = payload.rentPricePercent;
        this.form.timePeriodId = payload.rtimePeriod
          ? payload.rtimePeriod.timePeriodId
          : "";
        this.form.estimatePrice = payload.estimatePrice;
        this.form.description = payload.description;
        this.form.chartOfAccountId = payload.mchartOfAccount.chartOfAccountId;
        this.form.maxLoanPercent = payload.maxLoanPercent;
        this.form.metalTypeId = payload.rmortgageMetalType
          ? payload.rmortgageMetalType.metalTypeId
          : "";
        this.form.mortgageCaratId = payload.rmortgageCarat
          ? payload.rmortgageCarat.mortgageCaratId
          : "";
        this.form.slthePrice = payload.sltePrice;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitMortgage() {
      this.$emit("submitMortgage", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceMortgageCarat();
    this.getReferenceMetalType();
    this.getReferenceChartOfAccount();
    this.getReferenceTimePeriode();
  },
};
