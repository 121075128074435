<template>
  <b-select
    expanded
    size="is-small"
    class="w-full md:w-1/2"
    icon="sort"
    placeholder="Pilih"
    v-model="value"
    @input="onchange"
  >
    <option
      v-for="option in sortOption"
      :value="option.value"
      :key="option.value"
    >
      {{ option.text }}
    </option>
  </b-select>
</template>

<script>
export default {
  name: "CommonSelectSort",
  props: {
    value: null,
    sortOption: [],
  },
  methods: {
    onchange() {
      this.$emit("onchange", this.value);
    },
  },
};
</script>

<style></style>
