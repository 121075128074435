export default {
  name: "WadiahSavingBonusByOffice",
  data() {
    return {
      property: {
        animation: {
          sumbitLoading: false,
          isDownloadingFile: false,
        },
        listElement: {
          WadiahSaving: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        documentProofNumber: "",
        validateProcess: false,
        savingBonus: [],
        periode: "",
        sbbBonus: 0,
        sbbPajak: 0,
        sbbZakat: 0,
        totalNominalBonus: 0,
        totalNominalTax: 0,
        totalNominalZakat: 0,
        userName: "",
        productCode: "",
        period: "",
      },
      table: {
        data: {
          WadiahSaving: [],
        },
      },
    };
  },
  methods: {
    back() {
      this.$router.push("/wadiah-saving-bonus/by-product");
    },
    async changePaginationWadiahSavingBonus(event) {
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "wadiah-saving-bonus/by-office",
          payload: {
            productCode: this.dataForm.productCode,
            page: event - 1,
            size: this.property.listElement.WadiahSaving.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.WadiahSaving =
              resp.data.data.twadiahBonusSavingResponses.content;
            this.property.listElement.WadiahSaving.rows =
              resp.data.data.twadiahBonusSavingResponses.totalElements;
            this.dataForm.totalNominalBonus = resp.data.data.totalNominalBonus;
            this.dataForm.totalNominalTax = resp.data.data.totalNominalTax;
            this.dataForm.totalNominalZakat = resp.data.data.totalNominalZakat;
          } else {
            this.failedGetDataWadiahSavingBonus(resp);
            this.dataForm.totalNominalBonus = 0;
            this.dataForm.totalNominalTax = 0;
            this.dataForm.totalNominalZakat = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetWadiahSavingBonus(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.WadiahSaving.downloading = false;
        }, timeout);
      }
    },
    async findWadiahSavingAccount() {
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "wadiah-saving-bonus/by-office",
          payload: {
            productCode: this.dataForm.productCode,
            page: 0,
            size: this.property.listElement.WadiahSaving.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.WadiahSaving =
              resp.data.data.twadiahBonusSavingResponses.content;
            this.property.listElement.WadiahSaving.rows =
              resp.data.data.twadiahBonusSavingResponses.totalElements;
            this.dataForm.totalNominalBonus = resp.data.data.totalNominalBonus;
            this.dataForm.totalNominalTax = resp.data.data.totalNominalTax;
            this.dataForm.totalNominalZakat = resp.data.data.totalNominalZakat;
          } else {
            this.failedGetDataWadiahSavingBonus(resp);
            this.dataForm.totalNominalBonus = 0;
            this.dataForm.totalNominalTax = 0;
            this.dataForm.totalNominalZakat = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetWadiahSavingBonus(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.WadiahSaving.downloading = false;
        }, timeout);
      }
    },
    async onDownloadFileByAccount(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        productCode: this.dataForm.productCode,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/wadiah-saving-bonus/recap-by-account/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Bonus_Tabungan_Wadiah.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadFileByOffice(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        productCode: this.dataForm.productCode,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/wadiah-saving-bonus/recap-by-office/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Bonus_Tabungan_Wadiah.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async transactionWadiahSavingBonus() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Transaksikan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl: "wadiah-saving-bonus/authorized",
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.WadiahSaving.currentPage = 1;
          this.property.listElement.WadiahSaving.rows = 0;
          this.getTableWadiahSavingBonus();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    failedGetDataWadiahSavingBonus(resp) {
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.rows = 0;
      this.property.listElement.WadiahSaving.message = resp.data.message;
    },
    handleErrorGetWadiahSavingBonus(error) {
      console.log(error.response);
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.rows = 0;
      this.property.listElement.WadiahSaving.currentPage = 1;
      this.property.listElement.WadiahSaving.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getTableWadiahSavingBonus() {
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "wadiah-saving-bonus/by-office",
          payload: {
            productCode: "",
            page: 0,
            size: this.property.listElement.WadiahSaving.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.WadiahSaving =
              resp.data.data.twadiahBonusSavingResponses.content;
            this.property.listElement.WadiahSaving.rows =
              resp.data.data.twadiahBonusSavingResponses.totalElements;
            this.dataForm.totalNominalBonus = resp.data.data.totalNominalBonus;
            this.dataForm.totalNominalTax = resp.data.data.totalNominalTax;
            this.dataForm.totalNominalZakat = resp.data.data.totalNominalZakat;
          } else {
            this.failedGetDataWadiahSavingBonus(resp);
            this.dataForm.totalNominalBonus = 0;
            this.dataForm.totalNominalTax = 0;
            this.dataForm.totalNominalZakat = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetWadiahSavingBonus(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.WadiahSaving.downloading = false;
        }, timeout);
      }
    },
    dataInformation() {
      const getUserPayload = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.dataForm.userName = getUserPayload.userName;
      this.dataForm.periode = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.dataInformation();
    this.getTableWadiahSavingBonus();
  },
};
