export default {
  state: {
    isTokenExpired: false,
    isCentralLoading: false,
    breadcrumbElement: [],
    userPayloadData: {
      roleId: "",
      actions: {
        authorize: true,
        delete: true,
        detail: true,
        insert: true,
        print: true,
        update: true,
        userLimit: 0,
      },
    },
    userMenuAccess: null,
    balanceCashNominalRoleTeller: 0,
  },
  mutations: {
    SET_USER_ROLE_ID(state, payload) {
      state.userPayloadData.roleId = payload;
    },
    SET_BREADCRUMP_ELEMENT(state, payload) {
      state.breadcrumbElement = payload;
    },
    SET_IS_TOKEN_EXPIRED(state, payload) {
      state.isTokenExpired = payload;
    },
    SET_IS_CENTRAL_LOADING(state, payload) {
      state.isCentralLoading = payload;
    },
    SET_BALANCE_CASH_NOMINAL_ROLE_TELLER(state, payload) {
      state.balanceCashNominalRoleTeller = payload;
    },
    SET_ACTIONS_AUTHORIZED(state, payload) {
      state.userPayloadData.actions.authorize = payload;
    },
    SET_ACTIONS_USER_LIMIT(state, payload) {
      state.userPayloadData.actions.userLimit = payload;
    },
    SET_ACTIONS_DELETE(state, payload) {
      state.userPayloadData.actions.delete = payload;
    },
    SET_ACTIONS_DETAIL(state, payload) {
      state.userPayloadData.actions.detail = payload;
    },
    SET_ACTIONS_INSERT(state, payload) {
      state.userPayloadData.actions.insert = payload;
    },
    SET_ACTIONS_PRINT(state, payload) {
      state.userPayloadData.actions.print = payload;
    },
    SET_ACTIONS_UPDATE(state, payload) {
      state.userPayloadData.actions.update = payload;
    },
    SET_USER_MENU_ACCESS(state, payload) {
      state.userMenuAccess = payload;
    },
  },
  actions: {
    SET_BREADCRUMP_ELEMENT({ commit }, payload) {
      commit("SET_BREADCRUMP_ELEMENT", payload);
    },
    SET_USER_MENU_ACCESS({ commit }, payload) {
      commit("SET_USER_MENU_ACCESS", payload);
    },
    POST_LOGIN({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/login/v2`,
            method: "POST",
            headers: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    POST_LOGIN_DEV({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/login`,
            method: "POST",
            headers: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },

    SET_USER_ROLE_ID({ commit }, payload) {
      commit("SET_USER_ROLE_ID", payload);
    },
    SET_BALANCE_CASH_NOMINAL_ROLE_TELLER({ commit }, payload) {
      commit("SET_BALANCE_CASH_NOMINAL_ROLE_TELLER", payload);
    },
    SET_IS_TOKEN_EXPIRED({ commit }, payload) {
      commit("SET_IS_TOKEN_EXPIRED", payload);
    },
    SET_IS_CENTRAL_LOADING({ commit }, payload) {
      commit("SET_IS_CENTRAL_LOADING", payload);
    },
    SET_ACTIONS_AUTHORIZED({ commit }, payload) {
      commit("SET_ACTIONS_AUTHORIZED", payload);
    },
    SET_ACTIONS_USER_LIMIT({ commit }, payload) {
      commit("SET_ACTIONS_USER_LIMIT", payload);
    },
    SET_ACTIONS_DELETE({ commit }, payload) {
      commit("SET_ACTIONS_DELETE", payload);
    },
    SET_ACTIONS_DETAIL({ commit }, payload) {
      commit("SET_ACTIONS_DETAIL", payload);
    },
    SET_ACTIONS_INSERT({ commit }, payload) {
      commit("SET_ACTIONS_INSERT", payload);
    },
    SET_ACTIONS_PRINT({ commit }, payload) {
      commit("SET_ACTIONS_PRINT", payload);
    },
    SET_ACTIONS_UPDATE({ commit }, payload) {
      commit("SET_ACTIONS_UPDATE", payload);
    },
  },
  getters: {
    isTokenExpired: (state) => state.isTokenExpired,
    isCentralLoading: (state) => state.isCentralLoading,
    getActionsAuthorize: (state) => state.userPayloadData.actions.authorize,
    getBalanceCashNominalRoleTeller: (state) =>
      state.balanceCashNominalRoleTeller,
    getUserRoleId: (state) => state.userPayloadData.roleId,
    getActionsDelete: (state) => state.userPayloadData.actions.delete,
    getActionsDetail: (state) => state.userPayloadData.actions.detail,
    getActionsInsert: (state) => state.userPayloadData.actions.insert,
    getActionsPrint: (state) => state.userPayloadData.actions.print,
    getActionsUserLimit: (state) => state.userPayloadData.actions.userLimit,
    getActionsUpdate: (state) => state.userPayloadData.actions.update,
    getBreadcrumpElement: (state) => state.breadcrumbElement,
    getUserMenuAccess: (state) => state.userMenuAccess,
  },
};
