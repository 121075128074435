export default {
  name: "ListTDepositAccountDetailFundAllocatedOverbook",
  data() {
    return {
      dataForm: {
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      session: {
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        animation: {
          checkingDepositAccount: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingDepositAccount: false,
          showModalResultListDepositAccount: false,
        },
        listElement: {
          depositOverbook: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          depositAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      table: {
        header: {},
        data: {
          depositOverbook: [],
          depositAccount: [],
        },
      },
    };
  },
  methods: {
    async searchByCifIdNameOnDepositOverbook(event) {
      this.table.data.depositOverbook = [];
      this.property.listElement.depositOverbook.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            cifIdName: event,
            transactionCodeId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION_OVERBOOK,
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: this.property.listElement.depositOverbook.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.depositOverbook.rows =
              resp.data.data.totalElements;
            this.table.data.depositOverbook = resp.data.data.content;
            this.property.listElement.depositOverbook.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetDataDepositOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositOverbook.downloading = false;
        }, timeout + 500);
      }
    },
    async searchByAccountNumberOnDepositOverbook(event) {
      this.table.data.depositOverbook = [];
      this.property.listElement.depositOverbook.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/simple-list",
          payload: {
            accountNumber: event,
            cifIdName: this.property.filterDto.cifIdName,
            transactionCodeId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION_OVERBOOK,
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: this.property.listElement.depositOverbook.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.depositOverbook.rows =
              resp.data.data.totalElements;
            this.table.data.depositOverbook = resp.data.data.content;
            this.property.listElement.depositOverbook.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetDataDepositOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositOverbook.downloading = false;
        }, timeout + 500);
      }
    },
    async checkingAvailabilityDepositAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.depositAccount.accountNumber = this.dataForm.depositAccount.accountNumber;
        this.session.depositAccount.cifIdName = this.dataForm.depositAccount.cifIdName;
        this.dataForm.depositAccount.accountNumber = "";
        this.dataForm.depositAccount.cifIdName = "";
        const timeout = 500;
        try {
          this.property.animation.checkingDepositAccount.isLoading = true;
          this.property.listElement.depositAccount.downloading = false;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "deposit-account/simple-list",
            payload: {
              accountNumber: this.session.depositAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.depositAccount.cifIdName,
              dateFrom: "",
              dateTo: "",
              page: 0,
              size: this.property.listElement.depositAccount.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingDepositAccount = false;
              this.property.modal.showModalCheckingResultDepositAccount = true;
              this.table.data.depositAccount =
                resp.data.data.pageResponse.content;
              this.property.listElement.depositAccount.rows =
                resp.data.data.pageResponse.totalElements;
            } else if (resp.data.code === "DATA_NOT_FOUND") {
              this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
              this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
              this.session.depositAccount.accountNumber = "";
              this.session.depositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `Nomor Rekening Tidak Tersedia!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
              this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
              this.session.depositAccount.accountNumber = "";
              this.session.depositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
          this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
          this.session.depositAccount.accountNumber = "";
          this.session.depositAccount.cifIdName = "";
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingDepositAccount.isLoading = false;
          }, timeout);
        }
      }
    },
    handleErrorDepositAccount(error) {
      this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
      this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
      this.session.depositAccount.accountNumber = "";
      this.session.depositAccount.cifIdName = "";
      console.log(error.response);
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.currentPage = 1;
      this.property.listElement.depositAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationDepositAccount(event) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: this.session.depositAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.depositAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.depositAccount.perPage,
            dateFrom: "",
            dateTo: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.depositAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.data.depositAccount =
              resp.data.data.pageResponse.content;
          } else {
            this.failedGetDepositAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetDepositAccount(resp) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.message = resp.data.message;
    },
    routeToPageAddDepositOverbook(props) {
      this.$router.push(
        `/deposit/fund-allocated-overbook/new?refId=${this.encryptBASE64(
          JSON.stringify({ depositAccountId: props.row.depositAccountId })
        )}`
      );
    },
    routeToPageDetailDepositOverbook(props) {
      this.$router.push(
        `/deposit/fund-allocated-overbook/${this.encryptBASE64(
          `${props.row.depositAccountDetailId}`
        )}`
      );
    },
    async authorizationDepositOverbook(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account-detail/authorized/" +
                  props.row.depositAccountDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositOverbook.currentPage = 1;
                  this.property.filterDto.accountNumber = "";
                  this.property.filterDto.cifIdName = "";
                  this.property.listElement.depositOverbook.rows = 0;
                  this.helperGetDataTableDepositOverbook();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleCatchErrorDepositOverbook(error) {
      console.log(error.response);
      this.table.data.depositOverbook = [];
      this.property.listElement.depositOverbook.rows = 0;
      this.property.listElement.depositOverbook.currentPage = 1;
      this.property.listElement.depositOverbook.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationDepositOverbook(event) {
      this.table.data.depositOverbook = [];
      this.property.listElement.depositOverbook.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            transactionCodeId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION_OVERBOOK,
            dateFrom: "",
            dateTo: "",
            page: event - 1,
            size: this.property.listElement.depositOverbook.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositOverbook = resp.data.data.content;
            this.property.listElement.depositOverbook.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataDepositOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositOverbook.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableDepositOverbook() {
      this.table.data.depositOverbook = [];
      this.property.listElement.depositOverbook.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/simple-list",
          payload: {
            accountNumber: "",
            cifIdName: "",
            transactionCodeId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION_OVERBOOK,
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositOverbook = resp.data.data.content;
            this.property.listElement.depositOverbook.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataDepositOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositOverbook.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataDepositOverbook(resp) {
      this.table.data.depositOverbook = [];
      this.property.listElement.depositOverbook.rows = 0;
      this.property.listElement.depositOverbook.message = resp.data.message;
    },

    clearModalCheckingDepositAccount() {
      this.dataForm.depositAccount.accountNumber = "";
      this.dataForm.depositAccount.cifIdName = "";
    },
    clearModalResultCheckingDepositAccount() {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.perPage = 5;
      this.property.listElement.depositAccount.currentPage = 1;
    },
    closeModalCheckingDepositAccount() {
      this.property.modal.showModalCheckingDepositAccount = false;
      this.clearModalCheckingDepositAccount();
    },
  },
  mounted() {
    this.helperGetDataTableDepositOverbook();
  },
};
