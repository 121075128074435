import { jsPDF } from "jspdf";
import "jspdf-autotable";
import MixinsFuntions from "../../../../service/MixinsFuntions";

export default {
  mixins: [MixinsFuntions],
  name: "FormTSavingAccountDetail",
  props: {
    isExisting: false,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identity: {
        mainId: "",
      },
      property: {
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
        modal: {
          showModalPrint: false,
        },
        animation: {
          savingAccountDetail: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
      },
      form: {
        transactionCode: "",
        printSavingAccountDetail: "",
        balanceUsable: 0,
        holdNominal: 0,
        transactionCodeId: "",
        referenceNumber: "",
        transactionNumber: "",
        documentProofNumber: "",
        transactionDate: "",
        valutaDate: "",
        accountNumber: "",
        mutationPosition: "",
        nominal: 0,
        description: "",
        statusSavingAccountDetailId: "",
        savingAccountId: "",
        cifIdName: "",
        cabangName: "",
        cifClassificationName: "",
        descriptionTransactionCode: "",
        isKycIncluded: false,
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        journalRecord: {
          transactionCode: "",
          productCode: "",
          journalRecordDetails: [],
        },
        savingId: "",
        representative: "",
        isRepresentative: false,
        productLedgerId: "",
      },
      options: {
        kodeTransaksi: [],
        metodeBiayaAdmin: [],
      },
    };
  },
  methods: {
    getDateFormat(valDate) {
      var date = new Date(valDate);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    /* CODE LAMA */
    async getSavingChartOfAccountBySavingId() {
      this.form.journalRecord.journalRecordDetails = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: `saving-chart-of-account/saving-transaction/${this.form.savingId}/${this.form.transactionCodeId}`,
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.savingChartOfAccountId;
            const chartOfAccountId = i.mchartOfAccount
              ? i.mchartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.mchartOfAccount
              ? i.mchartOfAccount.chartOfAccountCode
              : "";
            const label = i.mproductLedger
              ? i.mproductLedger.productLedgerName
              : "";
            const pairType = i.pairType;
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.mchartOfAccount
              ? i.mchartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairMChartOfAccount
              ? i.pairMChartOfAccount.chartOfAccountId
              : "";
            const pairChartOfAccountCode = i.pairMChartOfAccount
              ? i.pairMChartOfAccount.chartOfAccountCode
              : "";
            const amount = i.fixedCost;
            const isFixedCost = i.isFixedCost;
            this.form.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              chartOfAccountCode,
              pairChartOfAccountId,
              pairChartOfAccountCode,
              label,
              positionMutation,
              labelInputComponent,
              pairType,
              pairPositionMutation,
              amount,
              isFixedCost,
            });
          });
        }
      } catch (error) {}
    },

    /*CODE BARU*/
    // async getSavingChartOfAccountBySavingId() {
    //   this.form.journalRecord.journalRecordDetails = [];
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_DATA_FIND_BY",
    //       endPoint: "transaction",
    //       reqUrl: `admin-fee/${this.form.transactionCodeId}/${this.form.productLedgerId}?nominalTransaction=${this.form.nominal}`,
    //     });
    //     if (resp.data.code === "SUCCESS") {
    //       const index = resp.data.data;
    //       const label = index.mproductLedger
    //         ? index.mproductLedger.productLedgerName
    //         : "";
    //       const amount = index.nominalFee ? index.nominalFee : 0;
    //       // const adminFeeId = index.adminfeeId;
    //       const productLedgerId = index.adminfeeId;
    //       const chartOfAccountId = index.mchartOfAccount
    //         ? index.mchartOfAccount.chartOfAccountId
    //         : "";
    //       const chartOfAccountCode = index.mchartOfAccount
    //         ? index.mchartOfAccount.chartOfAccountCode
    //         : "";
    //       const pairType = index.pairType;
    //       const positionMutation = index.mutationPosition;
    //       const labelInputComponent = index.mchartOfAccount
    //         ? index.mchartOfAccount.description
    //         : "";
    //       const pairPositionMutation = index.pairMutationPosition;
    //       const pairChartOfAccountId = index.pairMChartOfAccount
    //         ? index.pairMChartOfAccount.chartOfAccountId
    //         : "";
    //       const pairChartOfAccountCode = index.pairMChartOfAccount
    //         ? index.pairMChartOfAccount.chartOfAccountCode
    //         : "";
    //       this.form.journalRecord.journalRecordDetails.push({
    //         label,
    //         amount,
    //         productLedgerId,
    //         chartOfAccountId,
    //         chartOfAccountCode,
    //         pairType,
    //         positionMutation,
    //         labelInputComponent,
    //         pairPositionMutation,
    //         pairChartOfAccountId,
    //         pairChartOfAccountCode,
    //       });
    //     }
    //   } catch (error) {}
    // },
    routeToPageListSavingAccountDetail() {
      this.$router.push("/saving/saving-account-detail");
    },
    changeOptionsTransactionCode() {
      this.options.kodeTransaksi.map((i) => {
        if (i.value === this.form.transactionCodeId) {
          this.form.descriptionTransactionCode = i.description;
          this.form.transactionCode = i.transactionCode;
          this.form.description = i.value
            ? `${i.description} An ${this.form.cifIdName}`
            : "";
          this.form.journalRecord.transactionCode = i.transactionCode;
          this.getSavingChartOfAccountBySavingId();
          // this.clearMetodePembayaran();
        }
      });
    },
    helperGetTransactionCodeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            name: "",
            page: 0,
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_SAVING_ACCOUNT_DETAIL,
          },
          url: "transaction-code",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            const description = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.kodeTransaksi.push({
              text,
              value,
              description,
              transactionCode,
            });
          });
        });
    },
    async cetakSlipTrx() {
      if (
        this.fetchAppSession("@vue-session/asset-bundle").companyName ===
        "BPRS FORMES"
      ) {
        const payload = {
          accountNumber: this.form.printSavingAccountDetail.accountNumber,
          description: this.form.printSavingAccountDetail.description,
          transactionCode: this.form.printSavingAccountDetail.mtransactionCode
            ?.transactionCode,
          nominal: this.form.printSavingAccountDetail.nominal,
          officeName: this.form.printSavingAccountDetail.moffice?.officeName,
          officeCode: this.form.printSavingAccountDetail.moffice?.officeCode,
          transactionDate: this.form.printSavingAccountDetail.transactionDate,
          documentProofNumber: this.form.printSavingAccountDetail
            .documentProofNumber,
          createdByProfileSurename: this.form.printSavingAccountDetail
            .createdByProfileSurename,
        };
        this.printOutsSavingAccountDetailFormesVersion(payload);
        return;
      }
      const payload = {
        accountNumber: this.form.printSavingAccountDetail.accountNumber,
        description: this.form.printSavingAccountDetail.description,
        transactionCode: this.form.printSavingAccountDetail.mtransactionCode
          ?.transactionCode,
        nominal: this.form.printSavingAccountDetail.nominal,
        officeName: this.form.printSavingAccountDetail.moffice?.officeName,
        officeCode: this.form.printSavingAccountDetail.moffice?.officeCode,
        transactionDate: this.form.printSavingAccountDetail.transactionDate,
      };
      this.printOutsSavingAccountDetail(payload);
    },
    // printBuktiTransaksi() {
    //   const doc = new jsPDF({
    //     orientation: "landscape",
    //     unit: "in",
    //     format: "letter",
    //   });
    //   var pageWidth =
    //     doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    //   var pageHeight =
    //     doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    //   doc
    //     .setFontSize(9)
    //     .text(
    //       ` ${this.form.printSavingAccountDetail.officeCode} - ${this.form.printSavingAccountDetail.officeName}`,
    //       0.55,
    //       2.1
    //     );
    //   doc.setFontSize(9).text(
    //     `Tanggal Transaksi   :    ${
    //       this.form.printSavingAccountDetail.transactionDate
    //         ? new Date(
    //             this.form.printSavingAccountDetail.transactionDate
    //           ).getDate()
    //         : ""
    //     } ${
    //       this.form.printSavingAccountDetail.transactionDate
    //         ? this.property.accessories.day[
    //             new Date(
    //               this.form.printSavingAccountDetail.transactionDate
    //             ).getMonth()
    //           ]
    //         : ""
    //     } ${
    //       this.form.printSavingAccountDetail.transactionDate
    //         ? new Date(
    //             this.form.printSavingAccountDetail.transactionDate
    //           ).getFullYear()
    //         : ""
    //     }  ${
    //       this.form.printSavingAccountDetail.transactionDate
    //         ? new Date(
    //             this.form.printSavingAccountDetail.transactionDate
    //           ).getHours()
    //         : ""
    //     } : ${
    //       this.form.printSavingAccountDetail.transactionDate
    //         ? new Date(
    //             this.form.printSavingAccountDetail.transactionDate
    //           ).getMinutes()
    //         : ""
    //     } : ${
    //       this.form.printSavingAccountDetail.transactionDate
    //         ? new Date(
    //             this.form.printSavingAccountDetail.transactionDate
    //           ).getSeconds()
    //         : ""
    //     }
    //       `,
    //     0.55,
    //     2.4
    //   );
    //   doc
    //     .setFontSize(9)
    //     .text(
    //       `No Rekening            :   ${this.form.printSavingAccountDetail.accountNumber} - ${this.form.printSavingAccountDetail.cifIdName}`,
    //       0.55,
    //       2.7
    //     );
    //   doc
    //     .setFontSize(9)
    //     .text(
    //       `Kode Transaksi        :   ${this.form.printSavingAccountDetail.transactionCode} - ${this.form.printSavingAccountDetail.transactionName}`,
    //       0.55,
    //       3.0
    //     );
    //   doc
    //     .setFontSize(9)
    //     .text(
    //       `Nominal                    :    ${
    //         this.form.printSavingAccountDetail.nominal
    //           ? this.form.printSavingAccountDetail.nominal
    //               .toFixed(2)
    //               .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    //           : 0
    //       }`,
    //       0.55,
    //       3.3
    //     );

    //   doc
    //     .setFontSize(9)
    //     .text(
    //       `Keterangan               :   ${this.form.printSavingAccountDetail.description}`,
    //       0.55,
    //       3.6
    //     );

    //   doc
    //     .setFontSize(9)
    //     .text(
    //       `Print By  :  ${
    //         JSON.parse(
    //           this.decryptBASE64(
    //             sessionStorage.getItem("@vue-session/auth-bundle")
    //           )
    //         ).userName
    //       } ( ${
    //         JSON.parse(
    //           this.decryptBASE64(
    //             sessionStorage.getItem("@vue-session/auth-bundle")
    //           )
    //         ).userCode
    //       } )`,
    //       0.55,
    //       pageHeight - 0.5
    //     );

    //   doc.autoPrint();

    //   const hiddFrame = document.createElement("iframe");
    //   hiddFrame.style.position = "fixed";
    //   // "visibility: hidden" would trigger safety rules in some browsers like safari，
    //   // in which the iframe display in a pretty small size instead of hidden.
    //   // here is some little hack ~
    //   hiddFrame.style.width = "1px";
    //   hiddFrame.style.height = "1px";
    //   hiddFrame.style.opacity = "0.01";
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(
    //     window.navigator.userAgent
    //   );
    //   if (isSafari) {
    //     // fallback in safari
    //     hiddFrame.onload = () => {
    //       try {
    //         hiddFrame.contentWindow.document.execCommand("print", false, null);
    //       } catch (e) {
    //         hiddFrame.contentWindow.print();
    //       }
    //     };
    //   }
    //   hiddFrame.src = doc.output("bloburl");
    //   document.body.appendChild(hiddFrame);
    // },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListSavingAccountDetail();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).savingAccountId;
      this.$emit("getMainId", this.identity.mainId);
      this.form.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.form.valutaDate =
        this.isExisting === true
          ? this.form.valutaDate
          : new Date().toISOString();
    },
    // helperGetDataMetodeAdminBiayaForReference() {
    //   this.options.metodeBiayaAdmin = [
    //     {
    //       text: "Tunai",
    //       value: this.fetchAppSession("@vue-session/config-bundle")
    //         .VUE_APP_OPTION_ADMIN_TUNAI,
    //     },
    //     {
    //       text: "Non Tunai",
    //       value: this.fetchAppSession("@vue-session/config-bundle")
    //         .VUE_APP_OPTION_ADMIN_NON_TUNAI,
    //     },
    //   ];
    // },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.savingId = payload.tsavingAccount?.msaving?.savingId;
        this.form.balanceUsable = payload.tsavingAccount?.balanceUsable;
        this.form.holdNominal = payload.tsavingAccount?.holdNominal;
        this.form.transactionCodeId = payload.mtransactionCode
          ? payload.mtransactionCode.transactionCodeId
          : "";
        this.form.descriptionTransactionCode = payload.mtransactionCode
          ? payload.mtransactionCode.transactionName
          : "";
        this.form.cifClassificationName =
          payload.tsavingAccount?.raccountClassification?.accountClassificationName;
        this.form.cifIdName = payload.tsavingAccount
          ? payload.tsavingAccount.mcif
            ? payload.tsavingAccount.qqName
              ? `${payload.tsavingAccount.mcif.cifIdName} ${payload.tsavingAccount.qqCode} ${payload.tsavingAccount.qqName}`
              : payload.tsavingAccount.mcif.cifIdName
            : ""
          : "";
        this.form.cabangName = payload.tsavingAccount?.moffice?.officeName;
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.accountNumber = payload.accountNumber;
        this.form.transactionDate = payload.transactionDate;
        this.form.valutaDate = payload.valutaDate;
        this.form.mutationPosition = payload.mutationPosition;
        this.form.nominal = payload.nominal;
        this.form.description = payload.description;
        this.form.referenceNumber = payload.referenceNumber;
        this.form.transactionNumber = payload.transactionNumber;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        this.form.savingAccountId = payload.tsavingAccount?.savingAccountId;
        this.form.journalRecord = payload.journalRecord;
        this.form.representative = payload.representative;
        this.form.isRepresentative = payload.representative ? true : false;
        this.form.isKycIncluded = payload.isKycIncluded;
        return;
      }
      this.form.accountNumber = payload.accountNumber;
      this.form.cifIdName = payload.mcif
        ? payload.qqName
          ? `${payload.mcif.cifIdName} ${payload.qqCode} ${payload.qqName}`
          : payload.mcif.cifIdName
        : "";
      this.form.cabangName = payload.moffice?.officeName;
      this.form.cifClassificationName =
        payload.raccountClassification?.accountClassificationName;
      this.form.savingId = payload.msaving.savingId;
      this.form.balanceUsable = payload.balanceUsable;
      this.form.holdNominal = payload.holdNominal;
      this.form.journalRecord.productCode = payload.msaving
        ? payload.msaving.savingCode
        : "";
    },
    submitSavingAccountDetail() {
      this.$emit("submitSavingAccountDetail", this.form);
    },
    async callBackSubmitSavingAccountDetail(payload) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/print-transaction/" +
            payload.data.data.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.form.printSavingAccountDetail = payload.data.data;
          this.property.modal.showModalPrint = true;
          return;
        }
        this.routeToPageListSavingAccountDetail();
      } catch (error) {}
    },
    // clearMetodePembayaran() {
    //   this.form.productLedgerId = "";
    // },
  },
  beforeMount() {},
  mounted() {
    this.validationDomain = window.location.hostname;
    this.checkingMainId();
    this.helperGetTransactionCodeDataForReference();
    this.helperGetDataMetodeAdminBiayaForReference();
  },
  destroyed() {},
};
