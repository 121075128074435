import store from "../store";
import Moment from "moment";
import printJS from "print-js";

export const HandleImplementRole = () => {
  const getUserPayloadData = JSON.parse(
    decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
  );
  store.dispatch("SET_USER_ROLE_ID", getUserPayloadData.roleId);
  store.dispatch(
    "SET_ACTIONS_AUTHORIZED",
    !getUserPayloadData.actions.authorize
      ? false
      : getUserPayloadData.actions.authorize
  );
  store.dispatch(
    "SET_ACTIONS_DELETE",
    !getUserPayloadData.actions.delete
      ? false
      : getUserPayloadData.actions.delete
  );
  store.dispatch("SET_ACTIONS_USER_LIMIT", getUserPayloadData.userLimit);
  store.dispatch(
    "SET_ACTIONS_DETAIL",
    !getUserPayloadData.actions.detail
      ? false
      : getUserPayloadData.actions.detail
  );
  store.dispatch(
    "SET_ACTIONS_INSERT",
    !getUserPayloadData.actions.insert
      ? false
      : getUserPayloadData.actions.insert
  );
  store.dispatch(
    "SET_ACTIONS_PRINT",
    !getUserPayloadData.actions.print ? false : getUserPayloadData.actions.print
  );
  store.dispatch(
    "SET_ACTIONS_UPDATE",
    !getUserPayloadData.actions.update
      ? false
      : getUserPayloadData.actions.update
  );
};

export const endOfMonthDate = (date) => {
  return Moment(date)
    .endOf("month")
    .format();
};

export const dateToYearMonthDay = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};

export const encryptBASE64 = (val) => {
  var str = `${val}`;
  var enc = window.btoa(str);
  return enc;
};

export const decryptBASE64 = (hash) => {
  return window.atob(hash);
};

export const HanldeSetupHeaders = () => {
  const getUserPayloadDataFromSessionStorage = JSON.parse(
    decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
  );
  var allToken = getUserPayloadDataFromSessionStorage.tokenId;
  var searchPercent = allToken.search("%");
  var cekLengthAfterUserId = allToken.length - searchPercent;
  var RealUserId = allToken.slice(0, -cekLengthAfterUserId);
  const ModelHeaders = [
    {
      name: "x-token-id",
      value: getUserPayloadDataFromSessionStorage.tokenId,
    },
    {
      name: "x-role-id",
      value: getUserPayloadDataFromSessionStorage.roleId,
    },
    {
      name: "x-user-id",
      value: RealUserId,
    },
    {
      name: "x-branch-code",
      value: getUserPayloadDataFromSessionStorage.officeCode,
    },
    {
      name: "x-ip",
      value: "1",
    },
  ];

  if (
    `${process.env.VUE_APP_BASE_URL_GATEWAY}`.transactionEndpoint ===
    `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/`
  ) {
    // eslint-disable-next-line no-undef
    axios.defaults.headers.common[ModelHeaders[0].name] = ModelHeaders[0].value;
  } else {
    ModelHeaders.map((index) => {
      // eslint-disable-next-line no-undef
      axios.defaults.headers.common[index.name] = index.value;
    });
  }
};
const simpleWait = (duration) => {
  return new Promise(async (resolve, reject) => {
    setTimeout(() => {
      resolve("");
    }, duration);
  });
};

export const HandleResponseGuard = () => {
  const excludeLoadingFrom = [
    `${process.env.VUE_APP_BASE_URL_GATEWAY}/process/end-of-day-process/v3`,
    `${process.env.VUE_APP_BASE_URL_GATEWAY}/process/close-end-of-month-centralize`,
  ];
  // eslint-disable-next-line no-undef
  axios.interceptors.request.use(async (req) => {
    if (req.method !== "get" && !excludeLoadingFrom.includes(req.url)) {
      store.dispatch("SET_IS_CENTRAL_LOADING", true);
    }
    return req;
  });
  // eslint-disable-next-line no-undef
  axios.interceptors.response.use(
    async (response) => {
      await simpleWait(2000);
      store.dispatch("SET_IS_CENTRAL_LOADING", false);
      return response;
    },
    async (err) => {
      let error = err;
      if (error.response.data) {
        err.response.statusText = `${error.response.data.message} <br/><br/> <b>RequestId</b> : ${error.response?.data?.requestId}`;
        error.response.data.message = `${error.response.data.message} <br/><br/> <b>RequestId</b> : ${error.response?.data?.requestId}`;
        if (error.response.data.errorFields) {
          error.response.data.errorFields = {
            ...error.response.data.errorFields,
            requestId: `<br/><br/> <b>RequestId</b> : ${error.response?.data?.requestId}`,
          };
        }
      }
      await simpleWait(2000);
      store.dispatch("SET_IS_CENTRAL_LOADING", false);
      if (error.response.status === 401) {
        store.dispatch("SET_IS_TOKEN_EXPIRED", true);
      }
      return Promise.reject(error);
    }
  );
};

export const GetBalanceCashTeller = async () => {
  const getUserDataFromLogin = JSON.parse(
    decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
  );
  if (getUserDataFromLogin.roleId === "27") {
    try {
      const resp = await store.dispatch({
        type: "GET_LIST_DATA",
        reqUrl: "balance-cash/current-balance-nominal",
        endPoint: "transaction",
      });
      if (resp.data.code === "SUCCESS") {
        store.dispatch("SET_BALANCE_CASH_NOMINAL_ROLE_TELLER", resp.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const HandleSetupConsole = () => {
  // please dont change this code
  if (process.env.NODE_ENV === "production") {
    console.log = function() {};
    console.warn = function() {};
    console.error = function() {};
  }
};

export const NotificationErrorFieldToText = (value) => {
  const entries = Object.values(value);

  return entries.toString();
};

export const ObjectValidator = (item) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

export const mergingObject = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (ObjectValidator(target) && ObjectValidator(source)) {
    for (const key in source) {
      if (ObjectValidator(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergingObject(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return mergingObject(target, ...sources);
};

export const printOutWindow = (blob) => {
  const file = new Blob([blob], { type: "application/pdf" });
  printJS({
    printable: URL.createObjectURL(file),
    type: "pdf",
    showModal: false,
    documentTitle: "Document",
  });
};
