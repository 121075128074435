import Moment from "moment";

export default {
  name: "ProcessPpap",
  data() {
    return {
      property: {
        listElement: {
          processPpap: {
            currentPage: 1,
            rows: 0,
            perPage: 10,
            message: "",
            downloading: false,
          },
        },
        animation: {
          processPpap: {
            isLoading: false,
          },
          onGenerateJournal: false,
        },
        filterDto: {
          period: "",
          officeCodes: "",
        },
      },
      options: {
        officeIdList: [],
        officeListDataSoure: [],
      },
      table: {
        data: {
          processPpap: [],
        },
      },
    };
  },
  computed: {
    filteredDate() {
      if (!this.property.filterDto.period) {
        return "-";
      }
      const endOfMonth = this.endOfMonthDate(this.property.filterDto.period);
      return this.dateToMonthText(endOfMonth);
    },
  },
  methods: {
    periodConvertMonth(val) {
      Moment.locale("ID");
      return `${Moment(val).format("YYYY")}-${Moment(val).format("MM")}`;
    },
    async getReportPpap() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      var ungroupingOffice = [];
      if (this.property.filterDto.officeCodes.length > 0) {
        this.property.filterDto.officeCodes.map((index) => {
          ungroupingOffice.push(index.value);
        });
      }
      this.property.animation.processPpap.isLoading = true;
      this.property.listElement.processPpap.downloading = true;
      this.property.listElement.processPpap.currentPage = 1;
      this.property.listElement.processPpap.rows = 0;
      this.table.data.processPpap = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "ppap",
          payload: {
            period: this.periodConvertMonth(this.property.filterDto.period),
            officeCodes: ungroupingOffice,
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.table.data.processPpap = resp.data.data.content;
            this.property.listElement.processPpap.rows =
              resp.data.data.totalElements;
          }, 1000);
        } else {
          this.table.data.processPpap = [];
          this.property.listElement.processPpap.rows = 0;
          this.property.listElement.processPpap.message = resp.data.message;
        }
      } catch (error) {
        console.log(error.response);
        this.table.data.processPpap = [];
        this.property.listElement.processPpap.rows = 0;
        this.property.listElement.processPpap.currentPage = 1;
        this.property.listElement.processPpap.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        setTimeout(() => {
          this.property.animation.processPpap.isLoading = false;
          this.property.listElement.processPpap.downloading = false;
        }, 1000);
      }
    },
    async onGenerateJournal() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Bentuk Jurnal ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.property.animation.onGenerateJournal = true;
      var ungroupingOffice = [];
      if (this.property.filterDto.officeCodes.length > 0) {
        this.property.filterDto.officeCodes.map((index) => {
          ungroupingOffice.push(index.value);
        });
      }
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "loan",
          reqUrl: "ppap/generate-journal",
          payload: {
            period: this.periodConvertMonth(this.property.filterDto.period),
            officeCodes: ungroupingOffice,
          },
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.property.animation.onGenerateJournal = false;
      }
    },
    async changePaginationProcessPpap(event) {
      this.table.data.processPpap = [];
      this.property.listElement.processPpap.downloading = true;
      const timeout = 500;
      var ungroupingOffice = [];
      if (this.property.filterDto.officeCodes.length > 0) {
        this.property.filterDto.officeCodes.map((index) => {
          ungroupingOffice.push(index.value);
        });
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "ppap",
          payload: {
            period: this.periodConvertMonth(this.property.filterDto.period),
            officeCodes: ungroupingOffice,
            page: event - 1,
            size: this.property.listElement.processPpap.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.processPpap = resp.data.data.content;
            this.property.listElement.processPpap.rows =
              resp.data.data.totalElements;
          } else {
            this.table.data.processPpap = [];
            this.property.listElement.processPpap.rows = 0;
            this.property.listElement.processPpap.message = resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.table.data.processPpap = [];
          this.property.listElement.processPpap.rows = 0;
          this.property.listElement.processPpap.currentPage = 1;
          this.property.listElement.processPpap.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.processPpap.downloading = false;
        }, timeout);
      }
    },
    addOfficeList(event) {
      var unselectedOffice = this.options.officeIdList.filter(
        (index) => index.value !== event.value
      );
      this.options.officeIdList = unselectedOffice;
    },
    deleteOfficeList(event) {
      console.log(event);
      this.options.officeIdList.push(event);
    },
    filterOfficeIdList(text) {
      console.log("triggered");
      this.options.officeIdList = this.options.officeListDataSoure.filter(
        (index) => {
          return (
            index.text
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        }
      );
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.officeIdList = [
            { text: "-- Pilih Semua --", value: "" },
          ];
          this.options.officeListDataSoure = [
            { text: "-- Pilih Semua --", value: "" },
          ];
          resp.data.data.content.map((index) => {
            const value = index.officeId;
            const text = index.officeName;
            this.options.officeIdList.push({
              value,
              text,
            });
            this.options.officeListDataSoure.push({
              value,
              text,
            });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    setDefaultVal() {
      this.property.filterDto.period = new Date();
    },
  },
  mounted() {
    this.setDefaultVal();
    this.getReferenceOffice();
  },
};
