import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "FormMLoanCollectability",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isExisting: false,
    formMessage: {
      type: Object,
      default: {},
      required: false,
    },
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      form: {
        weight: 0,
        collectabilityCode: "",
        collectabilityIncomeTypeId: "",
        daysInArrearsBeforeMax: 0,
        daysInArrearsBeforeMin: 0,
        daysInArrearsAfterMin: 0,
        daysInArrearsAfterMax: 0,
        loanCollectabilityStatusId: "",
        collectabilityCodeId: "",
        profitShareEstimate: 0,
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        jenisPendapatan: [],
        kodeKolektibilitas: [],
        collectabilityCodeId: [],
      },
    };
  },
  methods: {
    routeToPageListLoanCollectability() {
      this.$router.push("/loan/loan-collectability");
    },
    getReferenceLoanCollectabilityIncomeType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-income-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityIncomeTypeId;
            const text = i.collectabilityIncomeTypeName;
            this.options.jenisPendapatan.push({ text, value });
          });
        });
    },
    getReferenceLoanCollectabilityStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-status",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityStatusId;
            const text = i.collectabilityStatusName;
            this.options.kodeKolektibilitas.push({ text, value });
          });
        });
    },
    getReferenceLoanCollectabilityCode() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-code",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityCodeId;
            const text = i.collectabilityCodeName;
            this.options.collectabilityCodeId.push({ text, value });
          });
        });
    },
    appendDefaultOptionSelect() {
      this.options.jenisPendapatan.push({ value: "", text: "-- Pilih --" });
      this.options.kodeKolektibilitas.push({ value: "", text: "-- Pilih --" });
      this.options.collectabilityCodeId.push({
        value: "",
        text: "-- Pilih --",
      });
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.daysInArrearsBeforeMax = payload.daysInArrearsBeforeMax;
        this.form.daysInArrearsAfterMin = payload.daysInArrearsAfterMin;
        this.form.daysInArrearsAfterMax = payload.daysInArrearsAfterMax;
        this.form.daysInArrearsBeforeMin = payload.daysInArrearsBeforeMin;
        this.form.weight = payload.weight;
        this.form.loanCollectabilityStatusId = payload.rloanCollectabilityStatus
          ? payload.rloanCollectabilityStatus.collectabilityStatusId
          : "";
        this.form.collectabilityCodeId = payload.rloanCollectabilityCode
          ? payload.rloanCollectabilityCode.collectabilityCodeId
          : "";
        this.form.collectabilityIncomeTypeId = payload.rloanCollectabilityIncomeType
          ? payload.rloanCollectabilityIncomeType.collectabilityIncomeTypeId
          : "";
        this.form.profitShareEstimate = payload.profitShareEstimate;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitLoanCollectability() {
      this.$emit("submitLoanCollectability", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendDefaultOptionSelect();
    this.getReferenceLoanCollectabilityStatus();
    this.getReferenceLoanCollectabilityIncomeType();
    this.getReferenceLoanCollectabilityCode();
  },
};
