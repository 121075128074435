import FormTSavingAccountDetail from "../form";

export default {
  name: "ExistingTSavingAccountDetail",
  components: {
    FormTSavingAccountDetail,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.helperGetDataSavingAccountDetailById();
    },
    async helperGetDataSavingAccountDetailById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTSavingAccountDetailRef.routeToPageListSavingAccountDetail();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving-account-detail/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTSavingAccountDetailRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTSavingAccountDetailRef.routeToPageListSavingAccountDetail(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTSavingAccountDetailRef.routeToPageListSavingAccountDetail(),
          });
        }
      }
    },
    async editDataSavingAccountDetail(payload) {
      const data = {
        transactionCodeId: payload.transactionCodeId,
        documentProofNumber: payload.documentProofNumber,
        transactionDate: payload.transactionDate,
        valutaDate: payload.valutaDate,
        nominal: payload.nominal,
        savingAccountId: payload.savingAccountId,
        description: payload.description,
        accountNumber: payload.accountNumber,
        transactionNumber: payload.transactionNumber,
        journalRecord: payload.journalRecord,
        representative: payload.representative,
        isKycIncluded: payload.isKycIncluded,
      };
      console.log(JSON.stringify(data));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl: "saving-account-detail/" + this.identity.mainId,
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              console.log(error.response.data.errorFields);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.NotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            } finally {
              setTimeout(() => {
                this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
