import Vue from "vue";
import Vuex from "vuex";
import sidebar from "./modules/sidebar";
import application from "./modules/application";
import MDeposit from "./modules/m-deposit";
import MLoan from "./modules/m-loan";
import TSavingAccount from "./modules/t-saving-account";
import MUserRole from "./modules/m-user-role";
import MUser from "./modules/m-user";
import MCif from "./modules/m-cif/";
import GlobalReference from "./modules/reference";
import TLoanAccount from "./modules/t-loan-account";
import Overbook from "./modules/overbook";
import MInterbankLiability from "./modules/m-interbank-liability";
import MInterbankAssets from "./modules/m-interbank-assets";
import TTaskInterbankAsset from "./modules/t-task-interbank-asset";
import MUSerV2 from "./modules/m-user-v-2";
import MGeneral from "./modules/m-general";
import TMortgageAccount from "./modules/t-mortgage-account";
import MSaving from "./modules/m-saving";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    sidebar,
    application,
    MSaving,
    MUserRole,
    GlobalReference,
    MUser,
    MCif,
    MDeposit,
    TSavingAccount,
    MLoan,
    TLoanAccount,
    Overbook,
    MInterbankLiability,
    MInterbankAssets,
    TTaskInterbankAsset,
    MUSerV2,
    MGeneral,
    TMortgageAccount,
  },
});
