export default {
  name: "FormWriteOff",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          writeOff: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        response: null,
        request: {
          loanAccountId: "",
          chartOfAccountId: "",
          documentProofNumber: "",
          description: "",
          writeOffReason: "",
          //user-activity
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
        },
      },
      options: {
        SBB: [],
      },
    };
  },
  methods: {
    routeToPageListLoanWriteOff() {
      this.$router.push("/loan/write-off");
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.response = payload;
        this.dataForm.request.loanAccountId = payload.loanAccountId;
        this.dataForm.request.chartOfAccountId = payload.chartOfAccountId;
        this.dataForm.request.documentProofNumber = payload.documentProofNumber;
        this.dataForm.request.description = payload.description;
        this.dataForm.request.writeOffReason = payload.writeOffReason;
        this.dataForm.request.createdByUserCode = payload.createdByUserCode;
        this.dataForm.request.createdByUserName = payload.createdByUserName;
        this.dataForm.request.createdDate = payload.createdDate;
        this.dataForm.request.updatedDate = payload.updatedDate;
        this.dataForm.request.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.request.updatedByUserName = payload.updatedByUserName;
        this.dataForm.request.authorizedDate = payload.authorizedDate;
        this.dataForm.request.authorizedByUserCode =
          payload.authorizedByUserCode;
        this.dataForm.request.authorizedByUserName =
          payload.authorizedByUserName;
        return;
      }
      this.dataForm.response = {
        ...payload,
        transactionDate: this.fetchAppSession("@vue-session/application")
          .system_date,
        totalNominalWriteOff: payload.principalRemain + payload.marginRemain,
      };
      this.getTransactionCode(payload);
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListLoanWriteOff();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).loanAccountId;
      this.$emit("getMainId", this.identity.mainId);
    },
    submitLoanWriteOff() {
      this.$emit("submitLoanWriteOff", this.dataForm);
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            this.options.SBB.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getTransactionCode(payload) {
      if (!this.isExisting) {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl:
              "transaction-code/transaction-code/" +
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_WRITE_OFF,
          });
          if (resp.data.code === "SUCCESS") {
            console.log(resp);
            this.dataForm.response = {
              ...this.dataForm.response,
              transactionName: resp.data.data.transactionName,
              transactionCode: resp.data.data.transactionCode,
            };
            this.dataForm.request.description = `${this.dataForm.response.transactionName} - ${payload.loanAccountNumber} an ${payload.cifIdName}`;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceChartOfAccount();
  },
};
