export default {
  name: "NominatifWriteOff",
  data() {
    return {
      property: {
        animation: {
          isDownloadingFile: false,
        },
        listElement: {
          nominatif: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      table: {
        data: {
          nominatif: [],
        },
      },
    };
  },
  mounted() {
    this.getNominatifWriteOff();
  },
  methods: {
    async onDownloadFile() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "write-off/nominative/download",
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Nominatif_Hapus_Buku.${"xlsx"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async getNominatifWriteOff() {
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "write-off/nominative",
          payload: {
            page: 0,
            size: this.property.listElement.nominatif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.nominatif = resp.data.data.content;
            this.property.listElement.nominatif.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetNominatif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetNominatif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.nominatif.downloading = false;
        }, timeout);
      }
    },
    failedGetNominatif(resp) {
      this.property.listElement.nominatif.rows = 0;
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.message = resp.data.message;
    },
    handleErrorGetNominatif(error) {
      console.log(error.response);
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.rows = 0;
      this.property.listElement.nominatif.currentPage = 1;
      this.property.listElement.nominatif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationNominatifWriteOff(event) {
      this.table.data.nominatif = [];
      this.property.listElement.nominatif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "write-off/nominative",
          payload: {
            page: event - 1,
            size: this.property.listElement.nominatif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.nominatif = resp.data.data.content;
            this.property.listElement.nominatif.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetNominatif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetNominatif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.nominatif.downloading = false;
        }, timeout);
      }
    },
  },
};
