export default {
  name: "AddMMortgageSlthe",
  data() {
    return {
      property: {
        animation: {
          addMortgageSlthe: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        setupDate: "",
        marketPrice: "",
        slthePrice: "",
        description: "",
        mortgageCaratId: "",
      },
      options: {
        karat: [],
      },
    };
  },
  methods: {
    appendDefaultInput() {
      const defaultOption = { value: "", text: "-- Pilih --" };
      this.options.karat.push(defaultOption);
    },
    routeToPageListMortgageSlthe() {
      this.$router.push("list");
    },
    appendDefaultInputNewDate() {
      this.dataForm.setupDate = new Date().toISOString();
    },
    async addMortgageSlthe() {
      const payload = {
        setupDate: this.dataForm.setupDate,
        marketPrice: this.dataForm.marketPrice,
        slthePrice: this.dataForm.slthePrice,
        description: this.dataForm.description,
        // mortgageCaratId: this.dataForm.mortgageCaratId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        if (window.confirm("Tambah Data Harga SLTHE ?")) {
          this.property.animation.addMortgageSlthe.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "POST_DATA",
              endPoint: "loan",
              reqUrl: "mortgage-metal-price",
              payload: payload,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                const toastBuefy = this.$buefy.toast.open(
                  this.$NotificationUtils.success
                );
                toastBuefy.$on("close", () => {
                  this.routeToPageListMortgageSlthe();
                });
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.addMortgageSlthe.isLoading = false;
            }, 1000);
          }
        }
      }
    },
    async getReferencemortgageCarat() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/mortgage-carat",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.mortgageCaratName;
            const value = i.mortgageCaratId;
            this.options.karat.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.appendDefaultInput();
    this.getReferencemortgageCarat();
    this.appendDefaultInputNewDate();
  },
};
