export default {
  name: "Sipesat",
  data() {
    return {
      property: {
        listElement: {
          rows: 0,
          perPage: 10,
          currentPage: 1,
          downloading: false,
          message: "",
        },
        filterDto: {
          name: "",
          idCardNumber: "",
          officeCode: "",
          dateFrom: "",
          dateTo: "",
        },
        animation: {
          isDownloadingFile: false,
        },
      },
      options: {
        office: [],
      },
      table: {
        data: {
          sipesat: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        name: this.property.filterDto.name,
        idCardNumber: this.property.filterDto.idCardNumber,
        officeCode: this.property.filterDto.officeCode,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/sipesat/excel",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Sipesat.${"xlsx"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((data) => {
            var value = data.officeCode;
            var text = `${data.officeCode} - ${data.officeName}`;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getTableSipesat() {
      this.table.data.sipesat = [];
      this.property.listElement.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/sipesat",
          payload: {
            name: this.property.filterDto.name,
            idCardNumber: this.property.filterDto.idCardNumber,
            officeCode: this.property.filterDto.officeCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sipesat = resp.data.data.content;
            this.property.listElement.rows = resp.data.data.totalElements;
            this.property.listElement.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetSipesat(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetSipesat(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.downloading = false;
        }, timeout);
      }
    },
    async changePaginationSipesat(event) {
      this.table.data.sipesat = [];
      this.property.listElement.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/sipesat",
          payload: {
            name: this.property.filterDto.name,
            idCardNumber: this.property.filterDto.idCardNumber,
            officeCode: this.property.filterDto.officeCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sipesat = resp.data.data.content;
            this.property.listElement.rows = resp.data.data.totalElements;
            this.property.listElement.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetSipesat(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetSipesat(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.downloading = false;
        }, timeout);
      }
    },
    failedGetSipesat(resp) {
      this.property.listElement.rows = 0;
      this.table.data.sipesat = [];
      this.property.listElement.message = resp.data.message;
    },
    handleErrorGetSipesat(error) {
      console.log(error.response);
      this.table.data.sipesat = [];
      this.property.listElement.rows = 0;
      this.property.listElement.currentPage = 1;
      this.property.listElement.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    helperGetSystemDate() {
      this.property.filterDto.dateFrom = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.property.filterDto.dateTo = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.helperGetSystemDate();
    this.getReferenceOffice();
  },
};
