export default {
  name: "MEdc",
  data() {
    return {
      dataForm: {
        add: {
          edcCode: "",
          edcName: "",
        },
        edit: {
          edcId: "",
          edcCode: "",
          edcName: "",
        },
        detail: {
          edcId: "",
          edcCode: "",
          edcName: "",
          createdBy: "",
          createdDate: "",
          updatedBy: "",
          updatedDate: "",
        },
      },
      property: {
        modal: {
          showModalAddEdc: false,
          showModalEditEdc: false,
          label: "",
        },
        animation: {
          addEdc: {
            isLoading: false,
          },
          editEdc: {
            isLoading: false,
          },
        },
        listElement: {
          edc: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          edcCode: "",
          edcName: "",
        },
      },
      table: {
        header: {
          edc: [],
        },
        data: {
          edc: [],
        },
      },
    };
  },
  methods: {
    async searchByEdcCode(event) {
      this.table.data.edc = [];
      this.property.listElement.edc.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "edc/",
          payload: {
            edcCode: event,
            edcName: this.property.filterDto.edcName,
            page: 0,
            size: this.property.listElement.edc.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.edc = resp.data.data.content;
            this.property.listElement.edc.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.edc.rows = resp.data.data.totalElements;
          } else {
            this.failedGetEdc(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetSavingAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.edc.downloading = false;
        }, timeout + 500);
      }
    },
    async authorizationEdc(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "edc/authorized/" + props.row.edcId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.edc.currentPage = 1;
                  this.property.filterDto.edcCode = "";
                  this.property.filterDto.edcName = "";
                  this.property.listElement.edc.rows = 0;
                  this.getEdc();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationEdc(event) {
      this.table.data.edc = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "edc",
          payload: {
            edcCode: this.session.edcCode,
            edcName: this.session.edcName,
            page: event - 1,
            size: this.property.listElement.edc.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.edc.rows = resp.data.data.totalElements;
          } else {
            this.failedGetCif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetEdc(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout);
      }
    },
    failedGetEdc(resp) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.message = resp.data.message;
    },
    routeToPageListEdc() {
      this.$router.push("list");
    },
    async getEdc() {
      this.table.data.edc = [];
      this.property.listElement.edc.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "edc",
          payload: {
            edcCode: "",
            edcName: "",
            page: 0,
            size: this.property.listElement.edc.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.edc = resp.data.data.content;
            this.property.listElement.edc.rows = resp.data.data.totalElements;
          } else {
            this.failedGetEdc(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetEdc(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.edc.downloading = false;
        }, timeout);
      }
    },
    routeToPageAddDayOff() {
      this.$router.push("/parameter/edc/new");
    },
    routeToPageEditDayOff(props) {
      this.$router.push(
        `/parameter/edc/${this.encryptBASE64(`${props.row.edcId}`)}`
      );
    },
    async deleteDataEdc(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "edc/" + props.row.edcId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.edc.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.listElement.edc.rows = 0;
                  this.getEdc();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleErrorGetEdc(error) {
      console.log(error.response);
      this.table.data.edc = [];
      this.property.listElement.edc.rows = 0;
      this.property.listElement.edc.currentPage = 1;
      this.property.listElement.edc.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationEdc(event) {
      this.table.data.edc = [];
      this.property.listElement.edc.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            idCardNumber: "",
            cifIdName: this.property.filterDto.cifIdName,
            page: event - 1,
            size: this.property.listElement.edc.perPage,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.edc = resp.data.data.content;
            this.property.listElement.edc.rows = resp.data.data.totalElements;
          } else {
            this.failedGetEdc(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetEdc(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.edc.downloading = false;
        }, timeout);
      }
    },
    failedGetEdc(resp) {
      this.table.data.edc = [];
      this.property.listElement.edc.rows = 0;
      this.property.listElement.edc.message = resp.data.message;
    },
  },
  mounted() {
    this.getEdc();
  },
};
