export default {
  name: "ListTDepositAccountDetailFundAllocated",
  data() {
    return {
      dataForm: {
        checkingDepositAccount: {
          accountNumber: "",
        },
      },
      session: {
        checkingDepositAccount: {
          accountNumber: "",
        },
      },
      property: {
        modal: {
          showModalCheckingDepositAccount: false,
          showModalResultListDepositAccount: false,
        },
        animation: {
          checkingDepositAccount: {
            isLoading: false,
          },
        },
        listElement: {
          depositFundAllocated: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          depositAccount: {
            downloading: false,
          },
        },
        filterDto: {
          accountNumber: "",
          dateFrom: "",
          dateTo: "",
        },
      },
      table: {
        header: {
          depositFundAllocated: [],
          depositAccount: [],
        },
        data: {
          depositFundAllocated: [],
          depositAccount: [],
        },
      },
    };
  },
  methods: {
    async findDepositFundAllocated() {
      this.table.data.depositFundAllocated = [];
      this.property.listElement.depositFundAllocated.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            transactionCodeId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.depositFundAllocated.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.depositFundAllocated.rows =
              resp.data.data.totalElements;
            this.table.data.depositFundAllocated = resp.data.data.content;
            this.property.listElement.depositFundAllocated.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetDataDepositFundAllocated(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositAccountDetail(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositFundAllocated.downloading = false;
        }, timeout + 500);
      }
    },
    async handleCheckingAvailabilityDepositAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingDepositAccount.accountNumber = this.dataForm.checkingDepositAccount.accountNumber;
        this.dataForm.checkingDepositAccount.accountNumber = "";

        try {
          this.property.animation.checkingDepositAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "deposit-account/account-number/" +
              this.session.checkingDepositAccount.accountNumber,
          });
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositAccount = [resp.data.data];
            this.property.modal.showModalCheckingDepositAccount = false;
            this.property.modal.showModalResultListDepositAccount = true;
          } else if (resp.data.code === "DEPOSIT_ACCOUNT_NUMBER_NOT_FOUND") {
            this.dataForm.checkingDepositAccount.accountNumber = this.session.checkingDepositAccount.accountNumber;
            this.session.checkingDepositAccount.accountNumber = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.dataForm.checkingDepositAccount.accountNumber = this.session.checkingDepositAccount.accountNumber;
            this.session.checkingDepositAccount.accountNumber = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message} !`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          this.dataForm.checkingDepositAccount.accountNumber = this.session.checkingDepositAccount.accountNumber;
          this.session.checkingDepositAccount.accountNumber = "";
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          this.property.animation.checkingDepositAccount.isLoading = false;
        }
      }
    },
    handleRouteToPageAddDepositAccountDetail(props) {
      this.$router.push(
        `/deposit/fund-allocated/new?refId=${this.encryptBASE64(
          JSON.stringify({ depositAccountId: props.row.depositAccountId })
        )}`
      );
    },
    handleRouteToPageDetailDepositAccountDetail(props) {
      this.$router.push(
        `/deposit/fund-allocated/${this.encryptBASE64(
          `${props.row.depositAccountDetailId}`
        )}`
      );
    },
    async handleAuthorizationDepositAccountDetail(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account-detail/fund-allocated/authorized/" +
                  props.row.depositAccountDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositFundAllocated.currentPage = 1;
                  this.property.filterDto.accountNumber = "";
                  this.property.listElement.depositFundAllocated.rows = 0;
                  this.helperGetDataTableDepositAccountDetail();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleCatchErrorDepositFundAllocated(error) {
      console.log(error.response);
      this.table.data.depositFundAllocated = [];
      this.property.listElement.depositFundAllocated.rows = 0;
      this.property.listElement.depositFundAllocated.currentPage = 1;
      this.property.listElement.depositFundAllocated.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangePaginationDepositFundAllocated(event) {
      this.table.data.depositFundAllocated = [];
      this.property.listElement.depositFundAllocated.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            transactionCodeId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.depositFundAllocated.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositFundAllocated = resp.data.data.content;
            this.property.listElement.depositFundAllocated.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataDepositFundAllocated(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositFundAllocated(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositFundAllocated.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableDepositAccountDetail() {
      this.table.data.depositFundAllocated = [];
      this.property.listElement.depositFundAllocated.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/simple-list",
          payload: {
            accountNumber: "",
            transactionCodeId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositFundAllocated = resp.data.data.content;
            this.property.listElement.depositFundAllocated.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataDepositFundAllocated(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositFundAllocated(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositFundAllocated.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataDepositFundAllocated(resp) {
      this.table.data.depositFundAllocated = [];
      this.property.listElement.depositFundAllocated.rows = 0;
      this.property.listElement.depositFundAllocated.message =
        resp.data.message;
    },
    handleCloseModalCheckingDepositAccount() {
      this.property.modal.showModalCheckingDepositAccount = false;
      this.handleClearModalCheckingDepositAccount();
    },
    handleClearModalCheckingDepositAccount() {
      this.dataForm.checkingDepositAccount.accountNumber = "";
    },
    handleClearModalResultCheckingDepositAccount() {
      this.table.data.depositAccount = [];
    },
  },
  mounted() {
    this.helperGetDataTableDepositAccountDetail();
  },
};
