export default {
  name: "NominatifComparing",
  data() {
    return {
      dataForm: {
        saving: {
          totalRekSaving: 0,
          totalNominalNeracaSaving: 0,
          totalNominalNominatifSaving: 0,
          totalSelisihSaving: 0,
        },
        deposito: {
          totalRekDeposit: 0,
          totalNominalNeracaDeposit: 0,
          totalNominalNominatifDeposit: 0,
          totalSelisihDeposit: 0,
        },
        loan: {
          totalRekLoan: 0,
          totalNominalNeracaLoan: 0,
          totalNominalNominatifLoan: 0,
          totalSelisihLoan: 0,
        },
        interbankAssets: {
          totalRekinterbankAssets: 0,
          totalNominalNeracainterbankAssets: 0,
          totalNominalNominatifinterbankAssets: 0,
          totalSelisihinterbankAssets: 0,
        },
        titipan: {
          totalRekTitipan: 0,
          totalNominalNeracaTitipan: 0,
          totalNominalNominatifTitipan: 0,
          totalSelisihTitipan: 0,
        },
      },
      property: {
        filterDto: {
          officeId: "",
        },
        listElement: {
          downloading: false,
          message: "",
        },
      },
      options: {
        office: [],
      },
      table: {
        data: {
          nominatifComparing: {
            deposito: [],
            loan: [],
            saving: [],
            interbankAssets: [],
            titipan: [],
          },
        },
      },
    };
  },
  methods: {
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeId;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getTableNominatifComparing() {
      this.table.data.nominatifComparing.deposito = [];
      this.table.data.nominatifComparing.loan = [];
      this.table.data.nominatifComparing.saving = [];
      this.table.data.nominatifComparing.interbankAssets = [];
      this.table.data.nominatifComparing.titipan = [];
      this.property.listElement.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/nominatif-comparing",
          payload: {
            officeId: this.property.filterDto.officeId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          /* get data tabungan */
          this.table.data.nominatifComparing.saving = resp.data.data.saving;
          this.dataForm.saving.totalRekSaving = resp.data.data.totalRekSaving;
          this.dataForm.saving.totalNominalNeracaSaving =
            resp.data.data.totalNominalNeracaSaving;
          this.dataForm.saving.totalNominalNominatifSaving =
            resp.data.data.totalNominalNominatifSaving;
          this.dataForm.saving.totalSelisihSaving =
            resp.data.data.totalSelisihSaving;

          /* get data deposito */
          this.table.data.nominatifComparing.deposito = resp.data.data.deposit;
          this.dataForm.deposito.totalRekDeposit =
            resp.data.data.totalRekDeposit;
          this.dataForm.deposito.totalNominalNeracaDeposit =
            resp.data.data.totalNominalNeracaDeposit;
          this.dataForm.deposito.totalNominalNominatifDeposit =
            resp.data.data.totalNominalNominatifDeposit;
          this.dataForm.deposito.totalSelisihDeposit =
            resp.data.data.totalSelisihDeposit;

          /* get data loan */
          this.table.data.nominatifComparing.loan = resp.data.data.loan;
          this.dataForm.loan.totalRekLoan = resp.data.data.totalRekLoan;
          this.dataForm.loan.totalNominalNeracaLoan =
            resp.data.data.totalNominalNeracaLoan;
          this.dataForm.loan.totalNominalNominatifLoan =
            resp.data.data.totalNominalNominatifLoan;
          this.dataForm.loan.totalSelisihLoan = resp.data.data.totalSelisihLoan;

          /* get data interbank assets */
          this.table.data.nominatifComparing.interbankAssets =
            resp.data.data.aba;
          this.dataForm.interbankAssets.totalRekInterbankAssets =
            resp.data.data.totalRekAba;
          this.dataForm.interbankAssets.totalNominalNeracaInterbankAssets =
            resp.data.data.totalNominalNeracaAba;
          this.dataForm.interbankAssets.totalNominalNominatifInterbankAssets =
            resp.data.data.totalNominalNominatifAba;
          this.dataForm.interbankAssets.totalSelisihInterbankAssets =
            resp.data.data.totalSelisihAba;

          /* get data titipan */
          this.table.data.nominatifComparing.titipan = resp.data.data.titipan;
          this.dataForm.titipan.totalRekTitipan =
            resp.data.data.totalRekTitipan;
          this.dataForm.titipan.totalNominalNeracaTitipan =
            resp.data.data.totalNominalNeracaTitipan;
          this.dataForm.titipan.totalNominalNominatifTitipan =
            resp.data.data.totalNominalNominatifTitipan;
          this.dataForm.titipan.totalSelisihTitipan =
            resp.data.data.totalSelisihTitipan;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.listElement.downloading = false;
      }
    },
  },
  mounted() {
    this.getReferenceOffice();
    this.getTableNominatifComparing();
  },
};
