export default {
  state: {
    identifier: {
      mortgageAccountId: "",
      mortgageAccountData: null,
    },
    options: {
      mortgageCollateral: {
        preciousMetal: {
          mortgageAccount: [],
        },
      },
      mortgageDocumentChecklist: {
        mortgageAccount: [],
      },
    },
  },
  mutations: {
    SET_IDENTIFIER_MORTGAGE_ACCOUNT_ID(state, payload) {
      state.identifier.mortgageAccountId = payload;
    },
    SET_IDENTIFIER_MORTGAGE_ACCOUNT_DATA(state, payload) {
      state.identifier.mortgageAccountData = payload;
    },
    SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_COLLATERAL_PRECIOUS_METAL(
      state,
      payload
    ) {
      state.options.mortgageCollateral.preciousMetal.mortgageAccount = payload;
    },
    SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_DOCUMENT_CHECKLIST(state, payload) {
      state.options.mortgageDocumentChecklist.mortgageAccount = payload;
    },
    CLEAR_OPTIONS_MORTGAGE_ACCOUNT(state, payload) {
      state.identifier.mortgageAccountId = "";
      state.identifier.mortgageAccountData = null;
      state.options.mortgageCollateral.preciousMetal.mortgageAccount;
      state.options.mortgageDocumentChecklist.mortgageAccount = [];
    },
  },
  actions: {
    CLEAR_OPTIONS_MORTGAGE_ACCOUNT({ commit }, payload) {
      commit("CLEAR_OPTIONS_MORTGAGE_ACCOUNT");
    },
    SET_IDENTIFIER_MORTGAGE_ACCOUNT_ID({ commit }, payload) {
      commit("SET_IDENTIFIER_MORTGAGE_ACCOUNT_ID", payload);
    },
    SET_IDENTIFIER_MORTGAGE_ACCOUNT_DATA({ commit }, payload) {
      commit("SET_IDENTIFIER_MORTGAGE_ACCOUNT_DATA", payload);
    },
    SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_COLLATERAL_PRECIOUS_METAL(
      { commit },
      payload
    ) {
      commit(
        "SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_COLLATERAL_PRECIOUS_METAL",
        payload
      );
    },
    SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_DOCUMENT_CHECKLIST(
      { commit },
      payload
    ) {
      commit(
        "SET_OPTIONS_MORTGAGE_ACCOUNT_MORTGAGE_DOCUMENT_CHECKLIST",
        payload
      );
    },
  },
  getters: {
    get_identifier_mortgage_account_id: (state) =>
      state.identifier.mortgageAccountId,
    get_identifier_mortgage_account_data: (state) =>
      state.identifier.mortgageAccountData,
    get_options_mortgage_account_mortgage_collateral_precious_metal: (state) =>
      state.options.mortgageCollateral.preciousMetal.mortgageAccount,
    get_options_mortgage_account_mortgage_document_checklist: (state) =>
      state.options.mortgageDocumentChecklist.mortgageAccount,
  },
};
