export default {
  name: "FormPPAP",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          ppap: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        ppapId: "",
        collectabilityStatusId: "",
        isCollateralCoverage: false,
        percentageCollateralValue: 0,
        percentagePpapValue: 0,
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        kodeKolektibilitas: [],
      },
    };
  },
  methods: {
    async getReferenceLoanCollectabilityStatus() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-collectability-status",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.kodeKolektibilitas.push({
            value: "",
            text: "-- Pilih --",
          });
          resp.data.data.map((i) => {
            const value = i.collectabilityStatusId;
            const text = i.collectabilityStatusName;
            this.options.kodeKolektibilitas.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.ppapId = payload.ppapId;
        this.dataForm.collectabilityStatusId = payload.collectabilityStatus
          ? payload.collectabilityStatus.collectabilityStatusId
          : "";
        this.dataForm.isCollateralCoverage = payload.isCollateralCoverage;
        this.dataForm.percentageCollateralValue =
          payload.percentageCollateralValue;
        this.dataForm.percentagePpapValue = payload.percentagePpapValue;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/parameter/ppap/");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitPpap() {
      this.$emit("submitPpap", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceLoanCollectabilityStatus();
  },
};
