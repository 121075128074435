var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-between flex-wrap flex-row"},[_c('div',{staticClass:"w-full flex justify-center items-start flex-col",staticStyle:{"max-width":"400px"}},[_c('div',{staticClass:"flex justify-between mt-2 flex-wrap items-center flex-row w-full",staticStyle:{"max-width":"270px"},attrs:{"inline":""}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nomor Mortgage Akun")]),_c('b-input',{staticStyle:{"max-width":"180px","width":"100%"},attrs:{"expanded":"","size":"is-small","lazy":true},on:{"input":_vm.searchByPaidOffMortgagePaidOff},model:{value:(_vm.property.filterDto.mortgageNoAccount),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "mortgageNoAccount", $$v)},expression:"property.filterDto.mortgageNoAccount"}})],1)])]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.mortgagePaidOff,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.mortgagePaidOff.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.mortgagePaidOff.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.mortgagePaidOff.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"officeName","label":"Cabang"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tmortgageAccount.office ? props.row.tmortgageAccount.office.officeName : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"cifNumber","label":"No Kontrak"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tmortgageAccount.mortgageAccountNumber ? props.row.tmortgageAccount.mortgageAccountNumber : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"dueDate","label":"Nama Nasabah"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tmortgageAccount.mcif ? props.row.tmortgageAccount.mcif.cifIdName : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"dueDate","label":"Tgl Jatuh Tempo"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tmortgageAccount.dueDate ? props.row.tmortgageAccount.dueDate : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"nominalMortgage","label":"Plafon Pinjaman"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.nominalMortgage ? _vm.convertDecimalCurrency(props.row.nominalMortgage) : props.row.nominalMortgage)+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"statusName","label":"Status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.statusId ? props.row.statusId.statusName : "-")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsUpdate'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.routeToPageEditMortgagePaidOff(props)}}},[_vm._v(" Rincian ")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":props.row.statusId.statusId === '2' ||
                props.row.statusId.statusId === '3' ||
                !_vm.$store.getters['getActionsAuthorize'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.authorizationGetMortgagePaidOff(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.mortgagePaidOff.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.mortgagePaidOff.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationMortgagePaidOff},model:{value:(_vm.property.listElement.mortgagePaidOff.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.mortgagePaidOff, "currentPage", $$v)},expression:"property.listElement.mortgagePaidOff.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }