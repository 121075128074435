export default {
  name: "FormTSingleEntry",
  props: {
    isExisting: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      form: {
        chartOfAccountId: "",
        officeId: "",
        nominal: 0,
        mutationPosition: "",
        description: "",
      },
      options: {
        coa: [],
        office: [],
        posisiMutasi: [
          { value: "D", text: "Debet" },
          { value: "C", text: "Credit" },
        ],
      },
    };
  },
  methods: {
    routeToPageListSingleEntry() {
      this.$router.push("/single-entry");
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.coa = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountId;
            this.options.coa.push({
              text,
              value,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              officeName: "",
              page: 0,
            },
            url: "office",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office.push({ value: "", text: "-- Pilih --" });
          resp.data.data.content.map((index) => {
            const value = index.officeId;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.office.push({
              value,
              text,
            });
          });
        }
      } catch (error) {}
    },
    submitSingleEntry() {
      this.$emit("submitSingleEntry", this.form);
    },
    getDefaultFormValue(payload) {
      this.form.chartOfAccountId = payload.chartOfAccountId;
      this.form.officeId = payload.officeId;
      this.form.nominal = payload.nominal;
      this.form.mutationPosition = payload.mutationPosition;
      this.form.description = payload.description;
      return;
    },
    checkingMainId() {
      var params = this.$route.params.id;
      this.identity.mainId = this.decryptBASE64(params);
      this.$emit("getMainId", this.identity.mainId);
    },
  },
  mounted() {
    this.getReferenceChartOfAccount();
    this.getReferenceOffice();
    this.checkingMainId();
  },
};
