export default {
  name: "FormMJournalLedgerReversal",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      dataForm: {
        header: {
          linkModule: "",
          description: "",
          nominal: "",
          journalCode: "",
          statusId: "",
        },
        detail: [],
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      table: {
        header: {
          journalLedgerReversal: [],
        },
        data: {
          journalLedgerReversal: [],
        },
      },
      options: {
        chartOfAccount: [],
      },
    };
  },
  computed: {
    calculateDebt() {
      if (this.table.data.journalLedgerReversal.length > 0) {
        var total = 0;
        this.table.data.journalLedgerReversal.map((index) => {
          const debtNominal = !index.debtNominal
            ? 0
            : parseFloat(index.debtNominal);
          total = total + debtNominal;
        });
        return total;
      } else {
        return 0;
      }
    },
    calculateCredit() {
      if (this.table.data.journalLedgerReversal.length > 0) {
        var total = 0;
        this.table.data.journalLedgerReversal.map((index) => {
          const creditNominal = !index.creditNominal
            ? 0
            : parseFloat(index.creditNominal);
          total = total + creditNominal;
        });
        return total;
      } else {
        return 0;
      }
    },
    matchedNominal() {
      if (this.calculateDebt === this.calculateCredit) {
        return this.calculateDebt;
      } else {
        return this.dataForm.header.nominal;
      }
    },
  },
  methods: {
    changeChartOfAccount(props) {
      this.table.data.journalLedgerReversal.map((index) => {
        if (index.chartOfAccountId === props.row.chartOfAccountId) {
          const findCoa = this.options.chartOfAccount.find(
            (i) => i.value === index.chartOfAccountId
          );
          index.namaAkun = findCoa.description;
        }
      });
    },
    deleteRowjournalLedgerReversal(props) {
      const filterUnselected = this.table.data.journalLedgerReversal.filter(
        (index) => index.detailId !== props.row.detailId
      );
      this.table.data.journalLedgerReversal = filterUnselected;
    },
    routeToPageListJournalLedger() {
      this.$router.push("/parameter/journal-ledger-reversal");
    },
    addRowjournalLedgerReversal() {
      this.table.data.journalLedgerReversal.push({
        detailId: Math.random()
          .toString(36)
          .substr(2, 9),
        chartOfAccountId: "",
        description: "",
        debtNominal: 0,
        creditNominal: 0,
        namaAkun: "",
      });
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const value = index.chartOfAccountId;
            const removeSpaceInSubsidaryLedgerNumber = `${index.chartOfAccountCode}`.trim();
            const description = `${index.description}`.trim();
            const text = `${removeSpaceInSubsidaryLedgerNumber} - ${description}`;
            this.options.chartOfAccount.push({ text, value, description });
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.header.linkModule = payload.header.linkModule;
        this.dataForm.header.description = payload.header.description;
        this.dataForm.header.nominal = payload.header.nominal;
        this.dataForm.header.journalDate = payload.header.journalDate;
        this.dataForm.header.journalCode = payload.header.journalCode;
        this.dataForm.header.referenceNumber = payload.header.referenceNumber;
        this.dataForm.header.statusId = payload.header.rstatus?.statusId;
        payload.detail.map((index) => {
          const debtNominal = !index.debtNominal ? 0 : index.debtNominal;
          const creditNominal = !index.creditNominal ? 0 : index.creditNominal;
          const description = index.description;
          const chartOfAccountId = index.mchartOfAccount.chartOfAccountId;
          const namaAkun = index.mchartOfAccount.description;
          const detailId = Math.random()
            .toString(36)
            .substr(2, 9);
          this.table.data.journalLedgerReversal.push({
            debtNominal,
            creditNominal,
            description,
            chartOfAccountId,
            namaAkun,
            detailId,
          });
        });
        this.dataForm.createdByUserCode = payload.header.createdByUserCode;
        this.dataForm.createdByUserName = payload.header.createdByUserName;
        this.dataForm.createdDate = payload.header.createdDate;
        this.dataForm.updatedDate = payload.header.updatedDate;
        this.dataForm.updatedByUserCode = payload.header.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.header.updatedByUserName;
        this.dataForm.authorizedDate = payload.header.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.header.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.header.authorizedByUserName;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceChartOfAccount();
  },
};
