export default {
  name: "LoanTransferProduct",
  data() {
    return {
      property: {
        modal: {
          showModalCheckingLoanAccount: false,
          showModalResultLoanAccount: false,
        },
        animation: {
          checkingLoanAccount: {
            isLoading: false,
          },
          submitLoading: false,
        },
        listElement: {
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        loanAccountChecking: {
          accountNumber: "",
        },
        loanAccountNumber: "",
        cifIdName: "",
        loanAccountId: "",
        originOfficeCode: "",
        officeCode: "",
        originLoanCode: "",
        loanCode: "",
        loanAkadTypeAcronym: "",
      },
      table: {
        data: {
          loanAccount: [],
        },
      },
      session: {
        loanAccountChecking: {
          accountNumber: "",
        },
      },
      options: {
        office: [],
        produkPembiayaanByAkad: [],
        produkPembiayaan: [],
        originProdukPembiayaan: [],
        loanAkadType: [],
      },
    };
  },
  methods: {
    closePopUpCheckingLoanAccount() {
      this.property.modal.showModalCheckingLoanAccount = false;
      this.dataForm.loanAccountChecking.accountNumber = "";
    },
    closePopUpResultCheckingLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.perPage = 5;
    },
    async checkingLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.checkingLoanAccount.isLoading = true;
      this.session.loanAccountChecking.accountNumber = this.dataForm.loanAccountChecking.accountNumber;
      this.dataForm.loanAccountChecking.accountNumber = "";
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.session.loanAccountChecking.accountNumber,
            page: 0,
            size: this.property.listElement.loanAccount.perPage,
            cifIdName: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.property.modal.showModalCheckingLoanAccount = false;
            this.property.modal.showModalResultLoanAccount = true;
          } else {
            this.dataForm.loanAccountChecking.accountNumber = this.session.loanAccountChecking.accountNumber;
            this.session.loanAccountChecking.accountNumber = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 500);
      } catch (error) {
        this.dataForm.loanAccountChecking.accountNumber = this.session.loanAccountChecking.accountNumber;
        this.session.loanAccountChecking.accountNumber = "";
        console.log(error.response);
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.animation.checkingLoanAccount.isLoading = false;
        }, 500);
      }
    },
    failedGetLoanAccount(resp) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.message = resp.data.message;
    },
    handleErrorLoanAccount(error) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.session.loanAccountChecking.accountNumber,
            cifIdName: "",
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 500);
      }
    },
    getDataLoanAccount(props) {
      console.log(props);
      this.property.modal.showModalResultLoanAccount = false;
      this.dataForm.loanAccountNumber = props.row.loanAccountNumber;
      this.dataForm.cifIdName = props.row.cifIdName;
      this.dataForm.loanAccountId = props.row.loanAccountId;
      this.dataForm.originLoanCode = props.row.loanCode;
      this.dataForm.loanAkadTypeAcronym = props.row.loanAkadTypeAcronym;
      this.changeSelectTypeAkadLoanAccount();
    },
    async processTranferProductLoan() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Proses Pindah Produk ?",
        "is-success"
      );
      if (!confirm) return;
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "loan",
          reqUrl:
            "transaction-loan/product-transfer/" +
            this.dataForm.loanAccountId +
            "/to/" +
            this.dataForm.loanCode,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {});
          this.dataForm.loanAccountNumber = "";
          this.dataForm.cifIdName = "";
          this.dataForm.loanAccountId = "";
          this.dataForm.originLoanCode = "";
          this.dataForm.loanAkadTypeAcronym = "";
          this.dataForm.loanCode = "";  
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.property.animation.submitLoading = false;
      }
    },
    getReferenceLoan() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan/simple-list",
          params: {
            loanName: "",
            loanCode: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((index) => {
              const text = `${index.loanName} - ${index.loanCode}`;
              const value = index.loanCode;
              const loanAkadTypeAcronym = index.loanAkadTypeAcronym;
              this.options.produkPembiayaan.push({
                text,
                value,
                loanAkadTypeAcronym,
              });
              this.options.originProdukPembiayaan.push({
                text,
                value,
                loanAkadTypeAcronym,
              });
            });
          }
        });
    },
    changeSelectTypeAkadLoanAccount() {
      const filterAkad = this.options.produkPembiayaan.filter(
        (index) =>
          index.loanAkadTypeAcronym === this.dataForm.loanAkadTypeAcronym
      );

      this.options.produkPembiayaanByAkad = [
        { value: "", text: "-- Pilih --" },
        ...filterAkad,
      ];
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeAcronym;
            this.options.loanAkadType.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  mounted() {
    this.getReferenceLoan();
    this.getReferenceLoanAkadType();
  },
};
