import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

export default {
  name: "ListTLoanInstallmentManualPayment",
  data() {
    return {
      identifier: {
        cetakData: "",
        companyName: "",
      },
      dataForm: {
        checkingLoanAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      session: {
        checkingLoanAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        animation: {
          checkingLoanAccount: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingLoanAccount: false,
          showModalResultListLoanAccount: false,
          showModalPrint: false,
        },
        listElement: {
          installmentManual: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          loanAccountNumber: "",
          dueDate: "",
        },
      },
      options: {
        tipeLayanan: [],
      },
      table: {
        header: {
          installmentManual: [],
        },
        data: {
          installmentManual: [],
          loanAccount: [],
        },
      },
    };
  },
  methods: {
    getDateFormat(valDate) {
      var date = new Date(valDate);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    async findInstallmentManual() {
      this.table.data.installmentManual = [];
      this.property.listElement.installmentManual.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/v2/manual-payment",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            dueDate: this.property.filterDto.dueDate,
            page: 0,
            size: this.property.listElement.installmentManual.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.installmentManual = resp.data.data.content;
            this.property.listElement.installmentManual.rows =
              resp.data.data.totalElements;
            this.property.listElement.installmentManual.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetDataInstallment(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorInstallmentManual(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.installmentManual.downloading = false;
        }, timeout + 500);
      }
    },
    async handleCheckingAvailabilityLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.table.data.loanAccount = [];
        this.property.animation.checkingLoanAccount.isLoading = true;
        this.session.checkingLoanAccount.accountNumber = this.dataForm.checkingLoanAccount.accountNumber;
        this.session.checkingLoanAccount.cifIdName = this.dataForm.checkingLoanAccount.cifIdName;
        (this.dataForm.checkingLoanAccount.cifIdName = ""),
          (this.dataForm.checkingLoanAccount.accountNumber = "");
        this.property.listElement.loanAccount.currentPage = 1;
        const timeout = 500;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "loan",
            reqUrl: "transaction-loan/find-all",
            payload: {
              loanAccountNumber: this.session.checkingLoanAccount.accountNumber,
              cifIdName: this.session.checkingLoanAccount.cifIdName,
              page: 0,
              size: this.property.listElement.loanAccount.perPage,
            },
          });

          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.loanAccount = resp.data.data.content;
              this.property.modal.showModalCheckingLoanAccount = false;
              this.property.modal.showModalResultListLoanAccount = true;
              this.property.listElement.loanAccount.rows =
                resp.data.data.totalElements;
            } else {
              this.dataForm.checkingLoanAccount.accountNumber = "";
              this.dataForm.checkingLoanAccount.cifIdName = "";
              this.session.checkingLoanAccount.accountNumber = this.dataForm.checkingLoanAccount.accountNumber;
              this.session.checkingLoanAccount.cifIdName = this.dataForm.checkingLoanAccount.cifIdName;
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          console.log(error.response);
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingLoanAccount.isLoading = false;
          }, timeout);
        }
      }
    },
    handleCloseModalCheckingLoanAccount() {
      this.property.modal.showModalCheckingLoanAccount = false;
      this.handleClearInputModalCheckingLoanAccount();
    },
    handleClearInputModalCheckingLoanAccount() {
      this.dataForm.checkingLoanAccount.accountNumber = "";
      this.dataForm.checkingLoanAccount.cifIdName = "";
    },
    handleClearModalResultLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.perPage = 5;
    },
    handleRouteToPageDetailInstallmentManual(props) {
      this.$router.push(
        `/loan/installment/manual-payment/${this.encryptBASE64(
          props.row.loanInstallmentId
        )}`
      );
      console.log(this.encryptBASE64(props.row.loanInstallmentId));
    },
    async handleChangePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/find-all",
          payload: {
            loanAccountNumber: this.session.checkingLoanAccount.accountNumber,
            cifIdName: "",
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataLoanAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataLoanAccount(resp) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.message = resp.data.message;
    },
    handleCatchErrorLoanAccount(error) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      console.log(error.response);
      this.property.listElement.loanAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleRouteToPageAddLoanInstallmentManual(props) {
      this.$router.push(
        `/loan/installment/manual-payment/new?refId=${this.encryptBASE64(
          JSON.stringify({ loanAccountNumber: props.row.loanAccountNumber })
        )}`
      );
    },
    async handleAuthorizationInstallmentManual(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "loan-installment/v2/manual-payment/authorize/" +
                  props.row.loanInstallmentId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.loanAccountNumber = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.listElement.installmentManual.rows = 0;
                  this.property.listElement.installmentManual.currentPage = 1;
                  this.helperGetDataTableInstallmentManual();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleCatchErrorInstallmentManual(error) {
      this.table.data.installmentManual = [];
      this.property.listElement.installmentManual.rows = 0;
      this.property.listElement.installmentManual.currentPage = 1;
      this.property.listElement.installmentManual.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangePaginationInstallmentManual(event) {
      this.table.data.installmentManual = [];
      this.property.listElement.installmentManual.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/v2/manual-payment",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            dueDate: this.property.filterDto.dueDate,
            page: event - 1,
            size: this.property.listElement.installmentManual.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.installmentManual = resp.data.data.content;
            this.property.listElement.installmentManual.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataInstallment(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorInstallmentManual(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.installmentManual.downloading = false;
        }, timeout);
      }
    },
    showModalPrintLoanInstallmentManualPayment(props) {
      this.property.modal.showModalPrint = true;
      this.identifier.cetakData = props.row;
      this.identifier.companyName = this.fetchAppSession(
        "@vue-session/asset-bundle"
      ).companyName;
    },
    closeModalPrintLoanInstallmentManualPayment() {
      this.identifier.cetakData = "";
      this.property.modal.showModalPrint = false;
    },
    async cetakSlip() {
      this.property.animation.cetakSlip.isLoading = true;

      let pdf = new jsPDF("landscape", "mm", [297, 210]);

      setTimeout(() => {
        html2canvas(document.querySelector("#table-slip-rak"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-slip-rak").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 21, 9);
          pdf.autoPrint();

          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-slip-rak").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          this.property.modal.showModalPrint = false;
        });
      }, 200);
    },

    async helperGetDataTableInstallmentManual() {
      this.table.data.installmentManual = [];
      this.property.listElement.installmentManual.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/v2/manual-payment",
          payload: {
            accountNumber: "",
            loanAccountNumber: "",
            dueDate: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.installmentManual = resp.data.data.content;
            this.property.listElement.installmentManual.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataInstallment(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorInstallmentManual(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.installmentManual.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataInstallment(resp) {
      this.table.data.installmentManual = [];
      this.property.listElement.installmentManual.rows = 0;
      this.property.listElement.installmentManual.message = resp.data.message;
    },
  },
  mounted() {
    this.helperGetDataTableInstallmentManual();
  },
};
