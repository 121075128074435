const Overbook = {
  actions: {
    GET_OVERBOOK({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/overbook`,
            method: "GET",
            params: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
export default Overbook;
