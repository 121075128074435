import Add from "./add";
import Detail from "./detail";
import Edit from "./edit";
import List from "./list";

export default {
  Add,
  Detail,
  Edit,
  List,
};
