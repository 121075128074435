import FormAtmChannel from "../form";

export default {
  name: "AtmChannelExisting",
  components: {
    FormAtmChannel,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getAtmChannelById();
    },
    async getAtmChannelById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formAtmChannelRef.backToList();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "atm-channel-transaction/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          console.log({resp,msg:'hello'})
          this.$refs.formAtmChannelRef.getDefaultFormValue(resp.data.data);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.$refs.formAtmChannelRef.backToList(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.$refs.formAtmChannelRef.backToList(),
        });
      }
    },
  },
};
