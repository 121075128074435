<template>
  <div class="w-full flex justify-center items-center">
    <div style="max-width:400px" class="w-full">
      <form-builder
        :message-mapper="messages"
        :scheme="{
          cifIdName: {
            type: 'text',
            label: 'Nama Nasabah',
            isInformationComponent: true,
            value: formSchemeModel.cifIdName,
          },
          nominal: {
            type: 'text',
            label: 'Nominal',
            isInformationComponent: true,
            value: convertDecimalCurrency(formSchemeModel.nominal),
            show: showAccountNumber,
          },
          accountNumber: {
            type: 'text',
            label: 'No. Rekening',
            isInformationComponent: false,
            models: 'accountNumber',
            event: { type: 'input', action: (e) => onChangeAccountNumber(e) },
          },
          transactionCodeId: {
            type: 'model-list-select',
            label: 'Kode Transaksi',
            models: 'transactionCodeId',
            selectProperty: {
              options: options.kodeTransaksi,
              bindValue: 'value',
              bindText: (e) => {
                return `${e.text}`;
              },
            },
            event: {
              type: 'input',
              action: (e) => {
                sayHello(e);
              },
            },
          },
        }"
        v-model="formSchemeModel"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RndPage",
  data() {
    return {
      options: {
        kodeTransaksi: [
          {
            text: "hello",
            value: "1",
          },
          {
            text: "hello 2",
            value: "2",
          },
        ],
      },
      showAccountNumber: false,
      formSchemeModel: {
        cifIdName: "hello",
        nominal: 10000,
        accountNumber: "",
        transactionCodeId: "",
      },
      messages: {},
    };
  },
  methods: {
    onChangeAccountNumber(e) {
      console.log(e.target.value);
    },
    setter() {
      setTimeout(() => {
        this.showAccountNumber = true;
      }, 3000);
    },
    sayHello(e) {
      console.log(e);
    },
    post() {
      this.messages = error.response.data.errorFields;
    },
  },
  mounted() {
    this.setter();
  },
};
</script>
