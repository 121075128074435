import ModalSearchSavingAccount from "@/components/modal-search-saving-account";

export default {
  name: "FormTCashAdvance",
  props: {
    isExisting: false,
  },
  components: {
    ModalSearchSavingAccount,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        modal: {
          modalFindSavingAccount: false,
        },
        animation: {
          submitLoading: false,
        },
      },
      options: {
        department: [],
        cashAdvanceType: [],
        accountType: [
          { text: "-- Pilih --", value: "" },
          { text: "ABA", value: "ABA" },
          { text: "TAB", value: "TAB" },
        ],
        interbankAssets: [],
      },
      form: {
        cashAdvanceName: "",
        cashAdvanceCode: "",
        realizationDate: "",
        returnDate: "",
        nominal: 0,
        description: "",
        departmentId: "",
        cashAdvanceTypeId: "",
        accountNumber: "",
        accountName: "",
        nominalAdmin: 0,
        accountType: "",
      },
    };
  },
  methods: {
    async getSystemDate() {
      this.form.realizationDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.form.returnDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    checkingMainId() {
      var params = this.$route.params.id;
      this.identity.mainId =
        this.isExisting === true ? this.decryptBASE64(params) : "";
      this.$emit("getMainId", this.identity.mainId);
    },
    backToList() {
      this.$router.push("/cash-advance");
    },
    submit() {
      this.$emit("submit", this.form);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting) {
        this.form = {
          ...this.form,
          cashAdvanceName: payload.cashAdvanceName,
          cashAdvanceCode: payload.cashAdvanceCode,
          realizationDate: payload.realizationDate,
          returnDate: payload.returnDate,
          nominal: payload.nominal,
          description: payload.description,
          departmentId: payload.departmentId,
          cashAdvanceTypeId: payload.rcashAdvancePaymentType?.paymentTypeId,
          accountNumber:
            payload.accountType === "TAB"
              ? payload.tsavingAccount?.accountNumber
              : payload.minterbankAssets?.accountNumber,
          accountName:
            payload.accountType === "TAB"
              ? `${
                  payload.tsavingAccount?.qqName
                    ? `${payload.tsavingAccount?.mcif?.cifIdName} ${payload.tsavingAccount?.qqCode} ${payload.tsavingAccount?.qqName}`
                    : payload.tsavingAccount?.mcif?.cifIdName
                }`
              : `${payload.minterbankAssets?.accountName} ( ${payload.minterbankAssets?.bankName} )`,
          nominalAdmin: payload.nominalAdmin,
          accountType: payload.accountType,
          createdByUserCode: payload.createdByUserCode,
          createdByUserName: payload.createdByUserName,
          createdDate: payload.createdDate,
          updatedDate: payload.updatedDate,
          updatedByUserCode: payload.updatedByUserCode,
          updatedByUserName: payload.updatedByUserName,
          authorizedDate: payload.authorizedDate,
          authorizedByUserCode: payload.authorizedByUserCode,
          authorizedByUserName: payload.authorizedByUserName,
        };
      }
    },
    onChangeAccountInterbankAsset() {
      let val = this.options.interbankAssets.find(
        (item) => item.value === this.form.accountNumber
      );
      this.form.accountName = val.accountName;
    },
    async getReferenceDepartment() {
      const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
        url: "department",
      });
      if (resp.data.code === "SUCCESS") {
        this.options.department = [{ value: "", text: "-- Pilih --" }];
        resp.data.data.map((data) => {
          const value = data.departmentId;
          const text = data.departmentName;
          this.options.department.push({ text, value });
        });
      }
    },
    async getReferenceCashAdvancePaymentType() {
      const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
        url: "cash_advance_payment_type",
      });
      if (resp.data.code === "SUCCESS") {
        this.options.cashAdvanceType = [{ value: "", text: "-- Pilih --" }];
        resp.data.data.map((data) => {
          const value = data.paymentTypeId;
          const text = data.paymentTypeName;
          this.options.cashAdvanceType.push({ text, value });
        });
      }
    },
    async getReferenceInterbankAsset() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.interbankAssets = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.accountNumber;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = `${index.accountName} ( ${index.bankName} )`;
            this.options.interbankAssets.push({
              text,
              value,
              accountName,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeMount() {
    this.checkingMainId();
  },
  mounted() {
    this.getSystemDate();
    this.getReferenceDepartment();
    this.getReferenceCashAdvancePaymentType();
    this.getReferenceInterbankAsset();
  },
};
