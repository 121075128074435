import moment from "moment";
import TransactionListUmbPdf from "@/components/page/transaction-list-umb-pdf";

export default {
  name: "TCashAdvance",
  components: {
    TransactionListUmbPdf,
  },
  data() {
    return {
      table: {
        data: {
          cashAdvance: [],
        },
      },
      options: {
        department: [],
        cashAdvanceType: [],
        statusAccount: [],
      },
      property: {
        listElement: {
          cashAdvance: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          cashAdvanceName: "",
          cashAdvanceCode: "",
          dateTo: "",
          dateFrom: "",
          statusId: "",
        },
        animation: {
          isDownloadingFile: false,
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        cashAdvanceName: this.property.filterDto.cashAdvanceName,
        cashAdvanceCode: this.property.filterDto.cashAdvanceCode,
        statusId: this.property.filterDto.statusId,
        isReturn: false,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "cash_advance/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Umb.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    routeToPageAddCashAdvanceReturn(props) {
      this.$router.push(
        `/cash-advance-return/new?refId=${this.encryptBASE64(
          JSON.stringify({
            cashAdvanceId: props.row.cashAdvanceId,
          })
        )}`
      );
    },
    async changePaginationCashAdvance(event) {
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: {
            cashAdvanceName: this.property.filterDto.cashAdvanceName,
            cashAdvanceCode: this.property.filterDto.cashAdvanceCode,
            statusId: this.property.filterDto.statusId,
            isReturn: false,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.cashAdvance.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashAdvance = resp.data.data.content;
            this.property.listElement.cashAdvance.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataCashAdvance(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashAdvance(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashAdvance.downloading = false;
        }, timeout);
      }
    },
    routeToPageCreateCashAdvance() {
      this.$router.push("/cash-advance/new");
    },
    routeToPageExistingCashAdvance(props) {
      this.$router.push(
        `/cash-advance/${this.encryptBASE64(`${props.row.cashAdvanceId}`)}`
      );
    },
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.transactionListUmbPdfRef.onOpenPreview({
        tableData: this.table.data.cashAdvance,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          officeName: getUserPayloadFromSession.officeName,
          filteredDate: `${moment().format("DD-MM-YYYY")} `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    async findCashAdvance() {
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: {
            cashAdvanceName: this.property.filterDto.cashAdvanceName,
            cashAdvanceCode: this.property.filterDto.cashAdvanceCode,
            statusId: this.property.filterDto.statusId,
            isReturn: false,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.cashAdvance.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashAdvance = resp.data.data.content;
            this.property.listElement.cashAdvance.rows =
              resp.data.data.totalElements;
            this.property.listElement.cashAdvance.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataCashAdvance(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashAdvance(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashAdvance.downloading = false;
        }, timeout);
      }
    },
    async getCashAdvance() {
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: {
            cashAdvanceName: "",
            cashAdvanceCode: "",
            statusId: "",
            isReturn: false,
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashAdvance = resp.data.data.content;
            this.property.listElement.cashAdvance.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataCashAdvance(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashAdvance(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashAdvance.downloading = false;
        }, timeout);
      }
    },
    failedGetDataCashAdvance(resp) {
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.rows = 0;
      this.property.listElement.cashAdvance.message = resp.data.message;
    },
    handleErrorGetCashAdvance(error) {
      console.log(error.response);
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.rows = 0;
      this.property.listElement.cashAdvance.currentPage = 1;
      this.property.listElement.cashAdvance.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationCashAdvance(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "cash-advance/authorized/" + props.row.cashAdvanceId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.resetListCashAdvance();
                  this.getCashAdvance();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteCashAdvance(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cash-advance/" + props.row.cashAdvanceId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.resetListCashAdvance();
                  this.getCashAdvance();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    resetListCashAdvance() {
      this.property.listElement.cashAdvance.currentPage = 1;
      this.property.listElement.cashAdvance.rows = 0;
      this.property.filterDto.cashAdvanceName = "";
      this.property.filterDto.cashAdvanceCode = "";
      this.property.filterDto.dateTo = "";
      this.property.filterDto.dateFrom = "";
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getCashAdvance();
    this.getReferenceStatusAccount();
  },
};
