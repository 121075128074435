import FormSandiBi from "../form";

export default {
  name: "CreateSandiBi",
  components: {
    FormSandiBi,
  },
  data() {
    return {
      idetity: {
        mainId: "",
      },
    };
  },
  methods: {
    async addSandiBi(payload) {
      const data = {
        sandiBiCode: payload.sandiBiCode,
        sandiBiName: payload.sandiBiName,
        sandiBiLevel2Code: payload.sandiBiLevel2Code,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formMSandiBiRef.property.animation.sandiBi.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "parameter",
                reqUrl: "sandi-bi",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$refs.formMSandiBiRef.routeToPageList();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              console.log(error.response);
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formMSandiBiRef.property.animation.sandiBi.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
