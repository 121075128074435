import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import MixinsFuntions from "../../../../service/MixinsFuntions";

export default {
  mixins: [MixinsFuntions],
  name: "ListTSavingAccountDetail",
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identifier: {
        printSavingAccountDetail: {
          accountNumber: "",
          transactionName: "",
          cifIdName: "",
          nominal: "",
          referenceNumber: "",
        },
        accountNumber: "",
        transactionName: "",
        cifIdName: "",
        nominal: "",
        referenceNumber: "",
      },
      form: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
        dateFrom: "",
        dateTo: "",
        total: 0,
        companyName: "",
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        modal: {
          showModalCheckingSavingAccount: false,
          showModalResultListSavingAccount: false,
          showModalPrintSavingAccountDetail: false,
          showModalPrintDailyHistory: false,
        },
        animation: {
          CheckingSavingAccount: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
        listElement: {
          savingAccountDetail: {
            rows: 0,
            detailOpened: [],
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          name: "",
          dateTo: "",
          dateFrom: "",
          statusId: "",
        },
      },
      options: {
        statusAccount: [],
      },
      table: {
        header: {
          savingAccountDetail: [],
          savingAccount: [],
        },
        data: {
          savingAccountDetail: [],
          savingAccount: [],
        },
      },
    };
  },
  methods: {
    async findSavingAccountDetail() {
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/simple-list-v2",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            page: 0,
            statusId: this.property.filterDto.statusId,
            size: this.property.listElement.savingAccountDetail.perPage,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });

        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.insertSavingAccountDetailTable(resp);
          this.property.listElement.savingAccountDetail.currentPage =
            resp.data.data.pageable.pageNumber + 1;
          this.property.listElement.savingAccountDetail.rows =
            resp.data.data.totalElements;
          this.defaultOpenDetailSavingAccountDetail(resp);
        } else {
          this.failedGetDataSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountDetail.downloading = false;
      }
    },

    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
        this.form.checkingSavingAccount.accountNumber = "";
        this.form.checkingSavingAccount.cifIdName = "";

        try {
          this.property.animation.CheckingSavingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.content;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.property.modal.showModalResultListSavingAccount = true;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
          this.form.checkingSavingAccount.accountNumber = "";
          this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
          this.form.checkingSavingAccount.cifIdName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.CheckingSavingAccount.isLoading = false;
        }
      }
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    handleErrorGetCif(error) {
      this.form.checkingCif.accountNumber = "";
      this.form.checkingCif.cifIdName = "";
      this.session.checkingCif.accountNumber = this.form.checkingCif.accountNumber;
      this.session.checkingCif.cifIdName = this.form.checkingCif.cifIdName;
      console.log(error.response);
      this.table.data.cif = [];
      this.property.listElement.cif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizeSavingAccountDetail(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/transaction_cash/authorized/" +
            props.row.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.savingAccountDetail.currentPage = 1;
          this.property.filterDto.accountNumber = "";
          this.property.filterDto.dateTo = "";
          this.property.filterDto.dateFrom = "";
          this.property.filterDto.statusId = "";
          this.property.listElement.savingAccountDetail.rows = 0;
          this.helperGetDataTableSavingAccountDetail();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    routeToPageAddSavingAccountDetail(props) {
      if (props.row.statusId !== "3") {
        this.property.modal.showModalResultListSavingAccount = false;
        this.$buefy.toast.open({
          message: `Rekening Tabungan Belum Aktif !`,
          type: "is-danger",
          duration: 3000,
          position: "is-top",
          queue: false,
        });
        return;
      }
      this.$router.push(
        `/saving/saving-account-detail/new?refId=${this.encryptBASE64(
          JSON.stringify({
            savingAccountId: props.row.savingAccountId,
          })
        )}`
      );
    },
    routeToPageDetailSavingAccountDetail(props) {
      this.$router.push(
        `/saving/saving-account-detail/${this.encryptBASE64(
          `${props.row.savingAccountDetailId}`
        )}`
      );
    },
    routeToPageReversalSavingAccountDetail(props) {
      this.$router.push(
        `/saving/reversal/new?refId=${this.encryptBASE64(
          JSON.stringify({
            savingAccountDetailId: props.row.savingAccountDetailId,
          })
        )}`
      );
    },
    async throwIdForPrintingSavingAccountDetail(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving-account-detail/" + props.row.savingAccountDetailId,
          });
          if (resp.data.code === "SUCCESS") {
            this.identifier.printSavingAccountDetail = resp.data.data;
            // this.property.modal.showModalPrintSavingAccountDetail = true;
            this.property.modal.showModalPrintDailyHistory = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async onPrintOutSlip(props) {
      if (
        this.fetchAppSession("@vue-session/asset-bundle").companyName ===
        "BPRS FORMES"
      ) {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "saving-account-detail/print-transaction/" +
              props.savingAccountDetailId,
          });
          if (resp.data.code === "SUCCESS") {
            this.printOutsSavingAccountDetailFormesVersion(props);
            return;
          }
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } catch (error) {
          console.log(error);
        }
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/print-transaction/" +
            props.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.printOutsSavingAccountDetail(props);
          return;
        }
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleCatchErrorSavingAccountDetail(error) {
      console.log(error.response);
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.rows = 0;
      this.property.listElement.savingAccountDetail.currentPage = 1;
      this.property.listElement.savingAccountDetail.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationSavingAccountDetail(event) {
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/simple-list-v2",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            page: event - 1,
            size: this.property.listElement.savingAccountDetail.perPage,
            statusId: this.property.filterDto.statusId,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.insertSavingAccountDetailTable(resp);
          this.property.listElement.savingAccountDetail.rows =
            resp.data.data.totalElements;
          this.defaultOpenDetailSavingAccountDetail(resp);
        } else {
          this.failedGetDataSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountDetail.downloading = false;
      }
    },
    printDataSavingAccountDetail() {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc
        .setFontSize(13)
        .text(`Print Out Transaksi Tunai`, pageWidth / 2 - 1.4, 1);
      doc
        .setFontSize(9)
        .text(
          `Nomor Rekening      :    ${this.identifier.printSavingAccountDetail.accountNumber}`,
          0.55,
          1.9
        );
      doc
        .setFontSize(9)
        .text(
          `Nama Nasabah        :    ${this.identifier.printSavingAccountDetail.cifIdName}`,
          0.55,
          2.3
        );
      doc
        .setFontSize(9)
        .text(
          `Jenis Transaksi        :   ${this.identifier.printSavingAccountDetail.transactionName}`,
          0.55,
          2.7
        );
      doc
        .setFontSize(9)
        .text(
          `Nomor Referensi      :   ${this.identifier.printSavingAccountDetail.referenceNumber}`,
          0.55,
          3.1
        );
      doc
        .setFontSize(9)
        .text(
          `Nominal                    :    ${
            this.identifier.printSavingAccountDetail.nominal
              ? this.identifier.printSavingAccountDetail.nominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          0.55,
          3.5
        );
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      doc
        .setFontSize(9)
        .text(
          `Print By  :  ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userName
          } ( ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userCode
          } )`,
          0.55,
          pageHeight - 0.5
        );

      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    async helperGetDataTableSavingAccountDetail() {
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/simple-list-v2",
          payload: {
            accountNumber: "",
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            name: "",
            page: 0,
            size: this.property.listElement.savingAccountDetail.perPage,
            dateTo: "",
            statusId: "",
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.insertSavingAccountDetailTable(resp);
          this.property.listElement.savingAccountDetail.rows =
            resp.data.data.totalElements;
          this.defaultOpenDetailSavingAccountDetail(resp);
        } else {
          this.failedGetDataSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountDetail.downloading = false;
      }
    },
    failedGetDataSavingAccountDetail(resp) {
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.rows = 0;
      this.property.listElement.savingAccountDetail.message = resp.data.message;
    },
    closeModalCheckingSavingAccount() {
      this.property.modal.showModalCheckingSavingAccount = false;
      this.clearModalCheckingSavingAccount();
    },
    clearModalCheckingSavingAccount() {
      this.form.checkingSavingAccount.accountNumber = "";
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);

      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    closeModalPrint() {
      this.identifier.printSavingAccountDetail = "";
      // this.property.modal.showModalPrintSavingAccountDetail = false;
      this.property.modal.showModalPrintDailyHistory = false;
    },
    async cetakSlipTrx() {
      this.property.animation.cetakSlip.isLoading = true;
      let pdf = new jsPDF("landscape", "mm", "a4");

      setTimeout(() => {
        html2canvas(document.querySelector("#table-slip-detail"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-slip-detail").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 21, 9);
          pdf.autoPrint();

          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-slip-detail").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          // this.property.modal.showModalPrintSavingAccountDetail = false;
          this.property.modal.showModalPrintDailyHistory = false;
        });
      }, 200);
    },
    insertSavingAccountDetailTable(resp) {
      this.table.data.savingAccountDetail = [];
      console.log(resp);
      resp.data.data.content.map((index) => {
        const savingAccountDetailId = index.header.savingAccountDetailId;
        this.table.data.savingAccountDetail.push({
          savingAccountDetailId,
          header: index.header,
          detail: index.detail,
        });
      });
    },
    defaultOpenDetailSavingAccountDetail(resp) {
      this.property.listElement.savingAccountDetail.detailOpened = [];
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getCompanyName() {
      this.form.companyName = this.fetchAppSession(
        "@vue-session/asset-bundle"
      ).companyName;
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.helperGetDataTableSavingAccountDetail();
    this.getReferenceStatusAccount();
    this.getCompanyName();
  },
};
