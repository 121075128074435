export default {
  name: "ListSandiBiCodeMapping",
  data() {
    return {
      property: {
        animation: {
          addSandiBiCodeMapping: {
            isLoading: false,
          },
          editSandiBiCodeMapping: {
            isLoading: false,
          },
        },

        listElement: {
          sandiBiCodeMapping: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          sandiBiLevel1Code: "",
          sandiBiLevel2Code: "",
          sandiBiCode: "",
        },
      },
      table: {
        data: {
          sandiBiCodeMapping: [],
        },
      },
    };
  },
  methods: {
    routeToPageAddDayOff() {
      this.$router.push("/parameter/mapping-parameter-sandi-bi/new");
    },
    routeToPageEditDayOff(props) {
      console.log(props.row);
      this.$router.push(
        `/parameter/mapping-parameter-sandi-bi/${this.encryptBASE64(
          `${props.row.sandiBiCode +
            "-" +
            props.row.sandiBiLevel1Code +
            "-" +
            props.row.sandiBiLevel2Code}`
        )}`
      );
    },
    async findMappingParameterSandiBi() {
      this.table.data.sandiBiCodeMapping = [];
      this.property.listElement.sandiBiCodeMapping.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "sandi-bi-code-mapping",
          payload: {
            sandiBiLevel1Code: this.property.filterDto.sandiBiLevel1Code,
            sandiBiLevel2Code: this.property.filterDto.sandiBiLevel2Code,
            sandiBiCode: this.property.filterDto.sandiBiCode,
            page: 0,
            size: this.property.listElement.sandiBiCodeMapping.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sandiBiCodeMapping = resp.data.data.content;
            this.property.listElement.sandiBiCodeMapping.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.sandiBiCodeMapping.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSandiBiCodeMapping(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSandiBiCodeMapping(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sandiBiCodeMapping.downloading = false;
        }, timeout + 500);
      }
    },

    async deleteSandiBiCodeMapping(props) {
      console.log(props);
      let params = {
        sandiBiLevel1Code: props.row.sandiBiLevel1Code,
        sandiBiLevel2Code: props.row.sandiBiLevel2Code,
        sandiBiCode: props.row.sandiBiCode,
      };
      console.log(params);
      if (window.navigator.onLine === false) {
        console.log("A");
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        console.log("d");
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "parameter",
          reqUrl: "sandi-bi-code-mapping",
          params  : params,
        });
        console.log("e");
        if (resp.data.code === "SUCCESS") {
          this.$buefy.toast.open(this.$NotificationUtils.success);
          setTimeout(() => {
            this.property.listElement.sandiBiCodeMapping.currentPage = 1;
            this.property.filterDto.sandiBiLevel1Code = "";
            this.property.filterDto.sandiBiLevel2Code = "";
            this.property.filterDto.sandiBiCode = "";
            this.property.listElement.sandiBiCodeMapping.rows = 0;
            this.getSandiBiCodeMapping();
          }, 1000);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handleErrorSandiBiCodeMapping(error) {
      console.log(error.response);
      this.table.data.sandiBiCodeMapping = [];
      this.property.listElement.sandiBiCodeMapping.rows = 0;
      this.property.listElement.sandiBiCodeMapping.currentPage = 1;
      this.property.listElement.sandiBiCodeMapping.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationSandiBiCodeMapping(event) {
      this.table.data.sandiBiCodeMapping = [];
      this.property.listElement.sandiBiCodeMapping.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "sandi-bi-code-mapping",
          payload: {
            sandiBiLevel1Code: this.property.filterDto.sandiBiLevel1Code,
            sandiBiLevel2Code: this.property.filterDto.sandiBiLevel2Code,
            sandiBiCode: this.property.filterDto.sandiBiCode,
            page: event - 1,
            size: this.property.listElement.sandiBiCodeMapping.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sandiBiCodeMapping = resp.data.data.content;
            this.property.listElement.sandiBiCodeMapping.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSandiBiCodeMapping(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSandiBiCodeMapping(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sandiBiCodeMapping.downloading = false;
        }, timeout);
      }
    },
    async getSandiBiCodeMapping() {
      this.table.data.sandiBiCodeMapping = [];
      this.property.listElement.sandiBiCodeMapping.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "sandi-bi-code-mapping",
          payload: {
            sandiBiLevel1Code: "",
            sandiBiLevel2Code: "",
            sandiBiCode: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sandiBiCodeMapping = resp.data.data.content;
            this.property.listElement.sandiBiCodeMapping.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSandiBiCodeMapping(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSandiBiCodeMapping(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sandiBiCodeMapping.downloading = false;
        }, timeout);
      }
    },

    failedGetDataSandiBiCodeMapping(resp) {
      this.table.data.sandiBiCodeMapping = [];
      this.property.listElement.sandiBiCodeMapping.rows = 0;
      this.property.listElement.sandiBiCodeMapping.message = resp.data.message;
      this.property.listElement.sandiBiCodeMapping.currentPage = 1;
    },
  },
  mounted() {
    this.getSandiBiCodeMapping();
  },
};
