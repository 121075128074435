export default {
  state: {
    identifier: {
      cifData: "",
    },
  },
  mutations: {
    SET_IDENTIFIER_CIF_DATA(state, payload) {
      state.identifier.cifData = payload;
    },
    CLEAR_CIF_DATA(state, payload) {
      state.identifier.cifData = "";
    },
  },
  actions: {
    CLEAR_CIF_DATA({ commit }, payload) {
      commit("CLEAR_CIF_DATA");
    },
    SET_IDENTIFIER_CIF_DATA({ commit }, payload) {
      commit("SET_IDENTIFIER_CIF_DATA", payload);
    },
  },
  getters: {
    get_identifier_cif_data: (state) => state.identifier.cifData,
  },
};
