export default {
  name: "FormTDepositAccountDisburse",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        accountNumber: "",
        depositAccountId: "",
        cifIdName: "",
        depositName: "",
        dueDate: "",
        documentProofNumber: "",
        depositBilyetNumber: "",
        depositTransferBankName: "",
        depositTransferBankAccount: "",
        isRollover: false,
        depositTransferBankAccountName: "",
        nominal: 0,
        tax: "",
        penalty: "",
        description: "",
        liquidityType: "",
        paymentType: "",
        isPhysicalBilyetIncluded: false,
        tsavingAccountDisburseDepositAccountNumber: "",
        savingDepositAccountNumberName: "",
        rdepositOptionDisburseDepositId: "",
        bilyetId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        jenisPencairan: [
          { value: "", text: "-- Pilih --" },
          { value: "PP", text: "Pencairan Pokok dan Pencairan Bagi Hasil" },
          {
            value: "TR",
            text: "Transfer ke Rekening dan Transfer Rekening ABA",
          },
        ],
        caraBayar: [
          { value: "", text: "-- Pilih --" },
          { value: "TABA", text: "Transfer Rekening ABA" },
          { value: "TAB_HIKP", text: "Transfer ke Rekening HIKP" },
        ],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
    };
  },
  methods: {
    routeToPageListDepositDisburse() {
      this.$router.push("/deposit/deposit-account-disburse");
    },
    async OnSubmit() {
      this.$buefy.dialog.confirm({
        title: "Konfirmasi",
        message: "Nasabah Membawa Bukti Bilyet?",
        confirmText: "Membawa",
        cancelText: "Tidak",
        type: "is-info",
        hasIcon: true,
        canCancel: ["button", "outside"],
        closeOnConfirm: false,
        onConfirm: async (value, { close }) => {
          this.form.isPhysicalBilyetIncluded = true;
          this.checkRollOver();
          close();
        },
        onCancel: () => {
          this.form.isPhysicalBilyetIncluded = false;
          this.checkRollOver();
          close();
        },
      });
    },
    checkRollOver() {
      switch (this.form.isRollover) {
        case true:
          this.$buefy.dialog.confirm({
            title: "Konfirmasi",
            message:
              "Rekening Deposito Merupakan Roll Over , Untuk Melanjutkan Pencairan , Roll Over Akan Di Non Aktifkan",
            confirmText: "Lanjutkan",
            type: "is-info",
            hasIcon: true,
            canCancel: ["button", "outside"],
            closeOnConfirm: false,
            onConfirm: async (value, { close }) => {
              try {
                const resp = await this.$store.dispatch({
                  type: "PUT_DATA",
                  endPoint: "transaction",
                  reqUrl:
                    "deposit-account/disable-aro/" + this.form.depositAccountId,
                });
                if (resp.data.code === "SUCCESS") {
                  close();
                  const toastBuefy = this.$buefy.toast.open({
                    message: "Roll Over Berhasil Di Non Aktifkan",
                    type: "is-success",
                    duration: 1000,
                    position: "is-top",
                    queue: false,
                  });
                  toastBuefy.$on("close", () => {
                    this.submitDepositAccountDisburse(false);
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              } catch (error) {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            },
          });
          break;
        case false:
          this.submitDepositAccountDisburse(true);
          break;
        default:
          return;
          break;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListDepositDisburse();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).depositAccountNumber;
      this.$emit("getMainId", this.identity.mainId);
      this.form.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.accountNumber = payload.tdepositAccount
          ? payload.tdepositAccount.accountNumber
          : "";
        this.form.cifIdName = payload.tdepositAccount
          ? payload.tdepositAccount.mcifResponseDto
            ? payload.tdepositAccount.mcifResponseDto.cifIdName
            : ""
          : "";
        this.form.depositName = payload.tdepositAccount
          ? payload.tdepositAccount.mdepositDto
            ? payload.tdepositAccount.mdepositDto.depositName
            : ""
          : "";
        this.form.dueDate = payload.tdepositAccount
          ? payload.tdepositAccount.dueDate
          : "";
        var rdepositOptionDisburseDepositDto = payload.tdepositAccount
          ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
            ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
                .optionDisburseId
            : ""
          : "";
        this.form.depositTransferBankName =
          rdepositOptionDisburseDepositDto ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_DEPOSIT_OPTION_DISBURSE_REKENING
            ? "HIKP"
            : rdepositOptionDisburseDepositDto ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_TRANSFER_BANK
            ? payload.tdepositAccount.depositTransferBankName
            : rdepositOptionDisburseDepositDto ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_SBB
            ? "SBB"
            : "";
        this.form.depositTransferBankAccount =
          rdepositOptionDisburseDepositDto ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_DEPOSIT_OPTION_DISBURSE_REKENING
            ? `${payload.tdepositAccount.disburseDepositAccount}`
            : rdepositOptionDisburseDepositDto ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_TRANSFER_BANK
            ? payload.tdepositAccount.depositTransferBankAccount
            : rdepositOptionDisburseDepositDto ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_SBB
            ? `${payload.tdepositAccount.disburseDepositAccount}`
            : "";
        this.form.depositTransferBankAccountName =
          rdepositOptionDisburseDepositDto ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_DEPOSIT_OPTION_DISBURSE_REKENING
            ? `${payload.tdepositAccount.disburseDepositAccountName}`
            : rdepositOptionDisburseDepositDto ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_TRANSFER_BANK
            ? payload.tdepositAccount.depositTransferBankAccountName
            : rdepositOptionDisburseDepositDto ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_SBB
            ? `${payload.tdepositAccount.disburseDepositAccountName}`
            : "";
        this.form.nominal = payload.nominal;
        this.form.rdepositOptionDisburseDepositId = payload.tdepositAccount
          ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
            ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
                .optionDisburseId
            : ""
          : "";
        this.form.tsavingAccountDisburseDepositAccountNumber = payload.tdepositAccount
          ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
            ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
                .accountNumber
            : ""
          : "";
        this.form.savingDepositAccountNumberName = payload.tdepositAccount
          ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
            ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
                .mcif
              ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
                  .mcif.cifIdName
              : ""
            : ""
          : "";
        this.form.tax = payload.tdepositAccount
          ? payload.tdepositAccount.mdepositDto
            ? payload.tdepositAccount.mdepositDto.tax
            : ""
          : "";
        this.form.penalty = payload.tdepositAccount
          ? payload.tdepositAccount.mdepositDto
            ? payload.tdepositAccount.mdepositDto.depositBreakPenalty
            : ""
          : "";
        this.form.depositBilyetNumber = payload.mdepositBilyet
          ? payload.mdepositBilyet.depositBilyetNumber
          : "";
        this.form.referenceNumber = payload.referenceNumber;
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.description = payload.description;
        this.form.transactionDate = payload.transactionDate;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.form.accountNumber = payload.tdepositAccount
        ? payload.tdepositAccount.accountNumber
        : "";
      this.form.cifIdName = payload.tdepositAccount
        ? payload.tdepositAccount.mcifResponseDto
          ? payload.tdepositAccount.mcifResponseDto.cifIdName
          : ""
        : "";
      this.form.depositName = payload.tdepositAccount
        ? payload.tdepositAccount.mdepositDto
          ? payload.tdepositAccount.mdepositDto.depositName
          : ""
        : "";
      this.form.dueDate = payload.tdepositAccount
        ? payload.tdepositAccount.dueDate
        : "";
      var rdepositOptionDisburseDepositDto = payload.tdepositAccount
        ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
          ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
              .optionDisburseId
          : ""
        : "";
      this.form.depositTransferBankName =
        rdepositOptionDisburseDepositDto ===
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DEPOSIT_OPTION_DISBURSE_REKENING
          ? "HIKP"
          : rdepositOptionDisburseDepositDto ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DEPOSIT_OPTION_DISBURSE_TRANSFER_BANK
          ? payload.tdepositAccount.depositTransferBankName
          : rdepositOptionDisburseDepositDto ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DEPOSIT_OPTION_DISBURSE_SBB
          ? "SBB"
          : "";
      this.form.depositTransferBankAccount =
        rdepositOptionDisburseDepositDto ===
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DEPOSIT_OPTION_DISBURSE_REKENING
          ? `${payload.tdepositAccount.disburseDepositAccount}`
          : rdepositOptionDisburseDepositDto ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DEPOSIT_OPTION_DISBURSE_TRANSFER_BANK
          ? payload.tdepositAccount.depositTransferBankAccount
          : rdepositOptionDisburseDepositDto ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DEPOSIT_OPTION_DISBURSE_SBB
          ? `${payload.tdepositAccount.disburseDepositAccount}`
          : "";
      this.form.depositTransferBankAccountName =
        rdepositOptionDisburseDepositDto ===
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DEPOSIT_OPTION_DISBURSE_REKENING
          ? `${payload.tdepositAccount.disburseDepositAccountName}`
          : rdepositOptionDisburseDepositDto ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DEPOSIT_OPTION_DISBURSE_TRANSFER_BANK
          ? payload.tdepositAccount.depositTransferBankAccountName
          : rdepositOptionDisburseDepositDto ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DEPOSIT_OPTION_DISBURSE_SBB
          ? `${payload.tdepositAccount.disburseDepositAccountName}`
          : "";
      this.form.nominal = payload.tdepositAccount
        ? payload.tdepositAccount.nominal
        : 0;
      this.form.tax = payload.tdepositAccount
        ? payload.tdepositAccount.mdepositDto
          ? payload.tdepositAccount.mdepositDto.tax
          : ""
        : "";
      this.form.isRollover = payload.tdepositAccount
        ? payload.tdepositAccount.isRollover
        : "";
      this.form.depositAccountId = payload.tdepositAccount
        ? payload.tdepositAccount.depositAccountId
        : "";
      this.form.penalty = payload.tdepositAccount
        ? payload.tdepositAccount.mdepositDto
          ? payload.tdepositAccount.mdepositDto.depositBreakPenalty
          : ""
        : "";
      this.form.rdepositOptionDisburseDepositId = payload.tdepositAccount
        ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
          ? payload.tdepositAccount.rdepositOptionDisburseDepositDto
              .optionDisburseId
          : ""
        : "";
      this.form.tsavingAccountDisburseDepositAccountNumber = payload.tdepositAccount
        ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
          ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
              .accountNumber
          : ""
        : "";
      this.form.savingDepositAccountNumberName = payload.tdepositAccount
        ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
          ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto.mcif
            ? payload.tdepositAccount.tsavingAccountDisburseDepositAccountDto
                .mcif.cifIdName
            : ""
          : ""
        : "";
      this.form.depositBilyetNumber = payload.depositBilyetNumber;
      this.form.bilyetId = payload.depositBilyetId;
      this.form.description = `Pencairan Deposito An ${
        payload.tdepositAccount
          ? payload.tdepositAccount.mcifResponseDto
            ? payload.tdepositAccount.mcifResponseDto.cifAliasName
            : ""
          : ""
      }`;
    },
    submitDepositAccountDisburse(withConfirmation) {
      this.$emit("submitDepositAccountDisburse", {
        form: this.form,
        isConfirm: withConfirmation,
      });
    },
  },
  beforeMount() {},
  mounted() {
    this.checkingMainId();
  },
  destroyed() {},
};
