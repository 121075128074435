export default {
  name: "ListTLoanSp3",
  data() {
    return {
      property: {
        animation: {
          checkingCif: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingCif: false,
          showModalResultListCif: false,
        },
        listElement: {
          loanSp3: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          cif: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          cifNumber: "",
          cifIdName: "",
          sp3DocNumber: "",
        },
      },
      options: {
        office: [],
      },
      dataForm: {
        checkingCif: {
          cifIdName: "",
          officeCode: "",
          cifNumber: "",
        },
      },
      session: {
        checkingCif: {
          cifIdName: "",
          cifNumber: "",
          officeCode: "",
        },
      },
      table: {
        header: {},
        data: {
          loanSp3: [],
          cif: [],
        },
      },
    };
  },
  methods: {
    async routeToPageAddLoanAccount(props) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "transaction-loan/sp3id/" + props.row.loanSp3Id,
          endPoint: "loan",
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: "Sp3 Sudah Di Gunakan Kontrak Lain",
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$router.push(
            `/loan/loan-account/new?refId=${this.encryptBASE64(
              JSON.stringify({
                sp3Id: props.row.loanSp3Id,
              })
            )}`
          );
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async handleCheckingAvailabilityCif() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingCif.cifIdName = this.dataForm.checkingCif.cifIdName;
        this.session.checkingCif.cifNumber = this.dataForm.checkingCif.cifNumber;
        this.session.checkingCif.officeCode = this.dataForm.checkingCif.officeCode;
        this.dataForm.checkingCif.cifIdName = "";
        this.dataForm.checkingCif.cifNumber = "";
        this.dataForm.checkingCif.officeCode = "";
        const timeout = 500;
        try {
          this.property.animation.checkingCif.isLoading = true;
          this.property.listElement.cif.downloading = false;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "cif/simple-list",
            payload: {
              cifNumber: this.session.checkingCif.cifNumber,
              categoryId: "",
              officeCode: this.session.checkingCif.officeCode,
              cifIdName: this.session.checkingCif.cifIdName,
              cifMotherName: "",
              page: 0,
              size: 5,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingCif = false;
              this.property.modal.showModalResultListCif = true;
              this.table.data.cif = resp.data.data.content;
              this.property.listElement.cif.rows = resp.data.data.totalElements;
            } else if (resp.data.code === "DATA_NOT_FOUND") {
              this.dataForm.checkingCif.cifIdName = this.session.checkingCif.cifIdName;
              this.dataForm.checkingCif.cifNumber = this.session.checkingCif.cifNumber;
              this.dataForm.checkingCif.officeCode = this.session.checkingCif.officeCode;
              this.session.checkingCif.officeCode = "";
              this.session.checkingCif.cifIdName = "";
              this.session.checkingCif.cifNumber = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `Nomor CIF Tidak Tersedia!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.dataForm.checkingCif.cifIdName = this.session.checkingCif.cifIdName;
              this.dataForm.checkingCif.cifNumber = this.session.checkingCif.cifNumber;
              this.dataForm.checkingCif.officeCode = this.session.checkingCif.officeCode;
              this.session.checkingCif.officeCode = "";
              this.session.checkingCif.cifIdName = "";
              this.session.checkingCif.cifNumber = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          this.dataForm.checkingCif.cifIdName = this.session.checkingCif.cifIdName;
          this.dataForm.checkingCif.cifNumber = this.session.checkingCif.cifNumber;
          this.dataForm.checkingCif.officeCode = this.session.checkingCif.officeCode;
          this.session.checkingCif.officeCode = "";
          this.session.checkingCif.cifIdName = "";
          this.session.checkingCif.cifNumber = "";
          console.log(error.response);
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingCif.isLoading = false;
          }, timeout);
        }
      }
    },

    async handleChangePaginationCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.session.checkingCif.cifNumber,
            officeCode: this.session.checkingCif.officeCode,
            cifMotherName: "",
            categoryId: "",
            cifIdName: this.session.checkingCif.cifIdName,
            page: event - 1,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout);
      }
    },
    handleCatchErrorCif(error) {
      console.log(error.response);
      this.table.data.cif = [];
      this.property.listElement.cif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleFailedGetDataCif(resp) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.message = resp.data.message;
    },
    async findLoanSp3() {
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-sp3/simple-list",
          payload: {
            cifNumber: this.property.filterDto.cifNumber,
            cifIdName: this.property.filterDto.cifIdName,
            sp3Subject: "",
            sp3DocumentFile1: "",
            isHaveParent: false,
            sp3DocNumber: this.property.filterDto.sp3DocNumber,
            isRestruct2: false,
            isAvailSp3: false,
            page: 0,
            size: this.property.listElement.loanSp3.perPage,
          },
        });

        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanSp3 = resp.data.data.content;
            this.property.listElement.loanSp3.rows =
              resp.data.data.totalElements;
            this.property.listElement.loanSp3.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetDataLoanSp3(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanSp3(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanSp3.downloading = false;
        }, timeout + 500);
      }
    },
    handleRouteToPageAddLoanSp3(props) {
      if (props.row.statusId !== "3") {
        this.property.modal.showModalResultListCif = false;
        this.$buefy.toast.open({
          message: `Cif Belum Aktif !`,
          type: "is-danger",
          duration: 3000,
          position: "is-top",
          queue: false,
        });
        return;
      }
      this.$router.push(
        `/loan/loan-sp3/new?refId=${this.encryptBASE64(
          JSON.stringify({ cifId: props.row.cifId })
        )}`
      );
    },
    async handleDeleteDataLoanSp3(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-sp3/" + props.row.loanSp3Id,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.loanSp3.currentPage = 1;
                  this.property.filterDto.sp3DocNumber = "";
                  this.property.filterDto.cifIdName = "";
                  this.property.listElement.loanSp3.rows = 0;
                  this.helperGetDataTableLoanSp3();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleAuthorizationLoanSp3() {
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.$buefy.toast.open(this.$NotificationUtils.apiNotFound);
        },
      });
    },
    handleRouteToPageEditLoanSp3(props) {
      this.$router.push(
        `/loan/loan-sp3/${this.encryptBASE64(`${props.row.loanSp3Id}`)}`
      );
    },
    handleRouteToPageDetailLoanSp3(props) {
      this.$router.push(
        `/loan/loan-sp3/${this.encryptBASE64(`${props.row.loanSp3Id}`)}`
      );
    },
    handleCatchErrorLoanSp3(error) {
      console.log(error.response);
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.rows = 0;
      this.property.listElement.loanSp3.currentPage = 1;
      this.property.listElement.loanSp3.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangePaginationLoanSp3(event) {
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-sp3/simple-list",
          payload: {
            cifNumber: "",
            sp3Subject: "",
            sp3DocumentFile1: "",
            isHaveParent: false,
            isAvailSp3: false,
            isRestruct2: false,
            sp3DocNumber: this.property.filterDto.sp3DocNumber,
            cifIdName: this.property.filterDto.cifIdName,
            page: event - 1,
            size: this.property.listElement.loanSp3.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanSp3 = resp.data.data.content;
            this.property.listElement.loanSp3.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataLoanSp3(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanSp3(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanSp3.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableLoanSp3() {
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-sp3/simple-list",
          payload: {
            cifNumber: "",
            sp3Subject: "",
            sp3DocumentFile1: "",
            cifIdName: "",
            sp3DocNumber: "",
            isRestruct2: false,
            isHaveParent: false,
            isAvailSp3: false,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanSp3 = resp.data.data.content;
            this.property.listElement.loanSp3.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataLoanSp3(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanSp3(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanSp3.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataLoanSp3(resp) {
      this.table.data.loanSp3 = [];
      this.property.listElement.loanSp3.rows = 0;
      this.property.listElement.loanSp3.message = resp.data.message;
    },
    handleCloseModalCheckingCif() {
      this.property.modal.showModalCheckingCif = false;
      this.handleClearModalCheckingCif();
    },
    handleClearModalCheckingCif() {
      this.dataForm.checkingCif.cifNumber = "";
      this.dataForm.checkingCif.cifIdName = "";
      this.dataForm.checkingCif.officeCode = "";
    },
    handleClearModalResultCheckingCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.perPage = 5;
      this.property.listElement.cif.currentPage = 1;
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Cabang Saat Ini --" }];
          resp.data.data.content.map((data) => {
            var value = data.officeCode;
            var text = data.officeName;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.helperGetDataTableLoanSp3();
    this.getReferenceOffice();
  },
};
