var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-between flex-wrap flex-row"},[_c('div',{staticClass:"w-full flex justify-center items-start flex-col",staticStyle:{"max-width":"400px"}},[_c('div',{staticClass:"flex justify-between mb-1 flex-wrap items-center flex-row w-full",staticStyle:{"max-width":"300px"},attrs:{"inline":""}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nama Asset")]),_c('b-input',{staticStyle:{"max-width":"200px","width":"100%","font-size":"12px"},attrs:{"size":"is-small","lazy":true},on:{"input":_vm.findMasterAssetCategory},model:{value:(_vm.property.filterDto.name),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "name", $$v)},expression:"property.filterDto.name"}})],1)]),_c('div',{staticClass:"flex justify-end flex-col"},[_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",attrs:{"disabled":!_vm.$store.getters['getActionsInsert'],"label":"Aset","icon-left":"plus","size":"is-small"},on:{"click":_vm.routeToPageAddMasterAssetCategory}})],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.masterAssetCategory,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.masterAssetCategory.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
            _vm.property.listElement.masterAssetCategory.downloading === false
          )?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.masterAssetCategory.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Nama Aset"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.assetName ? props.row.assetName : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"COA"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.mchartOfAccount ? props.row.mchartOfAccount.chartOfAccountCode : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Nama Coa"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.mchartOfAccount ? props.row.mchartOfAccount.description : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"","label":"Status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.rstatus ? props.row.rstatus.statusName : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsUpdate'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.routeToPageEditMasterAssetCategory(props)}}},[_vm._v(" Rincian ")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem"},on:{"click":function($event){return _vm.deleteMasterAssetCategory(props)}}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem","disabled":!_vm.$store.getters['getActionsAuthorize'] ||
                props.row.rstatus.statusId === '3' ||
                props.row.rstatus.statusId === '2'},on:{"click":function($event){return _vm.authorizationMasterAssetCategory(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.masterAssetCategory.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.masterAssetCategory.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationMasterAssetCategory},model:{value:(_vm.property.listElement.masterAssetCategory.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.masterAssetCategory, "currentPage", $$v)},expression:"property.listElement.masterAssetCategory.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }