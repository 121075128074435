export default {
  name: "AtmChannelHistory",
  data() {
    return {
      property: {
        listElement: {
          rows: 0,
          perPage: 10,
          currentPage: 1,
          downloading: false,
          message: "",
        },
        filterDto: {
          atmChannelName: "",
          atmChannelCode: "",
          atmChannelId: "",
          dateFrom: "",
          dateTo: "",
        },
      },
      table: {
        data: {
          history: [],
        },
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/atm-channel");
    },
    getDataInformation() {
      this.property.filterDto.atmChannelId = sessionStorage.getItem(
        "ATM_CHANNEL_ID"
      );
      this.property.filterDto.atmChannelName = sessionStorage.getItem(
        "ATM_CHANNEL_CODE"
      );
      this.property.filterDto.atmChannelCode = sessionStorage.getItem(
        "ATM_CHANNEL_NAME"
      );
      this.property.filterDto.dateFrom = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.property.filterDto.dateTo = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    async getTableAtmChannelHistory() {
      this.table.data.history = [];
      this.property.listElement.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel-detail/history",
          payload: {
            atmChannelId: this.property.filterDto.atmChannelId,
            startDate: this.property.filterDto.dateFrom,
            endDate: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.history = resp.data.data.content;
            this.property.listElement.rows = resp.data.data.totalElements;
          } else {
            this.failedGetAtmChannelHistory(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannelHistory(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.downloading = false;
        }, timeout);
      }
    },
    handleErrorGetAtmChannelHistory(error) {
      console.log(error.response);
      this.table.data.history = [];
      this.property.listElement.rows = 0;
      this.property.listElement.currentPage = 1;
      this.property.listElement.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetAtmChannelHistory(resp) {
      this.table.data.history = [];
      this.property.listElement.rows = 0;
      this.property.listElement.message = resp.data.message;
    },
    async changePaginationatmChannelHistory(event) {
      this.table.data.history = [];
      this.property.listElement.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel-detail/history",
          payload: {
            atmChannelId: this.property.filterDto.atmChannelId,
            startDate: this.property.filterDto.dateFrom,
            endDate: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.history = resp.data.data.content;
            this.property.listElement.rows = resp.data.data.totalElements;
          } else {
            this.failedGetAtmChannelHistory(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannelHistory(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.downloading = false;
        }, timeout);
      }
    },
  },
  mounted() {
    this.getDataInformation();
    // this.getTableAtmChannelHistory();
  },
};
