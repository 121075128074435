export default {
  name: "DetailTLoanAccountDroppingOverbook",
  data() {
    return {
      property: {},
    };
  },
  methods: {
    handleRouteToPageListLoanAccountDroppingOverbook() {
      this.$router.push("list");
    },
  },
};
