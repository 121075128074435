import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import moment from "moment";

export default {
  name: "FormTLoanInstallmentManualPayment",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        listElement: {
          manualPaymentArrers: {
            downloading: false,
            message: "",
          },
        },
        animation: {
          addInstallmentManual: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
        modal: {
          showModalPrint: false,
        },
        money: {
          precision: 2,
          decimal: ",",
          thousands: ".",
          prefix: "",
          suffix: "",
          masked: false,
        },
      },
      identifier: {
        cetakData: "",
      },
      dataForm: {
        loanAccountId: "",
        loanAccountNumber: "",
        accountNumber: "",

        // account information
        cifIdName: "",
        officeName: "",
        accountNumberInstallment: "",
        cifIdNameInstallment: "",
        officeNameInstallment: "",
        balanceUsable: 0,
        transactionCode: "",
        documentProofNumber: "",
        dueDate: "",
        description: "",
        transactionDate: "",

        // installment information
        remainInstallment: 0,
        remainPrincipal: 0,
        remainMargin: 0,
        arrearsTotalMonth: "",
        totalMonth: 0,
        firstMonthInstallment: 0,
        installmentPaid: 0,
        totalMonthPaid: 0,
        collectability: "",

        // request
        nominalPrincipal: 0,
        nominalMargin: 0,

        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        installmentPerMonth: [],
      },
      installment: [],
      table: {
        data: {
          manualPaymentArrers: [],
        },
      },
    };
  },
  methods: {
    callBackPost(payload) {
      this.identifier.cetakData = payload;
      this.property.modal.showModalPrint = true;
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "transaction-code",
          payload: {
            name: "",
            menuAccessid: "",
            transactionCodes: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_LOAN_INSTALLMENT_MANUAL_PAYMENT,
            size: 1,
            page: 0,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.transactionCode = `${resp.data.data.content[0].transactionName}`;
          if (!this.isExisting) {
            this.$emit("getMainId", this.identity.mainId);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleDefaultInput() {
      if (!this.isExisting) {
        this.dataForm.transactionDate = this.fetchAppSession(
          "@vue-session/application"
        ).system_date;
      }
    },
    handleRouteToPageListInstallmentManual() {
      this.$router.push("/loan/installment/manual-payment");
    },
    async cetakSlip() {
      this.property.animation.cetakSlip.isLoading = true;

      let pdf = new jsPDF("landscape", "mm", [297, 210]);

      setTimeout(() => {
        html2canvas(document.querySelector("#table-slip-rak"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-slip-rak").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 21, 9);
          pdf.autoPrint();

          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-slip-rak").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          this.property.modal.showModalPrint = false;
        });
      }, 200);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.loanAccountNumber = payload.loanAccountNumber;
        this.dataForm.lateCharge = payload.lateCharge;
        this.dataForm.referenceNumber = payload.referenceNumber;
        this.dataForm.cifIdName = payload.loanCifIdName;
        this.dataForm.officeName = payload.officeNameLoanAccount;
        this.dataForm.accountNumberInstallment =
          payload.accountNumberSavingAccountInstallment;
        this.dataForm.cifIdNameInstallment =
          payload.cifIdNameSavingAccountInstallment;
        this.dataForm.documentProofNumber = payload.documentProofNumber;
        this.dataForm.transactionDate = payload.transactionDate;
        this.dataForm.transactionCode = `${payload.transactionCode} - ${payload.transactionName}`;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        this.dataForm.description = payload.description;
        this.dataForm.officeNameInstallment =
          payload.officeNameSavingAccountInstallment;
        this.dataForm.dueDate = payload.dueDate;

        this.dataForm.remainPrincipal = payload.remainPrincipal;
        this.dataForm.remainMargin = payload.remainMargin;
        this.dataForm.remainInstallment = payload.remainInstallment;
        this.dataForm.arrearsTotalMonth = payload.arrearsTotalMonth;
        this.dataForm.collectability = payload.collectabilityStatusName;

        this.dataForm.nominalPrincipal = payload.principalPaid;
        this.dataForm.nominalMargin = payload.marginPaid;
        this.dataForm.installmentPaid = payload.installmentPaid;

        return;
      }
      this.dataForm.loanAccountNumber = payload.loanAccountNumber;
      this.dataForm.cifIdName = payload.cifIdNameLoanSp3;
      this.dataForm.officeName = payload.officeNameMcifLoanSp3;
      this.dataForm.accountNumberInstallment =
        payload.accountNumberSavingAccountInstallment;
      this.dataForm.balanceUsable =
        payload.balanceUsableSavingAccountInstallment;
      this.dataForm.cifIdNameInstallment =
        payload.cifIdNameSavingAccountInstallment;
      this.dataForm.officeNameInstallment =
        payload.officeNameSavingAccountInstallment;
      // this.dataForm.description = `${this.dataForm.transactionCode} -  ${payload.cifIdNameSavingAccountInstallment} Db ${payload.accountNumberSavingAccountInstallment}`;
      this.dataForm.dueDate = payload.dueDateInstallment;
      this.dataForm.remainPrincipal = payload.remainPrincipal;
      this.dataForm.remainMargin = payload.remainMargin;
      this.dataForm.remainInstallment = payload.remainInstallment;
      this.dataForm.arrearsTotalMonth = payload.arrearsTotalMonth;
      this.dataForm.collectability = payload.collectabilityStatusName;
      this.dataForm.firstMonthInstallment = payload.firstMonthInstallment;
    },
    getDataDescription() {
      if (this.dataForm.nominalPrincipal !== 0) {
        this.dataForm.description = `Angs (P) No : ${
          this.dataForm.loanAccountNumber
        } ${moment(this.dataForm.dueDate).format("MM/YY")} ${
          this.dataForm.cifIdName
        }`;
      }
      if (this.dataForm.nominalMargin !== 0) {
        this.dataForm.description = `Angs (M) No : ${
          this.dataForm.loanAccountNumber
        } ${moment(this.dataForm.dueDate).format("MM/YY")} ${
          this.dataForm.cifIdName
        }`;
      }
      if (
        this.dataForm.nominalPrincipal !== 0 &&
        this.dataForm.nominalMargin !== 0
      ) {
        this.dataForm.description = `Angs No : ${
          this.dataForm.loanAccountNumber
        } ${moment(this.dataForm.dueDate).format("MM/YY")} ${
          this.dataForm.cifIdName
        }`;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.handleRouteToPageListInstallmentManual();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).loanAccountNumber;
      if (this.isExisting) {
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitLoanInstallmentManualPayment() {
      this.$emit("submitLoanInstallmentManualPayment", this.dataForm);
    },
    async getTableIntallmentManualPayment() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      const loanAccount =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).loanAccountNumber;
      this.table.data.manualPaymentArrers = [];
      this.property.listElement.manualPaymentArrers.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/manual-payment-arrears",
          payload: {
            loanAccountNumber: loanAccount,
            principalPay: this.dataForm.nominalPrincipal,
            marginPay: this.dataForm.nominalMargin,
          },
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.table.data.manualPaymentArrers = resp.data.data;
          this.property.listElement.manualPaymentArrers.message =
            resp.data.message;
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan`,
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.listElement.manualPaymentArrers.downloading = false;
      }
    },
  },
  mounted() {
    this.getTableIntallmentManualPayment();
    this.checkingMainId();
    this.handleDefaultInput();
    this.getReferenceTransactionCode();
  },
};
