export default {
  name: "ListTSavingAccount",
  components: {},
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      dataForm: {
        checkingCif: {
          cifNumber: "",
          officeCode: "",
          cifIdName: "",
        },
      },
      session: {
        checkingCif: {
          cifNumber: "",
          officeCode: "",
          cifIdName: "",
        },
      },
      property: {
        animation: {
          checkingCif: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        modal: {
          showModalCheckingCif: false,
          showModalResultListCif: false,
        },
        listElement: {
          savingAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          cif: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          cifIdName: "",
          dateTo: "",
          dateFrom: "",
          idCardNumber: "",
          cityName: "",
          statusAccountId: "",
          referalOfficeId: "",
          orderByBalanceEnd: false,
        },
      },
      options: {
        office: [],
        officeReferal: [],
        cabang: [],
        statusAccount: [],
      },
      table: {
        totalRekening: "",
        totalBalanceEnd: "",
        totalBalanceUsable: "",
        header: {
          savingAccount: [],
          cif: [],
        },
        data: {
          savingAccount: [],
          cif: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        accountNumber: this.property.filterDto.accountNumber,
        idCardNumber: "",
        cifIdName: this.property.filterDto.cifIdName,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        cityName: this.property.filterDto.cityName,
        orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
        referalOfficeId: this.property.filterDto.referalOfficeId,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "saving-account-simple-list/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Rekening_Tabungan.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async findSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            idCardNumber: "",
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            cityName: this.property.filterDto.cityName,
            statusAccountId: this.property.filterDto.statusAccountId,
            orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
            referalOfficeId: this.property.filterDto.referalOfficeId,
            page: 0,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.pageResponse.content;
          this.property.listElement.savingAccount.currentPage =
            resp.data.data.pageResponse.pageable.pageNumber + 1;
          this.property.listElement.savingAccount.rows =
            resp.data.data.pageResponse.totalElements;
          this.table.totalRekening = resp.data.data.totalRekening;
          this.table.totalBalanceEnd = resp.data.data.totalBalanceEnd;
          this.table.totalBalanceUsable = resp.data.data.totalBalanceUsable;
        } else {
          this.failedGetSavingAccount(resp);
          this.table.totalRekening = 0;
          this.table.totalBalanceEnd = 0;
          this.table.totalBalanceUsable = 0;
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },

    async deleteDataSavingAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/" + props.row.savingAccountId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.savingAccount.currentPage = 1;
          this.property.filterDto.cifIdName = "";
          this.property.filterDto.accountNumber = "";
          this.property.filterDto.cityName = "";
          this.property.filterDto.statusAccountId = "";
          this.property.filterDto.orderByBalanceEnd = "";
          this.property.listElement.savingAccount.rows = 0;
          this.property.filterDto.referalOfficeId = "";
          this.getSavingAccount();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    async getReferenceReferalOfficeCenter() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              officeName: "",
              page: 0,
            },
            url: "office",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.cabang = resp.data.data.content;
          this.options.cabang.unshift({
            officeId: "",
            officeName: "-- Pilih --",
          });
        }
      } catch (error) {}
    },
    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.officeReferal = resp.data.data;
          this.options.officeReferal.unshift({
            officeId: "",
            officeName: "-- Pilih --",
          });
        }
      } catch (error) {}
    },
    async authorizationSavingAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "saving-account/authorized/" + props.row.savingAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                await this.simpleWait(1000);
                this.property.listElement.savingAccount.currentPage = 1;
                this.property.filterDto.accountNumber = "";
                this.property.filterDto.cifIdName = "";
                this.property.filterDto.cityName = "";
                this.property.filterDto.statusAccountId = "";
                this.property.filterDto.orderByBalanceEnd = "";
                this.property.listElement.savingAccount.rows = 0;
                this.property.filterDto.referalOfficeId = "";
                this.getSavingAccount();
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    clearTableCheckingCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.downloading = true;
    },
    async checkingCif() {
      this.clearTableCheckingCif();
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingCif.cifNumber = this.dataForm.checkingCif.cifNumber;
        this.session.checkingCif.cifIdName = this.dataForm.checkingCif.cifIdName;
        this.session.checkingCif.officeCode = this.dataForm.checkingCif.officeCode;
        this.dataForm.checkingCif.cifNumber = "";
        this.dataForm.checkingCif.cifIdName = "";
        this.dataForm.checkingCif.officeCode = "";

        try {
          this.property.animation.checkingCif.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "cif/simple-list",
            payload: {
              cifNumber: this.session.checkingCif.cifNumber,
              categoryId: "",
              officeCode: this.session.checkingCif.officeCode,
              cifIdName: this.session.checkingCif.cifIdName,
              cifMotherName: "",
              page: 0,
              size: this.property.listElement.cif.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.property.modal.showModalCheckingCif = false;
            this.property.modal.showModalResultListCif = true;
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
          } else {
            this.dataForm.checkingCif.cifIdName = this.session.checkingCif.cifIdName;
            this.dataForm.checkingCif.cifNumber = this.session.checkingCif.cifNumber;
            this.dataForm.checkingCif.officeCode = this.session.checkingCif.officeCode;
            this.session.checkingCif.cifIdName = "";
            this.session.checkingCif.cifNumber = "";
            this.session.checkingCif.officeCode = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.dataForm.checkingCif.cifIdName = this.session.checkingCif.cifIdName;
          this.dataForm.checkingCif.cifNumber = this.session.checkingCif.cifNumber;
          this.dataForm.checkingCif.officeCode = this.session.checkingCif.officeCode;
          this.session.checkingCif.cifIdName = "";
          this.session.checkingCif.cifNumber = "";
          this.session.checkingCif.officeCode = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.checkingCif.isLoading = false;
        }
      }
    },
    handleErrorGetCif(error) {
      console.log(error.response);
      this.table.data.cif = [];
      this.property.listElement.cif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.session.checkingCif.cifNumber,
            officeCode: this.session.checkingCif.officeCode,
            cifMotherName: "",
            categoryId: "",
            cifIdName: this.session.checkingCif.cifIdName,
            page: event - 1,
            size: this.property.listElement.cif.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.cif = resp.data.data.content;
          this.property.listElement.cif.rows = resp.data.data.totalElements;
        } else {
          this.failedGetCif(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetCif(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.cif.downloading = false;
      }
    },
    failedGetCif(resp) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.message = resp.data.message;
    },

    routeToPageAddSavingAccount(props) {
      if (props.row.statusId !== "3") {
        this.property.modal.showModalResultListCif = false;
        this.$buefy.toast.open({
          message: `Cif Belum Aktif !`,
          type: "is-danger",
          duration: 3000,
          position: "is-top",
          queue: false,
        });
        return;
      }
      this.$router.push(
        `/saving/saving-account/new?refId=${this.encryptBASE64(
          JSON.stringify({
            cifId: props.row.cifId,
          })
        )}`
      );
    },
    routeToPageEditSavingAccount(props) {
      this.$router.push(
        `/saving/saving-account/${this.encryptBASE64(
          `${props.row.savingAccountId}`
        )}`
      );
    },

    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            idCardNumber: "",
            cifIdName: this.property.filterDto.cifIdName,
            cityName: this.property.filterDto.cityName,
            orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
            referalOfficeId: this.property.filterDto.referalOfficeId,
            statusAccountId: this.property.filterDto.statusAccountId,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.pageResponse.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.pageResponse.totalElements;
          this.table.totalRekening = resp.data.data.totalRekening;
          this.table.totalBalanceEnd = resp.data.data.totalBalanceEnd;
          this.table.totalBalanceUsable = resp.data.data.totalBalanceUsable;
        } else {
          this.failedGetSavingAccount(resp);
          this.table.totalRekening = 0;
          this.table.totalBalanceEnd = 0;
          this.table.totalBalanceUsable = 0;
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    async getSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/simple-list",
          payload: {
            accountNumber: "",
            idCardNumber: "",
            cifIdName: "",
            cityName: "",
            referalOfficeId: "",
            statusAccountId: "",
            orderByBalanceEnd: false,
            page: 0,
            size: this.property.listElement.savingAccount.perPage,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: "",
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.pageResponse.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.pageResponse.totalElements;
          this.table.totalRekening = resp.data.data.totalRekening;
          this.table.totalBalanceEnd = resp.data.data.totalBalanceEnd;
          this.table.totalBalanceUsable = resp.data.data.totalBalanceUsable;
        } else {
          this.failedGetSavingAccount(resp);
          this.table.totalRekening = 0;
          this.table.totalBalanceEnd = 0;
          this.table.totalBalanceUsable = 0;
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },

    clearModalResultCheckingCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.perPage = 5;
    },
    closeModalCheckingCif() {
      this.property.modal.showModalCheckingCif = false;
      this.clearModalCheckingCif();
    },
    clearModalCheckingCif() {
      this.dataForm.checkingCif.cifNumber = "";
      this.dataForm.checkingCif.cifIdName = "";
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Cabang Saat Ini --" }];
          resp.data.data.content.map((data) => {
            var value = data.officeCode;
            var text = data.officeName;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
    // async aktivasiSavingAccount(props) {
    //   if (window.navigator.onLine === false) {
    //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
    //     return;
    //   }
    //   const confirm = await this.simpleConfirmation(
    //     "Apakah Anda Yakin Ingin Mengaktifkan Kembali Rekening Ini ?",
    //     "is-success"
    //   );
    //   if (!confirm) return;
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_DATA_FIND_BY",
    //       endPoint: "transaction",
    //       reqUrl: "saving-account/reactivate",
    //       payload: {
    //         savingAccountId: props.row.savingAccountId,
    //       },
    //     });
    //     await this.simpleWait(500);
    //     if (resp.data.code === "SUCCESS") {
    //       this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
    //       await this.simpleWait(500);
    //       this.property.listElement.savingAccount.currentPage = 1;
    //       this.property.filterDto.cifIdName = "";
    //       this.property.filterDto.accountNumber = "";
    //       this.property.filterDto.cityName = "";
    //       this.property.filterDto.statusAccountId = "";
    //       this.property.filterDto.orderByBalanceEnd = "";
    //       this.property.listElement.savingAccount.rows = 0;
    //       this.property.filterDto.referalOfficeId = "";
    //       this.getSavingAccount();
    //     } else {
    //       this.$buefy.dialog.alert({
    //         title: "Gagal !",
    //         message: resp.data.errorFields
    //           ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
    //           : resp.data.message,
    //         confirmText: "Ok",
    //         type: "is-danger",
    //       });
    //     }
    //   } catch (error) {
    //     console.log(error.response);
    //     this.$buefy.dialog.alert({
    //       title: "Gagal !",
    //       message: error.response
    //         ? error.response.data.errorFields
    //           ? this.globalNotificationErrorFieldToText(
    //               error.response.data.errorFields
    //             )
    //           : error.response.statusText
    //         : "Terjadi Kesalahan",
    //       confirmText: "Ok",
    //       type: "is-danger",
    //     });
    //   }
    // },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getSavingAccount();
    this.getReferenceOffice();
    this.getReferenceOfficeReferal();
    this.getReferenceReferalOfficeCenter();
    this.getReferenceStatusAccount();
  },
};
