export default {
  name: "FormSandiBi",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          sandiBi: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        sandiBiCode: "",
        sandiBiName: "",
        sandiBiId: "",
        sandiBiLevel2Code: "",
      },
      options: {
        sandiBiLevel2: [],
      },
    };
  },
  methods: {
    getParameterSandiBiLevel2() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "sandi-bi-level2",
          params: {
            sandiBiLevel2Name: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((index) => {
              const text = `${index.sandiBiLevel2Code}- ${index.sandiBiLevel2Name}`;
              const value = index.sandiBiLevel2Code;
              this.options.sandiBiLevel2.push({ text, value });
            });
          }
        });
    },

    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.sandiBiId = payload.sandiBiId;
        this.dataForm.sandiBiCode = payload.sandiBiCode;
        this.dataForm.sandiBiName = payload.sandiBiName;
        this.dataForm.sandiBiLevel2Code =
          payload.sandiBiLevel2.sandiBiLevel2Code;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/parameter/sandi-bi/");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitSandiBi() {
      this.$emit("submitSandiBi", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getParameterSandiBiLevel2();
  },
};
