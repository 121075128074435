export default {
  name: "FormMUser",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      identifier: {
        isSuperAdmin: false,
      },
      dataForm: {
        depositAuthorizationLimit: 0,
        userInitial: "",
        userName: "",
        userPassword: "",
        batchNumber: "",
        userLimit: 0,
        effectiveDate: "",
        expiredDate: "",
        dayPasswordValid: 0,
        isAccessAccount: "",
        isActivityLog: "",
        tellerAuthorizationLimit: 0,
        loanAuthorizationLimit: 0,
        otherAuthorizationLimit: 0,
        sbbTemp: "",
        profileSurename: "",
        profileIdcardNumber: "",
        profileBirthPlace: "",
        profileBirthDate: "",
        profileAddressIdcard: "",
        profileAddress: "",
        profileNationality: "",
        profileMobilePhone: "",
        profileFixPhone: "",
        userEmail: "",
        profileBloodType: "",
        authorizedStatusId: "",
        userNik: "",
        muserRoleId: "",
        mofficeCode: "",
        mdepartmentId: "",
        reducationId: "",
        rgenderId: "",
        rmaritalStatusId: "",
        rreligionId: "",
        ruserJobtitleId: "",
        ruserStatusId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        isPasswordUpdate: false,
      },
      options: {
        office: [],
        userRole: [],
        jobTitle: [],
        chartOfAccountId: [],
        department: [],
        userStatus: [],
        religion: [],
        education: [],
        maritalStatus: [],
        gender: [],
      },
      property: {
        animation: {
          addUser: {
            isLoading: false,
          },
        },
      },
    };
  },
  methods: {
    generateRandomPassword(length) {
      const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
      const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numericChars = "0123456789";
      const symbolChars = "!@#$%^&*";

      const charset =
        lowercaseChars + uppercaseChars + numericChars + symbolChars;

      let password = "";

      // Tambahkan setidaknya satu karakter dari setiap kategori
      password +=
        lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
      password +=
        uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
      password += numericChars[Math.floor(Math.random() * numericChars.length)];
      password += symbolChars[Math.floor(Math.random() * symbolChars.length)];

      // Tambahkan karakter acak untuk panjang password yang diinginkan
      for (let i = 4; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }

      // Acak urutan karakter dalam password
      password = this.shuffleString(password);

      return password;
    },
    shuffleString(str) {
      const arr = str.split("");
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      return arr.join("");
    },
    generatePassword() {
      const passwordLength = 8;
      const randomPassword = this.generateRandomPassword(passwordLength);
      this.dataForm.userPassword = randomPassword;
    },
    helperDefaultInput() {
      this.dataForm.rreligionId = "1";
    },
    routeToPageListUser() {
      this.$router.push("/admin/user");
    },

    helperGetSuperAdminFromSession() {
      const getTokenFromLogin = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );

      this.identifier.isSuperAdmin = getTokenFromLogin.superAdmin;
    },
    helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const value = i.officeCode;
            const text = i.officeName;
            this.options.office.push({ text, value });
          });
        });
    },
    helperGetJobTitleDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_MANAGEMENT", {
          url: "user-job-title",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.userJobtitleId;
            const text = i.userJobtitleName;
            this.options.jobTitle.push({ text, value });
          });
        });
    },
    helperGetUserRoleDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          params: {
            name: "",
            page: 0,
          },
          url: "user-role",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const text = i.userRoleName;
            const value = i.userRoleId;
            this.options.userRole.push({
              text,
              value,
            });
          });
        });
    },
    helperGetDepartmentDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_MANAGEMENT", {
          url: "department",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.departmentName;
            const value = i.departmentId;
            this.options.department.push({ text, value });
          });
        });
    },
    helperGetUserStatusDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_MANAGEMENT", {
          url: "user-status",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.statusName;
            const value = i.statusId;
            this.options.userStatus.push({ text, value });
          });
        });
    },
    helperGetReligionDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_MANAGEMENT", {
          url: "religion",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.religionName;
            const value = i.religionId;
            this.options.religion.push({ text, value });
          });
        });
    },
    helperGetEducationDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_MANAGEMENT", {
          url: "education",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.educationName;
            const value = i.educationId;

            this.options.education.push({ text, value });
          });
        });
    },
    helperGetMartialStatusDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_MANAGEMENT", {
          url: "martial-status",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.maritalStatusName;
            const value = i.maritalStatusId;

            this.options.maritalStatus.push({ text, value });
          });
        });
    },
    helperGetGenderDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_MANAGEMENT", {
          url: "gender",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.genderName;
            const value = i.genderId;

            this.options.gender.push({ text, value });
          });
        });
    },
    helperChargingOptionsComponent() {
      this.options.office.push({ value: "", text: "-- Pilih --" });
      this.options.userRole.push({ value: "", text: "-- Pilih --" });
      this.options.jobTitle.push({ value: "", text: "-- Pilih --" });
      this.options.department.push({ value: "", text: "-- Pilih --" });
      this.options.userStatus.push({ value: "", text: "-- Pilih --" });
      this.options.maritalStatus.push({ value: "", text: "-- Pilih --" });
      this.options.religion.push({ value: "", text: "-- Pilih --" });
      this.options.education.push({ value: "", text: "-- Pilih --" });
      this.options.gender.push({ value: "", text: "-- Pilih --" });
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.profileMobilePhone = payload.profileMobilePhone;
        this.dataForm.tellerAuthorizationLimit =
          payload.tellerAuthorizationLimit;
        this.dataForm.depositAuthorizationLimit =
          payload.depositAuthorizationLimit;
        this.dataForm.userName = payload.userName;
        this.dataForm.userPassword = payload.userPassword;
        this.dataForm.otherAuthorizationLimit = payload.otherAuthorizationLimit;
        this.dataForm.userLimit = payload.userLimit;
        this.dataForm.expiredDate = payload.expiredDate;
        this.dataForm.dayPasswordValid = payload.dayPasswordValid
          ? payload.dayPasswordValid
          : "";
        this.dataForm.loanAuthorizationLimit = payload.loanAuthorizationLimit;
        this.dataForm.profileSurename = payload.profileSurename
          ? payload.profileSurename
          : "";
        this.dataForm.userNik = payload.userNik ? payload.userNik : "";
        this.dataForm.muserRoleId = payload.muserRoleDto
          ? payload.muserRoleDto.userRoleId
          : "";
        this.dataForm.ruserJobtitleId = payload.ruserJobtitleDto
          ? payload.ruserJobtitleDto.userJobtitleId
          : "";
        this.dataForm.isAccessAccount = payload.isAccessAccount;
        this.dataForm.isActivityLog = payload.isActivityLog;
        this.dataForm.mdepartmentId = payload.mdepartmentDto
          ? payload.mdepartmentDto.departmentId
          : "";
        this.dataForm.ruserStatusId = payload.ruserStatusDto
          ? payload.ruserStatusDto.statusId
          : "";
        this.dataForm.userCode = payload.userCode ? payload.userCode : "";
        this.dataForm.mofficeCode = payload.mofficeDto
          ? payload.mofficeDto.officeCode
          : "";
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitUser() {
      this.$emit("submitUser", this.dataForm);
    },
  },
  async mounted() {
    this.checkingMainId();
    this.helperGetSuperAdminFromSession();
    this.helperChargingOptionsComponent();
    this.helperGetOfficeDataForReference();
    this.helperGetJobTitleDataForReference();
    this.helperGetUserRoleDataForReference();
    this.helperGetDepartmentDataForReference();
    await this.helperGetReligionDataForReference();
    this.helperDefaultInput();
    this.helperGetUserStatusDataForReference();
    this.helperGetEducationDataForReference();
    this.helperGetMartialStatusDataForReference();
    this.helperGetGenderDataForReference();
  },
};
