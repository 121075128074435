export default {
  name: "FormSbr",
  props: {
    isExisting: false,
    formMessage: {
      type: Object,
      default: {},
      required: false,
    },
  },
  data() {
    return {
      property: {
        animation: {
          sbr: {
            isLoading: false,
          },
        },
      },
      identity: {
        mainId: "",
        session: "",
        mortgageRegisterNumberAkhir: "",
      },
      dataForm: {
        mortgageRegisterNumber: "",
        sequence: "",
        data: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
    };
  },
  methods: {
    async changeMortgageNumberEnd() {
      var number = 0;
      var totalBilyet = 0;
      for (var i = 0; i < this.dataForm.sequence; i++) {
        number++;
        totalBilyet =
          parseInt(this.dataForm.mortgageRegisterNumber - 1) + parseInt(number);
      }
      this.identity.mortgageRegisterNumberAkhir = totalBilyet;
    },

    handleRouteToPageSBR() {
      this.$router.push("/loan/mortgage-register-number/inventory");
    },

    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.data = payload;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitSbr() {
      this.$emit("submitSbr", this.dataForm);
    },
    getSessionStorage() {
      this.identity.session = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
    },
  },
  mounted() {
    this.checkingMainId();
    this.getSessionStorage();
  },
};
