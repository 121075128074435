import FormMUser from "../form";

export default {
  name: "ExistingMUser",
  components: {
    FormMUser,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.helperGetDataUserById();
    },
    async helperGetDataUserById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formMUserRef.routeToPageListUser();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "user-management",
            reqUrl: "v2/user/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formMUserRef.getDefaultFormValue(resp.data.data);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.$refs.formMUserRef.routeToPageListUser(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.$refs.formMUserRef.routeToPageListUser(),
          });
        }
      }
    },
    async editUser(payload) {
      const data = {
        tellerAuthorizationLimit: payload.tellerAuthorizationLimit,
        userName: payload.userName,
        userCode: payload.userCode,
        userPassword: payload.userPassword,
        otherAuthorizationLimit: payload.otherAuthorizationLimit,
        userLimit: payload.userLimit,
        expiredDate: payload.expiredDate,
        dayPasswordValid: payload.dayPasswordValid,
        loanAuthorizationLimit: payload.loanAuthorizationLimit,
        profileSurename: payload.profileSurename,
        userNik: payload.userNik,
        muserRoleId: payload.muserRoleId,
        ruserJobtitleId: payload.ruserJobtitleId,
        isAccessAccount: payload.isAccessAccount,
        isActivityLog: payload.isActivityLog,
        mdepartmentId: payload.mdepartmentId,
        ruserStatusId: payload.ruserStatusId,
        mofficeCode: payload.mofficeCode,
        depositAuthorizationLimit: payload.depositAuthorizationLimit,
        profileMobilePhone: payload.profileMobilePhone,
        isPasswordUpdate: payload.isPasswordUpdate,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formMUserRef.property.animation.addUser.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                reqUrl: "v2/user/" + this.identity.mainId,
                endPoint: "user-management",
                type: "PUT_DATA",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formMUserRef.property.animation.addUser.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
