var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-between flex-wrap flex-row"},[_c('div',{staticClass:"w-full flex justify-center items-start flex-col",staticStyle:{"max-width":"400px"}},[_c('div',{staticClass:"flex justify-between mb-1 flex-wrap items-center flex-row w-full",staticStyle:{"max-width":"340px"},attrs:{"inline":""}},[_c('span',{staticClass:"text-xs"},[_vm._v("Nomor Kontrak")]),_c('b-input',{staticStyle:{"max-width":"180px","width":"100%"},attrs:{"lazy":true,"size":"is-small"},on:{"input":_vm.handleSearchByLoanName},model:{value:(_vm.property.filterDto.accountNumber),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "accountNumber", $$v)},expression:"property.filterDto.accountNumber"}})],1)]),_c('div',{staticClass:"flex justify-end flex-col"},[_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",attrs:{"disabled":!_vm.$store.getters['getActionsInsert'],"label":"Justifikasi","icon-left":"plus","size":"is-small"},nativeOn:{"click":function($event){_vm.property.modal.showModalNoKontrak = true}}})],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.loanCollectability,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.table.pagination.loanCollectability.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.table.pagination.loanCollectability.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.table.pagination.loanCollectability.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"tloanAccount.moffice","label":"Cabang"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tloanAccount.moffice ? props.row.tloanAccount.moffice.officeName : ""))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"tloanAccount.loanAccountNumber","label":"No Kontrak"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.tloanAccount.loanAccountNumber))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"tloanAccount.tloanSp3","label":"Nama"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tloanAccount.tloanSp3.mcif.cifIdName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"mloanCollectability.rloanAkadType","label":"Produk"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.row.mloanCollectability.rloanAkadType ? props.row.mloanCollectability.rloanAkadType.loanAkadTypeName : "")+" ")])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"rbh","label":"Nominal"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tloanAccount.nominalLoan))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"weight","label":"Jatuh Tempo"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tloanAccount ? _vm.dateToMonthText(props.row.tloanAccount.endDate) : ""))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"rstatus.statusName","label":"Kolektibilitas Now"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.rstatus ? props.row.rstatus.statusName : ""))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"rstatus.statusName","label":"New Kolektibilitas"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.mloanCollectability.rloanCollectabilityStatus ? props.row.mloanCollectability.rloanCollectabilityStatus .collectabilityStatusCode : ""))])]}}])}),_c('b-table-column',{attrs:{"label":"Aksi","centered":"","header-class":"bprs-hik-theme-background","cell-class":"py-2"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem","disabled":!_vm.$store.getters['getActionsUpdate']},on:{"click":function($event){return _vm.routeToPageEditLoanCollectability(props)}}},[_vm._v("Edit")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDetail'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.handleRouteToPageDetailLoanCollectability(props)}}},[_vm._v("Detail")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"aria-role":"menuitem","disabled":!_vm.$store.getters['getActionsDelete']},on:{"click":function($event){return _vm.handleDeleteDataLoanCollectability(props)}}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":props.row.rstatus.statusId === '2' ||
                props.row.rstatus.statusId === '3' ||
                !_vm.$store.getters['getActionsAuthorize'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.handleAuthorizationLoanCollectability(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.table.pagination.loanCollectability.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.table.pagination.loanCollectability.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationLoanCollectabilityDraft},model:{value:(_vm.table.pagination.loanCollectability.currentPage),callback:function ($$v) {_vm.$set(_vm.table.pagination.loanCollectability, "currentPage", $$v)},expression:"table.pagination.loanCollectability.currentPage"}}),_c('b-modal',{attrs:{"on-cancel":_vm.handleClearInputModalCheckingLoanAccount,"can-cancel":_vm.property.animation.checkingAccount.isLoading === false,"width":400},model:{value:(_vm.property.modal.showModalNoKontrak),callback:function ($$v) {_vm.$set(_vm.property.modal, "showModalNoKontrak", $$v)},expression:"property.modal.showModalNoKontrak"}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('span',{staticClass:"text-base"},[_vm._v("Input No Kontrak")])]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"label":"Nomor Kontrak"}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.dataForm.searchLoanData.loanAccountNumber),callback:function ($$v) {_vm.$set(_vm.dataForm.searchLoanData, "loanAccountNumber", $$v)},expression:"dataForm.searchLoanData.loanAccountNumber"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"label":"Batal"},on:{"click":function($event){_vm.property.modal.showModalNoKontrak = false}}}),_c('b-button',{attrs:{"label":"Cari","loading":_vm.property.animation.checkingAccount.isLoading,"disabled":(_vm.dataForm.searchLoanData.loanAccountNumber === '' &&
              _vm.dataForm.searchLoanData.cifIdName === '') ||
              _vm.property.animation.checkingAccount.isLoading === true,"type":"is-success"},on:{"click":_vm.handleCheckingLoanAccount}})],1)])]),_c('b-modal',{attrs:{"width":700,"on-cancel":_vm.handleCloseModalResultLoanAccount},model:{value:(_vm.property.modal.showModalResultAccount),callback:function ($$v) {_vm.$set(_vm.property.modal, "showModalResultAccount", $$v)},expression:"property.modal.showModalResultAccount"}},[_c('div',{staticClass:"modal-card ",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head",staticStyle:{"border-radius":"0px"}},[_c('span',{staticClass:"text-base"},[_vm._v("Data Loan Account")])]),_c('div',{staticClass:"p-5 bg-white",staticStyle:{"width":"auto"}},[_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.listAccount,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.table.pagination.loanAccount.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.table.pagination.loanAccount.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.table.pagination.loanAccount.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Cabang","field":"moffice.officeName","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tloanAccountResponseDto.moffice ? props.row.tloanAccountResponseDto.moffice.officeName : ""))])]}}])}),_c('b-table-column',{attrs:{"label":"No. Kontrak","field":"loanAccountNumber","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tloanAccountResponseDto.loanAccountNumber ? props.row.tloanAccountResponseDto.loanAccountNumber : ""))])]}}])}),_c('b-table-column',{attrs:{"label":"Nama Nasabah","field":"tloanSp3.mcif.cifIdName","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.tloanAccountResponseDto.tloanSp3 ? props.row.tloanAccountResponseDto.tloanSp3.mcif ? props.row.tloanAccountResponseDto.tloanSp3.mcif .cifIdName : "" : ""))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Pilih","type":"is-success"},on:{"click":function($event){return _vm.handleChooseLoanAccountReschedule(props)}}})]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.table.pagination.loanAccount.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.table.pagination.loanAccount.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.handleChangePaginationLoanAccount},model:{value:(_vm.table.pagination.loanAccount.currentPage),callback:function ($$v) {_vm.$set(_vm.table.pagination.loanAccount, "currentPage", $$v)},expression:"table.pagination.loanAccount.currentPage"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }