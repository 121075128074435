export default {
  name: "FormUserRole",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      dataForm: {
        levelNo: "",
        userRoleName: "",
        print: "",
        insert: "",
        update: "",
        delete: "",
        detail: "",
        authorize: "",
        ruserLevelDto: {
          userLevelId: "",
        },
        mmenuAccess: [],
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        animation: {
          userRole: {
            isLoading: false,
          },
        },
      },
      options: {
        userLevel: [],
      },
    };
  },
  methods: {
    helperGetUserPayloadFromSession() {
      const getTokenFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );

      this.dataForm.levelNo = getTokenFromSession.levelNo;
    },
    helperChargingOptionsComponent() {
      this.options.userLevel.push({ value: "", text: "-- Pilih --" });
    },
    helperGetUserLevelDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_USER_LEVEL", {
          levelNo: this.dataForm.levelNo,
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.userLevelName;
            const value = i.userLevelId;
            this.options.userLevel.push({ text, value });
          });
        });
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.userRoleId = payload.userRoleId;
        this.dataForm.userRoleName = payload.userRoleName;
        this.dataForm.print = payload.print;
        this.dataForm.insert = payload.insert;
        this.dataForm.update = payload.update;
        this.dataForm.delete = payload.delete;
        this.dataForm.detail = payload.detail;
        this.dataForm.ruserLevelDto.userLevelId = payload.ruserLevelDto
          ? payload.ruserLevelDto.userLevelId
          : "";
        this.dataForm.authorize = payload.authorize;
        this.dataForm.mmenuAccess = payload.mmenuAccess;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    routeToPageList() {
      this.$router.push("/admin/user-role");
    },
    submitMUserRole() {
      this.$emit("submitMUserRole", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.helperGetUserPayloadFromSession();
    this.helperGetUserLevelDataForReference();
    this.helperChargingOptionsComponent();
  },
};
