export default {
  name: "ListTMultiAccount",
  data() {
    return {
      property: {
        listElement: {
          multiAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          documentProofNumber: "",
          dateFrom: "",
          dateTo: "",
          statusAccountId: "",
        },
      },
      table: {
        data: {
          multiAccount: [],
        },
      },
      options: {
        statusAccount: [],
      },
    };
  },
  methods: {
    routeToPageRincianMultiAccount(props) {
      this.$router.push(
        `/saving/multi-account/${this.encryptBASE64(
          `${props.row.multiAccountId}`
        )}`
      );
    },
    routerToPageAddMultiAccount(props) {
      this.$router.push("/saving/multi-account/new");
    },
    deleteMultiAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "t-multi-account/" + props.row.multiAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.multiAccount.currentPage = 1;
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.documentProofNumber = "";
                  this.property.filterDto.statusAccountId = "";
                  this.property.listElement.multiAccount.rows = 0;
                  this.getMultiAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getMultiAccount() {
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account",
          payload: {
            documentProofNumber: "",
            accountNumber: "",
            transactionCode: "",
            dateFrom: "",
            dateTo: "",
            statusId: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.multiAccount = resp.data.data.content;
            this.property.listElement.multiAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetMultiAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMultiAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.multiAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetMultiAccount(resp) {
      this.property.listElement.multiAccount.rows = 0;
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.message = resp.data.message;
    },
    handleErrorGetMultiAccount(error) {
      console.log(error.response);
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.rows = 0;
      this.property.listElement.multiAccount.currentPage = 1;
      this.property.listElement.multiAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async findMultiAccount() {
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account",
          payload: {
            documentProofNumber: this.property.filterDto.documentProofNumber,
            transactionCode: "",
            accountNumber: "",
            page: 0,
            size: this.property.listElement.multiAccount.perPage,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
            statusId: this.property.filterDto.statusAccountId,
          },
        });

        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.multiAccount = resp.data.data.content;
            this.property.listElement.multiAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.multiAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetMultiAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMultiAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.multiAccount.downloading = false;
        }, timeout + 500);
      }
    },
    async authorizationMultiAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "t-multi-account/authorized/" + props.row.multiAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.multiAccount.currentPage = 1;
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.documentProofNumber = "";
                  this.property.listElement.multiAccount.rows = 0;
                  this.getMultiAccount();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationMultiAccount(event) {
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account",
          payload: {
            documentProofNumber: this.property.filterDto.documentProofNumber,
            transactionCode: "",
            accountNumber: "",
            statusId: this.property.filterDto.statusAccountId,
            page: event - 1,
            size: this.property.listElement.multiAccount.perPage,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.multiAccount = resp.data.data.content;
            this.property.listElement.multiAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetMultiAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMultiAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.multiAccount.downloading = false;
        }, timeout);
      }
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getMultiAccount();
    this.getReferenceStatusAccount();
  },
};
