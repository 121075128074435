import FormMappingSandiBi from "../form";

export default {
  name: "ExistingMappingSandiBi",
  components: {
    FormMappingSandiBi,
  },
  data() {
    return {
      identity: {
        sandiBiCode: "",
        sandiBiLevel1Code: "",
        sandiBiLevel2Code: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      let splitProps = props.split("-");
      this.identity.sandiBiCode = splitProps[0];
      this.identity.sandiBiLevel1Code = splitProps[1];
      this.identity.sandiBiLevel2Code = splitProps[2];
      this.getMappingSandiBiById(this.identity);
    },

    async getMappingSandiBiById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formMappingSandiBiRef.routeToPageList();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl: "sandi-bi-code-mapping/" + props.sandiBiCode,
            payload: {
              sandiBiLevel1Code: props.sandiBiLevel1Code,
              sandiBiLevel2Code: props.sandiBiLevel2Code,
              sandiBiCode: props.sandiBiCode,
              // isDefault: props.isDefault,
            },
          });
          console.log("this is data", resp);
          if (resp.data.code === "SUCCESS") {
            this.$refs.formMappingSandiBiRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formMappingSandiBiRef.routeToPageList(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.$refs.formMappingSandiBiRef.routeToPageList(),
          });
        }
      }
    },
    async editSandiBiCodeMapping(payload) {
      const data = {
        sandiBiLevel1Code: payload.sandiBiLevel1Code,
        sandiBiLevel2Code: payload.sandiBiLevel2Code,
        sandiBiCode: payload.sandiBiCode,
        isDefault: payload.isDefault,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formMappingSandiBiRef.property.animation.MappingSandiBi.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                reqUrl: "sandi-bi-code-mapping",
                endPoint: "parameter",
                payload: data,
                params: {
                  sandiBiLevel1Code: this.$refs.formMappingSandiBiRef.dataForm
                    .sandiBiLevel1CodeSelected,
                  sandiBiLevel2Code: this.$refs.formMappingSandiBiRef.dataForm
                    .sandiBiLevel2CodeSelected,
                  sandiBiCode: this.$refs.formMappingSandiBiRef.dataForm
                    .sandiBiCodeSelected,
                  isDefault: this.$refs.formMappingSandiBiRef.dataForm
                    .isDefault,
                },
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$refs.formMappingSandiBiRef.routeToPageList();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formMappingSandiBiRef.property.animation.MappingSandiBi.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
