var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"w-full py-5 px-5"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm mb-1"},[_vm._v("Sandi Bi Level 2")]),_c('b-field',{staticStyle:{"max-width":"260px","width":"100%"},attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0] ? 'Kolom Ini Wajib Di Isi' : ''}},[_c('model-select',{staticStyle:{"width":"100%","font-size":"12px"},attrs:{"options":_vm.options.sandiBiLevel2,"placeholder":"Cari Sandi Bi Level 2"},model:{value:(_vm.dataForm.sandiBiLevel2Code),callback:function ($$v) {_vm.$set(_vm.dataForm, "sandiBiLevel2Code", $$v)},expression:"dataForm.sandiBiLevel2Code"}})],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2",staticStyle:{"max-width":"450px"}},[_c('span',{staticClass:"text-sm mb-1"},[_vm._v("Keterangan")]),_c('b-field',{staticStyle:{"max-width":"260px","width":"100%"},attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0] ? 'Kolom Ini Wajib Di Isi' : ''}},[_c('b-input',{attrs:{"type":"is-success","size":"is-small"},model:{value:(_vm.dataForm.sandiBiName),callback:function ($$v) {_vm.$set(_vm.dataForm, "sandiBiName", $$v)},expression:"dataForm.sandiBiName"}})],1)],1)]}}],null,true)}),_c('div',{staticClass:"flex mt-5 justify-center w-full"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.routeToPageList}},[_vm._v("Kembali")]),_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-success","loading":_vm.property.animation.sandiBi.isLoading,"disabled":!_vm.$store.getters['getActionsInsert'] ||
              _vm.property.animation.sandiBi.isLoading === true},on:{"click":function($event){return handleSubmit(_vm.submitSandiBi)}}},[_vm._v(" Simpan")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }