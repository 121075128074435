export default {
  name: "MSandiBiLevel2",
  data() {
    return {
      property: {
        filterDto: {
          sandiBiLevel2Name: "",
        },

        listElement: {
          sandiBiLv2: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      table: {
        data: {
          sandiBiLv2: [],
        },
      },
    };
  },
  methods: {
    routeToPageAddDayOff() {
      this.$router.push("/parameter/sandi-bi-level-2/new");
    },
    routeToPageEditDayOff(props) {
      this.$router.push(
        `/parameter/sandi-bi-level-2/${this.encryptBASE64(
          `${props.row.sandiBiLevel2Code}`
        )}`
      );
    },
    async deleteSandiBiLevel2(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "parameter",
                reqUrl: "sandi-bi-level2/" + props.row.sandiBiLevel2Code,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.sandiBiLv2.currentPage = 1;
                  this.property.filterDto.sandiBiLevel2Name = "";
                  this.property.listElement.sandiBiLv2.rows = 0;
                  this.getSandiBiLevel2();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async searchBySandiBiLevel2Name(event) {
      this.table.data.sandiBiLv2 = [];
      this.property.listElement.sandiBiLv2.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "sandi-bi-level2",
          payload: {
            sandiBiLevel2Name: event,
            page: 0,
            size: this.property.listElement.sandiBiLv2.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sandiBiLv2 = resp.data.data.content;
            this.property.listElement.sandiBiLv2.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.sandiBiLv2.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSandiBiLevel2(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSandiBiLevel2(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sandiBiLv2.downloading = false;
        }, timeout + 500);
      }
    },
    async getSandiBiLevel2() {
      this.table.data.sandiBiLv2 = [];
      this.property.listElement.sandiBiLv2.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "sandi-bi-level2",
          payload: {
            sandiBiLevel2Name: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sandiBiLv2 = resp.data.data.content;
            this.property.listElement.sandiBiLv2.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSandiBiLevel2(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSandiBiLevel2(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sandiBiLv2.downloading = false;
        }, timeout);
      }
    },
    async changePaginationSandiBiLevel2(event) {
      this.table.data.sandiBiLv2 = [];
      this.property.listElement.sandiBiLv2.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "sandi-bi-level2",
          payload: {
            sandiBiLevel2Name: this.property.filterDto.sandiBiLevel2Name,
            page: event - 1,
            size: this.property.listElement.sandiBiLv2.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sandiBiLv2 = resp.data.data.content;
            this.property.listElement.sandiBiLv2.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSandiBiLevel2(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSandiBiLevel2(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sandiBiLv2.downloading = false;
        }, timeout);
      }
    },
    handleErrorSandiBiLevel2(error) {
      console.log(error.response);
      this.table.data.sandiBiLv2 = [];
      this.property.listElement.sandiBiLv2.rows = 0;
      this.property.listElement.sandiBiLv2.currentPage = 1;
      this.property.listElement.sandiBiLv2.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataSandiBiLevel2(resp) {
      this.table.data.sandiBiLv2 = [];
      this.property.listElement.sandiBiLv2.rows = 0;
      this.property.listElement.sandiBiLv2.message = resp.data.message;
    },
  },
  mounted() {
    this.getSandiBiLevel2();
  },
};
