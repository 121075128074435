import Notification from "../../../../components/notifications";

export default {
  name: "ListTLoanAccountDroppingOverbook",
  data() {
    return {
      property: {
        animation: {
          checkingLoanAccount: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingLoanAccount: false,
          showModalResultListLoanAccount: false,
        },
        listElement: {
          loanAccountDroppingOverbook: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "Belum Integrasi",
          },
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        checkingLoanAccount: {
          accountNumber: "",
        },
      },
      session: {
        checkingLoanAccount: {
          accountNumber: "",
        },
      },
      table: {
        header: {},
        data: {
          loanAccountDroppingOverbook: [],
          loanAccount: [],
        },
      },
    };
  },
  methods: {
    handleCloseModalCheckingLoanAccount() {
      this.property.modal.showModalCheckingLoanAccount = false;
      this.handleClearModalCheckingLoanAccount();
    },
    handleClearModalCheckingLoanAccount() {
      this.dataForm.checkingLoanAccount.accountNumber = "";
    },
    handleClearModalResultCheckingLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.perPage = 5;
    },
    async handleCheckingAvailabilityLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingLoanAccount.accountNumber = this.dataForm.checkingLoanAccount.accountNumber;
        this.dataForm.checkingLoanAccount.accountNumber = "";
        try {
          this.property.animation.checkingLoanAccount.isLoading = true;
          this.property.listElement.loanAccount.downloading = false;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "loan",
            reqUrl: "transaction-loan/find-all",
            payload: {
              loanAccountNumber: this.session.checkingLoanAccount.accountNumber,
              cifIdName: "",
              page: 0,
              size: this.property.listElement.loanAccount.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingLoanAccount = false;
              this.property.modal.showModalResultListLoanAccount = true;
              this.property.listElement.loanAccount.downloading = true;
              this.table.data.loanAccount = resp.data.data.content;
              this.property.listElement.loanAccount.rows =
                resp.data.data.totalElements;
            } else if (resp.data.code === "DATA_NOT_FOUND") {
              this.dataForm.checkingLoanAccount.accountNumber = this.session.checkingLoanAccount.accountNumber;
              this.session.checkingLoanAccount.accountNumber = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `Nomor Rekening Tidak Tersedia!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.dataForm.checkingLoanAccount.accountNumber = this.session.checkingLoanAccount.accountNumber;
              this.session.checkingLoanAccount.accountNumber = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          this.dataForm.checkingLoanAccount.accountNumber = this.session.checkingLoanAccount.accountNumber;
          this.session.checkingLoanAccount.accountNumber = "";
          console.log(error.response);
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingLoanAccount.isLoading = false;
          }, timeout);
        }
      }
    },
    async handleChangePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/find-all",
          payload: {
            loanAccountNumber: this.session.checkingLoanAccount.accountNumber,
            cifIdName: "",
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.table.data.loanAccount = [];
            this.property.listElement.loanAccount.rows = 0;
            this.property.listElement.loanAccount.message = resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.property.listElement.loanAccount.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, timeout);
      }
    },
    handleRouteToPageAddLoanAccountDropping(props) {
      sessionStorage.setItem("loan-account-id", props.row.loanAccountId);
      this.$router.push("add");
    },
  },
};
