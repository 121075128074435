export default {
  name: "FormMJournalLedger",
  components: {},
  props: {
    isExisting: false,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identity: {
        mainId: "",
      },
      dataForm: {
        header: {
          linkModule: "",
          description: "",
          nominal: "",
          journalCode: "",
          statusId: "",
          referenceNumber: "",
          branchCode: "",
        },
        detail: [],
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        animation: {
          addJournalLedgerManual: {
            isLoading: false,
          },
          reverseJournalLedger: {
            isLoading: false,
          },
        },
      },
      table: {
        header: {
          journalLedgerManual: [],
        },
        data: {
          journalLedgerManual: [],
        },
      },
      options: {
        chartOfAccount: [],
        office: [],
      },
    };
  },
  computed: {
    calculateDebt() {
      if (this.table.data.journalLedgerManual.length > 0) {
        var total = 0;
        this.table.data.journalLedgerManual.map((index) => {
          const debtNominal = !index.debtNominal
            ? 0
            : parseFloat(index.debtNominal);
          total = total + debtNominal;
        });
        return total;
      } else {
        return 0;
      }
    },
    calculateCredit() {
      if (this.table.data.journalLedgerManual.length > 0) {
        var total = 0;
        this.table.data.journalLedgerManual.map((index) => {
          const creditNominal = !index.creditNominal
            ? 0
            : parseFloat(index.creditNominal);
          total = total + creditNominal;
        });
        return total;
      } else {
        return 0;
      }
    },
    matchedNominal() {
      if (this.calculateDebt === this.calculateCredit) {
        return this.calculateDebt;
      } else {
        return this.dataForm.header.nominal;
      }
    },
  },

  methods: {
    changeChartOfAccount(props) {
      this.table.data.journalLedgerManual.map((index) => {
        if (index.chartOfAccountId === props.row.chartOfAccountId) {
          const findCoa = this.options.chartOfAccount.find(
            (i) => i.value === index.chartOfAccountId
          );
          index.namaAkun = findCoa.description;
        }
      });
    },
    deleteRowJournalLedgerManual(props) {
      const filterUnselected = this.table.data.journalLedgerManual.filter(
        (index) => index.detailId !== props.row.detailId
      );
      this.table.data.journalLedgerManual = filterUnselected;
    },
    routeToPageListJournalLedger() {
      this.$router.push("/parameter/journal-ledger");
    },
    addRowJournalLedgerManual() {
      this.table.data.journalLedgerManual.push({
        detailId: Math.random()
          .toString(36)
          .substr(2, 9),
        chartOfAccountId: "",
        description: "",
        debtNominal: 0,
        creditNominal: 0,
        namaAkun: "",
      });
    },
    async reserveJournalLedger() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      if (this.validationDomain==='pojok.bprshikp.co.id') {
      this.$buefy.dialog.confirm({
        message: "Reverse Data Ini ?",
        type: "is-info",
        onConfirm: async () => {
          this.property.animation.reverseJournalLedger.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA",
              endPoint: "transaction",
              reqUrl: "reversal/" + this.dataForm.header.referenceNumber,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.reverseJournalLedger.isLoading = false;
            }, 1500);
          }
        },
      });
    } else {
      this.$buefy.dialog.confirm({
        message: "Reverse Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.property.animation.reverseJournalLedger.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA",
              endPoint: "transaction",
              reqUrl: "reversal/" + this.dataForm.header.referenceNumber,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.reverseJournalLedger.isLoading = false;
            }, 1500);
          }
        },
      });
    }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const value = index.chartOfAccountId;
            const removeSpaceInSubsidaryLedgerNumber = `${index.chartOfAccountCode}`.trim();
            const description = `${index.description}`.trim();
            const text = `${removeSpaceInSubsidaryLedgerNumber} - ${description}`;
            this.options.chartOfAccount.push({ text, value, description });
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.header.linkModule = payload.header.linkModule;
        this.dataForm.header.description = payload.header.description;
        this.dataForm.header.nominal = payload.header.nominal;
        this.dataForm.header.journalDate = payload.header.journalDate;
        this.dataForm.header.journalCode = payload.header.journalCode;
        this.dataForm.header.referenceNumber = payload.header.referenceNumber;
        this.dataForm.header.statusId = payload.header.rstatus?.statusId;
        this.dataForm.header.branchCode = payload.header.moffice?.officeCode;
        payload.detail.map((index) => {
          const debtNominal = !index.debtNominal ? 0 : index.debtNominal;
          const creditNominal = !index.creditNominal ? 0 : index.creditNominal;
          const description = index.description;
          const chartOfAccountId = index.mchartOfAccount.chartOfAccountId;
          const namaAkun = index.mchartOfAccount.description;
          const detailId = Math.random()
            .toString(36)
            .substr(2, 9);
          this.table.data.journalLedgerManual.push({
            debtNominal,
            creditNominal,
            description,
            chartOfAccountId,
            namaAkun,
            detailId,
          });
        });
        this.dataForm.createdByUserCode = payload.header.createdByUserCode;
        this.dataForm.createdByUserName = payload.header.createdByUserName;
        this.dataForm.createdDate = payload.header.createdDate;
        this.dataForm.updatedDate = payload.header.updatedDate;
        this.dataForm.updatedByUserCode = payload.header.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.header.updatedByUserName;
        this.dataForm.authorizedDate = payload.header.authorizedDate;
        this.dataForm.authorizedByUserCode =
          payload.header.authorizedByUserCode;
        this.dataForm.authorizedByUserName =
          payload.header.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitJournalLedger() {
      this.$emit("submitJournalLedger", this.dataForm);
    },
    async getRefenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.office.push({ text, value });
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    setDefaultBranchCodeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.dataForm.header.branchCode = getUserDataFromSession.officeCode;
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.setDefaultBranchCodeValue();
    this.getRefenceOffice();
    this.checkingMainId();
    this.getReferenceChartOfAccount();
  },
};
