export default {
    name: "SavingAccountReactivate",
    components: {},
    computed: {
      officeCodeSession() {
        let getSession = JSON.parse(
          this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
        ).officeCode;
        return getSession;
      },
    },
    data() {
      return {
        dataForm: {
          checkingCif: {
            cifNumber: "",
            officeCode: "",
            cifIdName: "",
          },
        },
        session: {
          checkingCif: {
            cifNumber: "",
            officeCode: "",
            cifIdName: "",
          },
        },
        property: {
          animation: {
            checkingCif: {
              isLoading: false,
            },
            isDownloadingFile: false,
          },
          modal: {
            showModalCheckingCif: false,
            showModalResultListCif: false,
          },
          listElement: {
            savingAccount: {
              rows: 0,
              perPage: 10,
              currentPage: 1,
              downloading: false,
              message: "",
            },
            cif: {
              rows: 0,
              perPage: 5,
              currentPage: 1,
              downloading: false,
              message: "",
            },
          },
          filterDto: {
            accountNumber: "",
            cifIdName: "",
            dateTo: "",
            dateFrom: "",
            idCardNumber: "",
            cityName: "",
            statusAccountId: "",
            referalOfficeId: "",
            orderByBalanceEnd: false,
          },
        },
        options: {
          office: [],
          officeReferal: [],
          cabang: [],
          statusAccount: [],
        },
        table: {
          totalRekening: "",
          totalBalanceEnd: "",
          totalBalanceUsable: "",
          header: {
            savingAccount: [],
            cif: [],
          },
          data: {
            savingAccount: [],
            cif: [],
          },
        },
      };
    },
    methods: {
      async findSavingAccount() {
        this.table.data.savingAccount = [];
        this.property.listElement.savingAccount.downloading = true;
  
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/reactivate/simple-list",
            payload: {
              accountNumber: this.property.filterDto.accountNumber,
              cifIdName: this.property.filterDto.cifIdName,
              statusAccountId: 2,
              orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.pageResponse.content;
            this.property.listElement.savingAccount.currentPage =
              resp.data.data.pageResponse.pageable.pageNumber + 1;
            this.property.listElement.savingAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.totalRekening = resp.data.data.totalRekening;
            this.table.totalBalanceEnd = resp.data.data.totalBalanceEnd;
            this.table.totalBalanceUsable = resp.data.data.totalBalanceUsable;
          } else {
            this.failedGetSavingAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalBalanceEnd = 0;
            this.table.totalBalanceUsable = 0;
          }
        } catch (error) {
          await this.simpleWait(500);
          this.handleErrorGetSavingAccount(error);
        } finally {
          await this.simpleWait(500);
          this.property.listElement.savingAccount.downloading = false;
        }
      },
      // async authorizationSavingAccount(props) {
      //   if (window.navigator.onLine === false) {
      //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      //     return;
      //   } else {
      //     this.$buefy.dialog.confirm({
      //       message: "Otorisasi Data Ini ?",
      //       type: "is-success",
      //       onConfirm: async () => {
      //         try {
      //           const resp = await this.$store.dispatch({
      //             type: "PUT_DATA_AUTHORIZED",
      //             endPoint: "transaction",
      //             reqUrl:
      //               "saving-account/authorized/" + props.row.savingAccountId,
      //           });
      //           if (resp.data.code === "SUCCESS") {
      //             this.$buefy.toast.open(this.$NotificationUtils.success);
      //             await this.simpleWait(1000);
      //             this.property.listElement.savingAccount.currentPage = 1;
      //             this.property.filterDto.accountNumber = "";
      //             this.property.filterDto.cifIdName = "";
      //             this.property.filterDto.cityName = "";
      //             this.property.filterDto.statusAccountId = "";
      //             this.property.filterDto.orderByBalanceEnd = "";
      //             this.property.listElement.savingAccount.rows = 0;
      //             this.property.filterDto.referalOfficeId = "";
      //             this.getSavingAccount();
      //           } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
      //             this.$buefy.dialog.alert({
      //               title: "Harap Tunggu !",
      //               message: resp.data.errorFields
      //                 ? this.globalNotificationErrorFieldToText(
      //                     resp.data.errorFields
      //                   )
      //                 : resp.data.message,
      //               confirmText: "Ok",
      //               type: "is-danger",
      //             });
      //           } else {
      //             this.$buefy.dialog.alert({
      //               title: "Gagal !",
      //               message: resp.data.errorFields
      //                 ? this.globalNotificationErrorFieldToText(
      //                     resp.data.errorFields
      //                   )
      //                 : resp.data.message,
      //               confirmText: "Ok",
      //               type: "is-danger",
      //             });
      //           }
      //         } catch (error) {
      //           console.log(error.response);
      //           this.$buefy.dialog.alert({
      //             title: "Gagal !",
      //             message: error.response
      //               ? error.response.data.errorFields
      //                 ? this.globalNotificationErrorFieldToText(
      //                     error.response.data.errorFields
      //                   )
      //                 : error.response.statusText
      //               : "Terjadi Kesalahan",
      //             confirmText: "Ok",
      //             type: "is-danger",
      //           });
      //         }
      //       },
      //     });
      //   }
      // },
      async aktivasiSavingAccount(props) {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          "Apakah Anda Yakin Ingin Mengaktifkan Kembali Rekening Ini ?",
          "is-success"
        );
        if (!confirm) return;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving-account/reactivate",
            payload: {
              savingAccountId: props.row.savingAccountId,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
            await this.simpleWait(500);
            this.property.listElement.savingAccount.currentPage = 1;
            this.property.filterDto.cifIdName = "";
            this.property.filterDto.accountNumber = "";
            this.property.filterDto.cityName = "";
            this.property.filterDto.statusAccountId = "";
            this.property.filterDto.orderByBalanceEnd = "";
            this.property.listElement.savingAccount.rows = 0;
            this.property.filterDto.referalOfficeId = "";
            this.getSavingAccount();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      },
      handleErrorGetSavingAccount(error) {
        console.log(error.response);
        this.table.data.savingAccount = [];
        this.property.listElement.savingAccount.rows = 0;
        this.property.listElement.savingAccount.currentPage = 1;
        this.property.listElement.savingAccount.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      },
      async changePaginationSavingAccount(event) {
        this.table.data.savingAccount = [];
        this.property.listElement.savingAccount.downloading = true;
  
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/reactivate/simple-list",
            payload: {
              accountNumber: this.property.filterDto.accountNumber,
              cifIdName: this.property.filterDto.cifIdName,
              orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
              page: event - 1,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.pageResponse.content;
            this.property.listElement.savingAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.totalRekening = resp.data.data.totalRekening;
            this.table.totalBalanceEnd = resp.data.data.totalBalanceEnd;
            this.table.totalBalanceUsable = resp.data.data.totalBalanceUsable;
          } else {
            this.failedGetSavingAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalBalanceEnd = 0;
            this.table.totalBalanceUsable = 0;
          }
        } catch (error) {
          await this.simpleWait(500);
          this.handleErrorGetSavingAccount(error);
        } finally {
          await this.simpleWait(500);
          this.property.listElement.savingAccount.downloading = false;
        }
      },
      async getSavingAccount() {
        this.table.data.savingAccount = [];
        this.property.listElement.savingAccount.downloading = true;
  
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/reactivate/simple-list",
            payload: {
              accountNumber: this.property.filterDto.accountNumber,
              cifIdName: "",
              statusAccountId: 2,
              orderByBalanceEnd: false,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            console.log("Ini response =>", resp);
            this.table.data.savingAccount = resp.data.data.pageResponse.content;
            this.property.listElement.savingAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.totalRekening = resp.data.data.totalRekening;
            this.table.totalBalanceEnd = resp.data.data.totalBalanceEnd;
            this.table.totalBalanceUsable = resp.data.data.totalBalanceUsable;
          } else {
            this.failedGetSavingAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalBalanceEnd = 0;
            this.table.totalBalanceUsable = 0;
          }
        } catch (error) {
          await this.simpleWait(500);
          this.handleErrorGetSavingAccount(error);
        } finally {
          await this.simpleWait(500);
          this.property.listElement.savingAccount.downloading = false;
        }
      },
      failedGetSavingAccount(resp) {
        this.table.data.savingAccount = [];
        this.property.listElement.savingAccount.rows = 0;
        this.property.listElement.savingAccount.message = resp.data.message;
      },
      async getReferenceStatusAccount() {
        try {
          const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
            url: "status",
          });
          if (resp.data.code === "SUCCESS") {
            // console.log("Ini response statusAccount =>", resp);
            const data = resp.data.data.slice(0, 2);
            this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
            data.map((index) => {
              const text = index.statusName;
              const value = index.statusId;
              this.options.statusAccount.push({ text, value });
            });
          }
        } catch (error) {}
      },
    },
    mounted() {
      this.getSavingAccount();
      this.getReferenceStatusAccount();
    },
  };
  