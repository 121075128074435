export default {
  name: "ListTInventoryAmortisasi",
  data() {
    return {
      isShow: false,
      property: {
        listElement: {
          inventoryAmortisasi: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          inventoryNumber: "",
          kelompokBarang: "",
        },
      },
      table: {
        footer: {
          totalAkumulasiPenyusutan: 0,
          totalBookValue: 0,
          totalInstallment: 0,
          totalInventoryPrice: 0,
          inventoryCategoryName: "",
        },
        header: {},
        data: {
          inventoryAmortisasi: [],
        },
      },
      options: {
        inventoryCategory: [],
      },
    };
  },
  methods: {
    failedGetinventoryAmortisasi(resp) {
      this.property.listElement.inventoryAmortisasi.rows = 0;
      this.table.data.inventoryAmortisasi = [];
      this.property.listElement.inventoryAmortisasi.message = resp.data.message;
    },
    handleErrorGetinventoryAmortisasi(error) {
      console.log(error.response);
      this.table.data.inventoryAmortisasi = [];
      this.property.listElement.inventoryAmortisasi.rows = 0;
      this.property.listElement.inventoryAmortisasi.currentPage = 1;
      this.property.listElement.inventoryAmortisasi.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getReferenceInventoryCategory() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "asset/list",
          payload: {
            fixedAsset: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.inventoryCategory = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((i) => {
            const text = i.assetName;
            const value = i.assetId;
            this.options.inventoryCategory.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getInventoryAmortisasi() {
      this.table.data.inventoryAmortisasi = [];
      this.property.listElement.inventoryAmortisasi.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inventory-amortitation/simple-list",
          payload: {
            inventoryNumber: "",
            assetId: "",
            fixedAsset: true,
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.inventoryAmortisasi =
              resp.data.data.pageResponse.content;
            this.table.footer.totalAkumulasiPenyusutan =
              resp.data.data.totalAkumulasiPenyusutan;
            this.table.footer.totalBookValue = resp.data.data.totalBookValue;
            this.table.footer.totalInstallment =
              resp.data.data.totalInstallment;
            this.table.footer.totalInventoryPrice =
              resp.data.data.totalInventoryPrice;

            this.property.listElement.inventoryAmortisasi.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetinventoryAmortisasi(resp);
            this.resetTotal();
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetinventoryAmortisasi(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.inventoryAmortisasi.downloading = false;
        }, timeout);
      }
    },
    async searchInventoryAmortisasi() {
      this.table.data.inventoryAmortisasi = [];
      this.property.listElement.inventoryAmortisasi.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inventory-amortitation/simple-list",
          payload: {
            inventoryNumber: this.property.filterDto.inventoryNumber,
            assetId: this.property.filterDto.kelompokBarang,
            fixedAsset: true,
            page: 0,
            size: this.property.listElement.inventoryAmortisasi.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.inventoryAmortisasi =
              resp.data.data.pageResponse.content;
            this.table.footer.totalAkumulasiPenyusutan =
              resp.data.data.totalAkumulasiPenyusutan;
            this.table.footer.totalBookValue = resp.data.data.totalBookValue;
            this.table.footer.totalInstallment =
              resp.data.data.totalInstallment;
            this.table.footer.totalInventoryPrice =
              resp.data.data.totalInventoryPrice;
            this.property.listElement.inventoryAmortisasi.rows =
              resp.data.data.pageResponse.totalElements;
            this.property.listElement.inventoryAmortisasi.currentPage =
              resp.data.data.pageResponse.pageable.pageNumber + 1;
          } else {
            this.failedGetinventoryAmortisasi(resp);
            this.resetTotal();
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetinventoryAmortisasi(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.inventoryAmortisasi.downloading = false;
        }, timeout);
      }
    },
    async changePaginationinventoryAmortisasi(event) {
      this.table.data.inventoryAmortisasi = [];
      this.property.listElement.inventoryAmortisasi.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inventory-amortitation/simple-list",
          payload: {
            inventoryNumber: this.property.filterDto.inventoryNumber,
            assetId: this.property.filterDto.kelompokBarang,
            fixedAsset: true,
            page: event - 1,
            size: this.property.listElement.inventoryAmortisasi.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.inventoryAmortisasi =
              resp.data.data.pageResponse.content;
            this.table.footer.totalAkumulasiPenyusutan =
              resp.data.data.totalAkumulasiPenyusutan;
            this.table.footer.totalBookValue = resp.data.data.totalBookValue;
            this.table.footer.totalInstallment =
              resp.data.data.totalInstallment;
            this.table.footer.totalInventoryPrice =
              resp.data.data.totalInventoryPrice;
            this.property.listElement.inventoryAmortisasi.rows =
              resp.data.data.pageResponse.totalElements;
            this.property.listElement.inventoryAmortisasi.currentPage =
              resp.data.data.pageResponse.pageable.pageNumber + 1;
          } else {
            this.failedGetinventoryAmortisasi(resp);
            this.resetTotal();
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetinventoryAmortisasi(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.inventoryAmortisasi.downloading = false;
        }, timeout);
      }
    },
    resetTotal() {
      this.table.footer.totalAkumulasiPenyusutan = 0;
      this.table.footer.totalBookValue = 0;
      this.table.footer.totalInstallment = 0;
      this.table.footer.totalInventoryPrice = 0;
    },
    async authorizationInventoryAmortisasi(props) {
      console.log(props)
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "inventory-amortitation/authorized/" +
                  props.row.inventoryAmortitationId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.inventoryAmortisasi.currentPage = 1;
                  this.property.filterDto.inventoryNumber = "";
                  this.property.filterDto.kelompokBarang = "";
                  this.property.listElement.inventoryAmortisasi.rows = 0;
                  this.getInventoryAmortisasi();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routerToPageAddInventoryAmortisasi() {
      this.$router.push("/inventory-amortisasi/new");
    },
    routeToPageRincianInventoryAmortisasi(props) {
      this.$router.push(
        `/inventory-amortisasi/${this.encryptBASE64(
          `${props.row.inventoryAmortitationId}`
        )}`
      );
    },
    deleteInventoryAmortisasi(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "inventory-amortitation/" + props.row.inventoryAmortitationId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                this.property.listElement.inventoryAmortisasi.currentPage = 1;
                this.property.filterDto.inventoryNumber = "";
                this.property.filterDto.kelompokBarang = "";
                this.property.listElement.inventoryAmortisasi.rows = 0;
                this.getInventoryAmortisasi();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getInventoryAmortisasi();
    this.getReferenceInventoryCategory();
  },
};
