import Moment from "moment";

export default {
  name: "FormMInterbankLiability",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        accountBranch: "",
        accountNumber: "",
        accountName: "",
        biCode: "",
        bankName: "",
        interbankLiabilityTypeId: "",
        tenorYear: 0,
        tenorMonth: 0,
        tenorDay: 0,
        interestRate: 0,
        balance: 0,
        isActive: true,
        openDate: "",
        closeDate: "",
        dueDate: "",
        chartOfAccountId: "",
        accountBranch: "",
        interbankAssetsId: "",
        plafond: 0,
        installmentDeb: 0,
        feeChartOfAccountId: "",
        tenor: 1,
        chartOfAccountDescription: "",
        interbankAssetsId: "",
        interbankAssetsName: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        maxValue: {
          JatuhTempo: "",
        },
        animation: {
          submitLoading: false,
        },
      },
      options: {
        kodeBank: [],
        kodeCoa: [],
        jenisABP: [],
        nomorAba: [],
        tenor: [
          { text: "Harian", value: 1 },
          { text: "Bulanan", value: 2 },
          { text: "Tahunan", value: 3 },
        ],
      },
    };
  },
  methods: {
    calculateJangkaWaktuDay() {
      if (this.form.openDate !== "") {
        var day = this.form.tenorDay;
        console.log(new Date(this.form.openDate));
        var calculate = Moment(new Date(this.form.openDate))
          .add(day, "days")
          .format();
        this.form.closeDate = calculate;
      } else {
        return;
      }
    },
    calculateJangkaWaktuMonth() {
      if (this.form.openDate !== "") {
        var month = this.form.tenorMonth;
        var calculate = Moment(new Date(this.form.openDate))
          .add(month, "month")
          .format();
        this.form.closeDate = calculate;
      }
    },
    calculateJangkaWaktuYear() {
      if (this.form.openDate !== "") {
        var year = this.form.tenorYear;
        var calculate = Moment(new Date(this.form.openDate))
          .add(year, "year")
          .format();
        this.form.closeDate = calculate;
      }
    },
    changeOpenDate() {
      this.form.tenor = 1;
      this.changeTenor();
    },
    changeJatuhTempo() {
      if (this.form.openDate !== "") {
        var endDate = Moment(this.form.closeDate);
        var startDate = Moment(this.form.openDate);
        console.log(endDate, startDate);
        switch (this.form.tenor) {
          case 1:
            this.form.tenorDay = endDate.diff(startDate, "days") + 1;
            break;
          case 2:
            this.form.tenorMonth = endDate.diff(startDate, "months") + 1;
            break;
          case 3:
            this.form.tenorYear = endDate.diff(startDate, "years") + 1;
            break;
          default:
            return;
            break;
        }
      }
    },
    changeTenor() {
      this.form.tenorYear = 0;
      this.form.tenorDay = 0;
      this.form.tenorMonth = 0;
      this.form.closeDate = "";
      var periodeType = 0;
      if (this.form.openDate !== "") {
        switch (this.form.tenor) {
          case 1:
            periodeType = 1;
            break;

          case 2:
            periodeType = 31;
            break;

          case 3:
            periodeType = 365;
            break;
          default:
            break;
        }
        var calculate = new Date(
          new Date(this.form.openDate).getTime() +
            periodeType * 24 * 60 * 60 * 1000
        ).toISOString();
        this.property.maxValue.JatuhTempo = calculate;
      }
    },
    changeKodeAkun() {
      this.form.chartOfAccountDescription = "";
      this.options.kodeCoa.map((index) => {
        if (index.value === this.form.chartOfAccountId) {
          this.form.chartOfAccountDescription = index.description;
        }
      });
    },
    routeToPageListInterbankLiability() {
      this.$router.push("/interbank-liability");
    },
    async getReferenceInterbankLiabilityType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "interbank-liability-type",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.jenisABP = [{ text: "-- Pilih --", value: "" }];
          this.form.interbankLiabilityTypeId = this.fetchAppSession(
            "@vue-session/config-bundle"
          ).VUE_APP_M_ABP_DEFAULT_ABP_TYPE;
          resp.data.data.map((index) => {
            const value = index.interbankLiabilityTypeId;
            const text = index.interbankLiabilityTypeName;
            this.options.jenisABP.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              page: 0,
              description: "",
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.kodeCoa = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((index) => {
            const value = index.chartOfAccountId;
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const description = index.description;
            this.options.kodeCoa.push({ text, value, description });
          });
        }
      } catch (error) {}
    },
    async getReferenceInterbankAssets() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if ((resp.data.code = "SUCCESS")) {
          this.options.nomorAba = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.interbankAssetsId;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = index.accountName;
            const accountBranch = `${index.bankName} - Cabang ${index.accountBranch}`;
            this.options.nomorAba.push({
              text,
              value,
              accountName,
              accountBranch,
            });
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.accountBranch = payload.accountBranch;
        this.form.accountNumber = payload.accountNumber;
        this.form.accountName = payload.accountName;
        this.form.biCode = payload.biCode;
        this.form.bankName = payload.bankName;
        this.form.tenor =
          payload.tenorDay !== 0
            ? 1
            : payload.tenorMonth !== 0
            ? 2
            : payload.tenorYear !== 0
            ? 3
            : 0;
        this.form.interbankLiabilityTypeId = payload.rinterbankLiabilityType
          ? payload.rinterbankLiabilityType.interbankLiabilityTypeId
          : "";
        this.form.tenorDay = payload.tenorDay;
        this.form.tenorMonth = payload.tenorMonth;
        this.form.tenorYear = payload.tenorYear;
        this.form.interestRate = payload.interestRate;
        this.form.balance = payload.balance;
        this.form.isActive = payload.isActive;
        this.form.openDate = payload.openDate;
        this.form.closeDate = payload.closeDate;
        this.form.chartOfAccountId = payload.mchartOfAccount
          ? payload.mchartOfAccount.chartOfAccountId
          : "";
        this.form.chartOfAccountDescription = payload.mchartOfAccount
          ? payload.mchartOfAccount.description
          : "";
        this.form.interbankAssetsId = payload.minterbankAssets
          ? payload.minterbankAssets.interbankAssetsId
          : "";
        this.form.interbankAssetsName = payload.minterbankAssets
          ? payload.minterbankAssets.accountName
          : "";
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    changeNomorAba() {
      this.options.nomorAba.map((i) => {
        if (i.value === this.form.interbankAssetsId) {
          this.form.interbankAssetsName = i.accountName;
        }
      });
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    sumbitInterbankLiability() {
      this.$emit("sumbitInterbankLiability", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceInterbankLiabilityType();
    this.getReferenceChartOfAccount();
    this.getReferenceInterbankAssets();
  },
};
