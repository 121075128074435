export default {
  name: "BackupRestore",
  data() {
    return {
      dataForm: {
        officeName: "",
        date: "",
      },
    };
  },
  methods: {
    getData() {
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );

      this.dataForm.officeName = getDataFromSession.officeName;
      this.dataForm.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.getData();
  },
};
