export default {
  name: "FormTDepositAccountDetailFundAllocated",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },

      form: {
        accountNumber: "",
        cifIdName: "",
        officeName: "",
        nominal: 0,
        documentProofNumber: "",
        description: "",
        depositAccountId: "",
        transactionCodeId: "",
        transactionDate: "",
        valutaDate: "",
        transactionCode: "",
        referenceNumber: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
          officeName: "",
        },
        depositFundAllocated: {
          nominal: 0,
          documentProofNumber: "",
          description: "",
          depositAccountId: "",
          transactionCodeId: "",
          transactionDate: "",
          vautaDate: "",
          transactionCode: "",
        },
      },
      options: {
        kodeTransaksi: [],
      },
    };
  },
  methods: {
    handleRouteToPageListDepositFundAllocated() {
      this.$router.push("/deposit/fund-allocated");
    },
    handleChangeTransactionCode() {
      this.form.depositFundAllocated.transactionCode = "";
      this.options.kodeTransaksi.map((i) => {
        if (i.value === this.form.depositFundAllocated.transactionCodeId) {
          this.form.depositFundAllocated.transactionCode = i.transactionCode;
        }
      });
    },
    helperDefaultDateNow() {
      this.form.depositFundAllocated.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.form.depositFundAllocated.vautaDate = new Date().toISOString();
    },

    helperGetTransactionCodeForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            name: "",
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_DEPOSIT_FUND_ALLOCATION,
            page: 0,
          },
          url: "transaction-code",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const value = i.transactionCodeId;
            const text = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.kodeTransaksi.push({ text, value, transactionCode });
          });
        });
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.handleRouteToPageListDepositFundAllocated();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).depositAccountId;
      this.$emit("getMainId", this.identity.mainId);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.depositAccountId = payload.tdepositAccount
          ? payload.tdepositAccount.depositAccountId
          : "";
        this.form.accountNumber = payload.tdepositAccount
          ? payload.tdepositAccount.accountNumber
          : "";
        this.form.cifIdName = payload.tdepositAccount
          ? payload.tdepositAccount.mcifResponseDto
            ? payload.tdepositAccount.qqName
              ? `${payload.tdepositAccount.mcifResponseDto.cifIdName} QQ ${payload.tdepositAccount.qqName}`
              : payload.tdepositAccount.mcifResponseDto.cifIdName
            : ""
          : "";
        this.form.officeName = payload.moffice
          ? payload.moffice.officeName
          : "";
        this.form.nominal = payload.nominal;
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.description = payload.description;
        this.form.transactionCodeId = payload.mtransactionCode
          ? payload.mtransactionCode.transactionName
          : "";
        this.form.transactionDate = payload.transactionDate;
        this.form.valutaDate = payload.valutaDate;
        this.form.transactionCode = payload.mtransactionCode
          ? payload.mtransactionCode.transactionCode
          : "";
        this.form.referenceNumber = payload.referenceNumber;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.form.depositAccount.accountNumber = payload.accountNumber;
      this.form.depositFundAllocated.nominal = payload.nominal;
      this.form.depositAccount.cifIdName = payload.mcifResponseDto
        ? payload.qqName
          ? `${payload.mcifResponseDto.cifIdName} QQ ${payload.qqName}`
          : payload.mcifResponseDto.cifIdName
        : "";
      this.form.depositAccount.officeName = payload.mcifResponseDto
        ? payload.mcifResponseDto.officeId
          ? payload.mcifResponseDto.officeId.officeName
          : ""
        : "";
    },
    submitfundAllocated() {
      this.$emit("submitfundAllocated", this.form);
    },
  },
  beforeMount() {},
  mounted() {
    this.checkingMainId();
    this.helperDefaultDateNow();
    this.helperGetTransactionCodeForReference();
  },
  destroyed() {},
};
