const MUser = {
  actions: {
    GET_M_USER_BY_ROLE_ID({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user/by-role/${payload.roleId}`,
            method: "GET",
            params: payload.params,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};

export default MUser;
