export default {
  name: "ListTSavingAccountDetailReversal",
  data() {
    return {
      property: {
        listElement: {
          reversalSavingAccountDetail: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          name: "",
          dateTo: "",
          dateFrom: "",
        },
      },

      table: {
        data: {
          reversalSavingAccountDetail: [],
        },
      },
    };
  },
  methods: {
    async searchByAccountNumberOnReversalSavingAccountDetail(event) {
      this.table.data.reversalSavingAccountDetail = [];
      this.property.listElement.reversalSavingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/reversal",
          payload: {
            accountNumber: event,
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            name: this.property.filterDto.name,
            page: 0,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            size: this.property.listElement.reversalSavingAccountDetail.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.reversalSavingAccountDetail = resp.data.data.content;
          this.property.listElement.reversalSavingAccountDetail.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataReversalSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorReversalSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.reversalSavingAccountDetail.downloading = false;
      }
    },

    async changeDateFromSavingAccountDetailReversal(event) {
      this.table.data.reversalSavingAccountDetail = [];
      this.property.listElement.reversalSavingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          accountNumber: this.property.filterDto.accountNumber,
          transactionCode: `${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_CLOSE_TAB
          },${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
          },${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
          }`,
          name: this.property.filterDto.name,
          page: 0,
          size: this.property.listElement.reversalSavingAccountDetail.perPage,
          dateFrom: event,
          dateTo: this.property.filterDto.dateTo,
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.reversalSavingAccountDetail = resp.data.data.content;
          this.property.listElement.reversalSavingAccountDetail.currentPage =
            resp.data.data.pageable.pageNumber + 1;
          this.property.listElement.reversalSavingAccountDetail.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataReversalSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorReversalSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.reversalSavingAccountDetail.downloading = false;
      }
    },
    handleFailedGetDataReversalSavingAccountDetail(resp) {
      this.table.data.reversalSavingAccountDetail = [];
      this.property.listElement.reversalSavingAccountDetail.rows = 0;
      this.property.listElement.reversalSavingAccountDetail.message =
        resp.data.message;
    },
    async changeDateToSavingAccountDetailReversal(event) {
      this.table.data.reversalSavingAccountDetail = [];
      this.property.listElement.reversalSavingAccountDetail.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/reversal",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            name: this.property.filterDto.name,
            page: 0,
            size: this.property.listElement.reversalSavingAccountDetail.perPage,
            dateTo: event,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.reversalSavingAccountDetail = resp.data.data.content;
          this.property.listElement.reversalSavingAccountDetail.currentPage =
            resp.data.data.pageable.pageNumber + 1;
          this.property.listElement.reversalSavingAccountDetail.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataReversalSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorReversalSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.reversalSavingAccountDetail.downloading = false;
      }
    },

    async authorizationReversalSavingAccountDetail(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/reversal/authorized/" +
            props.row.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.reversalSavingAccountDetail.currentPage = 1;
          this.property.filterDto.accountNumber = "";
          this.property.filterDto.name = "";
          this.property.listElement.reversalSavingAccountDetail.rows = 0;
          this.helperGetDataTableReversalSavingAccountDetail();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handleCatchErrorReversalSavingAccountDetail(error) {
      console.log(error.response);
      this.table.data.reversalSavingAccountDetail = [];
      this.property.listElement.reversalSavingAccountDetail.rows = 0;
      this.property.listElement.reversalSavingAccountDetail.currentPage = 1;
      this.property.listElement.reversalSavingAccountDetail.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationReversalSavingAccountDetail(event) {
      this.table.data.reversalSavingAccountDetail = [];
      this.property.listElement.reversalSavingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/reversal",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            name: this.property.filterDto.name,
            page: event - 1,
            size: this.property.listElement.reversalSavingAccountDetail.perPage,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
          },
        });

        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.reversalSavingAccountDetail = resp.data.data.content;
          this.property.listElement.reversalSavingAccountDetail.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataReversalSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorReversalSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.reversalSavingAccountDetail.downloading = false;
      }
    },

    async helperGetDataTableReversalSavingAccountDetail() {
      this.table.data.reversalSavingAccountDetail = [];
      this.property.listElement.reversalSavingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/reversal",
          payload: {
            accountNumber: "",
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            name: "",
            page: 0,
            size: this.property.listElement.reversalSavingAccountDetail.perPage,
            dateTo: "",
            dateFrom: "",
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.reversalSavingAccountDetail = resp.data.data.content;
          this.property.listElement.reversalSavingAccountDetail.rows =
            resp.data.data.totalElements;
        } else {
          this.handleFailedGetDataReversalSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorReversalSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.reversalSavingAccountDetail.downloading = false;
      }
    },
    routeToPageDetailSavingAccountReversal(props) {
      this.$router.push(
        `/saving/reversal/${this.encryptBASE64(
          `${props.row.savingAccountDetailId}`
        )}`
      );
    },
  },
  mounted() {
    this.helperGetDataTableReversalSavingAccountDetail();
  },
};
