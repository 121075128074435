export default {
  name: "TAtmChannelReturn",
  data() {
    return {
      form: {
        transactionCodeId: "",
        atmChannelId: "",
        nominal: 0,
      },
      property: {
        animation: {
          isLoading: false,
        },
      },
      options: {
        listAtm: [],
        transactionCode: [],
        chartOfAccount: [],
      },
    };
  },
  methods: {
    resetFormAtmChannel() {
      (this.form.atmChannelId = ""),
        (this.form.transactionCodeId = ""),
        (this.form.nominal = 0);
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        console.log(resp)
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountCode;
            this.options.chartOfAccount.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async prosesAtmChannel() {
      const payload = {
        atmChannelId: this.form.atmChannelId,
        transactionCodeId: this.form.transactionCodeId,
        chartOfAccountCode: this.form.chartOfAccountCode,
        nominal: this.form.nominal,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Proses Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.property.animation.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "POST_DATA",
              endPoint: "transaction",
              reqUrl: "atm-channel-detail",
              payload: payload,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                const toastBuefy = this.$buefy.toast.open(
                  this.$NotificationUtils.success
                );
                toastBuefy.$on("close", () => {
                  this.resetFormAtmChannel();
                });
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.isLoading = false;
            }, 1000);
          }
        },
      });
    },
    async getReferenceListAtm() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel/list-by-office",
        });
        console.log("atm", resp);
        resp.data.data.map((i) => {
          const text = `${i.atmChannelName}`;
          const value = i.atmChannelId;
          this.options.listAtm.push({
            text,
            value,
          });
        });
      } catch {}
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "transaction-code",
            params: {
              name: "",
              page: 0,
              menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_MENU_ACCESS_ATM_CHANNEL_RETURN,
            },
          }
        );
        resp.data.data.content.map((i) => {
          const text = `${i.transactionCode} - ${i.transactionName}`;
          const value = i.transactionCodeId;
          const description = i.transactionName;
          const transactionCode = i.transactionCode;
          this.options.transactionCode.push({
            text,
            value,
            description,
            transactionCode,
          });
        });
      } catch (error) {}
    },
  },
  mounted() {
    this.getReferenceChartOfAccount();
    this.getReferenceListAtm();
    this.getReferenceTransactionCode();
  },
};
