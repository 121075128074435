import FormGeneratePinAtm from "../form";

export default {
  name: "CreateGeneratePinAtm",
  components: { FormGeneratePinAtm },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    async addSavingCard(payload) {
      const data = {
        savingAccountId: payload.savingAccountId,
        cardNumber: payload.cardNumber,
        cardPin: payload.cardPin,
        cardCvv: payload.cardCvv,
        cardPassword: payload.cardPassword,
        cardTypeId: payload.cardTypeId,
        description: payload.description,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formGeneratePinAtmRef.property.animation.generatePinAtm.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "saving-card",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$refs.formGeneratePinAtmRef.routeToPageListLoanGeneratePinAtm();
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formGeneratePinAtmRef.property.animation.generatePinAtm.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    getMainId(props) {
      this.identity.mainId = props;
      this.getSavingAccountId();
    },
    async getSavingAccountId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formGeneratePinAtmRef.routeToPageListLoanGeneratePinAtm();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$refs.formGeneratePinAtmRef.getDefaultFormValue(resp.data.data);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formGeneratePinAtmRef.routeToPageListLoanGeneratePinAtm(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () =>
            this.$refs.formGeneratePinAtmRef.routeToPageListLoanGeneratePinAtm(),
        });
      }
    },
  },
};
