export default {
  name: "FormUmb",
  props: {
    isExisting: false,
  },
  data() {
    return {
      dataForm: {
        bagian: "",
        kodeUmb: "",
        namaUMb: "",
        tanggalRealisasi: "",
        tanggalPengembalian: "",
        nominal: "",
        keterangan: "",
      },
      options: {
        bagian: [],
      },
      property: {
        animation: {
          umb: {
            isLoading: false,
          },
        },
      },
    };
  },
};
