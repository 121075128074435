export default {
  name: "WadiahSavingBonusByProduct",
  data() {
    return {
      property: {
        animation: {
          sumbitLoading: false,
        },
        listElement: {
          WadiahSaving: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        selectAll: false,
        documentProofNumber: "",
        validateProcess: false,
        savingBonus: [],
        periode: "",
        sbbBonus: 0,
        sbbPajak: 0,
        sbbZakat: 0,
        isSelected: [],
        totalNominalBonus: 0,
        totalNominalTax: 0,
        totalNominalZakat: 0,
      },
      table: {
        data: {
          WadiahSaving: [],
        },
      },
    };
  },
  methods: {
    next() {
      this.$router.push("/wadiah-saving-bonus/by-office");
    },
    async getSystemDate() {
      this.dataForm.periode = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    failedGetDataWadiahSavingBonus(resp) {
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.rows = 0;
      this.property.listElement.WadiahSaving.message = resp.data.message;
    },
    handleErrorGetWadiahSavingBonus(error) {
      console.log(error.response);
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.rows = 0;
      this.property.listElement.WadiahSaving.currentPage = 1;
      this.property.listElement.WadiahSaving.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getTableWadiahSavingBonus() {
      this.table.data.WadiahSaving = [];
      this.property.listElement.WadiahSaving.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "wadiah-saving-bonus/by-product",
        });

        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.WadiahSaving =
              resp.data.data.twadiahBonusSavingResponses;
            this.property.listElement.WadiahSaving.rows =
              resp.data.totalElements;

            this.dataForm.totalNominalBonus = resp.data.data.totalNominalBonus;
            this.dataForm.totalNominalTax = resp.data.data.totalNominalTax;
            this.dataForm.totalNominalZakat = resp.data.data.totalNominalZakat;
          } else {
            this.failedGetDataWadiahSavingBonus(resp);
            this.dataForm.totalNominalBonus = 0;
            this.dataForm.totalNominalTax = 0;
            this.dataForm.totalNominalZakat = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetWadiahSavingBonus(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.WadiahSaving.downloading = false;
        }, timeout);
      }
    },
    processWadiahSaving() {
      const data = {
        requestList: [],
      };
      data.requestList = [];
      this.table.data.WadiahSaving.map((index) => {
        const period = index.period;
        const productCode = index.productCode;
        const rate = index.rate;
        const isSelected = index.isSelected;
        data.requestList.push({
          period,
          productCode,
          rate,
          isSelected,
        });
      });
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.listElement.WadiahSaving.downloading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "wadiah-saving-bonus",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.dataForm.totalNominalBonus =
                      resp.data.data.totalNominalBonus;
                    this.dataForm.totalNominalTax =
                      resp.data.data.totalNominalTax;
                    this.dataForm.totalNominalZakat =
                      resp.data.data.totalNominalZakat;
                  });
                  this.getTableWadiahSavingBonus();
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.listElement.WadiahSaving.downloading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getTableWadiahSavingBonus();
    this.getSystemDate();
  },
};
