export default {
  name: "ListMJournalTemplate",
  components: {},
  data() {
    return {
      property: {
        listElement: {
          journalTemplate: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          journalName: "",
        },
      },
      table: {
        data: {
          journalTemplate: [],
        },
      },
    };
  },
  methods: {
    async searchByJournalNameJournalTemplate(event) {
      this.table.data.journalTemplate = [];
      this.property.listElement.journalTemplate.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "m-journal-template",
          payload: {
            journalName: event,
            page: 0,
            size: this.property.listElement.journalTemplate.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.journalTemplate = resp.data.data.content;
            this.property.listElement.journalTemplate.rows =
              resp.data.data.totalElements;
            this.property.listElement.journalTemplate.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataJournalTemplate(resp);
          }
        }, timeout);
      } catch (error) {
        this.handleCatchErrorJournalTemplate(error);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalTemplate.downloading = false;
        }, timeout + 500);
      }
    },
    failedGetDataJournalTemplate(resp) {
      this.table.data.journalTemplate = [];
      this.property.listElement.journalTemplate.rows = 0;
      this.property.listElement.journalTemplate.message = resp.data.message;
    },
    async deleteDataJurnalTemplate(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "parameter",
                reqUrl: "m-journal-template/" + props.row.journalTemplateId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.journalTemplate.currentPage = 1;
                  this.property.filterDto.journalName = "";
                  this.property.listElement.journalTemplate.rows = 0;
                  this.helperGetDataTableJournalTemplate();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageAddJurnalTemplate() {
      this.$router.push("/parameter/journal-template/new");
    },
    routeToPageEditJurnalTemplate(props) {
      this.$router.push(
        `/parameter/journal-template/${this.encryptBASE64(
          `${props.row.journalTemplateId}`
        )}`
      );
    },

    authorizationJurnalTemplate() {
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.$buefy.toast.open(this.$NotificationUtils.apiNotFound);
        },
      });
    },
    handleCatchErrorJournalTemplate(error) {
      console.log(error.response);
      this.table.data.journalTemplate = [];
      this.property.listElement.journalTemplate.rows = 0;
      this.property.listElement.journalTemplate.currentPage = 1;
      this.property.listElement.journalTemplate.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationJurnalTemplate(event) {
      this.table.data.journalTemplate = [];
      this.property.listElement.journalTemplate.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "m-journal-template",
          payload: {
            journalName: this.property.filterDto.journalName,
            page: event - 1,
            size: this.property.listElement.journalTemplate.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.journalTemplate = resp.data.data.content;
            this.property.listElement.journalTemplate.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataJournalTemplate(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorJournalTemplate(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalTemplate.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableJournalTemplate() {
      this.table.data.journalTemplate = [];
      this.property.listElement.journalTemplate.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "m-journal-template",
          payload: {
            journalName: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.journalTemplate = resp.data.data.content;
            this.property.listElement.journalTemplate.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataJournalTemplate(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorJournalTemplate(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalTemplate.downloading = false;
        }, timeout);
      }
    },
  },
  mounted() {
    this.helperGetDataTableJournalTemplate();
  },
};
