import html2pdf from "html2pdf.js";

export default {
  name: "FormTSavingAccount",
  components: {},
  props: {
    isExisting: "",
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      property: {
        animation: {
          submitLoading: false,
          modal: {
            showModalPrintSaving: false,
          },
        },
      },
      identity: {
        mainId: "",
      },
      form: {
        referalOfficeId: "",
        isAccountNumberNice: "",
        accountNumber: "",
        profitShareAddPercent: "",
        profitShareTotalPercent: "",
        openDate: "",
        permonthWithdrawFreq: "",
        permonthFixedDeposit: "",
        permonthTimePeriode: "",
        dueDate: "",
        isTaxable: "",
        taxRestitutionNumber: "",
        isZakat: false,
        qqName: "",
        qqCode: "",
        nonCashDepositFreq: "",
        nonCashWithdrawFreq: "",
        depositFreq: "",
        withdrawFreq: "",
        maximumTransaction: "",
        isSmsNotification: false,
        limitSmsTransaction: "",
        collectorUserId: "",
        accountOfficerHandleId: "",
        accountOfficerPromoId: "",
        statusAccountId: "",
        holdNominal: "",
        holdDate: "",
        balanceBegin: "",
        balanceEnd: "",
        balanceUsable: "",
        transactionDate: "",
        mutationCredit: "",
        mutationDebt: "",
        savingChartOfAccountId: "",
        chartOfAccountCode: "",
        blockedDate: "",
        savingId: "",
        bondedFinanceGroupId: "",
        sourceFundId: "",
        purposeOpenAccountId: "",
        accountClassificationId: "",
        deliveryStatementId: "",
        categoryId: "",
        cifIdName: "",
        cifNumber: "",
        cifPhoneNumber: "",
        cifIdAddress: "",
        appreciateTypeName: "",
        profitSharePercent: "",
        isForEdc: false,
        edcId: "",
        akadTypeName: "",
        cifId: "",
        newAccountNumber: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",

        /* print */
        savingName: "",
        sourceFundName: "",
        purposeOpenAccountName: "",
        accountClassificationName: "",
        deliveryStatementName: "",
        referalOfficeName: "",
        accountOfficerPromoName: "",
        accountOfficerHandleName: "",
      }, 
      options: {
        referalOffice: [],
        produkTabungan: [],
        aoPromosi: [],
        aoHandling: [],
        sumberDana: [],
        tujuanBukaRekening: [],
        klasifikasiRekening: [],
        penyampaianStatement: [],
        edcList: [],
        qq: [],
      },
    };
  },
  methods: {
    resetQQRadio() {
      this.form.qqCode = "";
      this.form.qqName = "";
    },
    // calculateNisbahTotal() {
    //   this.form.profitShareTotalPercent =
    //     this.form.profitShareAddPercent + this.form.profitSharePercent;
    // },

    routeToPageListSavingAccount() {
      this.$router.push("/saving/saving-account");
    },
    changeProdukTabungan() {
      this.options.produkTabungan.map((i) => {
        if (i.value === this.form.savingId) {
          this.form.appreciateTypeName = i.jenisApresiasi;
          this.form.profitSharePercent = i.nisbah;
          this.form.akadTypeName = i.akadTypeName;
        }
      });
    },
    async getReferenceSaving() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_TRANSACTION",
          {
            url: "saving/simple-list",
            params: {
              name: "",
              page: 0,
            },
          }
        );
        console.log('produk tab => ', resp)
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.savingId;
            const text = i.savingName;
            const jenisApresiasi = i.rsavingAppreciateType.appreciateTypeName;
            const nisbah = i.profitSharePercent;
            const akadTypeName = i.akadTypeName;
            this.options.produkTabungan.push({
              text,
              value,
              jenisApresiasi,
              nisbah,
              akadTypeName,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceAccountOfficer() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        console.log("AO => ", resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.aoPromosi.push({ text, value });
            this.options.aoHandling.push({ text, value });
          });
        }
      } catch (error) {}
    },

    async getReferenceSourceFund() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "source-fund",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.sourceFundName;
            const value = i.sourceFundId;
            this.options.sumberDana.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferencePurposeOpenAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "purpose-open-account",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.purposeOpenAccountId;
            const text = i.purposeOpenAccountName;
            this.options.tujuanBukaRekening.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceAccountClassification() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "account-classification",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.accountClassificationId;
            const text = i.accountClassificationName;
            this.options.klasifikasiRekening.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceDeliveryStatement() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "delivery-statement",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.deliveryStatementId;
            const text = i.deliveryStatementName;
            this.options.penyampaianStatement.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getEdcList() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "edc/active-data",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.edcName}`;
            const value = index.edcId;
            this.options.edcList.push({ text, value });
          });
        } else {
          this.options.edcList = [];
        }
      } catch (error) {}
    },
    async changeIsForEdc() {
      await this.simpleWait(500);
      if (this.form.isForEdc == false) {
        this.form.edcId = "";
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListDepositDisburse();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).cifId;
      this.$emit("getMainId", this.identity.mainId);
    },
    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.referalOffice = resp.data.data;
          this.options.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form = {
          ...payload,
          newAccountNumber: payload.accountNumber.slice(5, 10),
        };

        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        this.form.referalOfficeId = payload.referalOfficeId;
        this.form.akadTypeName = payload.akadTypeName;
        this.getReferenceQQ(payload);
        return;
      }

      this.form.categoryId = payload.categoryId
        ? payload.categoryId.cifCategoryName
        : "";
      this.identity.mainId = payload.cifId;
      this.form.cifNumber = payload.cifNumber;
      this.form.cifIdName = payload.cifIdName;
      this.form.cifPhoneNumber = payload.cifMobilePhoneNumber;
      this.form.cifIdAddress = payload.cifIdAddress;
      this.getReferenceQQ(payload);
    },
    submitSavingAccount() {
      this.$emit("submitSavingAccount", this.form);
    },
    async getReferenceQQ(payload) {
      console.log(payload);
      if (!this.isExisting) {
        if (payload.categoryId.cifCategoryId === "1") {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-family/cif-id/" + payload.cifId,
            });
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.cifFamilyName}`;
                const value = index.cifFamilyName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        } else if (
          payload.categoryId.cifCategoryId === "4" ||
          payload.categoryId.cifCategoryId === "3"
        ) {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-company-management/cif-id/" + payload.cifId,
            });
            console.log(resp);
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.managementName}`;
                const value = index.managementName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        }
      } else {
        if (payload.categoryId === "1") {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-family/cif-id/" + payload.cifId,
            });
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.cifFamilyName}`;
                const value = index.cifFamilyName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        } else if (payload.categoryId === "4" || payload.categoryId === "3") {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-company-management/cif-id/" + payload.cifId,
            });
            console.log(resp);
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.managementName}`;
                const value = index.managementName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        }
      }
    },
    
    /* print */
    downloadPdf() {
      this.property.animation.modal.showModalPrintSaving = true;
    },
    closePreview() {
      this.property.animation.modal.showModalPrintSaving = false;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        marginLeft: 0.2,
        marginRight: 0.2,
        marginBottom: 0,
        marginTop: 0,
        filename: `Tabungan_${this.form.cifIdName}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      html2pdf()
        .set(opt)
        .from(element)
        .save();

      // New Promise-based usage:
      // html2pdf()
      //   .from(element)
      //   .set(opt)
      //   .toPdf()
      //   .get("pdf")
      //   .then((pdf) => {
      //     pdf.autoPrint();
      //     const hiddFrame = document.createElement("iframe");
      //     const isSafari = /^((?!chrome|android).)*safari/i.test(
      //       window.navigator.userAgent
      //     );
      //     if (isSafari) {
      //       // fallback in safari
      //       hiddFrame.onload = () => {
      //         try {
      //           hiddFrame.contentWindow.document.execCommand(
      //             "print",
      //             false,
      //             null
      //           );
      //         } catch (e) {
      //           hiddFrame.contentWindow.print();
      //         }
      //       };
      //     }
      //     hiddFrame.src = pdf.output("bloburl");
      //     document.body.appendChild(hiddFrame);
      //   });
    },
  },
  beforeMount() {},
  mounted() {
    this.validationDomain = window.location.hostname;
    this.checkingMainId();
    this.getReferenceSaving();
    this.getReferenceAccountOfficer();
    this.getEdcList();
    this.getReferenceSourceFund();
    this.getReferencePurposeOpenAccount();
    this.getReferenceAccountClassification();
    this.getReferenceDeliveryStatement();
    this.getReferenceOfficeReferal();
  },
  destroyed() {},
};
