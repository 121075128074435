import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "FormTBalanceCash",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      form: {
        userId: "",
        userName: "",
        description: "",
        currentBalanceNominal: "",
        totalCash: "",
        tanggal: "",
        transactionCode: "",
        balanceMutation: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        kodeTransaksi: [],
        user: [],
      },
    };
  },
  methods: {
    async onChangeTransactionCode() {
      this.form.balanceMutation = 0;
      this.form.totalCash = 0;
      this.options.kodeTransaksi.map((index) => {
        if (index.value === this.form.transactionCode) {
          this.form.description = index.transactionName;
        }
      });
    },
    onChangeBalanceMutation() {
      if (
        this.form.transactionCode ==
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_TRX_CODE_PENGEMBALIAN_KAS_TELLER ||
        this.form.transactionCode ==
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_TRX_CODE_PENGISIAN_KAS_ANTAR_TELLER ||
        this.form.transactionCode ==
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_TRX_CODE_PENGEMBALIAN_KAS_ANTAR_TELLER
      ) {
        this.form.totalCash =
          this.form.currentBalanceNominal - this.form.balanceMutation;
      } else if (
        this.form.transactionCode ==
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_TRX_CODE_PENGISIAN_KAS_TELLER
      ) {
        this.form.totalCash =
          this.form.balanceMutation + this.form.currentBalanceNominal;
      }
    },
    routeToPageListBalanceCash() {
      this.$router.push("/balance-cash");
    },
    getReferenceTransactionCode() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            name: "",
            page: 0,
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_BALANCE_CASH,
          },
          url: "transaction-code",
        })
        .then((response) => {
          this.options.kodeTransaksi = [{ text: "-- Pilih --", value: "" }];
          response.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCode;
            const transactionName = i.transactionName;
            this.options.kodeTransaksi.push({ text, value, transactionName });
          });
        });
    },
    async getCurrentBalanceNominal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          reqUrl: "balance-cash/current-balance-nominal",
          endPoint: "transaction",
        });
        if (resp.data.code === "SUCCESS") {
          this.form.currentBalanceNominal = resp.data.data;
        }
      } catch (error) {
        console.log(error.response);
      }
    },
    validateJumlahKas() {
      if (this.form.jumlahKas === "0,00") {
        return "This Field is required";
      }
      return true;
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.userName = payload.user ? payload.user.userName : "";
        this.form.description = payload.description;
        this.form.transactionCode = payload.transactionCode
          ? payload.transactionCode.transactionCode
          : "";
        this.form.balanceMutation = payload.balanceMutation;
        this.form.userId = payload.user?.userId;
        this.form.tanggal = payload.createdDate;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        return;
      }
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.form.userName = getUserDataFromSession.userName;
      this.form.tanggal = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    sumbitBalanceCash() {
      this.$emit("sumbitBalanceCash", this.form);
    },
    async getReferenceUser() {
      this.options.user = [{ text: "-- Pilih --", value: "" }];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "user/tellers",
          endPoint: "parameter",
          payload: {
            isIncluded: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.userCode} - ${index.userName}`;
            const value = index.userId;
            this.options.user.push({ text, value });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceUser();
    this.getReferenceTransactionCode();
    this.getCurrentBalanceNominal();
  },
};
