import FormTLoanInstallmentCorrection from "../form";

export default {
  name: "ExistingTLoanInstallmentCorrection",
  components: {
    FormTLoanInstallmentCorrection,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    editLoanPaymentReturn(payload) {
      const data = {
        loanInstallmentId: payload.loanInstallmentId,
        transactionCodeId: payload.transactionCodeId,
        reasonReturn: payload.reasonReturn,
        docProofNumber: payload.docProofNumber,
        description: payload.description,
        loanAccountId: payload.loanAccountId,
      };
      console.log(data);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Eidt Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formTLoanInstallmentCorrectionRef.property.animation.addLoanInstallmentPaymentReturn.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "loan-payment-return/" + this.identity.mainId,
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formTLoanInstallmentCorrectionRef.property.animation.addLoanInstallmentPaymentReturn.isLoading = false;
              }, 1000);
            }
          },
        });
        // }
      }
    },
    getMainId(props) {
      this.identity.mainId = props;
      this.getLoanPaymentReturnById();
    },
    async getLoanPaymentReturnById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTLoanInstallmentCorrectionRef.routeToPageInstallmentCorrection();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl: "loan-payment-return/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$refs.formTLoanInstallmentCorrectionRef.getDefaultFormValue(
            resp.data.data
          );
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTLoanInstallmentCorrectionRef.routeToPageInstallmentCorrection(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () =>
            this.$refs.formTLoanInstallmentCorrectionRef.routeToPageInstallmentCorrection(),
        });
      }
    },
  },
};
