const Login = () => import("@/views/pages/login/Login");
const TheContainer = () => import("@/containers/the-container/TheContainer");

const ListMCif = () => import("@/views/transactions/m-cif/list/");
const CreateCif = () => import("@/views/transactions/m-cif/create/");
const ExistingCif = () => import("@/views/transactions/m-cif/existing/");

const HistoryLogUserSign = () =>
  import("@/views/user-managements/history-log-user-sign/");

const OfficeStatus = () => import("@/views/user-managements/office-status");

const ListMSaving = () => import("@/views/transactions/m-saving/list/");
const AddMSaving = () => import("@/views/transactions/m-saving/add/");
const EditMSaving = () => import("@/views/transactions/m-saving/edit/");
const DetailMSaving = () => import("@/views/transactions/m-saving/detail/");

const ListMDeposit = () => import("@/views/transactions/m-deposit/list/");
const AddMDeposit = () => import("@/views/transactions/m-deposit/add/");
const EditMDeposit = () => import("@/views/transactions/m-deposit/edit/");
const DetailMDeposit = () => import("@/views/transactions/m-deposit/detail/");

const ListMLoan = () => import("@/views/loans/m-loan/list/");
const AddMLoan = () => import("@/views/loans/m-loan/add/");
const EditMLoan = () => import("@/views/loans/m-loan/edit/");
const DetailMLoan = () => import("@/views/loans/m-loan/detail/");

const MDepositBilyetInventory = () =>
  import("@/views/transactions/m-deposit-bilyet-inventory/");

const TSavingAccountDetailBookTransaction = () =>
  import("@/views/transactions/t-saving-account-detail-book-transaction/");

const TSavingAccountBalanceInquiry = () =>
  import("@/views/transactions/t-saving-account-balance-inquiry/");

const TSavingAccountDetailAccountStatement = () =>
  import("@/views/transactions/t-saving-account-detail-account-statement/");

const MUserRoleMappingMenuAccess = () =>
  import("@/views/user-managements/m-user-role-mapping-menu-access/");

const ListTSavingBlock = () =>
  import("@/views/transactions/t-saving-block/list/");
const ProcessTSavingBlock = () =>
  import("@/views/transactions/t-saving-block/process/");

const ListTLoanAccount = () => import("@/views/loans/t-loan-account/list");
const AddTLoanAccount = () => import("@/views/loans/t-loan-account/add");
const EditTLoanAccount = () => import("@/views/loans/t-loan-account/edit");
const ListTLoanAccountRecondition = () =>
  import("@/views/loans/t-loan-account-recondition/list");
const TLoanInstallmentSimulation = () =>
  import("@/views/loans/t-loan-installment-simulation");

const LandingPage = () => import("@/views/landing/LandingPage");
const Page404 = () => import("@/views/pages/page404/Page404");
const PageUnaccess = () => import("@/views/pages/unaccess/PageUnaccess");

const TSavingAccountGenerateHeaderBook = () =>
  import("@/views/transactions/t-saving-account-generate-header-book/");

const TDepositAccountDetailAccountStatement = () =>
  import("@/views/transactions/t-deposit-account-detail-account-statement");

const ReversalJournalLedgerManual = () =>
  import("@/views/parameters/m-journal-ledger/reversal");

const TInterbankAssetsDetailAccountStatement = () =>
  import("@/views/transactions/t-interbank-assets-detail-account-statement");

const TInterbankLiabilityDetailAccountStatement = () =>
  import("@/views/transactions/t-interbank-liability-detail-account-statement");

const TOperationalProcess = () =>
  import("@/views/user-managements/t-operational-process");
const TOperationalProcessClosing = () =>
  import("@/views/user-managements/t-operational-process-closing");
const TOperationalProcessList = () =>
  import("@/views/user-managements/t-operational-process-list");
const ClosingEodCentralize = () =>
  import("@/views/user-managements/closing-eod-centralize");
const ClosingEomCentralize = () =>
  import("@/views/user-managements/closing-eom-centralize");

const LabaRugi = () => import("@/views/reports/laba-rugi");

const ReportRahn = () => import("@/views/reports/report-rahn");

const GenerateEstimateCollectability = () =>
  import("@/views/reports/generate-estimate-collectability");
const GenerateReportCollectability = () =>
  import("@/views/reports/generate-report-collectability");

const NominatifPembiayaan = () =>
  import("@/views/reports/nominatif-pembiayaan");

const NominatifLoanAccount = () =>
  import("@/views/reports/nominatif-loan-account");

const NominatifSavingAccountByProduct = () =>
  import("@/views/reports/nominatif-saving-account/product");

const NominatifSavingAccountByAccountNumber = () =>
  import("@/views/reports/nominatif-saving-account/account-number/list");

const DenominatifPembiayaan = () =>
  import("@/views/reports/denominatif-pembiayaan");
const CifPortofolio = () => import("@/views/reports/cif-portofolio");
const EstimateCollectability = () =>
  import("@/views/reports/estimate-collectability");
const LoanInstallmentPaymentHistory = () =>
  import("@/views/reports/loan-installment-payment-history");
const Neraca = () => import("@/views/reports/neraca");
const PrintOutFpr = () => import("@/views/reports/print-out-fpr");
const ReportTrialBalance = () => import("@/views/reports/report-trial-balance");
const SistemInformasiDebitur = () =>
  import("@/views/reports/sistem-informasi-debitur/list");
const DataSistemInformasiDebitur = () =>
  import("@/views/reports/sistem-informasi-debitur/data");

const PositionKasTeller = () => import("@/views/reports/position-kas-teller");
const DailyTransaction = () => import("@/views/reports/daily-transaction");
const DailyTransactionHistory = () =>
  import("@/views/reports/daily-transaction-history");
const RecapitulationTeller = () =>
  import("@/views/reports/recapitulation-teller");
const TDenominationUser = () =>
  import("@/views/transactions/t-denomination-user");
const DetailHistoryAngsuran = () =>
  import("@/views/reports/history-angsuran/detail");
const DetailHistoryAngsuranRecondition = () =>
  import("@/views/reports/history-angsuran-recondition/detail");
const ListHistoryAngsuran = () =>
  import("@/views/reports/history-angsuran/list");
const FormulirPermohonanRahn = () =>
  import("@/views/reports/formulir-permohonan-rahn");
const SuratBuktiRahn = () => import("@/views/reports/surat-bukti-rahn");

const ListTMortageAccount = () =>
  import("@/views/loans/t-mortage-account/list");
const AddTMortageAccount = () => import("@/views/loans/t-mortage-account/add");
const EditTMortageAccount = () =>
  import("@/views/loans/t-mortage-account/edit");

const MMortgageMetalPrice = () =>
  import("@/views/loans/m-mortgage-metal-price");

const ListTLoanCollectibiltyReports = () =>
  import("@/views/loans/t-loan-collectability-report/list");

const TLoanPaidSimulation = () =>
  import("@/views/loans/t-loan-paid-simulation");

const TLoanInstallmentSimulationRestructuration = () =>
  import("@/views/loans/t-loan-installment-simulation-restructuration");

const ListTLoanAccountReschedule = () =>
  import("@/views/loans/t-loan-account-reschedule/list");
const AddTLoanAccountReschedule = () =>
  import("@/views/loans/t-loan-account-reschedule/add");
const EditTLoanAccountReschedule = () =>
  import("@/views/loans/t-loan-account-reschedule/edit");

const ListTLoanAccountRestructurisation = () =>
  import("@/views/loans/t-loan-account-restructurisation/list");
const AddTLoanAccountRestructurisation = () =>
  import("@/views/loans/t-loan-account-restructurisation/add");
const EditTLoanAccountRestructurisation = () =>
  import("@/views/loans/t-loan-account-restructurisation/edit");

const MRatioAtmr = () => import("@/views/loans/m-ratio-atmr");

const TLoanInstallmentAnuitySimulation = () =>
  import("@/views/loans/t-loan-installment-anuity-simulation");

const RatioCar = () => import("@/views/loans/ratio-car");

const listVirtualAccount = () =>
  import("@/views/transactions/t-virtual-account/list");
const DetailVirtualAccount = () =>
  import("@/views/transactions/t-virtual-account/detail/");

const EstimateProfitShare = () =>
  import("@/views/reports/estimate-profit-share/");

const ListTVirtualAccountPpob = () =>
  import("@/views/transactions/t-virtual-account-ppob/list");
const PaymentTVirtualAccountPpob = () =>
  import("@/views/transactions/t-virtual-account-ppob/payment");
const DetailTVirtualAccountPpob = () =>
  import("@/views/transactions/t-virtual-account-ppob/detail");
const ProductDailiyOpening = () =>
  import("@/views/reports/product-daily-opening/");

const ListTHistoryProfitShare = () =>
  import("@/views/transactions/t-history-profit-share/list");
const DetailTHistoryProfitShare = () =>
  import("@/views/transactions/t-history-profit-share/detail");

const NominatifDeposito = () => import("@/views/reports/nominatif-deposito");

const MInterOfficeAccount = () =>
  import("@/views/reports/m-inter-office-account");
const ListTCollectorFunding = () =>
  import("@/views/transactions/t-collector-funding/list/");
const AddTCollectorFundingManual = () =>
  import("@/views/transactions/t-collector-funding/add/manual");
const AddTCollectorFundingSetor = () =>
  import("@/views/transactions/t-collector-funding/add/setor");
const EditTCollectorFunding = () =>
  import("@/views/transactions/t-collector-funding/edit/");

import MProductLedger from "@/views/transactions/m-product-ledger/";
import MChartOfAccount from "@/views/parameters/m-chart-of-account/";

import MappingParameterSandiBi from "@/views/parameters/mapping-parameter-sandi-bi/";
import MSandiBi from "@/views/parameters/m-sandi-bi";
import Umb from "@/views/parameters/umb";
import MasterAssetCategory from "@/views/parameters/master-asset-category";
import TInventoryAmortisasi from "@/views/transactions/t-inventory-amortisasi";
import MCollectorFunding from "@/views/transactions/m-collector-funding";
import AkumulasiPenyusutan from "@/views/transactions/akumulasi-penyusutan";
import AmortisasiBiaya from "@/views/transactions/amortisasi-biaya";
import MSandiBILevel1 from "@/views/parameters/m-sandi-bi-level-1/";
import MSandiBILevel2 from "@/views/parameters/m-sandi-bi-level-2/";
import MPpap from "@/views/parameters/m-ppap";
import MRak from "@/views/parameters/m-rak";
import MEdc from "@/views/parameters/m-edc/";
import MOffice from "@/views/parameters/m-office/";
import MDayOff from "@/views/parameters/m-day-off/";
import MBusinessSegment from "@/views/parameters/m-business-segment/";
import MTransactionCode from "@/views/parameters/m-transaction-code/";
import MJournalTemplate from "@/views/parameters/m-journal-template/";
import MJournalLedgerReversal from "@/views/parameters/m-journal-ledger-reversal/";
import MJournalLedger from "@/views/parameters/m-journal-ledger/";
import OverbookChartOfAccount from "@/views/transactions/overbook-chart-of-account/";
import MMenuAccess from "@/views/user-managements/m-menu-access/";
import MUserRole from "@/views/user-managements/m-user-role/";
import MAtmChannel from "@/views/transactions/m-atm-channel/";
import CollateralGroup from "@/views/loans/collateral-group/";
import GeneratePinAtm from "@/views/transactions/generate-pin-atm/";
import MDepositBilyet from "@/views/transactions/m-deposit-bilyet/";
import MInterbankAssets from "@/views/transactions/m-interbank-assets/";
import MInterbankLiability from "@/views/transactions/m-interbank-liability/";
import MUser from "@/views/user-managements/m-user/";
import Overbook from "@/views/transactions/overbook/";
import TBalanceCash from "@/views/transactions/t-balance-cash/";
import CashAdvance from "@/views/transactions/t-cash-advance/";
import CashAdvanceReturn from "@/views/transactions/t-cash-advance-return/";
import TCashCollection from "@/views/transactions/t-cash-collection/";
import TDepositAccount from "@/views/transactions/t-deposit-account/";
import TDepositAccountDetailFundAllocated from "@/views/transactions/t-deposit-account-detail-fund-allocated/";
import TDepositAccountDetailFundAllocatedOverbook from "@/views/transactions/t-deposit-account-detail-fund-allocated-overbook/";
import TDepositAccountDisburse from "@/views/transactions/t-deposit-account-disburse/";
import TMultiAccount from "@/views/transactions/t-multi-account/";
import TSavingAccount from "@/views/transactions/t-saving-account/";
import TSavingAccountDetail from "@/views/transactions/t-saving-account-detail/";
import TSavingAccountDetailCloseSavingAccount from "@/views/transactions/t-saving-account-detail-close-saving-account/";
import TSavingAccountDetailRak from "@/views/transactions/t-saving-account-detail-rak/";
import TSavingAccountDetailReversal from "@/views/transactions/t-saving-account-detail-reversal/";
import TTaskInterbankAsset from "@/views/transactions/t-task-interbank-asset/";
import TTaskInterbankLiability from "@/views/transactions/t-task-interbank-liability/";
import MLoanCollectibilty from "@/views/loans/m-loan-collectability/";
import MMortgage from "@/views/loans/m-mortgage/";
import MMortgageSlthe from "@/views/loans/m-mortgage-slthe/";
import Sbr from "@/views/loans/m-sbr/";
import TLoanAccountDroppingOverbook from "@/views/loans/t-loan-account-dropping-overbook/";
import TLoanCollectibiltyJustifikasi from "@/views/loans/t-loan-collectability-justifikasi/";
import TLoanInstallmentCorrection from "@/views/loans/t-loan-installment-correction/";
import TLoanInstallmentManualPayment from "@/views/loans/t-loan-installment-manual-payment/";
import TLoanPaid from "@/views/loans/t-loan-paid/";
import TLoanSp3 from "@/views/loans/t-loan-sp-3/";
import TLoanSp3Reschedule from "@/views/loans/t-loan-sp3-reschedule/";
import TLoanSp3Restructure from "@/views/loans/t-loan-sp3-restructure/";
import TMortgagePaidOff from "@/views/loans/t-mortgage-paid-off/";
import WriteOff from "@/views/loans/write-off/";
import ProcessPpap from "@/views/reports/process-ppap/";
import ReportDepositProfitShare from "@/views/reports/report-deposit-profit-share";
import Rnd from "@/views/pages/rnd";
import MappingOfficeCoa from "@/views/parameters/mapping-office-coa";
import MutasiCoa from "@/views/parameters/mutasi-coa/";
import TAtmChannel from "@/views/transactions/t-atm-channel";
import ManualTriggerProfitShare from "@/views/user-managements/manual-trigger-profit-share";
import ManualTriggerInstallment from "@/views/user-managements/manual-trigger-installment";
import ConfigurationList from "@/views/transactions/configuration/list";
import ConfigurationDetail from "@/views/transactions/configuration/detail";
import ConfigurationFrontendList from "@/views/parameters/configuration-frontend/list";
import ConfigurationFrontendDetail from "@/views/parameters/configuration-frontend/detail";
import WadiahSavingBonusByProduct from "@/views/transactions/t-wadiah-saving-bonus/wadiah-saving-bonus-by-product/";
import WadiahSavingBonusByOffice from "@/views/transactions/t-wadiah-saving-bonus/wadiah-saving-bonus-by-office/";
import TAtmChannelReturn from "@/views/transactions/t-atm-channel-return/";
import DailyTransactionApuppt from "@/views/reports/daily-transaction-apuppt/";
import BackupRestore from "@/views/user-managements/backup-restore/";
import AuthorizedAllTransaction from "@/views/transactions/authorized-all-transaction/";
import ReportCollectorFunding from "@/views/reports/collector-funding/";
import ReportAtmChannel from "@/views/reports/report-atm-channel/";
import NominatifComparing from "@/views/reports/nominatif-comparing/";
import SavingAccountReactivate from "@/views/transactions/t-saving-account-reactivate/";
import ReconcileSBB from "@/views/reports/reconcile-sbb";
import ReportEstimasiLabaRugi from "@/views/reports/report-estimasi-laba-rugi/";
import LoanDroppingReport from "@/views/reports/loan-dropping-report";
import LoanSettlementReport from "@/views/reports/loan-settlement-report";
import MAccountOfficer from "@/views/parameters/m-account-officer/";
import NominatifInterbankAssets from "@/views/reports/nominatif-interbank-assets/";
import TSavingHistory from "@/views/transactions/t-saving-history";
import DistributionProfitShare from "@/views/reports/distribution-profit-share/";
import Sipesat from "@/views/reports/sipesat";
import ClosingEoyCentralize from "@/views/user-managements/closing-eoy-centralize/";
import TSingleEntry from "@/views/transactions/t-single-entry/";
import ReportAuditrail from "@/views/reports/report-auditrail/";
import InqueryDanamon from "@/views/transactions/cash-out-danamon/";
import LoanTransferOffice from "@/views/loans/loan-transfer-office/";
import TLoanWriteOff from "@/views/loans/t-loan-write-off/";
import UpdateAccountOfficer from "@/views/loans/update-account-officer/";
import NominatifWriteOff from "@/views/reports/nominatif-write-off/";
import loanTransferProduct from "@/views/loans/loan-transfer-product/";
import UpdateFixRate from "@/views/transactions/update-fix-rate/";
import UpdateAoOfficeSubGroup from "@/views/loans/update-ao-office-sub-group/";
import UpdateUserMpos from "@/views/transactions/update-user-mpos/";
import ActivatedDormant from "@/views/transactions/activated-dormant/";
import ActivatedSaving from "@/views/transactions/activated-saving/";
import SavingUpdateAO from "@/views/transactions/t-saving-update-ao/";
import MDttot from "@/views/reports/m-dttot/";
import MSipendar from "@/views/reports/m-sipendar/";


export default {
  TSavingHistory,
  NominatifInterbankAssets,
  MAccountOfficer,
  LoanDroppingReport,
  LoanSettlementReport,
  ReportEstimasiLabaRugi,
  ReconcileSBB,
  SavingAccountReactivate,
  NominatifComparing,
  ReportAtmChannel,
  ReportCollectorFunding,
  AuthorizedAllTransaction,
  BackupRestore,
  DailyTransactionApuppt,
  TAtmChannelReturn,
  WadiahSavingBonusByOffice,
  WadiahSavingBonusByProduct,
  ManualTriggerInstallment,
  ManualTriggerProfitShare,
  MutasiCoa,
  MappingOfficeCoa,
  ProcessPpap,
  WriteOff,
  TMortgagePaidOff,
  TLoanSp3Restructure,
  TLoanSp3Reschedule,
  TLoanSp3,
  TLoanPaid,
  TLoanInstallmentManualPayment,
  TLoanInstallmentCorrection,
  TLoanCollectibiltyJustifikasi,
  TLoanAccountDroppingOverbook,
  Sbr,
  ConfigurationFrontendList,
  ConfigurationFrontendDetail,
  ListTHistoryProfitShare,
  DetailTHistoryProfitShare,
  MMortgageSlthe,
  MMortgage,
  MLoanCollectibilty,
  MasterAssetCategory,
  TTaskInterbankLiability,
  TTaskInterbankAsset,
  TSavingAccountDetailReversal,
  TSavingAccountDetailRak,
  TSavingAccountDetailCloseSavingAccount,
  TSavingAccountDetail,
  TSavingAccount,
  TInventoryAmortisasi,
  MCollectorFunding,
  ListTCollectorFunding,
  AddTCollectorFundingManual,
  AddTCollectorFundingSetor,
  EditTCollectorFunding,
  TMultiAccount,
  TDepositAccountDisburse,
  TDepositAccountDetailFundAllocatedOverbook,
  TDepositAccountDetailFundAllocated,
  TDepositAccount,
  TCashCollection,
  CashAdvanceReturn,
  CashAdvance,
  TBalanceCash,
  Overbook,
  MInterbankLiability,
  MInterbankAssets,
  MDepositBilyet,
  GeneratePinAtm,
  MAtmChannel,
  AmortisasiBiaya,
  LandingPage,
  Login,
  Rnd,
  TheContainer,
  ListMCif,
  CreateCif,
  ExistingCif,
  MBusinessSegment,
  MUser,
  HistoryLogUserSign,
  MUserRole,
  MTransactionCode,
  MOffice,
  MChartOfAccount,
  MProductLedger,
  ListMSaving,
  AddMSaving,
  EditMSaving,
  DetailMSaving,
  ListMDeposit,
  AddMDeposit,
  EditMDeposit,
  DetailMDeposit,
  MMenuAccess,
  ListMLoan,
  AddMLoan,
  EditMLoan,
  DetailMLoan,
  MDepositBilyetInventory,
  TSavingAccountDetailBookTransaction,
  TSavingAccountBalanceInquiry,
  TSavingAccountDetailAccountStatement,
  MUserRoleMappingMenuAccess,
  ListTSavingBlock,
  ProcessTSavingBlock,
  ListTLoanAccount,
  ListTLoanAccountRecondition,
  AddTLoanAccount,
  EditTLoanAccount,
  Page404,
  TSavingAccountGenerateHeaderBook,
  TLoanInstallmentSimulation,
  MJournalTemplate,
  TDepositAccountDetailAccountStatement,
  MJournalLedger,
  ReversalJournalLedgerManual,
  TInterbankAssetsDetailAccountStatement,
  TInterbankLiabilityDetailAccountStatement,
  TOperationalProcess,
  TOperationalProcessClosing,
  TOperationalProcessList,
  ClosingEodCentralize,
  ClosingEomCentralize,
  PageUnaccess,
  PositionKasTeller,
  LabaRugi,
  DailyTransaction,
  DailyTransactionHistory,
  DetailHistoryAngsuran,
  DetailHistoryAngsuranRecondition,
  ListHistoryAngsuran,
  FormulirPermohonanRahn,
  SuratBuktiRahn,
  RecapitulationTeller,
  TDenominationUser,
  ListTLoanCollectibiltyReports,
  GenerateEstimateCollectability,
  GenerateReportCollectability,
  NominatifPembiayaan,
  NominatifLoanAccount,
  MSandiBi,
  MRak,
  Umb,
  MDayOff,
  TLoanPaidSimulation,
  TLoanInstallmentSimulationRestructuration,
  ListTLoanAccountRestructurisation,
  AddTLoanAccountRestructurisation,
  EditTLoanAccountRestructurisation,
  ListTLoanAccountReschedule,
  AddTLoanAccountReschedule,
  EditTLoanAccountReschedule,
  MPpap,
  MRatioAtmr,
  TLoanInstallmentAnuitySimulation,
  RatioCar,
  Neraca,
  PrintOutFpr,
  ReportTrialBalance,
  SistemInformasiDebitur,
  DataSistemInformasiDebitur,
  MSandiBILevel1,
  MSandiBILevel2,
  MappingParameterSandiBi,
  ListTMortageAccount,
  AddTMortageAccount,
  EditTMortageAccount,
  DenominatifPembiayaan,
  MMortgageMetalPrice,
  CifPortofolio,
  EstimateCollectability,
  LoanInstallmentPaymentHistory,
  listVirtualAccount,
  DetailVirtualAccount,
  MEdc,
  EstimateProfitShare,
  ListTVirtualAccountPpob,
  PaymentTVirtualAccountPpob,
  DetailTVirtualAccountPpob,
  ProductDailiyOpening,
  NominatifDeposito,
  MInterOfficeAccount,
  MJournalLedgerReversal,
  OverbookChartOfAccount,
  NominatifSavingAccountByProduct,
  NominatifSavingAccountByAccountNumber,
  OfficeStatus,
  ReportRahn,
  CollateralGroup,
  ReportDepositProfitShare,
  TAtmChannel,
  AkumulasiPenyusutan,
  ConfigurationList,
  ConfigurationDetail,
  DistributionProfitShare,
  Sipesat,
  ClosingEoyCentralize,
  TSingleEntry,
  ReportAuditrail,
  InqueryDanamon,
  LoanTransferOffice,
  TLoanWriteOff,
  UpdateAccountOfficer,
  NominatifWriteOff,
  loanTransferProduct,
  UpdateFixRate,
  UpdateAoOfficeSubGroup,
  UpdateUserMpos,
  ActivatedDormant,
  ActivatedSaving,
  SavingUpdateAO,
  MDttot,
  MSipendar
};
