import FormMDepositBilyet from "../form";

export default {
  name: "CreateMDepositBilyet",
  components: {
    FormMDepositBilyet,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getDepositAccountById();
    },
    async addDepositBilyet(payload) {
      const data = {
        depositAccountId: this.identity.mainId,
        depositBilyetId: payload.depositBilyetId,
        bilyetTypeId: payload.bilyetTypeId,
        nominal: payload.nominal,
        usedDate: payload.usedDate,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formMDepositBilyetRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/mapping-deposit-bilyet",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.toast.open(
            this.$NotificationUtils.success
          );
          toastBuefy.$on("close", () => {
            this.$refs.formMDepositBilyetRef.routeToPageListDepositBilyet();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          await this.simpleWait(1500);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formMDepositBilyetRef.property.animation.submitLoading = false;
      }
    },
    async getDepositAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "deposit-account/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formMDepositBilyetRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formMDepositBilyetRef.routeToPageListDepositBilyet(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formMDepositBilyetRef.routeToPageListDepositBilyet(),
          });
        }
      }
    },
  },
};
