import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Moment from "moment";

export default {
  data() {
    return {
      depositBilyet: {
        depositAccount: {
          accountNumber: "",
          openDate: "",
          dueDate: "",
          counted: "",
          nominal: "",
          timePeriode: "",
          savingAccountNumber: "",
          cifIdName: "",
          cifIdAddress: "",
          cifCity: "",
          nisbah: 0,
          counter: "",
          nisbahBank: 0,
          profitShareBankName: "",
          profitShareAccountName: "",
          profitShareAccountNumber: "",
          qqCode: "",
          qqName: "",
          depositName: "",
          depositCode: "",
        },
      },
    };
  },
  methods: {
    pembilang(bilangan, append) {
      bilangan = String(bilangan);
      var angka = new Array(
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0"
      );
      var kata = new Array(
        "",
        "Satu",
        "Dua",
        "Tiga",
        "Empat",
        "Lima",
        "Enam",
        "Tujuh",
        "Delapan",
        "Sembilan"
      );
      var tingkat = new Array("", "Ribu", "Juta", "Milyar", "Triliun");

      var panjang_bilangan = bilangan.length;

      /* pengujian panjang bilangan */
      if (panjang_bilangan > 15) {
        kaLimat = "Diluar Batas";
        return kaLimat;
      }
      var i;
      var j;
      var kaLimat;
      var subkaLimat;
      var kata1;
      var kata2;
      var kata3;
      /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
      for (i = 1; i <= panjang_bilangan; i++) {
        angka[i] = bilangan.substr(-i, 1);
      }

      i = 1;
      j = 0;
      kaLimat = "";

      /* mulai proses iterasi terhadap array angka */
      while (i <= panjang_bilangan) {
        subkaLimat = "";
        kata1 = "";
        kata2 = "";
        kata3 = "";

        /* untuk Ratusan */
        if (angka[i + 2] != "0") {
          if (angka[i + 2] == "1") {
            kata1 = "Seratus";
          } else {
            kata1 = kata[angka[i + 2]] + " Ratus";
          }
        }

        /* untuk Puluhan atau Belasan */
        if (angka[i + 1] != "0") {
          if (angka[i + 1] == "1") {
            if (angka[i] == "0") {
              kata2 = "Sepuluh";
            } else if (angka[i] == "1") {
              kata2 = "Sebelas";
            } else {
              kata2 = kata[angka[i]] + " Belas";
            }
          } else {
            kata2 = kata[angka[i + 1]] + " Puluh";
          }
        }

        /* untuk Satuan */
        if (angka[i] != "0") {
          if (angka[i + 1] != "1") {
            kata3 = kata[angka[i]];
          }
        }

        /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
        if (angka[i] != "0" || angka[i + 1] != "0" || angka[i + 2] != "0") {
          subkaLimat =
            kata1 + " " + kata2 + " " + kata3 + " " + tingkat[j] + " ";
        }

        /* gabungkan variabe sub kaLimat (untuk Satu blok 3 angka) ke variabel kaLimat */
        kaLimat = subkaLimat + kaLimat;
        i = i + 3;
        j = j + 1;
      }

      /* mengganti Satu Ribu jadi Seribu jika diperlukan */
      if (angka[5] == "0" && angka[6] == "0") {
        kaLimat = kaLimat.replace("Satu Ribu", "Seribu");
      }
      let leftAppend = append ? append : "";
      return kaLimat + leftAppend;
    },
    Moment: Moment,
    async findDepositBilyetByDepositAccountId(depositAccountId) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/deposit-account-id/" + depositAccountId,
        });
        console.log("data =>", resp);
        if (resp.data.code === "SUCCESS") {
          this.depositBilyet.depositAccount.qqCode = resp.data.data.qqCode;
          this.depositBilyet.depositAccount.qqName = resp.data.data.qqName;
          this.depositBilyet.depositAccount.accountNumber =
            resp.data.data.accountNumber;
          this.depositBilyet.depositAccount.openDate = resp.data.data.openDate;

          this.depositBilyet.depositAccount.dueDate = resp.data.data.dueDate;

          this.depositBilyet.depositAccount.nominal = resp.data.data.nominal;

          this.depositBilyet.depositAccount.savingAccountNumber =
            resp.data.data.disburseProfitShareAccount;
          this.depositBilyet.depositAccount.timePeriode =
            resp.data.data.timePeriode;

          this.depositBilyet.depositAccount.cifIdName =
            resp.data.data.cifIdName;

          this.depositBilyet.depositAccount.cifIdAddress =
            resp.data.data.cifIdAddress;

          this.depositBilyet.depositAccount.cifCity = resp.data.data.cityName;

          this.depositBilyet.depositAccount.nisbah = resp.data.data.nisbah;
          this.depositBilyet.depositAccount.nisbahBank =
            100 - this.depositBilyet.depositAccount.nisbah;
          const profitShareType =
            resp.data.data?.tdepositAccount
              ?.rdepositOptionDisburseProfitShareDto?.optionDisburseId;

          this.depositBilyet.depositAccount.profitShareAccountName =
            resp.data.data.disburseProfitShareAccountName;
          this.depositBilyet.depositAccount.companyName =
            resp.data.data.disburseProfitShareBankName;
          this.depositBilyet.depositAccount.profitShareAccountNumber =
            resp.data.data.disburseProfitShareAccount;
          this.depositBilyet.depositAccount.depositName =
            resp.data.data.depositName;
          this.depositBilyet.depositAccount.officeCityName =
            resp.data.data.officeCityName;
          this.depositBilyet.depositAccount.depositName =
            resp.data.data.depositName;
          this.depositBilyet.depositAccount.depositCode =
            resp.data.data.depositCode;
          if (
            this.fetchAppSession("@vue-session/asset-bundle").companyName ===
            "BPRS FORMES"
          ) {
            this.printDepositBilyetFormesVersion();
            return;
          } else {
            if (
              this.depositBilyet.depositAccount.depositCode === "61" ||
              this.depositBilyet.depositAccount.depositCode === "62"
            ) {
              this.printDepositBilyetDigital();
            } else {
              this.printDepositBilyet();
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async cetakSbr(props) {
      if (
        this.fetchAppSession("@vue-session/asset-bundle").companyName ===
        "HIK Parahyangan"
      ) {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_SAVE_RESPONSE_DATA",
            endPoint: "report",
            reqUrl: "sbr-printout/files",
            payload: {
              fileType: "pdf",
              mortgageAccountId: props.row.mortgageAccountId,
            },
          });
          this.printOutWindow(resp.data);
        } catch (error) {
          console.log(error);
        }
      } else if (
        this.fetchAppSession("@vue-session/asset-bundle").companyName ===
        "HARUM"
      ) {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "mortgage-register-number/print-out-sbr/" +
              props.row.mortgageRegisterId.mortgageRegisterId,
          });
          if (resp.data.code === "SUCCESS") {
            this.printMortgageRegisterNumberHarumVersion(resp.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    cetakHeaderBook(printOutType, printOutData) {
      if (
        this.fetchAppSession("@vue-session/asset-bundle").companyName ===
        "BPRS FORMES"
      ) {
        this.printOutSavingAccountHeaderBookFormesVersion(
          printOutType,
          printOutData
        );
        return;
      } else {
        if (
          (printOutData.savingCode === "01" &&
            printOutData.cetakBukuLama === true) ||
          (printOutData.savingCode === "06" &&
            printOutData.cetakBukuLama === true) ||
          (printOutData.savingCode === "03" &&
            printOutData.cetakBukuLama === true)
        ) {
          this.printOutSavingAccountHeaderBook(printOutType, printOutData);
        } else {
          if (
            printOutData.savingCode === "06" ||
            printOutData.savingCode === "01" ||
            printOutData.savingCode === "03"
          ) {
            this.printOutSavingAccountHeaderBookSimple(
              printOutType,
              printOutData
            );
          } else {
            this.printOutSavingAccountHeaderBook(printOutType, printOutData);
          }
        }
      }
    },
    printMortgageRegisterNumberHarumVersion(props) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setFont("Lato-Regular", "bold");

      //no akad
      doc.setFontSize(9).text(`${props.noSbr}`, 1.3, 1.3);

      //nama
      doc.setFontSize(8).text(`${props.name}`, 5.2, 1.6);

      //alamat
      doc
        .setFontSize(8)
        .text(
          `${props.alamat.replace(/([\w\s]{36,}?)\s?\b/g, "$1\n")}`,
          5.2,
          1.7
        );
      //no ktp/sim
      doc.setFontSize(8).text(`${props.noIdentitas}`, 5.2, 1.98);
      //no telpon
      doc.setFontSize(8).text(`${props.telephone}`, 5.2, 2.1);

      // tanggal akad
      doc.setFontSize(9).text(`${props.tanggalAkad}`, 6.2, 2.8);

      // biaya pemeliharaan
      doc
        .setFontSize(9)
        .text(` ${this.convertCurrency(props.biayaSewa)}`, 4.8, 3.35);

      //Tanggal Jatuh Tempo
      doc.setFontSize(9).text(`${props.tanggalJthTempo}`, 6.2, 3.35);

      //Taksiran
      doc
        .setFontSize(9)
        .text(` ${this.convertCurrency(props.taksiran)}`, 1.3, 3.66);

      // pinjaman
      doc
        .setFontSize(9)
        .text(` ${this.convertCurrency(props.pinjaman)}`, 1.3, 3.88);

      // terbilang
      doc
        .setFontSize(8)
        .text(
          `${this.pembilang(props.pinjaman, "Rupiah").replace(
            /([\w\s]{45,}?)\s?\b/g,
            "$1\n"
          )}`,
          1.05,
          4.1
        );
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printMortgageRegisterNumberHikVersion(data) {
      console.log("trigered");
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setFontSize(10).text("office-name", 0.9, 1.05);
      doc.setFontSize(10).text("m-reg-number", 4.5, 1.04);

      doc.setFontSize(10).text("cif-number", 1.3, 1.4);
      doc.setFontSize(10).text("fpr-number", 1.3, 1.6);
      doc.setFontSize(10).text("cif-id-name", 1.3, 1.8);
      doc.setFontSize(10).text("cif-id-address", 1.3, 2);
      doc.setFontSize(10).text("cif-phone-number", 1.3, 2.2);
      doc.setFontSize(10).text("cif-profession-name", 1.3, 2.4);
      doc.setFontSize(10).text("cif-loan-reason", 1.3, 2.6);

      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printDepositBilyetFormesVersion() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.getFontList();
      doc.setFont("helvetica", "");

      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.nominal
              ? this.depositBilyet.depositAccount.nominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : this.depositBilyet.depositAccount.nominal
          }`,
          2.8,
          1.25
        );
      doc
        .setFontSize(9)
        .text(`${this.depositBilyet.depositAccount.accountNumber}`, 6.3, 1.25);
      doc
        .setFontSize(9)
        .text(`${this.depositBilyet.depositAccount.depositName}`, 4.4, 1.45);
      doc
        .setFontSize(9)
        .text(`${this.depositBilyet.depositAccount.timePeriode}`, 7.1, 1.45);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.nominal
              ? this.pembilang(
                  this.depositBilyet.depositAccount.nominal,
                  "Rupiah"
                )
              : "-"
          }`,
          2.0,
          1.6
        );
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.cifIdName}`.toUpperCase(),
          2.05,
          1.9
        );
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.cifIdAddress}`.toUpperCase(),
          2.05,
          2.1
        );
      doc
        .setFontSize(9)
        .text(`${this.depositBilyet.depositAccount.nisbah} %`, 2.9, 2.3);
      doc
        .setFontSize(9)
        .text(`${this.depositBilyet.depositAccount.officeCityName}`, 5.8, 2.5);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.openDate
              ? this.dateToMonthText(this.depositBilyet.depositAccount.openDate)
              : "-"
          }`,
          7.1,
          2.5
        );
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.dueDate
              ? this.dateToMonthText(this.depositBilyet.depositAccount.dueDate)
              : "-"
          }`,
          4.2,
          2.7
        );
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.openDate
              ? this.Moment(this.depositBilyet.depositAccount.openDate).format(
                  "DD"
                )
              : "-"
          }`,
          3.5,
          3
        );
      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printDepositBilyet() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setFont("Lato-Regular", "normal");

      doc.setFontSize(9.5).text(`No Rekening`, 0.3, 1);

      doc.setFontSize(9.5).text(`:`, 2, 1);
      doc
        .setFontSize(9)
        .text(`${this.depositBilyet.depositAccount.accountNumber}`, 2.3, 1);

      doc.setFontSize(9.5).text(`Nama`, 0.3, 1.25);
      doc.setFontSize(9.5).text(`:`, 2, 1.25);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.cifIdName} ${this.depositBilyet.depositAccount.qqCode} ${this.depositBilyet.depositAccount.qqName}`.toUpperCase(),
          2.3,
          1.25
        );

      doc.setFontSize(9.5).text(`Alamat`, 0.3, 1.5);
      doc.setFontSize(9.5).text(`:`, 2, 1.5);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.cifIdAddress}`.toUpperCase(),
          2.3,
          1.5
        );

      doc.setFontSize(9.5).text(`Nominal`, 0.3, 1.75);
      doc.setFontSize(9.5).text(`:`, 2, 1.75);
      doc
        .setFontSize(9)
        .text(
          `Rp. ${
            this.depositBilyet.depositAccount.nominal
              ? this.depositBilyet.depositAccount.nominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : this.depositBilyet.depositAccount.nominal
          }`,
          2.3,
          1.75
        );

      doc.setFontSize(9.5).text(`Terbilang`, 0.3, 2);
      doc.setFontSize(9.5).text(`:`, 2, 2);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.nominal
              ? this.pembilang(
                  this.depositBilyet.depositAccount.nominal,
                  "Rupiah"
                )
              : "-"
          }`,
          2.3,
          2
        );

      // jangka-waktu-part
      doc.setFontSize(9.5).text(`Jangka Waktu`, 0.3, 2.25);
      doc.setFontSize(9.5).text(`:`, 2, 2.25);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.timePeriode} (${this.pembilang(
            this.depositBilyet.depositAccount.timePeriode
          )}) Bulan`,
          2.3,
          2.25
        );

      // tanggal-penempatan-part
      doc.setFontSize(9.5).text(`Tanggal Penempatan`, 0.3, 2.5);
      doc.setFontSize(9.5).text(`:`, 2, 2.5);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.openDate
              ? this.dateToMonthText(this.depositBilyet.depositAccount.openDate)
              : "-"
          }`,
          2.3,
          2.5
        );

      // tanggal-jatuh-tempo-part
      doc.setFontSize(9.5).text(`Tanggal Jatuh Tempo`, 0.3, 2.75);
      doc.setFontSize(9.5).text(`:`, 2, 2.75);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.dueDate
              ? this.dateToMonthText(this.depositBilyet.depositAccount.dueDate)
              : "-"
          }`,
          2.3,
          2.75
        );

      // porsi-nisbah-part
      doc.setFontSize(9.5).text(`Porsi Nisbah`, 0.3, 3);
      doc.setFontSize(9.5).text(`:`, 2, 3);
      doc
        .setFontSize(9)
        .text(
          `Nasabah : ${this.depositBilyet.depositAccount.nisbah} %  Bank : ${this.depositBilyet.depositAccount.nisbahBank} %`,
          2.3,
          3
        );

      // // rekening-bagi-hasi
      doc.setFontSize(9.5).text(`Rekening Bagi Hasil`, 0.3, 3.25);
      doc.setFontSize(9.5).text(`:`, 2, 3.25);
      doc.setFontSize(9.5).text(`Bank`, 2.3, 3.25);
      doc.setFontSize(9.5).text(`:`, 3.2, 3.25);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.companyName
              ? this.depositBilyet.depositAccount.companyName ===
                "HIK Parahyangan"
                ? `BPRS ${this.depositBilyet.depositAccount.companyName}`
                : this.depositBilyet.depositAccount.companyName
              : ""
          }`.toUpperCase(),
          3.5,
          3.25
        );
      doc.setFontSize(9.5).text(`No. Rek`, 2.3, 3.5);
      doc.setFontSize(9.5).text(`:`, 3.2, 3.5);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.profitShareAccountNumber}`,
          3.5,
          3.5
        );
      doc.setFontSize(9.5).text(`Atas Nama`, 2.3, 3.75);
      doc.setFontSize(9.5).text(`:`, 3.2, 3.75);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.profitShareAccountName}`.toUpperCase(),
          3.5,
          3.75
        );

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printDepositBilyetDigital() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setFont("Lato-Regular", "normal");

      doc.setFontSize(9.5).text(`No Rekening`, 0.3, 1);

      doc.setFontSize(9.5).text(`:`, 2, 1);
      doc
        .setFontSize(9)
        .text(`${this.depositBilyet.depositAccount.accountNumber}`, 2.3, 1);

      doc.setFontSize(9.5).text(`Nama`, 0.3, 1.25);
      doc.setFontSize(9.5).text(`:`, 2, 1.25);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.cifIdName} ${this.depositBilyet.depositAccount.qqCode} ${this.depositBilyet.depositAccount.qqName}`.toUpperCase(),
          2.3,
          1.25
        );

      doc.setFontSize(9.5).text(`Alamat`, 0.3, 1.5);
      doc.setFontSize(9.5).text(`:`, 2, 1.5);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.cifIdAddress}`.toUpperCase(),
          2.3,
          1.5
        );

      doc.setFontSize(9.5).text(`Nominal`, 0.3, 1.75);
      doc.setFontSize(9.5).text(`:`, 2, 1.75);
      doc
        .setFontSize(9)
        .text(
          `Rp. ${
            this.depositBilyet.depositAccount.nominal
              ? this.depositBilyet.depositAccount.nominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : this.depositBilyet.depositAccount.nominal
          }`,
          2.3,
          1.75
        );

      doc.setFontSize(9.5).text(`Terbilang`, 0.3, 2);
      doc.setFontSize(9.5).text(`:`, 2, 2);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.nominal
              ? this.pembilang(
                  this.depositBilyet.depositAccount.nominal,
                  "Rupiah"
                )
              : "-"
          }`,
          2.3,
          2
        );

      // jangka-waktu-part
      doc.setFontSize(9.5).text(`Jangka Waktu`, 0.3, 2.25);
      doc.setFontSize(9.5).text(`:`, 2, 2.25);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.timePeriode} (${this.pembilang(
            this.depositBilyet.depositAccount.timePeriode
          )}) Bulan`,
          2.3,
          2.25
        );

      // tanggal-penempatan-part
      doc.setFontSize(9.5).text(`Tanggal Penempatan`, 0.3, 2.5);
      doc.setFontSize(9.5).text(`:`, 2, 2.5);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.openDate
              ? this.dateToMonthText(this.depositBilyet.depositAccount.openDate)
              : "-"
          }`,
          2.3,
          2.5
        );

      // tanggal-jatuh-tempo-part
      doc.setFontSize(9.5).text(`Tanggal Jatuh Tempo`, 0.3, 2.75);
      doc.setFontSize(9.5).text(`:`, 2, 2.75);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.dueDate
              ? this.dateToMonthText(this.depositBilyet.depositAccount.dueDate)
              : "-"
          }`,
          2.3,
          2.75
        );

      // porsi-nisbah-part
      doc.setFontSize(9.5).text(`Porsi Nisbah`, 0.3, 3);
      doc.setFontSize(9.5).text(`:`, 2, 3);
      doc
        .setFontSize(9)
        .text(
          `Nasabah : ${this.depositBilyet.depositAccount.nisbah} %  Bank : ${this.depositBilyet.depositAccount.nisbahBank} %`,
          2.3,
          3
        );

      // // rekening-bagi-hasi
      doc.setFontSize(9.5).text(`Rekening Pencairan`, 0.3, 3.25);
      doc.setFontSize(9.5).text(`:`, 2, 3.25);
      doc.setFontSize(9.5).text(`Bank`, 2.3, 3.25);
      doc.setFontSize(9.5).text(`:`, 3.2, 3.25);
      doc
        .setFontSize(9)
        .text(
          `${
            this.depositBilyet.depositAccount.companyName
              ? this.depositBilyet.depositAccount.companyName ===
                "HIK Parahyangan"
                ? `BPRS ${this.depositBilyet.depositAccount.companyName}`
                : this.depositBilyet.depositAccount.companyName
              : ""
          }`.toUpperCase(),
          3.5,
          3.25
        );
      doc.setFontSize(9.5).text(`No. Rek`, 2.3, 3.5);
      doc.setFontSize(9.5).text(`:`, 3.2, 3.5);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.profitShareAccountNumber}`,
          3.5,
          3.5
        );
      doc.setFontSize(9.5).text(`Atas Nama`, 2.3, 3.75);
      doc.setFontSize(9.5).text(`:`, 3.2, 3.75);
      doc
        .setFontSize(9)
        .text(
          `${this.depositBilyet.depositAccount.profitShareAccountName}`.toUpperCase(),
          3.5,
          3.75
        );

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printOutsSavingAccountDetailFormesVersion(props) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.getFontList();
      doc.setFont("helvetica", "");

      doc
        .setFontSize(8)
        .text(`${props.accountNumber} - ${props.description}`, 0.3, 0.2);
      doc
        .setFontSize(8)
        .text(
          `${props.transactionCode} - ${props.nominal
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")} - ${
            props.documentProofNumber
          }`,
          0.3,
          0.4
        );
      doc
        .setFontSize(8)
        .text(
          `${props.officeName} - ${props.officeCode} ${this.getDateFormat(
            props.transactionDate
          )} - ${props.createdByProfileSurename}`.toUpperCase(),
          0.3,
          0.6
        );

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printOutsSavingAccountDetail(props) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.getFontList();
      doc.setFont("helvetica", "");

      doc
        .setFontSize(8)
        .text(`${props.accountNumber} - ${props.description}`, 0.3, 0.9);
      doc
        .setFontSize(8)
        .text(
          `${props.transactionCode} - ${props.nominal
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
          0.3,
          1.1
        );
      doc
        .setFontSize(8)
        .text(
          `${props.officeName} - ${props.officeCode} ${this.getDateFormat(
            props.transactionDate
          )}`,
          0.3,
          1.3
        );

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printOutDailyTransaction(props) {
      // params yg di butuhkan {
      //   creditAccountNumber,
      //   debitAccountNumber,
      //   transactionCode,
      //   mutationPosition,
      //   creditNominal,
      //   debitNominal,
      //   mutationPosition,
      //   dailySeq,
      //   createdByUserName
      // }
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.getFontList();
      doc.setFont("helvetica", "");

      doc
        .setFontSize(8)
        .text(
          `${props.creditAccountNumber} ${props.debitAccountNumber}`,
          0.3,
          0.9
        );
      doc
        .setFontSize(8)
        .text(
          `${props.transactionCode} - Rp ${
            props.mutationPosition === "C"
              ? this.convertCurrency(props.creditNominal)
              : this.convertCurrency(props.debitNominal)
          } ${props.mutationPosition === "C" ? "Cr" : "Dr"} ${props.dailySeq}`,
          0.3,
          1.1
        );
      doc
        .setFontSize(8)
        .text(
          `${props.createdByUserName} / ${this.dateToYearMonthDay(
            props.transactionDate
          )} / ${new Date(props.transactionDate).toISOString()} / ${
            props.dailySeq
          }`,
          0.3,
          1.3
        );

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printDailyTransactionHistorySingle(printOutData) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setFont("Lato-Regular", "normal");

      ///
      ///
      ///

      // doc.setFontSize(ukuran font).text('text yang mau di munculkan', margin dri horizontal , margin dari vertical );
      doc.setFontSize(8).text("TANGGAL TRANSAKSI", 0.6, 1);
      doc.setFontSize(8).text(":", 1.9, 1);
      doc.setFontSize(8).text(`${printOutData.data.journalDate}`, 2.1, 1);
      doc.setFontSize(8).text("BATCH", 4.2, 1);
      doc.setFontSize(8).text(":", 5, 1);
      doc.setFontSize(8).text(`${printOutData.data.createdByUserCode}`, 5.2, 1);
      doc.setFontSize(8).text("NO.TRANS", 5.9, 1);
      doc.setFontSize(8).text(":", 6.6, 1);
      doc.setFontSize(8).text(`${printOutData.data.referenceNumber}`, 6.8, 1);
      doc.setFontSize(8).text("KODE TRANSAKSI", 0.6, 1.3);
      doc.setFontSize(8).text(":", 1.9, 1.3);
      doc.setFontSize(8).text(`${printOutData.data.journalCode}`, 2.1, 1.3);
      doc.setFontSize(8).text("REKENING DEBET", 0.6, 1.6);
      doc.setFontSize(8).text(":", 1.9, 1.6);
      doc
        .setFontSize(8)
        .text(
          `${printOutData.data.debtAccountNumber} ${printOutData.data.debtAccountNumberName}`,
          2.1,
          1.6
        );
      doc.setFontSize(8).text("SBB DB", 4.2, 1.6);
      doc.setFontSize(8).text(":", 5, 1.6);
      doc.setFontSize(8).text(`${printOutData.data.debtCoaCode}`, 5.2, 1.6);
      doc.setFontSize(8).text("REKENING KREDIT", 0.6, 1.9);
      doc.setFontSize(8).text(":", 1.9, 1.9);
      doc
        .setFontSize(8)
        .text(
          `${printOutData.data.creditAccountNumber} ${printOutData.data.creditAccountNumberName}`,
          2.1,
          1.9
        );
      doc.setFontSize(8).text("SBB CR", 4.2, 1.9);
      doc.setFontSize(8).text(":", 5, 1.9);
      doc.setFontSize(8).text(`${printOutData.data.creditCoaCode}`, 5.2, 1.9);
      doc.setFontSize(8).text("DOKUMEN", 0.6, 2.2);
      doc.setFontSize(8).text(":", 1.9, 2.2);
      doc
        .setFontSize(8)
        .text(
          `${printOutData.data.linkModule ? printOutData.data.linkModule : ""}`,
          2.1,
          2.2
        );
      doc.setFontSize(8).text("NOMINAL", 0.6, 2.5);
      doc.setFontSize(8).text(":", 1.9, 2.5);
      doc
        .setFontSize(8)
        .text(
          `${
            printOutData.data.creditNominal
              ? this.convertDecimalCurrency(printOutData.data.creditNominal)
              : printOutData.data.creditNominal
          }`,
          2.1,
          2.5
        );
      doc.setFontSize(8).text("KETERANGAN", 0.6, 2.8);
      doc.setFontSize(8).text(":", 1.9, 2.8);
      doc.setFontSize(8).text(`${printOutData.data.description}`, 2.1, 2.8);
      doc.setFontSize(8).text("STS TRANSAKSI", 0.6, 3.1);
      doc.setFontSize(8).text(":", 1.9, 3.1);
      doc.setFontSize(8).text(`${printOutData.data.statusCode}`, 2.1, 3.1);
      doc.setFontSize(8).text("STS CETAK", 0.6, 3.4);
      doc.setFontSize(8).text(":", 1.9, 3.4);
      doc.setFontSize(8).text("-", 2.1, 3.4);
      doc.setFontSize(8).text("POKOK", 4.2, 3.7);
      doc.setFontSize(8).text(":", 5, 3.7);
      doc.setFontSize(8).text("0.00", 5.2, 3.7);
      doc.setFontSize(8).text("MARGIN", 4.2, 4);
      doc.setFontSize(8).text(":", 5, 4);
      doc.setFontSize(8).text("0.00", 5.2, 4);
      doc.setFontSize(8).text("INPUTER", 4.2, 4.3);
      doc.setFontSize(8).text(":", 5, 4.3);
      doc.setFontSize(8).text("-", 5.2, 4.3);
      doc.setFontSize(8).text("AUTHORIZER", 4.2, 4.6);
      doc.setFontSize(8).text(":", 5, 4.6);
      doc.setFontSize(8).text("-", 5.2, 4.6);

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printOutDailyTransactionHistory(printOutData) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setFont("Lato-Regular", "bold");

      ///
      ///
      ///

      // doc.setFontSize(ukuran font).text('text yang mau di munculkan', margin dri horizontal , margin dari vertical );
      doc.setFontSize(8).text("Tanggal", 0.6, 1);
      doc.setFontSize(8).text(":", 1, 1);
      doc.setFontSize(8).text(`${printOutData.letterhead.printDate}`, 1.05, 1);
      doc.setFontSize(8).text("Batch", 3.2, 1);
      doc.setFontSize(8).text(":", 3.5, 1);
      doc.setFontSize(8).text(`${printOutData.letterhead.batch}`, 3.55, 1);
      doc.setFontSize(8).text("Nama", 0.6, 1.3);
      doc.setFontSize(8).text(":", 0.9, 1.3);
      doc.setFontSize(8).text(`${printOutData.letterhead.name}`, 0.95, 1.3);
      doc.setFontSize(8).text("Rekening", 0.6, 1.6);
      doc.setFontSize(8).text(":", 1.07, 1.6);
      doc
        .setFontSize(8)
        .text(`${printOutData.letterhead.accountNumber} `, 1.12, 1.6);
      doc.setFontSize(8).text("Keterangan", 0.6, 1.9);
      doc.setFontSize(8).text(":", 1.18, 1.9);
      doc
        .setFontSize(8)
        .text(`${printOutData.letterhead.description} `, 1.23, 1.9);

      doc.setFontSize(8).text("Total Debet", 6.4, 4);
      doc.setFontSize(8).text(":", 7, 4);
      doc
        .setFontSize(8)
        .text(
          `${
            printOutData.letterhead.totalDb
              ? this.convertDecimalCurrency(printOutData.letterhead.totalDb)
              : printOutData.letterhead.totalDb
          }`,
          7.1,
          4
        );
      doc.setFontSize(8).text("Total Credit", 6.4, 4.2);
      doc.setFontSize(8).text(":", 7, 4.2);
      doc
        .setFontSize(8)
        .text(
          `${
            printOutData.letterhead.totalCr
              ? this.convertDecimalCurrency(printOutData.letterhead.totalCr)
              : printOutData.letterhead.totalCr
          }`,
          7.1,
          4.2
        );
      doc.setFontSize(8).text("( Maker )", 0.6, 4.5);
      doc.setFontSize(8).text("( Checker )", 1.1, 4.5);
      doc.setFontSize(8).text("( Signer )", 1.67, 4.5);
      doc.setFontSize(8).text("PRINT BY", 0.6, 4.8);
      doc.setFontSize(8).text(":", 1.15, 4.8);
      doc.setFontSize(8).text(`${printOutData.printBy}`, 1.21, 4.8);

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printOutSavingAccountHeaderBookFormesVersion(printOutType, printOutData) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      if (printOutType === "new") {
        doc.setFont("Lato-Regular", "normal");
        doc.setFontSize(9).text(printOutData.savingName, 1.35, 0.2);
        doc.setFontSize(9).text(printOutData.accountNumber, 1.35, 0.9);
        doc.setFontSize(9).text(printOutData.officeName, 3.7, 0.9);
        doc.setFontSize(9).text(printOutData.cifName, 1.35, 1.22);
        doc.setFontSize(9).text(printOutData.cifAddress, 1.35, 1.5);
        doc.setFontSize(7.5).text(printOutData.printDate, 3.5, 2.5);
      } else if (printOutType === "old") {
        doc.setFont("Lato-Regular", "normal");
        doc.setFontSize(9).text(printOutData.savingName, 1.35, 0.2);
        doc.setFontSize(9).text(printOutData.accountNumber, 1.35, 0.9);
        doc.setFontSize(9).text(printOutData.officeName, 3.7, 0.9);
        doc.setFontSize(9).text(printOutData.cifName, 1.35, 1.22);
        doc.setFontSize(9).text(printOutData.cifAddress, 1.35, 1.5);
        doc.setFontSize(7.5).text(printOutData.printDate, 3.5, 2.5);
      }

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printOutSavingAccountHeaderBook(printOutType, printOutData) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      if (printOutType === "new") {
        // kodingan old
        // doc.setFont("Lato-Regular", "normal");
        // doc.setFontSize(9).text(printOutData.accountNumber, 1.9, 0.6);

        // doc.setFontSize(9).text(printOutData.cifName, 1.25, 0.8);

        // doc.setFontSize(9).text(printOutData.cifAddress, 1.25, 1);

        // doc.setFontSize(9).text(printOutData.cifIdCardNo, 1.25, 1.3);

        // doc.setFontSize(9).text(printOutData.cifNumber, 1.25, 1.5);

        // doc.setFontSize(9).text(printOutData.printDate, 1.25, 1.8);
        // doc.setFontSize(9).text(printOutData.officeName, 3.8, 1.8);

        doc.setFont("Lato-Regular", "normal");
        doc.setFontSize(9).text(printOutData.accountNumber, 1.85, 0.65);

        doc
          .setFontSize(9)
          .text(
            printOutData.cifName ? `${printOutData.cifName}`.toUpperCase() : "",
            1.45,
            0.83
          );

        doc.setFontSize(9).text(printOutData.cifAddress, 1.45, 1);

        doc.setFontSize(9).text(printOutData.cifIdCardNo, 1.45, 1.3);

        doc.setFontSize(9).text(printOutData.cifNumber, 1.45, 1.5);

        doc.setFontSize(9).text(printOutData.printDate, 1.45, 1.8);
        doc.setFontSize(9).text(printOutData.officeName, 3.8, 1.8);
      } else if (printOutType === "old") {
        doc.setFont("Lato-Regular", "normal");
        doc.setFontSize(9).text(printOutData.accountNumber, 1.9, 0.6);

        doc
          .setFontSize(9)
          .text(
            printOutData.cifName ? `${printOutData.cifName}`.toUpperCase() : "",
            1.25,
            0.8
          );

        doc.setFontSize(9).text(printOutData.cifAddress, 1.25, 1);

        doc.setFontSize(9).text(printOutData.cifIdCardNo, 1.25, 1.3);

        doc.setFontSize(9).text(printOutData.printDate, 1.25, 1.8);
        doc.setFontSize(9).text(printOutData.officeName, 3.8, 1.8);
      }

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    printOutSavingAccountHeaderBookSimple(printOutType, printOutData) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      if (printOutType === "new") {
        doc.setFont("Lato-Regular", "normal");
        doc.setFontSize(9).text(printOutData.accountNumber, 0.55, 0.75);
        doc
          .setFontSize(9)
          .text(
            printOutData.cifName
              ? printOutData.qqName
                ? `${printOutData.cifName} ${printOutData.qqCode} ${printOutData.qqName}`.toUpperCase()
                : printOutData.cifName.toUpperCase()
              : "",
            0.55,
            0.93
          );
        doc.setFontSize(9).text(printOutData.cifAddress, 0.55, 1.1);
        doc.setFontSize(9).text(printOutData.cifIdCardNo, 0.55, 1.4);
        doc.setFontSize(9).text(printOutData.cifNumber, 0.55, 1.6);
        doc.setFontSize(9).text(printOutData.printDate, 0.55, 2);
        doc.setFontSize(9).text(printOutData.officeName, 3.4, 2);
      } else if (printOutType === "old") {
        doc.setFont("Lato-Regular", "normal");
        doc.setFontSize(9).text(printOutData.accountNumber, 0.55, 0.75);
        doc
          .setFontSize(9)
          .text(
            printOutData.cifName
              ? printOutData.qqName
                ? `${printOutData.cifName} ${printOutData.qqCode} ${printOutData.qqName}`.toUpperCase()
                : printOutData.cifName
              : "",
            0.55,
            0.93
          );
        doc.setFontSize(9).text(printOutData.cifAddress, 0.55, 1.1);
        doc.setFontSize(9).text(printOutData.cifIdCardNo, 0.55, 1.4);
        // doc.setFontSize(9).text(printOutData.cifNumber, 0.55, 1.6);
        doc.setFontSize(9).text(printOutData.printDate, 0.55, 2);
        doc.setFontSize(9).text(printOutData.officeName, 3.4, 2);
      }

      doc.autoPrint();
      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);

      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
  },
};
