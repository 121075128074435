<template>
  <div class="font-custome">
    <ModalAuth
      :isReloadPage="$store.getters['reauthenticationWithReload']"
      v-if="$store.getters['isTokenExpired']"
    />
    <router-view></router-view>
    <b-loading
      :is-full-page="true"
      v-model="$store.getters['isCentralLoading']"
      :can-cancel="false"
    >
      <ScaleLoader />
    </b-loading>
  </div>
</template>

<script>
const regeneratorRuntime = require("regenerator-runtime");

import ModalAuth from "./components/modal-auth/modalAuth";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  components: {
    ModalAuth,
    ScaleLoader,
  },
  data() {
    return {
      isTokenExpired: this.$store.getters["isTokenExpired"],
      title_app: "",
      favicon_app: "",
    };
  },
  mounted() {
    this.getAssetApp();
  },
  methods: {
    getAssetApp() {
      const getAssetApp = sessionStorage.getItem("@vue-session/asset-bundle");
      if (getAssetApp) {
        const session = JSON.parse(this.decryptBASE64(getAssetApp));
        this.title_app = session.companyName;
        this.favicon_app = session.faviconImg;
        var self = this;
        self.$emit("updateHead");
        return;
      }
      this.fetchMCompany();
    },
    async fetchMCompany() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "parameter",
          reqUrl: "company",
        });
        if (resp.data.code === "SUCCESS") {
          sessionStorage.setItem(
            "@vue-session/asset-bundle",
            this.encryptBASE64(JSON.stringify(resp.data.data))
          );
          this.getAssetApp();
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
  name: "App",
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function() {
      return {
        inner: "CBS",
        separator: "|",
        complement: `${
          this.title_app
            ? this.title_app === "HIK Parahyangan"
              ? "BPRS HIK Parahyangan"
              : this.title_app
            : this.title_app
        }`,
      };
    },
    link: function() {
      return [
        {
          rel: "icon",
          sizes: "16x16",
          href: this.favicon_app,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
