import FormTSavingAccountDetail from "../form";

export default {
  name: "CreateTSavingAccountDetail",
  components: {
    FormTSavingAccountDetail,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identity: {
        mainId: "",
      },
      form: {
        balanceUsable: 0,
        openDate: ""
      },
    };
  },
  mounted() {
    this.validationDomain = window.location.hostname;
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.helperGetDataSavingAccountById();
    },
    async helperGetDataSavingAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTSavingAccountDetailRef.routeToPageListSavingAccountDetail();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTSavingAccountDetailRef.getDefaultFormValue(
              resp.data.data
            );
            this.form.balanceUsable = resp.data.data.balanceUsable;
            this.form.openDate = resp.data.data.openDate
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTSavingAccountDetailRef.routeToPageListSavingAccountDetail(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTSavingAccountDetailRef.routeToPageListSavingAccountDetail(),
          });
        }
      }
    },
    async addDataSavingAccountDetail(payload) {
      const data = {
        transactionCodeId: payload.transactionCodeId,
        documentProofNumber: payload.documentProofNumber,
        transactionDate: payload.transactionDate,
        valutaDate: payload.valutaDate,
        nominal: payload.nominal,
        savingAccountId: this.identity.mainId,
        description: payload.description,
        accountNumber: payload.accountNumber,
        transactionNumber: payload.transactionNumber,
        journalRecord: payload.journalRecord,
        representative: payload.representative,
        isKycIncluded: payload.isKycIncluded,
      };
      let date = this.form.openDate === this.fetchAppSession(
        "@vue-session/application"
      ).system_date; 

      const balanceUsable = this.form.balanceUsable + data.nominal;
      const openDate = date;

      let description = payload.description.toLowerCase();
      if (this.validationDomain==='pojok.bprshikp.co.id') {
      if (description.includes("setor")) {
        if (balanceUsable < 0 && openDate) {
          this.$buefy.dialog.alert({
            message: "Setoran Awal Harus Melebihi Saldo Minimum !",
            title: "Gagal !",
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          if (window.navigator.onLine === false) {
            this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
            return;
          }
          const confirm = await this.simpleConfirmation(
            `Proses Transaksi ? <br/><br/> <small>${
              payload.description
            }</small> <br/> <br/> <b>Rp.${this.convertDecimalCurrency(
              payload.nominal
            )}</b> `,
            "is-info"
          );
          if (!confirm) return;
          this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "POST_DATA",
              endPoint: "transaction",
              reqUrl: "saving-account-detail",
              payload: data,
            });
            await this.simpleWait(1500);
            if (resp.data.code === "SUCCESS") {
              const toastBuefy = this.$buefy.snackbar.open(
                this.$NotificationUtils.snackbarSuccess
              );
              toastBuefy.$on("close", () => {
                this.$refs.formTSavingAccountDetailRef.callBackSubmitSavingAccountDetail(
                  resp
                );
              });
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            console.log(error.response.data.errorFields);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            await this.simpleWait(1000);
            this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = false;
          }
        }
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          `Proses Transaksi ? <br/><br/> <small>${
            payload.description
          }</small> <br/> <br/> <b>Rp.${this.convertDecimalCurrency(
            payload.nominal
          )}</b> `,
          "is-info"
        );
        if (!confirm) return;
        this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account-detail",
            payload: data,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            const toastBuefy = this.$buefy.snackbar.open(
              this.$NotificationUtils.snackbarSuccess
            );
            toastBuefy.$on("close", () => {
              this.$refs.formTSavingAccountDetailRef.callBackSubmitSavingAccountDetail(
                resp
              );
            });
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response.data.errorFields);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = false;
        }
      }
    } else {
      if (description.includes("setor")) {
        if (balanceUsable < 0 && openDate) {
          this.$buefy.dialog.alert({
            message: "Setoran Awal Harus Melebihi Saldo Minimum !",
            title: "Gagal !",
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          if (window.navigator.onLine === false) {
            this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
            return;
          }
          const confirm = await this.simpleConfirmation(
            `Proses Transaksi ? <br/><br/> <small>${
              payload.description
            }</small> <br/> <br/> <b>Rp.${this.convertDecimalCurrency(
              payload.nominal
            )}</b> `,
            "is-success"
          );
          if (!confirm) return;
          this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "POST_DATA",
              endPoint: "transaction",
              reqUrl: "saving-account-detail",
              payload: data,
            });
            await this.simpleWait(1500);
            if (resp.data.code === "SUCCESS") {
              const toastBuefy = this.$buefy.snackbar.open(
                this.$NotificationUtils.snackbarSuccess
              );
              toastBuefy.$on("close", () => {
                this.$refs.formTSavingAccountDetailRef.callBackSubmitSavingAccountDetail(
                  resp
                );
              });
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            console.log(error.response.data.errorFields);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            await this.simpleWait(1000);
            this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = false;
          }
        }
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          `Proses Transaksi ? <br/><br/> <small>${
            payload.description
          }</small> <br/> <br/> <b>Rp.${this.convertDecimalCurrency(
            payload.nominal
          )}</b> `,
          "is-success"
        );
        if (!confirm) return;
        this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account-detail",
            payload: data,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            const toastBuefy = this.$buefy.snackbar.open(
              this.$NotificationUtils.snackbarSuccess
            );
            toastBuefy.$on("close", () => {
              this.$refs.formTSavingAccountDetailRef.callBackSubmitSavingAccountDetail(
                resp
              );
            });
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response.data.errorFields);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.$refs.formTSavingAccountDetailRef.property.animation.savingAccountDetail.isLoading = false;
        }
      }
    }
    },
  },
};
