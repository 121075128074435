export default {
  name: "InstallmentMInterbankLiability",
  data() {
    return {
      identity: {
        interbankLiabilityId: "",
      },
      dataForm: {
        reupload: {
          file: "",
        },
        file: "",
        accountBranch: "",
        accountNumber: "",
        accountName: "",
        biCode: "",
        bankName: "",
        interbankLiabilityTypeId: "",
        tenorYear: 0,
        tenorMonth: 0,
        tenorDay: 0,
        interestRate: 0,
        balance: 0,
        openDate: "",
        closeDate: "",
        chartOfAccountId: "",
        plafond: 0,
        installmentDeb: 0,
        feeChartOfAccountId: "",
        tenor: 0,
        chartOfAccountDescription: "",
        interbankAssetsId: "",
        interbankAssetsName: "",
        createdDate: "",
        createdBy: "",
        updatedDate: "",
        updatedBy: "",
        authorizedBy: "",
        authorizedDate: "",
        installment: {
          payment: {
            principalPay: 0,
            marginPay: 0,
            liabilityInstallmentId: "",
            transactionCodeId: "",
          },
          edit: {
            principal: 0,
            margin: 0,
            liabilityInstallmentId: "",
          },
        },
      },
      property: {
        maxValue: {
          JatuhTempo: "",
        },
        animation: {
          addInterbankLiabilityInstallment: {
            isLoading: false,
          },
          editInstallmentInterbankLiability: {
            isLoading: false,
          },
          payInstallmentInterbankLiability: {
            isLoading: false,
          },
          deleteInstallmentInterbankLiability: {
            isLoading: false,
          },
          editInterbankLiabilityInstallment: {
            isLoading: false,
          },
        },
        modal: {
          showModalEditInstallment: false,
          showModalPaymentInstallment: false,
          showModalReuploadInstallment: false,
        },
        listElement: {
          InterbankLiabilityInstallment: {
            message: "",
            downloading: false,
          },
        },
      },
      options: {
        kodeBank: [],
        kodeCoa: [],
        jenisABP: [],
        nomorAba: [],
        tenor: [],
        transactionCode: [],
      },
      table: {
        data: {
          installment: [],
        },
      },
    };
  },
  methods: {
    async editInterbankLiabilityInstallment() {
      let formData = new FormData();
      formData.append("file", this.dataForm.reupload.file);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.editInterbankLiabilityInstallment.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA_FILE",
          reqUrl:
            "interbank-liability-installment/files/" +
            this.identity.interbankLiabilityId,
          endPoint: "transaction",
          payload: formData,
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            setTimeout(() => {
              this.getInterbankLiabilityInstallment();
            }, 1500);
          }, 1000);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.editInterbankLiabilityInstallment.isLoading = false;
      }
    },
    closeModalReuploadInstallmentInterbankLiability() {
      this.property.modal.showModalReuploadInstallment = false;
      this.dataForm.reupload.file = "";
    },
    reuploadInstallmentAbp() {
      this.property.modal.showModalReuploadInstallment = true;
    },
    async addInterbankLiabilityInstallment() {
      let formData = new FormData();
      formData.append("file", this.dataForm.file);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.addInterbankLiabilityInstallment.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_FILE",
          reqUrl:
            "interbank-liability-installment/files/" +
            this.identity.interbankLiabilityId,
          endPoint: "transaction",
          payload: formData,
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            setTimeout(() => {
              this.getInterbankLiabilityInstallment();
            }, 1500);
          }, 1000);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.addInterbankLiabilityInstallment.isLoading = false;
      }
    },
    showModalEditInterbankLiability(props) {
      this.dataForm.installment.edit.principal = props.row.principal;
      this.dataForm.installment.edit.margin = props.row.margin;
      this.dataForm.installment.edit.liabilityInstallmentId =
        props.row.liabilityInstallmentId;
      this.property.modal.showModalEditInstallment = true;
    },
    showModalPaymentInterbankLiability(props) {
      this.dataForm.installment.payment.principalPay = props.row.principal;
      this.dataForm.installment.payment.marginPay = props.row.margin;
      this.dataForm.installment.payment.liabilityInstallmentId =
        props.row.liabilityInstallmentId;
      this.property.modal.showModalPaymentInstallment = true;
    },
    closeModalEditInstallmentInterbankLiability() {
      this.dataForm.installment.edit.principal = 0;
      this.dataForm.installment.edit.margin = 0;
      this.dataForm.installment.edit.liabilityInstallmentId = "";
      this.property.modal.showModalEditInstallment = false;
    },
    closeModalPaymentInstallmentInterbankLiability() {
      this.dataForm.installment.payment.principalPay = 0;
      this.dataForm.installment.payment.marginPay = 0;
      this.dataForm.installment.payment.liabilityInstallmentId = "";
      this.property.modal.showModalPaymentInstallment = false;
    },
    async deleteInstallmentInterbankLiability() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-danger",
        onConfirm: async () => {
          try {
            this.property.animation.deleteInstallmentInterbankLiability.isLoading = true;
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              reqUrl:
                "interbank-liability-installment/interbank-liability-id/" +
                this.identity.interbankLiabilityId,
              endPoint: "transaction",
            });
            if (resp.data.code === "SUCCESS") {
              setTimeout(() => {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getInterbankLiabilityInstallment();
                }, 1500);
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            setTimeout(() => {
              this.property.animation.deleteInstallmentInterbankLiability.isLoading = false;
            }, 1000);
          }
        },
      });
    },
    async editInstallmentInterbankLiability() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Edit Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            this.property.animation.editInstallmentInterbankLiability.isLoading = true;
            const resp = await this.$store.dispatch({
              type: "PUT_DATA",
              reqUrl:
                "interbank-liability-installment/" +
                this.dataForm.installment.edit.liabilityInstallmentId,
              endPoint: "transaction",
              payload: {
                principal: this.dataForm.installment.edit.principal,
                margin: this.dataForm.installment.edit.margin,
              },
            });
            if (resp.data.code === "SUCCESS") {
              this.closeModalEditInstallmentInterbankLiability();
              setTimeout(() => {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getInterbankLiabilityInstallment();
                }, 1500);
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            this.property.animation.editInstallmentInterbankLiability.isLoading = false;
          }
        },
      });
    },
    async payInstallmentInterbankLiability() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Bayar Angsuran Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            this.property.animation.payInstallmentInterbankLiability.isLoading = true;
            const resp = await this.$store.dispatch({
              type: "PUT_DATA",
              reqUrl:
                "interbank-liability-installment/payment/" +
                this.dataForm.installment.payment.liabilityInstallmentId,
              endPoint: "transaction",
              payload: {
                principalPay: this.dataForm.installment.payment.principalPay,
                marginPay: this.dataForm.installment.payment.marginPay,
                transactionCodeId: this.dataForm.installment.payment
                  .transactionCodeId,
              },
            });
            if (resp.data.code === "SUCCESS") {
              this.closeModalPaymentInstallmentInterbankLiability();
              setTimeout(() => {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getInterbankLiabilityInstallment();
                }, 1500);
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            this.property.animation.payInstallmentInterbankLiability.isLoading = false;
          }
        },
      });
    },
    async getInterbankLiabilityById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListInterbankLiability();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "interbankliability/" + this.identity.interbankLiabilityId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.accountBranch = resp.data.data.accountBranch;
            this.dataForm.accountNumber = resp.data.data.accountNumber;
            this.dataForm.accountName = resp.data.data.accountName;
            this.dataForm.biCode = resp.data.data.biCode;
            this.dataForm.bankName = resp.data.data.bankName;
            this.dataForm.tenor =
              resp.data.data.tenorDay !== 0
                ? 1
                : resp.data.data.tenorMonth !== 0
                ? 2
                : resp.data.data.tenorYear !== 0
                ? 3
                : 0;
            this.dataForm.tenorDay = resp.data.data.tenorDay;
            this.dataForm.tenorMonth = resp.data.data.tenorMonth;
            this.dataForm.tenorYear = resp.data.data.tenorYear;
            this.dataForm.interestRate = resp.data.data.interestRate;
            this.dataForm.balance = resp.data.data.balance;
            this.dataForm.openDate = resp.data.data.openDate;
            this.dataForm.closeDate = resp.data.data.closeDate;
            this.getInterbankLiabilityInstallment();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListInterbankLiability(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListInterbankLiability(),
          });
        }
      }
    },
    async getInterbankLiabilityInstallment() {
      this.table.data.installment = [];
      this.property.listElement.InterbankLiabilityInstallment.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl:
            "interbank-liability-installment/interbank-liability-id/" +
            this.identity.interbankLiabilityId,
          endPoint: "transaction",
        });
        if (resp.data.code === "SUCCESS") {
          this.table.data.installment = resp.data.data;
        } else {
          this.failedGetDataInterbankLiabilityInstallment(resp);
        }
      } catch (error) {
        this.handleErrorInterbankLiabilityInstallment(error);
      } finally {
        this.property.listElement.InterbankLiabilityInstallment.downloading = false;
      }
    },
    handleErrorInterbankLiabilityInstallment(error) {
      console.log(error.response);
      this.table.data.installment = [];
      this.property.listElement.InterbankLiabilityInstallment.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataInterbankLiabilityInstallment(resp) {
      this.table.data.installment = [];
      this.property.listElement.InterbankLiabilityInstallment.message =
        resp.data.message;
    },
    calculateJangkaWaktuDay() {
      if (this.dataForm.openDate !== "") {
        var day =
          this.dataForm.tenorDay === 0 || this.dataForm.tenorDay === ""
            ? 1
            : this.dataForm.tenorDay;
        var calculate = new Date(
          new Date(this.dataForm.openDate).getTime() + day * 24 * 60 * 60 * 1000
        ).toISOString();
        this.dataForm.closeDate = calculate;
      } else {
        return;
      }
    },
    calculateJangkaWaktuMonth() {
      if (this.dataForm.openDate !== "") {
        var month =
          this.dataForm.tenorMonth === 0 || this.dataForm.tenorMonth === ""
            ? 1
            : this.dataForm.tenorMonth;
        var calculate = new Date(
          new Date(this.dataForm.openDate).getTime() +
            month * 31 * 24 * 60 * 60 * 1000
        ).toISOString();
        this.dataForm.closeDate = calculate;
      }
    },
    calculateJangkaWaktuYear() {
      if (this.dataForm.openDate !== "") {
        var year =
          this.dataForm.tenorYear === 0 || this.dataForm.tenorYear === ""
            ? 1
            : this.dataForm.tenorYear;
        var calculate = new Date(
          new Date(this.dataForm.openDate).getTime() +
            year * 365 * 24 * 60 * 60 * 1000
        ).toISOString();
        this.dataForm.closeDate = calculate;
      }
    },
    changeOpenDate() {},
    changeJatuhTempo() {
      if (this.dataForm.openDate !== "") {
        var endDate = new Date(this.dataForm.closeDate);
        var startDate = new Date(this.dataForm.openDate);
        var calculate = Math.abs(endDate.getTime() - startDate.getTime());
        var result = Math.ceil(calculate / (1000 * 3600 * 24));

        switch (this.dataForm.tenor) {
          case 1:
            this.dataForm.tenorDay = Number(`${result}`);
            break;
          case 2:
            var calculateMonth = `${result / 31}`;
            var findComma = calculateMonth.indexOf(".");
            this.dataForm.tenorMonth = Number(
              calculateMonth.slice(0, findComma + 2)
            );
            break;
          case 3:
            var calculateYear = `${result / 365}`;
            var findComma = calculateYear.indexOf(".");
            this.dataForm.tenorYear = Number(
              calculateYear.slice(0, findComma + 2)
            );
            break;

          default:
            return;
            break;
        }
      }
    },
    changeTenor() {
      this.dataForm.tenorYear = 0;
      this.dataForm.tenorDay = 0;
      this.dataForm.tenorMonth = 0;
      this.dataForm.closeDate = "";
      var periodeType = 0;
      if (this.dataForm.openDate !== "") {
        switch (this.dataForm.tenor) {
          case 1:
            periodeType = 1;
            break;

          case 2:
            periodeType = 31;
            break;

          case 3:
            periodeType = 365;
            break;
          default:
            break;
        }
        var calculate = new Date(
          new Date(this.dataForm.openDate).getTime() +
            periodeType * 24 * 60 * 60 * 1000
        ).toISOString();
        this.property.maxValue.JatuhTempo = calculate;
      }
    },

    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            const description = i.description;
            this.options.kodeCoa.push({ text, value, description });
          });
        }
      } catch (error) {}
    },
    async getReferenceInterbankAssets() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_TRANSACTION",
          {
            url: "interbankAssets",
            params: {
              interbankAssetsTypeId: "",
              accountNumber: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.interbankAssetsId;
            const text = `${i.rinterbankAssetsType.interbankAssetsTypeName} - ${i.accountNumber} - ${i.bankName}`;
            const accountName = i.accountName;
            this.options.nomorAba.push({ text, value, accountName });
          });
        }
      } catch (error) {}
    },
    changeNomorAba() {
      this.options.nomorAba.map((i) => {
        if (i.value === this.dataForm.interbankAssetsId) {
          this.dataForm.interbankAssetsName = i.accountName;
        }
      });
    },
    routeToPageListInterbankLiability() {
      this.$router.push("/interbank-liability");
    },
    appendDefaultOptionSelect() {
      this.options.tenor.push(
        { text: "Harian", value: 1 },
        { text: "Bulanan", value: 2 },
        { text: "Tahunan", value: 3 }
      );
    },
    clearIdentifierInterbankLiabilityIdOnSessionStorage() {
      sessionStorage.removeItem("INTERBANK_LIABILITY_ID");
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              page: 0,
              name: "",
              transactionCodes: this.fetchAppSession(
                "@vue-session/config-bundle"
              ).VUE_APP_TRX_CODE_INSTALLMENT_ABP,
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.transactionCode = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            this.options.transactionCode.push({ text, value });
          });
        }
      } catch (error) {}
    },
    checkingMainId() {
      var params = this.$route.params.id;
      console.log(params);
      if (!params) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListInterbankLiability();
        return;
      }
      this.identity.interbankLiabilityId = this.decryptBASE64(params);
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendDefaultOptionSelect();
    this.getInterbankLiabilityById();
    this.getReferenceTransactionCode();
  },
};
