export default {
  name: "LoanDroppingReport",
  data() {
    return {
      property: {
        filterDto: {
          officeCode: "",
          dateFrom: "",
          dateTo: "",
        },
        animation: {
          isDownloadingFile: false,
        },
        listElement: {
          loanDroppingReport: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "DATA TIDAK DITEMUKAN",
          },
        },
      },
      options: {
        office: [],
      },
      table: {
        data: {
          loanDroppingReport: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFileUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        officeCode: this.property.filterDto.officeCode,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        fileType: fileType,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "loan-realization/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Realisasi_Pembiayaan.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async helperGetOfficeList() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    helperGetSystemDate() {
      this.property.filterDto.dateFrom = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.property.filterDto.dateTo = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    async helperGetLoanRealizationList() {
      this.table.data.loanDroppingReport = [];
      this.property.listElement.loanDroppingReport.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "loan-realization",
          payload: {
            officeCode: this.property.filterDto.officeCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.loanDroppingReport.perPage,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.table.data.loanDroppingReport = resp.data.data.content;
          this.property.listElement.loanDroppingReport.currentPage =
            resp.data.data.pageable.pageNumber + 1;
          this.property.listElement.loanDroppingReport.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataLoanRealization();
        }
      } catch (error) {
        this.handleErrorGetLoanRealization(error);
      } finally {
        this.property.listElement.loanDroppingReport.downloading = false;
      }
    },
    async changePaginationLoanRealizationList(event) {
      this.table.data.loanDroppingReport = [];
      this.property.listElement.loanDroppingReport.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "loan-realization",
          payload: {
            officeCode: this.property.filterDto.officeCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.loanDroppingReport.perPage,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.table.data.loanDroppingReport = resp.data.data.content;
          this.property.listElement.loanDroppingReport.currentPage =
            resp.data.data.pageable.pageNumber + 1;
          this.property.listElement.loanDroppingReport.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataLoanRealization();
        }
      } catch (error) {
        this.handleErrorGetLoanRealization(error);
      } finally {
        this.property.listElement.loanDroppingReport.downloading = false;
      }
    },
    failedGetDataLoanRealization(resp) {
      this.table.data.loanDroppingReport = [];
      this.property.listElement.loanDroppingReport.rows = 0;
      this.property.listElement.loanDroppingReport.message = resp.data.message;
    },
    handleErrorGetLoanRealization(error) {
      console.log(error.response);
      this.table.data.loanDroppingReport = [];
      this.property.listElement.loanDroppingReport.rows = 0;
      this.property.listElement.loanDroppingReport.currentPage = 1;
      this.property.listElement.loanDroppingReport.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
  },
  mounted() {
    this.helperGetOfficeList();
    this.helperGetSystemDate();
  },
};
