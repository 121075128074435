var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-end w-full"},[_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-link","icon-right":"microsoft-excel","loading":_vm.property.animation.isDownloadingFile,"disabled":_vm.property.animation.isDownloadingFile},on:{"click":_vm.onDownloadFile}},[_vm._v(" Download Excel")])],1),_c('div',{staticClass:"p-1 overflow-auto"},[_c('b-table',{staticClass:"my-1.5",attrs:{"mobile-cards":false,"data":_vm.table.data.nominatif,"striped":"","bordered":"","narrowed":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.nominatif.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.nominatif.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.nominatif.message ? _vm.property.listElement.nominatif.message : "DATA NOT FOUND")+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Nama","field":"cifIdName","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.cifIdName))])]}}])}),_c('b-table-column',{attrs:{"label":"Alamat","field":"cifIdAddress","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.cifIdAddress))])]}}])}),_c('b-table-column',{attrs:{"label":"Kode Produk","field":"loanCode","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.loanCode))])]}}])}),_c('b-table-column',{attrs:{"label":"No Kontrak","field":"loanAccountNumber","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.loanAccountNumber))])]}}])}),_c('b-table-column',{attrs:{"label":"No Rekening","field":"savingAccountNumber","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.savingAccountNumber))])]}}])}),_c('b-table-column',{attrs:{"label":"Cabang","field":"officeCode","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.officeCode))])]}}])}),_c('b-table-column',{attrs:{"label":"Tanggal Transaksi","field":"transactionDate","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.transactionDate))])]}}])}),_c('b-table-column',{attrs:{"label":"Nilai","field":"nominal","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.nominal ? props.row.nominal .toFixed(2) .replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0))])]}}])}),_c('b-table-column',{attrs:{"label":"Nilai Terbayar","field":"nominalPay","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.nominalPay ? props.row.nominalPay .toFixed(2) .replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0))])]}}])}),_c('b-table-column',{attrs:{"label":"Angsuran","field":"nominalInstallment","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.nominalInstallment ? props.row.nominalInstallment .toFixed(2) .replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0))])]}}])}),_c('b-table-column',{attrs:{"label":"User Entry","field":"userCodeOfCreator","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.userCodeOfCreator))])]}}])}),_c('b-table-column',{attrs:{"label":"Status","field":"statusName","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.statusName))])]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.nominatif.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.nominatif.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationNominatifWriteOff},model:{value:(_vm.property.listElement.nominatif.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.nominatif, "currentPage", $$v)},expression:"property.listElement.nominatif.currentPage"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }