export default {
  name: "ListSbr",
  data() {
    return {
      property: {
        listElement: {
          sbr: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        animation: {
          authorizedBulkMortgageRegisterNumber: {
            isLoading: false,
          },
          addRatioCar: {
            isLoading: false,
          },
          editRatioCar: {
            isLoading: false,
          },
        },
        filterDto: {
          numberSBR: "",
        },
      },
      identity: {
        carId: "",
      },
      table: {
        data: {
          sbr: [],
        },
      },
    };
  },
  methods: {
    async authorizedBulkMortgageRegisterNumber() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const getTotalUnAuthorized = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl: "mortgage-register-number/total-unauthorized",
        });
        if (getTotalUnAuthorized.data.code === "SUCCESS") {
          if (getTotalUnAuthorized.data.data === 0) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: "Tidak Terdapat Data Yang Belum Otorisasi",
              confirmText: "Ok",
              type: "is-danger",
            });
            return;
          }
          this.$buefy.dialog.confirm({
            message: `Otorisasi ${getTotalUnAuthorized.data.data} Data Sbr ?`,
            type: "is-success",
            onConfirm: async () => {
              this.property.animation.authorizedBulkMortgageRegisterNumber.isLoading = true;
              try {
                const resp = await this.$store.dispatch({
                  type: "PUT_DATA_AUTHORIZED",
                  endPoint: "loan",
                  reqUrl: "mortgage-register-number/authorized/bulk",
                });
                setTimeout(() => {
                  if (resp.data.code === "SUCCESS") {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    this.property.listElement.sbr.currentPage = 1;
                    this.property.filterDto.numberSBR = "";
                    this.property.listElement.sbr.rows = 0;
                    this.getSBRList();
                  } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                    this.$buefy.dialog.alert({
                      title: "Harap Tunggu !",
                      message: resp.data.errorFields
                        ? this.globalNotificationErrorFieldToText(
                            resp.data.errorFields
                          )
                        : resp.data.message,
                      confirmText: "Ok",
                      type: "is-danger",
                    });
                  } else {
                    this.$buefy.dialog.alert({
                      title: "Gagal !",
                      message: `${resp.data.message}`,
                      confirmText: "Ok",
                      type: "is-danger",
                    });
                  }
                }, 1000);
              } catch (error) {
                setTimeout(() => {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: error.response
                      ? `${error.response.statusText}`
                      : "Terjadi Kesalahan",
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }, 500);
              } finally {
                setTimeout(() => {
                  this.property.animation.authorizedBulkMortgageRegisterNumber.isLoading = false;
                }, 500);
              }
            },
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${getTotalUnAuthorized.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 500);
      }
    },
    routerLinkSbrAdd() {
      this.$router.push("/loan/mortgage-register-number/inventory/new");
    },
    async deleteSbr(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "mortgage-register-number/" + props.row.mortgageRegisterId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.sbr.currentPage = 1;
                  this.property.filterDto.numberSBR = "";
                  this.property.listElement.sbr.rows = 0;
                  this.getSBRList();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getSBRList() {
      this.table.data.sbr = [];
      this.property.listElement.sbr.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-register-number/inventory/simple-list",
          payload: {
            page: 0,
            size: this.property.listElement.sbr.perPage,
            mortgageRegisterNumber: "",
            usedStatusId: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sbr = resp.data.data.content;
            this.property.listElement.sbr.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataSBR(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListSBR(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sbr.downloading = false;
        }, 1000);
      }
    },
    async searchByNumberSbr(event) {
      this.table.data.sbr = [];
      this.property.listElement.sbr.downloading = true;
      const timeout = 1000;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-register-number/inventory/simple-list",
          payload: {
            usedStatusId: "",
            mortgageRegisterNumber: this.property.filterDto.numberSBR,
            page: 0,
            size: this.property.listElement.sbr.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sbr = resp.data.data.content;
            this.property.listElement.sbr.rows = resp.data.data.totalElements;
            this.property.listElement.sbr.currentPage = 1;
            this.property.listElement.sbr.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataSBR(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListSBR(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sbr.downloading = false;
        }, timeout);
      }
    },
    async changePaginationSbr(event) {
      this.table.data.sbr = [];
      this.property.listElement.sbr.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-register-number/inventory/simple-list",
          payload: {
            page: event - 1,
            size: this.property.listElement.sbr.perPage,
            mortgageRegisterNumber: this.property.filterDto.numberSBR,
            usedStatusId: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sbr = resp.data.data.content;
            this.property.listElement.sbr.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataSBR(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListSBR(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sbr.downloading = false;
        }, 1000);
      }
    },
    failedGetDataSBR(resp) {
      this.table.data.sbr = [];
      this.property.listElement.sbr.rows = 0;
      this.property.listElement.sbr.message = resp.data.message;
    },
    handleErrorListSBR(error) {
      console.log(error.response);
      this.table.data.sbr = [];
      this.property.listElement.sbr.rows = 0;
      this.property.listElement.sbr.currentPage = 1;
      this.property.listElement.sbr.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    routerLinkDetailSbr(props) {
      this.$router.push(
        `/loan/mortgage-register-number/inventory/${this.encryptBASE64(
          `${props.row.mortgageRegisterId}`
        )}`
      );
    },
    async authorizationMortgageRegisterNumber(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "loan",
              reqUrl:
                "mortgage-register-number/authorized/" +
                props.row.mortgageRegisterId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.sbr.currentPage = 1;
                this.property.filterDto.numberSBR = "";
                this.property.listElement.sbr.rows = 0;
                this.getSBRList();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
  },
  mounted() {
    this.getSBRList();
  },
};
