export default {
  name: "FormBusinessSegment",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          businessSegment: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        businessSegmentCode: "",
        businessSegmentName: "",
        businessSegmentId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
    };
  },
  methods: {
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.businessSegmentId = payload.businessSegmentId;
        this.dataForm.businessSegmentCode = payload.businessSegmentCode;
        this.dataForm.businessSegmentName = payload.businessSegmentName;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    routePageList() {
      this.$router.push("/parameter/business-segment/");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitBusinessSegment() {
      this.$emit("submitBusinessSegment", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
  },
};
