<template>
  <div class="w-full">
    <div v-for="(item, index) in schemeForms" :key="index">
      <div
        v-if="item.show"
        class="flex flex-row flex-wrap justify-between w-full items-center my-4"
      >
        <span class="text-xs w-full md:w-2/6">{{ item.label }}</span>
        <b-field
          class="w-full md:w-3/5"
          :type="{
            'is-danger': item.message
              ? true
              : messageMapper[item.models]
              ? true
              : false,
          }"
          :message="item.message ? item.message : messageMapper[item.models]"
        >
          <b-input
            v-if="
              !item.isInformationComponent &&
                (item.type === 'text' ||
                  item.type === 'number' ||
                  item.type === 'password')
            "
            :readonly="item.disabled"
            :type="item.type"
            v-mask="item.mask"
            @click.native="item.clicked(item)"
            size="is-small"
            :placeholder="item.placeholder"
            class="w-full"
            v-model="form[item.models]"
            @[item.event.type].native="item.event.action"
          ></b-input>
          <b-input
            v-if="!item.isInformationComponent && item.type === 'textarea'"
            :readonly="item.disabled"
            type="textarea"
            size="is-small"
            :placeholder="item.placeholder"
            class="w-full"
            v-model="form[item.models]"
            @[item.event.type].native="item.event.action"
          ></b-input>
          <b-input
            disabled
            v-if="item.isInformationComponent"
            v-mask="item.mask"
            size="is-small"
            :placeholder="item.placeholder"
            class="w-full"
            :value="item.formater ? item.formater(item.value) : item.value"
          ></b-input>
          <money
            v-if="item.type === 'money'"
            class="text-start p-2 text-xs rounded w-full"
            style="outline: 1px solid #e7eaed; height: 30px;"
            v-model="form[item.models]"
            :placeholder="item.placeholder"
            @[item.event.type].native="item.event.action"
          />
          <b-select
            v-if="item.type === 'select'"
            expanded
            class="w-full"
            :disabled="item.disabled"
            size="is-small"
            @[item.event.type].native="item.event.action"
            v-model="form[item.models]"
          >
            <option
              v-for="option in item.selectProperty.options"
              :key="option[item.selectProperty.bindValue]"
              :value="option[item.selectProperty.bindValue]"
            >
              {{ item.selectProperty.bindText(option) }}
            </option>
          </b-select>
          <b-datepicker
            v-if="item.type === 'date'"
            :show-week-number="true"
            @input="
              (e) => {
                form[item.models] = item.dateParser(e);
                item.event.action(e);
              }
            "
            class="w-full"
            :disabled="item.disabled"
            size="is-small"
            :date-formatter="
              (e) =>
                item.formater
                  ? item.formater(form[item.models])
                  : dateToMonthText(e)
            "
            :value="form[item.models] ? new Date(form[item.models]) : null"
            :placeholder="
              item.placeholder ? item.placeholder : 'Klik Untuk Memilih'
            "
            icon="calendar-today"
            :icon-right="form[item.models] ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="form[item.models] = ''"
            trap-focus
          >
          </b-datepicker>
          <div
            v-if="item.type === 'radio'"
            class="block flex justify-between w-full"
          >
            <b-radio
              v-for="option in item.radioProperty.options"
              :key="option.value"
              v-model="form[item.models]"
              type="is-success"
              size="is-small"
              @[item.event.type].native="item.event.action"
              :native-value="option.value"
              >{{ option.text }}</b-radio
            >
          </div>
          <div
            v-if="item.type === 'checkbox'"
            class="flex justify-start w-full"
          >
            <b-checkbox
              type="is-success"
              @[item.event.type]="item.event.action"
              size="is-small"
              v-model="form[item.models]"
              :true-value="item.checkboxProperty.trueValue"
              :false-value="item.checkboxProperty.falseValue"
              >{{ item.checkboxProperty.label }}</b-checkbox
            >
          </div>
          <model-list-select
            v-if="item.type === 'model-list-select'"
            :option-value="item.selectProperty.bindValue"
            :custom-text="(data) => item.selectProperty.bindText(data)"
            :list="item.selectProperty.options"
            v-model="form[item.models]"
            :placeholder="item.placeholder"
            class="w-full"
            @[item.event.type]="item.event.action"
            style="font-size: 12px"
          />
          <p v-if="item.appendText" class="control">
            <span class="button is-static" style="font-size: 12px">{{
              item.appendText
            }}</span>
          </p>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { ModelMapper } from "./form-builder-service";
import { mergingObject } from "@/service/serviceHelpers";
export default {
  name: "FormBuilder",
  props: {
    scheme: {
      type: Object,
      required: true,
    },
    value: null,
    messageMapper: {
      type: Object,
      required: false,
      default: {},
    },
  },
  computed: {
    form: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("input", val);
      },
    },
    schemeForms() {
      let objects = {};
      for (const key in this.scheme) {
        let model = ModelMapper(this.scheme[key].type);
        objects[key] = mergingObject({ ...model }, { ...this.scheme[key] });
      }
      return objects;
    },
  },
  methods: {
    sayHello() {
      console.log("hello");
    },
  },
};
</script>
