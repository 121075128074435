export default {
  name: "ListTLoanInstallmentCorrection",
  data() {
    return {
      property: {
        listElement: {
          loanPaymentReturn: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        animation: {
          checkingLoanAccount: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingLoanAccount: false,
          showModalResultLoanAccount: false,
        },
        filterDto: {
          accountNumber: "",
          name: "",
        },
      },
      dataForm: {
        loanAccountChecking: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      session: {
        loanAccountChecking: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      table: {
        data: {
          loanPaymentReturn: [],
          loanAccount: [],
        },
      },
    };
  },
  methods: {
    routeToPageRincian(props) {
      this.$router.push(
        `/loan/installment-correction/${this.encryptBASE64(
          props.row.loanPayReturnId
        )}`
      );
    },
    async authorizedLoanPaymentReturn(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "loan-payment-return/authorized/" + props.row.loanPayReturnId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.name = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.listElement.loanPaymentReturn.rows = 0;
                  this.getLoanPaymentReturn();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    failedGetLoanAccount(resp) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.message = resp.data.message;
    },
    async changePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.session.loanAccountChecking.accountNumber,
            cifIdName: this.session.loanAccountChecking.cifIdName,
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 500);
      }
    },
    handleErrorLoanAccount(error) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    routeToPageCreate(props) {
      this.$router.push(
        `/loan/installment-correction/new?refId=${this.encryptBASE64(
          JSON.stringify({
            loanAccountNumber: props.row.loanAccountNumber,
          })
        )}`
      );
    },
    async checkingLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.checkingLoanAccount.isLoading = true;
      this.session.loanAccountChecking.accountNumber = this.dataForm.loanAccountChecking.accountNumber;
      this.session.loanAccountChecking.cifIdName = this.dataForm.loanAccountChecking.cifIdName;
      this.dataForm.loanAccountChecking.accountNumber = "";
      this.dataForm.loanAccountChecking.cifIdName = "";
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.session.loanAccountChecking.accountNumber,
            page: 0,
            size: this.property.listElement.loanAccount.perPage,
            cifIdName: this.session.loanAccountChecking.cifIdName,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.property.modal.showModalCheckingLoanAccount = false;
            this.property.modal.showModalResultLoanAccount = true;
          } else {
            this.dataForm.loanAccountChecking.accountNumber = this.session.loanAccountChecking.accountNumber;
            this.dataForm.loanAccountChecking.cifIdName = this.session.loanAccountChecking.cifIdName;
            this.session.loanAccountChecking.accountNumber = "";
            this.session.loanAccountChecking.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 500);
      } catch (error) {
        this.dataForm.loanAccountChecking.accountNumber = this.session.loanAccountChecking.accountNumber;
        this.dataForm.loanAccountChecking.cifIdName = this.session.loanAccountChecking.cifIdName;
        this.session.loanAccountChecking.accountNumber = "";
        this.session.loanAccountChecking.cifIdName = "";
        console.log(error.response);
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.animation.checkingLoanAccount.isLoading = false;
        }, 500);
      }
    },
    closePopUpCheckingLoanAccount() {
      this.property.modal.showModalCheckingLoanAccount = false;
      this.dataForm.loanAccountChecking.accountNumber = "";
      this.dataForm.loanAccountChecking.cifIdName = "";
    },
    closePopUpResultCheckingLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.perPage = 5;
    },
    async deleteLoanPaymentReturn(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-payment-return/" + props.row.loanPayReturnId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.loanPaymentReturn.currentPage = 1;
                  this.property.filterDto.name = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.listElement.loanPaymentReturn.rows = 0;
                  this.getLoanPaymentReturn();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    clearInputModalCheckingLoanAccount() {
      this.property;
    },
    async findLoanPaymentReturn() {
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan/loan-return-payment",
          payload: {
            name: this.property.filterDto.name,
            accountNumber: this.property.filterDto.accountNumber,
            page: 0,
            size: this.property.listElement.loanPaymentReturn.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaymentReturn = resp.data.data.content;
            this.property.listElement.loanPaymentReturn.rows =
              resp.data.data.totalElements;
            this.property.listElement.loanPaymentReturn.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataLoanPaymentReturn(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanPaymentReturn(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaymentReturn.downloading = false;
        }, timeout + 500);
      }
    },
    async changePaginationLoanPaymentReturn(event) {
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan/loan-payment-return",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            name: this.property.filterDto.name,
            page: event - 1,
            size: this.property.listElement.loanPaymentReturn.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaymentReturn = resp.data.data.content;
            this.property.listElement.loanPaymentReturn.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaymentReturn(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanPaymentReturn(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaymentReturn.downloading = false;
        }, timeout);
      }
    },
    async getLoanPaymentReturn() {
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-payment-return",
          payload: {
            accountNumber: "",
            name: "",
            page: 0,
            size: this.property.listElement.loanPaymentReturn.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaymentReturn = resp.data.data.content;
            this.property.listElement.loanPaymentReturn.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaymentReturn(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanPaymentReturn(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaymentReturn.downloading = false;
        }, 500);
      }
    },
    handleErrorLoanPaymentReturn(error) {
      console.log(error.response);
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.rows = 0;
      this.property.listElement.loanPaymentReturn.currentPage = 1;
      this.property.listElement.loanPaymentReturn.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataLoanPaymentReturn(resp) {
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.rows = 0;
      this.property.listElement.loanPaymentReturn.message = resp.data.message;
    },
  },
  mounted() {
    this.getLoanPaymentReturn();
  },
};
