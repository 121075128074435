export default {
  name: "ListMMortgageSlthe",
  data() {
    return {
      property: {
        filterDto: {
          setupDate: "",
          slthePrice: "",
        },
        listElement: {
          mortgageSlthe: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      table: {
        data: {
          mortgageSlthe: [],
        },
      },
    };
  },
  methods: {
    routerToPageAddMortgageSlthe() {
      this.$router.push("add");
    },
    async deleteMortgageSlthe(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        if (window.confirm("Hapus Data Ini ?")) {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "mortgage-metal-price/" + props.row.mortgageSltheId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.mortgageSlthe.currentPage = 1;
                this.property.filterDto.mortgageSlthe = "";
                this.property.listElement.mortgageSlthe.rows = 0;
                this.getMortgageSlthe();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }
      }
    },
    routeToPageDetailMortgageSlthe(props) {
      sessionStorage.setItem(
        "MORTGAGE_SLTHE_ID",
        props.row.mortgageMetalPriceId
      );
      this.$router.push("detail");
    },
    routeToPageEditMortgageSlthe(props) {
      sessionStorage.setItem(
        "MORTGAGE_SLTHE_ID",
        props.row.mortgageMetalPriceId
      );
      this.$router.push("edit");
    },
    async changePaginationMortgageSlthe(event) {
      this.table.data.mortgageSlthe = [];
      this.property.listElement.mortgageSlthe.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-metal-price",
          payload: {
            setupDate: "",
            slthePrice: "",
            page: event - 1,
            size: this.property.listElement.mortgageSlthe.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageSlthe = resp.data.data.content;
            this.property.listElement.mortgageSlthe.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataMortgageSlthe(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageSlthe(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageSlthe.downloading = false;
        }, timeout);
      }
    },
    async authorizationGetMortgageSlthe(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        if (window.confirm("Otorisasi Data Ini ?")) {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "loan",
              reqUrl:
                "mortgage-metal-price/authorized/" +
                props.row.mortgageMetalPriceId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.mortgageSlthe.currentPage = 1;
                this.property.filterDto.mortgageSlthe = "";
                this.property.listElement.mortgageSlthe.rows = 0;
                this.getMortgageSlthe();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }
      }
    },
    async findSlthe() {
      this.table.data.mortgageSlthe = [];
      this.property.listElement.mortgageSlthe.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-metal-price",
          payload: {
            slthePrice: this.property.filterDto.slthePrice,
            setupDate: this.property.filterDto.setupDate,
            page: 0,
            size: this.property.listElement.mortgageSlthe.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageSlthe = resp.data.data.content;
            this.property.listElement.mortgageSlthe.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.mortgageSlthe.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataMortgageSlthe(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageSlthe(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageSlthe.downloading = false;
        }, timeout);
      }
    },
    async getMortgageSlthe() {
      this.table.data.mortgageSlthe = [];
      this.property.listElement.mortgageSlthe.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-metal-price",
          payload: {
            setupDate: "",
            slthePrice: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageSlthe = resp.data.data.content;
            this.property.listElement.mortgageSlthe.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataMortgageSlthe(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageSlthe(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageSlthe.downloading = false;
        }, timeout);
      }
    },
    handleErrorGetMortgageSlthe(error) {
      console.log(error.response);
      this.table.data.mortgageSlthe = [];
      this.property.listElement.mortgageSlthe.rows = 0;
      this.property.listElement.mortgageSlthe.currentPage = 1;
      this.property.listElement.mortgageSlthe.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataMortgageSlthe(resp) {
      this.table.data.mortgageSlthe = [];
      this.property.listElement.mortgageSlthe.rows = 0;
      this.property.listElement.mortgageSlthe.message = resp.data.message;
    },
  },
  mounted() {
    this.getMortgageSlthe();
  },
};
