var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"on-cancel":_vm.closeModal,"width":900},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('div',{staticClass:"p-4 bg-white",staticStyle:{"width":"auto"}},[_c('div',{staticClass:"flex justify-start w-6/12 flex-row"},[_c('b-input',{staticStyle:{"max-width":"250px","width":"100%"},attrs:{"size":"is-small","disabled":_vm.property.listElement.findSavingAccount.downloading === true,"placeholder":"No. Rekening Atau Nama Nasabah"},model:{value:(_vm.property.findSavingAccount.accountNumberOrCifIdName),callback:function ($$v) {_vm.$set(_vm.property.findSavingAccount, "accountNumberOrCifIdName", $$v)},expression:"property.findSavingAccount.accountNumberOrCifIdName"}}),_c('b-button',{staticClass:"button-theme-confirm ml-2",attrs:{"type":"is-link","size":"is-small","disabled":_vm.property.listElement.findSavingAccount.downloading === true},on:{"click":_vm.findSavingAccountByAccount}},[_c('b-icon',{attrs:{"icon":"magnify","size":"is-small"}})],1)],1),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.findSavingAccount,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.findSavingAccount.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
                  _vm.property.listElement.findSavingAccount.downloading === false
                )?_c('span',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.property.listElement.findSavingAccount.message ? _vm.property.listElement.findSavingAccount.message : _vm.property.findSavingAccount.cifIdName && _vm.property.findSavingAccount.accountNumber ? _vm.property.listElement.findSavingAccount.message : "Data Not Found")+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"background-theme-hik_account_statement p-0","centered":"","cell-class":"p-1","field":"officeName","label":"Cabang"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var column = ref.column;
return [_c('span',{staticClass:"text-black text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.officeName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"background-theme-hik_account_statement p-0","centered":"","cell-class":"p-1","field":"accountNumber","label":"No Rekening"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var column = ref.column;
return [_c('span',{staticClass:"text-black text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.accountNumber))])]}}])}),_c('b-table-column',{attrs:{"header-class":"background-theme-hik_account_statement p-0","centered":"","cell-class":"p-1","field":"cifIdName","label":"Nama Nasabah"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var column = ref.column;
return [_c('span',{staticClass:"text-black text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.cifIdName ? props.row.qqName ? ((props.row.cifIdName) + " " + (props.row.qqCode) + " " + (props.row.qqName)) : props.row.cifIdName : "-"))])]}}])}),(_vm.companyName === 'BPRS FORMES')?_c('b-table-column',{attrs:{"header-class":"background-theme-hik_account_statement p-0","centered":"","cell-class":"p-1","field":"cifIdAddress","label":"Alamat"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var column = ref.column;
return [_c('span',{staticClass:"text-black text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.cifIdAddress ? props.row.cifIdAddress : "-"))])]}}],null,false,1226178)}):_vm._e(),_c('b-table-column',{attrs:{"header-class":"background-theme-hik_account_statement p-0","centered":"","field":"savingName","cell-class":"p-1","label":"Jenis Tabungan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var column = ref.column;
return [_c('span',{staticClass:"text-black text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.savingName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"background-theme-hik_account_statement p-0","centered":"","field":"statusName","cell-class":"p-1","label":"Status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var column = ref.column;
return [_c('span',{staticClass:"text-black text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.statusName))])]}}])}),_c('b-table-column',{attrs:{"header-class":"background-theme-hik_account_statement p-0","centered":"","field":"actions","cell-class":"p-1","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var column = ref.column;
return [_c('span',{staticClass:"text-black text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-button',{staticClass:"font-bold border-none outline-none text-white hover:text-white focus:text-white ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Pilih","type":"is-text"},on:{"click":function($event){return _vm.selectSavingAccount(props)}}})]}}])})],1)],1),_c('div')])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }