import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

export default {
  name: "ListTSavingAccountDetailRak",
  data() {
    return {
      property: {
        filterDto: {
          accountNumber: "",
          dateFrom: "",
          dateTo: "",
          statusAccountId: "",
        },
        animation: {
          CheckingSavingAccount: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
        listElement: {
          SavingAccountDetaiRak: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        modal: {
          showModalCheckingSavingAccount: false,
          showModalResultListSavingAccount: false,
          showModalPrint: false,
        },
      },
      form: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
        printData: "",
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      options: {
        statusAccount: [],
      },
      table: {
        data: {
          SavingAccountDetaiRak: [],
          savingAccount: [],
        },
      },
    };
  },
  methods: {
    routeToPageReversalSavingAccountDetailRak(props) {
      sessionStorage.setItem(
        "SAVING_ACCOUNT_DETAIL_ID",
        props.row.savingAccountDetailId
      );
      this.$router.push("/saving/saving-account-detail/reversal/add");
    },
    routeToPageDetailSavingAccountDetailRak(props) {
      this.$router.push(
        `/saving/saving-account-detail-rak/${this.encryptBASE64(
          `${props.row.savingAccountDetailId}`
        )}`
      );
    },
    async authorizeSavingAccountDetailRak(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/transaction_cash/authorized/" +
            props.row.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.SavingAccountDetaiRak.currentPage = 1;
          this.property.filterDto.accountNumber = "";
          this.property.filterDto.dateTo = "";
          this.property.filterDto.dateFrom = "";
          this.property.listElement.SavingAccountDetaiRak.rows = 0;
          this.helperGetDataTableSavingAccountRak();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    closeModalCheckingSavingAccount() {
      this.property.modal.showModalCheckingSavingAccount = false;
      this.clearModalCheckingSavingAccount();
    },
    clearModalCheckingSavingAccount() {
      this.form.checkingSavingAccount.accountNumber = "";
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
        this.form.checkingSavingAccount.accountNumber = "";
        this.form.checkingSavingAccount.cifIdName = "";

        try {
          this.property.animation.CheckingSavingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.content;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.property.modal.showModalResultListSavingAccount = true;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
          this.form.checkingSavingAccount.accountNumber = "";
          this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
          this.form.checkingSavingAccount.cifIdName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.CheckingSavingAccount.isLoading = false;
        }
      }
    },
    async helperGetDataTableSavingAccountRak() {
      this.table.data.SavingAccountDetaiRak = [];
      this.property.listElement.SavingAccountDetaiRak.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-rak",
          payload: {
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
            accountNumber: "",
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.SavingAccountDetaiRak = resp.data.data.content;
          this.property.listElement.SavingAccountDetaiRak.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountDetaiRak(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetaiRak(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.SavingAccountDetaiRak.downloading = false;
      }
    },
    async paginationSavingAccountDetailRak(event) {
      this.table.data.SavingAccountDetaiRak = [];
      this.property.listElement.SavingAccountDetaiRak.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-rak",
          payload: {
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.taskInterbankAsset.perPage,
            accountNumber: this.property.filterDto.accountNumber,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.SavingAccountDetaiRak = resp.data.data.content;
          this.property.listElement.SavingAccountDetaiRak.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountDetaiRak(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetaiRak(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.SavingAccountDetaiRak.downloading = false;
      }
    },

    async searchSavingAccountDetailRak() {
      this.table.data.SavingAccountDetaiRak = [];
      this.property.listElement.SavingAccountDetaiRak.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-rak",
          payload: {
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.SavingAccountDetaiRak.perPage,
            accountNumber: this.property.filterDto.accountNumber,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.SavingAccountDetaiRak = resp.data.data.content;
          this.property.listElement.SavingAccountDetaiRak.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountDetaiRak(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetaiRak(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.SavingAccountDetaiRak.downloading = false;
      }
    },

    handleCatchErrorSavingAccountDetaiRak(error) {
      console.log(error.response);
      this.table.data.SavingAccountDetaiRak = [];
      this.property.listElement.SavingAccountDetaiRak.rows = 0;
      this.property.listElement.SavingAccountDetaiRak.currentPage = 1;
      this.property.listElement.SavingAccountDetaiRak.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataSavingAccountDetaiRak(resp) {
      this.table.data.SavingAccountDetaiRak = [];
      this.property.listElement.SavingAccountDetaiRak.rows = 0;
      this.property.listElement.SavingAccountDetaiRak.message =
        resp.data.message;
    },
    routeToPageAddSavingAccountDetailRak(props) {
      this.$router.push(
        `/saving/saving-account-detail-rak/new?refId=${this.encryptBASE64(
          JSON.stringify({
            savingAccountId: props.row.savingAccountId,
          })
        )}`
      );
    },
    showModalPrint(props) {
      this.property.modal.showModalPrint = true;
      this.form.printData = props.row;
    },
    closeModalPrint() {
      this.form.printData = "";
      this.property.modal.showModalPrint = false;
    },
    getDateFormat(valDate) {
      var date = new Date(valDate);

      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    async cetakSlipTrx() {
      this.property.animation.cetakSlip.isLoading = true;

      let pdf = new jsPDF("landscape", "mm", "a4");

      setTimeout(() => {
        html2canvas(document.querySelector("#table-slip-rak"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-slip-rak").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 21, 9);
          pdf.autoPrint();

          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-slip-rak").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          this.property.modal.showModalPrint = false;
        });
      }, 200);
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.helperGetDataTableSavingAccountRak();
    this.getReferenceStatusAccount();
  },
};
