function ENV_PARAMETER_ENDPOINT(endPoint, reqUrl) {
  var baseUrl = "";
  switch (endPoint) {
    case "parameter":
      baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/parameter/${reqUrl}`;
      break;
    case "transaction":
      baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/${reqUrl}`;
      break;
    case "user-management":
      baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/${reqUrl}`;
      break;
    case "loan":
      baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/loan/${reqUrl}`;
      break;
    case "report":
      baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/report/${reqUrl}`;
      break;
    case "process":
      baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/process/${reqUrl}`;
      break;
    case "outgoing-service-appdev":
      baseUrl = `${process.env.VUE_APP_BASE_URL_GATEWAY}/outgoing-service/${reqUrl}`;
      break;
    case "outgoing-service":
      baseUrl = `https://mdw-olibs.bprshikp.co.id/outgoing-service/${reqUrl}`;
      break;
    case "loan-installment":
      baseUrl = `http://localhost:8080/loan-installment/${reqUrl}`;
      break;
    default:
      baseUrl = `http://localhost:8080/${reqUrl}`;
      break;
  }
  return baseUrl;
}

const MGeneral = {
  actions: {
    GET_LIST_DATA({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "GET",
            params: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_SAVE_RESPONSE_DATA({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "GET",
            params: payload,
            responseType: "blob",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_DOWNLOADABLE_ESTIMATE_COLLECTABILITY(
      { commit },
      { officeCode, fileType }
    ) {
      return new Promise(async (resolve, reject) => {
        try {
          var endPoint = "report";
          var reqURL = `report/estimate-collectability/files?officeId=${officeCode}&fileType=${fileType}`;
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqURL);
          const resp = await axios({
            url: baseUrl,
            method: "GET",
            responseType: "blob",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },

    GET_DOWNLOADABLE_SISTEM_INFORMASI_DEBITUR(
      { commit },
      { accountNumber, cifIdName, fileType }
    ) {
      return new Promise(async (resolve, reject) => {
        try {
          var endPoint = "report";
          var reqURL = `report/sistem-informasi-debitur/files?accountNumber=${accountNumber}&cifIdName=${cifIdName}&fileType=${fileType}`;
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqURL);
          const resp = await axios({
            url: baseUrl,
            method: "GET",
            responseType: "blob",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },

    CAT_FILE_IPFS({ commit }, { CID }) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `https://dms-dev.digiard.id/api/v0/cat?arg=${CID}`,
            method: "POST",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    POST_DATA_SAVE_FILE({ commit }, { endPoint, reqUrl, payload, params }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "POST",
            data: payload,
            params: params,
            responseType: "blob",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_DATA_FIND_ALL({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "GET",
            params: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_DATA_FIND_BY({ commit }, { endPoint, reqUrl, payload, header }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "GET",
            params: payload,
            headers: header,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    POST_DATA({ commit }, { endPoint, reqUrl, payload, params }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "POST",
            data: payload,
            params: params,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_DATA_STREAM({ commit }, { endPoint, reqUrl, params }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "GET",
            responseType: "stream",
            params: params,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    POST_DATA_FILE({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios.post(baseUrl, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    PUT_DATA_FILE({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios.put(baseUrl, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    PUT_DATA({ commit }, { endPoint, reqUrl, payload, params }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "PUT",
            data: payload,
            params: params,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    PUT_DATA_AUTHORIZED({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "PUT",
            params: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    DELETE_DATA({ commit }, { endPoint, reqUrl, payload, params }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "DELETE",
            payload,
            params: params,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    DELETE_DATA_BY_QUERY({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "DELETE",
            params: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    POST_DATA_HEADERS({ commit }, { endPoint, reqUrl, payload }) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "POST",
            headers: payload,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    POST_DATA_CASH_OUT(
      { commit },
      { endPoint, reqUrl, payload, params, header }
    ) {
      return new Promise(async (resolve, reject) => {
        try {
          var baseUrl = ENV_PARAMETER_ENDPOINT(endPoint, reqUrl);
          const resp = await axios({
            url: baseUrl,
            method: "POST",
            data: payload,
            params: params,
            headers: header,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};

export default MGeneral;
