export default {
  name: "ListMLoanCollectability",
  data() {
    return {
      property: {
        listElement: {
          loanCollectability: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          loanCollectabilityId: "",
          collectabilityIncomeTypeId: "",
          collectabilityStatusId: "",
        },
      },
      options: {
        jenisPendapatan: [],
        kodeKolektibilitas: [],
      },
      table: {
        data: {
          loanCollectability: [],
        },
      },
    };
  },
  methods: {
    async findLoanCollectability() {
      this.table.data.loanCollectability = [];
      this.property.listElement.loanCollectability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability/simple-list",
          payload: {
            loanCollectabilityId: this.property.filterDto.loanCollectability,
            collectabilityIncomeTypeId: this.property.filterDto
              .collectabilityIncomeTypeId,
            collectabilityStatusId: this.property.filterDto
              .collectabilityStatusId,
            page: 0,
            size: this.property.listElement.loanCollectability.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.loanCollectability = resp.data.data.content;
          this.property.listElement.loanCollectability.rows =
            resp.data.data.totalElements;
          this.property.listElement.loanCollectability.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataLoanCollectability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorLoanCollectability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.loanCollectability.downloading = false;
      }
    },
    routeToPageAddLoanCollectability() {
      this.$router.push("/loan/loan-collectability/new");
    },
    routeToPageEditLoanCollectability(props) {
      this.$router.push(
        `/loan/loan-collectability/${this.encryptBASE64(
          `${props.row.loanCollectabilityId}`
        )}`
      );
    },

    async authorizationLoanCollectability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "loan",
          reqUrl:
            "loan-collectability/authorized/" + props.row.loanCollectabilityId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.filterDto.loanCollectabilityId = "";
          this.property.filterDto.collectabilityIncomeTypeId = "";
          this.property.listElement.loanCollectability.rows = 0;
          this.property.listElement.loanCollectability.currentPage = 1;
          this.getLoanCollectability();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async deleteLoanCollectability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability/" + props.row.loanCollectabilityId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.filterDto.loanCollectabilityId = "";
          this.property.filterDto.collectabilityIncomeTypeId = "";
          this.property.listElement.loanCollectability.rows = 0;
          this.property.listElement.loanCollectability.currentPage = 1;
          this.getLoanCollectability();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handleErrorLoanCollectability(error) {
      console.log(error.response);
      this.table.data.loanCollectability = [];
      this.property.listElement.loanCollectability.rows = 0;
      this.property.listElement.loanCollectability.currentPage = 1;

      this.property.listElement.loanCollectability.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanCollectability(event) {
      this.table.data.loanCollectability = [];
      this.property.listElement.loanCollectability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability/simple-list",
          payload: {
            loanCollectabilityId: this.property.filterDto.loanCollectabilityId,
            collectabilityIncomeTypeId: this.property.filterDto
              .collectabilityIncomeTypeId,
            collectabilityStatusId: this.property.filterDto
              .collectabilityStatusId,
            page: event - 1,
            size: this.property.listElement.loanCollectability.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.loanCollectability = resp.data.data.content;
          this.property.listElement.loanCollectability.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataLoanCollectability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorLoanCollectability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.loanCollectability.downloading = false;
      }
    },
    async getLoanCollectability() {
      this.table.data.loanCollectability = [];
      this.property.listElement.loanCollectability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability/simple-list",
          payload: {
            loanCollectabilityId: "",
            collectabilityIncomeTypeId: "",
            collectabilityStatusId: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.loanCollectability = resp.data.data.content;
          this.property.listElement.loanCollectability.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataLoanCollectability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorLoanCollectability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.loanCollectability.downloading = false;
      }
    },
    failedGetDataLoanCollectability(resp) {
      this.table.data.loanCollectability = [];
      this.property.listElement.loanCollectability.rows = 0;
      this.property.listElement.loanCollectability.message = resp.data.message;
    },
    getReferenceLoanCollectabilityIncomeType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-income-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityIncomeTypeId;
            const text = i.collectabilityIncomeTypeName;
            this.options.jenisPendapatan.push({ text, value });
          });
        });
    },
    getReferenceLoanCollectabilityStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-status",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityStatusId;
            const text = i.collectabilityStatusCode;
            this.options.kodeKolektibilitas.push({ text, value });
            console.log("this is loanCollectability", i);
          });
        });
    },
    appendDefaultOptionSelect() {
      this.options.jenisPendapatan.push({ value: "", text: "Semua Kategori" });
      this.options.kodeKolektibilitas.push({
        value: "",
        text: "Semua Kategori",
      });
    },
  },
  mounted() {
    this.appendDefaultOptionSelect();
    this.getLoanCollectability();
    this.getReferenceLoanCollectabilityIncomeType();
    this.getReferenceLoanCollectabilityStatus();
  },
};
