export default {
  name: "MRak",
  data() {
    return {
      property: {
        listElement: {
          rak: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          originOfficeId: "",
          destinationOfficeId: "",
          accountNumber: "",
        },
      },
      options: {
        cabang: [],
      },
      table: {
        data: {
          rak: [],
        },
      },
    };
  },
  methods: {
    routeToPageAddDayOff() {
      this.$router.push("/parameter/rak/new");
    },
    routeToPageEditDayOff(props) {
      this.$router.push(
        `/parameter/rak/${this.encryptBASE64(
          `${props.row.interOfficeAccountId}`
        )}`
      );
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              officeName: "",
              page: 0,
            },

            url: "office",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const value = index.officeId;
            const text = index.officeName;
            const officeCode = index.officeCode;
            this.options.cabang.push({
              value,
              text,
              officeCode,
            });
          });
          this.getIdentityFromSession();
        }
      } catch (error) {}
    },
    getIdentityFromSession() {
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      console.log(getDataFromSession, this.options.cabang);
      this.property.filterDto.officeName = getDataFromSession.officeName;

      const findUserByUserCode = this.options.cabang.find(
        (index) => index.officeCode === getDataFromSession.officeCode
      );
      console.log(findUserByUserCode, "test");
      this.dataForm.add.originOfficeId = findUserByUserCode.value;
    },
    async getRak() {
      this.table.data.rak = [];
      this.property.listElement.rak.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inter-office-account",
          payload: {
            originOfficeId: "",
            accountNumber: "",
            destinationOfficeId: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.rak = resp.data.data.content;
            this.property.listElement.rak.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataRak(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetRak(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.rak.downloading = false;
        }, timeout);
      }
    },
    async changePaginationRak(event) {
      this.table.data.rak = [];
      this.property.listElement.rak.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inter-office-account",
          payload: {
            originOfficeId: this.property.filterDto.originOfficeId,
            destinationOfficeId: this.property.filterDto.destinationOfficeId,
            accountNumber: this.property.filterDto.accountNumber,
            page: event - 1,
            size: this.property.listElement.rak.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.rak = resp.data.data.content;
            this.property.listElement.rak.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataRak(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetRak(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.rak.downloading = false;
        }, timeout);
      }
    },
    async findRak() {
      this.table.data.rak = [];
      this.property.listElement.rak.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inter-office-account",
          payload: {
            originOfficeId: this.property.filterDto.originOfficeId,
            accountNumber: this.property.filterDto.accountNumber,
            destinationOfficeId: this.property.filterDto.destinationOfficeId,
            page: 0,
            size: this.property.listElement.rak.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.rak = resp.data.data.content;
            this.property.listElement.rak.rows = resp.data.data.totalElements;
            this.property.listElement.rak.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataRak(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetRak(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.rak.downloading = false;
        }, timeout);
      }
    },

    async deleteRak(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "inter-office-account/" + props.row.interOfficeAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.rak.currentPage = 1;
                  this.property.listElement.rak.rows = 0;
                  this.property.filterDto.originOfficeId = "";
                  this.property.filterDto.destinationOfficeId = "";
                  this.getRak();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async authorizationRak(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "inter-office-account/authorized/" +
                  props.row.interOfficeAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.rak.currentPage = 1;
                  this.property.listElement.rak.rows = 0;
                  this.property.filterDto.originOfficeId = "";
                  this.property.filterDto.destinationOfficeId = "";
                  this.getRak();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    failedGetDataRak(resp) {
      this.table.data.rak = [];
      this.property.listElement.rak.rows = 0;
      this.property.listElement.rak.message = resp.data.message;
    },
    handleErrorGetRak(error) {
      console.log(error.response);
      this.table.data.rak = [];
      this.property.listElement.rak.rows = 0;
      this.property.listElement.rak.currentPage = 1;
      this.property.listElement.rak.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
  },
  mounted() {
    this.getRak();
    this.getReferenceOffice();
  },
};
