export default {
  name: "ListMMortgage",
  data() {
    return {
      identity: {
        mortgageId: "",
      },
      property: {
        listElement: {
          mortgage: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          mortgage: "",
          mortgageCode: "",
        },
      },
      table: {
        data: {
          mortgage: [],
        },
      },
    };
  },
  methods: {
    routerToPageAddMortgage() {
      this.$router.push("/loan/mortgage/new");
    },
    routeToPageEditMortgage(props) {
      this.$router.push(
        `/loan/mortgage/${this.encryptBASE64(`${props.row.mortgageId}`)}`
      );
    },
    async authorizationMortgage(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "loan",
          reqUrl: "mortgage/authorized/" + props.row.mortgageId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.mortgage.currentPage = 1;
          this.property.filterDto.mortgageCode = "";
          this.property.listElement.mortgage.rows = 0;
          this.getMortgage();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async deleteMortgage(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "loan",
          reqUrl: "mortgage/" + props.row.mortgageId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.mortgage.currentPage = 1;
          this.property.filterDto.mortgageCode = "";
          this.property.listElement.mortgage.rows = 0;
          this.getMortgage();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async searchByMortgageCode(event) {
      this.table.data.mortgage = [];
      this.property.listElement.mortgage.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage/simple-list",
          payload: {
            mortgageCode: event,
            page: 0,
            size: this.property.listElement.mortgage.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.mortgage = resp.data.data.content;
          this.property.listElement.mortgage.rows =
            resp.data.data.totalElements;
          this.property.listElement.mortgage.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataMortgage(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetMortgage(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.mortgage.downloading = false;
      }
    },

    async changePaginationMortgage(event) {
      this.table.data.mortgage = [];
      this.property.listElement.mortgage.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage/simple-list",
          payload: {
            mortgageCode: this.property.filterDto.mortgageCode,
            page: event - 1,
            size: this.property.listElement.mortgage.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.mortgage = resp.data.data.content;
          this.property.listElement.mortgage.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataMortgage(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetMortgage(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.mortgage.downloading = false;
      }
    },
    async getMortgage() {
      this.table.data.mortgage = [];
      this.property.listElement.mortgage.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage/simple-list",
          payload: {
            mortgageCode: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.mortgage = resp.data.data.content;
          this.property.listElement.mortgage.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataMortgage(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetMortgage(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.mortgage.downloading = false;
      }
    },
    handleErrorGetMortgage(error) {
      console.log(error.response);
      this.table.data.mortgage = [];
      this.property.listElement.mortgage.rows = 0;
      this.property.listElement.mortgage.currentPage = 1;
      this.property.listElement.mortgage.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataMortgage(resp) {
      this.table.data.mortgage = [];
      this.property.listElement.mortgage.rows = 0;
      this.property.listElement.mortgage.message = resp.data.message;
    },
  },
  mounted() {
    this.getMortgage();
  },
};
