var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex justify-center items-center"},[_c('div',{staticClass:"w-full",staticStyle:{"max-width":"400px"}},[_c('form-builder',{attrs:{"message-mapper":_vm.messages,"scheme":{
        cifIdName: {
          type: 'text',
          label: 'Nama Nasabah',
          isInformationComponent: true,
          value: _vm.formSchemeModel.cifIdName,
        },
        nominal: {
          type: 'text',
          label: 'Nominal',
          isInformationComponent: true,
          value: _vm.convertDecimalCurrency(_vm.formSchemeModel.nominal),
          show: _vm.showAccountNumber,
        },
        accountNumber: {
          type: 'text',
          label: 'No. Rekening',
          isInformationComponent: false,
          models: 'accountNumber',
          event: { type: 'input', action: function (e) { return _vm.onChangeAccountNumber(e); } },
        },
        transactionCodeId: {
          type: 'model-list-select',
          label: 'Kode Transaksi',
          models: 'transactionCodeId',
          selectProperty: {
            options: _vm.options.kodeTransaksi,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
          event: {
            type: 'input',
            action: function (e) {
              _vm.sayHello(e);
            },
          },
        },
      }},model:{value:(_vm.formSchemeModel),callback:function ($$v) {_vm.formSchemeModel=$$v},expression:"formSchemeModel"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }