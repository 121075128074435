export default {
  name: "ListTTaskInterbankLiability",
  data() {
    return {
      property: {
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Agst",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
        listElement: {
          taskInterbankLiability: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          transactionCodeId: "",
          dateFrom: "",
          dateTo: "",
          statusId: "",
        },
      },
      options: {
        interbankliability: [],
        statusAccount: [],
      },
      table: {
        data: {
          taskInterbankLiability: [],
        },
      },
    };
  },
  methods: {
    async searchTaskInterbankLiability() {
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-liability",
          payload: {
            transactionCodeId: this.property.filterDto.transactionCodeId,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.taskInterbankLiability.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankLiability = resp.data.data.content;
          this.property.listElement.taskInterbankLiability.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankLiability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankLiability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankLiability.downloading = false;
      }
    },
    routeToPageAddTaskInterbankLiability() {
      this.$router.push("/task-interbank-liability/new");
    },
    routeToPageDetailTaskInterbankLiability(props) {
      this.$router.push(
        `/task-interbank-liability/${this.encryptBASE64(
          `${props.row.taskInterbankLiabilityId}`
        )}`
      );
    },
    async deleteTaskInterbankLiability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl:
            "task-interbank-liability/" + props.row.taskInterbankLiabilityId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankLiability.currentPage = 1;
          this.property.filterDto.transactionCodeId = "";
          this.property.filterDto.dateTo = "";
          this.property.filterDto.dateFrom = "";
          this.property.filterDto.statusId = "";
          this.property.listElement.taskInterbankLiability.rows = 0;
          this.helperGetDataTableTInterbankAccount();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async authorizationTaskInterbankLiability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "task-interbank-liability/authorized/" +
            props.row.taskInterbankLiabilityId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankLiability.currentPage = 1;
          this.property.filterDto.transactionCodeId = "";
          this.property.filterDto.dateTo = "";
          this.property.filterDto.dateFrom = "";
          this.property.filterDto.statusId = "";
          this.property.listElement.taskInterbankLiability.rows = 0;
          this.helperGetDataTableTInterbankAccount();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async changePaginationTaskInterbankLiability(event) {
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-liability",
          payload: {
            transactionCodeId: this.property.filterDto.transactionCodeId,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusId,
            page: event - 1,
            size: this.property.listElement.taskInterbankLiability.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankLiability = resp.data.data.content;
          this.property.listElement.taskInterbankLiability.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankLiability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankLiability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankLiability.downloading = false;
      }
    },
    handleCatchErrorTaskInterbankLiability(error) {
      console.log(error.response);
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.rows = 0;
      this.property.listElement.taskInterbankLiability.currentPage = 1;
      this.property.listElement.taskInterbankLiability.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async helperGetDataTableTInterbankAccount() {
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-liability",
          payload: {
            transactionCodeId: "",
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: "",
            statusId: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankLiability = resp.data.data.content;
          this.property.listElement.taskInterbankLiability.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankLiability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankLiability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankLiability.downloading = false;
      }
    },
    failedGetDataTaskInterbankLiability(resp) {
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.rows = 0;
      this.property.listElement.taskInterbankLiability.message =
        resp.data.message;
    },
    helperGetDataTransactionCodeForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "transaction-code",
          params: {
            name: "",
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_TRX_ABP,
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.transactionCodeId;
            const text = i.transactionName;
            this.options.interbankliability.push({ text, value });
          });
        });
    },
    appendOptionseSelect() {
      this.options.interbankliability.push({
        value: "",
        text: "-- Semua Kategori --",
      });
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.helperGetDataTableTInterbankAccount();
    this.appendOptionseSelect();
    this.helperGetDataTransactionCodeForReference();
    this.getReferenceStatusAccount();
  },
};
