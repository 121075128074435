const MUSerV2 = {
  actions: {
    GET_V_2_USER_SIMPLE_FIND_BY_USER_ID({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/v2/user/simple-find-by-user-id/${payload.userId}`,
            method: "GET",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },

    DELETE_V_2_USER({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/v2/user/${payload.id}`,
            method: "DELETE",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};

export default MUSerV2;
