export default {
  name: "FormMOffice",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      territoryVariableHelper: {
        provinceId: "",
        cityId: "",
        districtId: "",
      },
      options: {
        kategoriKantor: [],
        province: [],
        city: [],
        village: [],
        district: [],
        sbbAtm: [],
        officeParent: [],
      },
      property: {
        animation: {
          addOffice: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        officeParentId: "",
        officeCode: "",
        officeName: "",
        officeAddress: "",
        postalCode: "",
        telephonNumber: "",
        faximileNumber: "",
        viceHeadOfficeName: "",
        sandiBankId: "",
        officeHeadName: "",
        officeHeadAddress: "",
        officeHeadCityId: "",
        headOfficeTelephoneNumber: "",
        viceHeadOfficeTelephoneNumber: "",
        adminOfficeTelephoneNumber: "",
        officeSmsNumber: "",
        officeHeadPosition: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        rofficeCategoryId: "",
        villageId: "",
        coaAtmId: "",
      },
    };
  },
  methods: {
    routeToPageListOffice() {
      this.$router.push("/parameter/office");
    },

    async getReferenceAreaProvince() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/province",
            params: {
              provinceName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.provinceName;
            const value = i.provinceId;
            this.options.province.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async changeSelectProvince() {
      this.options.city = [];
      this.options.district = [];
      this.options.village = [];
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/city",
            params: {
              provinceId: this.territoryVariableHelper.provinceId,
              cityName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.cityName;
            const value = i.cityId;
            this.options.city.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async changeSelectCity() {
      this.options.district = [];
      this.options.village = [];
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/district",
            params: {
              cityId: this.territoryVariableHelper.cityId,
              districtName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.districtName;
            const value = i.districtId;
            this.options.district.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async changeSelectDistrict() {
      this.options.village = [];
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/village",
            params: {
              districtId: this.territoryVariableHelper.districtId,
              villageName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.villageName;
            const value = i.villageId;
            const postalcode = i.postalcode;
            this.options.village.push({ text, value, postalcode });
          });
        }
      } catch (error) {}
    },
    appendDefaultInput() {
      const defaultOption = { value: "", text: "-- Pilih --" };
      this.options.kategoriKantor.push(defaultOption);
      this.options.officeParent.push(defaultOption);
      this.options.province.push(defaultOption);
      this.options.city.push(defaultOption);
      this.options.district.push(defaultOption);
      this.options.village.push(defaultOption);
      this.options.sbbAtm.push(defaultOption);
    },
    async getReferenceOfficeCategory() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "reference/office-category",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.officeCategoryName;
            const value = i.officeCategoryId;
            this.options.kategoriKantor.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceOfficeParent() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.officeParent = resp.data.data;
        }
      } catch (error) {}
    },

    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              description: "",
              page: 0,
            },
            url: "chart-of-account",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            this.options.sbbAtm.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getAreaVilageById() {
      const payload = {
        id: this.dataForm.villageId,
      };
      try {
        const resp = await this.$store.dispatch(
          "GET_MASTER_AREA_VILLAGE_BY_ID",
          payload
        );
        if (resp.data.code === "SUCCESS") {
          this.territoryVariableHelper.districtId = resp.data.data.mdistrictDto
            ? resp.data.data.mdistrictDto.districtId
            : "";
          this.territoryVariableHelper.cityId = resp.data.data.mdistrictDto
            ? resp.data.data.mdistrictDto.mcityDto
              ? resp.data.data.mdistrictDto.mcityDto.cityId
              : ""
            : "";
          this.territoryVariableHelper.provinceId = resp.data.data.mdistrictDto
            ? resp.data.data.mdistrictDto.mcityDto
              ? resp.data.data.mdistrictDto.mcityDto.mprovinceDto
                ? resp.data.data.mdistrictDto.mcityDto.mprovinceDto.provinceId
                : ""
              : ""
            : "";
          this.changeSelectProvince();
          this.changeSelectCity();
          this.changeSelectDistrict();
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.officeCode = payload.officeCode;
        this.dataForm.officeName = payload.officeName;
        this.dataForm.officeAddress = payload.officeAddress;
        this.dataForm.postalCode = payload.postalCode;
        this.dataForm.telephonNumber = payload.telephonNumber;
        this.dataForm.faximileNumber = payload.faximileNumber;
        this.dataForm.viceHeadOfficeName = payload.viceHeadOfficeName;
        this.dataForm.sandiBankId = payload.sandiBankId;
        this.dataForm.officeHeadName = payload.officeHeadName;
        this.dataForm.officeHeadAddress = payload.officeHeadAddress;
        this.dataForm.officeHeadCityId = payload.officeHeadCityId;
        this.dataForm.headOfficeTelephoneNumber =
          payload.headOfficeTelephoneNumber;
        this.dataForm.viceHeadOfficeTelephoneNumber =
          payload.viceHeadOfficeTelephoneNumber;
        this.dataForm.adminOfficeTelephoneNumber =
          payload.adminOfficeTelephoneNumber;
        this.dataForm.officeSmsNumber = payload.officeSmsNumber;
        this.dataForm.coaAtmId = payload.atmSbb
          ? payload.atmSbb.chartOfAccountId
          : "";
        this.dataForm.officeHeadPosition = payload.officeHeadPosition;
        this.dataForm.rofficeCategoryId = payload.rofficeCategory
          ? payload.rofficeCategory.officeCategoryId
          : "";
        this.dataForm.villageId = payload.villageId
          ? payload.villageId.villageId
          : "";
        this.dataForm.officeParentId = payload.officeParentId;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        this.getAreaVilageById();
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitOffice() {
      this.$emit("submitOffice", this.dataForm);
    },
    onChangeOfficeCategory() {
      if (
        this.dataForm.rofficeCategoryId ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_OFFICE_CATEGORY_KAS ||
        this.dataForm.rofficeCategoryId ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_OFFICE_CATEGORY_UNIT_LAYANAN ||
        this.dataForm.rofficeCategoryId ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_OFFICE_CATEGORY_UNIT_MOBILE
      ) {
        return;
      }
      this.dataForm.officeParentId = "";
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendDefaultInput();
    this.getReferenceOfficeCategory();
    this.getReferenceAreaProvince();
    this.getReferenceChartOfAccount();
    this.getReferenceOfficeParent();
  },
};
