const Reference = {
  actions: {
    GET_REFERENCE_PARAMETER({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/parameter/reference/${payload.url}`,
        method: "GET",
      }).then((resp) => {
        return resp;
      });
    },
    GET_REFERENCE_TRANSACTIONS({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/reference/${payload.url}`,
        params: payload.params,
        method: "GET",
      }).then((resp) => {
        return resp;
      });
    },
    GET_REFERENCE_USER_MANAGEMENT({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/reference/${payload.url}`,
        params: payload.params,
        method: "GET",
      }).then((resp) => {
        return resp;
      });
    },
    GET_REFERENCE_LOAN({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/loan/reference/${payload.url}`,
        params: payload.params,
        method: "GET",
      }).then((resp) => {
        return resp;
      });
    },
    GET_REFERENCE_FROM_MASTER_PARAMETER({ commit }, payload) {
      const params = payload.params;
      Object.assign(params, { size: 1 });
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/parameter/${payload.url}`,
        method: "GET",
        params: params,
      }).then((resp) => {
        if (resp.data.code === "SUCCESS") {
          Object.assign(params, { size: resp.data.data.totalElements });
          return axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/parameter/${payload.url}`,
            method: "GET",
            params: params,
          }).then((response) => {
            return response;
          });
        }
      });
    },
    GET_REFERENCE_FROM_MASTER_TRANSACTION({ commit }, payload) {
      const params = payload.params;
      Object.assign(params, { size: 1 });
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/${payload.url}`,
        method: "GET",
        params: params,
      }).then((resp) => {
        if (resp.data.code === "SUCCESS") {
          Object.assign(params, { size: resp.data.data.totalElements });
          return axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/${payload.url}`,
            method: "GET",
            params: params,
          }).then((response) => {
            return response;
          });
        } else {
          return resp;
        }
      });
    },
    GET_REFERENCE_FROM_USER_MANAGEMENT({ commit }, payload) {
      const params = payload.params;
      Object.assign(params, { size: 1 });
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/${payload.url}`,
        method: "GET",
        params: params,
      }).then((resp) => {
        if (resp.data.code === "SUCCESS") {
          Object.assign(params, { size: resp.data.data.totalElements });
          return axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/${payload.url}`,
            method: "GET",
            params: params,
          }).then((response) => {
            return response;
          });
        }
      });
    },
    GET_REFERENCE_FROM_MASTER_LOAN({ commit }, payload) {
      const params = payload.params;
      Object.assign(params, { size: 1 });
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/loan/${payload.url}`,
        method: "GET",
        params: params,
      }).then((resp) => {
        if (resp.data.code === "SUCCESS") {
          Object.assign(params, { size: resp.data.data.totalElements });
          return axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/loan/${payload.url}`,
            method: "GET",
            params: params,
          }).then((response) => {
            return response;
          });
        } else {
          return resp;
        }
      });
    },
    GET_REFERENCE_PRODUCT_CATEGORY({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/product-category/product-category`,
        method: "GET",
      }).then((resp) => {
        return resp;
      });
    },
    GET_MASTER_AREA_VILLAGE_BY_ID({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/parameter/area/village/${payload.id}`,
        method: "GET",
      }).then((resp) => {
        return resp;
      });
    },
    GET_PRODUCT_LEDGER_BY_PRODUCT_CATEGORY_ID({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/product-ledger/ledger-list/${payload.productCategoryId}`,
        method: "GET",
      }).then((resp) => {
        return resp;
      });
    },
    GET_REFERENCE_USER_LEVEL({ commit }, payload) {
      return axios({
        url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/reference/user-level`,
        method: "GET",
        params: payload,
      }).then((resp) => {
        return resp;
      });
    },
  },
};

export default Reference;
