export default {
  name: "FormTDepositAccountDetailFundAllocatedOverbook",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      form: {
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
          officeName: "",
        },
        overbook: {
          accountNumber: "",
          cifIdName: "",
          officeName: "",
          nominal: 0,
          documentProofNumber: "",
          transactionDate: "",
          description: "",
          referenceNumber: "",
          transactionName: "",
          //user-activity
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
        },
      },
      options: {
        kodeTransaksi: [],
      },
    };
  },
  methods: {
    routeToPageListDepositOverbook() {
      this.$router.push("/deposit/fund-allocated-overbook");
    },

    defaultDateNow() {
      this.form.overbook.transactionDate = new Date().toISOString();
    },
    async getReferenceTransactionCode() {
      if (!this.isExisting) {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl:
              "transaction-code/transaction-code/" +
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_DEPOSIT_FUND_ALLOCATION_OVERBOOK_CODE,
          });
          if (resp.data.code === "SUCCESS") {
            this.form.overbook.transactionName = resp.data.data.transactionName;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListDepositOverbook();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).depositAccountId;
      this.$emit("getMainId", this.identity.mainId);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.overbook.accountNumber = payload.tdepositAccount
          ? payload.tdepositAccount.accountNumber
          : "";

        this.form.overbook.cifIdName = payload.tdepositAccount
          ? payload.tdepositAccount.mcifResponseDto
            ? payload.tdepositAccount.mcifResponseDto.cifIdName
            : ""
          : "";
        this.form.overbook.officeName = payload.tdepositAccount
          ? payload.tdepositAccount.mcifResponseDto
            ? payload.tdepositAccount.mcifResponseDto.officeId
              ? payload.tdepositAccount.mcifResponseDto.officeId.officeName
              : ""
            : ""
          : "";
        this.form.overbook.nominal = payload.nominal;
        this.form.overbook.documentProofNumber = payload.documentProofNumber;
        this.form.overbook.transactionDate = payload.transactionDate;
        this.form.overbook.description = payload.description;
        this.form.overbook.referenceNumber = payload.referenceNumber;
        this.form.overbook.createdBy = payload.createdBy
          ? payload.createdBy
          : "";
        this.form.overbook.transactionName =
          payload.mtransactionCode?.transactionName;
        this.form.overbook.createdByUserCode = payload.createdByUserCode;
        this.form.overbook.createdByUserName = payload.createdByUserName;
        this.form.overbook.createdDate = payload.createdDate;
        this.form.overbook.updatedDate = payload.updatedDate;
        this.form.overbook.updatedByUserCode = payload.updatedByUserCode;
        this.form.overbook.updatedByUserName = payload.updatedByUserName;
        this.form.overbook.authorizedDate = payload.authorizedDate;
        this.form.overbook.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.overbook.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.form.depositAccount.accountNumber = payload.accountNumber;
      this.form.depositAccount.cifIdName = payload.mcifResponseDto
        ? payload.mcifResponseDto.cifIdName
        : "";
      this.form.depositAccount.officeName = payload.mcifResponseDto
        ? payload.mcifResponseDto.officeId
          ? payload.mcifResponseDto.officeId.officeName
          : ""
        : "";
      this.form.overbook.nominal = payload.nominal;
    },
    submitDepositAccountDetailFundAllocatedOverbook() {
      this.$emit("submitDepositAccountDetailFundAllocatedOverbook", this.form);
    },
  },
  beforeMount() {},
  mounted() {
    this.checkingMainId();
    this.defaultDateNow();
    this.getReferenceTransactionCode();
  },
  destroyed() {},
};
