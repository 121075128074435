export default {
  state: {
    sidebarShow: "responsive",
    sidebarMinimize: false,
    isShow: true,
  },
  mutations: {
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : "responsive";
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : "responsive";
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    SET_IS_SHOW_ROUTER(state, payload) {
      state.isShow = payload;
    },
  },
  actions: {
    SET_IS_SHOW_ROUTER({ commit }, payload) {
      commit("SET_IS_SHOW_ROUTER", payload);
    },
  },
  getters: {
    getIsShow: (state) => state.isShow,
  },
};
