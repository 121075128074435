import Moment from "moment";

export default {
  name: "ReportEstimasiLabaRugi",
  data() {
    return {
      dataForm: {
        date: "",
        history: 0,
        revenue: 0,
        expense: 0,
        totalProfitShare: 0,
        totalProfitLoss: 0,
      },
    };
  },
  methods: {
    Moment: Moment,
    getSystemDate() {
      this.dataForm.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    async getProfitLossEstimate() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/profit-loss-estimate",
        });
        console.log("data => ", resp);
        if (resp.data.code === "SUCCESS") {
          this.dataForm.history = resp.data.data.history;
          this.dataForm.revenue = resp.data.data.revenue;
          this.dataForm.expense = resp.data.data.expense;
        }
      } catch (error) {}
    },
    async changeProfitLossEstimate() {
      // first
      const totalRevenue =
        this.dataForm.history + this.dataForm.revenue - this.dataForm.expense;
      // const totalProfit =
      //   this.dataForm.expense - this.dataForm.totalProfitShare;
      this.dataForm.totalProfitLoss =
        totalRevenue - this.dataForm.totalProfitShare;
      console.log("totalprofit => ", this.dataForm.totalProfitShare);
    },
  },
  mounted() {
    this.getSystemDate();
    this.getProfitLossEstimate();
    // this.changeProfitLossEstimate();
  },
};
