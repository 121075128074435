import FormTSavingAccount from "../form";

export default {
  name: "ExistingTSavingAccount",
  components: {
    FormTSavingAccount,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identity: {
        mainId: "",
      },
    };
  },
  mounted() {
    this.validationDomain = window.location.hostname;
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getSavingAccountById();
    },
    async getSavingAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTSavingAccountRef.routeToPageListSavingAccount();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving-account/simple/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTSavingAccountRef.getDefaultFormValue(
              resp.data.data
            );
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTSavingAccountRef.routeToPageListSavingAccount(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTSavingAccountRef.routeToPageListSavingAccount(),
          });
        }
      }
    },
    async editSavingAccount(payload) {
      const data = {
        cifId: payload.cifId,
        // profitShareAddPercent: payload.profitShareAddPercent,
        profitShareTotalPercent: payload.profitShareTotalPercent,
        permonthWithdrawFreq: payload.permonthWithdrawFreq,
        permonthFixedDeposit: payload.permonthFixedDeposit,
        isTaxable: payload.isTaxable,
        taxRestitutionNumber: payload.taxRestitutionNumber,
        isZakat: payload.isZakat,
        qqName: payload.qqName,
        qqCode: payload.qqCode,
        maximumTransaction: payload.maximumTransaction,
        isSmsNotification: payload.isSmsNotification,
        accountOfficerHandleId: payload.accountOfficerHandleId,
        accountOfficerPromoId: payload.accountOfficerPromoId,
        savingId: payload.savingId,
        sourceFundId: payload.sourceFundId,
        purposeOpenAccountId: payload.purposeOpenAccountId,
        accountClassificationId: payload.accountClassificationId,
        deliveryStatementId: payload.deliveryStatementId,
        isBlocked: payload.isBlocked,
        // isForEdc: payload.isForEdc,
        // edcId: payload.edcId,
        referalOfficeId: payload.referalOfficeId,
        accountNumber: `${payload.accountNumber.slice(0, 5)}${
          payload.newAccountNumber
        }`,
      };
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          "Edit Data Ini ?",
          "is-info"
        );
        if (!confirm) return;
        this.$refs.formTSavingAccountRef.property.animation.submitLoading = true;

        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/" + this.identity.mainId,
            payload: data,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1500);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.$refs.formTSavingAccountRef.property.animation.submitLoading = false;
        }
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          "Edit Data Ini ?",
          "is-success"
        );
        if (!confirm) return;
        this.$refs.formTSavingAccountRef.property.animation.submitLoading = true;

        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/" + this.identity.mainId,
            payload: data,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1500);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.$refs.formTSavingAccountRef.property.animation.submitLoading = false;
        }
      }
    },
  },
};
