export default {
  name: "FormTMortgagePaidOff",
  props: {
    isExisting: false,
  },
  data() {
    return {
      property: {
        animation: {
          addMortgagePaidOff: {
            isLoading: false,
          },
        },
      },
      identity: {
        mainId: "",
      },
      dataForm: {
        mortgageNumberInventory: "",
        mortgageAccountNumber: "",
        mortgageAccountId: "",
        nomorKontrak: "",
        namaNasabah: "",
        cabang: "",
        kodeTransaksi: "",
        noBuktiDokumen: "",
        setupDate: "",
        rekeningTabunganDebet: "",
        plafonPinjaman: "",
        diskon: "",
        totalPelunasan: "",
        keterangan: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        mortgageNumberInventory: [],
        mortgageAccountList: [],
        transactionCode: [],
      },
      resultDataMAccount: "",
    };
  },
  methods: {
    appendDefault() {
      this.dataForm.kodeTransaksi = 69;
    },
    routeToPageListMortgagePaidOff() {
      this.$router.push("/loan/mortgage-paid-off");
    },
    countTotalPelunasan() {
      var plafonPinjaman = this.dataForm.plafonPinjaman;
      var diskon = this.dataForm.diskon;

      var total = plafonPinjaman - plafonPinjaman * (diskon / 100);

      this.dataForm.totalPelunasan = total;
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              page: 0,
              name: "",
              transactionCodes: this.fetchAppSession(
                "@vue-session/config-bundle"
              ).VUE_APP_TRX_CODE_MORTGAGE_PAID,
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.transactionCode = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            this.options.transactionCode.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.mortgageAccountId =
          payload.tmortgageAccount.mortgageAccountId;
        this.dataForm.mortgageNumberInventory =
          payload.tmortgageAccount.mortgageRegisterId.mortgageRegisterNumber;
        this.dataForm.nomorKontrak =
          payload.tmortgageAccount.mortgageAccountNumber;
        this.dataForm.namaNasabah = payload.tmortgageAccount.mcif.cifIdName;
        this.dataForm.cabang = payload.tmortgageAccount.office.officeName;
        this.dataForm.setupDate = payload.tmortgageAccount.akadDate;
        this.dataForm.rekeningTabunganDebet =
          payload.tmortgageAccount.dropingAccountId.accountNumber;
        this.dataForm.plafonPinjaman = payload.tmortgageAccount.nominalMortgage;
        this.dataForm.totalPelunasan = payload.nominalMortgage;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
      this.dataForm.mortgageAccountId = payload.mortgageAccountId;
      this.dataForm.mortgageNumberInventory =
        payload.mortgageRegisterId.mortgageRegisterNumber;
      this.dataForm.nomorKontrak = payload.mortgageAccountNumber;
      this.dataForm.namaNasabah = payload.mcif.cifIdName;
      this.dataForm.cabang = payload.office.officeName;
      this.dataForm.setupDate = payload.akadDate;
      this.dataForm.rekeningTabunganDebet =
        payload.dropingAccountId.accountNumber;
      this.dataForm.plafonPinjaman = payload.nominalMortgage;
      this.dataForm.totalPelunasan = payload.nominalMortgage;
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListMortgagePaidOff();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).mortgageAccountId;
      this.$emit("getMainId", this.identity.mainId);
    },
    submitMortgagePaidOf() {
      this.$emit("submitMortgagePaidOf", this.dataForm);
    },
  },
  beforeMount() {},
  mounted() {
    this.checkingMainId();
    this.getReferenceTransactionCode();
    this.appendDefault();
  },
  destroyed() {},
};
