export default {
  name: "FormCollateralGroup",
  props: {
    isExisting: false,
    formMessage: {
      type: Object,
      default: {},
      required: false,
    },
  },
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        mainId: "",
      },
      form: {
        loanCollateralCategoryId: "",
        ownership: "",
        weight: 0,
        collateralWeight: "",
        collateralBiCode: "",
        collateralSidCode: "",
        description: "",
        htLess: 0,
        htMore: 0,
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        loanCollateralCategory: [],
        kodeOJK: [],
      },
    };
  },
  methods: {
    getReferenceLoanCollateralCategory() {
      this.options.loanCollateralCategory = [
        { text: "-- Pilih --", value: "" },
      ];
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-category",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.rloanCollateralCategoryId;
              const text = i.collateralCategoryName;
              this.options.loanCollateralCategory.push({ text, value });
            });
          }
        });
    },
    async findMSandiBiBySandiBiL() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "parameter",
          reqUrl:
            "sandi-bi/sandi-bi-by-level2-code/" +
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_SANDI_BI_LEVEL_2_JENIS_AGUNAN,
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.sandiBiCode} - ${index.sandiBiName}`;
            const value = index.sandiBiCode;
            this.options.kodeOJK.push({
              text,
              value,
            });
          });
        }
      } catch (error) {}
    },
    routeToPageListCollateralGroup() {
      this.$router.push("/loan/collateral-group");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.loanCollateralCategoryId = payload.rloanCollateralCategory
          ? payload.rloanCollateralCategory.rloanCollateralCategoryId
          : "";
        this.form.ownership = payload.ownership;
        this.form.description = payload.description;
        this.form.weight = payload.weight;
        this.form.collateralWeight = payload.collateralWeight;
        this.form.collateralBiCode = payload.collateralBiCode;
        this.form.collateralSidCode = payload.collateralSidCode;
        this.form.htLess = payload.htLess;
        this.form.htMore = payload.htMore;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    submitCollateralGroup() {
      this.$emit("submitCollateralGroup", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceLoanCollateralCategory();
    this.findMSandiBiBySandiBiL();
  },
};
