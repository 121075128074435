export default {
  name: "NominatifInterbankAssets",
  data() {
    return {
      property: {
        animation: {
          isDownloadingFile: false,
        },
      },
    };
  },
  methods: {
    async onDownload(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "interbank_assets/files",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Nominatif_ABA.${fileType === "xls" ? "xlsx" : ""}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
        console.log(error);
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
  },
};
