export default {
  name: "ReportAuditrial",
  data() {
    return {
      property: {
        animation: {
          isDownloadingFile: false,
        },
        filterDto: {
          officeCode: "",
          userCode: "",
          dateFrom: "",
          dateTo: "",
          isComaDelimiter: false,
        },
      },
      options: {
        office: [],
        user: [],
      },
    };
  },
  methods: {
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeCode;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceUser() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
              officeCode: "",
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.user = [{ value: "", text: "-- Semua User --" }];
          resp.data.data.content.map((index) => {
            const value = index.userCode;
            const text = `${index.userCode} - ${index.profileSurename}`;
            this.options.user.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async onDownloadFileIdn() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        officeCode: this.property.filterDto.officeCode,
        userCode: this.property.filterDto.userCode,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        isComaDelimiter: false,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/user-audit-trail",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Auditrail.${"csv"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadFileUs() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        officeCode: this.property.filterDto.officeCode,
        userCode: this.property.filterDto.userCode,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/user-audit-trail",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Auditrail.${"csv"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
  },
  mounted() {
    this.getReferenceOffice();
    this.getReferenceUser();
  },
};
