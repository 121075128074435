export default {
  name: "AkumulasiPenyusutan",
  data() {
    return {
      property: {
        listElement: {
          akumulasiPenyusutan: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          dateFrom: "",
          dateTo: "",
        },
      },
      table: {
        footer: {
          totalDebet: 0,
          totalCredit: 0,
          balance: 0,
        },
        header: {},
        data: {
          akumulasiPenyusutan: [],
        },
      },
    };
  },
  methods: {
    failedGetakumulasiPenyusutan(resp) {
      this.property.listElement.akumulasiPenyusutan.rows = 0;
      this.table.data.akumulasiPenyusutan = [];
      this.property.listElement.akumulasiPenyusutan.message = resp.data.message;
    },
    handleErrorGetakumulasiPenyusutan(error) {
      console.log(error.response);
      this.table.data.akumulasiPenyusutan = [];
      this.property.listElement.akumulasiPenyusutan.rows = 0;
      this.property.listElement.akumulasiPenyusutan.currentPage = 1;
      this.property.listElement.akumulasiPenyusutan.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getakumulasiPenyusutan() {
      this.table.data.akumulasiPenyusutan = [];
      this.property.listElement.akumulasiPenyusutan.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inventory-amortitation/simple-list/akumulasi-penyusutan",
          payload: {
            fixedAsset: true,
            dateFrom: "",
            dateTo: "",
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.akumulasiPenyusutan = resp.data.data.listResponse;
            this.table.footer.totalDebet = resp.data.data.totalDebet;
            this.table.footer.totalCredit = resp.data.data.totalCredit;
            this.table.footer.balance = resp.data.data.balance;
            this.property.listElement.akumulasiPenyusutan.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetakumulasiPenyusutan(resp);
            this.resetTotal();
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetakumulasiPenyusutan(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.akumulasiPenyusutan.downloading = false;
        }, timeout);
      }
    },
    async searchakumulasiPenyusutan() {
      this.table.data.akumulasiPenyusutan = [];
      this.property.listElement.akumulasiPenyusutan.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "inventory-amortitation/simple-list/akumulasi-penyusutan",
          payload: {
            fixedAsset: true,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.akumulasiPenyusutan = resp.data.data.listResponse;
            this.table.footer.totalDebet = resp.data.data.totalDebet;
            this.table.footer.totalCredit = resp.data.data.totalCredit;
            this.table.footer.balance = resp.data.data.balance;
            this.property.listElement.akumulasiPenyusutan.rows =
              resp.data.data.totalElements;
            this.property.listElement.akumulasiPenyusutan.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetakumulasiPenyusutan(resp);
            this.resetTotal();
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetakumulasiPenyusutan(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.akumulasiPenyusutan.downloading = false;
        }, timeout);
      }
    },
  },
  mounted() {
    this.getakumulasiPenyusutan();
  },
};
