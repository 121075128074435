import FormTSavingAccount from "../form";

export default {
  name: "CreateTSavingAccount",
  components: {
    FormTSavingAccount,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      identity: {
        mainId: "",
      },
    };
  },
  mounted() {
    this.validationDomain = window.location.hostname;
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getCifById();
    },
    async getCifById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "cif/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$refs.formTSavingAccountRef.getDefaultFormValue(resp.data.data);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTSavingAccountRef.routeToPageListSavingAccount(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () =>
            this.$refs.formTSavingAccountRef.routeToPageListSavingAccount(),
        });
      }
    },
    async addSavingAccount(payload) {
      const data = {
        cifId: this.identity.mainId,
        accountNumber: payload.accountNumber,
        // profitShareAddPercent: payload.profitShareAddPercent,
        profitShareTotalPercent: payload.profitShareTotalPercent,
        permonthWithdrawFreq: payload.permonthWithdrawFreq,
        qqName: payload.qqName,
        qqCode: payload.qqCode,
        savingId: payload.savingId,
        isTaxable: payload.isTaxable,
        taxRestitutionNumber: payload.taxRestitutionNumber,
        isZakat: payload.isZakat,
        accountOfficerPromoId: payload.accountOfficerPromoId,
        accountOfficerHandleId: payload.accountOfficerHandleId,
        sourceFundId: payload.sourceFundId,
        purposeOpenAccountId: payload.purposeOpenAccountId,
        permonthFixedDeposit: payload.permonthFixedDeposit,
        // eslint-disable-next-line no-dupe-keys
        permonthWithdrawFreq: payload.permonthWithdrawFreq,
        maximumTransaction: payload.maximumTransaction,
        accountClassificationId: payload.accountClassificationId,
        deliveryStatementId: payload.deliveryStatementId,
        isSmsNotification: payload.isSmsNotification,
        // isForEdc: payload.isForEdc,
        // edcId: payload.edcId,
        referalOfficeId: payload.referalOfficeId,
      };
      console.log(JSON.stringify(data));
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          "Simpan Data Ini ?",
          "is-info"
        );
        if (!confirm) return;
        this.$refs.formTSavingAccountRef.property.animation.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account",
            payload: data,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            const toastBuefy = this.$buefy.snackbar.open(
              this.$NotificationUtils.snackbarSuccess
            );
            toastBuefy.$on("close", () => {
              this.$refs.formTSavingAccountRef.routeToPageListSavingAccount();
            });
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1500);
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.$refs.formTSavingAccountRef.property.animation.submitLoading = false;
        }
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        const confirm = await this.simpleConfirmation(
          "Simpan Data Ini ?",
          "is-success"
        );
        if (!confirm) return;
        this.$refs.formTSavingAccountRef.property.animation.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account",
            payload: data,
          });
          await this.simpleWait(1500);
          if (resp.data.code === "SUCCESS") {
            const toastBuefy = this.$buefy.snackbar.open(
              this.$NotificationUtils.snackbarSuccess
            );
            toastBuefy.$on("close", () => {
              this.$refs.formTSavingAccountRef.routeToPageListSavingAccount();
            });
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1500);
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.$refs.formTSavingAccountRef.property.animation.submitLoading = false;
        }
      }
    },
  },
};
