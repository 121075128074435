export default {
  name: "ListMUser",
  data() {
    return {
      identity: {
        officeCode: "",
      },
      options: {
        office: [],
      },
      property: {
        listElement: {
          user: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          profileSurename: "",
          userCode: "",
          name: "",
          officeId: "",
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      table: {
        data: {
          user: [],
        },
      },
    };
  },
  methods: {
    async findUser() {
      this.table.data.user = [];
      this.property.listElement.user.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          reqUrl: "v2/user/simple-list",
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          payload: {
            name: this.property.filterDto.name,
            profileSurename: this.property.filterDto.profileSurename,
            userCode: this.property.filterDto.userCode,
            page: 0,
            officeId: this.property.filterDto.officeId,
            size: this.property.listElement.user.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.user = resp.data.data.content;
            this.property.listElement.user.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.user.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataUser(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorUser(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.user.downloading = false;
        }, timeout + 500);
      }
    },

    async deleteDataUser(props) {
      const payload = {
        id: props.row.userId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch(
                "DELETE_V_2_USER",
                payload
              );
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.user.currentPage = 1;
                  this.property.filterDto.profileSurename = "";
                  this.property.filterDto.userCode = "";
                  this.property.filterDto.name = "";
                  this.property.filterDto.officeId = "";
                  this.property.listElement.user.rows = 0;
                  this.helperGetDataTableUser();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async authorizationUser(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "user-management",
                reqUrl: "user/authorize/" + props.row.userId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.user.currentPage = 1;
                  this.property.filterDto.name = "";
                  this.property.filterDto.profileSurename = "";
                  this.property.filterDto.userCode = "";
                  this.property.filterDto.officeId = "";
                  this.property.listElement.user.rows = 0;
                  this.helperGetDataTableUser();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageAddUser() {
      this.$router.push("/admin/user/new");
    },
    routeToPageEditUser(props) {
      this.$router.push(
        `/admin/user/${this.encryptBASE64(`${props.row.userId}`)}`
      );
    },

    async changePaginationUser(event) {
      this.table.data.user = [];
      this.property.listElement.user.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          reqUrl: "v2/user/simple-list",
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          payload: {
            name: this.property.filterDto.name,
            profileSurename: this.property.filterDto.profileSurename,
            userCode: this.property.filterDto.userCode,
            page: event - 1,
            size: this.property.listElement.user.perPage,
            officeId: this.property.filterDto.officeId,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.user = resp.data.data.content;
            this.property.listElement.user.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataUser(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorUser(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.user.downloading = false;
        }, timeout);
      }
    },
    handleCatchErrorUser(error) {
      console.log(error.response);
      this.table.data.user = [];
      this.property.listElement.user.rows = 0;
      this.property.listElement.user.currentPage = 1;
      this.property.listElement.user.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async helperGetDataTableUser() {
      this.table.data.user = [];
      this.property.listElement.user.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          reqUrl: "v2/user/simple-list",
          type: "GET_LIST_DATA",
          endPoint: "user-management",
          payload: {
            officeId: "",
            profileSurename: "",
            userCode: "",
            name: "",
            page: 0,
            size: this.property.listElement.user.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.user = resp.data.data.content;
            this.property.listElement.user.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataUser(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorUser(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.user.downloading = false;
        }, timeout);
      }
    },
    failedGetDataUser(resp) {
      this.table.data.user = [];
      this.property.listElement.user.rows = 0;
      this.property.listElement.user.message = resp.data.message;
    },
    async getRefenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const value = index.officeId;
            const text = index.officeName;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getIdentityOfficeCodeFromUserData() {
      const getIdentityOfficeCodeFromUserData = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.identity.officeCode = getIdentityOfficeCodeFromUserData.officeCode;
    },
  },
  mounted() {
    this.helperGetDataTableUser();
    this.getRefenceOffice();
    this.getIdentityOfficeCodeFromUserData();
  },
};
