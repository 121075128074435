export default {
  state: {
    identifier: {
      loanAccountId: "",
      savingAccountNumber: "",
    },
    options: {
      loanInstallment: {
        loanAccount: [],
      },
      loanCollateral: {
        fixedAsset: {
          loanAccount: [],
        },
        vehicle: {
          loanAccount: [],
        },
        decree: {
          loanAccount: [],
        },
        securities: {
          loanAccount: [],
        },
        preciousMetal: {
          loanAccount: [],
        },
      },
      loanBiCode: {
        loanAccount: [],
      },
      loanDocumentChecklist: {
        loanAccount: [],
      },
    },
  },
  mutations: {
    SET_IDENTIFIER_LOAN_ACCOUNT_ID(state, payload) {
      state.identifier.loanAccountId = payload;
    },
    SET_IDENTIFIER_SAVING_(state, payload) {
      state.identifier.savingAccountNumber = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_INSTALLMENT(state, payload) {
      state.options.loanInstallment.loanAccount = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_FIXED_ASSET(state, payload) {
      state.options.loanCollateral.fixedAsset.loanAccount = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_VEHICLE(state, payload) {
      state.options.loanCollateral.vehicle.loanAccount = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_DECREE(state, payload) {
      state.options.loanCollateral.decree.loanAccount = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_SECURITIES(state, payload) {
      state.options.loanCollateral.securities.loanAccount = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_PRECIOUS_METAL(state, payload) {
      state.options.loanCollateral.preciousMetal.loanAccount = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_BI_CODE(state, payload) {
      state.options.loanBiCode.loanAccount = payload;
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_DOCUMENT_CHECKLIST(state, payload) {
      state.options.loanDocumentChecklist.loanAccount = payload;
    },
    CLEAR_OPTIONS_LOAN_ACCOUT(state, payload) {
      state.identifier.loanAccountId = "";
      state.options.loanInstallment.loanAccount = [];
      state.options.loanCollateral.fixedAsset.loanAccount;
      state.options.loanCollateral.vehicle.loanAccount = [];
      state.options.loanCollateral.decree.loanAccount = [];
      state.options.loanCollateral.securities.loanAccount = [];
      state.options.loanBiCode.loanAccount = [];
      state.options.loanDocumentChecklist.loanAccount = [];
    },
  },
  actions: {
    CLEAR_OPTIONS_LOAN_ACCOUT({ commit }, payload) {
      commit("CLEAR_OPTIONS_LOAN_ACCOUT");
    },
    SET_IDENTIFIER_LOAN_ACCOUNT_ID({ commit }, payload) {
      commit("SET_IDENTIFIER_LOAN_ACCOUNT_ID", payload);
    },
    SET_IDENTIFIER_SAVING_ACCOUNT_NUMBER({ commit }, payload) {
      commit("SET_IDENTIFIER_SAVING_ACCOUNT_NUMBER", payload);
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_INSTALLMENT({ commit }, payload) {
      commit("SET_OPTIONS_LOAN_ACCOUNT_LOAN_INSTALLMENT", payload);
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_FIXED_ASSET({ commit }, payload) {
      commit("SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_FIXED_ASSET", payload);
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_VEHICLE({ commit }, payload) {
      commit("SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_VEHICLE", payload);
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_DECREE({ commit }, payload) {
      commit("SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_DECREE", payload);
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_SECURITIES({ commit }, payload) {
      commit("SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_SECURITIES", payload);
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_PRECIOUS_METAL(
      { commit },
      payload
    ) {
      commit(
        "SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_PRECIOUS_METAL",
        payload
      );
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_BI_CODE({ commit }, payload) {
      commit("SET_OPTIONS_LOAN_ACCOUNT_LOAN_BI_CODE", payload);
    },
    SET_OPTIONS_LOAN_ACCOUNT_LOAN_DOCUMENT_CHECKLIST({ commit }, payload) {
      commit("SET_OPTIONS_LOAN_ACCOUNT_LOAN_DOCUMENT_CHECKLIST", payload);
    },
  },
  getters: {
    get_identifier_loan_account_id: (state) => state.identifier.loanAccountId,
    get_identifier_saving_account_number: (state) =>
      state.identifier.savingAccountNumber,
    get_options_loan_account_loan_installment: (state) =>
      state.options.loanInstallment.loanAccount,
    get_options_loan_account_loan_collateral_fixed_asset: (state) =>
      state.options.loanCollateral.fixedAsset.loanAccount,
    get_options_loan_account_loan_collateral_vehicle: (state) =>
      state.options.loanCollateral.vehicle.loanAccount,
    get_options_loan_account_loan_collateral_decree: (state) =>
      state.options.loanCollateral.decree.loanAccount,
    get_options_loan_account_loan_collateral_precious_metal: (state) =>
      state.options.loanCollateral.preciousMetal.loanAccount,
    get_options_loan_account_loan_collateral_securities: (state) =>
      state.options.loanCollateral.securities.loanAccount,
    get_options_loan_account_loan_bi_code: (state) =>
      state.options.loanBiCode.loanAccount,
    get_options_loan_account_loan_document_checklist: (state) =>
      state.options.loanDocumentChecklist.loanAccount,
  },
};
