export default {
  name: "FormMProductLedger",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      formSchemeModel: {
        productCategoryId: "",
        productLedgerCode: "",
        productLedgerName: "",
        description: "",
        productLedgerId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      formScheme: {
        productCategoryId: {
          type: "select",
          label: "Tipe Layanan",
          models: "productCategoryId",
          selectProperty: {
            options: [],
            bindValue: "value",
            bindText: (item) => {
              return `${item.text}`;
            },
          },
        },
        productLedgerCode: {
          type: "text",
          label: "Kode Parameter",
          models: "productLedgerCode",
        },
        productLedgerName: {
          type: "text",
          label: "Nama Parameter",
          models: "productLedgerName",
        },
        description: {
          type: "text",
          label: "Deskripsi Parameter",
          models: "description",
        },
      },
    };
  },
  methods: {
    helperGetProductCategoryDataForReferenceForm() {
      this.$store.dispatch("GET_REFERENCE_PRODUCT_CATEGORY").then((resp) => {
        this.formScheme.productCategoryId.selectProperty.options = [
          {
            value: "",
            text: "-- Pilih --",
          },
        ];
        resp.data.data.map((i) => {
          const value = i.productCategoryId;
          const text = i.productCategoryName;
          this.formScheme.productCategoryId.selectProperty.options.push({
            text,
            value,
          });
        });
      });
    },
    routeToPageList() {
      this.$router.push("/product-ledger");
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.formSchemeModel.productCategoryId = payload?.productCategoryId;
        this.formSchemeModel.productLedgerCode = payload?.productLedgerCode;
        this.formSchemeModel.productLedgerName = payload?.productLedgerName;
        this.formSchemeModel.description = payload?.description;
        this.formSchemeModel.productLedgerId = payload?.productLedgerId;
        this.formSchemeModel.createdByUserCode = payload?.createdByUserCode;
        this.formSchemeModel.createdByUserName = payload?.createdByUserName;
        this.formSchemeModel.createdDate = payload?.createdDate;
        this.formSchemeModel.updatedDate = payload?.updatedDate;
        this.formSchemeModel.updatedByUserCode = payload?.updatedByUserCode;
        this.formSchemeModel.updatedByUserName = payload?.updatedByUserName;
        this.formSchemeModel.authorizedDate = payload?.authorizedDate;
        this.formSchemeModel.authorizedByUserCode =
          payload?.authorizedByUserCode;
        this.formSchemeModel.authorizedByUserName =
          payload?.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitProductLedger() {
      this.$emit("submitProductLedger", this.formSchemeModel);
    },
  },
  mounted() {
    this.checkingMainId();
    this.helperGetProductCategoryDataForReferenceForm();
  },
};
