export default {
  somethingWhenWrong: {
    title: "Gagal !",
    message: "Terjadi Kesalahan",
    confirmText: "Ok",
    type: "is-danger",
  },
  connectionsError: {
    message: `Koneksi Internet Terputus !`,
    type: "is-danger",
    duration: 2000,
    position: "is-top",
    queue: false,
  },
  success: {
    message: "Sukses !",
    type: "is-success",
    duration: 1000,
    position: "is-top",
    queue: false,
  },
  snackbarSuccess: {
    message: "Sukses !",
    type: "is-success",
    position: "is-bottom-right",
    indefinite: false,
  },
  snackbarConnectionError: {
    message: "Periksa Koneksi Internet Anda !",
    type: "is-danger",
    position: "is-bottom-right",
    indefinite: false,
  },
  apiNotFound: {
    message: `Api Belum Tersedia !`,
    type: "is-danger",
    duration: 3000,
    indefinite: false,
    position: "is-top",
    queue: false,
  },
  idNotFound: {
    message: `Ref Tidak Tersedia !`,
    type: "is-danger",
    duration: 2000,
    position: "is-top",
    queue: false,
  },
};
