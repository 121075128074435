export default {
  name: "TAtmChannelList",
  data() {
    return {
      property: {
        animation: {
          submitLoading: false,
        },
        filterDto: {
          sourceChannelCode: "",
          destinationChannelCode: "",
          dateFrom: "",
          dateTo: "",
        },
        listElement: {
          atmChannel: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      table: {
        data: {
          atmChannel: [],
        },
      },
    };
  },
  methods: {
    handleErrorGetAtmChannel(error) {
      console.log(error.response);
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.rows = 0;
      this.property.listElement.atmChannel.currentPage = 1;
      this.property.listElement.atmChannel.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetAtmChannel(resp) {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.rows = 0;
      this.property.listElement.atmChannel.message = resp.data.message;
    },
    async searchAtmChannel() {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel-transaction",
          payload: {
            sourceChannelCode: this.property.filterDto.sourceChannelCode,
            destinationChannelCode:
              this.property.filterDto.destinationChannelCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.atmChannel.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.atmChannel = resp.data.data.content;
            this.property.listElement.atmChannel.rows =
              resp.data.data.totalElements;
            this.property.listElement.atmChannel.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetAtmChannel(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannel(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmChannel.downloading = false;
        }, timeout);
      }
    },
    async getTableAtmChannel() {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel-transaction",
          payload: {
            page: 0,
            size: this.property.listElement.atmChannel.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.atmChannel = resp.data.data.content;
            this.property.listElement.atmChannel.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetAtmChannel(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannel(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmChannel.downloading = false;
        }, timeout);
      }
    },
    async changePagination(event) {
      this.table.data.atmChannel = [];
      this.property.listElement.atmChannel.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "atm-channel-transaction",
          payload: {
            sourceChannelCode: this.property.filterDto.sourceChannelCode,
            destinationChannelCode:
              this.property.filterDto.destinationChannelCode,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.atmChannel.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.atmChannel = resp.data.data.content;
            this.property.listElement.atmChannel.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetAtmChannel(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetAtmChannel(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.atmChannel.downloading = false;
        }, timeout);
      }
    },
    routeToPageDetail(props) {
      this.$router.push(
        `/t-atm-channel/${this.encryptBASE64(`${props.row.atmChannelId}`)}`
      );
    },
    routeToCreate() {
      this.$router.push("/t-atm-channel/new");
    },
  },
  mounted() {
    this.getTableAtmChannel();
  },
};
