var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-3",staticStyle:{"max-width":"500px"}},[_c('form-builder',{attrs:{"message-mapper":_vm.message,"scheme":{
          nomorInventory: {
            type: 'text',
            label: 'Nomor Inventory',
            placeholder: 'GENERATE BY SYSTEM',
            isInformationComponent: true,
            value: _vm.form.nomorInventory,
            disabled: true,
          },
          namaBiaya: {
            type: 'text',
            label: 'Nama Biaya',
            models: 'namaBiaya',
          },
          kelompokBiaya: {
            type: 'model-list-select',
            label: 'Kelompok Biaya',
            models: 'kelompokBiaya',
            selectProperty: {
              options: _vm.options.kelompokBiaya,
              bindValue: 'value',
              bindText: function (item) {
                return ("" + (item.text));
              },
            },
          },
          keterangan: {
            type: 'textarea',
            label: 'Keterangan',
            models: 'keterangan',
          },

          nominalBayar: {
            type: 'money',
            label: 'Nominal Bayar',
            models: 'nominalBayar',
          },
          tanggalBeli: {
            type: 'date',
            label: 'Tanggal Bayar',
            models: 'tanggalBeli',
          },
          masa: {
            type: 'text',
            label: 'Masa',
            models: 'masa',
            appendText: 'Bulan',
          },
        }},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('div',{staticClass:"flex mt-32 mb-3 justify-center w-full"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.routeToPageList}},[_vm._v("Kembali")]),_c('b-button',{staticClass:"button-theme-confirm ",attrs:{"loading":_vm.property.animation.submitLoading,"disabled":!_vm.$store.getters['getActionsInsert'] ||
              _vm.property.animation.submitLoading === true,"type":"is-success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" Simpan ")])],1)]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.form.createdByUserCode,"createdByUserName":_vm.form.createdByUserName,"createdDate":_vm.form.createdDate,"updatedDate":_vm.form.updatedDate,"updatedByUserCode":_vm.form.updatedByUserCode,"updatedByUserName":_vm.form.updatedByUserName,"authorizedDate":_vm.form.authorizedDate,"authorizedByUserCode":_vm.form.authorizedByUserCode,"authorizedByUserName":_vm.form.authorizedByUserName}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }