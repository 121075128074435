export default {
  name: "FormAmortisasiBiaya",
  props: {
    isExisting: false,
    message: {
      type: Object,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      form: {
        nomorInventory: "",
        namaBiaya: "",
        kelompokBiaya: "",
        nominalBayar: "",
        tanggalBeli: "",
        perkiraanLawan: "",
        masa: 0,
        keterangan: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },

      options: {
        kelompokBiaya: [],
        perkiraanLawan: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        return;
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.nomorInventory = payload.inventoryNumber;
        this.form.namaBiaya = payload.inventoryAmortitationName;
        this.form.kelompokBiaya = payload.masset?.assetId;
        this.form.nominalBayar = payload.inventoryPrice;
        this.form.tanggalBeli = payload.dateBuy;
        this.form.masa = payload.timePeriod;
        this.form.keterangan = payload.description;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/amortisasi-biaya");
    },
    submit() {
      this.$emit("submit", this.form);
    },
    async getReferenceInventoryCategory() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "asset/list",
          payload: {
            fixedAsset: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.kelompokBiaya = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((i) => {
            const text = i.assetName;
            const value = i.assetId;
            this.options.kelompokBiaya.push({ text, value });
          });
        }
      } catch (error) {}
    },
    // async getReferenceChartOfAccount() {
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_DATA_FIND_BY",
    //       reqUrl: "chart-of-account/list",
    //       endPoint: "parameter",
    //     });
    //     if (resp.data.code === "SUCCESS") {
    //       this.options.perkiraanLawan = [{ value: "", text: "-- Pilih --" }];
    //       resp.data.data.map((index) => {
    //         const text = `${index.description} - ${index.chartOfAccountCode}`;
    //         const value = index.chartOfAccountId;
    //         const description = index.description;
    //         this.options.perkiraanLawan.push({
    //           text,
    //           value,
    //           description,
    //         });
    //       });
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceInventoryCategory();
  },
};
