import MixinsFuntions from "../../../../service/MixinsFuntions";

export default {
  name: "ListMDepositBilyet",
  mixins: [MixinsFuntions],
  data() {
    return {
      dataForm: {
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      session: {
        depositAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        animation: {
          checkingDepositAccount: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingDepositAccount: false,
          showModalCheckingResultDepositAccount: false,
        },
        listElement: {
          depositBilyet: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          depositAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          depositBilyetNumber: "",
          depositAccountNumber: "",
          statusId: "",
        },
      },
      table: {
        data: {
          depositBilyet: [],
          depositAccount: [],
        },
      },
      options: {
        statusAccount: [],
      },
    };
  },
  methods: {
    async findDepositBilyet() {
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/simple-list",
          payload: {
            depositAccountNumber: this.property.filterDto.depositAccountNumber,
            depositBilyetNumber: this.property.filterDto.depositBilyetNumber,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.depositBilyet.perPage,
            sortBy: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositBilyet = resp.data.data.content;
            this.property.listElement.depositBilyet.rows =
              resp.data.data.totalElements;
            this.property.listElement.depositBilyet.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDepositBilyet(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositBilyet(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositBilyet.downloading = false;
        }, timeout + 500);
      }
    },

    async checkingAvailabilityDepositAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.depositAccount.accountNumber = this.dataForm.depositAccount.accountNumber;
        this.session.depositAccount.cifIdName = this.dataForm.depositAccount.cifIdName;
        this.dataForm.depositAccount.accountNumber = "";
        this.dataForm.depositAccount.cifIdName = "";
        const timeout = 500;
        try {
          this.property.animation.checkingDepositAccount.isLoading = true;
          this.property.listElement.depositAccount.downloading = false;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "deposit-account/simple-list",
            payload: {
              accountNumber: this.session.depositAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.depositAccount.cifIdName,
              dateFrom: "",
              dateTo: "",
              page: 0,
              size: this.property.listElement.depositAccount.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingDepositAccount = false;
              this.property.modal.showModalCheckingResultDepositAccount = true;
              this.table.data.depositAccount =
                resp.data.data.pageResponse.content;
              this.property.listElement.depositAccount.rows =
                resp.data.data.pageResponse.totalElements;
            } else if (resp.data.code === "DATA_NOT_FOUND") {
              this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
              this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
              this.session.depositAccount.accountNumber = "";
              this.session.depositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `Nomor Rekening Tidak Tersedia!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
              this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
              this.session.depositAccount.accountNumber = "";
              this.session.depositAccount.cifIdName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
          this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
          this.session.depositAccount.accountNumber = "";
          this.session.depositAccount.cifIdName = "";
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingDepositAccount.isLoading = false;
          }, timeout);
        }
      }
    },
    handleErrorDepositAccount(error) {
      this.dataForm.depositAccount.accountNumber = this.session.depositAccount.accountNumber;
      this.dataForm.depositAccount.cifIdName = this.session.depositAccount.cifIdName;
      this.session.depositAccount.accountNumber = "";
      this.session.depositAccount.cifIdName = "";
      console.log(error.response);
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.currentPage = 1;
      this.property.listElement.depositAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizeDepositBilyet(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-bilyet/authorized/" + props.row.depositBilyetId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositBilyet.currentPage = 1;
                  this.property.filterDto.depositBilyetNumber = "";
                  this.property.filterDto.depositAccountNumber = "";
                  this.property.filterDto.statusId = "",
                  this.property.listElement.depositBilyet.rows = 0;
                  this.getDepositBilyet();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routeToPageAddDepositBilyet(props) {
      this.$router.push(
        `/deposit/deposit-bilyet/new?refId=${this.encryptBASE64(
          JSON.stringify({ depositAccountId: props.row.depositAccountId })
        )}`
      );
    },
    routeToPageDetailDepositBilyet(props) {
      this.$router.push(
        `/deposit/deposit-bilyet/${this.encryptBASE64(
          `${props.row.depositBilyetId}`
        )}`
      );
    },
    async cancelBilyet(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      } else {
        this.$buefy.dialog.confirm({
          message: "Reject Bilyet Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl:
                  "deposit-bilyet/cancel-bilyet/" + props.row.depositBilyetId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositBilyet.currentPage = 1;
                  this.property.filterDto.depositBilyetNumber = "";
                  this.property.filterDto.depositAccountNumber = "";
                  this.property.filterDto.statusId = "",
                  this.property.listElement.depositBilyet.rows = 0;
                  this.getDepositBilyet();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteDepositBilyet(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "deposit-bilyet/" + props.row.depositBilyetId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositBilyet.currentPage = 1;
                  this.property.filterDto.depositBilyetNumber = "";
                  this.property.filterDto.depositAccountNumber = "";
                  this.property.filterDto.statusId = ""
                  this.property.listElement.depositBilyet.rows = 0;
                  this.getDepositBilyet();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationDepositAccount(event) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: this.session.depositAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.depositAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.depositAccount.perPage,
            dateFrom: "",
            dateTo: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.depositAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.data.depositAccount =
              resp.data.data.pageResponse.content;
          } else {
            this.failedGetDepositAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetDepositAccount(resp) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.message = resp.data.message;
    },
    handleErrorGetDepositBilyet(error) {
      console.log(error.response);
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.rows = 0;
      this.property.listElement.depositBilyet.currentPage = 1;
      this.property.listElement.depositBilyet.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationDepositBilyet(event) {
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/simple-list",
          payload: {
            depositBilyetNumber: this.property.filterDto.depositBilyetNumber,
            depositAccountNumber: this.property.filterDto.depositAccountNumber,
            statusId: this.property.filterDto.statusId,
            page: event - 1,
            size: this.property.listElement.depositBilyet.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositBilyet = resp.data.data.content;
            this.property.listElement.depositBilyet.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDepositBilyet(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositBilyet(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositBilyet.downloading = false;
        }, timeout);
      }
    },
    async getDepositBilyet() {
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/simple-list",
          payload: {
            depositBilyetNumber: "",
            depositAccountNumber: "",
            statusId: "",
            page: 0,
            size: this.property.listElement.depositBilyet.perPage,
          },
        });

        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositBilyet = resp.data.data.content;
            this.property.listElement.depositBilyet.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDepositBilyet(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositBilyet(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositBilyet.downloading = false;
        }, timeout);
      }
    },
    failedGetDepositBilyet(resp) {
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.rows = 0;
      this.property.listElement.depositBilyet.message = resp.data.message;
    },
    clearModalCheckingDepositAccount() {
      this.dataForm.depositAccount.accountNumber = "";
      this.dataForm.depositAccount.cifIdName = "";
    },
    clearModalResultCheckingDepositAccount() {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.perPage = 5;
      this.property.listElement.depositAccount.currentPage = 1;
    },
    closeModalCheckingDepositAccount() {
      this.property.modal.showModalCheckingDepositAccount = false;
      this.clearModalCheckingDepositAccount();
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getDepositBilyet();
    this.getReferenceStatusAccount();
  },
};
