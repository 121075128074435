import FormMLoanCollectability from "../form";

export default {
  name: "CreateMLoanCollectability",
  components: {
    FormMLoanCollectability,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      formMessage: {},
    };
  },
  methods: {
    async addLoanCollectability(payload) {
      const data = {
        daysInArrearsBeforeMin: payload.daysInArrearsBeforeMin,
        daysInArrearsBeforeMax: payload.daysInArrearsBeforeMax,
        daysInArrearsAfterMin: payload.daysInArrearsAfterMin,
        daysInArrearsAfterMax: payload.daysInArrearsAfterMax,
        weight: payload.weight,
        collectabilityIncomeTypeId: payload.collectabilityIncomeTypeId,
        loanCollectabilityStatusId: payload.loanCollectabilityStatusId,
        collectabilityCodeId: payload.collectabilityCodeId,
        profitShareEstimate: payload.profitShareEstimate,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.formMessage = {};
      this.$refs.formMLoanCollectabilityRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formMLoanCollectabilityRef.routeToPageListLoanCollectability();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.formMessage = error?.response?.data?.errorFields;
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formMLoanCollectabilityRef.property.animation.submitLoading = false;
      }
    },
  },
};
