export default {
  name: "AuthorizedAllTransaction",
  data() {
    return {
      form: {
        detail: [],
      },
      dataForm: {
        allTransaction: "",
        reRenderComponent: true,
        overbookChartOfAccount: {
          description: "",
        },
        interbankAssets: {
          description: "",
        }
      },
      property: {
        modal: {
          showModalRincianTransaksiSBB: false,
          showModalRincianTransaksiMulti: false,
          showModalRincianTransaksiABA: false,
          downloading: false,
        },
        animation: {
          submitLoading: false,
        },
        listElement: {
          cashAdvance: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          cashAdvanceReturn: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          overbookChartOfAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          overbook: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          multiAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          virtualAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          virtualAccountPpob: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          cashCollection: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          balanceCash: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "DATA NOT FOUND",
            downloading: false,
          },
          savingAccountDetail: {
            rows: 0,
            detailOpened: [],
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          inventoryBilyet: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          depositBilyet: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          depositAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          mortgageAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          loanAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          savingAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          cif: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanAccountReschedule: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanAccountRestructure: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanPaid: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanPaymentReturn: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          writeOff: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          collectorFunding: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          taskInterbankLiability: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          taskInterbankAsset: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          InterbankLiability: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          interbankAssets: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          sbr: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          mortgagePaidOff: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          depositDisburse: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          generatePinAtm: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          savingAccountClose: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          savingBlock: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          journalLedgerReversal: {
            detailOpened: [],
            subDetailOpened: [],
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          cashAdvance: {
            cashAdvanceName: "",
            cashAdvanceCode: "",
            dateTo: "",
            dateFrom: "",
          },
          cashAdvanceReturn: {
            cashAdvanceName: "",
            cashAdvanceCode: "",
            dateTo: "",
            dateFrom: "",
            statusId: "",
          },
          overbookChartOfAccount: {
            dateFrom: "",
            dateTo: "",
            transactionCode: "",
          },
          overbook: {
            name: "",
            accountNumber: "",
          },
          multiAccount: {
            documentProofNumber: "",
            dateFrom: "",
            dateTo: "",
          },
          virtualAccount: {
            virtualAccountNumber: "",
            accountNumber: "",
          },
          virtualAccountPpob: {
            dateFrom: "",
            dateTo: "",
            virtualAccountNumber: "",
          },
          cashCollection: {
            edcCode: "",
            dateFrom: "",
            dateTo: "",
            collectorUserName: "",
          },
          balanceCash: {
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
            userName: "",
          },
          savingAccountDetaiRak: {
            accountNumber: "",
            dateFrom: "",
            dateTo: "",
          },
          savingAccountDetail: {
            accountNumber: "",
            name: "",
            dateTo: "",
            dateFrom: "",
          },
          inventoryBilyet: {
            depositBilyetNumber: "",
            bilyetTypeId: "",
          },
          depositBilyet: {
            depositBilyetNumber: "",
            depositAccountNumber: "",
          },
          depositAccount: {
            cifIdName: "",
            accountNumber: "",
            dateTo: "",
            dateFrom: "",
            cityName: "",
            orderByBalanceEnd: false,
            statusAccountId: "",
          },
          mortgageAccount: {
            mortgageNumber: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
            statusAccountId: "",
          },
          loanAccount: {
            loanAccountNumber: "",
            officeCode: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
            cityName: "",
            referalOfficeId: "",
            orderByBalanceEnd: false,
          },
          savingAccount: {
            accountNumber: "",
            cifIdName: "",
            dateTo: "",
            dateFrom: "",
            idCardNumber: "",
            cityName: "",
            statusAccountId: "",
            referalOfficeId: "",
            orderByBalanceEnd: false,
          },
          cif: {
            cifIdName: "",
            categoryId: "",
            cifNumber: "",
            officeCode: "",
          },
          loanAccountReschedule: {
            loanAccountNumber: "",
            cifIdName: "",
            officeCode: "",
            dateFrom: "",
            dateTo: "",
          },
          loanAccountRestructure: {
            loanAccountNumber: "",
            cifIdName: "",
            dateFrom: "",
            officeCode: "",
            dateTo: "",
          },
          loanPaid: {
            loanAccountNumber: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
          },
          loanPaymentReturn: {
            accountNumber: "",
            name: "",
          },
          writeOff: {
            loanAccountNumber: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
          },
          collectorFunding: {
            collectorFundingUserId: "",
            chartOfAccountCodePair: "",
            accountNumberPair: "",
            officeId: "",
            statusId: "",
            dateFrom: "",
            dateTo: "",
          },
          taskInterbankLiability: {
            transactionCodeId: "",
            dateFrom: "",
            dateTo: "",
            statusId: "",
          },
          taskInterbankAsset: {
            name: "",
            transactionCodeId: "",
            dateFrom: "",
            dateTo: "",
            statusAccountId: "",
          },
          InterbankLiability: {
            interbankLiabilityType: "",
            accountNumber: "",
            accountName: "",
          },
          interbankAssets: {
            interbankAssetsTypeId: "",
            accountNumber: "",
            accountName: "",
          },
          sbr: {
            numberSBR: "",
          },
          mortgagePaidOff: {
            setupDate: "",
            mortgageNoAccount: "",
          },
          depositDisburse: {
            referenceNumber: "",
            depositAccountNumber: "",
            dateFrom: "",
            dateTo: "",
          },
          generatePinAtm: {
            savingCardId: "",
            accountNumber: "",
            cifIdName: "",
          },
          savingAccountClose: {
            accountNumber: "",
            dateFrom: "",
            dateTo: "",
          },
          savingBlock: {
            accountNumber: "",
            blockedTypeId: "",
          },
          journalLedgerReversal: {
            journalCode: "",
            referenceNumber: "",
            branchCode: "",
            dateFrom: "",
            dateTo: "",
            isReversal: true,
            userId: "",
          },
        },
      },
      options: {
        accountType: [
          {
            text: "-- Pilih --",
            value: "",
            disabled: false,
          },
          {
            text: "Rekening Tabungan",
            value: "TAB",
            disabled: false,
          },
          {
            text: "ABA",
            value: "ABA",
            disabled: false,
          },
          {
            text: "SBB ",
            value: "SBB",
            disabled: false,
          },
        ],
        interbankAssets: [],
        chartOfAccount: [],
        office: [],

        umum: [
          {
            text: "Kolektor Funding",
            value: "kolektor funding",
          },
          {
            text: "Otorisasi Reversal",
            value: "otorisasi reversal",
          },
          {
            text: "UMB",
            value: "umb",
          },
          {
            text: "Pengembalian UMB",
            value: "Pengembalian Umb",
          },
          {
            text: "Transaksi ABP",
            value: "transaksi abp",
          },
          {
            text: "Transaksi ABA",
            value: "transaksi aba",
          },
          {
            text: "Master ABP",
            value: "master abp",
          },
          {
            text: "Master ABA",
            value: "master aba",
          },
        ],
        transaksi: [
          {
            text: "Pindah Buku SBB",
            value: "Pindah Buku Sbb",
          },
          {
            text: "Transaksi Multi",
            value: "Transaksi Multi",
          },
          // {
          //   text: "Transaksi Virtual Account",
          //   value: "Transaksi Virtual Account",
          // },
          // {
          //   text: "Transaksi Virtual Account PPOB",
          //   value: "Transaksi Virtual Account Ppob",
          // },
        ],
        teller: [
          // {
          //   text: "-- Pilih --",
          //   value: "",
          // },
          {
            text: "Pengisian Kas Teller",
            value: "Pengisian Kas Teller",
          },
          {
            text: "Transaksi Tunai Tabungan",
            value: "Transaksi Tunai Tabungan",
          },
        ],
        deposito: [
          {
            text: "Pencairan Deposito",
            value: "pencairan deposito",
          },
          {
            text: "Inventory Bilyet",
            value: "Inventory Bilyet",
          },
          {
            text: "Mapping Bilyet",
            value: "Mapping Bilyet",
          },
          {
            text: "Buka Rekening Deposito",
            value: "Buka Rekening Deposito",
          },
        ],
        rahn: [
          {
            text: "Kontrak RAHN",
            value: "Kontrak Rahn",
          },
          {
            text: "Register SBR",
            value: "register sbr",
          },
          {
            text: "Pelunasan Rahn",
            value: "pelunasan rahn",
          },
        ],
        pembiayaan: [
          {
            text: "Buka Rekening Pembiayaan",
            value: "Rekening Pembiayaan",
          },
          {
            text: "Restruk 1 Pembiayaan",
            value: "restruk 1",
          },
          {
            text: "Restruk 2 Pembiayaan",
            value: "restruk 2",
          },
          {
            text: "Pelunasan Pembiayaan",
            value: "pelunasan pembiayaan",
          },
          {
            text: "Koreksi Angsuran",
            value: "koreksi angsuran",
          },
          {
            text: "Hapus Buku",
            value: "hapus buku",
          },
        ],
        tabungan: [
          {
            text: "Generate Pin ATM",
            value: "generate atm",
          },
          {
            text: "Transaksi Overbook Tabungan",
            value: "Transaksi Overbook Tabungan",
          },
          {
            text: "Tutup Rekening Tabungan",
            value: "tutup rekening tabungan",
          },
          {
            text: "Blokir dan Buka Blokir Rekening Tabungan",
            value: "blokir dan buka blokir rekening tabungan",
          },
          {
            text: "Buka Rekening Tabungan",
            value: "Rekening Tabungan",
          },
        ],
        customerService: [
          {
            text: "Entry CIF",
            value: "cif",
          },
        ],
      },
      table: {
        data: {
          cashAdvance: [],
          cashAdvanceReturn: [],
          overbookChartOfAccount: [],
          overbook: [],
          multiAccount: [],
          virtualAccount: [],
          virtualAccountPpob: [],
          balanceCash: [],
          savingAccountDetaiRak: [],
          savingAccountDetail: [],
          depositBilyet: [],
          depositAccount: [],
          mortgageAccount: [],
          loanAccount: [],
          savingAccount: [],
          cif: [],
          loanAccountReschedule: [],
          loanAccountRestructure: [],
          loanPaid: [],
          loanPaymentReturn: [],
          writeOff: [],
          collectorFunding: [],
          taskInterbankLiability: [],
          taskInterbankAsset: [],
          InterbankLiability: [],
          interbankAssets: [],
          sbr: [],
          mortgagePaidOff: [],
          depositDisburse: [],
          generatePinAtm: [],
          savingAccountClose: [],
          savingBlock: [],
          journalLedgerReversal: [],
        },
      },
    };
  },
  computed: {
    nominalTotalVal() {
      let sum = 0;
      for (let item of this.form.detail) {
        sum = sum + item.nominal;
      }
      this.form.nominal = sum;
      return sum;
    },
  },
  methods: {
    /* START FUNCTION MODAL */
    // Pinbuk SBB
    closeModalRincianTransaksiSBB() {
      this.property.modal.showModalRincianTransaksiSBB = false;
      this.dataForm.overbookChartOfAccount.description = "";
    },
    async showModalKeteranganTransaksiSBB(props) {
      this.property.modal.showModalRincianTransaksiSBB = true;
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "transaction-coa/" + props.row.referenceNumber,
          });
          console.log("props", resp);
          if (resp.data.code === "SUCCESS") {
            this.dataForm.overbookChartOfAccount.description = resp.data.data.description
              ? resp.data.data.description
              : "";
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    // multi
    clearModalRincianTransaksiMulti() {
      this.property.modal.showModalRincianTransaksiMulti = false;
      this.form.detail = [];
    },
    async showModalRincianTransaksiMulti(props) {
      this.property.modal.showModalRincianTransaksiMulti = true;
      this.property.modal.downloading = true;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "t-multi-account/" + props.row.multiAccountId,
        });
        console.log("showModalRincianTransaksiMulti", resp);
        if (resp.data.code === "SUCCESS") {
          this.form.detail = resp.data.data.detail;
          var payload = resp.data.data.detail;
          this.insertRowMultiAccount(payload)
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
      } finally {
        this.property.modal.downloading = false;
      }
    },
    insertRowMultiAccount(payload) {
      console.log(payload);
      let data = [];
      while (data.length !== payload.length) {
        data.push({
          nominal: payload[data.length].nominal,
          description: payload[data.length].description,
          destinationAccountNumber: payload[data.length].accountNumber,
          cifIdName: payload[data.length].accountName,
          officeName: payload[data.length].accountOfficeName,
          accountType: payload[data.length].accountType,
          identifierId: Math.random()
            .toString(36)
            .substr(2, 9),
          officeId: payload[data.length].accountOfficeId,
        });
      }
      this.form.detail = data;
    },
    // ABA
    closeModalRincianTransaksiABA() {
      this.property.modal.showModalRincianTransaksiABA = false;
      this.dataForm.interbankAssets.description = "";
    },
    async showModalRincianTransaksiABA(props) {
      this.property.modal.showModalRincianTransaksiABA = true;
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "task-interbank-asset/" + props.row.taskInterbankAssetId,
          });
          console.log("props", resp);
          if (resp.data.code === "SUCCESS") {
            this.dataForm.interbankAssets.description = resp.data.data.description
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    /* END FUNCTION MODAL */

    /* START OPTION SELECT */
    async getReferenceInterbankAsset() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.interbankAssets = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.accountNumber;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = `${index.accountName} ( ${index.bankName} )`;
            const accountBranch = `${index.bankName} - Cabang ${index.accountBranch}`;
            const officeName = index.officeName;
            const officeId = index.officeId;
            this.options.interbankAssets.push({
              text,
              value,
              officeName,
              accountName,
              accountBranch,
              officeId,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.chartOfAccount = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountCode;
            const description = index.description;
            this.options.chartOfAccount.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "office/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.officeName} - ${index.officeCode}`;
            const value = index.officeId;
            const officeName = index.officeName;
            this.options.office.push({
              text,
              value,
              officeName,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* END OPTION SELECT */

    // UMB
    async getCashAdvance() {
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: {
            cashAdvanceName: "",
            cashAdvanceCode: "",
            isReturn: false,
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.cashAdvance = resp.data.data.content;
          this.property.listElement.cashAdvance.rows =
            resp.data.data.totalElements;
          // this.property.listElement.cashAdvance.message = resp.data.message;
        } else {
          this.failedGetDataCashAdvance(resp);
        }
      } catch (error) {
        await this.simpleWait(1000);
        this.handleErrorGetCashAdvance(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.cashAdvance.downloading = false;
      }
    },
    async authorizationCashAdvance(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "cash-advance/authorized/" + props.row.cashAdvanceId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.resetListCashAdvance();
                  this.getCashAdvance();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationCashAdvance(event) {
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: {
            cashAdvanceName: this.property.filterDto.cashAdvance
              .cashAdvanceName,
            cashAdvanceCode: this.property.filterDto.cashAdvance
              .cashAdvanceCode,
            isReturn: false,
            statusId: 1,
            dateFrom: this.property.filterDto.cashAdvance.dateFrom,
            dateTo: this.property.filterDto.cashAdvance.dateTo,
            page: event - 1,
            size: this.property.listElement.cashAdvance.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.cashAdvance = resp.data.data.content;
          this.property.listElement.cashAdvance.rows =
            resp.data.data.totalElements;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1000);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(500);
        this.property.listElement.cashAdvance.downloading = false;
      }
    },
    resetListCashAdvance() {
      this.property.listElement.cashAdvance.currentPage = 1;
      this.property.listElement.cashAdvance.rows = 0;
      this.property.filterDto.cashAdvanceName = "";
      this.property.filterDto.dateTo = "";
      this.property.filterDto.dateFrom = "";
    },
    failedGetDataCashAdvance(resp) {
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.rows = 0;
      this.property.listElement.cashAdvance.message = resp.data.message;
    },
    handleErrorGetCashAdvance(error) {
      console.log(error.response);
      this.table.data.cashAdvance = [];
      this.property.listElement.cashAdvance.rows = 0;
      this.property.listElement.cashAdvance.currentPage = 1;
      this.property.listElement.cashAdvance.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleAuthorizationBulkUmb() {
      this.$buefy.dialog.confirm({
        message: "Pastikan Anda Telah Memeriksa Transaksi !",
        type: "is-success",
        onConfirm: async () => {
          this.$buefy.toast.open(this.$NotificationUtils.apiNotFound);
        },
      });
    },

    // Pengembalian UMB
    async getCashAdvanceReturn() {
      this.table.data.cashAdvanceReturn = [];
      this.property.listElement.cashAdvanceReturn.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: {
            cashAdvanceName: "",
            cashAdvanceCode: "",
            statusId: 1,
            isReturn: true,
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashAdvanceReturn = resp.data.data.content;
            this.property.listElement.cashAdvanceReturn.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataCashAdvanceReturn(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashAdvanceReturn(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashAdvanceReturn.downloading = false;
        }, timeout);
      }
    },
    failedGetDataCashAdvanceReturn(resp) {
      this.table.data.cashAdvanceReturn = [];
      this.property.listElement.cashAdvanceReturn.rows = 0;
      this.property.listElement.cashAdvanceReturn.message = resp.data.message;
    },
    handleErrorGetCashAdvanceReturn(error) {
      console.log(error.response);
      this.table.data.cashAdvanceReturn = [];
      this.property.listElement.cashAdvanceReturn.rows = 0;
      this.property.listElement.cashAdvanceReturn.currentPage = 1;
      this.property.listElement.cashAdvanceReturn.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationCashAdvanceReturn(event) {
      this.table.data.cashAdvanceReturn = [];
      this.property.listElement.cashAdvanceReturn.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cash-advance",
          payload: {
            cashAdvanceName: this.property.filterDto.cashAdvanceReturn
              .cashAdvanceName,
            cashAdvanceCode: this.property.filterDto.cashAdvanceReturn
              .cashAdvanceCode,
            statusId: 1,
            isReturn: true,
            dateFrom: this.property.filterDto.cashAdvanceReturn.dateFrom,
            dateTo: this.property.filterDto.cashAdvanceReturn.dateTo,
            page: event - 1,
            size: this.property.listElement.cashAdvanceReturn.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cashAdvanceReturn = resp.data.data.content;
            this.property.listElement.cashAdvanceReturn.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataCashAdvanceReturn(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCashAdvanceReturn(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cashAdvance.downloading = false;
        }, timeout);
      }
    },
    async authorizationCashAdvanceReturn(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "cash-advance/unauthorized/" + props.row.cashAdvanceId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.resetListCashAdvanceReturn();
                  this.getCashAdvanceReturn();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    resetListCashAdvanceReturn() {
      this.property.listElement.cashAdvanceReturn.currentPage = 1;
      this.property.listElement.cashAdvanceReturn.rows = 0;
      this.property.filterDto.cashAdvanceReturn.cashAdvanceName = "";
      this.property.filterDto.cashAdvanceReturn.dateTo = "";
      this.property.filterDto.cashAdvanceReturn.dateFrom = "";
    },
    async deleteCashAdvance(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cash-advance/" + props.row.cashAdvanceId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.resetListCashAdvanceReturn();
                  this.getCashAdvanceReturn();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Pindah Buku SBB
    async getOverbookCoa() {
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "transaction-coa",
          payload: {
            transactionCode: "",
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbookChartOfAccount = resp.data.data.content;
            this.property.listElement.overbookChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOverbookChartOfAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorOverbookChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbookChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetDataOverbookChartOfAccount(resp) {
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.rows = 0;
      this.property.listElement.overbookChartOfAccount.message =
        resp.data.message;
    },
    handleErrorOverbookChartOfAccount(error) {
      console.log(error.response);
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.rows = 0;
      this.property.listElement.overbookChartOfAccount.currentPage = 1;
      this.property.listElement.overbookChartOfAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationOverbookChartOfAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "transaction-coa/authorized/" + props.row.referenceNumber,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.overbookChartOfAccount.currentPage = 1;
                  this.property.filterDto.overbookChartOfAccount.dateFrom = "";
                  this.property.filterDto.overbookChartOfAccount.dateTo = "";
                  this.property.listElement.overbookChartOfAccount.rows = 0;
                  this.getOverbookCoa();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationOverbookChartOfAccount(event) {
      this.table.data.overbookChartOfAccount = [];
      this.property.listElement.overbookChartOfAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "transaction-coa",
          payload: {
            transactionCode: this.property.filterDto.overbookChartOfAccount
              .transactionCode,
            dateFrom: this.property.filterDto.overbookChartOfAccount.dateFrom,
            dateTo: this.property.filterDto.overbookChartOfAccount.dateTo,
            page: event - 1,
            statusId: 1,
            size: this.property.listElement.overbookChartOfAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.overbookChartOfAccount = resp.data.data.content;
          this.property.listElement.overbookChartOfAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataOverbookChartOfAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorOverbookChartOfAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.overbookChartOfAccount.downloading = false;
      }
    },

    // Transaksi Overbook Tabungan
    async helperGetDataTableOverbook() {
      this.table.data.overbook = [];
      this.property.listElement.overbook.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "overbook",
          payload: {
            accountNumber: "",
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_SAVING_OVERBOOK,
            statusId: 1,
            name: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbook = resp.data.data.content;
            this.property.listElement.overbook.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbook.downloading = false;
        }, timeout);
      }
    },
    failedGetDataOverbook(resp) {
      this.table.data.overbook = [];
      this.property.listElement.overbook.rows = 0;
      this.property.listElement.overbook.message = resp.data.message;
    },
    handleCatchErrorOverbook(error) {
      console.log(error.response);
      this.table.data.overbook = [];
      this.property.listElement.overbook.rows = 0;
      this.property.listElement.overbook.currentPage = 1;
      this.property.listElement.overbook.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationOverbook(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "overbook/authorized/" + props.row.referenceNumber,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.overbook.currentPage = 1;
                  this.property.filterDto.overbook.accountNumber = "";
                  this.property.filterDto.overbook.name = "";
                  this.property.listElement.overbook.rows = 0;
                  this.helperGetDataTableOverbook();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationOverbook(event) {
      this.table.data.overbook = [];
      this.property.listElement.overbook.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "overbook",
          payload: {
            accountNumber: this.property.filterDto.overbook.accountNumber,
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_SAVING_OVERBOOK,
            name: this.property.filterDto.overbook.name,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.overbook.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbook = resp.data.data.content;
            this.property.listElement.overbook.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbook.downloading = false;
        }, timeout);
      }
    },

    // Transaksi Multi
    async getMultiAccount() {
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account",
          payload: {
            documentProofNumber: "",
            accountNumber: "",
            transactionCode: "",
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.multiAccount = resp.data.data.content;
            this.property.listElement.multiAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetMultiAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMultiAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.multiAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetMultiAccount(resp) {
      this.property.listElement.multiAccount.rows = 0;
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.message = resp.data.message;
    },
    handleErrorGetMultiAccount(error) {
      console.log(error.response);
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.rows = 0;
      this.property.listElement.multiAccount.currentPage = 1;
      this.property.listElement.multiAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationMultiAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "t-multi-account/authorized/" + props.row.multiAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.multiAccount.currentPage = 1;
                  this.property.filterDto.multiAccount.dateFrom = "";
                  this.property.filterDto.multiAccount.dateTo = "";
                  this.property.filterDto.multiAccount.documentProofNumber = "";
                  this.property.listElement.multiAccount.rows = 0;
                  this.getMultiAccount();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationMultiAccount(event) {
      this.table.data.multiAccount = [];
      this.property.listElement.multiAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account",
          payload: {
            documentProofNumber: this.property.filterDto.multiAccount
              .documentProofNumber,
            transactionCode: "",
            accountNumber: "",
            page: event - 1,
            size: this.property.listElement.multiAccount.perPage,
            dateTo: this.property.filterDto.multiAccount.dateTo,
            dateFrom: this.property.filterDto.multiAccount.dateFrom,
            statusId: 1,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.multiAccount = resp.data.data.content;
            this.property.listElement.multiAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetMultiAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMultiAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.multiAccount.downloading = false;
        }, timeout);
      }
    },
    deleteMultiAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "t-multi-account/" + props.row.multiAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.multiAccount.currentPage = 1;
                  this.property.listElement.multiAccount.rows = 0;
                  this.getMultiAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Transaksi Virtual Account
    // async getVirtualAccountList() {
    //   this.table.data.virtualAccount = [];
    //   this.property.listElement.virtualAccount.downloading = true;
    //   const timeout = 500;
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_LIST_DATA",
    //       endPoint: "transaction",
    //       reqUrl: "virtual-account",
    //       payload: {
    //         virtualAccountNumber: "",
    //         accountNumber: "",
    //         statusId: 1,
    //         page: 0,
    //         size: 10,
    //       },
    //     });
    //     setTimeout(() => {
    //       if (resp.data.code === "SUCCESS") {
    //         this.table.data.virtualAccount = resp.data.data.content;
    //         this.property.listElement.virtualAccount.rows =
    //           resp.data.data.totalElements;
    //       } else {
    //         this.failedGetDataVirtualAccoun(resp);
    //       }
    //     }, timeout);
    //   } catch (error) {
    //     setTimeout(() => {
    //       this.handleErrorVirtualAccount(error);
    //     }, timeout);
    //   } finally {
    //     setTimeout(() => {
    //       this.property.listElement.virtualAccount.downloading = false;
    //     }, timeout);
    //   }
    // },
    // failedGetDataVirtualAccoun(resp) {
    //   this.table.data.virtualAccount = [];
    //   this.property.listElement.virtualAccount.rows = 0;
    //   this.property.listElement.virtualAccount.message = resp.data.message;
    // },
    // handleErrorVirtualAccount(error) {
    //   this.table.data.virtualAccount = [];
    //   this.property.listElement.virtualAccount.rows = 0;
    //   this.property.listElement.virtualAccount.currentPage = 1;
    //   this.property.listElement.virtualAccount.message = error.response
    //     ? `Gagal Mengunduh Data : ${error.response.statusText}`
    //     : "Terjadi Kesalahan";
    // },
    // async auhtorizeVirtualAccount(props) {
    //   if (window.navigator.onLine === false) {
    //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
    //     return;
    //   } else {
    //     this.$buefy.dialog.confirm({
    //       message: "Otorisasi Data Ini ?",
    //       type: "is-success",
    //       onConfirm: async () => {
    //         try {
    //           const resp = await this.$store.dispatch({
    //             type: "PUT_DATA_AUTHORIZED",
    //             endPoint: "transaction",
    //             reqUrl:
    //               "virtual-account/authorized/" + props.row.virtualAccountId,
    //           });
    //           if (resp.data.code === "SUCCESS") {
    //             this.$buefy.toast.open(this.$NotificationUtils.success);
    //             setTimeout(() => {
    //               this.property.listElement.virtualAccount.currentPage = 1;
    //               this.property.listElement.virtualAccount.rows = 0;
    //               this.property.filterDto.virtualAccount.virtualAccountNumber =
    //                 "";
    //               this.property.filterDto.virtualAccount.accountNumber = "";
    //               this.getVirtualAccountList();
    //             }, 1000);
    //           } else {
    //             this.$buefy.dialog.alert({
    //               title: "Gagal !",
    //               message: `${resp.data.message}`,
    //               confirmText: "Ok",
    //               type: "is-danger",
    //             });
    //           }
    //         } catch (error) {
    //           console.log(error.response);
    //           this.$buefy.dialog.alert({
    //             title: "Gagal !",
    //             message: error.response
    //               ? `${error.response.statusText}`
    //               : "Terjadi Kesalahan",
    //             confirmText: "Ok",
    //             type: "is-danger",
    //           });
    //         }
    //       },
    //     });
    //   }
    // },
    // async changePaginationVirtualAccount(event) {
    //   this.table.data.virtualAccount = [];
    //   this.property.listElement.virtualAccount.downloading = true;
    //   const timeout = 500;

    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_LIST_DATA",
    //       endPoint: "transaction",
    //       reqUrl: "virtual-account",
    //       payload: {
    //         virtualAccountNumber: this.property.filterDto.virtualAccount
    //           .virtualAccountNumber,
    //         accountNumber: this.property.filterDto.virtualAccount.accountNumber,
    //         statusId: 1,
    //         page: event - 1,
    //         size: this.property.listElement.virtualAccount.perPage,
    //       },
    //     });
    //     setTimeout(() => {
    //       if (resp.data.code === "SUCCESS") {
    //         this.table.data.virtualAccount = resp.data.data.content;
    //         this.property.listElement.virtualAccount.rows =
    //           resp.data.data.totalElements;
    //       } else {
    //         this.failedGetDataVirtualAccoun(resp);
    //       }
    //     }, timeout);
    //   } catch (error) {
    //     setTimeout(() => {
    //       this.handleErrorVirtualAccount(error);
    //     }, timeout);
    //   } finally {
    //     setTimeout(() => {
    //       this.property.listElement.virtualAccount.downloading = false;
    //     }, timeout);
    //   }
    // },

    // Transaksi Virtual Account Ppob
    // async getVirtualAccountPpob() {
    //   this.table.data.virtualAccountPpob = [];
    //   this.property.listElement.virtualAccountPpob.downloading = true;
    //   const timeout = 500;
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_LIST_DATA",
    //       endPoint: "transaction",
    //       reqUrl: "virtual-account-ppob",
    //       payload: {
    //         dateFrom: "",
    //         dateTo: "",
    //         statusId: 1,
    //         virtualAccountNumber: "",
    //         page: 0,
    //         size: 10,
    //       },
    //     });
    //     setTimeout(() => {
    //       if (resp.data.code === "SUCCESS") {
    //         this.table.data.virtualAccountPpob = resp.data.data.content;
    //         this.property.listElement.virtualAccountPpob.rows =
    //           resp.data.data.totalElements;
    //       } else {
    //         this.failedGetVirtualAccountPpob(resp);
    //       }
    //     }, timeout);
    //   } catch (error) {
    //     this.handleErrorVirtualAccountPpob(error);
    //   } finally {
    //     setTimeout(() => {
    //       this.property.listElement.virtualAccountPpob.downloading = false;
    //     }, timeout);
    //   }
    // },
    // failedGetVirtualAccountPpob(resp) {
    //   this.table.data.virtualAccountPpob = [];
    //   this.property.listElement.virtualAccountPpob.rows = 0;
    //   this.property.listElement.virtualAccountPpob.message = resp.data.message;
    // },
    // handleErrorVirtualAccountPpob(error) {
    //   console.log(error.response);
    //   this.table.data.virtualAccountPpob = [];
    //   this.property.listElement.virtualAccountPpob.rows = 0;
    //   this.property.listElement.virtualAccountPpob.currentPage = 1;
    //   this.property.listElement.virtualAccountPpob.message = error.response
    //     ? `Gagal Mengunduh Data : ${error.response.statusText}`
    //     : "Terjadi Kesalahan";
    // },
    // async changePaginationVirtualAccountPpob(event) {
    //   this.table.data.virtualAccountPpob = [];
    //   this.property.listElement.virtualAccountPpob.downloading = true;
    //   const timeout = 500;
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_LIST_DATA",
    //       endPoint: "transaction",
    //       reqUrl: "virtual-account-ppob",
    //       payload: {
    //         dateFrom: this.property.filterDto.virtualAccountPpob.dateFrom,
    //         dateTo: this.property.filterDto.virtualAccountPpob.dateTo,
    //         virtualAccountNumber: this.property.filterDto.virtualAccountPpob
    //           .virtualAccountNumber,
    //         statusId: 1,
    //         page: event - 1,
    //         size: this.property.listElement.virtualAccountPpob.perPage,
    //       },
    //     });
    //     setTimeout(() => {
    //       if (resp.data.code === "SUCCESS") {
    //         this.table.data.virtualAccountPpob = resp.data.data.content;
    //         this.property.listElement.virtualAccountPpob.rows =
    //           resp.data.data.totalElements;
    //       } else {
    //         this.failedGetVirtualAccountPpob(resp);
    //       }
    //     }, timeout);
    //   } catch (error) {
    //     setTimeout(() => {
    //       this.handleErrorVirtualAccountPpob(error);
    //     }, timeout);
    //   } finally {
    //     setTimeout(() => {
    //       this.property.listElement.virtualAccountPpob.downloading = false;
    //     }, timeout);
    //   }
    // },

    // Pengisian Kas Teller
    async getBalanceCash() {
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "balance-cash",
          payload: {
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.balanceCash = resp.data.data.content;
            this.property.listElement.balanceCash.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataBalanceCash(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorBalanceCash(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.balanceCash.downloading = false;
        }, timeout);
      }
    },
    failedGetDataBalanceCash(resp) {
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.rows = 0;
      this.property.listElement.balanceCash.message = resp.data.message;
    },
    handleErrorBalanceCash(error) {
      console.log(error.response);
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.rows = 0;
      this.property.listElement.balanceCash.currentPage = 1;
      this.property.listElement.balanceCash.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationBalanceCash(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "balance-cash/authorized/" + props.row.balanceCashId,
              });
              if (resp.data.code === "SUCCESS") {
                this.property.filterDto.balanceCash.dateFrom = "";
                this.property.filterDto.balanceCash.dateTo = "";
                this.getBalanceCash();
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationBalanceCash(event) {
      this.table.data.balanceCash = [];
      this.property.listElement.balanceCash.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "balance-cash",
          payload: {
            dateFrom: this.property.filterDto.balanceCash.dateFrom,
            dateTo: this.property.filterDto.balanceCash.dateTo,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.balanceCash.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.balanceCash = resp.data.data.content;
            this.property.listElement.balanceCash.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataBalanceCash(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorBalanceCash(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.balanceCash.downloading = false;
        }, timeout);
      }
    },
    async deleteBalanceCash(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "balance-cash/" + props.row.balanceCashId,
              });
              if (resp.data.code === "SUCCESS") {
                this.getBalanceCash();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Transaksi Tunai Tabungan
    async helperGetDataTableSavingAccountDetail() {
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/simple-list-v2",
          payload: {
            accountNumber: "",
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            name: "",
            statusId: 1,
            page: 0,
            size: this.property.listElement.savingAccountDetail.perPage,
            dateTo: "",
            dateFrom: this.property.filterDto.savingAccountDetail.dateFrom,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.insertSavingAccountDetailTable(resp);
          this.property.listElement.savingAccountDetail.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountDetail.downloading = false;
      }
    },
    insertSavingAccountDetailTable(resp) {
      this.table.data.savingAccountDetail = [];
      console.log(resp);
      resp.data.data.content.map((index) => {
        const savingAccountDetailId = index.header.savingAccountDetailId;
        this.table.data.savingAccountDetail.push({
          savingAccountDetailId,
          header: index.header,
          detail: index.detail,
        });
      });
    },
    failedGetDataSavingAccountDetail(resp) {
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.rows = 0;
      this.property.listElement.savingAccountDetail.message = resp.data.message;
    },
    handleCatchErrorSavingAccountDetail(error) {
      console.log(error.response);
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.rows = 0;
      this.property.listElement.savingAccountDetail.currentPage = 1;
      this.property.listElement.savingAccountDetail.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizeSavingAccountDetail(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/transaction_cash/authorized/" +
            props.row.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.savingAccountDetail.currentPage = 1;
          this.property.filterDto.savingAccountDetail.accountNumber = "";
          this.property.filterDto.savingAccountDetail.dateTo = "";
          this.property.filterDto.savingAccountDetail.dateFrom = "";
          this.property.listElement.savingAccountDetail.rows = 0;
          this.helperGetDataTableSavingAccountDetail();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async changePaginationSavingAccountDetail(event) {
      this.table.data.savingAccountDetail = [];
      this.property.listElement.savingAccountDetail.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/simple-list-v2",
          payload: {
            accountNumber: this.property.filterDto.savingAccountDetail
              .accountNumber,
            transactionCode: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_CLOSE_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_SETORAN_TUNAI_TAB
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_TARIK_TUNAI_TAB
            }`,
            page: event - 1,
            statusId: 1,
            size: this.property.listElement.savingAccountDetail.perPage,
            dateTo: this.property.filterDto.savingAccountDetail.dateTo,
            dateFrom: this.property.filterDto.savingAccountDetail.dateFrom,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.insertSavingAccountDetailTable(resp);
          this.property.listElement.savingAccountDetail.rows =
            resp.data.data.totalElements;
          this.defaultOpenDetailSavingAccountDetail(resp);
        } else {
          this.failedGetDataSavingAccountDetail(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorSavingAccountDetail(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountDetail.downloading = false;
      }
    },
    async deleteSavingAccountDetail(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "saving-account-detail/" + props.row.savingAccountDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.snackbar.open(
                  this.$NotificationUtils.snackbarSuccess
                );
                await this.simpleWait(1000);
                this.helperGetDataTableSavingAccountDetail();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Inventory Bilyet
    async getInventoryBilyet() {
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/inventory/simple-list",
          payload: {
            depositBilyetNumber: "",
            bilyetTypeId: "",
            bilyetStatusId: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.inventoryBilyet = resp.data.data.content;
          this.property.listElement.inventoryBilyet.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetInventoryBilyet(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetInventoryBilyet(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.inventoryBilyet.downloading = false;
      }
    },
    failedGetInventoryBilyet(resp) {
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.rows = 0;
      this.property.listElement.inventoryBilyet.message = resp.data.message;
    },
    handleErrorGetInventoryBilyet(error) {
      console.log(error.response);
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.rows = 0;
      this.property.listElement.inventoryBilyet.currentPage = 1;
      this.property.listElement.inventoryBilyet.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationMDepositBiletInventory(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/authorized/" + props.row.depositBilyetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.inventoryBilyet.currentPage = 1;
          this.property.filterDto.inventoryBilyet.depositBilyetNumber = "";
          this.property.filterDto.inventoryBilyet.bilyetTypeId = "";
          this.property.listElement.inventoryBilyet.rows = 0;
          this.getInventoryBilyet();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async changePaginationBilyetInventory(event) {
      this.table.data.inventoryBilyet = [];
      this.property.listElement.inventoryBilyet.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/inventory/simple-list",
          payload: {
            depositBilyetNumber: this.property.filterDto.inventoryBilyet
              .depositBilyetNumber,
            bilyetTypeId: this.property.filterDto.inventoryBilyet.bilyetTypeId,
            bilyetStatusId: "",
            statusId: 1,
            sortBy: "",
            page: event - 1,
            size: this.property.listElement.inventoryBilyet.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.inventoryBilyet = resp.data.data.content;
          this.property.listElement.inventoryBilyet.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetInventoryBilyet(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetInventoryBilyet(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.inventoryBilyet.downloading = false;
      }
    },
    async authorizedBulkDepositBilyet() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const getTotalUnAuthorized = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/total-unauthorized",
        });
        if (getTotalUnAuthorized.data.code === "SUCCESS") {
          if (getTotalUnAuthorized.data.data === 0) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: "Tidak Terdapat Data Yang Belum Otorisasi",
              confirmText: "Ok",
              type: "is-danger",
            });
            return;
          }

          const confirm = await this.simpleConfirmation(
            "Otorisasi Data Ini ?",
            "is-success"
          );
          if (!confirm) return;
          this.property.animation.submitLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "transaction",
              reqUrl: "deposit-bilyet/authorized/bulk",
            });
            await this.simpleWait(1000);
            if (resp.data.code === "SUCCESS") {
              this.$buefy.snackbar.open(
                this.$NotificationUtils.snackbarSuccess
              );
              this.property.listElement.inventoryBilyet.currentPage = 1;
              this.property.filterDto.inventoryBilyet.depositBilyetNumber = "";
              this.property.filterDto.inventoryBilyet.bilyetTypeId = "";
              this.property.listElement.inventoryBilyet.rows = 0;
              this.getInventoryBilyet();
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            await this.simpleWait(500);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            await this.simpleWait(500);
            this.property.animation.submitLoading = false;
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${getTotalUnAuthorized.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 500);
      }
    },
    async deleteBilyetInventory(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/" + props.row.depositBilyetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.inventoryBilyet.currentPage = 1;
          this.property.listElement.inventoryBilyet.rows = 0;
          this.getInventoryBilyet();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    // Deposit Bilyet
    async getDepositBilyet() {
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/simple-list",
          payload: {
            depositBilyetNumber: "",
            depositAccountNumber: "",
            statusId: 1,
            page: 0,
            size: this.property.listElement.depositBilyet.perPage,
          },
        });

        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositBilyet = resp.data.data.content;
            this.property.listElement.depositBilyet.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDepositBilyet(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositBilyet(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositBilyet.downloading = false;
        }, timeout);
      }
    },
    failedGetDepositBilyet(resp) {
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.rows = 0;
      this.property.listElement.depositBilyet.message = resp.data.message;
    },
    handleErrorGetDepositBilyet(error) {
      console.log(error.response);
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.rows = 0;
      this.property.listElement.depositBilyet.currentPage = 1;
      this.property.listElement.depositBilyet.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizeDepositBilyet(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-bilyet/authorized/" + props.row.depositBilyetId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositBilyet.currentPage = 1;
                  this.property.filterDto.depositBilyet.depositBilyetNumber =
                    "";
                  this.property.filterDto.depositBilyet.depositAccountNumber =
                    "";
                  this.property.listElement.depositBilyet.rows = 0;
                  this.getDepositBilyet();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationDepositBilyet(event) {
      this.table.data.depositBilyet = [];
      this.property.listElement.depositBilyet.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-bilyet/simple-list",
          payload: {
            depositBilyetNumber: this.property.filterDto.depositBilyet
              .depositBilyetNumber,
            depositAccountNumber: this.property.filterDto.depositBilyet
              .depositAccountNumber,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.depositBilyet.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositBilyet = resp.data.data.content;
            this.property.listElement.depositBilyet.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDepositBilyet(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositBilyet(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositBilyet.downloading = false;
        }, timeout);
      }
    },
    async deleteDepositBilyet(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "deposit-bilyet/" + props.row.depositBilyetId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositBilyet.currentPage = 1;
                  this.property.listElement.depositBilyet.rows = 0;
                  this.getDepositBilyet();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Buka Rekening Deposito
    async helperGetDataTableDepositAccount() {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: "",
            idCardNumber: "",
            cifIdName: "",
            cityName: "",
            orderByBalanceEnd: false,
            statusAccountId: 1,
            page: 0,
            size: this.property.listElement.depositAccount.perPage,
            dateTo: "",
            dateFrom: this.property.filterDto.depositAccount.dateFrom,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositAccount =
              resp.data.data.pageResponse.content;
            this.property.listElement.depositAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.handleFailedGetDataDepositAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataDepositAccount(resp) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.message = resp.data.message;
    },
    handleCatchErrorDepositAccount(error) {
      console.log(error.response);
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.rows = 0;
      this.property.listElement.depositAccount.currentPage = 1;
      this.property.listElement.depositAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleAuthorizationDepositAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account/authorized/" + props.row.depositAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositAccount.currentPage = 1;
                  this.property.filterDto.depositAccount.cifIdName = "";
                  this.property.filterDto.depositAccount.accountNumber = "";
                  this.property.filterDto.depositAccount.cityName = "";
                  this.property.filterDto.depositAccount.statusAccountId = "";
                  this.property.filterDto.depositAccount.orderByBalanceEnd = false;
                  this.property.listElement.depositAccount.rows = 0;
                  this.helperGetDataTableDepositAccount();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async handleChangePaginationDepositAccount(event) {
      this.table.data.depositAccount = [];
      this.property.listElement.depositAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account/simple-list",
          payload: {
            accountNumber: this.property.filterDto.depositAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.property.filterDto.depositAccount.cifIdName,
            cityName: this.property.filterDto.depositAccount.cityName,
            orderByBalanceEnd: this.property.filterDto.depositAccount
              .orderByBalanceEnd,
            statusAccountId: 1,
            page: event - 1,
            size: this.property.listElement.depositAccount.perPage,
            dateTo: this.property.filterDto.depositAccount.dateTo,
            dateFrom: this.property.filterDto.depositAccount.dateFrom,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositAccount =
              resp.data.data.pageResponse.content;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominatif;
            this.property.listElement.depositAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.handleFailedGetDataDepositAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositAccount.downloading = false;
        }, timeout);
      }
    },
    async handleDeleteDataDepositAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "deposit-account/" + props.row.depositAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositAccount.currentPage = 1;
                  this.property.filterDto.orderByBalanceEnd = false;
                  this.property.listElement.depositAccount.rows = 0;
                  this.helperGetDataTableDepositAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Kontrak RAHN
    async getMortgageAccount() {
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-account/simple-list",
          payload: {
            mortgageNumber: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageAccount = resp.data.data.page.content;
            this.property.listElement.mortgageAccount.rows =
              resp.data.data.page.totalElements;
          } else {
            this.failedGetMortgageAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetMortgageAccount(resp) {
      this.property.listElement.mortgageAccount.rows = 0;
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.message = resp.data.message;
    },
    handleErrorGetMortgageAccount(error) {
      console.log(error.response);
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.rows = 0;
      this.property.listElement.mortgageAccount.currentPage = 1;
      this.property.listElement.mortgageAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationMortgageAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "loan",
              reqUrl:
                "mortgage-account/authorized/" + props.row.mortgageAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.mortgageAccount.currentPage = 1;
                this.property.filterDto.mortgageAccount.mortgageNumber = "";
                this.property.filterDto.mortgageAccount.cifIdName = "";
                this.property.filterDto.mortgageAccount.dateFrom = "";
                this.property.filterDto.mortgageAccount.dateTo = "";
                this.property.filterDto.mortgageAccount.statusAccountId = "";
                this.property.listElement.mortgageAccount.rows = 0;
                this.getMortgageAccount();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
              // this.globalErrorFields(resp);
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    async changePaginationMortgageAccount(event) {
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-account/simple-list",
          payload: {
            mortgageNumber: this.property.filterDto.mortgageAccount
              .mortgageNumber,
            cifIdName: this.property.filterDto.mortgageAccount.cifIdName,
            dateFrom: this.property.filterDto.mortgageAccount.dateFrom,
            dateTo: this.property.filterDto.mortgageAccount.dateTo,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.mortgageAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageAccount = resp.data.data.page.content;
            this.property.listElement.mortgageAccount.rows =
              resp.data.data.page.totalElements;
          } else {
            this.failedGetMortgageAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageAccount.downloading = false;
        }, 500);
      }
    },
    async deleteMortgageAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Delete Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "mortgage-account/" + props.row.mortgageAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.mortgageAccount.currentPage = 1;
                this.property.listElement.mortgageAccount.rows = 0;
                this.getMortgageAccount();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },

    // Buka Rekening Pembiayaan
    async getLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            officeCode: this.property.filterDto.loanAccount.officeCode,
            loanAccountNumber: "",
            cifIdName: "",
            cityName: "",
            referalOfficeId: "",
            statusId: 1,
            orderByBalanceEnd: false,
            isSubContract: false,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetLoanAccount(resp) {
      this.property.listElement.loanAccount.rows = 0;
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.message = resp.data.message;
    },
    handleErrorGetLoanAccount(error) {
      console.log(error.response);
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        title: "Konfirmasi",
        message: `${
          props.row.isLineFacility === true
            ? "Otorisasi Nomor Kontrak & Setuju Limit sebesar"
            : "Otorisasi Nomor Kontrak & Setuju Droping sebesar"
        } ${
          props.row.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          props.row.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
            ? props.row.participateBank
              ? props.row.participateBank
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
            : props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
            ? props.row.nominalPurchase
              ? props.row.nominalPurchase
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
            : props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_QARDH ||
              props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_LINE_FACILITY
            ? props.row.nominalLoan
              ? props.row.nominalLoan
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
            : 0
        } ?`,
        cancelText: "Batal",
        confirmText: "Konfirmasi",
        closeOnConfirm: false,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async (value, { close }) => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "loan",
              reqUrl: "transaction-loan/authorized/" + props.row.loanAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              close();
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccount.currentPage = 1;
                this.property.filterDto.loanAccount.loanAccountNumber = "";
                this.property.filterDto.loanAccount.dataForm = "";
                this.property.filterDto.loanAccount.dateTo = "";
                this.property.filterDto.loanAccount.cifIdName = "";
                this.property.listElement.loanAccount.rows = 0;
                this.property.filterDto.loanAccount.officeCode = "";
                this.property.filterDto.loanAccount.cityName = "";
                this.property.filterDto.loanAccount.referalOfficeId = "";
                this.property.filterDto.loanAccount.orderByBalanceEnd = false;
                this.getLoanAccount();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    async changePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccount
              .loanAccountNumber,
            dateFrom: this.property.filterDto.loanAccount.dateFrom,
            officeCode: this.property.filterDto.loanAccount.officeCode,
            dateTo: this.property.filterDto.loanAccount.dateTo,
            cifIdName: this.property.filterDto.loanAccount.cifIdName,
            cityName: this.property.filterDto.loanAccount.cityName,
            referalOfficeId: this.property.filterDto.loanAccount
              .referalOfficeId,
            orderByBalanceEnd: this.property.filterDto.loanAccount
              .orderByBalanceEnd,
            statusId: 1,
            isSubContract: false,
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 500);
      }
    },
    async deleteLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "transaction-loan/" + props.row.loanAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccount.currentPage = 1;
                this.property.filterDto.orderByBalanceEnd = false;
                this.property.listElement.loanAccount.rows = 0;
                this.getLoanAccount();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },

    // Buka Rekening Tabungan
    async getSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/simple-list",
          payload: {
            accountNumber: "",
            idCardNumber: "",
            cifIdName: "",
            cityName: "",
            referalOfficeId: "",
            statusAccountId: 1,
            orderByBalanceEnd: false,
            page: 0,
            size: this.property.listElement.savingAccount.perPage,
            dateFrom: this.property.filterDto.savingAccount.dateFrom,
            dateTo: "",
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.pageResponse.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.pageResponse.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async authorizationSavingAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "saving-account/authorized/" + props.row.savingAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                await this.simpleWait(1000);
                this.property.listElement.savingAccount.currentPage = 1;
                this.property.filterDto.savingAccount.accountNumber = "";
                this.property.filterDto.savingAccount.cifIdName = "";
                this.property.filterDto.savingAccount.cityName = "";
                this.property.filterDto.savingAccount.statusAccountId = "";
                this.property.filterDto.savingAccount.orderByBalanceEnd = "";
                this.property.listElement.savingAccount.rows = 0;
                this.property.filterDto.savingAccount.referalOfficeId = "";
                this.getSavingAccount();
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/simple-list",
          payload: {
            accountNumber: this.property.filterDto.savingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.property.filterDto.savingAccount.cifIdName,
            cityName: this.property.filterDto.savingAccount.cityName,
            orderByBalanceEnd: this.property.filterDto.savingAccount
              .orderByBalanceEnd,
            referalOfficeId: this.property.filterDto.savingAccount
              .referalOfficeId,
            statusAccountId: 1,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
            dateTo: this.property.filterDto.savingAccount.dateTo,
            dateFrom: this.property.filterDto.savingAccount.dateFrom,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.pageResponse.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.pageResponse.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    async deleteDataSavingAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/" + props.row.savingAccountId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.savingAccount.currentPage = 1;
          this.property.listElement.savingAccount.rows = 0;
          this.getSavingAccount();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    // Entry CIF
    async helperGetDataTableCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: "",
            cifMotherName: "",
            officeCode: "",
            categoryId: "",
            cifIdName: "",
            statusId: 1,
            page: 0,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataCif(resp) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.message = resp.data.message;
    },
    handleCatchErrorCif(error) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleAuthorizationCif(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "cif/validation-dttot/authorized/" + props.row.cifId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.cif.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.categoryId = "";
                  this.property.filterDto.cifNumber = "";
                  this.property.listElement.cif.rows = 0;
                  this.helperGetDataTableCif();
                }, 1000);
              } else if (
                resp.data.code === "THIS_CIF_DATA_IS_A_SUGGESTED_TERRORIST"
              ) {
                if (window.navigator.onLine === false) {
                  this.$buefy.toast.open(
                    this.$NotificationUtils.connectionsError
                  );
                  return;
                } else {
                  this.$buefy.dialog.confirm({
                    message: `${resp.data.message}`,
                    type: "is-info",
                    onConfirm: async () => {
                      try {
                        const resp = await this.$store.dispatch({
                          type: "PUT_DATA_AUTHORIZED",
                          endPoint: "transaction",
                          reqUrl: "cif/authorized/" + props.row.cifId,
                        });
                        if (resp.data.code === "SUCCESS") {
                          this.$buefy.toast.open(
                            this.$NotificationUtils.success
                          );
                          setTimeout(() => {
                            this.property.listElement.cif.currentPage = 1;
                            this.property.filterDto.cifIdName = "";
                            this.property.filterDto.categoryId = "";
                            this.property.filterDto.cifNumber = "";
                            this.property.listElement.cif.rows = 0;
                            this.helperGetDataTableCif();
                          }, 1000);
                        } else if (
                          resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS"
                        ) {
                          this.$buefy.dialog.alert({
                            title: "Harap Tunggu !",
                            message: resp.data.errorFields
                              ? this.globalNotificationErrorFieldToText(
                                  resp.data.errorFields
                                )
                              : resp.data.message,
                            confirmText: "Ok",
                            type: "is-danger",
                          });
                        } else {
                          this.$buefy.dialog.alert({
                            title: "Gagal !",
                            message: `${resp.data.message}`,
                            confirmText: "Ok",
                            type: "is-danger",
                          });
                        }
                      } catch (error) {
                        setTimeout(() => {
                          this.$buefy.dialog.alert({
                            title: "Gagal !",
                            message: error.response
                              ? `${error.response.statusText}`
                              : "Terjadi Kesalahan",
                            confirmText: "Ok",
                            type: "is-danger",
                          });
                        }, 1000);
                      }
                    },
                  });
                }
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1000);
            }
          },
        });
      }
    },
    async handleChangePaginationCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.property.filterDto.cif.cifNumber,
            cifMotherName: "",
            officeCode: this.property.filterDto.cif.officeCode,
            categoryId: this.property.filterDto.cif.categoryId,
            cifIdName: this.property.filterDto.cif.cifIdName,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout);
      }
    },
    async handleDeleteDataCif(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cif/" + props.row.cifId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.cif.currentPage = 1;
                  this.property.listElement.cif.rows = 0;
                  this.helperGetDataTableCif();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1000);
            }
          },
        });
      }
    },

    // Restruk 1 Pembiayaan
    async getLoanAccountReschedule() {
      this.table.data.loanAccountReschedule = [];
      this.property.listElement.loanAccountReschedule.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/reschedule/simple-list",
          payload: {
            loanAccountNumber: "",
            officeCode: "",
            cifIdName: "",
            rloanStatusId: 3,
            isRestruct2: false,
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccountReschedule =
              resp.data.data.pageResponse.content;
            this.property.listElement.loanAccountReschedule.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetDataLoanAccountReschedule(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanAccountReschedule(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccountReschedule.downloading = false;
        }, timeout);
      }
    },
    handleErrorLoanAccountReschedule(error) {
      console.log(error.response);
      this.table.data.loanAccountReschedule = [];
      this.property.listElement.loanAccountReschedule.rows = 0;
      this.property.listElement.loanAccountReschedule.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataLoanAccountReschedule(resp) {
      this.table.data.loanAccountReschedule = [];
      this.property.listElement.loanAccountReschedule.rows = 0;
      this.property.listElement.loanAccountReschedule.message =
        resp.data.message;
    },
    async changePaginationLoanAccountReschedule(event) {
      this.table.data.loanAccountReschedule = [];
      this.property.listElement.loanAccountReschedule.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/reschedule/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountReschedule
              .loanAccountNumber,
            cifIdName: this.property.filterDto.loanAccountReschedule.cifIdName,
            officeCode: this.property.filterDto.loanAccountReschedule
              .officeCode,
            dateFrom: this.property.filterDto.loanAccountReschedule.dateFrom,
            dateTo: this.property.filterDto.loanAccountReschedule.dateTo,
            isRestruct2: false,
            statusId: 1,
            rloanStatusId: 3,
            page: event - 1,
            size: this.property.listElement.loanAccountReschedule.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccountReschedule =
              resp.data.data.pageResponse.content;
            this.property.listElement.loanAccountReschedule.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetDataLoanAccountReschedule(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanAccountReschedule(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccountReschedule.downloading = false;
        }, timeout);
      }
    },
    async authorizedLoanAccountReschedule(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "transaction-loan/reschedule/authorized/" +
                  props.row.loanAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.loanAccountReschedule.cifIdName = "";
                  this.property.filterDto.loanAccountReschedule.loanAccountNumber =
                    "";
                  this.property.filterDto.loanAccountReschedule.dateFrom = "";
                  this.property.filterDto.loanAccountReschedule.officeCode = "";
                  this.property.listElement.loanAccountReschedule.currentPage = 1;
                  this.property.listElement.loanAccountReschedule.rows = 0;
                  this.getLoanAccountReschedule();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteLoanAccountReschedule(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "transaction-loan/" + props.row.loanAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccountReschedule.currentPage = 1;
                this.property.listElement.loanAccountReschedule.rows = 0;
                this.getLoanAccountReschedule();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },

    // Restruk 2 Pembiayaan
    async getLoanAccountRestructure() {
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/reschedule/simple-list",
          payload: {
            loanAccountNumber: "",
            officeCode: "",
            cifIdName: "",
            isRestruct2: true,
            rloanStatusId: 4,
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccountRestructure =
              resp.data.data.pageResponse.content;
            this.property.listElement.loanAccountRestructure.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.handleFailedGetDataLoanAccountRestructure(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanAccountRestructure(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccountRestructure.downloading = false;
        }, timeout);
      }
    },
    handleCatchErrorLoanAccountRestructure(error) {
      console.log(error.response);
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.rows = 0;
      this.property.listElement.loanAccountRestructure.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleFailedGetDataLoanAccountRestructure(resp) {
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.rows = 0;
      this.property.listElement.loanAccountRestructure.message =
        resp.data.message;
    },
    async handleChangePaginationLoanAccountRestructure(event) {
      this.table.data.loanAccountRestructure = [];
      this.property.listElement.loanAccountRestructure.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/reschedule/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountRestructure
              .loanAccountNumber,
            cifIdName: this.property.filterDto.loanAccountRestructure.cifIdName,
            dateFrom: this.property.filterDto.loanAccountRestructure.dateFrom,
            dateTo: this.property.filterDto.loanAccountRestructure.dateTo,
            officeCode: this.property.filterDto.loanAccountRestructure
              .officeCode,
            statusId: 1,
            rloanStatusId: 4,
            isRestruct2: true,
            page: event - 1,
            size: this.property.listElement.loanAccountRestructure.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccountRestructure =
              resp.data.data.pageResponse.content;
            this.property.listElement.loanAccountRestructure.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.handleFailedGetDataLoanAccountRestructure(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanAccountRestructure(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccountRestructure.downloading = false;
        }, timeout);
      }
    },
    async authorizedLoanAccountRestructure(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "transaction-loan/reschedule/authorized/" +
                  props.row.loanAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.loanAccountRestructure.loanAccountNumber =
                    "";
                  this.property.filterDto.loanAccountRestructure.dateFrom = "";
                  this.property.filterDto.loanAccountRestructure.dateTo = "";
                  this.property.filterDto.loanAccountRestructure.cifIdName = "";
                  this.property.filterDto.loanAccountRestructure.officeCode =
                    "";
                  this.property.listElement.loanAccountReschedule.currentPage = 1;
                  this.getLoanAccountRestructure();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteLoanAccountRestructure(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "transaction-loan/" + props.row.loanAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccountReschedule.currentPage = 1;
                this.getLoanAccountRestructure();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },

    // Pelunasan Pembiayaan
    async getLoanPaid() {
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-paid-off/simple-list",
          payload: {
            loanAccountNumber: "",
            cifIdName: "",
            transactionCodeId: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaid = resp.data.data.content;
            this.property.listElement.loanPaid.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaid(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanPaid(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaid.downloading = false;
        }, timeout);
      }
    },
    failedGetDataLoanPaid(resp) {
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.rows = 0;
      this.property.listElement.loanPaid.message = resp.data.message;
    },
    handleErrorGetLoanPaid(error) {
      console.log(error.response);
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.rows = 0;
      this.property.listElement.loanPaid.currentPage = 1;
      this.property.listElement.loanPaid.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanPaid(event) {
      this.table.data.loanPaid = [];
      this.property.listElement.loanPaid.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-paid-off/simple-list",
          payload: {
            transactionCodeId: "",
            loanAccountNumber: this.property.filterDto.loanPaid
              .loanAccountNumber,
            cifIdName: this.property.filterDto.loanPaid.cifIdName,
            dateFrom: this.property.filterDto.loanPaid.dateFrom,
            dateTo: this.property.filterDto.loanPaid.dateTo,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.loanPaid.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaid = resp.data.data.content;
            this.property.listElement.loanPaid.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaid(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanPaid(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaid.downloading = false;
        }, timeout);
      }
    },
    async authorizedLoanPaid(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl: "loan-paid-off/authorized/" + props.row.loanPaidOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.loanPaid.cifIdName = "";
                  this.property.filterDto.loanPaid.dateTo = "";
                  this.property.filterDto.loanPaid.dateFrom = "";
                  this.property.filterDto.loanPaid.loanAccountNumber = "";
                  this.property.listElement.loanPaid.rows = 0;
                  this.getLoanPaid();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteDataLoanPaid(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-paid-off/" + props.row.loanPaidOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.loanPaid.currentPage = 1;
                  this.property.listElement.loanPaid.rows = 0;
                  this.getLoanPaid();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Koreksi Angsuran
    async getLoanPaymentReturn() {
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-payment-return",
          payload: {
            accountNumber: "",
            name: "",
            statusId: 1,
            page: 0,
            size: this.property.listElement.loanPaymentReturn.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaymentReturn = resp.data.data.content;
            this.property.listElement.loanPaymentReturn.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaymentReturn(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanPaymentReturn(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaymentReturn.downloading = false;
        }, 500);
      }
    },
    handleErrorLoanPaymentReturn(error) {
      console.log(error.response);
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.rows = 0;
      this.property.listElement.loanPaymentReturn.currentPage = 1;
      this.property.listElement.loanPaymentReturn.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataLoanPaymentReturn(resp) {
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.rows = 0;
      this.property.listElement.loanPaymentReturn.message = resp.data.message;
    },
    async authorizedLoanPaymentReturn(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "loan-payment-return/authorized/" + props.row.loanPayReturnId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.loanPaymentReturn.name = "";
                  this.property.filterDto.loanPaymentReturn.accountNumber = "";
                  this.property.listElement.loanPaymentReturn.rows = 0;
                  this.getLoanPaymentReturn();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationLoanPaymentReturn(event) {
      this.table.data.loanPaymentReturn = [];
      this.property.listElement.loanPaymentReturn.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan/loan-payment-return",
          payload: {
            accountNumber: this.property.filterDto.loanPaymentReturn
              .accountNumber,
            name: this.property.filterDto.loanPaymentReturn.name,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.loanPaymentReturn.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanPaymentReturn = resp.data.data.content;
            this.property.listElement.loanPaymentReturn.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanPaymentReturn(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanPaymentReturn(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanPaymentReturn.downloading = false;
        }, timeout);
      }
    },
    async deleteLoanPaymentReturn(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-payment-return/" + props.row.loanPayReturnId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.loanPaymentReturn.currentPage = 1;
                  this.property.listElement.loanPaymentReturn.rows = 0;
                  this.getLoanPaymentReturn();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Hapus Buku
    async getLoanWriteOff() {
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-write-off",
          payload: {
            loanAccountNumber: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.writeOff = resp.data.data.content;
            this.property.listElement.writeOff.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanWriteOff(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanWriteOff(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.writeOff.downloading = false;
        }, timeout);
      }
    },
    failedGetDataLoanWriteOff(resp) {
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.rows = 0;
      this.property.listElement.writeOff.message = resp.data.message;
    },
    handleErrorGetLoanWriteOff(error) {
      console.log(error.response);
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.rows = 0;
      this.property.listElement.writeOff.currentPage = 1;
      this.property.listElement.writeOff.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanWriteOff(event) {
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-write-off",
          payload: {
            loanAccountNumber: this.property.filterDto.writeOff
              .loanAccountNumber,
            cifIdName: this.property.filterDto.writeOff.cifIdName,
            dateFrom: this.property.filterDto.writeOff.dateFrom,
            dateTo: this.property.filterDto.writeOff.dateTo,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.writeOff.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.writeOff = resp.data.data.content;
            this.property.listElement.writeOff.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanWriteOff(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanWriteOff(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.writeOff.downloading = false;
        }, timeout);
      }
    },
    async authorizedLoanWriteOff(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl: "loan-write-off/authorized/" + props.row.loanWriteOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.writeOff.cifIdName = "";
                  this.property.filterDto.writeOff.dateTo = "";
                  this.property.filterDto.writeOff.dateFrom = "";
                  this.property.filterDto.writeOff.loanAccountNumber = "";
                  this.property.listElement.writeOff.rows = 0;
                  this.getLoanWriteOff();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteDataLoanWriteOff(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-write-off/" + props.row.loanWriteOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.writeOff.currentPage = 1;
                  this.property.listElement.writeOff.rows = 0;
                  this.getLoanWriteOff();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Kolektor Funding
    async getCollectorFunding() {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding-detail/simple-list",
          payload: {
            collectorFundingUserId: "",
            chartOfAccountCodePair: "",
            accountNumberPair: "",
            officeId: "",
            statusId: 1,
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    failedGetCollectorFunding(resp) {
      this.property.listElement.collectorFunding.rows = 0;
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.message = resp.data.message;
    },
    handleErrorGetCollectorFunding(error) {
      console.log(error.response);
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.rows = 0;
      this.property.listElement.collectorFunding.currentPage = 1;
      this.property.listElement.collectorFunding.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationCollectorFunding(event) {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding-detail/simple-list",
          payload: {
            collectorFundingUserId: this.property.filterDto.collectorFunding
              .collectorFundingUserId,
            chartOfAccountCodePair: this.property.filterDto.collectorFunding
              .chartOfAccountCodePair,
            accountNumberPair: this.property.filterDto.collectorFunding
              .accountNumberPair,
            officeId: this.property.filterDto.collectorFunding.officeId,
            statusId: 1,
            dateFrom: this.property.filterDto.collectorFunding.dateFrom,
            dateTo: this.property.filterDto.collectorFunding.dateTo,
            page: event - 1,
            size: this.property.listElement.collectorFunding.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
            this.property.listElement.collectorFunding.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    async authorizationCollectorFunding(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "collector-funding-detail/authorized/" +
                  props.row.collectorFundingDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.collectorFunding.currentPage = 1;
                  this.property.filterDto.collectorFunding.userName = "";
                  this.property.filterDto.collectorFunding.chartOfAccountCode =
                    "";
                  this.property.filterDto.collectorFunding.officeId = "";
                  this.property.listElement.collectorFunding.rows = 0;
                  this.getCollectorFunding();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteCollectorFunding(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "collector-funding-detail/" +
                  props.row.collectorFundingDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                this.property.listElement.collectorFunding.currentPage = 1;
                this.property.filterDto.collectorFunding.userName = "";
                this.property.filterDto.collectorFunding.chartOfAccountCode =
                  "";
                this.property.filterDto.collectorFunding.officeId = "";
                this.property.listElement.collectorFunding.rows = 0;
                this.getCollectorFunding();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Transaksi ABP
    async helperGetDataTableTInterbankAccount() {
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-liability",
          payload: {
            transactionCodeId: "",
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankLiability = resp.data.data.content;
          this.property.listElement.taskInterbankLiability.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankLiability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankLiability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankLiability.downloading = false;
      }
    },
    handleCatchErrorTaskInterbankLiability(error) {
      console.log(error.response);
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.rows = 0;
      this.property.listElement.taskInterbankLiability.currentPage = 1;
      this.property.listElement.taskInterbankLiability.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataTaskInterbankLiability(resp) {
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.rows = 0;
      this.property.listElement.taskInterbankLiability.message =
        resp.data.message;
    },
    async changePaginationTaskInterbankLiability(event) {
      this.table.data.taskInterbankLiability = [];
      this.property.listElement.taskInterbankLiability.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-liability",
          payload: {
            transactionCodeId: this.property.filterDto.taskInterbankLiability
              .transactionCodeId,
            dateFrom: this.property.filterDto.taskInterbankLiability.dateFrom,
            dateTo: this.property.filterDto.taskInterbankLiability.dateTo,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.taskInterbankLiability.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankLiability = resp.data.data.content;
          this.property.listElement.taskInterbankLiability.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankLiability(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankLiability(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankLiability.downloading = false;
      }
    },
    async authorizationTaskInterbankLiability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "task-interbank-liability/authorized/" +
            props.row.taskInterbankLiabilityId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankLiability.currentPage = 1;
          this.property.filterDto.taskInterbankLiability.transactionCodeId = "";
          this.property.filterDto.taskInterbankLiability.dateTo = "";
          this.property.filterDto.taskInterbankLiability.dateFrom = "";
          this.property.filterDto.taskInterbankLiability.statusId = "";
          this.property.listElement.taskInterbankLiability.rows = 0;
          this.helperGetDataTableTInterbankAccount();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async deleteTaskInterbankLiability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl:
            "task-interbank-liability/" + props.row.taskInterbankLiabilityId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankLiability.currentPage = 1;
          this.property.listElement.taskInterbankLiability.rows = 0;
          this.helperGetDataTableTInterbankAccount();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    // Transaksi ABA
    async helperGetDataTableTaskInterbankAsset() {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset",
          payload: {
            transactionCodeId: "",
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },
    failedGetDataTaskInterbankAsset(resp) {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.rows = 0;
      this.property.listElement.taskInterbankAsset.message = resp.data.message;
    },
    handleCatchErrorTaskInterbankAsset(error) {
      console.log(error.response);
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.rows = 0;
      this.property.listElement.taskInterbankAsset.currentPage = 1;
      this.property.listElement.taskInterbankAsset.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationTaskInterbankAsset(event) {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset",
          payload: {
            transactionCodeId: this.property.filterDto.taskInterbankAsset
              .transactionCodeId,
            dateFrom: this.property.filterDto.taskInterbankAsset.dateFrom,
            dateTo: this.property.filterDto.taskInterbankAsset.dateTo,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.taskInterbankAsset.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },
    async authorizationTaskInterbankAsset(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "task-interbank-asset/authorized/" + props.row.taskInterbankAssetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankAsset.currentPage = 1;
          this.property.filterDto.taskInterbankAsset.name = "";
          this.property.filterDto.taskInterbankAsset.transactionCodeId = "";
          this.property.filterDto.taskInterbankAsset.dateFrom = "";
          this.property.filterDto.taskInterbankAsset.dateTo = "";
          this.property.filterDto.taskInterbankAsset.statusAccountId = "";
          this.property.listElement.taskInterbankAsset.rows = 0;
          this.helperGetDataTableTaskInterbankAsset();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async deleteTaskInterbankAsset(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset/" + props.row.taskInterbankAssetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankAsset.currentPage = 1;
          this.property.listElement.taskInterbankAsset.rows = 0;
          this.helperGetDataTableTaskInterbankAsset();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    // Master ABP
    async getInterbankLiability() {
      this.table.data.InterbankLiability = [];
      this.property.listElement.InterbankLiability.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankliability",
          payload: {
            interbankLiabilityTypeId: "",
            accountNumber: "",
            accountName: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.InterbankLiability = resp.data.data.content;
            this.property.listElement.InterbankLiability.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataInterbankLiability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorInterbankLiability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.InterbankLiability.downloading = false;
        }, timeout);
      }
    },
    handleErrorInterbankLiability(error) {
      console.log(error.response);
      this.table.data.InterbankLiability = [];
      this.property.listElement.InterbankLiability.rows = 0;
      this.property.listElement.InterbankLiability.currentPage = 1;
      this.property.listElement.InterbankLiability.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataInterbankLiability(resp) {
      this.table.data.InterbankLiability = [];
      this.property.listElement.InterbankLiability.rows = 0;
      this.property.listElement.InterbankLiability.message = resp.data.message;
    },
    async changePaginationInterbankLiability(event) {
      const timeout = 500;
      this.table.data.InterbankLiability = [];
      this.property.listElement.InterbankLiability.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankliability",
          payload: {
            interbankLiabilityTypeId: this.property.filterDto.interbankLiability
              .interbankLiabilityType,
            page: event - 1,
            statusId: 1,
            accountNumber: this.property.filterDto.interbankLiability
              .accountNumber,
            accountName: this.property.filterDto.interbankLiability.accountName,
            size: this.property.listElement.InterbankLiability.perPag,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.InterbankLiability = resp.data.data.content;
            this.property.listElement.InterbankLiability.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataInterbankLiability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorInterbankLiability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.InterbankLiability.downloading = false;
        }, timeout);
      }
    },
    async authorizationInterbankLiability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "interbankliability/authorized/" +
                  props.row.interbankLiabilityId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.InterbankLiability.currentPage = 1;
                  this.property.filterDto.interbankLiability.interbankLiabilityType =
                    "";
                  this.property.filterDto.interbankLiability.accountNumber = "";
                  this.property.filterDto.interbankLiability.accountName = "";
                  this.property.listElement.InterbankLiability.rows = 0;
                  this.getInterbankLiability();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteInterbankLiability(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "interbankliability/" + props.row.interbankLiabilityId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.InterbankLiability.currentPage = 1;

                  this.property.listElement.InterbankLiability.rows = 0;
                  this.getInterbankLiability();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Master ABA
    async getInterbankAssets() {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankAssets",
          payload: {
            accountNumber: "",
            accountName: "",
            interbankAssetsTypeId: "",
            statusId: 1,
            page: 0,
            size: this.property.listElement.interbankAssets.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.interbankAssets = resp.data.data.content;
            this.property.listElement.interbankAssets.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetInterbankAssets(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorInterbankAssets(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.interbankAssets.downloading = false;
        }, timeout);
      }
    },
    failedGetInterbankAssets(resp) {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.rows = 0;
      this.property.listElement.interbankAssets.message = resp.data.message;
    },
    handleErrorInterbankAssets(error) {
      console.log(error.response);
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.rows = 0;
      this.property.listElement.interbankAssets.currentPage = 1;
      this.property.listElement.interbankAssets.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationInterbankAssets(event) {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankAssets",
          payload: {
            accountNumber: this.property.filterDto.interbankAssets
              .accountNumber,
            accountName: this.property.filterDto.interbankAssets.accountName,
            interbankAssetsTypeId: this.property.filterDto.interbankAssets
              .interbankAssetsId,
            page: event - 1,
            statusId: 1,
            size: this.property.listElement.interbankAssets.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.interbankAssets = resp.data.data.content;
            this.property.listElement.interbankAssets.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetInterbankAssets(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorInterbankAssets(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.interbankAssets.downloading = false;
        }, timeout);
      }
    },
    async authorizationInterbankAssets(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "interbankAssets/authorized/" + props.row.interbankAssetsId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.interbankAssets.currentPage = 1;
                  this.property.filterDto.interbankAssets.interbankAssetsTypeId =
                    "";
                  this.property.filterDto.interbankAssets.accountName = "";
                  this.property.filterDto.interbankAssets.accountNumber = "";
                  this.property.listElement.interbankAssets.rows = 0;
                  this.getInterbankAssets();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteInterbankAssets(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "interbankAssets/" + props.row.interbankAssetsId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.interbankAssets.currentPage = 1;
                  this.property.listElement.interbankAssets.rows = 0;
                  this.getInterbankAssets();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Register SBR
    async getSBRList() {
      this.table.data.sbr = [];
      this.property.listElement.sbr.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-register-number/inventory/simple-list",
          payload: {
            page: 0,
            size: this.property.listElement.sbr.perPage,
            mortgageRegisterNumber: "",
            usedStatusId: "",
            statusId: 1,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sbr = resp.data.data.content;
            this.property.listElement.sbr.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataSBR(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListSBR(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sbr.downloading = false;
        }, 1000);
      }
    },
    failedGetDataSBR(resp) {
      this.table.data.sbr = [];
      this.property.listElement.sbr.rows = 0;
      this.property.listElement.sbr.message = resp.data.message;
    },
    handleErrorListSBR(error) {
      console.log(error.response);
      this.table.data.sbr = [];
      this.property.listElement.sbr.rows = 0;
      this.property.listElement.sbr.currentPage = 1;
      this.property.listElement.sbr.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationSbr(event) {
      this.table.data.sbr = [];
      this.property.listElement.sbr.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-register-number/inventory/simple-list",
          payload: {
            page: event - 1,
            size: this.property.listElement.sbr.perPage,
            mortgageRegisterNumber: this.property.filterDto.sbr.numberSBR,
            usedStatusId: "",
            statusId: 1,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.sbr = resp.data.data.content;
            this.property.listElement.sbr.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataSBR(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListSBR(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.sbr.downloading = false;
        }, 1000);
      }
    },
    async authorizationMortgageRegisterNumber(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "loan",
              reqUrl:
                "mortgage-register-number/authorized/" +
                props.row.mortgageRegisterId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.sbr.currentPage = 1;
                this.property.filterDto.sbr.numberSBR = "";
                this.property.listElement.sbr.rows = 0;
                this.getSBRList();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    async deleteSbr(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "mortgage-register-number/" + props.row.mortgageRegisterId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.sbr.currentPage = 1;
                  this.property.listElement.sbr.rows = 0;
                  this.getSBRList();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Pelunasan RAHN
    async getMortgagePaidOff() {
      this.table.data.mortgagePaidOff = [];
      this.property.listElement.mortgagePaidOff.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-paid-off",
          payload: {
            mortgageAccountNumber: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgagePaidOff = resp.data.data.content;
            this.property.listElement.mortgagePaidOff.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataMortgagePaidOff(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgagePaidOff(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgagePaidOff.downloading = false;
        }, timeout);
      }
    },
    handleErrorGetMortgagePaidOff(error) {
      console.log(error.response);
      this.table.data.mortgagePaidOff = [];
      this.property.listElement.mortgagePaidOff.rows = 0;
      this.property.listElement.mortgagePaidOff.currentPage = 1;
      this.property.listElement.mortgagePaidOff.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataMortgagePaidOff(resp) {
      this.table.data.mortgagePaidOff = [];
      this.property.listElement.mortgagePaidOff.rows = 0;
      this.property.listElement.mortgagePaidOff.message = resp.data.message;
    },
    async changePaginationMortgagePaidOff(event) {
      this.table.data.mortgagePaidOff = [];
      this.property.listElement.mortgagePaidOff.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-paid-off",
          payload: {
            mortgageAccountNumber: this.property.filterDto.mortgagePaidOff
              .mortgageAccountNumber,
            statusId: 1,
            page: event - 1,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgagePaidOff = resp.data.data.content;
            this.property.listElement.mortgagePaidOff.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataMortgagePaidOff(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgagePaidOff(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgagePaidOff.downloading = false;
        }, timeout);
      }
    },
    async authorizationGetMortgagePaidOff(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "mortgage-paid-off/authorized/" + props.row.mortgagePaidOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.mortgagePaidOff.currentPage = 1;
                  this.property.filterDto.mortgagePaidOff.mortgageNoAccount =
                    "";
                  this.property.listElement.mortgagePaidOff.rows = 0;
                  this.getMortgagePaidOff();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Pencairan Deposito
    async helperGetDataTableDepositDisburse() {
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/disburse",
          payload: {
            page: 0,
            size: 10,
            depositAccountNumber: "",
            referenceNumber: "",
            statusId: 1,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositDisburse = resp.data.data.content;
            this.property.listElement.depositDisburse.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDepositDisburse(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositDisburse(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositDisburse.downloading = false;
        }, timeout);
      }
    },
    failedGetDataDepositDisburse(resp) {
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.rows = 0;
      this.property.listElement.depositDisburse.message = resp.data.message;
    },
    handleCatchErrorDepositDisburse(error) {
      console.log(error.response);
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.rows = 0;
      this.property.listElement.depositDisburse.currentPage = 1;
      this.property.listElement.depositDisburse.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationDepositDisburse(event) {
      this.table.data.depositDisburse = [];
      this.property.listElement.depositDisburse.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-account-detail/disburse",
          payload: {
            depositAccountNumber: this.property.filterDto.depositDisburse
              .depositAccountNumber,
            referenceNumber: this.property.filterDto.depositDisburse
              .referenceNumber,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.depositDisburse.perPage,
            sortBy: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositDisburse = resp.data.data.content;
            this.property.listElement.depositDisburse.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataDepositDisburse(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorDepositDisburse(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositDisburse.downloading = false;
        }, timeout);
      }
    },
    async authorizationDepositDisburse(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account-detail/disburse/authorized/" +
                  props.row.depositAccountDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositDisburse.currentPage = 1;
                  this.property.filterDto.depositDisburse.referenceNumber = "";
                  this.property.filterDto.depositDisburse.depositAccountNumber =
                    "";
                  this.property.listElement.depositDisburse.rows = 0;
                  this.helperGetDataTableDepositDisburse();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteDepositDisburse(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "deposit-account-detail/" + props.row.depositAccountDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.depositDisburse.currentPage = 1;
                  this.property.listElement.depositDisburse.rows = 0;
                  this.helperGetDataTableDepositDisburse();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Generate Pin ATM
    async getDataTableGeneratePinAtm() {
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-card",
          payload: {
            savingCardId: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.generatePinAtm = resp.data.data.content;
            this.property.listElement.generatePinAtm.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataGeneratePinAtm(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetGeneratePinAtm(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.generatePinAtm.downloading = false;
        }, timeout);
      }
    },
    failedGetDataGeneratePinAtm(resp) {
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.rows = 0;
      this.property.listElement.generatePinAtm.message = resp.data.message;
    },
    handleErrorGetGeneratePinAtm(error) {
      console.log(error.response);
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.rows = 0;
      this.property.listElement.generatePinAtm.currentPage = 1;
      this.property.listElement.generatePinAtm.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationGeneratePinAtm(event) {
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-card",
          payload: {
            savingCardId: this.property.filterDto.generatePinAtm.savingCardId,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.generatePinAtm.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.generatePinAtm = resp.data.data.content;
            this.property.listElement.generatePinAtm.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataGeneratePinAtm(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetGeneratePinAtm(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.generatePinAtm.downloading = false;
        }, timeout);
      }
    },
    async authorizedGeneratePinAtm(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "saving-card/authorized/" + props.row.savingCardId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.generatePinAtm.savingCardId = "";
                  this.property.listElement.generatePinAtm.rows = 0;
                  this.getDataTableGeneratePinAtm();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteDataGeneratePinAtm(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "saving-card/" + props.row.savingCardId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.generatePinAtm.currentPage = 1;
                  this.property.listElement.generatePinAtm.rows = 0;
                  this.getDataTableGeneratePinAtm();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Tutup Rekening Tabungan
    async getSavingAccountClose() {
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: {
            accountNumber: "",
            dateFrom: "",
            dateTo: "",
            statusId: 1,
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccountClose = resp.data.data.content;
          this.property.listElement.savingAccountClose.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountClose(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorSavingAccountClose(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountClose.downloading = false;
      }
    },
    failedGetDataSavingAccountClose(resp) {
      this.property.listElement.savingAccountClose.message = resp.data.message;
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.rows = 0;
    },
    handleErrorSavingAccountClose(error) {
      console.log(error.response);
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.rows = 0;
      this.property.listElement.savingAccountClose.currentPage = 1;
      this.property.listElement.savingAccountClose.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationSavingAccountClose(event) {
      this.table.data.savingAccountClose = [];
      this.property.listElement.savingAccountClose.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account-detail/close-saving-account",
          payload: {
            accountNumber: this.property.filterDto.savingAccountClose
              .accountNumber,
            dateFrom: this.property.filterDto.savingAccountClose.dateFrom,
            dateTo: this.property.filterDto.savingAccountClose.dateTo,
            statusId: 1,
            page: event - 1,
            size: this.property.listElement.savingAccountClose.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccountClose = resp.data.data.content;
          this.property.listElement.savingAccountClose.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataSavingAccountClose(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorSavingAccountClose(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccountClose.downloading = false;
      }
    },
    async authorizationSavingAccountClose(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "saving-account-detail/close-saving-account/authorized/" +
            props.row.savingAccountDetailId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.savingAccountClose.currentPage = 1;
          this.property.filterDto.savingAccountClose.accountNumber = "";
          this.property.filterDto.savingAccountClose.dateFrom = "";
          this.property.filterDto.savingAccountClose.dateTo = "";
          this.property.listElement.savingAccountClose.rows = 0;
          this.getSavingAccountClose();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    // Blokir dan Buka Blokir Rekening Tabungan
    async getSavingBlock() {
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-block",
          payload: {
            accountNumber: this.property.filterDto.savingBlock.accountNumber,
            blockedTypeId: "",
            page: 0,
            size: 10,
            statusId: 1,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingBlock = resp.data.data.content;
            this.property.listElement.savingBlock.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSavingBlock(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSavingBlock(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.savingBlock.downloading = false;
        }, timeout);
      }
    },
    failedGetDataSavingBlock(resp) {
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.rows = 0;
      this.property.listElement.savingBlock.message = resp.data.message;
    },
    handleErrorSavingBlock(error) {
      console.log(error.response);
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.rows = 0;
      this.property.listElement.savingBlock.currentPage = 1;
      this.property.listElement.savingBlock.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationProcessSavingBlock(event) {
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-block",
          payload: {
            accountNumber: this.property.filterDto.savingBlock.accountNumber,
            blockedTypeId: "",
            page: event - 1,
            size: this.property.listElement.savingBlock.perPage,
            statusId: 1,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingBlock = resp.data.data.content;
            this.property.listElement.savingBlock.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSavingBlock(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSavingBlock(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.savingBlock.downloading = false;
        }, timeout);
      }
    },
    async authorizationSavingBlocked(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "saving-block/authorized/" + props.row.savingBlockedId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getSavingAccountBlock();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteSavingBlock(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "saving-block/" + props.row.savingBlockedId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getSavingBlock();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    // Otorisasi Reversal
    async getListTableJournalReversal() {
      this.table.data.journalLedgerReversal = [];
      this.property.listElement.journalLedgerReversal.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "journal-ledger/v2/manual/detail-new",
          payload: {
            referenceNumber: "",
            journalCode: "",
            statusId: 1,
            isReversal: this.property.filterDto.journalLedgerReversal
              .isReversal,
            branchCode: this.property.filterDto.journalLedgerReversal
              .branchCode,
            page: 0,
            size: this.property.listElement.journalLedgerReversal.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.insertJournalLedgerTable(resp);
            this.property.listElement.journalLedgerReversal.rows =
              resp.data.data.totalElements;
            this.defaultOpenDetailJournalLedger(resp);
          } else {
            this.failedGetDatajournalLedgerReversal(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorJournalEntry(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalLedgerReversal.downloading = false;
        }, timeout);
      }
    },
    insertJournalLedgerTable(resp) {
      console.log(resp);
      resp.data.data.content.map((index) => {
        const journalLedgerId = index.header.journalLedgerId;
        this.table.data.journalLedgerReversal.push({
          journalLedgerId,
          header: index.header,
          detail: index.detail,
        });
      });
    },
    defaultOpenDetailJournalLedger(resp) {
      this.property.listElement.journalLedgerReversal.detailOpened = [];
      this.property.listElement.journalLedgerReversal.subDetailOpened = [];
      // this.property.listElement.journalLedgerReversal.rows = 0;
      // this.property.listElement.journalLedgerReversal.message =
      //   resp.data.message;
    },
    failedGetDatajournalLedgerReversal(resp) {
      this.table.data.journalLedgerReversal = [];
      this.property.listElement.journalLedgerReversal.rows = 0;
      this.property.listElement.journalLedgerReversal.message =
        resp.data.message;
    },
    handleErrorJournalEntry(error) {
      console.log(error.response);
      this.table.data.journalLedgerReversal = [];
      this.property.listElement.journalLedgerReversal.rows = 0;
      this.property.listElement.journalLedgerReversal.currentPage = 1;
      this.property.listElement.journalLedgerReversal.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationjournalLedgerReversal(event) {
      this.table.data.journalLedgerReversal = [];
      this.property.listElement.journalLedgerReversal.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "journal-ledger/v2/manual/detail-new",
          payload: {
            statusId: 1,
            referenceNumber: this.property.filterDto.journalLedgerReversal
              .referenceNumber,
            journalCode: this.property.filterDto.journalLedgerReversal
              .journalCode,
            branchCode: this.property.filterDto.journalLedgerReversal
              .branchCode,
            isReversal: this.property.filterDto.journalLedgerReversal
              .isReversal,
            dateFrom: this.property.filterDto.journalLedgerReversal.dateFrom,
            userId: this.property.filterDto.journalLedgerReversal.userId,
            dateTo: this.property.filterDto.journalLedgerReversal.dateTo,
            page: event - 1,
            size: this.property.listElement.journalLedgerReversal.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.insertJournalLedgerTable(resp);
            this.property.listElement.journalLedgerReversal.rows =
              resp.data.data.totalElements;
            this.defaultOpenDetailJournalLedger(resp);
          } else {
            this.failedGetDatajournalLedgerReversal(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorJournalEntry(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalLedgerReversal.downloading = false;
        }, timeout);
      }
    },
    authorizationjournalLedgerReversal(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "reversal/authorized/" + props.row.header.referenceNumber,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.journalLedgerReversal.currentPage = 1;
                  this.property.filterDto.journalLedgerReversal.journalCode =
                    "";
                  this.property.filterDto.journalLedgerReversal.branchCode = "";
                  this.property.filterDto.journalLedgerReversal.referenceNumber =
                    "";
                  this.property.filterDto.journalLedgerReversal.userId = "";
                  this.property.filterDto.journalLedgerReversal.dateFrom = "";
                  this.property.filterDto.journalLedgerReversal.dateTo = "";
                  this.property.listElement.journalLedgerReversal.rows = 0;
                  this.getListTableJournalReversal();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async deleteJournalLedgerReversal(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "reversal/" + props.row.header.referenceNumber,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.journalLedgerReversal.journalCode =
                    "";
                  this.property.filterDto.journalLedgerReversal.branchCode = "";
                  this.property.filterDto.journalLedgerReversal.referenceNumber =
                    "";
                  this.property.filterDto.journalLedgerReversal.userId = "";
                  this.property.filterDto.journalLedgerReversal.dateFrom = "";
                  this.property.filterDto.journalLedgerReversal.dateTo = "";
                  this.property.listElement.journalLedgerReversal.rows = 0;
                  this.property.listElement.journalLedgerReversal.currentPage = 1;
                  this.getListTableJournalReversal();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    async changeAllTransaction(event) {
      this.dataForm.allTransaction = event;
      console.log("test function jalan");
      this.dataForm.reRenderComponent = false;
      console.log("reRender = ", this.dataForm.reRenderComponent);
      await this.$nextTick();
      this.dataForm.reRenderComponent = true;
    },
  },
  mounted() {
    //option select
    this.getReferenceInterbankAsset();
    this.getReferenceChartOfAccount();
    this.getReferenceOffice();
    this.getCashAdvance();
    this.getCashAdvanceReturn();
    this.getOverbookCoa();
    this.helperGetDataTableOverbook();
    this.getMultiAccount();
    // this.getVirtualAccountList();
    this.getBalanceCash();
    this.helperGetDataTableSavingAccountDetail();
    this.getInventoryBilyet();
    this.getDepositBilyet();
    this.helperGetDataTableDepositAccount();
    this.getMortgageAccount();
    this.getLoanAccount();
    this.getSavingAccount();
    this.helperGetDataTableCif();
    this.getLoanAccountReschedule();
    this.getLoanAccountRestructure();
    this.getLoanPaid();
    this.getLoanPaymentReturn();
    this.getLoanWriteOff();
    this.getCollectorFunding();
    this.helperGetDataTableTInterbankAccount();
    this.helperGetDataTableTaskInterbankAsset();
    this.getInterbankLiability();
    this.getInterbankAssets();
    this.getSBRList();
    this.getMortgagePaidOff();
    this.helperGetDataTableDepositDisburse();
    this.getDataTableGeneratePinAtm();
    this.getSavingAccountClose();
    this.getSavingBlock();
    this.getListTableJournalReversal();
  },
};
