export default {
  name: "DailyTransactionApuppt",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
  },
  data() {
    return {
      property: {
        filterDto: {
          cifNumber: "",
          officeCode: "",
          dateFrom: "",
          dateTo: "",
        },
        animation: {
          submitLoading: false,
          isDownloadingFile: false,
        },
        listElement: {
          downloading: false,
          message: "",
        },
      },
      options: {
        office: [],
      },
      table: {
        data: {
          dailyTransactionApuppt: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        cifNumber: this.property.filterDto.cifNumber,
        officeCode: this.property.filterDto.officeCode,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/daily-transaction-apuppt/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Transaksi_Apuppt.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async getTableDailyTransactionApuppt() {
      this.table.data.dailyTransactionApuppt = [];
      const timeout = 500;

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.listElement.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/daily-transaction-apuppt",
            payload: {
              cifNumber: this.property.filterDto.cifNumber,
              officeCode: this.property.filterDto.officeCode,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
            },
          });
          console.log(resp);
          if (resp.data.code === "SUCCESS") {
            setTimeout(() => {
              this.table.data.dailyTransactionApuppt = resp.data.data;
            }, timeout);
          } else {
            this.property.listElement.message = resp.data.message;
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          setTimeout(() => {
            this.property.listElement.downloading = false;
          }, 1000);
        }
      }
    },
    helperGetCabangList() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "office",
          params: {
            officeName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeCode;
            this.options.office.push({ text, value });
          });
        });
    },
    setDefaultOfficeCodeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.property.filterDto.officeCode = getUserDataFromSession.officeCode;
      this.property.filterDto.dateFrom = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.property.filterDto.dateTo = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.getTableDailyTransactionApuppt();
    this.setDefaultOfficeCodeValue();
    this.helperGetCabangList();
  },
};
