import ModalSearchSavingAccount from "@/components/modal-search-saving-account";

export default {
  name: "FormTCashAdvanceReturn",
  props: {
    isExisting: false,
  },
  components: {
    ModalSearchSavingAccount,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        modal: {
          modalFindSavingAccount: false,
          modalFindSavingAccountReturn: false,
        },
        animation: {
          submitLoading: false,
        },
      },
      options: {
        department: [],
        cashAdvanceType: [],
        accountType: [
          { text: "-- Pilih --", value: "" },
          { text: "ABA", value: "ABA" },
          { text: "TAB", value: "TAB" },
        ],
        interbankAssets: [],
        chartOfAccount: [],
      },
      form: {
        cashAdvanceName: "",
        cashAdvanceCode: "",
        realizationDate: "",
        returnDate: "",
        nominal: 0,
        description: "",
        departmentId: "",
        cashAdvanceTypeId: "",
        accountNumber: "",
        accountName: "",
        nominalAdmin: 0,
        accountType: "",
        // request bellow
        return: {
          accountNumber: "",
          accountName: "",
          accountType: "",
          returnNominal: 0,
          returnDescription: "",
          returnOption: 0,
          detail: [],
        },
      },
    };
  },
  methods: {
    addRowReturnDetail() {
      this.form.return.detail.push({
        nominal: 0,
        description: "",
        chartOfAccountId: "",
        chartOfAccountDescription: "",
        identifierId: Math.random().toString(36).substr(2, 9),
      });
    },
    async deleteReturnDetail(props) {
      const confirmation = await this.simpleConfirmation(
        "Hapus Data Ini",
        "is-danger"
      );
      if (confirmation) {
        var filterUnlessSelected = this.form.return.detail.filter(
          (index) => index.identifierId !== props.row.identifierId
        );
        this.form.return.detail = filterUnlessSelected;
      }
    },
    chartOfAccountDescFinder(value) {
      return this.options.chartOfAccount.find((i) => i.value === value)
        .description;
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.backToList();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).cashAdvanceId;

      this.$emit("getMainId", this.identity.mainId);
    },
    backToList() {
      this.$router.push("/cash-advance-return");
    },
    submit() {
      this.$emit("submit", this.form);
    },
    insertRowReturnDetail(payload) {
      let data = [];
      let index = 0;
      while (index !== payload.length) {
        data.push({
          nominal: payload[index].nominal,
          description: payload[index].description,
          chartOfAccountId: payload[index].chartOfAccountId,
          chartOfAccountDescription: payload[index].chartOfAccountDescription,
          identifierId: Math.random().toString(36).substr(2, 9),
        });
        index++;
      }
      this.form.return.detail = data;
    },
    getDefaultFormValue(payload) {
      if (this.isExisting) {
        console.log(payload);
        this.form = {
          ...this.form,
          cashAdvanceName: payload.cashAdvanceName,
          cashAdvanceCode: payload.cashAdvanceCode,
          realizationDate: payload.realizationDate,
          returnDate: payload.returnDate,
          nominal: payload.nominal,
          description: payload.description,
          departmentId: payload.departmentId,
          cashAdvanceTypeId: payload.rcashAdvancePaymentType?.paymentTypeId,
          accountNumber:
            payload.accountType === "TAB"
              ? payload.tsavingAccount?.accountNumber
              : payload.minterbankAssets?.accountNumber,
          accountName:
            payload.accountType === "TAB"
              ? `${
                  payload.tsavingAccount?.qqName
                    ? `${payload.tsavingAccount?.mcif?.cifIdName} ${payload.tsavingAccount?.qqCode} ${payload.tsavingAccount?.qqName}`
                    : payload.tsavingAccount?.mcif?.cifIdName
                }`
              : `${payload.minterbankAssets?.accountName} ( ${payload.minterbankAssets?.bankName} )`,
          nominalAdmin: payload.nominalAdmin,
          accountType: payload.accountType,
          createdByUserCode: payload.createdByUserCode,
          createdByUserName: payload.createdByUserName,
          createdDate: payload.createdDate,
          updatedDate: payload.updatedDate,
          updatedByUserCode: payload.updatedByUserCode,
          updatedByUserName: payload.updatedByUserName,
          authorizedDate: payload.authorizedDate,
          authorizedByUserCode: payload.authorizedByUserCode,
          authorizedByUserName: payload.authorizedByUserName,
        };
        this.form.return = {
          ...this.form.return,
          accountNumber:
            payload.accountTypeReturn === "TAB"
              ? payload.tsavingAccountReturn?.accountNumber
              : payload.minterbankAssets?.accountNumber,
          accountName:
            payload.accountTypeReturn === "TAB"
              ? `${
                  payload.tsavingAccountReturn?.qqName
                    ? `${payload.tsavingAccountReturn?.mcif?.cifIdName} ${payload.tsavingAccountReturn?.qqCode} ${payload.tsavingAccountReturn?.qqName}`
                    : payload.tsavingAccountReturn?.mcif?.cifIdName
                }`
              : `${payload.minterbankAssets?.accountName} ( ${payload.minterbankAssets?.bankName} )`,
          accountType: payload.accountTypeReturn,
          returnNominal: payload.returnNominal,
          returnDescription: payload.returnDescription,
          returnOption: payload.returnOption,
        };
        this.insertRowReturnDetail(payload.detail);
        return;
      }
      this.form = {
        ...this.form,
        cashAdvanceName: payload.cashAdvanceName,
        cashAdvanceCode: payload.cashAdvanceCode,
        realizationDate: payload.realizationDate,
        returnDate: payload.returnDate,
        nominal: payload.nominal,
        description: payload.description,
        departmentId: payload.departmentId,
        cashAdvanceTypeId: payload.rcashAdvancePaymentType?.paymentTypeId,
        accountNumber:
          payload.accountType === "TAB"
            ? payload.tsavingAccount?.accountNumber
            : payload.minterbankAssets?.accountNumber,
        accountName:
          payload.accountType === "TAB"
            ? `${
                payload.tsavingAccount?.qqName
                  ? `${payload.tsavingAccount?.mcif?.cifIdName} ${payload.tsavingAccount?.qqCode} ${payload.tsavingAccount?.qqName}`
                  : payload.tsavingAccount?.mcif?.cifIdName
              }`
            : `${payload.minterbankAssets?.accountName} ( ${payload.minterbankAssets?.bankName} )`,
        nominalAdmin: payload.nominalAdmin,
        accountType: payload.accountType,
      };
    },
    onChangeAccountInterbankAsset() {
      let val = this.options.interbankAssets.find(
        (item) => item.value === this.form.accountNumber
      );
      this.form.accountName = val.accountName;
    },
    onChangeAccountInterbankAssetReturn() {
      let val = this.options.interbankAssets.find(
        (item) => item.value === this.form.return.accountNumber
      );
      this.form.return.accountName = val.accountName;
    },
    async getReferenceDepartment() {
      const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
        url: "department",
      });
      if (resp.data.code === "SUCCESS") {
        this.options.department = [{ value: "", text: "-- Pilih --" }];
        resp.data.data.map((data) => {
          const value = data.departmentId;
          const text = data.departmentName;
          this.options.department.push({ text, value });
        });
      }
    },
    async getReferenceCashAdvancePaymentType() {
      const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
        url: "cash_advance_payment_type",
      });
      if (resp.data.code === "SUCCESS") {
        this.options.cashAdvanceType = [{ value: "", text: "-- Pilih --" }];
        resp.data.data.map((data) => {
          const value = data.paymentTypeId;
          const text = data.paymentTypeName;
          this.options.cashAdvanceType.push({ text, value });
        });
      }
    },
    async getReferenceInterbankAsset() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.interbankAssets = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.accountNumber;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = `${index.accountName} ( ${index.bankName} )`;
            this.options.interbankAssets.push({
              text,
              value,
              accountName,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.chartOfAccount = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountId;
            const description = index.description;
            this.options.chartOfAccount.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeMount() {
    this.checkingMainId();
  },
  mounted() {
    this.getReferenceDepartment();
    this.getReferenceCashAdvancePaymentType();
    this.getReferenceInterbankAsset();
    this.getReferenceChartOfAccount();
  },
};
