var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-center items-center flex-row w-full"},[_c('div',{staticClass:"flex justify-between items-center flex-wrap flex-row w-full"},[_c('div',{staticClass:"flex w-full flex-wrap items-center justify-between",staticStyle:{"max-width":"280px"}},[_c('span',{staticClass:"text-xs"},[_vm._v("Kode EDC")]),_c('b-input',{staticStyle:{"max-width":"200px","width":"100%"},attrs:{"lazy":true,"size":"is-small"},on:{"input":_vm.searchByEdcCode},model:{value:(_vm.property.filterDto.edcCode),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "edcCode", $$v)},expression:"property.filterDto.edcCode"}})],1),_c('b-button',{staticClass:"bprs-hik-theme-background button-theme-confirm",attrs:{"label":"EDC","icon-left":"plus","size":"is-small","disabled":!_vm.$store.getters['getActionsInsert']},on:{"click":_vm.routeToPageAddDayOff}})],1)]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.edc,"striped":"","narrowed":"","bordered":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.edc.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.edc.downloading === false)?_c('span',[_vm._v(" "+_vm._s(_vm.property.listElement.edc.message)+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"edcCode","label":"Kode EDC"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.edcCode ? props.row.edcCode : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"edcName","label":"Nama EDC"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.edcName ? props.row.edcName : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","field":"statusName","label":"Status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs uppercase"},[_vm._v(_vm._s(props.row.status ? props.row.status.statusName : "-"))])]}}])}),_c('b-table-column',{attrs:{"header-class":"bprs-hik-theme-background","centered":"","cell-class":"py-2","field":"","label":"Aksi"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"can-close":"outside","append-to-body":"","aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"\n                font-bold\n                border-none\n                outline-none\n                text-white\n                hover:text-white\n                focus:text-white\n              ",staticStyle:{"border-radius":"3px","font-size":"10px"},attrs:{"size":"is-small","label":"Aksi","type":"is-success","icon-right":active ? 'menu-up' : 'menu-down'}})]}}],null,true)},[_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsUpdate'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.routeToPageEditDayOff(props)}}},[_vm._v(" Rincian ")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsDelete'],"aria-role":"menuitem"},on:{"click":function($event){return _vm.deleteDataEdc(props)}}},[_vm._v("Delete")]),_c('b-dropdown-item',{staticClass:"flex justify-left text-xs",attrs:{"disabled":!_vm.$store.getters['getActionsAuthorize'] ||
                props.row.status.statusId === '3' ||
                props.row.status.statusId === '2',"aria-role":"menuitem"},on:{"click":function($event){return _vm.authorizationEdc(props)}}},[_vm._v("Authorisasi")])],1)]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.edc.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.edc.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationEdc},model:{value:(_vm.property.listElement.edc.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.edc, "currentPage", $$v)},expression:"property.listElement.edc.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }