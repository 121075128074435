export default {
  name: "ListWriteOff",
  data() {
    return {
      dataForm: {
        checkingLoanWriteOff: {
          accountNumber: "",
          nama: "",
        },
        installmentLoanWriteOff: {
          loanAccountId: "",
          nominal: 0,
          cifIdName: "",
          accountNumber: "",
          loanAccountNumber: "",
          balanceUsable: "",
          outstanding: "",
        },
        paidOffLoanWriteOff: {
          loanAccountId: "",
          nominal: 0,
          cifIdName: "",
          accountNumber: "",
          loanAccountNumber: "",
          balanceUsable: "",
          outstanding: "",
          isDefaultPayment: true,
          sbbDescription: "",
        },
      },
      session: {
        checkingLoanWriteOff: {
          accountNumber: "",
          nama: "",
        },
      },
      property: {
        animation: {
          checkingLoanWriteOff: {
            isLoading: false,
          },
          installmentLoanWriteOff: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingLoanWriteOff: false,
          showModalResultListLoanAccount: false,
          showModalAngsuranLoanWriteOff: false,
          showModalPaidOffLoanWriteOff: false,
        },
        filterDto: {
          loanAccountNumber: "",
          cifIdName: "",
          dateFrom: "",
          dateTo: "",
        },
        listElement: {
          writeOff: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loan: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      table: {
        data: {
          writeOff: [],
          transactionLoan: [],
        },
      },
    };
  },
  methods: {
    /* Modal Angsuran */
    routeTopageTransactionLoanWriteOff() {
      this.$router.push("/loan/loan-write-off");
    },
    openModalAngsuranLoanWriteOff(props) {
      console.log("test data =>", props.row);
      this.dataForm.installmentLoanWriteOff.loanAccountId =
        props.row.loanAccountId;
      this.dataForm.installmentLoanWriteOff.cifIdName = props.row.cifIdName;
      this.dataForm.installmentLoanWriteOff.accountNumber =
        props.row.accountNumber;
      this.dataForm.installmentLoanWriteOff.loanAccountNumber =
        props.row.loanAccountNumber;
      this.dataForm.installmentLoanWriteOff.balanceUsable =
        props.row.balanceUsable;
      this.dataForm.installmentLoanWriteOff.outstanding = props.row.outstanding;
      this.property.modal.showModalAngsuranLoanWriteOff = true;
    },
    closeModalAngsuranLoanWriteOff() {
      this.property.modal.showModalAngsuranLoanWriteOff = false;
      this.dataForm.installmentLoanWriteOff.loanAccountId = "";
      this.dataForm.installmentLoanWriteOff.nominal = 0;
    },
    generateInstallmentLoanWriteOff() {
      const data = {
        loanAccountId: this.dataForm.installmentLoanWriteOff.loanAccountId,
        nominal: this.dataForm.installmentLoanWriteOff.nominal,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Angsuran ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.installmentLoanWriteOff.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "write-off-transaction/installment",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$refs.formTLoanWriteOffRef.routeToPageListLoanWriteOff();
                  });
                  this.closeModalAngsuranLoanWriteOff();
                  this.routeTopageTransactionLoanWriteOff();
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.installmentLoanWriteOff.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    /* Modal Pelunasan */
    closeModalPaidOffLoanWriteOff() {
      this.property.modal.showModalPaidOffLoanWriteOff = false;
      this.dataForm.paidOffLoanWriteOff.loanAccountId = "";
      this.dataForm.paidOffLoanWriteOff.cifIdName = "";
      this.dataForm.paidOffLoanWriteOff.accountNumber = "";
      this.dataForm.paidOffLoanWriteOff.loanAccountNumber = "";
      this.dataForm.paidOffLoanWriteOff.balanceUsable = "";
      this.dataForm.paidOffLoanWriteOff.outstanding = "";
      this.dataForm.paidOffLoanWriteOff.nominal = 0;
      this.dataForm.paidOffLoanWriteOff.isDefaultPayment = true;
      this.dataForm.paidOffLoanWriteOff.sbbDescription = "";
    },
    openModalPaidOffLoanWriteOff(props) {
      console.log("test data =>", props.row);
      this.dataForm.paidOffLoanWriteOff.loanAccountId = props.row.loanAccountId;
      this.dataForm.paidOffLoanWriteOff.cifIdName = props.row.cifIdName;
      this.dataForm.paidOffLoanWriteOff.accountNumber = props.row.accountNumber;
      this.dataForm.paidOffLoanWriteOff.loanAccountNumber =
        props.row.loanAccountNumber;
      this.dataForm.paidOffLoanWriteOff.balanceUsable = props.row.balanceUsable;
      this.dataForm.paidOffLoanWriteOff.outstanding = props.row.outstanding;
      this.dataForm.paidOffLoanWriteOff.sbbDescription = `${props.row.coaCodeTitipanNasabah} - ${props.row.coaNameTitipanNasabah}`;
      this.property.modal.showModalPaidOffLoanWriteOff = true;
    },
    generatePaidOffLoanWriteOff() {
      const data = {
        loanAccountId: this.dataForm.paidOffLoanWriteOff.loanAccountId,
        isDefaultPayment: this.dataForm.paidOffLoanWriteOff.isDefaultPayment,
      };
      console.log('payload pelunasan :', data)
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Pelunasan Hapus Buku?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.installmentLoanWriteOff.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "write-off-transaction/paid-off",
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$refs.formTLoanWriteOffRef.routeToPageListLoanWriteOff();
                  });
                  this.routeTopageTransactionLoanWriteOff();
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.installmentLoanWriteOff.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },

    async findLoanWriteOff() {
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-write-off",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.writeOff.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.writeOff = resp.data.data.content;
            this.property.listElement.writeOff.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanWriteOff(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanWriteOff(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.writeOff.downloading = false;
        }, timeout);
      }
    },
    routeToPageCreate(props) {
      this.$router.push(
        `/loan/write-off/new?refId=${this.encryptBASE64(
          JSON.stringify({
            loanAccountId: props.row.loanAccountId,
          })
        )}`
      );
    },
    routeToPageRincian(props) {
      this.$router.push(
        `/loan/write-off/${this.encryptBASE64(`${props.row.loanWriteOffId}`)}`
      );
    },
    async getLoanWriteOff() {
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-write-off",
          payload: {
            loanAccountNumber: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.writeOff = resp.data.data.content;
            this.property.listElement.writeOff.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanWriteOff(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanWriteOff(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.writeOff.downloading = false;
        }, timeout);
      }
    },
    async changePaginationLoanWriteOff(event) {
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-write-off",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.writeOff.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.writeOff = resp.data.data.content;
            this.property.listElement.writeOff.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanWriteOff(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanWriteOff(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.writeOff.downloading = false;
        }, timeout);
      }
    },
    failedGetDataLoanWriteOff(resp) {
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.rows = 0;
      this.property.listElement.writeOff.message = resp.data.message;
    },
    handleErrorGetLoanWriteOff(error) {
      console.log(error.response);
      this.table.data.writeOff = [];
      this.property.listElement.writeOff.rows = 0;
      this.property.listElement.writeOff.currentPage = 1;
      this.property.listElement.writeOff.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataLoan(resp) {
      this.table.data.transactionLoan = [];
      this.property.listElement.loan.rows = 0;
      this.property.listElement.loan.message = resp.data.message;
    },
    handleErrorGetLoan(error) {
      console.log(error.response);
      this.table.data.transactionLoan = [];
      this.property.listElement.loan.rows = 0;
      this.property.listElement.loan.currentPage = 1;
      this.property.listElement.loan.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async deleteDataLoanWriteOff(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl: "loan-write-off/" + props.row.loanWriteOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.writeOff.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.loanAccountNumber = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.dateFrom = "";
                  this.property.listElement.writeOff.rows = 0;
                  this.getLoanWriteOff();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async authorizedLoanWriteOff(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl: "loan-write-off/authorized/" + props.row.loanWriteOffId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.dateTo = "";
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.loanAccountNumber = "";
                  this.property.listElement.writeOff.rows = 0;
                  this.getLoanWriteOff();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleCloseModalCheckingLoanWriteOff() {
      this.property.modal.showModalCheckingLoanWriteOff = false;
      this.handleClearModalCheckingLoanWriteOff();
    },
    handleClearModalCheckingLoanWriteOff() {
      this.dataForm.checkingLoanWriteOff.accountNumber = "";
      this.dataForm.checkingLoanWriteOff.nama = "";
    },
    handleClearModalResultCheckingLoanWriteOff() {
      this.table.data.transactionLoan = [];
      this.property.listElement.loan.rows = 0;
      this.property.listElement.loan.currentPage = 1;
      this.property.listElement.loan.perPage = 5;
    },
    clearTableCheckingTransactionLoan() {
      this.table.data.transactionLoan = [];
      this.property.listElement.loan.currentPage = 1;
      this.property.listElement.loan.rows = 0;
      this.property.listElement.loan.downloading = true;
    },
    async handleCheckingLoanWriteOff() {
      this.clearTableCheckingTransactionLoan();
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingLoanWriteOff.nama = this.dataForm.checkingLoanWriteOff.nama;
        this.session.checkingLoanWriteOff.accountNumber = this.dataForm.checkingLoanWriteOff.accountNumber;
        this.dataForm.checkingLoanWriteOff.nama = "";
        this.dataForm.checkingLoanWriteOff.accountNumber = "";
        const timeout = 500;
        try {
          this.property.animation.checkingLoanWriteOff.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "loan",
            reqUrl: "transaction-loan/simple-list",
            payload: {
              loanAccountNumber: this.session.checkingLoanWriteOff
                .accountNumber,
              cifIdName: this.session.checkingLoanWriteOff.nama,
              page: 0,
              size: this.property.listElement.loan.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.property.modal.showModalCheckingLoanWriteOff = false;
              this.property.modal.showModalResultListLoanWriteOff = true;
              this.table.data.transactionLoan =
                resp.data.data.pageResponse.content;
              this.property.listElement.loan.rows =
                resp.data.data.pageResponse.totalElements;
            } else {
              this.dataForm.checkingLoanWriteOff.accountNumber = this.session.checkingLoanWriteOff.accountNumber;
              this.dataForm.checkingLoanWriteOff.nama = this.session.checkingLoanWriteOff.nama;
              this.session.checkingLoanWriteOff.accountNumber = "";
              this.session.checkingLoanWriteOff.nama = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          console.log(error.response);
          setTimeout(() => {
            this.dataForm.checkingLoanWriteOff.accountNumber = this.session.checkingLoanWriteOff.accountNumber;
            this.dataForm.checkingLoanWriteOff.nama = this.session.checkingLoanWriteOff.nama;
            this.session.checkingLoanWriteOff.accountNumber = "";
            this.session.checkingLoanWriteOff.nama = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.checkingLoanWriteOff.isLoading = false;
          }, timeout);
        }
      }
    },
    async changePaginationLoan(event) {
      this.table.data.transactionLoan = [];
      this.property.listElement.loan.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.session.checkingLoanWriteOff.accountNumber,
            cifIdName: this.session.checkingLoanWriteOff.nama,
            page: event - 1,
            size: this.property.listElement.loan.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.transactionLoan =
              resp.data.data.pageResponse.content;
            this.property.listElement.loan.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetDataLoan(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoan(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loan.downloading = false;
        }, timeout);
      }
    },
  },
  mounted() {
    this.getLoanWriteOff();
  },
};
