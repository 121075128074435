import FormTLoanSp3 from "../form";

export default {
  name: "CreateTLoanSp3",
  components: {
    FormTLoanSp3,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getCifById();
    },
    async addLoanSp3(payload) {
      var data = {
        cifId: this.identity.mainId,
        sp3Date: payload.sp3Date,
        sp3Subject: payload.sp3Subject,
        sp3DocumentFile1: payload.sp3DocumentFile1,
        sp3DocNumber: payload.sp3DocNumber,
        deliveryDate: payload.deliveryDate,
        receiptDate: payload.receiptDate,
        approvalDate: payload.approvalDate,
        sp3DocumentFile2: payload.sp3DocumentFile2,
        returnDate: payload.returnDate,
        loanSp3SendreceiveTypeByDeliverySendreceiveTypeId:
          payload.loanSp3SendreceiveTypeByDeliverySendreceiveTypeId,
        loanSp3DebiturResponseByDebiturResponseId:
          payload.loanSp3DebiturResponseByDebiturResponseId,
        loanSp3SendreceiveTypeByReturnSendreceiveTypeId:
          payload.loanSp3SendreceiveTypeByReturnSendreceiveTypeId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Simpan Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.$refs.formTLoanSp3Ref.property.animation.loanSp3.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "POST_DATA",
              endPoint: "loan",
              reqUrl: "loan-sp3",
              payload: data,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                const toastBuefy = this.$buefy.toast.open(
                  this.$NotificationUtils.success
                );
                toastBuefy.$on("close", () => {
                  this.$refs.formTLoanSp3Ref.routeToPageListLoanSp3();
                });
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.$refs.formTLoanSp3Ref.property.animation.loanSp3.isLoading = false;
            }, 1000);
          }
        },
      });
    },
    async getCifById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formTLoanSp3Ref.routeToPageListLoanSp3();
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "cif/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formTLoanSp3Ref.getDefaultFormValue(resp.data.data);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formTLoanSp3Ref.routeToPageListLoanSp3(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan !`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formTLoanSp3Ref.routeToPageListLoanSp3(),
          });
        }
      }
    },
  },
};
