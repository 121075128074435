const TTaskInterbankAsset = {
  actions: {
    GET_T_TASK_INTERBANK_ASSET({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/transaction/task-interbank-asset`,
            params: payload,
            method: "GET",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
export default TTaskInterbankAsset;
