import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
import Modules from "./modules";
import {
  decryptBASE64,
  GetBalanceCashTeller,
} from "../service/serviceHelpers.js";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  const getUserDataFromSession = sessionStorage.getItem(
    "@vue-session/auth-bundle"
  );

  if (!getUserDataFromSession) {
    next();
    return;
  }
  if (
    to.query !== null &&
    to.query !== undefined &&
    to.query.ref !== null &&
    to.query.ref !== undefined
  ) {
    return next(to.query.ref);
  }
  next("/");
};

const ifAuthenticated = async (to, from, next) => {
  const getUserDataFromSession = sessionStorage.getItem(
    "@vue-session/auth-bundle"
  );

  const getMenuAccessDataFromSession = sessionStorage.getItem(
    "@vue-session/access-bundle"
  );

  if (!getUserDataFromSession || !getMenuAccessDataFromSession) {
    return next("/pages/login");
  }

  var routerMatched = to.matched;
  var breadCrumpRouter = routerMatched[routerMatched.length - 1].props.default
    ? routerMatched[routerMatched.length - 1].props.default.breadcrumbElement
    : [];
  store.dispatch("SET_BREADCRUMP_ELEMENT", breadCrumpRouter);
  if (to.path === "/main" || to.path === "/area/unaccess") {
    return next();
  }
  GetBalanceCashTeller();
  if (process.env.NODE_ENV === "production") {
    const menu_access_data = JSON.parse(
      decryptBASE64(sessionStorage.getItem("@vue-session/access-bundle"))
    );
    const MapMenuAccess = new Map(menu_access_data);
    MapMenuAccess.get(`${to.meta.routerLink}`)
      ? next()
      : next("/area/unaccess");
    return;
  }
  next();
  //
};

const router = new Router({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/main",
      name: "",
      component: Modules.TheContainer,
      children: [
        {
          path: "/area",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "unaccess",
              component: Modules.PageUnaccess,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "",
              },
              props: {
                breadcrumbElement: [],
              },
            },
          ],
        },
        {
          path: "main",
          component: Modules.LandingPage,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "",
          },
          props: {
            breadcrumbElement: [],
          },
        },
        // history profit share
        {
          path: "history-profit-share",
          component: Modules.ListTHistoryProfitShare,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "history-profit-share",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Deposito",
              "Tabel History Profit Share",
            ],
          },
        },
        {
          path: "history-profit-share/:id",
          component: Modules.DetailTHistoryProfitShare,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "history-profit-share",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Deposito",
              "Rincian History Profit Share",
            ],
          },
        },

        // cif
        {
          path: "cif",
          component: Modules.ListMCif,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cif",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Customer Service", "Tabel CIF"],
          },
        },
        {
          path: "cif/new/:type",
          component: Modules.CreateCif,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cif",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Customer Service", "Tambah CIF"],
          },
        },
        {
          path: "cif/:id/:type",
          component: Modules.ExistingCif,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cif",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Customer Service", "Rincian CIF"],
          },
        },
        // akumulasi penyusutan
        {
          path: "akumulasi-penyusutan",
          component: Modules.AkumulasiPenyusutan,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "akumulasi-penyusutan",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Reporting",
              "Akunting",
              "Akumulasi Penyusutan",
            ],
          },
        },
        // otorisasi semua transaksi
        {
          path: "authorized-all-transaction",
          component: Modules.AuthorizedAllTransaction,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "authorized-all-transaction",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Proses", "Otorisasi Semua Transaksi"],
          },
        },
        // inventory amortisasi
        {
          path: "inventory-amortisasi",
          component: Modules.TInventoryAmortisasi.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "inventory-amortisasi",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Reporting",
              "Akunting",
              "Penyusutan Nilai Asset",
            ],
          },
        },
        {
          path: "inventory-amortisasi/new",
          component: Modules.TInventoryAmortisasi.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "inventory-amortisasi",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Reporting",
              "Akunting",
              "Tambah Penyusutan Nilai Asset",
            ],
          },
        },
        {
          path: "inventory-amortisasi/:id",
          component: Modules.TInventoryAmortisasi.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "inventory-amortisasi",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Reporting",
              "Akunting",
              "Rincian Penyusutan Nilai Asset",
            ],
          },
        },
        {
          path: "collector-funding",
          component: Modules.MCollectorFunding.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "collector-funding",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Utility",
              "User Management",
              "Tabel Dana Kolektor",
            ],
          },
        },
        {
          path: "collector-funding/new",
          component: Modules.MCollectorFunding.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "collector-funding",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Utility",
              "User Management",
              "Tambah Dana Kolektor",
            ],
          },
        },
        {
          path: "collector-funding/:id",
          component: Modules.MCollectorFunding.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "collector-funding",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Utility",
              "User Management",
              "Rincian Dana Kolektor",
            ],
          },
        },
        {
          path: "collector-funding-detail",
          redirect: "collector-funding-detail/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              component: Modules.ListTCollectorFunding,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "collector-funding-detail",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Umum",
                  "Tabel Kolektor Funding ",
                ],
              },
            },
            {
              path: "add/manual",
              component: Modules.AddTCollectorFundingManual,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "collector-funding-detail",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Umum",
                  "Tambah Kolektor Funding",
                ],
              },
            },
            {
              path: "add/setor",
              component: Modules.AddTCollectorFundingSetor,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "collector-funding-detail",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Umum",
                  "Tambah Kolektor Funding",
                ],
              },
            },
            {
              path: "edit",
              component: Modules.EditTCollectorFunding,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "collector-funding-detail",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Umum",
                  "Rincian Kolektor Funding",
                ],
              },
            },
          ],
        },

        {
          path: "collector-funding-detail/list",
          component: Modules.ListTCollectorFunding,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "collector-funding-detail",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Tabel Kolektor Funding "],
          },
        },
        {
          path: "collector-funding-detail/add/manual",
          component: Modules.AddTCollectorFundingManual,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "collector-funding-detail",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Tambah Kolektor Funding"],
          },
        },
        {
          path: "collector-funding-detail/add/setor",
          component: Modules.AddTCollectorFundingSetor,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "collector-funding-detail",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Tambah Kolektor Funding"],
          },
        },
        {
          path: "collector-funding-detail/edit",
          component: Modules.EditTCollectorFunding,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "collector-funding-detail",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Rincian Kolektor Funding"],
          },
        },
        // Amortisasi Biaya
        {
          path: "amortisasi-biaya",
          component: Modules.AmortisasiBiaya.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "amortisasi-biaya",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Reporting", "Akunting", "Amortisasi Biaya"],
          },
        },
        {
          path: "amortisasi-biaya/new",
          component: Modules.AmortisasiBiaya.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "amortisasi-biaya",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Reporting",
              "Akunting",
              "Tambah Amortisasi Biaya",
            ],
          },
        },
        {
          path: "amortisasi-biaya/:id",
          component: Modules.AmortisasiBiaya.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "amortisasi-biaya",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Reporting",
              "Akunting",
              "Rincian Amortisasi Biaya",
            ],
          },
        },
        // saving
        {
          path: "saving",
          redirect: "saving/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              component: Modules.ListMSaving,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Tabungan",
                  "Tabel Produk Tabungan",
                ],
              },
            },
            {
              path: "add",
              component: Modules.AddMSaving,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Tabungan",
                  "Tambah Produk Tabungan",
                ],
              },
            },
            {
              path: "edit",
              component: Modules.EditMSaving,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Tabungan",
                  "Edit Produk Tabungan",
                ],
              },
            },
            {
              path: "detail",
              component: Modules.DetailMSaving,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Tabungan",
                  "Detail Produk Tabungan",
                ],
              },
            },
            /* Reactivate Savings */
            {
              path: "saving-account-reactivate",
              component: Modules.SavingAccountReactivate,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account-reactivate",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Tabel Reaktivasi Tabungan",
                ],
              },
            },
            /* Update user mpos */
            {
              path: "update-user-mpos",
              component: Modules.UpdateUserMpos,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/update-user-mpos",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Update User MPOS",
                ],
              },
            },
            /* Aktivasi Rekening Dormant */
            {
              path: "activated-dormant",
              component: Modules.ActivatedDormant,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/activated-dormant",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Aktifasi Rekening Dorman",
                ],
              },
            },
            /* Aktivasi Rekening Tab */
            {
              path: "activated-saving",
              component: Modules.ActivatedSaving,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/activated-saving",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Aktifasi Rekening Tabungan",
                ],
              },
            },
            /* PindahAO */
            {
              path: "update-ao",
              component: Modules.SavingUpdateAO,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/update-ao",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Pindah AO Tabungan",
                ],
              },
            },
            // saving account
            {
              path: "saving-account",
              component: Modules.TSavingAccount.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Tabel Rekening Tabungan",
                ],
              },
            },
            {
              path: "saving-account/new",
              component: Modules.TSavingAccount.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Tambah Rekening Tabungan",
                ],
              },
            },
            {
              path: "saving-account/:id",
              component: Modules.TSavingAccount.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Rincian Rekening Tabungan",
                ],
              },
            },
            // generate header book
            {
              path: "generate-header-book",
              component: Modules.TSavingAccountGenerateHeaderBook,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/generate-header-book",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Customer Service",
                  "Cetak Header Buku",
                ],
              },
            },
            // balance inquiry
            {
              path: "balance-inquiry",
              component: Modules.TSavingAccountBalanceInquiry,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/balance-inquiry",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Laporan Informasi",
                  "Saldo Inquiry",
                ],
              },
            },
            // multi account
            {
              path: "multi-account",
              component: Modules.TMultiAccount.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/multi-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Transaksi Multi Rekening",
                ],
              },
            },
            {
              path: "multi-account/new",
              component: Modules.TMultiAccount.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/multi-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tambah Transaksi Tunai",
                ],
              },
            },
            {
              path: "multi-account/:id",
              component: Modules.TMultiAccount.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/multi-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Transaksi Multi Rekening",
                ],
              },
            },
            // saving account detail
            {
              path: "saving-account-detail",
              component: Modules.TSavingAccountDetail.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account-detail",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Teller",
                  "Tabel Transaksi Tunai",
                ],
              },
            },
            {
              path: "saving-account-detail/new",
              component: Modules.TSavingAccountDetail.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account-detail",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Teller",
                  "Tambah Transaksi Tunai",
                ],
              },
            },
            {
              path: "saving-account-detail/:id",
              component: Modules.TSavingAccountDetail.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account-detail",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Teller",
                  "Rincian Transaksi Tunai",
                ],
              },
            },
            // account statement
            {
              path: "account-statement",
              component: Modules.TSavingAccountDetailAccountStatement,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/account-statement",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Laporan Informasi",
                  "Rekening Koran Tabungan",
                ],
              },
            },
            /* Saving History */
            {
              path: "saving-history",
              component: Modules.TSavingHistory,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-history",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Laporan Informasi",
                  "History Tabungan",
                ],
              },
            },
            // book transaction
            {
              path: "book-transaction",
              component: Modules.TSavingAccountDetailBookTransaction,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/book-transaction",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Laporan Informasi",
                  "Cetak Transaksi Buku",
                ],
              },
            },
            // close account

            {
              path: "close-saving-account",
              component: Modules.TSavingAccountDetailCloseSavingAccount.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/close-saving-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Tabel Tutup Rekening",
                ],
              },
            },
            {
              path: "close-saving-account/new",
              component: Modules.TSavingAccountDetailCloseSavingAccount.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/close-saving-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Form Tutup Rekening",
                ],
              },
            },
            {
              path: "close-saving-account/:id",
              component:
                Modules.TSavingAccountDetailCloseSavingAccount.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/close-saving-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Tabungan",
                  "Rincian Tutup Rekening",
                ],
              },
            },

            // reversal saving account

            {
              path: "reversal",
              component: Modules.TSavingAccountDetailReversal.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/reversal",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Teller",
                  "Tabel Reversal Transaksi Tunai",
                ],
              },
            },
            {
              path: "reversal/new",
              component: Modules.TSavingAccountDetailReversal.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/reversal",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Teller",
                  "Tambah Reversal Transaksi Tunai",
                ],
              },
            },
            {
              path: "reversal/:id",
              component: Modules.TSavingAccountDetailReversal.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/reversal",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Teller",
                  "Rincian Reversal Transaksi Tunai",
                ],
              },
            },

            // saving rak
            {
              path: "saving-account-detail-rak",
              component: Modules.TSavingAccountDetailRak.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account-detail-rak",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Teller", "Tabel Rak"],
              },
            },
            {
              path: "saving-account-detail-rak/new",
              component: Modules.TSavingAccountDetailRak.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account-detail-rak",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Teller", "Tambah Rak"],
              },
            },
            {
              path: "saving-account-detail-rak/:id",
              component: Modules.TSavingAccountDetailRak.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/saving-account-detail-rak",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Teller", "Rincian Rak"],
              },
            },
            // saving block
            {
              path: "saving-block",
              redirect: "saving-block/list",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "list",
                  component: Modules.ListTSavingBlock,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "saving/saving-block",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Tabungan",
                      "Tabel Blokir Rekening Tabungan",
                    ],
                  },
                },
                {
                  path: "process",
                  component: Modules.ProcessTSavingBlock,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "saving/saving-block",
                    isNeedReMount: false,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Tabungan",
                      "Proses Blokir Rekening Tabungan",
                    ],
                  },
                },
              ],
            },
            // saving overbook

            {
              path: "overbook",
              component: Modules.Overbook.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/overbook",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tabel Pindah Buku",
                ],
              },
            },
            {
              path: "overbook/new",
              component: Modules.Overbook.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/overbook",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tambah Data Pindah Buku",
                ],
              },
            },
            {
              path: "overbook/:id",
              component: Modules.Overbook.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "saving/overbook",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Pindah Buku",
                ],
              },
            },
          ],
        },

        // deposit
        {
          path: "deposit",
          redirect: "deposit/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              component: Modules.ListMDeposit,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Deposito",
                  "Tabel Produk Deposito",
                ],
              },
            },
            {
              path: "add",
              component: Modules.AddMDeposit,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Deposito",
                  "Tambah Produk Deposito",
                ],
              },
            },
            {
              path: "edit",
              component: Modules.EditMDeposit,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Deposito",
                  "Edit Produk Deposito",
                ],
              },
            },
            {
              path: "detail",
              component: Modules.DetailMDeposit,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Deposito",
                  "Detail Produk Deposito",
                ],
              },
            },
            // deposit account
            {
              path: "deposit-account",
              component: Modules.TDepositAccount.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Deposito",
                  "Tabel Rekening Deposito",
                ],
              },
            },
            {
              path: "deposit-account/new",
              component: Modules.TDepositAccount.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Deposito",
                  "Tambah Rekening Deposito",
                ],
              },
            },
            {
              path: "deposit-account/:id",
              component: Modules.TDepositAccount.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Deposito",
                  "Rincian Rekening Deposito",
                ],
              },
            },

            /* update fixrate */
            {
              path: "update-fix-rate",
              component: Modules.UpdateFixRate,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/update-fix-rate",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Deposito", "Update Fix Rate"],
              },
            },
            // deposit disburse

            {
              path: "deposit-account-disburse",
              component: Modules.TDepositAccountDisburse.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-account-disburse",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",

                  "Transaksi",
                  "Tabel Pencairan Pokok",
                ],
              },
            },
            {
              path: "deposit-account-disburse/new",
              component: Modules.TDepositAccountDisburse.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-account-disburse",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tambah Pencairan Pokok",
                ],
              },
            },
            {
              path: "deposit-account-disburse/:id",
              component: Modules.TDepositAccountDisburse.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-account-disburse",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Pencairan Pokok",
                ],
              },
            },
            {
              path: "fund-allocated",
              component: Modules.TDepositAccountDetailFundAllocated.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/fund-allocated",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tabel Transaksi Tunai Setor Deposito",
                ],
              },
            },
            {
              path: "fund-allocated/new",
              component: Modules.TDepositAccountDetailFundAllocated.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/fund-allocated",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tambah Transaksi Tunai Setor Deposito",
                ],
              },
            },
            {
              path: "fund-allocated/:id",
              component: Modules.TDepositAccountDetailFundAllocated.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/fund-allocated",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Transaksi Tunai Setor Deposito",
                ],
              },
            },
            // deposit overbook

            {
              path: "fund-allocated-overbook",
              component:
                Modules.TDepositAccountDetailFundAllocatedOverbook.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/fund-allocated-overbook",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Setor Deposito Pindah Buku",
                ],
              },
            },
            {
              path: "fund-allocated-overbook/new",
              component:
                Modules.TDepositAccountDetailFundAllocatedOverbook.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/fund-allocated-overbook",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tambah Deposito Pindah Buku",
                ],
              },
            },
            {
              path: "fund-allocated-overbook/:id",
              component:
                Modules.TDepositAccountDetailFundAllocatedOverbook.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/fund-allocated-overbook",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Deposito Pindah Buku",
                ],
              },
            },
            // atm channel kas
            // deposit account statement
            {
              path: "account-statement",
              component: Modules.TDepositAccountDetailAccountStatement,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/account-statement",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Laporan Informasi",
                  "Transaksi Rekening Koran Deposito",
                ],
              },
            },

            // deposit bilyet

            {
              path: "deposit-bilyet",
              component: Modules.MDepositBilyet.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-bilyet",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Deposito",
                  "Tabel Registrasi Bilyet",
                ],
              },
            },
            {
              path: "deposit-bilyet/new",
              component: Modules.MDepositBilyet.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-bilyet",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Deposito",
                  "Tambah Data Bilyet",
                ],
              },
            },
            {
              path: "deposit-bilyet/:id",
              component: Modules.MDepositBilyet.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-bilyet",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Deposito", "Rincian Bilyet"],
              },
            },

            // deposit bilyet inventory

            {
              path: "deposit-bilyet-inventory",
              component: Modules.MDepositBilyetInventory,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "deposit/deposit-bilyet-inventory",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Deposito",
                  "Tabel Inventory Bilyet",
                ],
              },
            },
          ],
        },
        //generatePinAtm
        {
          path: "saving-card",
          component: Modules.GeneratePinAtm.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "saving-card",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Tabungan", "List Generate Pin"],
          },
        },
        {
          path: "saving-card/new",
          component: Modules.GeneratePinAtm.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "saving-card",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Tabungan", "Form Generate Pin"],
          },
        },
        {
          path: "saving-card/:id",
          component: Modules.GeneratePinAtm.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "saving-card",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Tabungan",
              "Rincian Generate Pin",
            ],
          },
        },

        // inventory category
        {
          path: "inventory-category",
          component: Modules.GeneratePinAtm.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "inventory-category",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Akunting",
              "Penyusutan Nilai Aset",
            ],
          },
        },
        {
          path: "inventory-category/new",
          component: Modules.GeneratePinAtm.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "inventory-category",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Akunting",
              "Form Penyusutan Nilai Aset",
            ],
          },
        },
        {
          path: "inventory-category/:id",
          component: Modules.GeneratePinAtm.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "inventory-category",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Akunting",
              "Rincian Penyusutan Nilai Aset",
            ],
          },
        },
        // wadiah saving bonus
        {
          path: "wadiah-saving-bonus",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "by-product",
              component: Modules.WadiahSavingBonusByProduct,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "wadiah-saving-bonus/by-product",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Proses", "Hitung Bonus Tabungan"],
              },
            },
            {
              path: "by-office",
              component: Modules.WadiahSavingBonusByOffice,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "wadiah-saving-bonus/by-office",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Proses", "Hitung Bonus Tabungan"],
              },
            },
          ],
        },

        // pengisian kas teller
        {
          path: "balance-cash",
          component: Modules.TBalanceCash.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "balance-cash",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Teller", "Tabel Kas Teller"],
          },
        },
        {
          path: "balance-cash/new",
          component: Modules.TBalanceCash.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "balance-cash",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Teller", "Tambah Kas Teller"],
          },
        },
        {
          path: "balance-cash/:id",
          component: Modules.TBalanceCash.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "balance-cash",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Teller", "Rincian Kas Teller"],
          },
        },

        /* start transaksi single entry */
        {
          path: "single-entry",
          component: Modules.TSingleEntry.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "single-entry",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Transaksi", "Tabel Single Entry"],
          },
        },
        {
          path: "single-entry/new",
          component: Modules.TSingleEntry.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "single-entry",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Tambah Single Entry",
            ],
          },
        },
        {
          path: "single-entry/:id",
          component: Modules.TSingleEntry.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "single-entry",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Rincian Single Entry",
            ],
          },
        },
        /* end transaksi single entry */
        {
          path: "cash-advance",
          component: Modules.CashAdvance.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-advance",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Umum", "Tabel UMB"],
          },
        },
        {
          path: "cash-advance/new",
          component: Modules.CashAdvance.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-advance",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Umum", "Tambah UMB"],
          },
        },
        {
          path: "cash-advance/:id",
          component: Modules.CashAdvance.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-advance",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Umum", "Rincian UMB"],
          },
        },

        {
          path: "cash-advance-return",
          component: Modules.CashAdvanceReturn.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-advance-return",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Umum", "Tabel Pengembalian UMB"],
          },
        },
        {
          path: "cash-advance-return/new",
          component: Modules.CashAdvanceReturn.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-advance-return",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Umum", "Buat Pengembalian UMB"],
          },
        },
        {
          path: "cash-advance-return/:id",
          component: Modules.CashAdvanceReturn.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-advance-return",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Umum",
              "Rincian Pengembalian UMB",
            ],
          },
        },

        // interbank-assets
        {
          path: "interbank-assets",
          component: Modules.MInterbankAssets.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-assets",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Tabel Master ABA",
            ],
          },
        },
        {
          path: "interbank-assets/new",
          component: Modules.MInterbankAssets.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-assets",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Tambah Data Master ABA",
            ],
          },
        },
        {
          path: "interbank-assets/:id",
          component: Modules.MInterbankAssets.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-assets",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Rincian Master ABA",
            ],
          },
        },

        // interbank asset account statement
        {
          path: "account-statement-aba",
          component: Modules.TInterbankAssetsDetailAccountStatement,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "account-statement-aba",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Laporan Informasi",
              "Rekening Koran ABA",
            ],
          },
        },
        // interbank-liability

        {
          path: "interbank-liability",
          component: Modules.MInterbankLiability.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-liability",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Tabel Master ABP",
            ],
          },
        },
        {
          path: "interbank-liability/new",
          component: Modules.MInterbankLiability.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-liability",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Tambah Data Master ABP",
            ],
          },
        },
        {
          path: "interbank-liability/:id",
          component: Modules.MInterbankLiability.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-liability",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Rincian Master ABP",
            ],
          },
        },

        {
          path: "installment-abp/:id",
          component: Modules.MInterbankLiability.Installment,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-liability",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Installment ABP",
            ],
          },
        },

        // interbank liability account statement
        {
          path: "interbank-liability-account-statement",
          component: Modules.TInterbankLiabilityDetailAccountStatement,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "interbank-liability-account-statement",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Laporan Informasi",
              "Rekening Koran ABP",
            ],
          },
        },

        /* Cash Out Danamon */
        {
          path: "cash-out-danamon",
          component: Modules.InqueryDanamon.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-out-danamon",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Transaksi", "Cash Out"],
          },
        },
        {
          path: "cash-out-danamon/inquiry",
          component: Modules.InqueryDanamon.Inquery,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-out-danamon",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Pelayanan", "Transaksi", "Cash Out"],
          },
        },
        {
          path: "cash-out-danamon/transfer",
          component: Modules.InqueryDanamon.Tranfer,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-out-danamon",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Laporan Informasi",
              "Rekening Koran ABP",
            ],
          },
        },
        {
          path: "cash-out-danamon/status",
          component: Modules.InqueryDanamon.Status,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "cash-out-danamon",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Laporan Informasi",
              "Rekening Koran ABP",
            ],
          },
        },

        {
          path: "overbook-chart-of-account",
          component: Modules.OverbookChartOfAccount.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "overbook-chart-of-account",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Transaksi Pindah Buku SBB",
            ],
          },
        },
        {
          path: "overbook-chart-of-account/new",
          component: Modules.OverbookChartOfAccount.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "overbook-chart-of-account",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Teller",
              "Transaksi Transaksi Pindah Buku SBB",
            ],
          },
        },
        {
          path: "overbook-chart-of-account/:id",
          component: Modules.OverbookChartOfAccount.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "overbook-chart-of-account",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Teller",
              "Rincian Transaksi Pindah Buku SBB",
            ],
          },
        },

        {
          path: "atm-channel",
          component: Modules.MAtmChannel.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "Kas ATM",
            ],
          },
        },
        {
          path: "atm-channel/history",
          component: Modules.MAtmChannel.History,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "History Kas ATM",
            ],
          },
        },
        {
          path: "atm-channel/new",
          component: Modules.MAtmChannel.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "Form Kas ATM",
            ],
          },
        },
        {
          path: "atm-channel/:id",
          component: Modules.MAtmChannel.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "Rincian Kas ATM",
            ],
          },
        },
        {
          path: "atm-channel-return",
          component: Modules.TAtmChannelReturn,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel-return",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Umum",
              "Pengisian dan Pengembalian Kas ATM",
            ],
          },
        },
        {
          path: "atm-channel-transaction",
          component: Modules.TAtmChannel.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel-transaction",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "Transaksi Kas ATM",
            ],
          },
        },
        {
          path: "atm-channel-transaction/new",
          component: Modules.TAtmChannel.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel-transaction",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "Form Transaksi Kas ATM",
            ],
          },
        },
        {
          path: "atm-channel-transaction/:id",
          component: Modules.TAtmChannel.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "atm-channel-transaction",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "Rincian Transaksi Kas ATM",
            ],
          },
        },

        // task interbank asset
        {
          path: "task-interbank-asset",
          component: Modules.TTaskInterbankAsset.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "task-interbank-asset",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Transaksi Non Tunai ABA",
            ],
          },
        },
        {
          path: "task-interbank-asset/new",
          component: Modules.TTaskInterbankAsset.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "task-interbank-asset",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Transaksi Non Tunai ABA",
            ],
          },
        },
        {
          path: "task-interbank-asset/:id",
          component: Modules.TTaskInterbankAsset.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "task-interbank-asset",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Transaksi Non Tunai ABA",
            ],
          },
        },

        // task interbank liability
        {
          path: "task-interbank-liability",
          component: Modules.TTaskInterbankLiability.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "task-interbank-liability",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Transaksi Non Tunai ABP",
            ],
          },
        },
        {
          path: "task-interbank-liability/new",
          component: Modules.TTaskInterbankLiability.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "task-interbank-liability",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Tambah Non Tunai ABP",
            ],
          },
        },
        {
          path: "task-interbank-liability/:id",
          component: Modules.TTaskInterbankLiability.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "task-interbank-liability",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Pelayanan",
              "Transaksi",
              "Rincian Non Tunai ABP",
            ],
          },
        },
        // denomination user
        {
          path: "denomination-user",
          component: Modules.TDenominationUser,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "denomination-user",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: ["Reporting", "Teller", "Denominasi Teller"],
          },
        },
        // product-ledger
        {
          path: "product-ledger",
          component: Modules.MProductLedger.List,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "product-ledger",
            isNeedReMount: true,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Global",
              "Tabel Parameter Ledger Produk",
            ],
          },
        },
        {
          path: "product-ledger/new",
          component: Modules.MProductLedger.Create,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "product-ledger",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Tambah Parameter Ledger Produk",
            ],
          },
        },
        {
          path: "product-ledger/:id",
          component: Modules.MProductLedger.Existing,
          beforeEnter: ifAuthenticated,
          meta: {
            routerLink: "product-ledger",
            isNeedReMount: false,
          },
          props: {
            breadcrumbElement: [
              "Setup Parameter",
              "Parameter Akunting",
              "Rincian Parameter Ledger Produk",
            ],
          },
        },

        // admin
        {
          path: "admin",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            // user
            {
              path: "user",
              component: Modules.MUser.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/user",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "User Management",
                  "Tabel Parameter User Id",
                ],
              },
            },
            {
              path: "user/new",
              component: Modules.MUser.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/user",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "User Management",
                  "Tambah Parameter User Id",
                ],
              },
            },
            {
              path: "user/:id",
              component: Modules.MUser.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/user",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "User Management",
                  "Tambah Parameter User Id",
                ],
              },
            },
            // user role
            {
              path: "user-role",
              component: Modules.MUserRole.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/user-role",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "User Management",
                  "Tabel Parameter Role",
                ],
              },
            },
            {
              path: "user-role/new",
              component: Modules.MUserRole.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/user-role",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["User Management", "Tambah Parameter Role"],
              },
            },
            {
              path: "user-role/:id",
              component: Modules.MUserRole.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/user-role",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "User Management",
                  "Rincian Parameter Role",
                ],
              },
            },
            //mapping menu access
            {
              path: "mapping-menu-access",
              component: Modules.MUserRoleMappingMenuAccess,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/mapping-menu-access",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "User Management",
                  "Setup Role Menu",
                ],
              },
            },

            // menu access
            {
              path: "menu-access",
              component: Modules.MMenuAccess.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/menu-access",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Utility", "User Management", "Tabel Menu"],
              },
            },
            {
              path: "menu-access/new",
              component: Modules.MMenuAccess.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/menu-access",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "User Management",
                  "Tambah Menu",
                ],
              },
            },
            {
              path: "menu-access/:id",
              component: Modules.MMenuAccess.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/menu-access",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "User Management",
                  "Rincian Menu",
                ],
              },
            },
            {
              path: "history-log-user-sign",
              component: Modules.HistoryLogUserSign,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/history-log-user-sign",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Utility",
                  "Laporan Auditrail",
                  "Histori User Sign",
                ],
              },
            },
            {
              path: "manual-trigger-profit-share",
              component: Modules.ManualTriggerProfitShare,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/manual-trigger-profit-share",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["PROSES", "Proses Bayar Baghas"],
              },
            },
            {
              path: "manual-trigger-installment",
              component: Modules.ManualTriggerInstallment,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/manual-trigger-installment",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["PROSES", "Proses Bayar Angsuran"],
              },
            },
            //backup-restore
            {
              path: "backup-restore",
              component: Modules.BackupRestore,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/backup-restore",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Proses",
                  "Proses Data Center",
                  "Backup Restore",
                ],
              },
            },
            {
              path: "user-management",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                // office status
                {
                  path: "office-status",
                  component: Modules.OfficeStatus,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "admin/user-management/office-status",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Utility",
                      "Laporan Auditrail",
                      "Tabel Operasional Cabang",
                    ],
                  },
                },
              ],
            },
            {
              path: "configuration",
              component: Modules.ConfigurationList,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/configuration",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Konfigurasi"],
              },
            },
            {
              path: "configuration/:id",
              component: Modules.ConfigurationDetail,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/configuration",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Konfigurasi", "Rincian Konfigurasi"],
              },
            },
            {
              path: "configuration-frontend",
              component: Modules.ConfigurationFrontendList,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/configuration-frontend",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Konfigurasi Frontend"],
              },
            },
            {
              path: "configuration-frontend/:id",
              component: Modules.ConfigurationFrontendDetail,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "admin/configuration-frontend",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Konfigurasi Frontend",
                  "Rincian Konfigurasi Frontend",
                ],
              },
            },

            // operational process
            {
              path: "operational-process",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                // open operational process
                {
                  path: "open",
                  component: Modules.TOperationalProcess,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "admin/operational-process/open",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Proses",
                      "Proses Operasional",
                      "Buka Operasional Cabang",
                    ],
                  },
                },
                // close operational process
                {
                  path: "close",
                  component: Modules.TOperationalProcessClosing,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "admin/operational-process/close",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Proses",
                      "Proses Operasional",
                      "Tutup Operasional Cabang",
                    ],
                  },
                },
                {
                  path: "closing-eod-centralize",
                  component: Modules.ClosingEodCentralize,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink:
                      "admin/operational-process/closing-eod-centralize",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Proses",
                      "Proses Data Center",
                      "Proses Akhir Hari",
                    ],
                  },
                },
                {
                  path: "closing-eom-centralize",
                  component: Modules.ClosingEomCentralize,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink:
                      "admin/operational-process/closing-eom-centralize",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Proses",
                      "Proses Data Center",
                      "Proses Akhir Bulan",
                    ],
                  },
                },
                /* Proses EOY */
                {
                  path: "closing-eoy-centralize",
                  component: Modules.ClosingEoyCentralize,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink:
                      "admin/operational-process/closing-eoy-centralize",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Proses",
                      "Proses Data Center",
                      "Proses Akhir Tahun",
                    ],
                  },
                },
                // open operational process
                {
                  path: "list",
                  component: Modules.TOperationalProcessList,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "admin/operational-process/list",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: ["List", "Proses", "Operational"],
                  },
                },
              ],
            },
          ],
        },
        // parameter
        {
          path: "parameter",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "sbb-office-mapping",
              component: Modules.MappingOfficeCoa,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sbb-office-mapping",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Mapping Office COA",
                ],
              },
            },
            {
              path: "account-officer",
              component: Modules.MAccountOfficer,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/account-officer",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Master Account Officer",
                ],
              },
            },
            {
              path: "ppap",
              component: Modules.MPpap.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/ppap",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Kesehatan Bank",
                  "Master PPAP",
                ],
              },
            },
            {
              path: "ppap/new",
              component: Modules.MPpap.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/ppap",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Kesehatan Bank",
                  "Tambah PPAP",
                ],
              },
            },
            {
              path: "ppap/:id",
              component: Modules.MPpap.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/ppap",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Kesehatan Bank",
                  "Rincian PPAP",
                ],
              },
            },

            {
              path: "sandi-bi-level-1",
              component: Modules.MSandiBILevel1.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi-level-1",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Sandi BI Level 1",
                ],
              },
            },
            {
              path: "sandi-bi-level-1/new",
              component: Modules.MSandiBILevel1.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi-level-1",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Sandi BI Level 1",
                ],
              },
            },
            {
              path: "sandi-bi-level-1/:id",
              component: Modules.MSandiBILevel1.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi-level-1",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Sandi BI Level 1",
                ],
              },
            },

            {
              path: "sandi-bi-level-2",
              component: Modules.MSandiBILevel2.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi-level-2",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Sandi BI Level 2",
                ],
              },
            },
            {
              path: "sandi-bi-level-2/new",
              component: Modules.MSandiBILevel2.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi-level-2",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Sandi BI Level 2",
                ],
              },
            },
            {
              path: "sandi-bi-level-2/:id",
              component: Modules.MSandiBILevel2.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi-level-2",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Sandi BI Level 2",
                ],
              },
            },

            {
              path: "mapping-parameter-sandi-bi",
              component: Modules.MappingParameterSandiBi.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/mapping-parameter-sandi-bi",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Parameter Sandi BI",
                ],
              },
            },

            {
              path: "mapping-parameter-sandi-bi/new",
              component: Modules.MappingParameterSandiBi.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/mapping-parameter-sandi-bi",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Parameter Sandi BI",
                ],
              },
            },
            {
              path: "mapping-parameter-sandi-bi/:id",
              component: Modules.MappingParameterSandiBi.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/mapping-parameter-sandi-bi",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Parameter Sandi BI",
                ],
              },
            },

            // configuration FE
            // umb
            {
              path: "umb",
              component: Modules.Umb.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/umb",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel UMB",
                ],
              },
            },
            {
              path: "umb/new",
              component: Modules.Umb.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/umb",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah UMB",
                ],
              },
            },
            {
              path: "umb/:id",
              component: Modules.Umb.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/umb",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian UMB",
                ],
              },
            },
            {
              path: "business-segment",
              component: Modules.MBusinessSegment.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/business-segment",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Segmen Bisnis",
                ],
              },
            },
            {
              path: "business-segment/new",
              component: Modules.MBusinessSegment.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/business-segment",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Segmen Bisnis",
                ],
              },
            },
            {
              path: "business-segment/:id",
              component: Modules.MBusinessSegment.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/business-segment",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Segmen Bisnis",
                ],
              },
            },
            // transaction code

            {
              path: "transaction-code",
              component: Modules.MTransactionCode.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/transaction-code",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Kode Transaksi",
                ],
              },
            },
            {
              path: "transaction-code/new",
              component: Modules.MTransactionCode.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/transaction-code",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Kode Transaksi",
                ],
              },
            },
            {
              path: "transaction-code/:id",
              component: Modules.MTransactionCode.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/transaction-code",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Kode Transaksi",
                ],
              },
            },
            // office

            {
              path: "office",
              component: Modules.MOffice.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/office",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Kantor",
                ],
              },
            },
            {
              path: "office/new",
              component: Modules.MOffice.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/office",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Kantor",
                ],
              },
            },
            {
              path: "office/:id",
              component: Modules.MOffice.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/office",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Kantor",
                ],
              },
            },

            //master asset category
            {
              path: "master-asset-category",
              component: Modules.MasterAssetCategory.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/master-asset-category",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Master Aset Kategori",
                ],
              },
            },
            {
              path: "master-asset-category/new",
              component: Modules.MasterAssetCategory.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/master-asset-category",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Parameter Akunting",
                  "Tambah Master Aset Kategori",
                ],
              },
            },
            {
              path: "master-asset-category/:id",
              component: Modules.MasterAssetCategory.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/master-asset-category",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Parameter Akunting",
                  " Rincian Master Aset Kategori",
                ],
              },
            },

            // chart of account

            {
              path: "chart-of-account",
              component: Modules.MChartOfAccount.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/chart-of-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Tabel Chart Of Account",
                ],
              },
            },
            {
              path: "chart-of-account/new",
              component: Modules.MChartOfAccount.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/chart-of-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Tambah Chart Of Account",
                ],
              },
            },
            {
              path: "chart-of-account/:id",
              component: Modules.MChartOfAccount.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/chart-of-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Rincian Chart Of Account",
                ],
              },
            },

            // journal template

            {
              path: "journal-template",
              component: Modules.MJournalTemplate.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-template",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Tabel Jurnal Template",
                ],
              },
            },
            {
              path: "journal-template/new",
              component: Modules.MJournalTemplate.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-template",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Akunting",
                  "Jurnal",
                  "Tambah Jurnal Template",
                ],
              },
            },
            {
              path: "journal-template/:id",
              component: Modules.MJournalTemplate.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-template",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Akunting",
                  "Jurnal",
                  "Rincian Jurnal Template",
                ],
              },
            },

            // journal ledger
            {
              path: "journal-ledger",
              component: Modules.MJournalLedger.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-ledger",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Akunting",
                  "Tabel Journal Entry Manual",
                ],
              },
            },
            {
              path: "journal-ledger/new",
              component: Modules.MJournalLedger.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-ledger",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Akunting",
                  "Journal",
                  "Tambah Journal Entry Manual",
                ],
              },
            },
            {
              path: "journal-ledger/:id",
              component: Modules.MJournalLedger.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-ledger",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Akunting",
                  "Journal",
                  "Rincian Journal Entry Manual",
                ],
              },
            },

            // journal ledger reversal
            {
              path: "journal-ledger-reversal",
              component: Modules.MJournalLedgerReversal.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-ledger-reversal",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Akunting",
                  "Tabel Journal Reversal",
                ],
              },
            },
            {
              path: "journal-ledger-reversal/:id",
              component: Modules.MJournalLedgerReversal.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/journal-ledger-reversal",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Akunting",
                  "Journal",
                  "Rincian Journal Reversal",
                ],
              },
            },

            // sandi bi
            {
              path: "sandi-bi",
              component: Modules.MSandiBi.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Sandi BI",
                ],
              },
            },
            {
              path: "sandi-bi/new",
              component: Modules.MSandiBi.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Sandi BI",
                ],
              },
            },
            {
              path: "sandi-bi/:id",
              component: Modules.MSandiBi.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/sandi-bi",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Sandi BI",
                ],
              },
            },

            // rak
            {
              path: "rak",
              component: Modules.MRak.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/rak",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel RAK",
                ],
              },
            },
            {
              path: "rak/new",
              component: Modules.MRak.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/rak",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Tambah RAK",
                ],
              },
            },
            {
              path: "rak/:id",
              component: Modules.MRak.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/rak",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Akunting",
                  "Rincian RAK",
                ],
              },
            },
            //day-off
            {
              path: "day-off",
              component: Modules.MDayOff.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/day-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tabel Hari Libur",
                ],
              },
            },
            {
              path: "day-off/new",
              component: Modules.MDayOff.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/day-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Tambah Hari Libur",
                ],
              },
            },
            {
              path: "day-off/:id",
              component: Modules.MDayOff.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/day-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Rincian Hari Libur",
                ],
              },
            },
            // EDC
            {
              path: "edc",
              component: Modules.MEdc.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/edc",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Global",
                  "Setup Teller",
                ],
              },
            },
            {
              path: "edc/new",
              component: Modules.MEdc.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/edc",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Teller",
                  "Tambah Teller",
                ],
              },
            },
            {
              path: "edc/:id",
              component: Modules.MEdc.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "parameter/edc",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Teller",
                  "Rincian Teller",
                ],
              },
            },
          ],
        },
        // report
        {
          path: "report",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            /*m dttot*/
            {
              path: "dttot",
              component: Modules.MDttot.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/dttot",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Apuppt", "Dttot"],
              },
            },
            {
              path: "dttot/new",
              component: Modules.MDttot.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/dttot",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Reporting", "Apuppt", "Tambah Dttot"],
              },
            },
            {
              path: "dttot/:id",
              component: Modules.MDttot.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/dttot",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Reporting", "Apuppt", "Rincian Dttot"],
              },
            },
            /*m sipendar*/
            {
              path: "sipendar",
              component: Modules.MSipendar.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/sipendar",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Apuppt", "Sipendar"],
              },
            },
            {
              path: "sipendar/new",
              component: Modules.MSipendar.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/sipendar",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Reporting", "Apuppt", "Tambah Sipendar"],
              },
            },
            {
              path: "sipendar/:id",
              component: Modules.MSipendar.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/sipendar",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Reporting", "Apuppt", "Rincian Sipendar"],
              },
            },
            {
              path: "coa-mutation-history",
              component: Modules.MutasiCoa,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/coa-mutation-history",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Akunting", "Mutasi SBB"],
              },
            },
            // nominatif deposito
            {
              path: "nominatif-deposito",
              component: Modules.NominatifDeposito,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/nominatif-deposito",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Deposito",
                  "Nominatif Deposito",
                ],
              },
            },
            // nominatf comparing
            {
              path: "nominatif-comparing",
              component: Modules.NominatifComparing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/nominatif-comparing",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Nominatif",
                  "Nominatif Comparing",
                ],
              },
            },
            // nominatif aba
            {
              path: "nominatif-interbank-assets",
              component: Modules.NominatifInterbankAssets,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/nominatif-interbank-assets",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Akunting", "Nominatif ABA"],
              },
            },
            /* nominatif hapus buku */
            {
              path: "nominatif-write-off",
              component: Modules.NominatifWriteOff,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/nominatif-write-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Nominatif Hapus Buku",
                ],
              },
            },
            {
              path: "inter-office-account",
              component: Modules.MInterOfficeAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/inter-office-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Proses",
                  "Proses Data Center",
                  "Estimasi Baghas Rak",
                ],
              },
            },
            {
              path: "process-ppap",
              beforeEnter: ifAuthenticated,
              component: Modules.ProcessPpap,
              meta: {
                routerLink: "report/process-ppap",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Proses", "Hitung PPAP"],
              },
            },
            {
              path: "atm-channel",
              beforeEnter: ifAuthenticated,
              component: Modules.ReportAtmChannel,
              meta: {
                routerLink: "report/atm-channel",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Umum", "Laporan Kas ATM"],
              },
            },
            {
              path: "product-dailiy-opening",
              component: Modules.ProductDailiyOpening,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/product-dailiy-opening",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Umum",
                  "Pembukaan Produk Hari Ini",
                ],
              },
            },
            // position kas teller
            {
              path: "position-kas-teller",
              component: Modules.PositionKasTeller,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/position-kas-teller",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Teller", "Posisi Kas Teller"],
              },
            },
            {
              path: "laba-rugi",
              component: Modules.LabaRugi,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/laba-rugi",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Akunting", "Laba Rugi"],
              },
            },
            //Laporan Rahn
            {
              path: "report-rahn",
              component: Modules.ReportRahn,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/report-rahn",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Rahn", "Laporan Rahn"],
              },
            },
            // laporan denominatif pembiayaan
            {
              path: "denominatif",
              component: Modules.DenominatifPembiayaan,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/denominatif",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Denominatif Pembiayaan",
                ],
              },
            },
            {
              path: "cif-portfolio",
              component: Modules.CifPortofolio,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/cif-portfolio",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Umum", "Portofolio Nasabah"],
              },
            },
            {
              path: "estimate-colltability",
              component: Modules.EstimateCollectability,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/estimate-colltability",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Generate Estimasi Kolektabilitas",
                ],
              },
            },
            {
              path: "estimate-profit-share",
              component: Modules.EstimateProfitShare,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/estimate-profit-share",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Deposito",
                  "List Estimasi Bagi Hasil",
                ],
              },
            },
            {
              path: "loan-installment-payment-history",
              component: Modules.LoanInstallmentPaymentHistory,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/loan-installment-payment-history",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Portofolio History Pembayaran Angsuran",
                ],
              },
            },
            /* Reconcile SBB*/
            {
              path: "reconcile-sbb",
              component: Modules.ReconcileSBB,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/reconcile-sbb",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Reconcile SBB"],
              },
            },
            {
              path: "loan-dropping-report",
              component: Modules.LoanDroppingReport,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/loan-dropping-report",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Laporan Dropping Pembiayaan",
                ],
              },
            },
            /* Loan Settlement Report */
            {
              path: "loan-settlement-report",
              component: Modules.LoanSettlementReport,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/loan-settlement-report",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Laporan Pelunasan Pembiayaan",
                ],
              },
            },
            /* Report Estimasi Laba Rugi */
            {
              path: "estimasi-laba-rugi",
              component: Modules.ReportEstimasiLabaRugi,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/estimasi-laba-rugi",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Akunting",
                  "Laporan Estimasi Laba Rugi",
                ],
              },
            },
            /* Report Distribusi Bagi Hasil */
            {
              path: "distribution-profit-share",
              component: Modules.DistributionProfitShare,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/distribution-profit-share",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Akunting",
                  "Distribusi Bagi Hasil",
                ],
              },
            },
            {
              path: "neraca",
              component: Modules.Neraca,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/neraca",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Akunting", "Neraca"],
              },
            },
            {
              path: "sipesat",
              component: Modules.Sipesat,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/sipesat",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Apuppt", "Sipesat"],
              },
            },
            {
              path: "auditrail",
              component: Modules.ReportAuditrail,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/auditrail",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Auditrail"],
              },
            },
            {
              path: "fpr-print-out",
              component: Modules.PrintOutFpr,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/fpr-print-out",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Rahn", "Print Out FPR"],
              },
            },
            // daily transaction
            {
              path: "daily-transaction",
              component: Modules.DailyTransaction,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/daily-transaction",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Teller",
                  "Laporan Transaksi Harian",
                ],
              },
            },
            // daily transaction apuppt
            {
              path: "daily-transaction-apuppt",
              component: Modules.DailyTransactionApuppt,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/daily-transaction-apuppt",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Laporan Transaksi Apuppt"],
              },
            },
            // report collector funding
            {
              path: "collector-funding",
              component: Modules.ReportCollectorFunding,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/collector-funding",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Umum",
                  "Laporan Collector Funding",
                ],
              },
            },

            // daily transaction history
            {
              path: "daily-transaction-history",
              component: Modules.DailyTransactionHistory,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/daily-transaction-history",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Umum",
                  "Laporan Transaksi harian",
                ],
              },
            },
            // reporting-rekapitulasi-nominatif-pembiayaan
            {
              path: "nominatif-loan-product",
              beforeEnter: ifAuthenticated,
              component: Modules.NominatifPembiayaan,
              meta: {
                routerLink: "report/nominatif-loan-product",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Nominatif Pembiayaan",
                ],
              },
            },

            // nominatif pembiayaan by kontrak

            {
              path: "nominatif-loan-contract",
              beforeEnter: ifAuthenticated,
              component: Modules.NominatifLoanAccount,
              meta: {
                routerLink: "report/nominatif-loan-contract",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Pembiayaan",
                  "Nominatif Pembiayaan",
                ],
              },
            },
            {
              path: "nominatif-saving-account",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                // nominatif Tabungan by produk

                {
                  path: "product",
                  beforeEnter: ifAuthenticated,
                  component: Modules.NominatifSavingAccountByProduct,
                  meta: {
                    routerLink: "report/nominatif-saving-account/product",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Tabungan",
                      "Nominatif Tabungan",
                    ],
                  },
                },
                // nominatif Tabungan by No rek

                {
                  path: "account-number",
                  beforeEnter: ifAuthenticated,
                  component: Modules.NominatifSavingAccountByAccountNumber,
                  meta: {
                    routerLink:
                      "report/nominatif-saving-account/account-number",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Tabungan",
                      "Nominatif Tabungan Per Produk",
                    ],
                  },
                },
              ],
            },

            {
              path: "history-angsuran/detail",
              component: Modules.DetailHistoryAngsuran,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/history-angsuran/detail",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Laporan",
                  "History Angsuran",
                ],
              },
            },

            {
              path: "history-angsuran-recondition/detail",
              component: Modules.DetailHistoryAngsuranRecondition,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/history-angsuran-recondition/detail",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Pelunasan Sebagian",
                ],
              },
            },

            {
              path: "formulir-permohonan-rahn",
              component: Modules.FormulirPermohonanRahn,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/formulir-permohonan-rahn",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Cetak", "Formulir Permohonan RAHN"],
              },
            },

            // reporting-estimasi-kolektibilitas
            {
              path: "generate-estimate-collectability",
              beforeEnter: ifAuthenticated,
              component: Modules.GenerateEstimateCollectability,
              meta: {
                routerLink: "report/generate-estimate-collectability",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Reporting",
                  "Generate Estimasi Kolektabilitas",
                ],
              },
            },

            {
              path: "generate-report-collectability",
              beforeEnter: ifAuthenticated,
              component: Modules.GenerateReportCollectability,
              meta: {
                routerLink: "report/generate-report-collectability",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Reporting",
                  "Report Kolektabilitas",
                ],
              },
            },

            {
              path: "deposit-profit-share",
              beforeEnter: ifAuthenticated,
              component: Modules.ReportDepositProfitShare,
              meta: {
                routerLink: "report/deposit-profit-share",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Deposito",
                  "Laporan Bagi Hasil Deposito",
                ],
              },
            },

            {
              path: "sistem-informasi-debitur",
              redirect: "sistem-informasi-debitur/list",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "list",
                  component: Modules.SistemInformasiDebitur,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "report/sistem-informasi-debitur",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Umum",
                      "Sistem Informasi Debitur",
                    ],
                  },
                },
                {
                  path: "data",
                  beforeEnter: ifAuthenticated,
                  component: Modules.DataSistemInformasiDebitur,
                  meta: {
                    routerLink: "report/sistem-informasi-debitur",
                    isNeedReMount: false,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Umum",
                      "Sistem Informasi Debitur data",
                    ],
                  },
                },
              ],
            },
            {
              path: "reporting-trial-balance",
              beforeEnter: ifAuthenticated,
              component: Modules.ReportTrialBalance,
              meta: {
                routerLink: "report/reporting-trial-balance",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Reporting", "Akunting", "Trial Balance"],
              },
            },
            // recapitulation teller
            {
              path: "recapitulation-teller",
              component: Modules.RecapitulationTeller,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "report/recapitulation-teller",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Reporting",
                  "Teller",
                  "Rekapitulasi Transaksi Harian",
                ],
              },
            },
          ],
        },
        // loan
        {
          path: "loan",
          redirect: "loan/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              component: Modules.ListMLoan,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Pembiayaan",
                  "Tabel Produk Pembiayaan",
                ],
              },
            },
            {
              path: "add",
              component: Modules.AddMLoan,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Pembiayaan",
                  "Produk Pembiayaan",
                  "Tambah Produk Pembiayaan",
                ],
              },
            },
            {
              path: "edit",
              component: Modules.EditMLoan,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Pembiayaan",
                  "Produk Pembiayaan",
                  "Edit Produk Pembiayaan",
                ],
              },
            },
            {
              path: "detail",
              component: Modules.DetailMLoan,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Pembiayaan",
                  "Produk Pembiayaan",
                  "Detail Produk Pembiayaan",
                ],
              },
            },
            {
              path: "installment-correction",
              component: Modules.TLoanInstallmentCorrection.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/installment-correction",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Koreksi Angsuran",
                ],
              },
            },
            {
              path: "installment-correction/new",
              component: Modules.TLoanInstallmentCorrection.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/installment-correction",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Form Koreksi Angsuran",
                ],
              },
            },
            {
              path: "installment-correction/:id",
              component: Modules.TLoanInstallmentCorrection.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/installment-correction",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Koreksi Angsuran",
                ],
              },
            },

            // atmr
            {
              path: "atmr",
              component: Modules.MRatioAtmr,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/atmr",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Kesehatan Bank",
                  "Master ATMR",
                ],
              },
            },
            // ratio car
            {
              path: "ratio-car",
              component: Modules.RatioCar,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/ratio-car",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Kesehatan Bank",
                  "CAR Configuration",
                ],
              },
            },

            /* Pindah cabang pembiayaan */
            {
              path: "transfer-office",
              component: Modules.LoanTransferOffice,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/transfer-office",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Pindah Kantor Pembiayaan",
                ],
              },
            },

            /* Update Account Officer */
            {
              path: "update-account-officer",
              component: Modules.UpdateAccountOfficer,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/update-account-officer",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Pindah Account Officer",
                ],
              },
            },

            /* Loan Transfer Product */

            {
              path: "transfer-product",
              component: Modules.loanTransferProduct,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/transfer-product",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Pindah Produk Pembiayaan",
                ],
              },
            },

            /* update ao, office, sub group */
            {
              path: "update-bulk",
              component: Modules.UpdateAoOfficeSubGroup,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/update-bulk",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Update AO, Office, Sub Group Pembiayaan",
                ],
              },
            },

            // MORTGAGE

            {
              path: "mortgage",
              component: Modules.MMortgage.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Gadai",
                  "Tabel Setup Gadai Karat",
                ],
              },
            },
            {
              path: "mortgage/new",
              component: Modules.MMortgage.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Dan Parameter",
                  "Parameter Gadai",
                  "Tambah Setup Gadai Karat",
                ],
              },
            },
            {
              path: "mortgage/:id",
              component: Modules.MMortgage.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup Dan Parameter",
                  "Parameter Gadai",
                  "Rincian Setup Gadai Karat",
                ],
              },
            },

            {
              path: "mortgage-slthe",
              redirect: "mortgage-slthe/list",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "list",
                  component: Modules.MMortgageSlthe.List,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/mortgage-slthe",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Setup Dan Parameter",
                      "Parameter Gadai",
                      "Tabel Harga SLTHE",
                    ],
                  },
                },
                {
                  path: "add",
                  component: Modules.MMortgageSlthe.Add,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/mortgage-slthe",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Setup Dan Parameter",
                      "Parameter Gadai",
                      "Tambah Harga SLTHE",
                    ],
                  },
                },
                {
                  path: "edit",
                  component: Modules.MMortgageSlthe.Edit,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/mortgage-slthe",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Setup Dan Parameter",
                      "Parameter Gadai",
                      "Edit Harga SLTHE",
                    ],
                  },
                },
                {
                  path: "detail",
                  component: Modules.MMortgageSlthe.Detail,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/mortgage-slthe",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Setup Dan Parameter",
                      "Parameter Gadai",
                      "Detail Harga SLTHE",
                    ],
                  },
                },
              ],
            },
            // harga pasaran emas

            {
              path: "mortgage-metal-price",
              component: Modules.MMortgageMetalPrice,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage-metal-price",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Gadai",
                  "Pasaran Emas",
                ],
              },
            },
            {
              path: "mortgage-register-number",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "inventory",
                  component: Modules.Sbr.List,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/mortgage-register-number/inventory",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: ["Pelayanan", "Rahn", "Register SBR"],
                  },
                },
                {
                  path: "inventory/new",
                  component: Modules.Sbr.Create,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/mortgage-register-number/inventory",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Rahn",
                      "Tambah Register SBR",
                    ],
                  },
                },
                {
                  path: "inventory/:id",
                  component: Modules.Sbr.Existing,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/mortgage-register-number/inventory",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Rahn",
                      "Rincian Register SBR",
                    ],
                  },
                },
              ],
            },
            // loan-paid

            {
              path: "loan-paid",
              component: Modules.TLoanPaid.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-paid",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pembiayaan",
                  "Transaksi",
                  "Tabel Pelunasan Pembiayaan",
                ],
              },
            },
            {
              path: "loan-paid/new",
              component: Modules.TLoanPaid.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-paid",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pembiayaan",
                  "Transaksi",
                  "Tambah Pelunasan Pembiayaan",
                ],
              },
            },
            {
              path: "loan-paid/:id",
              component: Modules.TLoanPaid.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-paid",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pembiayaan",
                  "Transaksi",
                  "Rincian Pelunasan Pembiayaan",
                ],
              },
            },

            // loan-paid/simulation
            {
              path: "loan-paid-simulation",
              component: Modules.TLoanPaidSimulation,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-paid-simulation",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Simulasi",
                  "Simulasi Pelunasan Pembiayaan",
                ],
              },
            },
            // mortgage paid off

            {
              path: "mortgage-paid-off",
              component: Modules.TMortgagePaidOff.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage-paid-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "List Pelunasan Rahn",
                ],
              },
            },
            {
              path: "mortgage-paid-off/new",
              component: Modules.TMortgagePaidOff.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage-paid-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Transaksi", "Pelunasan Rahn"],
              },
            },
            {
              path: "mortgage-paid-off/:id",
              component: Modules.TMortgagePaidOff.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage-paid-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Pelunasan Rahn",
                ],
              },
            },
            // write off
            {
              path: "write-off",
              component: Modules.WriteOff.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/write-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "List Hapus Buku",
                ],
              },
            },
            {
              path: "write-off/new",
              component: Modules.WriteOff.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/write-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tambah Hapus Buku",
                ],
              },
            },
            {
              path: "write-off/:id",
              component: Modules.WriteOff.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/write-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Rincian Hapus Buku",
                ],
              },
            },
            /* Transaksi Hapus Buku */
            {
              path: "loan-write-off",
              component: Modules.TLoanWriteOff.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-write-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "List Transaksi Hapus Buku",
                ],
              },
            },
            {
              path: "loan-write-off/:id",
              component: Modules.TLoanWriteOff.Detail,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-write-off",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Rincian Transaksi Hapus Buku",
                ],
              },
            },

            {
              path: "collateral-group",
              component: Modules.CollateralGroup.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/collateral-group",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Pembiayaan",
                  "Kelompok Jaminan",
                ],
              },
            },
            {
              path: "collateral-group/new",
              component: Modules.CollateralGroup.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/collateral-group",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Pembiayaan",
                  "Form Kelompok Jaminan",
                ],
              },
            },
            {
              path: "collateral-group/:id",
              component: Modules.CollateralGroup.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/collateral-group",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup Parameter",
                  "Parameter Pembiayaan",
                  "Rincian Kelompok Jaminan",
                ],
              },
            },
            {
              path: "loan-account",
              component: Modules.ListTLoanAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Penyaluran",
                  "Tabel Kontrak Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account/new",
              component: Modules.AddTLoanAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Penyaluran",
                  "Buat Kontrak Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account/:id",
              component: Modules.EditTLoanAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Penyaluran",
                  "Rincian Kontrak Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-recondition",
              component: Modules.ListTLoanAccountRecondition,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-recondition",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Pelunasan Sebagian",
                  "Tabel Pelunasan Sebagian",
                ],
              },
            },
            {
              path: "loan-account-recondition/new",
              component: Modules.AddTLoanAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-recondition",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Buka Pembiayaan",
                  "Buat Kontrak Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-recondition/:id",
              component: Modules.EditTLoanAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-recondition",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Buka Pembiayaan",
                  "Rincian Kontrak Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-reschedule",
              component: Modules.ListTLoanAccountReschedule,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-reschedule",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Tabel Reschedule Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-reschedule/new",
              component: Modules.AddTLoanAccountReschedule,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-reschedule",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Buat Reschedule Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-reschedule/:id",
              component: Modules.EditTLoanAccountReschedule,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-reschedule",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Rincian Reschedule Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-restructure",
              component: Modules.ListTLoanAccountRestructurisation,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-restructure",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Tabel Restrukturisasi Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-restructure/new",
              component: Modules.AddTLoanAccountRestructurisation,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-restructure",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Form Restrukturisasi Pembiayaan",
                ],
              },
            },
            {
              path: "loan-account-restructure/:id",
              component: Modules.EditTLoanAccountRestructurisation,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-account-restructure",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Rincian Restrukturisasi Pembiayaan",
                ],
              },
            },
            {
              path: "mortgage-account",
              component: Modules.ListTMortageAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Rahn", "Tabel Kontrak Rahn"],
              },
            },
            {
              path: "mortgage-account/new",
              component: Modules.AddTMortageAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Rahn", "Buat Kontrak Rahn"],
              },
            },
            {
              path: "mortgage-account/:id",
              component: Modules.EditTMortageAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/mortgage-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Rahn",
                  "Rincian Kontrak Rahn",
                ],
              },
            },
            // loan collectability

            {
              path: "loan-collectability",
              component: Modules.MLoanCollectibilty.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-collectability",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Setup & Parameter",
                  "Parameter Pembiayaan",
                  "Kolektabilitas",
                ],
              },
            },
            {
              path: "loan-collectability/new",
              component: Modules.MLoanCollectibilty.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-collectability",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup & Parameter",
                  "Parameter Pembiayaan",
                  "Tambah Kolektabilitas",
                ],
              },
            },
            {
              path: "loan-collectability/:id",
              component: Modules.MLoanCollectibilty.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-collectability",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Setup & Parameter",
                  "Parameter Pembiayaan",
                  "Rincian Kolektabilitas",
                ],
              },
            },

            // loan collectability justifikasi

            {
              path: "loan-collectability-justifikasi",
              redirect: "loan-collectability-justifikasi/list",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "list",
                  component: Modules.TLoanCollectibiltyJustifikasi.List,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/loan-collectability-justifikasi",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Pembiayaan",
                      "Justifikasi",
                    ],
                  },
                },
                {
                  path: "add",
                  component: Modules.TLoanCollectibiltyJustifikasi.Add,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/loan-collectability-justifikasi",
                    isNeedReMount: false,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Pembiayaan",
                      "Justifikasi",
                    ],
                  },
                },
                {
                  path: "edit",
                  component: Modules.TLoanCollectibiltyJustifikasi.Edit,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/loan-collectability-justifikasi",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Pembiayaan",
                      "Justifikasi",
                    ],
                  },
                },
                {
                  path: "detail",
                  component: Modules.TLoanCollectibiltyJustifikasi.Detail,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/loan-collectability-justifikasi",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Pembiayaan",
                      "Justifikasi",
                    ],
                  },
                },
              ],
            },
            // t-loan collectability report
            {
              path: "loan-collectability-report",
              redirect: "loan-collectability-report/list",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "list",
                  component: Modules.ListTLoanCollectibiltyReports,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/loan-collectability-report",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Reporting",
                      "Pembiayaan",
                      "Laporan Kolektabilitas",
                    ],
                  },
                },
              ],
            },

            {
              path: "loan-sp3",
              beforeEnter: ifAuthenticated,
              component: Modules.TLoanSp3.List,
              meta: {
                routerLink: "loan/loan-sp3",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Penyaluran",
                  "Tabel SP3",
                ],
              },
            },

            {
              path: "loan-sp3/new",
              component: Modules.TLoanSp3.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-sp3",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Penyaluran",
                  "Tambah Data SP3",
                ],
              },
            },
            {
              path: "loan-sp3/:id",
              component: Modules.TLoanSp3.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-sp3",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Penyaluran",
                  "Rincian Data SP3",
                ],
              },
            },
            {
              path: "loan-sp3-restructure",
              component: Modules.TLoanSp3Restructure.List,
              beforeEnter: ifAuthenticated,

              meta: {
                routerLink: "loan/loan-sp3-restructure",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Tabel SP3 Resctructure",
                ],
              },
            },
            {
              path: "loan-sp3-restructure/new",
              component: Modules.TLoanSp3Restructure.Create,
              beforeEnter: ifAuthenticated,

              meta: {
                routerLink: "loan/loan-sp3-restructure",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Tambah SP3 Resctructure",
                ],
              },
            },
            {
              path: "loan-sp3-restructure/:id",
              component: Modules.TLoanSp3Restructure.Existing,
              beforeEnter: ifAuthenticated,

              meta: {
                routerLink: "loan/loan-sp3-restructure",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Rincian SP3 Resctructure",
                ],
              },
            },
            {
              path: "loan-sp3-reschedule",
              component: Modules.TLoanSp3Reschedule.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-sp3-reschedule",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Tabel SP3 Reschedule",
                ],
              },
            },
            {
              path: "loan-sp3-reschedule/new",
              component: Modules.TLoanSp3Reschedule.Create,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-sp3-reschedule",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Tambah SP3 Reschedule",
                ],
              },
            },
            {
              path: "loan-sp3-reschedule/:id",
              component: Modules.TLoanSp3Reschedule.Existing,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "loan/loan-sp3-reschedule",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Pembiayaan",
                  "Restrukturisasi",
                  "Rincian SP3 Reschedule",
                ],
              },
            },
            // loan installment manual
            {
              path: "installment",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "simulation",
                  component: Modules.TLoanInstallmentSimulation,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/installment/simulation",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Pembiayaan",
                      "Simulasi",
                      "Simulasi Angsuran",
                    ],
                  },
                },
                {
                  path: "anuity-simulation",
                  component: Modules.TLoanInstallmentAnuitySimulation,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/installment/anuity-simulation",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Pembiayaan",
                      "Simulasi",
                      "Simulasi Angsuran Efektif Anuitas",
                    ],
                  },
                },

                {
                  path: "manual-payment",
                  component: Modules.TLoanInstallmentManualPayment.List,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/installment/manual-payment",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Transaksi",
                      "Tabel Debet Manual Angsuran Pembiayaan",
                    ],
                  },
                },
                {
                  path: "manual-payment/new",
                  component: Modules.TLoanInstallmentManualPayment.Create,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/installment/manual-payment",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Transaksi",
                      "Tambah Debet Manual Angsuran Pembiayaan",
                    ],
                  },
                },
                {
                  path: "manual-payment/:id",
                  component: Modules.TLoanInstallmentManualPayment.Existing,
                  beforeEnter: ifAuthenticated,
                  meta: {
                    routerLink: "loan/installment/manual-payment",
                    isNeedReMount: true,
                  },
                  props: {
                    breadcrumbElement: [
                      "Pelayanan",
                      "Transaksi",
                      "Rincian Debet Manual Angsuran Pembiayaan",
                    ],
                  },
                },
                {
                  path: "restructuration",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "simulation",
                      component:
                        Modules.TLoanInstallmentSimulationRestructuration,
                      beforeEnter: ifAuthenticated,
                      meta: {
                        routerLink:
                          "loan/installment/restructuration/simulation",
                        isNeedReMount: true,
                      },
                      props: {
                        breadcrumbElement: [
                          "Pelayanan",
                          "Pembiayaan",
                          "Simulasi",
                          "Simulasi Angsuran Restrukturisasi",
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            // loan droping overbook
            {
              path: "dropping",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "overbook",
                  redirect: "overbook/list",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "list",
                      component: Modules.TLoanAccountDroppingOverbook.List,
                      beforeEnter: ifAuthenticated,
                      meta: {
                        routerLink: "loan/dropping/overbook",
                        isNeedReMount: true,
                      },
                      props: {
                        breadcrumbElement: [
                          "Pembiayaan",
                          "Transaksi",
                          "Table Overbook",
                        ],
                      },
                    },
                    {
                      path: "add",
                      component: Modules.TLoanAccountDroppingOverbook.Add,
                      beforeEnter: ifAuthenticated,
                      meta: {
                        routerLink: "loan/dropping/overbook",
                        isNeedReMount: true,
                      },
                      props: {
                        breadcrumbElement: [
                          "Pembiayaan",
                          "Transaksi",
                          "Tambah Overbook",
                        ],
                      },
                    },
                    {
                      path: "detail",
                      component: Modules.TLoanAccountDroppingOverbook.Detail,
                      beforeEnter: ifAuthenticated,
                      meta: {
                        routerLink: "loan/dropping/overbook",
                        isNeedReMount: true,
                      },
                      props: {
                        breadcrumbElement: [
                          "Pembiayaan",
                          "Transaksi",
                          "Detail Overbook",
                        ],
                      },
                    },
                    {
                      path: "edit",
                      component: Modules.TLoanAccountDroppingOverbook.Edit,
                      beforeEnter: ifAuthenticated,
                      meta: {
                        routerLink: "loan/dropping/overbook",
                        isNeedReMount: true,
                      },
                      props: {
                        breadcrumbElement: [
                          "Pembiayaan",
                          "Transaksi",
                          "Edit Overbook",
                        ],
                      },
                    },
                    {
                      path: "detail",
                      component: Modules.TLoanAccountDroppingOverbook.Detail,
                      beforeEnter: ifAuthenticated,
                      meta: {
                        routerLink: "loan/dropping/overbook",
                        isNeedReMount: true,
                      },
                      props: {
                        breadcrumbElement: [
                          "Pembiayaan",
                          "Dropping",
                          "Detail Overbook",
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        // process-ppap

        // virtual-account
        {
          path: "virtual-account",
          redirect: "virtual-account/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              component: Modules.listVirtualAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "virtual-account",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Tabel Virtual Account",
                ],
              },
            },
            {
              path: "detail",
              component: Modules.DetailVirtualAccount,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "virtual-account",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Detail Virtual Account",
                ],
              },
            },
          ],
        },
        {
          path: "virtual-account-ppob",
          redirect: "virtual-account-ppob/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              component: Modules.ListTVirtualAccountPpob,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "virtual-account-ppob",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Transaksi Pembayaran Ppob",
                ],
              },
            },
            {
              path: "payment",
              component: Modules.PaymentTVirtualAccountPpob,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "virtual-account-ppob",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Form Pembayaran Ppob",
                ],
              },
            },
            {
              path: "detail",
              component: Modules.DetailTVirtualAccountPpob,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "virtual-account-ppob",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: ["Pelayanan", "Transaksi", "Detail Ppob"],
              },
            },
          ],
        },
        // Cash Collection
        {
          path: "cash-collection",
          redirect: "cash-collection/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              component: Modules.TCashCollection.List,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "cash-collection",
                isNeedReMount: true,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "List Kas Kolektor",
                ],
              },
            },
            {
              path: "add",
              component: Modules.TCashCollection.Add,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "cash-collection",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Add Kas Kolektor",
                ],
              },
            },
            {
              path: "detail",
              component: Modules.TCashCollection.Detail,
              beforeEnter: ifAuthenticated,
              meta: {
                routerLink: "cash-collection",
                isNeedReMount: false,
              },
              props: {
                breadcrumbElement: [
                  "Pelayanan",
                  "Transaksi",
                  "Detail Kas Kolektor",
                ],
              },
            },
          ],
        },
      ],
    },
    {
      path: "*",
      component: Modules.Page404,
    },
    {
      path: "/pages",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Modules.Login,
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: "rnd",
          name: "Rnd",
          component: Modules.Rnd,
        },
      ],
    },
  ];
}

export default router;
