import ListTLoanWriteOff from "../list";

export default {
  name: "DetailTLoanWriteOff",
  components: {
    ListTLoanWriteOff,
  },
  data() {
    return {
      property: {
        animation: {
          isLoading: false,
        },
      },
      indentity: {
        mainId: "",
      },
      form: {
        cifIdName: "",
        loanAccountNumber: "",
        accountNumber: "",
        nominal: "",
        balanceUsable: "",
        isEditable: false,
        loanAccountId: "",
      },
    };
  },
  mounted() {
    this.getIdentityFromSession();
  },
  methods: {
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_WRITE_OFF");
      if (getTokenFromSession) {
        this.indentity.mainId = getTokenFromSession;
        console.log(getTokenFromSession);
        this.getLoanWriteOffByID();
      }
    },
    routeToPageListTLoanWriteOff() {
      this.$router.push("/loan/loan-write-off");
    },
    async getLoanWriteOffByID() {
      console.log("consol");
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl: "write-off-transaction/" + this.indentity.mainId,
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.form.cifIdName = resp.data.data.cifIdName;
          this.form.loanAccountNumber = resp.data.data.loanAccountNumber;
          this.form.accountNumber = resp.data.data.accountNumber;
          this.form.balanceUsable = resp.data.data.balanceUsable;
          this.form.outstanding = resp.data.data.outstanding;
          this.form.nominal = resp.data.data.nominal;
          this.form.isEditable = resp.data.data.isEditable;
          this.form.loanAccountId = resp.data.data.loanAccountId;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListTLoanWriteOff(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.routeToPageListTLoanWriteOff(),
        });
      }
    },
    async editLoanWriteOff() {
      const data = {
        loanAccountId: this.form.loanAccountId,
        nominal: this.form.nominal,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.isLoading = true;
            try {
              console.log('1')
              // const resp = await this.$store.dispatch({
              //   type: "PUT_DATA",
              //   endPoint: "loan",
              //   reqUrl:
              //     "write-off-transaction/installment/" + this.identity.mainId,
              //   payload: data,
              // });
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "write-off-transaction/installment/" + this.indentity.mainId,
                payload: data,
              })
              console.log('2')
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  this.routeToPageListTLoanWriteOff();
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
