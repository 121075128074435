export default {
  name: "ListGeneratePinAtm",
  data() {
    return {
      dataForm: {
        checkingGeneratePinAtm: {
          accountNumber: "",
          nama: "",
        },
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        filterDto: {
          savingCardId: "",
          accountNumber: "",
          cifIdName: "",
        },
        modal: {
          showModalCheckingGeneratePinAtm: false,
          showModalResultListGeneratePinAtm: false,
        },
        animation: {
          CheckingGeneratePinAtm: {
            isLoading: false,
          },
        },
        listElement: {
          generatePinAtm: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
      table: {
        data: {
          generatePinAtm: [],
          savingAccount: [],
        },
      },
    };
  },
  methods: {
    routeToPageCreate(props) {
      this.$router.push(
        `/saving-card/new?refId=${this.encryptBASE64(
          JSON.stringify({
            savingAccountId: props.row.savingAccountId,
          })
        )}`
      );
    },
    routeToPageRincian(props) {
      this.$router.push(
        `/saving-card/${this.encryptBASE64(`${props.row.savingCardId}`)}`
      );
    },
    async findGeneratePinAtm() {
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-card",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            savingCardId: this.property.filterDto.savingCardId,
            page: 0,
            size: this.property.listElement.generatePinAtm.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.generatePinAtm = resp.data.data.content;
            this.property.listElement.generatePinAtm.rows =
              resp.data.data.totalElements;
            this.property.listElement.generatePinAtm.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataGeneratePinAtm(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetGeneratePinAtm(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.generatePinAtm.downloading = false;
        }, timeout);
      }
    },
    async getDataTableGeneratePinAtm() {
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-card",
          payload: {
            savingCardId: this.property.filterDto.savingCardId,
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.generatePinAtm = resp.data.data.content;
            this.property.listElement.generatePinAtm.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataGeneratePinAtm(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetGeneratePinAtm(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.generatePinAtm.downloading = false;
        }, timeout);
      }
    },
    async changePaginationGeneratePinAtm(event) {
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-card",
          payload: {
            savingCardId: this.property.filterDto.savingCardId,
            page: event - 1,
            size: this.property.listElement.generatePinAtm.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.generatePinAtm = resp.data.data.content;
            this.property.listElement.generatePinAtm.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataGeneratePinAtm(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetGeneratePinAtm(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.generatePinAtm.downloading = false;
        }, timeout);
      }
    },
    async deleteDataGeneratePinAtm(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "saving-card/" + props.row.savingCardId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.generatePinAtm.currentPage = 1;
                  this.property.filterDto.savingCardId = "";
                  this.property.listElement.generatePinAtm.rows = 0;
                  this.getDataTableGeneratePinAtm();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async authorizedGeneratePinAtm(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "saving-card/authorized/" + props.row.savingCardId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.savingCardId = "";
                  this.property.listElement.generatePinAtm.rows = 0;
                  this.getDataTableGeneratePinAtm();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    failedGetDataGeneratePinAtm(resp) {
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.rows = 0;
      this.property.listElement.generatePinAtm.message = resp.data.message;
    },
    handleErrorGetGeneratePinAtm(error) {
      console.log(error.response);
      this.table.data.generatePinAtm = [];
      this.property.listElement.generatePinAtm.rows = 0;
      this.property.listElement.generatePinAtm.currentPage = 1;
      this.property.listElement.generatePinAtm.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    handleCloseModalCheckingGeneratePinAtm() {
      this.property.modal.showModalCheckingGeneratePinAtm = false;
      this.handleClearModalCheckingGeneratePinAtm();
    },
    handleClearModalCheckingGeneratePinAtm() {
      this.dataForm.checkingGeneratePinAtm.accountNumber = "";
      this.dataForm.checkingGeneratePinAtm.nama = "";
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.content;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetSavingAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetSavingAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.savingAccount.downloading = false;
        }, timeout);
      }
    },
    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.accountNumber = this.dataForm.checkingGeneratePinAtm.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.dataForm.checkingGeneratePinAtm.nama;
        this.dataForm.checkingGeneratePinAtm.accountNumber = "";
        this.dataForm.checkingGeneratePinAtm.nama = "";
        const timeout = 500;
        try {
          this.property.animation.CheckingGeneratePinAtm.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.savingAccount = resp.data.data.content;
              this.property.modal.showModalCheckingGeneratePinAtm = false;
              this.property.modal.showModalResultListGeneratePinAtm = true;
              this.property.listElement.savingAccount.rows =
                resp.data.data.totalElements;
            } else if (resp.data.code === "DATA NOT FOUND") {
              this.session.checkingSavingAccount.accountNumber = this.dataForm.checkingGeneratePinAtm.accountNumber;
              this.dataForm.checkingGeneratePinAtm.accountNumber = "";
              this.session.checkingSavingAccount.cifIdName = this.dataForm.checkingGeneratePinAtm.nama;
              this.dataForm.checkingGeneratePinAtm.nama = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `Nomor Rekening Tidak Tersedia!`,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.session.checkingSavingAccount.accountNumber = this.dataForm.checkingGeneratePinAtm.accountNumber;
              this.dataForm.checkingGeneratePinAtm.accountNumber = "";
              this.session.checkingSavingAccount.cifIdName = this.dataForm.checkingGeneratePinAtm.nama;
              this.dataForm.checkingGeneratePinAtm.nama = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, timeout);
        } catch (error) {
          console.log(error.response);
          setTimeout(() => {
            this.session.checkingSavingAccount.accountNumber = this.dataForm.checkingSavingAccount.accountNumber;
            this.dataForm.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.dataForm.checkingSavingAccount.nama;
            this.dataForm.checkingSavingAccount.nama = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.animation.CheckingGeneratePinAtm.isLoading = false;
          }, timeout);
        }
      }
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
  },
  mounted() {
    this.getDataTableGeneratePinAtm();
  },
};
