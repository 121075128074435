export default {
  name: "FormMTransactionCode",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      options: {
        digunakanPada: [],
        journalAllocation: [],
        cetakValidasi: [],
        cetakTransaksiKeBuku: [],
        modul: [],
        otorisasiDiperlukan: [],
        transaksiRekap: [],
      },
      dataForm: {
        transactionCode: "",
        isModul: "",
        transactionName: "",
        subledgerDebt: "",
        positionDebt: "",
        subledgerCredit: "",
        positionCredit: "",
        isAuthorization: "",
        usedOnId: "",
        isTransactionRecap: "",
        isValidationPrint: "",
        transactionBookCode: "",
        transactionBookPrint: "",
        transactionBookDescription: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      property: {
        animation: {
          addTransactionCode: {
            isLoading: false,
          },
        },
      },
    };
  },
  methods: {
    routeToPageListTransactionCode() {
      this.$router.push("/parameter/transaction-code");
    },
    async getReferenceUsedOn() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "used-on",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.usedOnName;
            const value = index.usedOnId;
            this.options.digunakanPada.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceJournalAllocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "journal-allocation",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.journalAllocationName;
            const value = i.journalAllocationId;
            this.options.journalAllocation.push({ text, value });
          });
        }
      } catch (error) {}
    },
    appendDefaultOptionSelect() {
      this.options.cetakValidasi.push(
        { text: "Tidak", value: "N" },
        { text: "Iya", value: "Y" }
      );
      this.options.cetakTransaksiKeBuku.push(
        { text: "Tidak", value: "N" },
        { text: "Iya", value: "Y" }
      );
      this.options.modul.push(
        { text: "Tidak", value: "N" },
        { text: "Iya", value: "Y" }
      );
      this.options.otorisasiDiperlukan.push(
        { text: "Tidak", value: "N" },
        { text: "Iya", value: "Y" }
      );
      this.options.transaksiRekap.push(
        { text: "Tidak", value: "N" },
        { text: "Iya", value: "Y" }
      );
      this.options.digunakanPada.push({ value: "", text: "-- Pilih --" });
      this.options.journalAllocation.push({ value: "", text: "-- Pilih --" });
    },
    appendDefaultInput() {
      this.dataForm.isValidationPrint = "N";
      this.dataForm.transactionBookPrint = "N";
      this.dataForm.isModul = "N";
      this.dataForm.isAuthorization = "N";
      this.dataForm.isTransactionRecap = "N";
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.transactionCode = payload.transactionCode;
        this.dataForm.isModul = payload.isModul;
        this.dataForm.transactionName = payload.transactionName;
        this.dataForm.subledgerDebt = payload.subledgerDebt;
        this.dataForm.positionDebt = payload.positionDebt;
        this.dataForm.subledgerCredit = payload.subledgerCredit;
        this.dataForm.positionCredit = payload.positionCredit;
        this.dataForm.isAuthorization = payload.isAuthorization;
        this.dataForm.isTransactionRecap = payload.isTransactionRecap;
        this.dataForm.isValidationPrint = payload.isValidationPrint;
        this.dataForm.transactionBookCode = payload.transactionBookCode;
        this.dataForm.transactionBookPrint = payload.transactionBookPrint;
        this.dataForm.usedOnId = payload.usedOnId;
        this.dataForm.transactionBookDescription =
          payload.transactionBookDescription;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitTransactionCode() {
      this.$emit("submitTransactionCode", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceUsedOn();
    this.getReferenceJournalAllocation();
    this.appendDefaultOptionSelect();
    this.appendDefaultInput();
  },
};
