export default {
  name: "DetailTCashCollection",
  data() {
    return {
      identity: {
        multiAccountId: "",
      },
      identifier: {
        targetAccountSelectedForEdit: "",
      },
      property: {},
      dataForm: {
        file: null,
        nominal: 0,
        transactionCode: "",
        transactionDate: "",
        description: "",
        sourceAccountNumber: "",
        cifIdName: "",
        accountType: "",
        accountTypeMulti: "",
        cifOfficeName: "",
        documentProofNumber: "",
        detail: [],
        collectorId: "",
      },
      options: {
        transactionCode: [],
        interbankAssets: [],
        collector: [],
        accountType: [
          {
            text: "-- Pilih --",
            value: "",
            disabled: false,
          },
          {
            text: "Rekening Tabungan",
            value: "TAB",
            disabled: false,
          },
          {
            text: "ABA",
            value: "ABA",
            disabled: false,
          },
          {
            text: "SBB ",
            value: "SBB",
            disabled: true,
          },
        ],
      },
    };
  },
  methods: {
    changeAccountTypeSource() {
      this.dataForm.sourceAccountNumber = "";
      this.dataForm.cifIdName = "";
      this.dataForm.cifOfficeName = "";
    },
    routeToPageListMultiAccount() {
      this.$router.push("list");
    },
    async getMultiAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListMultiAccount();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "t-multi-account/cash-collection/" + this.identity.multiAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.cifIdName = resp.data.data.header.qqName
              ? `${resp.data.data.header.qqName} ${resp.data.data.header.qqCode} ${resp.data.data.header.accountName}`
              : resp.data.data.header.accountName;
            this.dataForm.cifOfficeName = resp.data.data.header.accountBranch;
            this.dataForm.nominal = resp.data.data.header.nominal;
            this.dataForm.transactionCode =
              resp.data.data.header.mtransactionCode.transactionCode;
            this.dataForm.transactionDate =
              resp.data.data.header.transactionDate;
            this.dataForm.description = resp.data.data.header.description;
            this.dataForm.sourceAccountNumber =
              resp.data.data.header.accountNumber;
            this.dataForm.documentProofNumber =
              resp.data.data.header.documentProofNumber;
            this.dataForm.accountType = resp.data.data.header.accountType;
            this.dataForm.collectorId = resp.data.data.header.collector
              ? resp.data.data.header.collector.userId
              : "";
            this.insertDetailData(resp);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListMultiAccount(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListMultiAccount(),
          });
        }
      }
    },
    insertDetailData(resp) {
      resp.data.data.detail.map((index) => {
        const nominal = index.nominal;
        const description = index.description;
        const destinationAccountNumber = index.accountNumber;
        const cifIdName = index.qqName
          ? `${index.accountName} ${index.qqCode} ${index.qqName}`
          : index.accountName;
        const accountType = index.accountType;
        this.dataForm.detail.push({
          nominal,
          description,
          destinationAccountNumber,
          cifIdName,
          accountType,
        });
      });
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("MULTI_ACCOUNT_ID");
      this.identity.multiAccountId = getTokenFromSession;
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              name: "",
              page: 0,
              menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_MENU_ACCESS_MULTI_ACCOUNT,
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCode;
            const description = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.transactionCode.push({
              text,
              value,
              description,
              transactionCode,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceInterbankAsset() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if ((resp.data.code = "SUCCESS")) {
          this.options.interbankAssets = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.interbankAssetsId;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = index.accountName;
            const accountBranch = `${index.bankName} - Cabang ${index.accountBranch}`;
            this.options.interbankAssets.push({
              text,
              value,
              accountName,
              accountBranch,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceCollector() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.collector.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.getMultiAccountById();
    this.getReferenceTransactionCode();
    this.getReferenceInterbankAsset();
    this.getReferenceCollector();
  },
};
