export default {
  name: "UMB",
  data() {
    return {
      table: {
        data: {
          umb: [],
        },
      },

      property: {
        listElement: {
          umb: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {},
      },
    };
  },
  methods: {
    routeTopageAdd() {
      this.$router.push("/parameter/umb/new");
    },
  },
};
