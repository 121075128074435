import FormProductLedger from "../form";
export default {
  name: "CreateProductLedger",
  components: {
    FormProductLedger,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    async addproductLedger(payload) {
      const data = {
        productLedgerId: payload.productLedgerId,
        productLedgerCode: payload.productLedgerCode,
        productLedgerName: payload.productLedgerName,
        description: payload.description,
        productCategoryId: payload.productCategoryId,
        productCategoryCode: payload.productCategoryCode,
        productCategoryName: payload.productCategoryName,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formMProductLedgerRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl: "product-ledger",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formMProductLedgerRef.routeToPageList();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formMProductLedgerRef.property.animation.submitLoading = false;
      }
    },
  },
};
