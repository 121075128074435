import FormMLoanCollectability from "../form";

export default {
  name: "ExistingMLoanCollectability",
  components: {
    FormMLoanCollectability,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      formMessage: {},
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getLoanCollectabilityById();
    },
    async getLoanCollectabilityById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formMLoanCollectabilityRef.routeToPageListLoanCollectability();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "loan-collectability/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formMLoanCollectabilityRef.getDefaultFormValue(
              resp.data.data
            );
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formMLoanCollectabilityRef.routeToPageListLoanCollectability(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formMLoanCollectabilityRef.routeToPageListLoanCollectability(),
          });
        }
      }
    },
    async editLoanCollectability(payload) {
      const data = {
        daysInArrearsBeforeMax: payload.daysInArrearsBeforeMax,
        daysInArrearsBeforeMin: payload.daysInArrearsBeforeMin,
        daysInArrearsAfterMin: payload.daysInArrearsAfterMin,
        daysInArrearsAfterMax: payload.daysInArrearsAfterMax,
        loanCollectabilityStatusId: payload.loanCollectabilityStatusId,
        collectabilityCodeId: payload.collectabilityCodeId,
        daysInArrearsBefore: payload.daysInArrearsBefore,
        daysInArrearsAfter: payload.daysInArrearsAfter,
        weight: payload.weight,
        collectabilityIncomeTypeId: payload.collectabilityIncomeTypeId,
        profitShareEstimate: payload.profitShareEstimate,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.formMessage = {};
      this.$refs.formMLoanCollectabilityRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "loan",
          reqUrl: "loan-collectability/" + this.identity.mainId,
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.$refs.formMLoanCollectabilityRef.routeToPageListLoanCollectability();
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.formMessage = error?.response?.data?.errorFields;
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formMLoanCollectabilityRef.property.animation.submitLoading = false;
      }
    },
  },
};
