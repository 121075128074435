export default {
  name: "ReconcileSBB",
  data() {
    return {
      dataForm: {
        tempSbb: "",
        totalData: "",
        totalNominalDebit: "",
        totalNominalCredit: "",
      },
      property: {
        // animation: {
        //   submitLoading: false,
        // },
        modal: {
          showModalDetailSbb: false,
        },
        listElement: {
          reconcileSBB: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          detailReconcileSbb: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          journalDate: "",
          officeId: "",
          userId: "",
          chartOfAccountCode: "",
        },
      },
      options: {
        users: [],
        cabangs: [],
        chartOfAccountCode: [],
      },
      table: {
        data: {
          reconcileSBB: [],
          detailReconcileSbb: [],
        },
      },
    };
  },
  methods: {
    /* Get user Data */
    async helperGetUserDataForReference() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
              officeCode: "",
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.users = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const value = index.userId;
            const text = `${index.userCode} - ${index.profileSurename}`;
            this.options.users.push({ text, value });
          });
        }
      } catch (error) {}
    },
    /* Get Cabang Data */
    async helperGetCabangList() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.cabangs = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.cabangs.push({ text, value });
            this.helperGetUserDataForReference();
          });
        }
      } catch (error) {}
    },
    /* Get SBB or Chart of Accounts Data */
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        // console.log(resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountCode;
            this.options.chartOfAccountCode.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* Get System Date Data */
    getSystemDate() {
      this.property.filterDto.journalDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    /* Get Data Reconcile SBB List */
    async getTableReconcileSbb() {
      this.table.data.reconcileSBB = [];
      const timeout = 500;

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.listElement.reconcileSBB.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/header-reconcile-sbb",
            payload: {
              officeId: this.property.filterDto.officeId,
              journalDate: this.property.filterDto.journalDate,
              userId: this.property.filterDto.userId,
              chartOfAccountCode: this.property.filterDto.chartOfAccountCode,
              page: 0,
              size: this.property.listElement.reconcileSBB.perPage,
            },
          });
          console.log("getTableReconcileSbb =>", resp);
          if (resp.data.code === "SUCCESS") {
            setTimeout(() => {
              this.table.data.reconcileSBB = resp.data.data.detail.content;
              this.dataForm.totalData = resp.data.data.totalData;
              this.dataForm.totalNominalCredit =
                resp.data.data.totalNominalCredit;
              this.dataForm.totalNominalDebit =
                resp.data.data.totalNominalDebit;
              this.property.listElement.reconcileSBB.rows =
                resp.data.data.detail.totalElements;
            }, timeout);
          } else {
            this.property.listElement.message = resp.data.message;
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          setTimeout(() => {
            this.property.listElement.reconcileSBB.downloading = false;
          }, 1000);
        }
      }
    },
    /* Get Detail SBB List Data & Show Modal Detail */
    async getDetailReconcileSbb(props) {
      this.dataForm.tempSbb = props.row.sbb;
      console.log("Ini props =>", props);
      this.table.data.detailReconcileSbb = [];
      const timeout = 500;

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.listElement.detailReconcileSbb.downloading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/reconcile-sbb",
            payload: {
              officeId: this.property.filterDto.officeId,
              journalDate: this.property.filterDto.journalDate,
              userId: this.property.filterDto.userId,
              chartOfAccountCode: this.dataForm.tempSbb,
              page: 0,
              size: this.property.listElement.detailReconcileSbb.perPage,
            },
          });
          console.log("getTableReconcileSbb =>", resp);
          if (resp.data.code === "SUCCESS") {
            this.property.modal.showModalDetailSbb = true;
            setTimeout(() => {
              this.table.data.detailReconcileSbb = resp.data.data.content;
              this.property.listElement.detailReconcileSbb.rows =
                resp.data.data.totalElements;
            }, timeout);
          } else {
            this.property.listElement.message = resp.data.message;
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          setTimeout(() => {
            this.property.listElement.detailReconcileSbb.downloading = false;
          }, 1000);
        }
      }
    },
    /* Test Show Modal */
    async searchReconcileSbbByUserId() {
      this.table.data.detailReconcileSbb = [];
      this.property.listElement.detailReconcileSbb.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/reconcile-sbb",
          payload: {
            journalDate: this.property.filterDto.journalDate,
            officeId: this.property.filterDto.officeId,
            userId: this.property.filterDto.userId,
            chartOfAccountCode: this.dataForm.tempSbb,
            page: 0,
            size: this.property.listElement.detailReconcileSbb.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.detailReconcileSbb = resp.data.data.content;
            this.property.listElement.detailReconcileSbb.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDetailReconcileSbb(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDetailReconcileSbb(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.detailReconcileSbb.downloading = false;
        }, timeout);
      }
    },
    /* Clear Modal Results Inside SBB Detail List */
    clearModalResultDetailSbb() {
      this.property.filterDto.userId = "";
      this.dataForm.tempSbb = "";
      this.table.data.detailReconcileSbb = [];
      this.property.listElement.detailReconcileSbb.rows = 0;
      this.property.listElement.detailReconcileSbb.currentPage = 1;
      this.property.listElement.detailReconcileSbb.perPage = 5;
    },
    /* Pagination For Detail SBB List Inside Modal */
    async changePaginationDetailTableReconcileSbb(event) {
      this.table.data.detailReconcileSbb = [];
      this.property.listElement.detailReconcileSbb.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/reconcile-sbb",
          payload: {
            journalDate: this.property.filterDto.journalDate,
            officeId: this.property.filterDto.officeId,
            userId: this.property.filterDto.userId,
            chartOfAccountCode: this.dataForm.tempSbb,
            page: event - 1,
            size: this.property.listElement.detailReconcileSbb.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.detailReconcileSbb = resp.data.data.content;
            this.property.listElement.detailReconcileSbb.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDetailReconcileSbb(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDetailReconcileSbb(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.detailReconcileSbb.downloading = false;
        }, timeout);
      }
    },
    failedGetDetailReconcileSbb(resp) {
      this.property.listElement.detailReconcileSbb.rows = 0;
      this.table.data.detailReconcileSbb = [];
      this.property.listElement.detailReconcileSbb.message = resp.data.message;
    },
    handleErrorGetDetailReconcileSbb(error) {
      console.log(error.response);
      this.table.data.detailReconcileSbb = [];
      this.property.listElement.detailReconcileSbb.rows = 0;
      this.property.listElement.detailReconcileSbb.currentPage = 1;
      this.property.listElement.detailReconcileSbb.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    /* Pagination For Reconcile SBB List */
    async changePaginationTableReconcileSbb(event) {
      this.table.data.reconcileSBB = [];
      this.property.listElement.reconcileSBB.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/header-reconcile-sbb",
          payload: {
            journalDate: this.property.filterDto.journalDate,
            officeId: this.property.filterDto.officeId,
            chartOfAccountCode: this.property.filterDto.chartOfAccountCode,
            userId: this.property.filterDto.userId,
            page: event - 1,
            size: this.property.listElement.reconcileSBB.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.reconcileSBB = resp.data.data.detail.content;
            this.property.listElement.reconcileSBB.rows =
              resp.data.data.detail.totalElements;
          } else {
            this.failedGetReconcileSbb(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetReconcileSbb(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.reconcileSBB.downloading = false;
        }, timeout);
      }
    },
    failedGetReconcileSbb(resp) {
      this.property.listElement.reconcileSBB.rows = 0;
      this.table.data.reconcileSBB = [];
      this.property.listElement.reconcileSBB.message = resp.data.message;
    },
    handleErrorGetReconcileSbb(error) {
      console.log(error.response);
      this.table.data.reconcileSBB = [];
      this.property.listElement.reconcileSBB.rows = 0;
      this.property.listElement.reconcileSBB.currentPage = 1;
      this.property.listElement.reconcileSBB.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
  },
  mounted() {
    this.helperGetCabangList();
    this.helperGetUserDataForReference();
    this.getReferenceChartOfAccount();
    this.getSystemDate();
    // this.getTableReconcileSbb();
  },
};
