export default {
  name: "CollateralGroup",
  data() {
    return {
      property: {
        listElement: {
          loan: {
            downloading: false,
            message: "",
            perPage: 10,
            rows: 0,
            currentPage: 1,
          },
        },
        filterDto: {
          collateralTypeId: "",
          description: "",
        },
      },
      table: {
        data: {
          loan: [],
        },
      },
    };
  },
  methods: {
    async findLoanCollateralGroup() {
      this.table.data.loan = [];
      this.property.listElement.loan.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral",
          payload: {
            collateralTypeId: this.property.filterDto.collateralTypeId,
            description: this.property.filterDto.description,
            page: 0,
            size: this.property.listElement.loan.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loan = resp.data.data.content;
            this.property.listElement.loan.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanCollateralGroup(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralGroup(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loan.downloading = false;
        }, timeout);
      }
    },
    routeToPageEditCollateralGroup(props) {
      this.$router.push(
        `/loan/collateral-group/${this.encryptBASE64(
          `${props.row.loanCollateralId}`
        )}`
      );
    },
    routeToPageAddCollateralGroup() {
      this.$router.push("/loan/collateral-group/new");
    },
    async deleteCollacteral(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral/" + props.row.loanCollateralId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.loan.currentPage = 1;
          this.property.filterDto.collateralTypeId = "";
          this.property.filterDto.description = "";
          this.property.listElement.loan.rows = 0;
          this.getLoanCollateralGroup();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async authorizationCollateral(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "loan",
          reqUrl: "loan-collateral/authorized/" + props.row.loanCollateralId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.loan.currentPage = 1;
          this.property.filterDto.collateralTypeId = "";
          this.property.filterDto.description = "";
          this.property.listElement.loan.rows = 0;
          this.getLoanCollateralGroup();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async changePaginationLoanCollateralGroup(event) {
      this.table.data.loan = [];
      this.property.listElement.loan.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral",
          payload: {
            collateralTypeId: this.property.filterDto.collateralTypeId,
            description: this.property.filterDto.description,
            page: event - 1,
            size: this.property.listElement.loan.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loan = resp.data.data.content;
            this.property.listElement.loan.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanCollateralGroup(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralGroup(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loan.downloading = false;
        }, timeout);
      }
    },
    async getLoanCollateralGroup() {
      this.table.data.loan = [];
      this.property.listElement.loan.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral",
          payload: {
            collateralTypeId: "",
            description: "",
            page: 0,
            size: this.property.listElement.loan.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loan = resp.data.data.content;
            this.property.listElement.loan.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanCollateralGroup(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralGroup(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loan.downloading = false;
        }, timeout);
      }
    },
    handleErrorLoanCollateralGroup(error) {
      console.log(error.response);
      this.table.data.loan = [];
      this.property.listElement.loan.rows = 0;
      this.property.listElement.loan.currentPage = 1;
      this.property.listElement.loan.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataLoanCollateralGroup(resp) {
      this.table.data.loan = [];
      this.property.listElement.loan.rows = 0;
      this.property.listElement.loan.message = resp.data.message;
    },
  },
  mounted() {
    this.getLoanCollateralGroup();
  },
};
