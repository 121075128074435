export default {
  name: "FormMDepositBilyet",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identifier: {
        depositId: "",
      },
      form: {
        officeName: "",
        accountNumber: "",
        cifIdName: "",
        nominal: "",
        timePeriode: "",
        nisbah: "",
        dueDate: "",
        depositBilyetId: "",
        bilyetTypeId: "",
        depositBilyetNumber: "",
        usedDate: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        nomorBilyet: [],
        jangkaWaktu: [],
        jenisBilyet: [],
      },
    };
  },
  methods: {
    sumbitLDepositBilyet() {
      this.$emit("sumbitLDepositBilyet", this.form);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.depositBilyetId = payload.depositBilyetId;
        this.form.officeName = payload.office ? payload.office.officeName : "";
        this.form.accountNumber = payload.tdepositAccount
          ? payload.tdepositAccount.accountNumber
          : "";
        this.form.cifIdName = payload.tdepositAccount
          ? payload.tdepositAccount.mcifResponseDto
            ? payload.tdepositAccount.qqName
              ? `${payload.tdepositAccount.mcifResponseDto.cifIdName} ${payload.tdepositAccount.qqCode} ${payload.tdepositAccount.qqName}`
              : payload.tdepositAccount.mcifResponseDto.cifIdName
            : ""
          : "";
        this.form.nominal = payload.tdepositAccount
          ? payload.tdepositAccount.nominal
          : "";
        this.form.depositNisbahId = payload.tdepositAccount
          ? payload.tdepositAccount.mdepositNisbahChartOfAccountResponseDto
            ? payload.tdepositAccount.mdepositNisbahChartOfAccountResponseDto
                .depositNisbahId
            : ""
          : "";
        this.form.nisbah = payload.tdepositAccount
          ? payload.tdepositAccount.mdepositNisbahChartOfAccountResponseDto
            ? payload.tdepositAccount.mdepositNisbahChartOfAccountResponseDto
                .nisbah
            : ""
          : "";
        this.form.dueDate = payload.tdepositAccount
          ? payload.tdepositAccount.dueDate
          : "";
        this.form.bilyetTypeId = payload.rbilyetType
          ? payload.rbilyetType.bilyetTypeId
          : "";
        this.form.depositBilyetNumber = payload.depositBilyetNumber;
        this.form.usedDate = this.dateToYearMonthDay(payload.usedDate);
        this.identity.depositAccountId = payload.depositAccountId;
        this.identifier.depositId = payload.tdepositAccount
          ? payload.tdepositAccount.mdepositDto
            ? payload.tdepositAccount.mdepositDto.depositId
            : ""
          : "";
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        this.getReferenceDepositNisbahChartOfAccount();
        return;
      }
      this.form.officeName = payload.mcifResponseDto
        ? payload.mcifResponseDto.officeId
          ? payload.mcifResponseDto.officeId.officeName
          : ""
        : "";
      this.form.cifIdName = payload.mcifResponseDto
        ? payload.qqName
          ? `${payload.mcifResponseDto.cifIdName} ${payload.qqCode} ${payload.qqName}`
          : payload.mcifResponseDto.cifIdName
        : "";
      this.form.accountNumber = payload.accountNumber;
      this.form.nominal = payload.nominal;
      this.form.depositNisbahId = payload.mdepositNisbahChartOfAccountResponseDto
        ? payload.mdepositNisbahChartOfAccountResponseDto.depositNisbahId
        : "";
      this.form.nisbah = payload.mdepositNisbahChartOfAccountResponseDto
        ? payload.mdepositNisbahChartOfAccountResponseDto.nisbah
        : "";
      this.identifier.depositId = payload.mdepositDto
        ? payload.mdepositDto.depositId
        : "";
      this.form.dueDate = payload.dueDate;
      this.getReferenceDepositNisbahChartOfAccount();
    },
    async changeBilyetTypeId() {
      this.form.depositBilyetId = "";
      if (this.form.bilyetTypeId === "") {
        this.options.nomorBilyet = [
          { value: "", text: "-- Pilih Jenis Bilyet Terlebih Dahulu--" },
        ];
      } else {
        try {
          const resp = await this.$store.dispatch(
            "GET_REFERENCE_FROM_MASTER_TRANSACTION",
            {
              params: {
                bilyetTypeId: this.form.bilyetTypeId,
                bilyetStatusId: "1",
                statusId: "3",
                page: 0,
                sortBy: "",
              },
              url: "deposit-bilyet/inventory/list",
            }
          );
          if (resp.data.code === "SUCCESS") {
            this.options.nomorBilyet = [{ value: "", text: "-- Pilih --" }];
            resp.data.data.map((i) => {
              const value = i.depositBilyetId;
              const text = i.depositBilyetNumber;
              this.options.nomorBilyet.push({ value, text });
            });
          }
        } catch (error) {}
      }
    },
    getReferenceDepositNisbahChartOfAccount() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_TRANSACTION", {
          params: {
            depositId: this.identifier.depositId,
            page: 0,
          },
          url: "deposit-nisbah-chart-of-account",
        })
        .then((resp) => {
          this.options.jangkaWaktu = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((i) => {
            const value = i.depositNisbahId;
            const text = `${i.timePeriode} - ${i.mdepositResponseDto.rdepositTimeType.depositTimeTypeName}`;
            this.options.jangkaWaktu.push({ value, text });
          });
        });
    },
    getReferenceBilyetType() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "bilyet-type",
        })
        .then((resp) => {
          this.options.jenisBilyet = [{ value: "", text: "-- Pilih --" }];
          console.log(resp);
          if (resp.data.code === "SUCCESS") {
            const index = resp.data.data[0];
            const value = index.bilyetTypeId;
            const text = index.bilyetTypeName;
            console.log('test data', value, text)
            this.options.jenisBilyet.push({value, text})
          }
          // resp.data.data.map((i) => {
          //   console.log(resp.data.data.map());
          //   const value = i.bilyetTypeId;
          //   const text = i.bilyetTypeName;
          //   this.options.jenisBilyet.push({ value, text });
          // });
        });
    },
    routeToPageListDepositBilyet() {
      this.$router.push("/deposit/deposit-bilyet");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListDepositBilyet();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? (this.identity.mainId = this.decryptBASE64(params))
          : (this.identity.mainId = JSON.parse(
              this.decryptBASE64(query)
            ).depositAccountId);
      this.$emit("getMainId", this.identity.mainId);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceBilyetType();
  },
  destroyed() {},
};
