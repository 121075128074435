export default {
  name: "FormMappingSandiBi",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          MappingSandiBi: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        sandiBiLevel1Code: "",
        sandiBiLevel2Code: "",
        sandiBiCode: "",
        isDefault: false,
      },
      options: {
        sandiBiLevel1: [],
        sandiBiLevel2: [],
        sandiBi: [],
      },
    };
  },
  methods: {
    async getRefferenceSandiBiLevel1() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "m-sandi-bi-level-1",
            params: {
              sandiBiLevel1Name: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.sandiBiLevel1Code} - ${index.sandiBiLevel1Name}`;
            const value = index.sandiBiLevel1Code;
            this.options.sandiBiLevel1.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getRefferenceSandiBiLevel2() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "sandi-bi-level2",
            params: {
              sandiBiLevel2Name: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.sandiBiLevel2Code} - ${index.sandiBiLevel2Name}`;
            const value = index.sandiBiLevel2Code;
            this.options.sandiBiLevel2.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getRefferenceSandiBi() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "sandi-bi",
            params: {
              sandiBiCode: "",
              sandiBiName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.sandiBiCode} - ${index.sandiBiName}`;
            const value = index.sandiBiCode;
            this.options.sandiBi.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.sandiBiLevel1Code = payload.sandiBiLevel1Code;
        this.dataForm.sandiBiLevel2Code = payload.sandiBiLevel2Code;
        this.dataForm.sandiBiCode = payload.sandiBiCode;
        this.dataForm.sandiBiLevel1CodeSelected = payload.sandiBiLevel1Code;
        this.dataForm.sandiBiLevel2CodeSelected = payload.sandiBiLevel2Code;
        this.dataForm.sandiBiCodeSelected = payload.sandiBiCode;
        this.dataForm.isDefault = payload.isDefault;
        this.findMSandiBiBySandiBiLevel2();
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/parameter/mapping-parameter-sandi-bi/");
    },
    async findMSandiBiBySandiBiLevel2() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "parameter",
          reqUrl:
            "sandi-bi/sandi-bi-by-level2-code/" +
            this.dataForm.sandiBiLevel2Code,
        });
        if (resp.data.code === "SUCCESS") {
          this.options.sandiBi = [];
          resp.data.data.map((index) => {
            const text = `${index.sandiBiCode} - ${index.sandiBiName}`;
            const value = index.sandiBiCode;
            this.options.sandiBi.push({ text, value });
          });
        }
      } catch (error) {}
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        console.log("checkingMainId", params)
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
        console.log("checkingMainId2", this.identity.mainId)

      }
    },
    submitMappingSandiBi() {
      this.$emit("submitMappingSandiBi", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getRefferenceSandiBiLevel1();
    this.getRefferenceSandiBiLevel2();
    // this.getRefferenceSandiBi();
  },
};
