import Moment from "moment";
import axios from "axios";

export default {
  name: "ListMJournalLedger",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      property: {
        listElement: {
          journalLedgerManual: {
            detailOpened: [],
            subDetailOpened: [],
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          journalCode: "",
          referenceNumber: "",
          branchCode: "",
          dateFrom: "",
          dateTo: "",
          isReversal: false,
          userId: "",
        },
      },
      table: {
        data: {
          journalLedgerManual: [],
        },
      },
      options: {
        office: [],
        user: [],
      },
    };
  },
  methods: {
    routeToPageAddJournalLedgerManual() {
      this.$router.push("/parameter/journal-ledger/new");
    },
    routeToPageEditJournaledgerManual(props) {
      this.$router.push(
        `/parameter/journal-ledger/${this.encryptBASE64(
          `${props.row.header.journalLedgerId}`
        )}`
      );
    },

    authorizationJournalLedgerManual(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "parameter",
                reqUrl:
                  "journal-ledger/authorized/" + props.row.journalLedgerId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.journalLedgerManual.currentPage = 1;
                  this.property.filterDto.journalCode = "";
                  this.property.filterDto.branchCode = "";
                  this.property.filterDto.referenceNumber = "";
                  this.property.filterDto.userId = "";
                  this.property.filterDto.dateFrom = "";
                  this.property.filterDto.dateTo = "";
                  this.property.listElement.journalLedgerManual.rows = 0;
                  this.setDefaultBranchCodeValue();
                  this.getJournalEntry();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    periodConvertMonth(event) {
      var endOfMonthDate = Moment(event)
        .endOf("month")
        .format();
      let converted = `${Moment(endOfMonthDate).format("YYYY")}-${Moment(
        endOfMonthDate
      ).format("MM")}`;
      return converted;
    },
    handleErrorJournalEntry(error) {
      console.log(error.response);
      this.table.data.journalLedgerManual = [];
      this.property.listElement.journalLedgerManual.rows = 0;
      this.property.listElement.journalLedgerManual.currentPage = 1;
      this.property.listElement.journalLedgerManual.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async findJournalLedgerManual() {
      this.table.data.journalLedgerManual = [];
      this.property.listElement.journalLedgerManual.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "journal-ledger/v2/manual/detail-new",
          payload: {
            referenceNumber: this.property.filterDto.referenceNumber,
            journalCode: this.property.filterDto.journalCode,
            isReversal: this.property.filterDto.isReversal,
            dateFrom: this.property.filterDto.dateFrom,
            userId: this.property.filterDto.userId,
            dateTo: this.property.filterDto.dateTo,
            branchCode: this.property.filterDto.branchCode,
            page: 0,
            size: this.property.listElement.journalLedgerManual.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.insertJournalLedgerTable(resp);
            this.property.listElement.journalLedgerManual.rows =
              resp.data.data.totalElements;
            this.property.listElement.journalLedgerManual.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.defaultOpenDetailJournalLedger(resp);
          } else {
            this.failedGetDataJournalLedgerManual(resp);
          }
        }, timeout);
      } catch (error) {
        this.handleErrorJournalEntry(error);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalLedgerManual.downloading = false;
        }, timeout + 500);
      }
    },
    async changePaginationJournalLedgerManual(event) {
      this.table.data.journalLedgerManual = [];
      this.property.listElement.journalLedgerManual.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "journal-ledger/v2/manual/detail-new",
          payload: {
            referenceNumber: this.property.filterDto.referenceNumber,
            journalCode: this.property.filterDto.journalCode,
            branchCode: this.property.filterDto.branchCode,
            isReversal: this.property.filterDto.isReversal,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            userId: this.property.filterDto.userId,
            page: event - 1,
            size: this.property.listElement.journalLedgerManual.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.insertJournalLedgerTable(resp);
            this.property.listElement.journalLedgerManual.rows =
              resp.data.data.totalElements;
            this.defaultOpenDetailJournalLedger(resp);
          } else {
            this.failedGetDataJournalLedgerManual(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorJournalEntry(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalLedgerManual.downloading = false;
        }, timeout);
      }
    },
    async getJournalEntry() {
      this.table.data.journalLedgerManual = [];
      this.property.listElement.journalLedgerManual.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "journal-ledger/v2/manual/detail-new",
          payload: {
            referenceNumber: "",
            journalCode: "",
            isReversal: this.property.filterDto.isReversal,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            branchCode: this.property.filterDto.branchCode,
            userId: this.property.filterDto.userId,
            page: 0,
            size: this.property.listElement.journalLedgerManual.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.insertJournalLedgerTable(resp);
            this.property.listElement.journalLedgerManual.rows =
              resp.data.data.totalElements;
            this.defaultOpenDetailJournalLedger(resp);
          } else {
            this.failedGetDataJournalLedgerManual(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorJournalEntry(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.journalLedgerManual.downloading = false;
        }, timeout);
      }
    },
    failedGetDataJournalLedgerManual(resp) {
      this.table.data.journalLedgerManual = [];
      this.property.listElement.journalLedgerManual.rows = 0;
      this.property.listElement.journalLedgerManual.message = resp.data.message;
    },
    defaultOpenDetailJournalLedger() {
      this.property.listElement.journalLedgerManual.detailOpened = [];
      this.property.listElement.journalLedgerManual.subDetailOpened = [];
    },
    insertJournalLedgerTable(resp) {
      console.log(resp);
      resp.data.data.content.map((index) => {
        const journalLedgerId = index.header.journalLedgerId;
        this.table.data.journalLedgerManual.push({
          journalLedgerId,
          header: index.header,
          detail: index.detail,
        });
      });
    },
    async getRefenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.office.push({ text, value });
            this.getRefenceUser();
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    setDefaultBranchCodeValue() {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        const getUserDataFromSession = JSON.parse(
          this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
        );
        console.log("this is", getUserDataFromSession);
        this.property.filterDto.branchCode = getUserDataFromSession.officeCode;
        this.property.filterDto.userId =
          axios.defaults.headers.common["x-user-id"];
        this.property.filterDto.dateFrom = this.fetchAppSession(
          "@vue-session/application"
        ).system_date;
        this.property.filterDto.dateTo = this.fetchAppSession(
          "@vue-session/application"
        ).system_date;
      }
      console.log('value')
    },
    async getRefenceUser() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
              userRoleId: "",
              officeCode: this.property.filterDto.branchCode,
              allBranchesCanSee: true,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.user = [{ value: "", text: "-- Semua User --" }];
          resp.data.data.content.map((index) => {
            const value = index.userId;
            const text = `${index.userCode} - ${index.profileSurename}`;
            this.options.user.push({ text, value });
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.setDefaultBranchCodeValue();
    this.getJournalEntry();
    this.getRefenceOffice();
    this.getRefenceUser();
  },
};
