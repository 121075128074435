export default {
  name: "FormSandiBiLevel2",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          sandiBiLevel2: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        sandiBiLevel2Name: "",
        sandiBiLevel2Code: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
    };
  },
  methods: {
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.sandiBiLevel2Code = payload.sandiBiLevel2Code;
        this.dataForm.sandiBiLevel2Name = payload.sandiBiLevel2Name;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/parameter/sandi-bi-level-2/");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitSandiBiLevel2() {
      this.$emit("submitSandiBiLevel2", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
  },
};
