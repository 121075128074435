import FormMInterbankLiability from "../form";

export default {
  name: "ExistingMInterbankLiability",
  components: {
    FormMInterbankLiability,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getInterbankLiabilityById();
    },
    async getInterbankLiabilityById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formMInterbankLiabilityRef.routeToPageListInterbankLiability();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "interbankliability/" + this.identity.mainId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$refs.formMInterbankLiabilityRef.getDefaultFormValue(
            resp.data.data
          );
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () =>
              this.$refs.formMInterbankLiabilityRef.routeToPageListInterbankLiability(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () =>
            this.$refs.formMInterbankLiabilityRef.routeToPageListInterbankLiability(),
        });
      }
    },
    async editInterbankLiability(payload) {
      var data = {
        accountBranch: payload.accountBranch,
        accountNumber: payload.accountNumber,
        accountName: payload.accountName,
        biCode: payload.biCode,
        bankName: payload.bankName,
        interbankLiabilityTypeId: payload.interbankLiabilityTypeId,
        tenorYear: payload.tenorYear,
        tenorMonth: payload.tenorMonth,
        tenorDay: payload.tenorDay,
        interestRate: payload.interestRate,
        balance: payload.balance,
        isActive: payload.isActive,
        openDate: payload.openDate,
        closeDate: payload.closeDate,
        chartOfAccountId: payload.chartOfAccountId,
        interbankAssetsId: payload.interbankAssetsId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.$refs.formMInterbankLiabilityRef.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl: "interbankliability/" + this.identity.mainId,
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1000);
        this.$refs.formMInterbankLiabilityRef.property.animation.submitLoading = false;
      }
    },
  },
};
