<template>
  <div>
    <b-modal
      v-model="showModal"
      :width="500"
      :canClose="['outside']"
      scroll="clip"
      :on-cancel="closeModal"
    >
      <header style="border-radius:0px" class="modal-card-head">
        <span class="tetx-base">Form File</span>
      </header>
      <div class="card" style="width: auto;border-radius:0px">
        <div
          class="bg-white pb-5 pt-3 flex justify-start items-center flex-col"
          style="width:auto;"
        >
          <div
            style="max-width: 430px"
            class="flex flex-row flex-wrap justify-between w-full items-center mt-3"
          >
            <span class="text-sm  w-full md:w-1/2">Nomor Dokumen</span>
            <b-field class="w-full md:w-1/2">
              <b-input
                v-model="form.documentNumber"
                type="number"
                size="is-small"
                class="w-full"
              />
            </b-field>
          </div>
          <div class="mt-5">
            <b-upload v-model="form.documentFile" class="p-0" drag-drop>
              <section class="section py-5 px-5">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-medium"> </b-icon>
                  </p>
                  <span v-if="!form.documentFile" class="text-sm">
                    Jatuhkan File Di Sini Atau Klik Untuk Pilih</span
                  >
                  <span v-if="form.documentFile" class="text-sm">
                    File Terpilih : (
                    {{ form.documentFile ? form.documentFile.name : "" }} )
                    <br />
                    Atau Jatuhkan File Di Sini Atau Klik Untuk Pilih Ulang
                  </span>
                </div>
              </section>
            </b-upload>
          </div>
          <div
            style="max-width: 430px"
            class="flex flex-row justify-between w-full items-center mt-5"
          >
            <b-button @click="closeModal" class="is-light" style="width:49%"
              >Batal</b-button
            >
            <b-button
              :disabled="form.documentNumber === 0 || !form.documentFile"
              @click="onSubmit"
              type="is-link"
              style="width:49%"
              >Simpan</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "DocumentChecklistModalUpload",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        documentNumber: 0,
        documentFile: null,
      },
      modal: {
        showModalDocumentChecklistUpload: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    showModalDocumentChecklistUpload() {
      this.property.modal.showModalDocumentChecklistUpload = true;
    },
    onSubmit() {
      this.$emit("onSubmit", this.form);
      this.closeModal();
    },
  },
};
</script>
