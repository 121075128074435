import FormMOffice from "../form";

export default {
  name: "ExistingMOffice",
  components: {
    FormMOffice,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
    };
  },
  methods: {
    getMainId(props) {
      this.identity.mainId = props;
      this.getDataOfficeById();
    },
    async getDataOfficeById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.$refs.formMOfficeRef.routeToPageListOffice();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "parameter",
            reqUrl: "office/" + this.identity.mainId,
          });
          if (resp.data.code === "SUCCESS") {
            this.$refs.formMOfficeRef.getDefaultFormValue(resp.data.data);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () =>
                this.$refs.formMOfficeRef.routeToPageListOffice(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.$refs.formMOfficeRef.routeToPageListOffice(),
          });
        }
      }
    },
    async editOffice(payload) {
      const data = {
        officeCode: payload.officeCode,
        officeName: payload.officeName,
        officeAddress: payload.officeAddress,
        postalCode: payload.postalCode,
        telephonNumber: payload.telephonNumber,
        faximileNumber: payload.faximileNumber,
        viceHeadOfficeName: payload.viceHeadOfficeName,
        sandiBankId: payload.sandiBankId,
        officeHeadName: payload.officeHeadName,
        officeHeadAddress: payload.officeHeadAddress,
        officeHeadCityId: payload.officeHeadCityId,
        headOfficeTelephoneNumber: payload.headOfficeTelephoneNumber,
        viceHeadOfficeTelephoneNumber: payload.viceHeadOfficeTelephoneNumber,
        adminOfficeTelephoneNumber: payload.adminOfficeTelephoneNumber,
        officeSmsNumber: payload.officeSmsNumber,
        officeHeadPosition: payload.officeHeadPosition,
        villageId: payload.villageId,
        coaAtmId: payload.coaAtmId,
        rofficeCategoryId: payload.rofficeCategoryId,
        officeParentId: payload.officeParentId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.$refs.formMOfficeRef.property.animation.addOffice.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "parameter",
                reqUrl: "office/" + this.identity.mainId,
                payload: data,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.$refs.formMOfficeRef.property.animation.addOffice.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
  },
};
