import Moment from "moment";
export default{
    name: "ListSipendar",
    data(){
        return{
            property: {
                listElement: {
                    sipendar: {
                        rows: 0,
                        perPage: 10,
                        currentPage: 1,
                        downloading: false,
                        message: "",
                      },
                },
                filterData: {
                  sipendarName: "",
                  idCardNumber: ""
                },
            },
            table: {
                data: {
                    sipendar: []
                }
            }
        }
    },
    methods: {
        Moment: Moment,
          getSystemDate(){
            this.table.data.sipendar.birthDate = this.fetchAppSession(
              "@vue-session/application"
            ).system_date;
        },
        async findSipendar() {
          this.table.data.sipendar = [];
          this.property.listElement.sipendar.downloading = true;
          const timeout = 500;
          try {
            const resp = await this.$store.dispatch({
              type: "GET_LIST_DATA",
              endPoint: "transaction",
              reqUrl: "sipendar",
              payload: {
                sipendarName: this.property.filterData.sipendarName,
                idCardNumber: this.property.filterData.idCardNumber,
                page: 0,
                size: 10,
              },
            });
    
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.table.data.sipendar = resp.data.data.content;
                this.property.listElement.sipendar.rows =
                  resp.data.data.totalElements;
                this.property.listElement.sipendar.currentPage =
                  resp.data.data.pageable.pageNumber + 1;
              } else {
                this.failedGetDataSipendar(resp);
              }
            }, timeout);
          } catch (error) {
            setTimeout(() => {
              this.handleCatchErrorSipendar(error);
            }, timeout);
          } finally {
            setTimeout(() => {
              this.property.listElement.sipendar.downloading = true;
            }, timeout);
          }
        },
        routeToPageAddSipendar() {
          this.$router.push("/report/sipendar/new");
        },
        handleCatchErrorSipendar(error) {
          this.table.data.sipendar = [];
          this.property.listElement.sipendar.rows = 0;
          this.property.listElement.sipendar.currentPage = 1;
          this.property.listElement.sipendar.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        },
        failedGetDataSipendar(resp) {
          this.table.data.sipendar = [];
          this.property.listElement.sipendar.rows = 0;
          this.property.listElement.sipendar.message = resp.data.message;
        },
        async changePaginationSipendar(event) {
          this.table.data.sipendar = [];
          this.property.listElement.sipendar.downloading = true;
          const timeout = 500;
    
          try {
            const resp = await this.$store.dispatch({
              type: "GET_LIST_DATA",
              endPoint: "transaction",
              reqUrl: "sipendar",
              payload: {
                sipendarName: this.property.filterData.sipendarName,
                idCardNumber: this.property.filterData.idCardNumber,
                page: event - 1,
                size: this.property.listElement.sipendar.perPage,
              },
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.table.data.sipendar = resp.data.data.content;
                this.property.listElement.sipendar.rows =
                  resp.data.data.totalElements;
              } else {
                this.failedGetDataSipendar(resp);
              }
            }, timeout);
          } catch (error) {
            setTimeout(() => {
              this.handleCatchErrorSipendar(error);
            }, timeout);
          } finally {
            setTimeout(() => {
              this.property.listElement.sipendar.downloading = false;
            }, timeout);
          }
        },
        async helperGetDataTableSipendar() {
            this.table.data.sipendar = [];
            this.property.listElement.sipendar.downloading = true;
            const timeout = 500;
      
            try {
              const resp = await this.$store.dispatch({
                type: "GET_LIST_DATA",
                endPoint: "transaction",
                reqUrl: "sipendar",
                payload: {
                  name: "",
                  address: "",
                  page: 0,
                  size: 10,
                },
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  console.log(resp.data.data.content);
                  this.table.data.sipendar = resp.data.data.content;
                  this.property.listElement.sipendar.rows =
                    resp.data.data.totalElements;
                } else {
                  this.failedGetDataSipendar(resp);
                }
              }, timeout);
            } catch (error) {
              setTimeout(() => {
                this.handleCatchErrorSipendar(error);
              }, timeout);
            } finally {
              setTimeout(() => {
                this.property.listElement.sipendar.downloading = false;
              }, timeout);
            }
        },
        routeToPageEditSipendar(props) {
          this.$router.push(
            `/report/sipendar/${this.encryptBASE64(
              `${props.row.sipendarId}`
            )}`
          );
        },
        async deleteSipendar(props) {
          if (window.navigator.onLine === false) {
            this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
            return;
          } else {
            const confirmation = await this.simpleConfirmation(
              "Hapus Data Ini ?",
              "is-success"
            );
            if (!confirmation) {
              return;
            }
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "sipendar/" + props.row.sipendarId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                await this.simpleWait(1000);
                this.property.listElement.sipendar.currentPage = 1;
                this.property.filterData.sipendarName = "";
                this.property.filterData.idCardNumber = "";
                this.property.listElement.sipendar.rows = 0;
                this.helperGetDataTableSipendar();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }
        },
    },
    mounted() {
        this.helperGetDataTableSipendar();
    },
}