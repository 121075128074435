import ModalSearchSavingAccount from "../../../../components/modal-search-saving-account";

export default {
  name: "FormTMultiAccount",
  components: {
    ModalSearchSavingAccount,
  },
  props: {
    isExisting: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          upload: false,
          submitMultiAccount: false,
          isDownloadingFile: false,
        },
        modal: {
          findSavingAccountSource: false,
          findSavingAccountTarget: {
            index: "",
            open: false,
          },
        },
      },
      form: {
        isComaDelimiter: false,
        file: null,
        nominal: 0,
        transactionCode: "",
        transactionDate: "",
        description: "",
        officeId: "",
        sourceAccountNumber: "",
        cifIdName: "",
        accountType: "",
        cifOfficeName: "",
        documentProofNumber: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        detail: [],
      },
      options: {
        transactionCode: [],
        interbankAssets: [],
        chartOfAccount: [],
        office: [],
        accountType: [
          {
            text: "-- Pilih --",
            value: "",
            disabled: false,
          },
          {
            text: "Rekening Tabungan",
            value: "TAB",
            disabled: false,
          },
          {
            text: "ABA",
            value: "ABA",
            disabled: false,
          },
          {
            text: "SBB ",
            value: "SBB",
            disabled: false,
          },
        ],
      },
      table: {
        data: {},
      },
    };
  },
  computed: {
    nominalTotalVal() {
      let sum = 0;
      for (let item of this.form.detail) {
        sum = sum + item.nominal;
      }
      this.form.nominal = sum;
      return sum;
    },
  },
  methods: {
    async onDownloadTemplateUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account/download-multi-account/us",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Template_Upload_Rekening_Multi_.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadTemplateIdn(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        isComaDelimiter: false,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "t-multi-account/download-multi-account/idn",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Template_Upload_Rekening_Multi_.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    submitMultiAccount() {
      this.$emit("submitMultiAccount", this.form);
    },
    addRowMultiAccount() {
      this.form.detail.push({
        nominal: 0,
        description: "",
        destinationAccountNumber: "",
        cifIdName: "",
        officeName: "",
        accountType: "",
        identifierId: Math.random()
          .toString(36)
          .substr(2, 9),
        officeId: "",
      });
    },
    insertRowMultiAccount(payload) {
      console.log(payload);
      let data = [];
      while (data.length !== payload.length) {
        data.push({
          nominal: payload[data.length].nominal,
          description: payload[data.length].description,
          destinationAccountNumber: payload[data.length].accountNumber,
          cifIdName: payload[data.length].accountName,
          officeName: payload[data.length].accountOfficeName,
          accountType: payload[data.length].accountType,
          identifierId: Math.random()
            .toString(36)
            .substr(2, 9),
          officeId: payload[data.length].accountOfficeId,
        });
      }
      this.form.detail = data;
    },
    onChangeTargetAccountAba(props) {
      console.log(props);
      var data = this.options.interbankAssets.find(
        (item) => item.value === props.row.destinationAccountNumber
      );
      console.log(data);
      this.form.detail[props.index].cifIdName = data.accountName;
      this.form.detail[props.index].officeName = data.officeName;
      this.form.detail[props.index].officeId = data.officeId;
    },
    chartOfAccountDescFinder(value) {
      return this.options.chartOfAccount.find((i) => i.value === value)
        .description;
    },
    offinceOfficeNameFinder(value) {
      return this.options.office.find((i) => i.value === value).officeName;
    },
    async uploadFile() {
      let formData = new FormData();
      formData.append("file", this.form.file);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.upload = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA_FILE",
          reqUrl: "t-multi-account/upload-multi-account",
          endPoint: "transaction",
          payload: formData,
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((data) => {
            const dto = {
              nominal: data.nominal ? data.nominal : 0,
              description: data.description,
              destinationAccountNumber: data.accountNumber,
              cifIdName: data.name,
              officeName: data.officeName,
              accountType: data.accountType,
              identifierId: Math.random()
                .toString(36)
                .substr(2, 9),
              officeId: data.officeId,
            };
            this.form.detail.push(dto);
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.upload = false;
      }
    },
    submit() {
      this.$emit("submit", this.form);
    },
    getDefaultFormValue(payload) {
      if (this.isExisting) {
        this.form.nominal = payload.header.nominal;
        this.form.transactionCode =
          payload.header.mtransactionCode?.transactionCode;
        this.form.transactionDate = payload.header.transactionDate;
        this.form.description = payload.header.description;
        this.form.officeId = payload.header.accountOfficeId;
        this.form.sourceAccountNumber = payload.header.accountNumber;
        this.form.cifIdName = payload.header.accountName;
        this.form.accountType = payload.header.accountType;
        this.form.cifOfficeName = payload.header.accountOfficeName;
        this.form.documentProofNumber = payload.header.documentProofNumber;
        this.form.createdByUserCode = payload.header.createdByUserCode;
        this.form.createdByUserName = payload.header.createdByUserName;
        this.form.createdDate = payload.header.createdDate;
        this.form.updatedDate = payload.header.updatedDate;
        this.form.updatedByUserCode = payload.header.updatedByUserCode;
        this.form.updatedByUserName = payload.header.updatedByUserName;
        this.form.authorizedDate = payload.header.authorizedDate;
        this.form.authorizedByUserCode = payload.header.authorizedByUserCode;
        this.form.authorizedByUserName = payload.header.authorizedByUserName;
        this.insertRowMultiAccount(payload.detail);
        return;
      }
    },
    routeToPageListMultiAccount() {
      this.$router.push("/saving/multi-account");
    },
    resetDetail() {
      this.form.detail = [];
    },
    async deleteTargetAccount(props) {
      const confirmation = await this.simpleConfirmation(
        "Hapus Data Ini",
        "is-danger"
      );
      if (confirmation) {
        var filterUnlessSelected = this.form.detail.filter(
          (index) => index.identifierId !== props.row.identifierId
        );
        this.form.detail = filterUnlessSelected;
      }
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              name: "",
              page: 0,
              menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_MENU_ACCESS_MULTI_ACCOUNT,
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCode;
            const description = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.transactionCode.push({
              text,
              value,
              description,
              transactionCode,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceInterbankAsset() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.interbankAssets = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.accountNumber;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = `${index.accountName} ( ${index.bankName} )`;
            const accountBranch = `${index.bankName} - Cabang ${index.accountBranch}`;
            const officeName = index.officeName;
            const officeId = index.officeId;
            this.options.interbankAssets.push({
              text,
              value,
              officeName,
              accountName,
              accountBranch,
              officeId,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.chartOfAccount = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountCode;
            const description = index.description;
            this.options.chartOfAccount.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "office/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.officeName} - ${index.officeCode}`;
            const value = index.officeId;
            const officeName = index.officeName;
            this.options.office.push({
              text,
              value,
              officeName,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (!params && this.isExisting) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListMultiAccount();
        return;
      }
      this.identity.mainId =
        this.isExisting === true ? this.decryptBASE64(params) : "";
      this.$emit("getMainId", this.identity.mainId);
      this.form.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceTransactionCode();
    this.getReferenceInterbankAsset();
    this.getReferenceChartOfAccount();
    this.getReferenceOffice();
  },
};
