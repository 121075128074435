export default {
  name: "ListTTaskInterbankAsset",
  data() {
    return {
      property: {
        listElement: {
          taskInterbankAsset: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          name: "",
          transactionCodeId: "",
          dateFrom: "",
          dateTo: "",
          statusAccountId: "",
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      options: {
        kodeTransaksi: [],
        statusAccount: [],
      },
      table: {
        data: {
          taskInterbankAsset: [],
        },
      },
    };
  },
  methods: {
    async changeDateFromTaskInterbankAsset(event) {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;

      try {
        const payload = {
          transactionCodeId: this.property.filterDto.transactionCodeId,
          dateFrom: event,
          dateTo: this.property.filterDto.dateTo,
          statusId: this.property.filterDto.statusAccount,
          page: 0,
          size: this.property.listElement.taskInterbankAsset.perPage,
        };
        const resp = await this.$store.dispatch(
          "GET_T_TASK_INTERBANK_ASSET",
          payload
        );
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
          this.property.listElement.taskInterbankAsset.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },
    async changeDateToTaskInterbankAsset(event) {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;

      try {
        const payload = {
          transactionCodeId: this.property.filterDto.transactionCodeId,
          dateFrom: this.property.filterDto.dateFrom,
          dateTo: event,
          statusId: this.property.filterDto.statusAccountId,
          page: 0,
          size: this.property.listElement.taskInterbankAsset.perPage,
        };
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset",
          payload: payload,
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
          this.property.listElement.taskInterbankAsset.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },

    async searchByTransactionTaskInterbankAsset(event) {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;

      try {
        const payload = {
          transactionCodeId: event,
          dateFrom: this.property.filterDto.dateFrom,
          dateTo: this.property.filterDto.dateTo,
          statusId: this.property.filterDto.statusAccountId,
          page: 0,
          size: this.property.listElement.taskInterbankAsset.perPage,
        };
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset",
          payload: payload,
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
          this.property.listElement.taskInterbankAsset.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },
    async searchByStatusAccountTaskInterbankAsset() {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;

      try {
        const payload = {
          transactionCodeId: this.property.filterDto.transactionCodeId,
          dateFrom: this.property.filterDto.dateFrom,
          dateTo: this.property.filterDto.dateTo,
          statusId: this.property.filterDto.statusAccountId,
          page: 0,
          size: this.property.listElement.taskInterbankAsset.perPage,
        };
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset",
          payload: payload,
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
          this.property.listElement.taskInterbankAsset.currentPage =
            resp.data.data.pageable.pageNumber + 1;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },
    async deleteTaskInterbankAsset(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Hapus Data Ini ?",
        "is-danger"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "DELETE_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset/" + props.row.taskInterbankAssetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankAsset.currentPage = 1;
          this.property.filterDto.name = "";
          this.property.filterDto.transactionCodeId = "";
          this.property.filterDto.dateFrom = "";
          this.property.filterDto.dateTo = "";
          this.property.filterDto.statusAccountId = "";
          this.property.listElement.taskInterbankAsset.rows = 0;
          this.helperGetDataTableTaskInterbankAsset();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    routeToPageAddTaskInterbankAsset() {
      this.$router.push("/task-interbank-asset/new");
    },
    routeToPageDetailTaskInterbankAsset(props) {
      this.$router.push(
        `/task-interbank-asset/${this.encryptBASE64(
          `${props.row.taskInterbankAssetId}`
        )}`
      );
    },
    async authorizationTaskInterbankAsset(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Otorisasi Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA_AUTHORIZED",
          endPoint: "transaction",
          reqUrl:
            "task-interbank-asset/authorized/" + props.row.taskInterbankAssetId,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          await this.simpleWait(1000);
          this.property.listElement.taskInterbankAsset.currentPage = 1;
          this.property.filterDto.name = "";
          this.property.filterDto.transactionCodeId = "";
          this.property.filterDto.dateFrom = "";
          this.property.filterDto.dateTo = "";
          this.property.filterDto.statusAccountId = "";
          this.property.listElement.taskInterbankAsset.rows = 0;
          this.helperGetDataTableTaskInterbankAsset();
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async changePaginationTaskInterbankAsset(event) {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset",
          payload: {
            transactionCodeId: this.property.filterDto.transactionCodeId,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusAccountId,
            page: event - 1,
            size: this.property.listElement.taskInterbankAsset.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },
    async helperGetDataTableTaskInterbankAsset() {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "task-interbank-asset",
          payload: {
            transactionCodeId: "",
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: "",
            statusId: "",
            page: 0,
            size: 10,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.taskInterbankAsset = resp.data.data.content;
          this.property.listElement.taskInterbankAsset.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetDataTaskInterbankAsset(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleCatchErrorTaskInterbankAsset(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.taskInterbankAsset.downloading = false;
      }
    },
    failedGetDataTaskInterbankAsset(resp) {
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.rows = 0;
      this.property.listElement.taskInterbankAsset.message = resp.data.message;
    },
    handleCatchErrorTaskInterbankAsset(error) {
      console.log(error.response);
      this.table.data.taskInterbankAsset = [];
      this.property.listElement.taskInterbankAsset.rows = 0;
      this.property.listElement.taskInterbankAsset.currentPage = 1;
      this.property.listElement.taskInterbankAsset.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    helperGetKodeTransaksiDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "transaction-code",
          params: {
            name: "",
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_TRX_ABA,
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = index.transactionName;
            const value = index.transactionCodeId;
            this.options.kodeTransaksi.push({ text, value });
          });
        });
    },
    helperChargingOptionsComponent() {
      this.options.kodeTransaksi.push({
        value: "",
        text: "-- Semua Kategori --",
      });
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log("this is status", resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.helperGetDataTableTaskInterbankAsset();
    this.helperChargingOptionsComponent();
    this.helperGetKodeTransaksiDataForReference();
    this.getReferenceStatusAccount();
  },
};
