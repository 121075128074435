import { jsPDF } from "jspdf";

export default {
  name: "FormTLoanPaid",
  props: {
    isExisting: false,
  },
  data() {
    return {
      property: {
        animation: {
          isLoading: false,
        },
        money: {
          precision: 2,
          decimal: ",",
          thousands: ".",
          prefix: "",
          suffix: "",
          masked: false,
        },
      },
      identity: {
        mainId: "",
      },
      dataForm: {
        transactionCodeId: "",
        description: "",
        marginPrincipal: "",
        marginRecognized: "",
        transactionDate: "",
        documentProof: "",
        reason: "",
        reasonStandartValue: "",
        totalPaidOff: "",
        outstandingPrincipal: "",
        savingAccountNumber: "",
        cifId: "",
        loanCifIdName: "",
        loanCifBranchName: "",
        savingAccountCifIdName: "",
        savingAccountBranchName: "",
        loanAccountNumber: "",
        discountMargin: "",
        remainingMarginToPaid: "",
        savingAccountBalanceUsable: 0,
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        alasanPelunasan: [],
        kodeTransaksi: [],
      },
    };
  },
  methods: {
    optionValueFinderText(arr, val) {
      var result = arr.find((index) => index.value === val);
      if (result) {
        return result.text;
      }
    },
    printOutLoanPaid() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      doc.setFontSize(9).text(`Nomor Referensi : -`, 0.55, 0.8);
      doc
        .setFontSize(9)
        .text(`Nomor Kontrak : ${this.dataForm.loanAccountNumber}`, 0.55, 1.1);
      doc
        .setFontSize(9)
        .text(`Nama Nasabah : ${this.dataForm.loanCifIdName}`, 0.55, 1.4);
      doc
        .setFontSize(9)
        .text(`Cabang : ${this.dataForm.loanCifBranchName}`, 0.55, 1.7);

      doc
        .setFontSize(9)
        .text(
          `Kode Transaksi : ${this.optionValueFinderText(
            this.options.kodeTransaksi,
            this.dataForm.transactionCodeId
          )}`,
          0.55,
          2
        );
      doc
        .setFontSize(9)
        .text(`Alasan Pelunasan : ${this.dataForm.reason}`, 0.55, 2.3);
      doc
        .setFontSize(9)
        .text(`No. Bukti : ${this.dataForm.documentProof}`, 0.55, 2.6);
      doc
        .setFontSize(9)
        .text(
          `Tgl. Transaksi : ${this.dateToYearMonthDay(
            this.dataForm.transactionDate
          )}`,
          0.55,
          2.9
        );
      doc
        .setFontSize(9)
        .text(
          `Rekening Tabungan : ${this.dataForm.savingAccountNumber}`,
          0.55,
          3.2
        );
      doc
        .setFontSize(9)
        .text(
          `Saldo Rekening Tabungan : ${this.convertCurrency(
            this.dataForm.savingAccountBalanceUsable
          )}`,
          0.55,
          3.5
        );
      doc
        .setFontSize(9)
        .text(
          `Sisa Pokok  : ${this.convertCurrency(
            this.dataForm.outstandingPrincipal
          )}`,
          0.55,
          3.8
        );
      doc
        .setFontSize(9)
        .text(
          `Sisa Margin  : ${this.convertCurrency(
            this.dataForm.marginPrincipal
          )}`,
          0.55,
          4.2
        );
      doc
        .setFontSize(9)
        .text(
          `Diskon (Margin)   : ${this.convertCurrency(
            this.dataForm.discountMargin
          )}`,
          0.55,
          4.5
        );

      doc
        .setFontSize(9)
        .text(
          `Sisa Margin Dibayar  : ${this.convertCurrency(
            this.dataForm.remainingMarginToPaid
          )}`,
          0.55,
          4.8
        );

      doc
        .setFontSize(9)
        .text(
          `Margin Diakui  : ${this.convertCurrency(
            this.dataForm.marginRecognized
          )}`,
          0.55,
          5.2
        );

      doc
        .setFontSize(9)
        .text(`Nama  : ${this.dataForm.savingAccountCifIdName}`, 0.55, 5.5);

      doc
        .setFontSize(9)
        .text(`Cabang  : ${this.dataForm.savingAccountBranchName}`, 0.55, 5.8);
      doc
        .setFontSize(9)
        .text(
          `Total Pelunasan  : ${this.convertCurrency(
            this.dataForm.totalPaidOff
          )}`,
          0.55,
          6.4
        );
      doc
        .setFontSize(9)
        .text(`Keterangan  : ${this.dataForm.description}`, 0.55, 6.1);

      doc.setFontSize(9).text("( Maker )", 0.55, pageHeight - 3);
      doc.setFontSize(9).text("( Taker )", 1.2, pageHeight - 3);
      doc.setFontSize(9).text("( Signer )", 1.82, pageHeight - 3);

      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    appendDefaultOptionSelect() {
      this.options.kodeTransaksi.push({ value: "", text: "-- Pilih --" });
    },
    changeDiscountMargin() {
      this.dataForm.marginRecognized =
        this.dataForm.marginPrincipal - this.dataForm.discountMargin;
      this.dataForm.totalPaidOff =
        this.dataForm.marginRecognized + this.dataForm.outstandingPrincipal;
      this.dataForm.remainingMarginToPaid =
        this.dataForm.marginPrincipal - this.dataForm.discountMargin;
    },
    getTransactionCodeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "transaction-code",
          params: {
            name: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            if (
              i.transactionCode ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_TRX_CODE_LOAN_PAID
            ) {
              const value = i.transactionCodeId;
              const text = `${i.transactionName} - ${i.transactionCode}`;
              const transactionName = i.transactionName;
              this.options.kodeTransaksi.push({ text, value, transactionName });
            }
          });
        });
    },
    handleRouteToPageListLoanPaid() {
      this.$router.push("/loan/loan-paid");
    },

    changeOptionsTransactionCode() {
      this.dataForm.description = "";
      this.options.kodeTransaksi.map((i) => {
        if (i.value === this.dataForm.transactionCodeId) {
          this.dataForm.description = `${i.transactionName} - ${this.dataForm.loanAccountNumber} - an  ${this.dataForm.savingAccountCifIdName}`;
        }
      });
    },
    changeOptionsPaidReason() {
      this.dataForm.reason = "";
      if (this.dataForm.reasonStandartValue !== "Lainya") {
        this.dataForm.reason = this.dataForm.reasonStandartValue;
      }
    },
    getRerencePaidReason() {
      this.options.alasanPelunasan = [
        { value: "", text: "-- Pilih --" },
        { value: "Profit Usaha", text: "Profit Usaha" },
        { value: "Bonus", text: "Bonus" },
        { value: "Warisan", text: "Warisan" },
        { value: "Pailit", text: "Pailit" },
        { value: "Meninggal", text: "Meninggal" },
        { value: "Take Over", text: "Take Over" },
        { value: "Ganti Akad", text: "Ganti Akad" },
        { value: "Lainya", text: "Lainnya" },
      ];
    },

    setupPaidReason() {
      var matchingData = this.options.alasanPelunasan.filter(
        (data) => data.value === this.dataForm.reason
      );
      if (matchingData.length === 0) {
        this.dataForm.reasonStandartValue = "Lainya";
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        var obj = payload;
        this.dataForm.transactionCodeId = obj.transactionCode
          ? obj.transactionCode.transactionCodeId
          : "";
        this.dataForm.description = obj.description;
        this.dataForm.marginPrincipal = obj.marginPrincipal;
        this.dataForm.marginRecognized = obj.marginRecognized;
        this.dataForm.transactionDate = obj.transactionDate;
        this.dataForm.documentProof = obj.documentProof;
        this.dataForm.reason = obj.reason;
        this.dataForm.reasonStandartValue = obj.reason;
        this.dataForm.totalPaidOff = obj.totalPaidOff;
        this.dataForm.outstandingPrincipal = obj.outstandingPrincipal;
        this.dataForm.savingAccountNumber = obj.savingAccount.accountNumber;
        this.dataForm.cifId = obj.savingAccount.mcif.cifId;
        this.dataForm.loanCifIdName = obj.savingAccount.mcif.cifIdName;
        this.dataForm.loanCifBranchName = obj.loanAccount.moffice.officeName;
        this.dataForm.savingAccountCifIdName = obj.savingAccount.mcif.cifIdName;
        this.dataForm.savingAccountBranchName =
          obj.savingAccount.mcif.moffice.officeName;
        this.dataForm.loanAccountNumber = obj.loanAccount.loanAccountNumber;
        this.dataForm.discountMargin = obj.discountMargin;
        this.dataForm.remainingMarginToPaid = "";
        this.dataForm.savingAccountBalanceUsable = payload.savingAccount
          ? payload.savingAccount.balanceUsable
          : "";
        this.dataForm.createdByUserCode = obj.createdByUserCode;
        this.dataForm.createdByUserName = obj.createdByUserName;
        this.dataForm.createdDate = obj.createdDate;
        this.dataForm.updatedDate = obj.updatedDate;
        this.dataForm.updatedByUserCode = obj.updatedByUserCode;
        this.dataForm.updatedByUserName = obj.updatedByUserName;
        this.dataForm.authorizedDate = obj.authorizedDate;
        this.dataForm.authorizedByUserCode = obj.authorizedByUserCode;
        this.dataForm.authorizedByUserName = obj.authorizedByUserName;
        this.setupPaidReason();
        return;
      }
      this.dataForm.loanAccountNumber = payload.loanAccountNumber;
      this.dataForm.loanCifIdName = payload.tloanSp3
        ? payload.tloanSp3.mcif.cifIdName
        : "";
      this.dataForm.loanCifBranchName = payload.moffice
        ? payload.moffice.officeName
        : "";
      this.dataForm.savingAccountNumber = payload.tsavingAccountInstallment
        ? payload.tsavingAccountInstallment.accountNumber
        : "";
      this.dataForm.outstandingPrincipal = payload.outstanding;
      this.dataForm.marginPrincipal = payload.outstandingMargin;
      this.dataForm.savingAccountCifIdName = payload.tsavingAccountInstallment
        ? payload.tsavingAccountInstallment.mcif
          ? payload.tsavingAccountInstallment.mcif.cifIdName
          : ""
        : "";
      this.dataForm.savingAccountBranchName = payload.tsavingAccountInstallment
        ? payload.tsavingAccountInstallment.mcif
          ? payload.tsavingAccountInstallment.mcif.moffice
            ? payload.tsavingAccountInstallment.mcif.moffice.officeName
            : ""
          : ""
        : "";
      this.dataForm.savingAccountBalanceUsable = payload.tsavingAccountInstallment
        ? payload.tsavingAccountInstallment.balanceUsable
        : "";
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.handleRouteToPageListLoanPaid();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query)).loanAccountId;
      this.$emit("getMainId", this.identity.mainId);
      this.dataForm.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    submitLoanPaid() {
      this.$emit("submitLoanPaid", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getTransactionCodeDataForReference();
    this.appendDefaultOptionSelect();
    this.getRerencePaidReason();
  },
};
