const MUserRole = {
  actions: {
    GET_M_USER_ROLE_MENU_ACCESS({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user-role/get-menu-access`,
            method: "GET",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_M_USER_ROLE_BY_ID_AFTER_SIGN({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user-role/${payload.id}`,
            method: "GET",
            headers: payload.header,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_M_USER_ROLE_BY_ID({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user-role/${payload.id}`,
            method: "GET",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_M_USER_ROLE_BY_ID_AFTER_SIGN({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user-role/${payload.id}`,
            method: "GET",
            headers: {
              "x-token-id": payload.tokenId,
            },
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_M_USER_ROLE_MAPPING_BY_ROLE_ID({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user-role/mapping-menu-access/${payload.id}`,
            method: "GET",
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
    GET_M_USER_ROLE_MAPPING_NEW_BY_ROLE_ID({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user-role/mapping-menu-access-new/${payload.id}`,
            method: "GET",
            params: payload.params,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },

    PUT_M_USER_ROLE_MAPPING_BY_ROLE_ID({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await axios({
            url: `${process.env.VUE_APP_BASE_URL_GATEWAY}/user-management/user-role/mapping-menu-access/${payload.id}`,
            method: "PUT",
            data: payload.data,
          });
          resolve(resp);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};

export default MUserRole;
