export default {
  name: "ListOverbook",
  components: {},
  data() {
    return {
      property: {
        listElement: {
          overbook: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          name: "",
          accountNumber: "",
          statusId: "",
        },
      },
      table: {
        data: {
          overbook: [],
        },
      },
      options: {
        statusAccount: [],
      },
    };
  },

  methods: {
    routeToPageDetail(props) {
      this.$router.push(
        `/saving/overbook/${this.encryptBASE64(`${props.row.referenceNumber}`)}`
      );
    },
    async findOverbook() {
      this.table.data.overbook = [];
      this.property.listElement.overbook.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch("GET_OVERBOOK", {
          accountNumber: "",
          transactionCode: this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_TRX_CODE_SAVING_OVERBOOK,
          name: this.property.filterDto.name,
          statusId: this.property.filterDto.statusId,
          page: 0,
          size: this.property.listElement.overbook.perPage,
        });

        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbook = resp.data.data.content;
            this.property.listElement.overbook.rows =
              resp.data.data.totalElements;
            this.property.listElement.overbook.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbook.downloading = true;
        }, timeout);
      }
    },

    handleDeleteDataOverbook(props) {},
    routeToPageAddOverbook() {
      this.$router.push("overbook/new");
    },
    handleRouteToPageEditOverbook(props) {},
    async authorizationOverbook(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "overbook/authorized/" + props.row.referenceNumber,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.overbook.currentPage = 1;
                  this.property.filterDto.accountNumber = "";
                  this.property.filterDto.name = "";
                  this.property.filterDto.statusId = "";
                  this.property.listElement.overbook.rows = 0;
                  this.helperGetDataTableOverbook();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    handleCatchErrorOverbook(error) {
      console.log(error.response);
      this.table.data.overbook = [];
      this.property.listElement.overbook.rows = 0;
      this.property.listElement.overbook.currentPage = 1;
      this.property.listElement.overbook.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationOverbook(event) {
      this.table.data.overbook = [];
      this.property.listElement.overbook.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "overbook",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_SAVING_OVERBOOK,
            name: this.property.filterDto.name,
            statusId: this.property.filterDto.statusId,
            page: event - 1,
            size: this.property.listElement.overbook.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbook = resp.data.data.content;
            this.property.listElement.overbook.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbook.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableOverbook() {
      this.table.data.overbook = [];
      this.property.listElement.overbook.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "overbook",
          payload: {
            accountNumber: "",
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_SAVING_OVERBOOK,
            statusId: "",
            name: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.overbook = resp.data.data.content;
            this.property.listElement.overbook.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOverbook(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorOverbook(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.overbook.downloading = false;
        }, timeout);
      }
    },
    failedGetDataOverbook(resp) {
      this.table.data.overbook = [];
      this.property.listElement.overbook.rows = 0;
      this.property.listElement.overbook.message = resp.data.message;
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.helperGetDataTableOverbook();
    this.getReferenceStatusAccount();
  },
};
