import Moment from "moment";

export default {
  name: "ClosingEoyCentralize",
  data() {
    return {
      loading: false,
      eomProgressPanel: {
        timer: 1000,
        open: false,
        data: [],
        loading: false,
      },
      property: {
        animation: {
          isDownloadingFile: false,
          closingEomCentralize: {
            isLoading: false,
          },
        },
        listElement: {
          endOfMonthResult: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          officeCode: "",
        },
      },
      dataForm: {
        lastUpdate: "",
        date: "",
        periode: "",
        displayPeriode: "",
        is_maintenance: false,
      },
      table: {
        data: {
          endOfMonthResult: [],
        },
      },
    };
  },
  methods: {
    Moment: Moment,
    prepareEndOfDay() {
      Moment.locale("ID");
      var endOfMonthDate = Moment()
        .endOf("month")
        .format();
      this.dataForm.date = Moment(endOfMonthDate).format("LL");
      this.dataForm.periode = this.dateToMonth(new Date(endOfMonthDate));
      this.dataForm.displayPeriode = this.dateToYear(
        this.fetchAppSession("@vue-session/application").system_date
      );
      this.property.listElement.endOfMonthResult.message = "No Data Processed";
    },

    resetEomProgressPanel(timeout) {
      setTimeout(() => {
        this.eomProgressPanel = {
          timer: 1000,
          open: false,
          data: [],
          status: {},
        };
      }, timeout);
    },
    closeEomProgressPanel() {
      this.resetEomProgressPanel(500);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    async fetchMaintaince() {
      this.dataForm.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.periode = this.dateToMonth(
        this.fetchAppSession("@vue-session/application").system_date
      );
      // this.dataForm.is_maintenance = this.fetchAppSession(
      //   "@vue-session/application"
      // ).is_maintenance;
    },
    async onChangeSystemMaintaince() {
      const confirm = await this.simpleConfirmation(
        "Lanjutkan Proses ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.property.animation.closingEomCentralize.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "process",
          reqUrl: "system/maintenance",
        });
        if (resp.data.code === "SUCCESS") {
          this.getIsMaintenance();
          window.location.reload();
          return;
        }
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.closingEomCentralize.isLoading = false;
      }
    },
    async processClosingEndOfMonth() {
      const confirm = await this.simpleConfirmation(
        "Proses EOY ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.postEomProccess();
    },
    async streamEom() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "close-end-of-month-centralize/eom-state-process",
          endPoint: "process",
        });
        if (resp.data.code !== "SUCCESS") {
          return;
        }
        const status = resp.data.data[0];
        console.log(status);
        if (status?.value === "IN_PROGRESS" && resp.data.data.length !== 0) {
          this.eomProgressPanel.data = resp.data.data;
          await this.simpleWait(this.eomProgressPanel.timer);
          this.streamEom();
          return;
        }
        if (status?.value === "DONE") {
          this.eomProgressPanel.data = resp.data.data;
        }
        this.eomProgressPanel.loading = false;
      } catch (error) {}
    },
    async postEomProccess() {
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          reqUrl: "close-end-of-month-centralize",
          endPoint: "process",
        });
        if (resp.data.code === "SUCCESS") {
          this.eomProgressPanel.open = true;
          this.eomProgressPanel.loading = true;
          this.streamEom();
          return;
        }
        this.resetEomProgressPanel(0);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        this.resetEomProgressPanel(0);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
      }
    },
    async postProfitShareEstimate() {
      const confirm = await this.simpleConfirmation(
        "Update Estimasi Bagi Hasil ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.loading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          reqUrl:
            "close-end-of-month-centralize/generate-current-profit-share-estimate",
          endPoint: "process",
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.dialog.alert({
            title: "Success !",
            message: "Update Estimasi Bagi Hasil Berhasil Di Lakukan. ",
            confirmText: "Ok",
            type: "is-success",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async getIsMaintenance() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "process",
          reqUrl: "system",
        });
        console.log("status => ", resp);
        if (resp.data.code === "SUCCESS") {
          this.dataForm.is_maintenance = resp.data.data.is_maintenance;
          console.log(this.dataForm.is_maintenance)
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getIsMaintenance()
    this.prepareEndOfDay();
    this.fetchMaintaince();
  },
};
