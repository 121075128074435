<template>
  <div>
    <b-modal
      :on-cancel="closePreview"
      v-model="showModal"
      :width="1000"
      scroll="clip"
    >
      <div class="modal-card h-screen" style="width: auto;">
        <header class="modal-card-head">
          <span class="text-base">Preview</span>
        </header>
        <div id="output-canvas" class="modal-card-body w-full p-3">
          <div class="flex justify-center w-full items-center flex-col p-0">
            <div class="w-full flex justify-between items-start flex-row">
              <span class="transaction-umb-print-out-font font-bold"
                >HIK PARAHYANGAN</span
              >
              <span class="transaction-umb-print-out-font font-bold"
                >Tanggal :
                {{
                  printOutData ? printOutData.letterhead.printDate : "-"
                }}</span
              >
            </div>
            <div class="w-full mt-1 flex justify-between items-start flex-row">
              <span class="transaction-umb-print-out-font font-bold">{{
                printOutData ? printOutData.letterhead.officeName : "-"
              }}</span>
              <span class="font-bold"></span>
            </div>
            <div class="w-full mt-1 flex justify-center items-center flex-col">
              <span class="transaction-umb-print-out-font font-bold"
                >TRANSAKSI UMB</span
              >
              <span class="transaction-umb-print-out-font font-bold mt-1"
                >SUKSES PER TANGGAL :
                {{
                  printOutData ? printOutData.letterhead.filteredDate : "-"
                }}</span
              >
            </div>
            <b-table
              class="mt-3"
              narrowed
              v-if="printOutData"
              :data="printOutData.tableData"
            >
              <template v-slot:empty>
                <div class="flex justify-center items-center p-3">
                  <span class="transaction-umb-print-out-font">
                    No Data Found
                  </span>
                </div>
              </template>
              <b-table-column centered field="cashAdvanceCode" label="Kode UMB">
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.cashAdvanceCode
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="cashAdvanceName" label="Nama UMB">
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.cashAdvanceName
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="departmentName" label="Bagian">
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.departmentName
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="description" label="Keterangan">
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.description
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="rcashAdvancePaymentType"
                label="Opsi Pengembalian"
              >
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.rcashAdvancePaymentType
                      ? props.row.rcashAdvancePaymentType.paymentTypeName
                      : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="returnDescription"
                label="Keterangan Pengembalian"
              >
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.returnDescription
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="realizationDate"
                label="Tanggal Realisasi"
              >
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.realizationDate ? props.row.realizationDate : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="returnDate"
                label="Rencana Pengembalian"
              >
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.returnDate
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="nominal" label="Nominal">
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.nominal ? props.row.nominal : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="statusName" label="Status">
                <template v-slot:header="{ column }">
                  <span class="transaction-umb-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="transaction-umb-print-out-font">{{
                    props.row.statusName ? props.row.statusName : "-"
                  }}</span>
                </template>
              </b-table-column>
            </b-table>
            <div
              class="mt-20 ml-4 flex justify-start align-start w-full flex-col"
            >
              <div>
                <span class="font-bold transaction-umb-print-out-font mr-2">
                  ( Maker )
                </span>
                <span class="font-bold transaction-umb-print-out-font mr-2">
                  ( Checker )
                </span>
                <span class="font-bold transaction-umb-print-out-font">
                  ( Signer )
                </span>
              </div>
              <div>
                <span class="font-bold transaction-umb-print-out-font">
                  Print By : {{ printOutData ? printOutData.printBy : "-" }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button @click="closePreview" label="Batal" />
          <b-button
            :loading="isLoading"
            :disabled="isLoading"
            @click="onPressPrint"
            label="Print Out"
            type="is-link"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "TransactionListUmbPdf",
  data() {
    return {
      showModal: false,
      isLoading: false,
      printOutData: null,
    };
  },

  methods: {
    closePreview() {
      this.showModal = false;
      this.printOutData = null;
    },
    onOpenPreview(payload) {
      this.showModal = true;
      this.printOutData = payload;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        margin: 0.1,
        filename: `UMB.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      // New Promise-based usage:
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>

<style>
.transaction-umb-print-out-font {
  font-size: 8px;
}
</style>
