import FormSipendar from "../form";

export default{
    name: "ExistingSipendar",
    components: {
        FormSipendar,
    },
    data(){
        return{
            identity: {
              mainId: "",
            },
            message: {},
        }
    },
    methods: {
        getMainId(props) {
            this.identity.mainId = props;
            this.getSipendarById();
        },
        async getSipendarById() {
            if (window.navigator.onLine === false) {
              this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
              this.$refs.formSipendarRef.backToList();
              return;
            }
            try {
              const resp = await this.$store.dispatch({
                type: "GET_DATA_FIND_BY",
                endPoint: "transaction",
                reqUrl: "sipendar/" + this.identity.mainId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$refs.formSipendarRef.getDefaultFormValue(
                  resp.data.data
                );
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                  onConfirm: () => this.$refs.formSipendarRef.backToList(),
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : `Terjadi Kesalahan !`,
                confirmText: "Ok",
                type: "is-danger",
                onConfirm: () => this.$refs.formSipendarRef.backToList(),
              });
            }
        },
        async editSipendar(payload) {
          const data = {
            idCardNumber: payload.idCardNumber,
            otherCardNumber: payload.otherCardNumber,
            npwpNumber: payload.npwpNumber,
            periode: payload.periode,
            rtypePersonId: payload.rtypePersonId,
            sipendarName: payload.sipendarName,
            watchlistCode: payload.watchlistCode,
            birthPlace: payload.birthPlace,
            birthDate: payload.birthDate,
          };
          if (window.navigator.onLine === false) {
            this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
            return;
          }
          const confirmation = await this.simpleConfirmation(
            "Edit Data Ini ?",
            "is-success"
          );
          if (!confirmation) {
            return;
          }
          this.$refs.formSipendarRef.property.animation.submitLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA",
              endPoint: "transaction",
              reqUrl: "sipendar/" + this.identity.mainId,
              payload: data,
            });
            await this.simpleWait(1500);
            if (resp.data.code === "SUCCESS") {
              this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
              this.$refs.formSipendarRef.routeToPageListSipendar()
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            await this.simpleWait(1500);
            this.message = error.response?.data?.errorFields;
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : `<b>${error.response.statusText}</b> <br/> <br/> ${error.response.message} `
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          } finally {
            await this.simpleWait(1000);
            this.$refs.formSipendarRef.property.animation.submitLoading = false;
          }
        },
    },
}