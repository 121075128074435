export default {
  name: "FormRak",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
        },
        filterDto: {
          officeName: "",
        },
      },
      dataForm: {
        originOfficeName: "",
        interOfficeAccountId: "",
        description: "",
        profitSharePercent: "",
        accountNumber: "",
        destinationOfficeId: "",
        originOfficeId: "",
        chartOfAccountId: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        isAssets: null,
      },
      options: {
        cabang: [],
        chartOfAccountId: [],
        posisiNeraca: [
          { text: "-- Pilih --", value: "" },
          { text: "Aktiva", value: true },
          { text: "Pasiva", value: false },
        ],
      },
    };
  },
  methods: {
    getIdentityFromSession() {
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      console.log(getDataFromSession, this.options.cabang);
      this.property.filterDto.officeName = getDataFromSession.officeName;

      const findUserByUserCode = this.options.cabang.find(
        (index) => index.officeCode === getDataFromSession.officeCode
      );
      console.log(findUserByUserCode, "test");
      this.dataForm.originOfficeId = findUserByUserCode.value;
    },
    appendDefaultOptionSelect() {
      const defaultOption = { value: "", text: "-- Pilih --" };
      this.options.cabang.push(defaultOption);
      this.options.chartOfAccountId.push(defaultOption);
    },

    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              officeName: "",
              page: 0,
            },

            url: "office",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const value = index.officeId;
            const text = index.officeName;
            const officeCode = index.officeCode;
            this.options.cabang.push({
              value,
              text,
              officeCode,
            });
          });
          this.getIdentityFromSession();
        }
      } catch (error) {}
    },

    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              description: "",
              page: 0,
            },
            url: "chart-of-account",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.chartOfAccountCode}-${index.description}`;
            const value = index.chartOfAccountId;
            const deskripsi = index.description;
            this.options.chartOfAccountId.push({ text, value, deskripsi });
          });
        }
      } catch (error) {}
    },

    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.dataForm.interOfficeAccountId = payload.interOfficeAccountId;
        this.dataForm.description = payload.description;
        this.dataForm.profitSharePercent = payload.profitSharePercent;
        this.dataForm.destinationOfficeId = payload.destinationOffice
          ? payload.destinationOffice.officeId
          : "";
        this.dataForm.accountNumber = payload.accountNumber;
        this.dataForm.originOfficeId = payload.originOffice
          ? payload.originOffice.officeId
          : "";
        this.dataForm.originOfficeName = payload.originOffice
          ? payload.originOffice.officeName
          : "";
        this.dataForm.chartOfAccountId = payload.chartOfAccount
          ? payload.chartOfAccount.chartOfAccountId
          : "";
        this.dataForm.isAssets = payload.isAssets;
        this.dataForm.createdByUserCode = payload.createdByUserCode;
        this.dataForm.createdByUserName = payload.createdByUserName;
        this.dataForm.createdDate = payload.createdDate;
        this.dataForm.updatedDate = payload.updatedDate;
        this.dataForm.updatedByUserCode = payload.updatedByUserCode;
        this.dataForm.updatedByUserName = payload.updatedByUserName;
        this.dataForm.authorizedDate = payload.authorizedDate;
        this.dataForm.authorizedByUserCode = payload.authorizedByUserCode;
        this.dataForm.authorizedByUserName = payload.authorizedByUserName;
        return;
      }
    },
    routeToPageList() {
      this.$router.push("/parameter/rak/");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitMRak() {
      this.$emit("submitMRak", this.dataForm);
    },
  },
  mounted() {
    this.checkingMainId();
    this.getReferenceChartOfAccount();
    this.getReferenceOffice();
    this.appendDefaultOptionSelect();
  },
};
