var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-between flex-wrap flex-row"},[_c('div',{staticClass:"w-full flex justify-center items-start flex-col",staticStyle:{"max-width":"400px"}},[_c('div',{staticClass:"flex justify-between mb-1 flex-wrap items-center flex-row w-full",staticStyle:{"max-width":"340px"},attrs:{"inline":""}},[_c('span',{staticClass:"text-xs"},[_vm._v("Deskripsi Konfigurasi")]),_c('b-input',{attrs:{"lazy":true,"size":"is-small"},on:{"input":_vm.findConfiguration},model:{value:(_vm.property.filterDto.configDescription),callback:function ($$v) {_vm.$set(_vm.property.filterDto, "configDescription", $$v)},expression:"property.filterDto.configDescription"}})],1)])]),_c('b-table',{staticClass:"my-4",attrs:{"mobile-cards":false,"data":_vm.table.data.configuration,"striped":"","bordered":"","narrowed":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center p-3"},[(_vm.property.listElement.configuration.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(_vm.property.listElement.configuration.downloading === false)?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.configuration.message ? _vm.property.listElement.configuration.message : "DATA NOT FOUND")+" ")]):_vm._e()],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Value","field":"configValue","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.configValue))])]}}])}),_c('b-table-column',{attrs:{"label":"Kode Konfigurasi","field":"configCode","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.configCode))])]}}])}),_c('b-table-column',{attrs:{"label":"Deskripsi","field":"configDescription","centered":"","header-class":"bprs-hik-theme-background"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.configDescription))])]}}])}),_c('b-table-column',{attrs:{"label":"Aksi","centered":"","header-class":"bprs-hik-theme-background","cell-class":"py-2"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"text-xs text-white"},[_vm._v(" "+_vm._s(column.label)+" ")])]}},{key:"default",fn:function(props){return [_c('b-button',{staticClass:"button-theme-confirm",attrs:{"type":"is-success","label":"","size":"is-small","icon-left":"eye"},on:{"click":function($event){return _vm.rouetToPageDetail(props)}}})]}}])})],1),_c('b-pagination',{attrs:{"total":_vm.property.listElement.configuration.rows,"range-before":1,"range-after":1,"order":"is-left","size":"is-small","per-page":_vm.property.listElement.configuration.perPage,"icon-prev":"chevron-left","icon-next":"chevron-right"},on:{"change":_vm.changePaginationConfiguration},model:{value:(_vm.property.listElement.configuration.currentPage),callback:function ($$v) {_vm.$set(_vm.property.listElement.configuration, "currentPage", $$v)},expression:"property.listElement.configuration.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }