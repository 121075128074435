export default {
  name: "ConfigurationFrontendList",
  data() {
    return {
      property: {
        listElement: {
          configuration: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          configDescription: "",
        },
      },
      table: {
        data: {
          configuration: [],
        },
      },
    };
  },
  methods: {
    async findConfiguration() {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "configuration-frontend",
          payload: {
            configDescription: this.property.filterDto.configDescription,
            page: 0,
            size: this.property.listElement.virtualAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.configuration = resp.data.data.content;
            this.property.listElement.configuration.rows =
              resp.data.data.totalElements;
            this.property.listElement.configuration.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataConfiguration(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorConfiguration(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.configuration.downloading = false;
        }, timeout + 500);
      }
    },
    rouetToPageDetail(props) {
      this.$router.push(
        `/admin/configuration-frontend/${this.encryptBASE64(
          `${props.row.configCode}`
        )}`
      );
    },
    handleErrorConfiguration(error) {
      this.table.data.configuration = [];
      this.property.listElement.configuration.rows = 0;
      this.property.listElement.configuration.currentPage = 1;

      this.property.listElement.configuration.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationConfiguration(event) {
      this.table.data.configuration = [];
      this.property.listElement.configuration.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "configuration-frontend",
          payload: {
            configDescription: this.property.filterDto.configDescription,
            page: event - 1,
            size: this.property.listElement.configuration.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.configuration = resp.data.data.content;
            this.property.listElement.configuration.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataConfiguration(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorConfiguration(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.configuration.downloading = false;
        }, timeout);
      }
    },
    async getConfiguration() {
      this.table.data.configuration = [];
      this.property.listElement.configuration.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "configuration-frontend",
          payload: {
            configDescription: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.configuration = resp.data.data.content;
            this.property.listElement.configuration.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataConfiguration(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorConfiguration(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.configuration.downloading = false;
        }, timeout);
      }
    },
    failedGetDataConfiguration(resp) {
      this.table.data.configuration = [];
      this.property.listElement.configuration.rows = 0;
      this.property.listElement.configuration.message = resp.data.message;
    },
  },
  mounted() {
    this.getConfiguration();
  },
};
