var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex flex-col flex-start",staticStyle:{"max-width":"600px"}},[_c('form-builder',{attrs:{"message-mapper":_vm.formMessage,"scheme":{
        loanCollateralCategoryId: {
          label: 'Kategori Jaminan',
          type: 'select',
          models: 'loanCollateralCategoryId',
          selectProperty: {
            options: _vm.options.loanCollateralCategory,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
        },
        description: {
          label: 'Nama Jaminan',
          type: 'text',
          models: 'description',
        },
        collateralBiCode: {
          label: 'Kode Sandi OJK',
          type: 'model-list-select',
          models: 'collateralBiCode',
          placeholder: 'Cari Kode OJK',
          selectProperty: {
            options: _vm.options.kodeOJK,
            bindValue: 'value',
            bindText: function (e) {
              return ("" + (e.text));
            },
          },
        },
        collateralWeight: {
          type: 'number',
          label: 'Bobot Jaminan',
          models: 'collateralWeight',
          appendText: '%',
        },
        htLess: {
          label: 'HT ( < 2 tahun )',
          type: 'number',
          models: 'htLess',
          appendText: '%',
        },
        htMore: {
          label: 'Non HT ( > 2 tahun )',
          type: 'number',
          appendText: '%',
          models: 'htMore',
        },
      }},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('div',{staticClass:"flex mt-5 justify-center w-full"},[_c('div',{staticClass:"w-full flex justify-between items-center flex-row",staticStyle:{"max-width":"180px"}},[_c('b-button',{staticClass:"button-theme-cancel",on:{"click":_vm.routeToPageListCollateralGroup}},[_vm._v("Kembali")]),_c('b-button',{staticClass:"button-theme-confirm",attrs:{"loading":_vm.property.animation.submitLoading,"type":"is-success","disabled":!_vm.$store.getters['getActionsInsert'] ||
            _vm.property.animation.submitLoading === true},on:{"click":_vm.submitCollateralGroup}},[_vm._v(" Simpan ")])],1)]),(_vm.isExisting === true)?_c('MoreInformationDetail',{attrs:{"createdByUserCode":_vm.form.createdByUserCode,"createdByUserName":_vm.form.createdByUserName,"createdDate":_vm.form.createdDate,"updatedDate":_vm.form.updatedDate,"updatedByUserCode":_vm.form.updatedByUserCode,"updatedByUserName":_vm.form.updatedByUserName,"authorizedDate":_vm.form.authorizedDate,"authorizedByUserCode":_vm.form.authorizedByUserCode,"authorizedByUserName":_vm.form.authorizedByUserName}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }