import ModalSearchSavingAccount from "../../../../components/modal-search-saving-account";
export default {
  name: "FormTTaskInterbankAsset",
  props: {
    isExisting: false,
  },
  components: {
    ModalSearchSavingAccount,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      form: {
        transactionCodeId: "",
        documentProofNumber: "",
        nominal: 0,
        description: "",
        sourceSavingAccountNumber: "",
        transactionDate: "",
        targetSavingAccountNumber: "",
        sourceCoaNumber: "",
        targetCoaNumber: "",
        sourceAccountId: "",
        targetAccountId: "",
        officeId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        sourceAccountResult: {
          accountName: "",
          branch: "",
          description: "",
        },
        targetAccountResult: {
          accountName: "",
          branch: "",
          description: "",
        },
      },
      options: {
        kodeTransaksi: [],
        sourceAccount: [],
        targetAccount: [],
        chartOfAccount: [],
        interbankAssets: [],
        sourceAccountType: [],
        targetAccountType: [],
        office: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
        modal: {
          showModalFindSavingAccountSource: false,
          showModalFindSavingAccountDestination: false,
        },
      },
    };
  },
  methods: {
    showModalFindSavingAccount(type) {
      switch (type) {
        case "source":
          this.property.modal.showModalFindSavingAccountSource = true;
          break;
        case "destination":
          this.property.modal.showModalFindSavingAccountDestination = true;
          break;
        default:
          break;
      }
    },
    selectSavingAccountSource(props) {
      this.form.sourceSavingAccountNumber = props.row.accountNumber;
      this.form.sourceAccountId = props.row.savingAccountId;
      this.form.sourceAccountResult.accountName = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
      this.form.sourceAccountResult.branch = props.row.officeName;
    },
    selectSavingAccountDestination(props) {
      this.form.targetSavingAccountNumber = props.row.accountNumber;
      this.form.targetAccountId = props.row.savingAccountId;
      this.form.targetAccountResult.accountName = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
      this.form.targetAccountResult.branch = props.row.officeName;
    },
    changeSelectOptionsGetCoaDetailTarget() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.targetCoaNumber) {
          this.form.targetAccountResult.description = index.description;
          this.form.targetAccountId = index.value;
        }
      });
    },
    changeGetCoaDetailSource() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.sourceCoaNumber) {
          this.form.sourceAccountResult.description = index.description;
          this.form.sourceAccountId = index.value;
        }
      });
    },
    changeSelectOptionsTargetCoa() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.targetAccountId) {
          this.form.targetAccountResult.description = index.description;
        }
      });
    },
    changeSelectOptionsSourceCoa() {
      this.options.chartOfAccount.map((index) => {
        if (index.value === this.form.sourceAccountId) {
          this.form.sourceAccountResult.description = index.description;
        }
      });
    },
    changeSourceRekeningAba() {
      this.options.interbankAssets.map((index) => {
        if (index.value === this.form.sourceAccountId) {
          this.form.sourceAccountResult.accountName = index.accountName;
          this.form.sourceAccountResult.branch = index.accountBranch;
        }
      });
    },
    changeTargetRekeningAba() {
      this.options.interbankAssets.map((index) => {
        if (index.value === this.form.targetAccountId) {
          this.form.targetAccountResult.accountName = index.accountName;
          this.form.targetAccountResult.branch = index.accountBranch;
        }
      });
    },
    changeKodeTransaksi() {
      this.form.sourceAccountId = "";
      this.form.sourceAccountResult.accountName = "";
      this.form.sourceAccountResult.branch = "";
      this.form.sourceAccountResult.description = "";
      this.form.sourceCoaNumber = "";
      this.form.sourceSavingAccountNumber = "";
      this.form.targetSavingAccountNumber = "";
      this.form.targetCoaNumber = "";
      this.form.targetAccountId = "";
      this.form.targetAccountResult.accountName = "";
      this.form.targetAccountResult.branch = "";
      this.form.targetAccountResult.description = "";
    },
    helperGetTransactionCodeDataForReference() {
      const payload = {
        url: "transaction-code",
        params: {
          name: "",
          page: 0,
          menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_TRX_MENU_ACCESS_TRX_ABA,
        },
      };
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", payload)
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const value = index.transactionCodeId;
            const text = `${index.transactionCode} - ${index.transactionName}`;
            if (text.includes("ABA")) {
              this.options.kodeTransaksi.push({ text, value });
            }
          });
        });
    },
    helperGetChartOfAccountDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "chart-of-account",
          params: {
            description: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const value = index.chartOfAccountId;
            const removeSpaceInSubsidaryLedgerNumber = `${index.chartOfAccountCode}`.trim();
            const description = `${index.description}`.trim();
            const text = `${removeSpaceInSubsidaryLedgerNumber} - ${description}`;
            this.options.chartOfAccount.push({ text, value, description });
          });
        });
    },
    routeToPageListInterbankAssets() {
      this.$router.push("/task-interbank-asset");
    },
    appendDefaultOptions() {
      this.options.kodeTransaksi.push({ value: "", text: "-- Pilih --" });
      this.options.sourceAccountType.push({ value: "", text: "-- Pilih --" });
      this.options.targetAccountType.push({ value: "", text: "-- Pilih --" });
    },
    async getReferenceInterbankAsset() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "interbankAssets/list",
          endPoint: "transaction",
        });
        if ((resp.data.code = "SUCCESS")) {
          this.options.interbankAssets = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const value = index.interbankAssetsId;
            const text = `${index.assetsTypeName} - ${index.accountNumber} - ${index.bankName}`;
            const accountName = index.accountName;
            const accountBranch = `${index.bankName} - Cabang ${index.accountBranch}`;
            this.options.interbankAssets.push({
              text,
              value,
              accountName,
              accountBranch,
            });
          });
        }
      } catch (error) {}
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "office/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.officeName} - ${index.officeCode}`;
            const value = index.officeId;
            const officeName = index.officeName;
            this.options.office.push({
              text,
              value,
              officeName,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async helperGetinterbankAssetsAccount(type) {
      const payload = {
        accountNumber:
          type === "SOURCE"
            ? this.form.sourceinterbankAssetsAccountNumber
            : this.form.targetinterbankAssetsAccountNumber,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch(
            "GET_M_INTERBANK_ASSETS_BY_ACCOUNT_NUMBER",
            payload
          );
          if (resp.data.code === "SUCCESS") {
            switch (type) {
              case "SOURCE":
                this.form.sourceAccountResult.accountName =
                  resp.data.data.accountName;
                this.form.sourceAccountResult.branch =
                  resp.data.data.accountBranch;
                break;
              case "TARGET":
                this.form.targetAccountResult.accountName =
                  resp.data.data.accountName;
                this.form.targetAccountResult.branch =
                  resp.data.data.accountBranch;

              default:
                return;
                break;
            }
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async helperGetTargetSavingAccount(type) {
      try {
        const payload = {
          accountNumber:
            type === "SOURCE"
              ? this.form.sourceSavingAccountNumber
              : this.form.targetSavingAccountNumber,
        };
        const resp = await this.$store.dispatch(
          "GET_T_SAVING_ACCOUNT_BY_ACCOUNT_NUMBER",
          payload
        );
        if (resp.data.code === "SUCCESS") {
          switch (type) {
            case "SOURCE":
              this.form.sourceAccountResult.accountName = resp.data.data.mcif
                ? resp.data.data.mcif.cifIdName
                : "";
              this.form.sourceAccountResult.branch = resp.data.data.mcif
                ? resp.data.data.mcif.officeId
                  ? resp.data.data.mcif.officeId.officeName
                  : ""
                : "";

              break;
            case "TARGET":
              this.form.targetAccountResult.accountName = resp.data.data.mcif
                ? resp.data.data.mcif.cifIdName
                : "";
              this.form.targetAccountResult.branch = resp.data.data.mcif
                ? resp.data.data.mcif.officeId
                  ? resp.data.data.mcif.officeId.officeName
                  : ""
                : "";

              break;

            default:
              return;
              break;
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    handlingSelectorAccountSourceAndTarget(payload) {
      switch (payload.mtransactionCode.transactionCodeId) {
        case "51":
          this.form.sourceinterbankAssetsAccountNumber =
            payload.sourceAccountNumber;
          this.form.targetSavingAccountNumber = payload.targetAccountNumber;
          this.helperGetinterbankAssetsAccount("SOURCE");
          this.helperGetTargetSavingAccount("TARGET");
          break;
        case "52":
          this.form.sourceCoaNumber = payload.sourceAccountNumber;
          this.form.sourceAccountResult.description = payload.sourceAccountName;
          this.form.targetinterbankAssetsAccountNumber =
            payload.targetAccountNumber;
          this.helperGetinterbankAssetsAccount("TARGET");
          break;
        case "54":
          this.form.sourceSavingAccountNumber = payload.sourceAccountNumber;
          this.form.targetinterbankAssetsAccountNumber =
            payload.targetAccountNumber;
          this.helperGetTargetSavingAccount("SOURCE");
          this.helperGetinterbankAssetsAccount("TARGET");
          break;
        case "55":
          this.form.sourceinterbankAssetsAccountNumber =
            payload.sourceAccountNumber;
          this.form.targetinterbankAssetsAccountNumber =
            payload.targetAccountNumber;
          this.helperGetinterbankAssetsAccount("SOURCE");
          this.helperGetinterbankAssetsAccount("TARGET");
          break;
        case "56":
          this.form.sourceinterbankAssetsAccountNumber =
            payload.sourceAccountNumber;
          this.form.targetCoaNumber = payload.targetAccountNumber;
          this.form.targetAccountResult.description = payload.targetAccountName;
          this.helperGetinterbankAssetsAccount("SOURCE");
          break;
        default:
          return;
          break;
      }
    },
    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form.transactionCodeId =
          payload.mtransactionCode.transactionCodeId;
        this.form.documentProofNumber = payload.documentProofNumber;
        this.form.officeId = payload.office?.officeId;
        this.form.description = payload.description;
        this.form.nominal = payload.nominal;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        this.form.referenceNumberSourceAccount =
          payload.referenceNumberSourceAccount;
        this.form.referenceNumberTargetAccount =
          payload.referenceNumberTargetAccount;
        this.form.createdByUserCode = payload.createdByUserCode;
        this.form.createdByUserName = payload.createdByUserName;
        this.form.createdDate = payload.createdDate;
        this.form.updatedDate = payload.updatedDate;
        this.form.updatedByUserCode = payload.updatedByUserCode;
        this.form.updatedByUserName = payload.updatedByUserName;
        this.form.authorizedDate = payload.authorizedDate;
        this.form.authorizedByUserCode = payload.authorizedByUserCode;
        this.form.authorizedByUserName = payload.authorizedByUserName;
        this.handlingSelectorAccountSourceAndTarget(payload);
        return;
      }
    },
    checkingMainId() {
      var params = this.$route.params.id;
      if (this.isExisting === true && params) {
        this.identity.mainId = this.decryptBASE64(params);
        this.$emit("getMainId", this.identity.mainId);
      }
    },
    submitTaskInterbankAssets() {
      this.$emit("submitTaskInterbankAssets", this.form);
    },
  },
  mounted() {
    this.checkingMainId();
    this.appendDefaultOptions();
    this.helperGetTransactionCodeDataForReference();
    this.helperGetChartOfAccountDataForReference();
    this.getReferenceInterbankAsset();
    this.getReferenceOffice();
    this.form.transactionDate = this.fetchAppSession(
      "@vue-session/application"
    ).system_date;
  },
};
