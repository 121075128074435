import html2pdf from "html2pdf.js";

export default {
  name: "FormTDepositAccount",
  props: {
    isExisting: false,
  },
  data() {
    return {
      identity: {
        mainId: "",
      },
      property: {
        animation: {
          submitLoading: false,
          modal: {
            showModalPrintDeposito: false,
          },
        },
      },
      form: {
        isBpjs: false,
        referalOfficeId: "",
        referalOfficeName: "",
        accountNumber: "",
        profitShareAddPercent: 0,
        profitShareTotalPercent: 0,
        isRollover: true,
        openDate: "",
        timePeriode: 0,
        dueDate: "",
        nominal: "",
        isTaxable: true,
        taxRestitutionNumber: "",
        isZakat: false,
        infaqNominal: "",
        qqName: "",
        qqCode: "",
        rolloverCount: "",
        accountOfficerHandleId: "",
        accountOfficerPromoId: "",
        disburseProfitShareAccount: "",
        disburseProfitShareAccountName: "",
        profitShareBankCode: "",
        profitShareBankName: "",
        profitShareBankAccount: "",
        profitShareBankAccountName: "",
        profitShareBankCost: "",
        profitShareBankNews: "",
        depositTransferBankCode: "",
        depositTransferBankName: "",
        depositTransferBankAccount: "",
        depositTransferBankAccountName: "",
        depositTransferBankCost: "",
        depositTransferBankNews: "",
        isBlocked: false,
        transactionDate: "",
        blockedDate: "",
        accountClassificationId: "",
        depositId: "",
        rdepositOptionDisburseDepositId: "",
        rblockedReasonId: "",
        rsourceFundId: "",
        rpurposeOpenAccountId: "",
        rdepositOptionDisburseFundId: "",
        rdepositOptionDisburseProfitShareId: "",
        cifCategoryName: "",
        cifNumber: "",
        phoneNumber: "",
        regionalCode: "",
        cifPhoneNumber: "",
        cifIdAddress: "",
        companyRelatedId: "",
        groupOwnerId: "",
        depositAkadTypeId: "",
        depositTimeTypeName: "",
        mdepositNisbahChartOfAccountId: "",
        nisbah: 0,
        disburseFundAccount: "",
        disburseFundAccountName: "",
        disburseDepositAccount: "",
        disburseDepositAccountName: "",
        smsNotification: false,
        accountNumberName: "",
        savingProfitShareAccountNumberName: "",
        savingDepositAccountNumberName: "",
        depositProfitShareTypeName: "",
        cifId: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        commitRate: 0,

        // print
        depositName: "",
        rblockedReasonName: "",
        rsourceFundName: "",
        rpurposeOpenAccountName: "",
        cifGroupOwnerName: "",
        cifCompanyRelatedName: "",
        accountOfficerPromoName: "",
        accountOfficerHandleName: "",
        mdepositNisbahChartOfAccountName: "",
        rdepositOptionDisburseFundName: "",
        rdepositOptionDisburseProfitShareName: "",
        rdepositOptionDisburseDepositName: "",
      },
      options: {
        qq: [],
        referalOffice: [],
        produkDeposito: [],
        sumberDana: [],
        tujuanBukaDeposito: [],
        hubunganDenganBank: [],
        golonganPemilik: [],
        penyampaianStatement: [],
        aoPromosi: [],
        aoHandling: [],
        sumberPenempatanDana: [],
        sumberTransferBagiHasil: [],
        sumberTransferPokok: [],
        alasanDiblokir: [],
        klasifikasiRekening: [],
        jangkaWaktu: [],
        caraBagiHasil: [],
        savingAccount: [],
        chartOfAccount: [],
      },
    };
  },
  methods: {
    /* show modal print */
    downloadPdf() {
      this.property.animation.modal.showModalPrintDeposito = true;
    },
    closePreview() {
      this.property.animation.modal.showModalPrintDeposito = false;
    },
    pembilang(bilangan, append) {
      bilangan = String(bilangan);
      var angka = new Array(
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0"
      );
      var kata = new Array(
        "",
        "Satu",
        "Dua",
        "Tiga",
        "Empat",
        "Lima",
        "Enam",
        "Tujuh",
        "Delapan",
        "Sembilan"
      );
      var tingkat = new Array("", "Ribu", "Juta", "Milyar", "Triliun");

      var panjang_bilangan = bilangan.length;

      /* pengujian panjang bilangan */
      if (panjang_bilangan > 15) {
        kaLimat = "Diluar Batas";
        return kaLimat;
      }
      var i;
      var j;
      var kaLimat;
      var subkaLimat;
      var kata1;
      var kata2;
      var kata3;
      /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
      for (i = 1; i <= panjang_bilangan; i++) {
        angka[i] = bilangan.substr(-i, 1);
      }

      i = 1;
      j = 0;
      kaLimat = "";

      /* mulai proses iterasi terhadap array angka */
      while (i <= panjang_bilangan) {
        subkaLimat = "";
        kata1 = "";
        kata2 = "";
        kata3 = "";

        /* untuk Ratusan */
        if (angka[i + 2] != "0") {
          if (angka[i + 2] == "1") {
            kata1 = "Seratus";
          } else {
            kata1 = kata[angka[i + 2]] + " Ratus";
          }
        }

        /* untuk Puluhan atau Belasan */
        if (angka[i + 1] != "0") {
          if (angka[i + 1] == "1") {
            if (angka[i] == "0") {
              kata2 = "Sepuluh";
            } else if (angka[i] == "1") {
              kata2 = "Sebelas";
            } else {
              kata2 = kata[angka[i]] + " Belas";
            }
          } else {
            kata2 = kata[angka[i + 1]] + " Puluh";
          }
        }

        /* untuk Satuan */
        if (angka[i] != "0") {
          if (angka[i + 1] != "1") {
            kata3 = kata[angka[i]];
          }
        }

        /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
        if (angka[i] != "0" || angka[i + 1] != "0" || angka[i + 2] != "0") {
          subkaLimat =
            kata1 + " " + kata2 + " " + kata3 + " " + tingkat[j] + " ";
        }

        /* gabungkan variabe sub kaLimat (untuk Satu blok 3 angka) ke variabel kaLimat */
        kaLimat = subkaLimat + kaLimat;
        i = i + 3;
        j = j + 1;
      }

      /* mengganti Satu Ribu jadi Seribu jika diperlukan */
      if (angka[5] == "0" && angka[6] == "0") {
        kaLimat = kaLimat.replace("Satu Ribu", "Seribu");
      }
      let leftAppend = append ? append : "";
      return kaLimat + leftAppend;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        margin: 0.2,
        filename: `Deposito_${this.form.cifIdName}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      html2pdf()
        .set(opt)
        .from(element)
        .save();

      // New Promise-based usage:
      // html2pdf()
      //   .from(element)
      //   .set(opt)
      //   .toPdf()
      //   .get("pdf")
      //   .then((pdf) => {
      //     pdf.autoPrint();
      //     const hiddFrame = document.createElement("iframe");
      //     const isSafari = /^((?!chrome|android).)*safari/i.test(
      //       window.navigator.userAgent
      //     );
      //     if (isSafari) {
      //       // fallback in safari
      //       hiddFrame.onload = () => {
      //         try {
      //           hiddFrame.contentWindow.document.execCommand(
      //             "print",
      //             false,
      //             null
      //           );
      //         } catch (e) {
      //           hiddFrame.contentWindow.print();
      //         }
      //       };
      //     }
      //     hiddFrame.src = pdf.output("bloburl");
      //     document.body.appendChild(hiddFrame);
      //   });
    },

    resetQQRadio() {
      this.form.qqCode = "";
      this.form.qqName = "";
    },
    handleRouteToPageListDepositAccount() {
      this.$router.push("/deposit/deposit-account");
    },

    changeTsavingDisburseFundAccountNumber() {
      this.form.disburseFundAccountName = "";
      this.options.savingAccount.map((data) => {
        if (data.value === this.form.disburseFundAccount) {
          this.form.disburseFundAccountName = data.accountName;
        }
      });
    },
    changeTsavingDisburseProfitShareAccountNumber() {
      this.form.disburseProfitShareAccountName = "";
      this.options.savingAccount.map((data) => {
        if (data.value === this.form.disburseProfitShareAccount) {
          this.form.disburseProfitShareAccountName = data.accountName;
        }
      });
    },
    changeTsavingAccountDisburseDepositAccountNumber() {
      this.form.disburseDepositAccountName = "";
      this.options.savingAccount.map((data) => {
        if (data.value === this.form.disburseDepositAccount) {
          this.form.disburseDepositAccountName = data.accountName;
        }
      });
    },
    helperDefaultDateNow() {
      this.form.transactionDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.form.openDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    handleChangeOptionsProdukDeposito() {
      this.options.produkDeposito.map((i) => {
        if (this.isExisting === false) {
          this.form.dueDate = "";
          this.form.profitShareAddPercent = 0;
          this.form.profitShareTotalPercent = 0;
        }
        if (i.value === this.form.depositId) {
          this.options.jangkaWaktu = [];
          this.form.depositTimeTypeName = i.depositTimeTypeName;
          this.form.depositProfitShareTypeName = i.depositProfitShareTypeName;
          this.$store
            .dispatch("GET_REFERENCE_FROM_MASTER_TRANSACTION", {
              params: {
                depositId: i.value,
                page: 0,
              },
              url: "deposit-nisbah-chart-of-account",
            })
            .then((resp) => {
              resp.data.data.content.map((index) => {
                const text = `${index.timePeriode} - ${index.mdepositResponseDto.rdepositTimeType.depositTimeTypeName}`;
                const value = index.depositNisbahId;
                const nisbah = index.nisbah;
                const timePeriode = index.timePeriode;
                this.options.jangkaWaktu.push({
                  text,
                  value,
                  nisbah,
                  timePeriode,
                });
              });
            });
        }
      });
    },
    handleChangeJangkaWaktu() {
      this.form.profitShareAddPercent = 0;
      this.form.profitShareTotalPercent = 0;
      this.form.nisbah = 0;

      var textPeriode = "";
      this.options.jangkaWaktu.map((i) => {
        if (i.value === this.form.mdepositNisbahChartOfAccountId) {
          this.form.timePeriode = i.timePeriode;
          this.form.nisbah = i.nisbah;
          textPeriode = i.text;
        }
      });

      var splitPeriode = textPeriode.split(" - ");
      var jangkaWaktu = 0;
      if (this.form.mdepositNisbahChartOfAccountId != "") {
        jangkaWaktu = this.form.mdepositNisbahChartOfAccountId;
      }

      var periode = 0;
      var calculateDate = "";
      var d = new Date(this.form.transactionDate);
      if (this.form.transactionDate !== "") {
        periode = splitPeriode[0];
        if (splitPeriode[1] == "Tahunan") {
          calculateDate = d.setFullYear(d.getFullYear() + parseInt(periode));
        } else if (splitPeriode[1] == "Bulanan") {
          calculateDate = d.setMonth(d.getMonth() + parseInt(periode));
        } else if (splitPeriode[1] == "Harian") {
          calculateDate = d.setDate(d.getDate() + parseInt(periode));
        }
        this.form.dueDate = new Date(calculateDate).toISOString();
      }
    },
    helperGetDepositDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_TRANSACTION", {
          params: {
            name: "",
            page: 0,
          },
          url: "deposit",
        })
        .then((resp) => {
          resp.data.data.content.map((i, index) => {
            const text = i.depositName;
            const value = i.depositId;
            const depositTimeTypeName = i.rdepositTimeType.depositTimeTypeName;
            const depositProfitShareTypeName =
              i.rdepositProfitShareType.profitShareTypeName;
            this.options.produkDeposito.push({
              text,
              value,
              depositTimeTypeName,
              depositProfitShareTypeName,
            });
            if (resp.data.data.content.length === index + 1) {
              this.handleChangeOptionsProdukDeposito();
            }
          });
        });
    },

    handleChangeSumberPenempatanDana() {
      this.form.disburseFundAccount = "";
      this.form.disburseFundAccountName = "";
    },
    handleChangeTransferBagiHasil() {
      this.form.profitShareBankName = "";
      this.form.profitShareBankCode = "";
      this.form.profitShareBankAccount = "";
      this.form.profitShareBankAccountName = "";
      this.form.profitShareBankCost = "";
      this.form.profitShareBankNews = "";
      this.form.disburseProfitShareAccount = "";
      this.form.disburseProfitShareAccountName = "";
    },
    handleChangeTransferPokok() {
      this.form.depositTransferBankName = "";
      this.form.depositTransferBankCode = "";
      this.form.depositTransferBankAccount = "";
      this.form.depositTransferBankAccountName = "";
      this.form.depositTransferBankCost = "";
      this.form.depositTransferBankNews = "";
      this.form.disburseDepositAccount = "";
      this.form.disburseDepositAccountName = "";
      this.form.savingDepositAccountNumberName = "";
    },

    //CALCULATION NISBAH + SPESIAL NISBAH = TOTAL
    handleCalculationSpecialNisbah() {
      this.form.profitShareTotalPercent =
        parseFloat(
          this.form.profitShareAddPercent === ""
            ? 0
            : this.form.profitShareAddPercent
        ) + parseFloat(this.form.nisbah === "" ? 0 : this.form.nisbah);
    },
    defaultTotalNisbah() {
      this.form.nisbah =
        this.form.profitShareTotalPercent - this.form.profitShareAddPercent;
      this.form.profitShareTotalPercent =
        this.form.nisbah + this.form.profitShareAddPercent;
    },
    helperGetSourceFundDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "source-fund",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.sourceFundName;
            const value = i.sourceFundId;
            this.options.sumberDana.push({ text, value });
          });
        });
    },
    helperGetPurposeOpenAccountDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "purpose-open-account",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.purposeOpenAccountName;
            const value = i.purposeOpenAccountId;
            this.options.tujuanBukaDeposito.push({ text, value });
          });
        });
    },
    helperGetCifCompanyRelatedDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-company-related",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.cifCompanyRelatedName;
            const value = i.cifCompanyRelatedId;
            this.options.hubunganDenganBank.push({ text, value });
          });
        });
    },
    helperGetCifGroupOwnerDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-group-owner",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.cifGroupOwnerName;
            const value = i.cifGroupOwnerId;
            this.options.golonganPemilik.push({ text, value });
          });
        });
    },
    helperGetDeliveryStatementDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "delivery-statement",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.deliveryStatementName;
            const value = i.deliveryStatementId;
            this.options.penyampaianStatement.push({ text, value });
          });
        });
    },
    async helperGetAccountOfficerDataForReference() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        console.log("AO => ", resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.aoPromosi.push({ text, value });
            this.options.aoHandling.push({ text, value });
          });
        }
      } catch (error) {}
    },
    helperGetDepositProfitShareType() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-profit-share-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.profitShareTypeId;
            const text = i.profitShareTypeName;
            this.options.caraBagiHasil.push({ text, value });
          });
        });
    },
    async helperGetDepositOptionDisburseDataForReference() {
      const session = this.fetchAppSession("@vue-session/config-bundle");
      await this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "deposit-option-disburse",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.optionDisburseId;
            const text = i.optionDisburseName;
            console.log(session);
            if (
              i.optionDisburseGroup ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_GROUP_IN
            ) {
              console.log({ text, value });
              this.options.sumberPenempatanDana.push({ text, value });
            } else if (
              i.optionDisburseGroup ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_DEPOSIT_OPTION_DISBURSE_GROUP_OUT
            ) {
              this.options.sumberTransferBagiHasil.push({
                text,
                value,
                disabled: false,
              });
              this.options.sumberTransferPokok.push({
                text,
                value,
                disabled: false,
              });
            }
          });
        });
      this.options.sumberTransferBagiHasil.map((index) => {
        if (index.value === "2") {
          index.text = "Tunai - Layanan Tidak Tersedia";
          index.disabled = true;
        } else if (index.value === "6") {
          index.text = "Tunai - Layanan Tidak Tersedia";
          index.disabled = true;
        }
      });
      this.options.sumberTransferPokok.map((index) => {
        if (index.value === "2") {
          index.text = "Tunai - Layanan Tidak Tersedia";
          index.disabled = true;
        } else if (index.value === "6") {
          index.text = "Tunai - Layanan Tidak Tersedia";
          index.disabled = true;
        }
      });
    },
    helperGetBlockedReasonDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "blocked-reason",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.blockedReasonId;
            const text = i.blockedReasonName;
            this.options.alasanDiblokir.push({ text, value });
          });
        });
    },
    helperGetAccountClassificationDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "account-classification",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const text = i.accountClassificationName;
            const value = i.accountClassificationId;
            this.options.klasifikasiRekening.push({ text, value });
          });
        });
    },
    helperClearIdentifierCifIdOnSessionStorage() {
      sessionStorage.removeItem("CIF_ID");
    },

    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((data) => {
            const text = `${data.chartOfAccountCode} - ${data.description}`;
            const value = data.chartOfAccountCode;
            const subsidaryLedgerNumber = data.chartOfAccountCode;
            const description = data.description;
            this.options.chartOfAccount.push({
              text,
              value,
              description,
              subsidaryLedgerNumber,
            });
          });
        }
      } catch (error) {}
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.handleRouteToPageListDepositAccount();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? (this.identity.mainId = this.decryptBASE64(params))
          : (this.identity.mainId = JSON.parse(
              this.decryptBASE64(query)
            ).cifId);
      this.$emit("getMainId", this.identity.mainId);
    },
    async getSavingAccountByCifId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.handleRouteToPageListDepositAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl:
            "saving-account/cif-id/" +
            (this.isExisting ? this.form.cifId : this.identity.mainId),
          payload: {
            isCrossOffice: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.mcif.cifIdName} - ${index.accountNumber} - ${index.msaving.savingName}`;
            const value = index.accountNumber;
            const accountName = index.mcif ? index.mcif.cifIdName : "";
            this.options.savingAccount.push({ text, value, accountName });
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.handleRouteToPageListDepositAccount(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },

    getDefaultFormValue(payload) {
      if (this.isExisting === true) {
        this.form = payload;
        this.defaultTotalNisbah();
        this.getSavingAccountByCifId();
        this.getReferenceQQ(payload);
        return;
      }
      this.form.officeName = payload.officeId
        ? payload.officeId.officeName
        : "";
      this.form.cifCategoryName = payload.categoryId
        ? payload.categoryId.cifCategoryName
        : "";

      this.form.companyRelatedId = payload.companyRelatedId
        ? payload.companyRelatedId.cifCompanyRelatedId
        : "";
      this.form.groupOwnerId = payload.groupOwnerId
        ? payload.groupOwnerId.cifGroupOwnerId
        : "";
      this.form.commitRate = payload.commitRate;
      this.identity.mainId = payload.cifId;
      this.form.cifNumber = payload.cifNumber;
      this.form.cifIdName = payload.cifIdName;
      this.form.cifPhoneNumber = payload.cifMobilePhoneNumber;
      this.form.regionalCode = payload.regionalCode;
      this.form.cifIdAddress = payload.cifIdAddress;
      this.form.createdByUserCode = payload.createdByUserCode;
      this.form.createdByUserName = payload.createdByUserName;
      this.form.createdDate = payload.createdDate;
      this.form.updatedDate = payload.updatedDate;
      this.form.updatedByUserCode = payload.updatedByUserCode;
      this.form.updatedByUserName = payload.updatedByUserName;
      this.form.authorizedDate = payload.authorizedDate;
      this.form.authorizedByUserCode = payload.authorizedByUserCode;
      this.form.authorizedByUserName = payload.authorizedByUserName;
      this.getSavingAccountByCifId();
      this.getReferenceQQ(payload);
    },
    async getReferenceQQ(payload) {
      console.log(payload);
      if (!this.isExisting) {
        if (payload.categoryId.cifCategoryId === "1") {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-family/cif-id/" + payload.cifId,
            });
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.cifFamilyName}`;
                const value = index.cifFamilyName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        } else if (
          payload.categoryId.cifCategoryId === "4" ||
          payload.categoryId.cifCategoryId === "3"
        ) {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-company-management/cif-id/" + payload.cifId,
            });
            console.log(resp);
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.managementName}`;
                const value = index.managementName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        }
      } else {
        if (payload.categoryId === "1") {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-family/cif-id/" + payload.cifId,
            });
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.cifFamilyName}`;
                const value = index.cifFamilyName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        } else if (payload.categoryId === "4" || payload.categoryId === "3") {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl: "cif-company-management/cif-id/" + payload.cifId,
            });
            console.log(resp);
            if (resp.data.code === "SUCCESS") {
              resp.data.data.map((index) => {
                const text = `${index.managementName}`;
                const value = index.managementName;
                this.options.qq.push({ text, value });
              });
            }
          } catch (error) {}
        }
      }
    },
    submitDepositAccount() {
      this.$emit("submitDepositAccount", this.form);
    },
    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.referalOffice = resp.data.data;
          this.options.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
        }
      } catch (error) {}
    },
  },
  beforeMount() {},
  mounted() {
    this.checkingMainId();
    this.helperGetDepositDataForReference();
    this.helperGetSourceFundDataForReference();
    this.helperGetPurposeOpenAccountDataForReference();
    this.helperGetCifCompanyRelatedDataForReference();
    this.helperGetCifGroupOwnerDataForReference();
    this.helperGetDeliveryStatementDataForReference();
    this.helperGetAccountOfficerDataForReference();
    this.helperGetDepositOptionDisburseDataForReference();
    this.helperGetDepositProfitShareType();
    this.helperDefaultDateNow();
    this.helperGetBlockedReasonDataForReference();
    this.helperGetAccountClassificationDataForReference();
    this.getReferenceChartOfAccount();
    this.getReferenceOfficeReferal();
  },
  destroyed() {
    // this.helperClearIdentifierCifIdOnSessionStorage();
  },
};
